import { createContext, useContext } from 'react';

export const CollapseAllContext = createContext({
  isAllCollapsed: false,
  isAllExpanded: false,
  collapseAll: () => null,
  expandAll: () => null,
  uncontrolCollapse: () => null,
});
export const useCollapseAll = () => useContext(CollapseAllContext);
