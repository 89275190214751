import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import NavDroppable from './NavDroppable';
import NavSection from './NavSection';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { createSelector } from '@reduxjs/toolkit';
import { range } from '@acheloisbiosoftware/absui.utils';
import { useResizableWidth } from '@acheloisbiosoftware/absui.hooks';
import { useTheme } from '@mui/material/styles';

const RESIZER_WIDTH = 8;

const loadingSectionSelector = createSelector(
  (state, selection) => selection,
  (section) => ({
    id: section,
    icon: (
      <Skeleton
        variant='rounded'
        sx={{
          width: (theme) => theme.spacing(3),
          height: (theme) => theme.spacing(3),
        }}
      />),
    text: (
      <Skeleton variant='rounded' sx={{ width: 1, height: 24 }} />
    ),
  }),
);

function NavDrawer(props) {
  const {
    header,
    sections,
    sectionSelector,
    subsectionSelector,
    onDragEnd,
    initWidth,
    minWidth,
    maxWidth,
    resizable,
    loading,
  } = props;
  const [collapsed, setCollapsed] = useState(false);
  const resizableOptions = useMemo(() => ({
    minWidth,
    maxWidth,
  }), [minWidth, maxWidth]);
  const { width, Resizer, isResizing } = useResizableWidth(initWidth, resizableOptions);

  const theme = useTheme();
  const { easing: easings, duration: durations } = theme.transitions;
  const easing = easings.sharp;
  const duration = { enter: durations.enteringScreen, exit: durations.leavingScreen };
  const numLoadingSections = loading ? (typeof(loading) === 'boolean' ? 5 : loading) : 0;

  return (
    <Drawer
      variant='permanent'
      PaperProps={{
        sx: [{
          position: 'relative',
          bgcolor: 'background.grey',
          height: 1,
          overflowX: 'hidden',
          zIndex: 'header',
          boxSizing: 'border-box',
        },
        collapsed ? {
          width: theme.spacing(7), /* For icons: 1 ml + 1 pl + 3 icon + 1 pr + 1 mr */
          transition: theme.transitions.create('width', { easing, duration: duration.exit }),
        } : {
          width,
          transition: isResizing ? null : theme.transitions.create('width', { easing, duration: duration.enter }),
        }],
      }}
    >
      <List
        subheader={(
          <ListSubheader
            component={Paper}
            elevation={2}
            square
            sx={{
              display: 'flex',
              height: 64,
              px: 1,
              borderBottom: 1,
              borderColor: 'divider',
              boxShadow: 0,
            }}
          >
            <Box sx={{ m: 'auto', flexGrow: 1 }}>
              <Collapse
                orientation='horizontal'
                in={!collapsed}
                timeout={duration}
                easing={easing}
              >
                <Box
                  sx={{
                    lineHeight: 2,
                    width: `calc(${width}px - ${theme.spacing(8)})`, /* 7 for the icon, 1 for margin */
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  { loading ? (
                    <Skeleton variant='rounded' sx={{ width: 1, height: 40 }} />
                  ) : header}
                </Box>
              </Collapse>
            </Box>
            <Button icon sx={{ m: 'auto', color: 'text.icon' }} onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon /> }
            </Button>
          </ListSubheader>
        )}
      >
        <NavDroppable
          items={sections}
          onDragEnd={onDragEnd}
        >
          {
            loading ? (
              range(numLoadingSections).map((i) => (
                <NavSection
                  key={`NavSection${i}`}
                  section={i}
                  sectionSelector={loadingSectionSelector}
                />
              ))
            ) : (
              sections.map((section) => (
                <NavSection
                  key={`NavSection${section}`}
                  section={section}
                  sectionSelector={sectionSelector}
                  subsectionSelector={subsectionSelector}
                  collapsed={collapsed}
                />
              ))
            )
          }
        </NavDroppable>
      </List>
      { resizable && !collapsed ? (
        <Resizer
          sx={{
            width: RESIZER_WIDTH,
            left: width - RESIZER_WIDTH,
            zIndex: 'header',
          }}
        />
      ) : null}
    </Drawer>
  );
}

NavDrawer.propTypes = {
  header: PropTypes.node,
  sections: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ])),
  sectionSelector: PropTypes.func.isRequired,
  subsectionSelector: PropTypes.func,
  onDragEnd: PropTypes.func,
  initWidth: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  resizable: PropTypes.bool,
  loading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
};

NavDrawer.defaultProps = {
  initWidth: 300,
  minWidth: 240,
  maxWidth: 560,
  resizable: true,
  sections: [],
};

export default NavDrawer;
