import { useDirectory, useDirectoryChildren, useDirectoryHasLoaded, useDirectoryItem } from 'hooks/store.hooks';

import DocumentTable from 'components/DocumentTable';
import PropTypes from 'prop-types';
import React from 'react';

const templateColumnProps = { status: { hidden: true }};

function WorkspaceTable(props) {
  const { id, loading } = props;
  const childrenItemIds = useDirectoryChildren(id);
  const childrenItemsHasLoaded = useDirectoryHasLoaded(id, 'master_note'); // The directory's children are derived from the master note
  const childrenItems = useDirectoryItem(childrenItemIds, { id: 'object_id', type: 'content_type' });
  const isTemplate = useDirectory(id, 'is_template');

  return (
    <DocumentTable
      ids={childrenItems}
      loading={loading || !childrenItemsHasLoaded}
      stateInSearchParams={false}
      columnProps={isTemplate ? templateColumnProps : null}
    />
  );
}

WorkspaceTable.propTypes = {
  id: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default WorkspaceTable;
