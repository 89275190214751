import React, { useCallback, useEffect, useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';

import Collapse from '@mui/material/Collapse';
import CollapseButton from 'components/CollapseButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function NavListItem(props) {
  const {
    icon,
    text,
    children,
    path,
    onClick,
    open: _open,
    selected,
    collapsable,
    openOnClick,
    onOpen,
    onClose,
    buttonProps: _buttonProps,
    draggableProps,
    droppableProps,
  } = props;
  const [open, setOpen] = useState(false);
  const _setOpen = useCallback((newOpen) => {
    let oldOpen;
    let _newOpen = newOpen;
    setOpen((_oldOpen) => {
      oldOpen = _oldOpen;
      if (typeof(_newOpen) === 'function') _newOpen = _newOpen(_oldOpen);
      return _newOpen;
    });
    if (_newOpen && !oldOpen) onOpen?.();
    else if (!_newOpen && oldOpen) onClose?.();
  }, [onOpen, onClose]);
  const bgcolor = selected ? 'primary.main' : null;
  const color = selected ? 'primary.contrastText' : null;
  const showCollapse = (children && !(Array.isArray(children) && children.length === 0)) && collapsable;

  useEffect(() => {
    if (isDefined(_open)) _setOpen(_open);
  }, [_open, _setOpen]);

  const _onClick = useCallback((...args) => {
    if (openOnClick) {
      _setOpen((oldOpen) => !oldOpen);
    }
    onClick?.(...args);
  }, [onClick, openOnClick, _setOpen]);

  const buttonProps = {
    onClick: _onClick,
    ...(path ? {
      component: RouterLink,
      to: path,
    } : {}),
    ..._buttonProps,
  };

  const draggable = isDefined(draggableProps);
  const droppable = isDefined(droppableProps);
  const { isOver, setNodeRef: setDroppableNodeRef } = useDroppable({ ...droppableProps, disabled: !droppable });
  const { isDragging, attributes, listeners, setNodeRef: setDraggableNodeRef } = useDraggable({ ...draggableProps, disabled: !draggable });
  const _isOver = droppable && isOver;
  const _isDragging = draggable && isDragging;
  const showOutline = _isOver && !isDragging;

  useEffect(() => {
    /** Open after hovering over option */
    const setOpenTimeout = () => setTimeout(() => _setOpen(true), 500);
    let timeout;
    if (_isOver) {
      timeout = setOpenTimeout();
    } else {
      clearTimeout(timeout);
    }
    return () => clearTimeout(timeout);
  }, [_isOver, _setOpen]);

  return (
    <>
      <ListItem
        ref={setDroppableNodeRef}
        disableGutters
        disablePadding
        secondaryAction={showCollapse ? (
          <CollapseButton
            open={open}
            setOpen={_setOpen}
            buttonProps={{ sx: { p: 0, mr: 0.5, color }}}
          />
        ) : null}
        sx={_isDragging ? { opacity: 0.5 } : {}}
      >
        <ListItemButton
          ref={setDraggableNodeRef}
          {...(draggable ? attributes : {})}
          {...(draggable ? listeners : {})}
          {...buttonProps}
          sx={[
            {
              borderRadius: 100,
              transition: (theme) => theme.transitions.create(['background-color', 'color']),
            },
            selected ? {
              color,
              bgcolor,
              '&:hover': { bgcolor: 'primary.main' },
            } : {},
            showOutline ? {
              boxShadow: (theme) => `inset 0px 0px 0px 2px ${theme.palette.secondary.main}`,
            } : {},
          ]}
        >
          { icon ? (
            <ListItemIcon
              sx={{
                minWidth: 36,
                color: color ?? 'text.icon',
                transition: (theme) => theme.transitions.create('color'),
              }}
            >
              {icon}
            </ListItemIcon>
          ) : null}
          <ListItemText primaryTypographyProps={{ noWrap: true, color: color ?? 'text.primary' }}>
            {text ?? (<Skeleton />)}
          </ListItemText>
        </ListItemButton>
      </ListItem>
      { showCollapse ? (
        <Collapse in={open} timeout='auto' unmountOnExit sx={{ ml: 2 }}>
          {children}
        </Collapse>
      ) : children }
    </>
  );
}

NavListItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node,
  children: PropTypes.node,
  path: PropTypes.any,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.bool,
  collapsable: PropTypes.bool,
  openOnClick: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  draggableProps: PropTypes.object,
  droppableProps: PropTypes.object,
  buttonProps: PropTypes.object,
};

NavListItem.defaultProps = {
  open: false,
  collapsable: true,
};

export default NavListItem;
