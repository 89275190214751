import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import {
  Prop,
  createEntitiesWithUpdatesSelector,
  createEntityHasLoadedSelector,
  createEntityListSelector,
  createEntityPermissionSelector,
  createEntitySelector,
  createEntityUpdatesSelector,
} from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { isEmpty } from 'lodash';
import { permissionSelectors } from '../permission';

// #############################################################################
// ################################ Departments ################################
// #############################################################################
export const selectDepartments = (state) => state[ENTITY][ENTITY_DEPARTMENT];
export const selectDepartmentList = createEntityListSelector(selectDepartments);
export const selectDepartment = createEntitySelector(selectDepartments);
export const selectDepartmentHasLoaded = createEntityHasLoadedSelector(selectDepartments);
export const selectDepartmentUpdates = createEntityUpdatesSelector(selectDepartments);
export const selectDepartmentsWithUpdates = createEntitiesWithUpdatesSelector(selectDepartmentUpdates);
export const selectDepartmentHasUpdates = (state, id) => !isEmpty(selectDepartmentUpdates(state, id));
export const selectDepartmentPermission = createEntityPermissionSelector(selectDepartment);
export const selectDepartmentHasPermission = (state, id, obj, action) => {
  const permissionRelationships = permissionSelectors.selectPermissionRelationship(state, obj.permission_relationships ?? []);
  return permissionRelationships.some(
    (pr) => (
      isDefined(pr) &&
      pr.individual_content_type === ENTITY_DEPARTMENT &&
      pr.individual_id === id &&
      (!isDefined(action) || action === pr.action)
    ),
  );
};


// #############################################################################
// ################################### Teams ###################################
// #############################################################################
export const selectTeams = (state) => state[ENTITY][ENTITY_TEAM];
export const selectTeamList = createEntityListSelector(selectTeams);
export const selectTeam = createEntitySelector(selectTeams);
export const selectTeamHasLoaded = createEntityHasLoadedSelector(selectTeams);
export const selectTeamUpdates = createEntityUpdatesSelector(selectTeams);
export const selectTeamsWithUpdates = createEntitiesWithUpdatesSelector(selectTeamUpdates);
export const selectTeamHasUpdates = (state, id) => !isEmpty(selectTeamUpdates(state, id));
export const selectTeamPermission = createEntityPermissionSelector(selectTeam);
export const selectTeamHasPermission = (state, id, obj, action) => {
  const permissionRelationships = permissionSelectors.selectPermissionRelationship(state, obj.permission_relationships ?? []);
  return permissionRelationships.some(
    (pr) => (
      isDefined(pr) &&
      pr.individual_content_type === ENTITY_TEAM &&
      pr.individual_id === id &&
      (!isDefined(action) || action === pr.action)
    ),
  );
};


// #############################################################################
// ################################ User Roles #################################
// #############################################################################
export const selectUserRoles = (state) => state[ENTITY][ENTITY_USER_ROLE];
export const selectUserRoleList = createEntityListSelector(selectUserRoles);
export const selectUserRole = createEntitySelector(selectUserRoles);
export const selectUserRoleHasLoaded = createEntityHasLoadedSelector(selectUserRoles);
export const selectUserRoleUpdates = createEntityUpdatesSelector(selectUserRoles);
export const selectUserRolesWithUpdates = createEntitiesWithUpdatesSelector(selectUserRoleUpdates);
export const selectUserRoleHasUpdates = (state, id) => !isEmpty(selectUserRoleUpdates(state, id));
export const selectUserRolePermission = createEntityPermissionSelector(selectUserRole);


// #############################################################################
// ################################### Users ###################################
// #############################################################################
export const selectUsers = (state) => state[ENTITY][ENTITY_USER];
export const selectUserList = createEntityListSelector(selectUsers);
export const selectUser = createEntitySelector(selectUsers);
export const selectUserHasLoaded = createEntityHasLoadedSelector(selectUsers);

const userNameProp = new Prop('full_name');
export const selectUserName = (state, id) => selectUser(state, id, userNameProp);

const userInitialsProp = new Prop('initials');
export const selectUserInitials = (state, id) => selectUser(state, id, userInitialsProp);

export const selectUserUpdates = createEntityUpdatesSelector(selectUsers);
export const selectUsersWithUpdates = createEntitiesWithUpdatesSelector(selectUserUpdates);
export const selectUserHasUpdates = (state, id) => !isEmpty(selectUserUpdates(state, id));
export const selectUserPermission = createEntityPermissionSelector(selectUser);
