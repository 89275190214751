import { usePermission, useUpdateEntity, useUserRole, useUserRoleHasLoaded } from 'hooks/store.hooks';

import { ENTITY_USER_ROLE } from 'constants/schemas';
import PermissionMatrix from 'components/PermissionMatrix';
import PropTypes from 'prop-types';
import React from 'react';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';

const EMPTY_ARRAY = [];

function UserRolePermissions(props) {
  const { id } = props;
  const update = useUpdateEntity();
  const permissionIds = useUserRole(id, 'permissions_granted');
  const permissionIdsHasLoaded = useUserRoleHasLoaded(id, 'permissions_granted');
  const permissionObjs = usePermission(permissionIds ?? EMPTY_ARRAY);
  const isReadOnly = useEntityIsReadOnly();

  const onChange = (newPermissions) => {
    const newPermissionIds = newPermissions.map((permission) => permission.id);
    update({ type: ENTITY_USER_ROLE, id, key: 'permissions_granted', value: newPermissionIds });
  };

  return (
    <PermissionMatrix
      value={permissionObjs}
      onChange={onChange}
      readOnly={isReadOnly}
      loading={!permissionIdsHasLoaded}
      sx={{ mt: 2 }}
    />
  );
}

UserRolePermissions.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserRolePermissions;
