import { ENTITY_ATTACHMENT } from 'constants/schemas';
import { useMemo } from 'react';

export const getGenericId = (id, type) => {
  if (type === ENTITY_ATTACHMENT) { // Since attachment IDs are UUIDs, no need to parseFloat
    return { id, type };
  }
  const floatId = parseFloat(id);
  return { id: isNaN(floatId) ? id : floatId, type };
};
export const useGenericId = (id, type) => useMemo(() => getGenericId(id, type), [id, type]);

export const genericIdToString = (genericId) => `${genericId?.type}-${genericId?.id}`;
export const genericIdFromString = (genericIdString) => {
  const [type, id] = (genericIdString ?? '').split('-');
  return getGenericId(id, type);
};

export const genericIdEquals = (genericId1, genericId2) => genericId1.type === genericId2.type && genericId1.id === genericId2.id;
export const includesGenericId = (genericIdList, genericId) => genericIdList.some((id) => genericIdEquals(id, genericId));
export const uniqueGenericIds = (genericIdList) => genericIdList.filter((id, idx) => genericIdList.findIndex((other) => genericIdEquals(id, other)) === idx);
