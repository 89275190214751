import { useElectronContextMenu, useElectronDriveUpdateListener, useElectronNavigateListener } from 'hooks/electron.hooks';

import AppBody from './AppBody';
import NavBlocker from 'components/NavBlocker';
import { Outlet } from 'react-router-dom';
import React from 'react';
import SnackbarNotifier from 'views/SnackbarNotifier';
import useAuthVerify from 'hooks/useAuthVerify';
import useDocumentTitle from 'hooks/useDocumentTitle';

function AppRoot() {
  useAuthVerify();
  useDocumentTitle();
  useElectronContextMenu();
  useElectronNavigateListener();
  useElectronDriveUpdateListener();
  return (
    <>
      <SnackbarNotifier />
      <NavBlocker />
      <AppBody>
        <Outlet />
      </AppBody>
    </>
  );
}

export default AppRoot;
