import Department from './Department';
import React from 'react';
import { useParams } from 'react-router-dom';

function DepartmentPage() {
  const { id } = useParams();
  return (<Department id={parseFloat(id)} />);
}

export default DepartmentPage;
