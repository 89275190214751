import client from 'services';

export function fetchPins(params, config) {
  return client({ method: 'get', url: '/api/pins/', params, ...config });
}

export function createPin(data, config) {
  return client({ method: 'post', url: '/api/pins/', data, ...config });
}

export function fetchPin({ id }, config) {
  return client({ method: 'get', url: `/api/pins/${id}/`, ...config });
}

export function patchPin({ id, data }, config) {
  return client({ method: 'patch', url: `/api/pins/${id}/`, data, ...config });
}

export function deletePin({ id }, config) {
  return client({ method: 'delete', url: `/api/pins/${id}/`, ...config });
}
