import React from 'react';
import Workspace from './Workspace';
import { useParams } from 'react-router-dom';

function WorkspacePage() {
  const { id } = useParams();
  return (
    <Workspace id={parseFloat(id)} />
  );
}

export default WorkspacePage;
