import React, { useEffect, useState } from 'react';

import { CSS } from '@dnd-kit/utilities';
import { DragOverlay } from '@dnd-kit/core';
import PropTypes from 'prop-types';
import Row from './Row';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { createPortal } from 'react-dom';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useSortable } from '@dnd-kit/sortable';
import { useTheme } from '@mui/material/styles';

function RowSortable(props) {
  const { rowId, tableProps, ...restProps } = props;
  const rowProps = { rowId, ...restProps };
  const theme = useTheme();

  const {
    attributes: sortableAttributes,
    listeners: sortableListeners,
    setNodeRef: setSortableNodeRef,
    transform: sortableTransform,
    transition: sortableTransition,
    isDragging: sortableIsDragging,
  } = useSortable({ id: rowId });

  /**
   * This is a hack to show the dragged row in the overlay correctly. From the
   * dnd-kit documentation, DragOverlay should not be conditionally rendered.
   * However, the DataTable Row component must be within the context provided
   * by DataTable, so we cannot render the DragOverlay outside of the DataTable.
   * In the case of multiple container dragging (i.e. moving elements from one
   * DataTable instance to another), to unconditionally render the DragOverlay,
   * it must be outside of the DataTable context. To hack around this, each Row
   * is rendered with a conditional DragOverlay that is only rendered when the
   * row is being dragged. To allow the animation to complete after the Row is
   * dropped, we set a timeout to continue rendering the DragOverlay for a short
   * period of time.
   *
   * References:
   * https://docs.dndkit.com/api-documentation/draggable/drag-overlay
   */
  const [recentlyDragged, setRecentlyDragged] = useState(sortableIsDragging);
  useEffect(() => {
    const timeoutRef = { current: null };
    if (sortableIsDragging) {
      setRecentlyDragged(true);
    } else {
      timeoutRef.current = setTimeout(() => setRecentlyDragged(false), 500);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [sortableIsDragging]);

  return (
    <>
      <Row
        {...rowProps}
        setNodeRef={setSortableNodeRef}
        {...sortableAttributes}
        {...sortableListeners}
        cellProps={{ sx: sortableIsDragging ? { opacity: theme.opacity.disabled } : {}}}
        sx={[
          { cursor: 'grab' },
          sortableTransform ? {
            transform: CSS.Translate.toString(sortableTransform),
            transition: sortableTransition,
          } : {},
        ]}
      />
      {recentlyDragged ? createPortal(
        <DragOverlay style={{ zIndex: theme.zIndex.containedModal }}>
          <Table
            {...tableProps}
            sx={mergeSx({ bgcolor: 'background.paper' }, tableProps?.sx)}
          >
            <TableBody>
              <Row
                {...rowProps}
                cellProps={{ sx: { border: 0, cursor: 'grabbing' }}}
              />
            </TableBody>
          </Table>
        </DragOverlay>,
        document.body,
      ) : null}
    </>
  );
}

RowSortable.propTypes = {
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  tableProps: PropTypes.object,
};

export default RowSortable;
