import { ENTITY_MASTER_NOTE, ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import { useEntityId, useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useMasterNoteSection, useUpdateEntity } from 'hooks/store.hooks';

import Field from 'components/Field';
import PropTypes from 'prop-types';
import React from 'react';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';

const toolbarProps = { sx: { top: 58 }, elevation: 2 };

function MasterNoteSectionDescription(props) {
  const { id: sectionId } = props;
  const masterNoteId = useEntityId();
  const description = useMasterNoteSection(sectionId, 'description');
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(masterNoteId, ENTITY_MASTER_NOTE);
  const isReadOnly = useEntityIsReadOnly();

  const onChange = (value) => {
    update({ type: ENTITY_MASTER_NOTE_SECTION, id: sectionId, key: 'description', value });
  };

  return (
    <Field
      value={description}
      variant={Field.VARIANTS.TEXT_EDITOR}
      readOnly={isReadOnly}
      onChangeSerialized={onChange}
      onChange={editorChangeHandler}
      serializeOnBlur={false}
      sx={[
        { mt: 1 },
        !isReadOnly ? { minHeight: 80 } : {},
      ]}
      toolbarProps={toolbarProps}
      valueProps={{ key: `section${sectionId}_description` }}
    />
  );
}

MasterNoteSectionDescription.propTypes = {
  id: PropTypes.number,
};

export default MasterNoteSectionDescription;
