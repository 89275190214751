import { DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import DocumentTable, { ActionAddDocuments } from 'components/DocumentTable';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback, useEffect, useMemo } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { directoryActions, entitySelectors } from 'store/entity';
import { formatDate, momentValue } from 'utils/date.utils';
import { genericIdFromString, genericIdToString, getGenericId } from 'utils/generic.utils';
import { isNil, maxBy, zip } from 'lodash';
import {
  useDirectoryItemList,
  useEntity,
  useFetchListing,
  useSpaceGenericId,
  useSpaceType,
} from 'hooks/store.hooks';

import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getPath } from 'utils/entity.utils';
import { useNavigate } from 'react-router-dom';

function LaterTable(props) {
  const { listView, selected, setSelected } = props;
  const navigate = useNavigate();
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();

  const fetchOptions = useMemo(() => ({
    params: { filters: {
      in_sandbox: true,
      ...(spaceType === SPACE_USER ? { created_by: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_TEAM ? { team: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_DEPARTMENT ? { department: spaceGenericId.id } : {}),
    }},
  }), [spaceType, spaceGenericId]);

  const listParams = useMemo(() => ({
    prop: { id: 'object_id', type: 'content_type' },
    filter: (directoryItem, state) => (
      directoryItem.in_sandbox &&
      entitySelectors.selectEntityInSpace(state, getGenericId(directoryItem.object_id, directoryItem.content_type), spaceType, spaceGenericId)
    ),
  }), [spaceType, spaceGenericId]);

  const loading = useFetchListing(directoryActions.fetchDirectoryItems, fetchOptions);
  const genericIds = useDirectoryItemList(listParams);

  const createdDates = useEntity(genericIds, 'created');
  useEffect(() => {
    if (isNil(selected)) {
      const maxPair = maxBy(zip(genericIds, createdDates), (p) => momentValue(p[1]));
      setSelected(genericIdToString(maxPair?.[0]), { inParams: false });
    }
  }, [selected, setSelected, genericIds, createdDates]);

  const listViewOnRowClick = useCallback((row, event) => {
    const genericId = genericIdFromString(row.id);
    if (event.detail > 1) {
      navigate(getPath(genericId));
    } else {
      setSelected(row.id, { inParams: true });
    }
  }, [navigate, setSelected]);

  const listViewColumnProps = useMemo(() => ({
    checkbox: {
      selection: isNil(selected) ? [] : [selected],
    },
    name: {
      renderValue: ({ row, value }) => (
        <Stack>
          <Typography variant='subtitle2'>{value || 'Untitled'}</Typography>
          <Typography color='text.secondary' variant='caption'>{formatDate(row.created)}</Typography>
        </Stack>
      ),
      cellProps: { sx: { whiteSpace: 'normal' }},
    },
    owner: { hidden: true },
    status: { hidden: true },
    created: { hidden: true },
  }), [selected]);

  return (
    <DocumentTable
      ids={genericIds}
      loading={loading}
      {...(listView ? { onRowClick: listViewOnRowClick } : {})}
      noHeader={listView}
      disableFilter={listView}
      disableGroupBy={listView}
      columnProps={listView ? listViewColumnProps : null}
      actions={listView ? null : (
        <ActionAddDocuments
          entityTypes={[ENTITY_NOTE, ENTITY_EXPERIMENT, ENTITY_SOP, ENTITY_TASK, ENTITY_DIRECTORY]}
          directoryTypes={[DIR_TYPE_STUDY, DIR_TYPE_PROJECT]}
        />
      )}
      sx={{ overflowY: 'auto' }}
    />
  );
}

LaterTable.propTypes = {
  listView: PropTypes.bool,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};

export default LaterTable;
