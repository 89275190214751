import { AddIcon, ScriptIcon, TemplateIcon } from 'constants/icon.constants';
import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import React, { useMemo, useState } from 'react';
import { formatType, getIcon } from 'utils/entity.utils';

import { ACTION_CREATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import { DIR_TYPES } from 'constants/directory.constants';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import NewDirectoryDialog from 'components/NewDirectoryDialog';
import NewExperimentDialog from 'components/NewExperimentDialog';
import NewNoteDialog from 'components/NewNoteDialog';
import NewSopDialog from 'components/NewSopDialog';
import NewTaskDialog from 'components/NewTaskDialog';
import Tooltip from '@mui/material/Tooltip';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useMyGlobalPermission } from 'hooks/store.hooks';

const newEntityDialogComponentMap = {
  [ENTITY_DIRECTORY]: NewDirectoryDialog,
  [ENTITY_EXPERIMENT]: NewExperimentDialog,
  [ENTITY_NOTE]: NewNoteDialog,
  [ENTITY_SOP]: NewSopDialog,
  [ENTITY_TASK]: NewTaskDialog,
};

const initNewEntityState = {
  type: null,
  open: false,
  initState: {},
};

const documentTypes = [ENTITY_NOTE, ENTITY_EXPERIMENT, ENTITY_SOP, ENTITY_TASK, ENTITY_DIRECTORY];
const templateTypes = [ENTITY_NOTE, ENTITY_EXPERIMENT, ENTITY_SOP, ENTITY_TASK];

function NewButton() {
  const [anchorNew, setAnchorNew] = useState(null);
  const newOpen = Boolean(anchorNew);
  const [newEntityState, setNewEntityState] = useState(initNewEntityState);

  const canCreateDirectory = useMyGlobalPermission(ENTITY_DIRECTORY, ACTION_CREATE);
  const canCreateExp = useMyGlobalPermission(ENTITY_EXPERIMENT, ACTION_CREATE);
  const canCreateNote = useMyGlobalPermission(ENTITY_NOTE, ACTION_CREATE);
  const canCreateSop = useMyGlobalPermission(ENTITY_SOP, ACTION_CREATE);
  const canCreateTask = useMyGlobalPermission(ENTITY_TASK, ACTION_CREATE);

  const entityPermissionMap = useMemo(() => ({
    [ENTITY_DIRECTORY]: canCreateDirectory,
    [ENTITY_EXPERIMENT]: canCreateExp,
    [ENTITY_NOTE]: canCreateNote,
    [ENTITY_SOP]: canCreateSop,
    [ENTITY_TASK]: canCreateTask,
  }), [canCreateDirectory, canCreateExp, canCreateNote, canCreateSop, canCreateTask]);

  const canCreateTemplate = useMemo(
    () => templateTypes.some((type) => entityPermissionMap[type]),
    [entityPermissionMap],
  );

  const openNew = (event) => {
    setAnchorNew(event.currentTarget);
  };

  const closeNew = (callback) => {
    setAnchorNew(null);
    callback?.();
  };

  const newOptions = [
    ...(
      documentTypes.filter((type) => entityPermissionMap[type])
        .map((type) => {
          const Icon = getIcon(type);
          return {
            label: formatType(type),
            icon: <Icon colored />,
            onClick: () => setNewEntityState({ type, open: true, initState: {}}),
            children: type === ENTITY_DIRECTORY ? DIR_TYPES.map((dirType) => {
              const DirTypeIcon = getIcon(ENTITY_DIRECTORY, { directoryType: dirType });
              return {
                label: formatType(ENTITY_DIRECTORY, { directoryType: dirType }),
                icon: <DirTypeIcon colored />,
                onClick: () => setNewEntityState({ type, open: true, initState: { type: dirType }}),
              };
            }) : null,
          };
        })
    ),
    ...(canCreateDirectory ? [{
      label: 'Script',
      icon: <ScriptIcon colored />,
      onClick: () => setNewEntityState({ type: ENTITY_DIRECTORY, open: true, initState: { is_template: true }}),
      children: DIR_TYPES.map((dirType) => {
        const DirTypeIcon = getIcon(ENTITY_DIRECTORY, { directoryType: dirType });
        return {
          label: formatType(ENTITY_DIRECTORY, { directoryType: dirType, template: true }),
          icon: <DirTypeIcon colored template />,
          onClick: () => setNewEntityState({ type: ENTITY_DIRECTORY, open: true, initState: { type: dirType, is_template: true }}),
        };
      }),
    }] : []),
    ...(canCreateTemplate ? [{
      label: 'Template',
      icon: <TemplateIcon colored />,
      children: templateTypes.map((type) => {
        const Icon = getIcon(type);
        return {
          label: formatType(type, { template: true }),
          icon: <Icon colored template />,
          onClick: () => setNewEntityState({ type, open: true, initState: { is_template: true }}),
        };
      }),
    }] : []),
  ];

  return (
    <>
      <Tooltip arrow placement='right' title='Create New'>
        <Box component='span' sx={{ mb: 1 }}>
          <Button
            icon
            onClick={openNew}
            disabled={newOptions.length === 0}
            data-test='Nav-NEW'
            sx={[
              {
                zIndex: 2,
                color: newOpen ? 'text.primary' : 'text.icon',
                bgcolor: newOpen ? 'action.selected' : 'action.hover',
              },
              { ':hover': {
                color: 'text.primary',
                bgcolor: 'action.selected',
              }},
            ]}
          >
            <AddIcon />
          </Button>
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorNew}
        open={newOpen}
        onClose={() => closeNew()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {
          newOptions.map(({ label, icon, onClick, children }) => (
            <MenuItem
              key={label}
              icon={icon}
              label={label}
              onClick={() => closeNew(onClick)}
              data-test={`Nav-NEW-${label}`}
              nested={isDefined(children)}
              listItemTextProps={{ primaryTypographyProps: { variant: 'body2' }}}
            >
              { children?.map?.((child) => (
                <MenuItem
                  key={child.label}
                  icon={child.icon}
                  label={child.label}
                  onClick={() => closeNew(child.onClick)}
                  listItemTextProps={{ primaryTypographyProps: { variant: 'body2' }}}
                />
              ))}
            </MenuItem>
          ))
        }
      </Menu>
      {documentTypes.map((type) => {
        const Component = newEntityDialogComponentMap[type];
        return (
          <Component
            key={`new-${type}`}
            open={Boolean(newEntityState.open && newEntityState.type === type)}
            onClose={() => setNewEntityState(initNewEntityState)}
            initState={newEntityState.type === type ? newEntityState.initState : null}
          />
        );
      })}
    </>
  );
}

export default NewButton;
