import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { getGenericId } from 'utils/generic.utils';

export const EntitiesWithDelayedUpdatesContext = createContext([]);
export const useGetEntitiesWithDelayedUpdates = () => useContext(EntitiesWithDelayedUpdatesContext);

export const StageDelayedUpdatesContext = createContext(null);
export const useStageDelayedUpdates = () => useContext(StageDelayedUpdatesContext);

export const HasDelayedUpdatesContext = createContext(false);
export const useHasDelayedUpdates = () => useContext(HasDelayedUpdatesContext);

export const DelayedUpdateContext = createContext(null);
export const useDelayedUpdate = () => useContext(DelayedUpdateContext);

/**
 * This is a convenience function to allow for a better debounced rich text
 * editor experience (add as onChange callback to CustomEditor).
 */
export const useEditorChangeHandler = (id, type) => {
  const delayedUpdate = useDelayedUpdate();
  return useCallback((newValue, editor, { serializationCallback, isNewSerialization }) => {
    if (isNewSerialization) {
      delayedUpdate(getGenericId(id, type), () => {
        serializationCallback.flush();
      });
    }
  }, [id, type, delayedUpdate]);
};

function DelayedUpdateProvider(props) {
  const { children } = props;

  const delayedUpdates = useRef([]);
  const [hasDelayedUpdates, setHasDelayedUpdates] = useState(delayedUpdates.length > 0);

  const getDelayedUpdateEntities = useCallback(() => delayedUpdates.current.map(({ id }) => id), []);

  const delayedUpdate = useCallback((genericId, fn) => {
    delayedUpdates.current.push({ id: genericId, update: fn });
    setHasDelayedUpdates(true);
  }, []);

  const stageUpdates = useCallback(() => {
    delayedUpdates.current.forEach(({ update }) => update());
    delayedUpdates.current = [];
    setHasDelayedUpdates(false);
  }, []);

  useEffect(() => {
    const stageBeforeUnload = () => {
      stageUpdates();
    };
    window.addEventListener('beforeunload', stageBeforeUnload);
    return () => window.removeEventListener('beforeunload', stageBeforeUnload);
  }, [stageUpdates]);

  return (
    <EntitiesWithDelayedUpdatesContext.Provider value={getDelayedUpdateEntities}>
      <HasDelayedUpdatesContext.Provider value={hasDelayedUpdates}>
        <DelayedUpdateContext.Provider value={delayedUpdate}>
          <StageDelayedUpdatesContext.Provider value={stageUpdates}>
            {children}
          </StageDelayedUpdatesContext.Provider>
        </DelayedUpdateContext.Provider>
      </HasDelayedUpdatesContext.Provider>
    </EntitiesWithDelayedUpdatesContext.Provider>
  );
}

DelayedUpdateProvider.propTypes = {
  children: PropTypes.node,
};

export default DelayedUpdateProvider;
