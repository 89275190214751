import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useState } from 'react';
import { formatType, getIcon } from 'utils/entity.utils';

import Box from '@mui/material/Box';
import { DIR_TYPES } from 'constants/directory.constants';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import { ShareIcon } from 'constants/icon.constants';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function NewDirectoryFormInline(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    containerProps,
  } = props;
  const { name, type, is_template, permission_relationships } = formData ?? {};

  const numShares = (permission_relationships ?? []).length;
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  return (
    <Stack direction='row' alignItems='center' {...containerProps}>
      { excludeFields.includes('name') ? null : (
        <TextField
          variant='standard'
          size='small'
          label='Name'
          value={name}
          onChange={(_, newName) => updateFormData('name', newName)}
          {...fieldPropsMap?.name}
        />
      )}
      { excludeFields.includes('type') ? null : (
        <TextField
          variant='standard'
          size='small'
          label='Type'
          select
          value={type ?? ''}
          onChange={(_, newType) => updateFormData('type', newType)}
          {...fieldPropsMap?.type}
          SelectProps={{
            renderValue: (value) => {
              const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: value });
              return (
                <Stack direction='row' alignItems='center'>
                  <Icon colored sx={{ mx: 1 }} />
                  <Typography noWrap>{formatType(ENTITY_DIRECTORY, { directoryType: value })}</Typography>
                </Stack>
              );
            },
            ...fieldPropsMap?.type?.SelectProps,
          }}
        >
          { DIR_TYPES.map((_type) => {
            const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: _type });
            return (
              <MenuItem
                key={`option_${_type}`}
                value={_type}
                label={formatType(ENTITY_DIRECTORY, { directoryType: _type })}
                icon={<Icon colored />}
              />
            );
          })}
        </TextField>
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <Box sx={{ color: 'text.icon' }}>
          <Button
            variant='text'
            color={numShares > 0 ? 'primary' : 'inherit'}
            onClick={() => setShareDialogOpen(true)}
            startIcon={<ShareIcon />}
          >
            {numShares}
          </Button>
          <Dialog
            title={`Share New ${formatType(ENTITY_DIRECTORY, { directoryType: type, template: is_template })}`}
            open={shareDialogOpen}
            onConfirm={() => setShareDialogOpen(false)}
            confirmOnly
            fullWidth
            maxWidth='sm'
          >
            <ShareNewDocumentField
              shares={permission_relationships}
              setShares={(newShares) => updateFormData('permission_relationships', newShares)}
              {...fieldPropsMap?.permission_relationships}
            />
          </Dialog>
        </Box>
      )}
    </Stack>
  );
}

NewDirectoryFormInline.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewDirectoryFormInline;
