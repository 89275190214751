import {
  DEFAULT_GROUP_DATES_BY,
  GROUP_DATES_BY_OPTIONS,
  generateFilter,
} from './DataTable.utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { generateSearchParams } from 'utils/dom.utils';
import { useSearchParams } from 'react-router-dom';

// #############################################################################
// Sorting #####################################################################
// #############################################################################
const validatedOrderBy = (orderBy, columns) => {
  const column = columns.find((col) => col.id === orderBy);
  return column?.sortable ? orderBy : null;
};

const validatedOrder = (order) => (['asc', 'desc'].includes(order) ? order : 'asc');

export const useSortable = (initState, stateInSearchParams, columns) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderByParam = useMemo(() => {
    if (!searchParams.has('orderBy')) {
      return validatedOrderBy(initState?.orderBy, columns);
    }
    const orderByString = searchParams.get('orderBy');
    const orderByValue = orderByString === 'null' ? null : orderByString;
    return validatedOrderBy(orderByValue, columns);
  }, [columns, initState?.orderBy, searchParams]);

  const orderParam = useMemo(() => {
    if (!searchParams.has('order')) {
      return validatedOrder(initState?.order);
    }
    return validatedOrder(searchParams.get('order'));
  }, [initState?.order, searchParams]);

  const [orderByState, setOrderByState] = useState(() => validatedOrderBy(initState?.orderBy, columns));
  const [orderState, setOrderState] = useState(() => validatedOrder(initState?.order));

  const _setOrderState = useCallback((newOrderState) => {
    const { orderBy: newOrderBy = null, order: newOrder = 'asc' } = newOrderState ?? {};
    if (stateInSearchParams) {
      setSearchParams((oldSearchParams) => generateSearchParams({ orderBy: newOrderBy, order: newOrder }, oldSearchParams));
    } else {
      setOrderByState(newOrderBy);
      setOrderState(newOrder);
    }
  }, [stateInSearchParams, setSearchParams]);

  return useMemo(() => ({
    orderBy: stateInSearchParams ? orderByParam : orderByState,
    order: stateInSearchParams ? orderParam : orderState,
    setOrderState: _setOrderState,
  }), [orderByState, orderState, _setOrderState, orderByParam, orderParam, stateInSearchParams]);
};


// #############################################################################
// Filtering ###################################################################
// #############################################################################
const validatedFilters = (filters) => (filters ?? [{}]).map(generateFilter);

export const useFilterable = (initState, stateInSearchParams) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersParam = useMemo(() => {
    if (searchParams.has('filters')) {
      try {
        const parsedFilters = JSON.parse(searchParams.get('filters'));
        return validatedFilters(parsedFilters);
      } catch (e) {}
    }
    return validatedFilters(initState?.filters);
  }, [initState?.filters, searchParams]);

  const [filtersParamStaged, setFiltersParamStaged] = useState(filtersParam);

  const prevFiltersParam = useRef(filtersParam);
  useEffect(() => {
    if (filtersParam !== prevFiltersParam.current) {
      setFiltersParamStaged(filtersParam);
      prevFiltersParam.current = filtersParam;
    }
  }, [filtersParam]);

  const [filtersState, setFiltersState] = useState(() => validatedFilters(initState?.filters));

  const applyFilters = useCallback(() => {
    if (stateInSearchParams) {
      setSearchParams((oldSearchParams) => generateSearchParams({ filters: JSON.stringify(filtersParamStaged) }, oldSearchParams));
    }
  }, [stateInSearchParams, setSearchParams, filtersParamStaged]);

  const setFilters = useCallback((newFilters) => {
    if (stateInSearchParams) {
      setFiltersParamStaged(newFilters);
    } else {
      setFiltersState(newFilters);
    }
  }, [stateInSearchParams]);

  return useMemo(() => ({
    filters: stateInSearchParams ? filtersParamStaged : filtersState,
    applyFilters,
    setFilters,
  }), [filtersState, filtersParamStaged, applyFilters, setFilters, stateInSearchParams]);
};

// #############################################################################
// Grouping ####################################################################
// #############################################################################
const validatedGroupBy = (groupBy, columns) => {
  const column = columns.find((col) => col.id === groupBy);
  return column?.groupable ? groupBy : null;
};

const validatedGroupDatesBy = (groupDatesBy) => {
  const option = GROUP_DATES_BY_OPTIONS.find((o) => o.id === groupDatesBy);
  return option?.id ?? DEFAULT_GROUP_DATES_BY;
};

export const useGroupable = (initState, stateInSearchParams, columns) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const groupByParam = useMemo(() => {
    if (!searchParams.has('groupBy')) {
      return validatedGroupBy(initState?.groupBy, columns);
    }
    const groupByString = searchParams.get('groupBy');
    const groupByValue = groupByString === 'null' ? null : groupByString;
    return validatedGroupBy(groupByValue, columns);
  }, [columns, initState?.groupBy, searchParams]);

  const groupDatesByParam = useMemo(() => {
    if (!searchParams.has('groupDatesBy')) {
      return validatedGroupDatesBy(initState?.groupDatesBy);
    }
    return validatedGroupDatesBy(searchParams.get('groupDatesBy'));
  }, [initState?.groupDatesBy, searchParams]);

  const [groupByState, setGroupByState] = useState(() => validatedGroupBy(initState?.groupBy, columns));
  const [groupDatesByState, setGroupDatesByState] = useState(() => validatedGroupDatesBy(initState?.groupDatesBy));

  const groupDatesByOption = useMemo(() => {
    const _groupDatesBy = stateInSearchParams ? groupDatesByParam : groupDatesByState;
    return GROUP_DATES_BY_OPTIONS.find((option) => option.id === _groupDatesBy);
  }, [groupDatesByState, groupDatesByParam, stateInSearchParams]);

  const setGroupBy = useCallback((newGroupBy) => {
    if (stateInSearchParams) {
      setSearchParams((oldSearchParams) => generateSearchParams({ groupBy: newGroupBy }, oldSearchParams));
    } else {
      setGroupByState(newGroupBy);
    }
  }, [stateInSearchParams, setSearchParams]);

  const setGroupDatesBy = useCallback((option) => {
    const newGroupDatesBy = option?.id ?? DEFAULT_GROUP_DATES_BY;
    if (stateInSearchParams) {
      setSearchParams((oldSearchParams) => generateSearchParams({ groupDatesBy: newGroupDatesBy }, oldSearchParams));
    } else {
      setGroupDatesByState(newGroupDatesBy);
    }
  }, [stateInSearchParams, setSearchParams]);

  return useMemo(() => ({
    groupBy: stateInSearchParams ? groupByParam : groupByState,
    groupDatesBy: groupDatesByOption,
    setGroupBy,
    setGroupDatesBy,
  }), [groupByState, groupByParam, groupDatesByOption, setGroupBy, setGroupDatesBy, stateInSearchParams]);
};
