import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function WidgetContainer(props) {
  const { paperProps, children, ...restProps } = props;
  return (
    <Grid item xs={12} md={6} xl={6} {...restProps}>
      <Paper
        elevation={2}
        component={Stack}
        {...paperProps}
        sx={mergeSx(
          {
            px: 2,
            py: 1,
            bgcolor: 'background.default',
            borderRadius: 4,
            boxShadow: 0,
            height: 400,
          },
          paperProps?.sx,
        )}
      >
        {children}
      </Paper>
    </Grid>
  );
}

WidgetContainer.propTypes = {
  paperProps: PropTypes.object,
  children: PropTypes.node,
};

export default WidgetContainer;
