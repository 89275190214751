import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from 'components/SectionHeader';

function ExperimentSectionBase(props) {
  const {
    children,
    containerProps,
    sectionHeaderProps,
  } = props;
  return (
    <Box {...containerProps}>
      <SectionHeader
        sticky
        variant='h4'
        {...sectionHeaderProps}
        sx={{ height: 40, overflow: 'hidden' }}
        stickyContainerProps={{ sx: { pt: 1 }}}
        titleTypographyProps={{ noWrap: true }}
      />
      { children }
    </Box>
  );
}

ExperimentSectionBase.propTypes = {
  children: PropTypes.node,
  containerProps: PropTypes.object,
  sectionHeaderProps: PropTypes.object,
};

export default ExperimentSectionBase;
