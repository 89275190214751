import { isNil } from 'lodash';
import { schema } from 'normalizr';

// #############################################################################
// ############################### Entity Types ################################
// #############################################################################
export const ENTITY_ATTACHMENT = 'attachment';
export const ENTITY_CATEGORY = 'category';
export const ENTITY_COMMENT = 'comment';
export const ENTITY_DEPARTMENT = 'department';
export const ENTITY_DIRECTORY = 'directory';
export const ENTITY_DIRECTORY_ITEM = 'directory_item';
export const ENTITY_EXPERIMENT = 'experiment';
export const ENTITY_EXPERIMENT_SECTION = 'experiment_section';
export const ENTITY_EXPERIMENT_SUBSECTION = 'experiment_subsection';
export const ENTITY_MASTER_NOTE = 'master_note';
export const ENTITY_MASTER_NOTE_ITEM = 'master_note_item';
export const ENTITY_MASTER_NOTE_SECTION = 'master_note_section';
export const ENTITY_NOTE = 'note';
export const ENTITY_PERMISSION = 'permission';
export const ENTITY_PERMISSION_RELATIONSHIP = 'permission_relationship';
export const ENTITY_PIN = 'pin';
export const ENTITY_SIGNATURE = 'signature';
export const ENTITY_SIGNATURE_WORKFLOW = 'signature_workflow';
export const ENTITY_SIGNATURE_WORKFLOW_STEP = 'signature_workflow_step';
export const ENTITY_SOP = 'sop';
export const ENTITY_SOP_SECTION = 'sop_section';
export const ENTITY_SOP_SUBSECTION = 'sop_subsection';
export const ENTITY_TASK = 'task';
export const ENTITY_TEAM = 'team';
export const ENTITY_USER = 'user';
export const ENTITY_USER_ROLE = 'user_role';

export const ALL_ENTITY_TYPES = [
  ENTITY_ATTACHMENT,
  ENTITY_CATEGORY,
  ENTITY_COMMENT,
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_DIRECTORY_ITEM,
  ENTITY_EXPERIMENT,
  ENTITY_EXPERIMENT_SECTION,
  ENTITY_EXPERIMENT_SUBSECTION,
  ENTITY_MASTER_NOTE,
  ENTITY_MASTER_NOTE_ITEM,
  ENTITY_MASTER_NOTE_SECTION,
  ENTITY_NOTE,
  ENTITY_PERMISSION,
  ENTITY_PERMISSION_RELATIONSHIP,
  ENTITY_PIN,
  ENTITY_SIGNATURE,
  ENTITY_SIGNATURE_WORKFLOW,
  ENTITY_SIGNATURE_WORKFLOW_STEP,
  ENTITY_SOP,
  ENTITY_SOP_SECTION,
  ENTITY_SOP_SUBSECTION,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
];


// #############################################################################
// ################################## Schemas ##################################
// #############################################################################
export const attachment = new schema.Entity(ENTITY_ATTACHMENT, {}, { idAttribute: 'uuid' });
export const category = new schema.Entity(ENTITY_CATEGORY);
export const comment = new schema.Entity(ENTITY_COMMENT);
export const department = new schema.Entity(ENTITY_DEPARTMENT);
export const directory = new schema.Entity(ENTITY_DIRECTORY);
export const directoryItem = new schema.Entity(ENTITY_DIRECTORY_ITEM);
export const experiment = new schema.Entity(ENTITY_EXPERIMENT);
export const experimentSection = new schema.Entity(ENTITY_EXPERIMENT_SECTION);
export const experimentSubsection = new schema.Entity(ENTITY_EXPERIMENT_SUBSECTION);
export const masterNote = new schema.Entity(ENTITY_MASTER_NOTE);
export const masterNoteItem = new schema.Entity(ENTITY_MASTER_NOTE_ITEM);
export const masterNoteSection = new schema.Entity(ENTITY_MASTER_NOTE_SECTION);
export const note = new schema.Entity(ENTITY_NOTE);
export const permission = new schema.Entity(ENTITY_PERMISSION);
export const permissionRelationship = new schema.Entity(ENTITY_PERMISSION_RELATIONSHIP);
export const pin = new schema.Entity(ENTITY_PIN);
export const signature = new schema.Entity(ENTITY_SIGNATURE);
export const signatureWorkflow = new schema.Entity(ENTITY_SIGNATURE_WORKFLOW);
export const signatureWorkflowStep = new schema.Entity(ENTITY_SIGNATURE_WORKFLOW_STEP);
export const sop = new schema.Entity(ENTITY_SOP);
export const sopSection = new schema.Entity(ENTITY_SOP_SECTION);
export const sopSubsection = new schema.Entity(ENTITY_SOP_SUBSECTION);
export const task = new schema.Entity(ENTITY_TASK);
export const team = new schema.Entity(ENTITY_TEAM);
export const user = new schema.Entity(ENTITY_USER);
export const userRole = new schema.Entity(ENTITY_USER_ROLE);

const SCHEMAS = [
  attachment,
  category,
  comment,
  department,
  directory,
  directoryItem,
  experiment,
  experimentSection,
  experimentSubsection,
  masterNote,
  masterNoteItem,
  masterNoteSection,
  note,
  permission,
  permissionRelationship,
  pin,
  signature,
  signatureWorkflow,
  signatureWorkflowStep,
  sop,
  sopSection,
  sopSubsection,
  task,
  team,
  user,
  userRole,
];

const ENTITY_SCHEMA_MAP = Object.fromEntries(SCHEMAS.map((s) => [s.key, s]));


// #############################################################################
// ############################ Schema Definitions #############################
// #############################################################################
attachment.define({
  created_by: user,
  checked_out_by: user,
});


comment.define({
  created_by: user,
  related_obj: (input) => ENTITY_SCHEMA_MAP[input.content_type],
});


department.define({
  users: [user],
});


directory.define({
  created_by: user,
  master_note: masterNote,
  directory_item: directoryItem,
  permission_relationships: [permissionRelationship],
  checked_out_by: user,
});
directory._cascadingParents = ['directory_item', 'master_note'];
directory._cascadingChildren = ['directory_item', 'master_note', 'permission_relationships'];


directoryItem.define({
  locations: [directory],
  pin,
  item: (input) => ENTITY_SCHEMA_MAP[input.content_type],
});


experiment.define({
  sections: [experimentSection],
  created_by: user,
  related_sop: sop,
  directory_item: directoryItem,
  permission_relationships: [permissionRelationship],
  signature_workflow: signatureWorkflow,
  checked_out_by: user,
});
experiment._cascadingParents = ['directory_item'];
experiment._cascadingChildren = ['directory_item', 'permission_relationships', 'signature_workflow', 'sections'];


experimentSection.define({
  subsections: [experimentSubsection],
});
experimentSection._cascadingChildren = ['subsections'];


experimentSubsection.define({
  comments: [comment],
});
experimentSubsection._cascadingChildren = ['comments'];


masterNote.define({
  sections: [masterNoteSection],
  parent: directory,
});
masterNote._cascadingParents = ['parent'];
masterNote._cascadingChildren = ['parent', 'sections'];


masterNoteItem.define({
  directory_item: directoryItem,
});
masterNoteItem._cascadingParents = ['directory_item'];


masterNoteSection.define({
  items: [masterNoteItem],
});
masterNoteSection._cascadingChildren = ['items'];


note.define({
  created_by: user,
  directory_item: directoryItem,
  permission_relationships: [permissionRelationship],
});
note._cascadingParents = ['directory_item'];
note._cascadingChildren = ['directory_item', 'permission_relationships'];


permissionRelationship.define({
  individual: (input) => ENTITY_SCHEMA_MAP[input.individual_content_type], // NOTE: if the related individual is deleted, the permission relationship will not be aware
  object: (input) => ENTITY_SCHEMA_MAP[input.object_content_type],
});


pin.define({
  user,
  directory_item: directoryItem,
});
pin._cascadingParents = ['directory_item'];


signature.define({
  signee: user,
  created_by: user,
  signature_workflow: signatureWorkflow,
});
signature._cascadingParents = ['signature_workflow'];


signatureWorkflow.define({
  steps: [signatureWorkflowStep],
  related_obj: (input) => ENTITY_SCHEMA_MAP[input.content_type],
});
signatureWorkflow._cascadingChildren = ['steps'];


signatureWorkflowStep.define({
  signatures: [signature],
  comments: [comment],
});
signatureWorkflowStep._cascadingChildren = ['signatures', 'comments'];


sop.define({
  category,
  sections: [sopSection],
  created_by: user,
  directory_item: directoryItem,
  permission_relationships: [permissionRelationship],
  signature_workflow: signatureWorkflow,
  cloned_from: sop,
  previous_version: sop,
  versions: [sop],
  checked_out_by: user,
});
sop._cascadingParents = ['directory_item'];
sop._cascadingChildren = ['directory_item', 'permission_relationships', 'signature_workflow', 'sections'];


sopSection.define({
  subsections: [sopSubsection],
});
sopSection._cascadingChildren = ['subsections'];


task.define({
  created_by: user,
  assignees: [user],
  comments: [comment],
  directory_item: directoryItem,
  permission_relationships: [permissionRelationship],
  related_obj: (input) => ENTITY_SCHEMA_MAP[input.related_obj_content_type], // NOTE: if the related object is deleted, the task will not be aware
  checked_out_by: user,
});
task._cascadingParents = ['directory_item'];
task._cascadingChildren = ['directory_item', 'permission_relationships', 'comments'];


team.define({
  users: [user],
});


user.define({
  departments: [department],
  teams: [team],
  user_roles: [userRole],
});


userRole.define({
  permissions_granted: [permission],
  created_by: user,
  users: [user],
});


// #############################################################################
// ############################### Dependencies ################################
// #############################################################################
const _getFieldKey = (schemaField) => {
  if (Array.isArray(schemaField) && schemaField[0] instanceof schema.Entity) {
    return schemaField[0].key;
  } else if (schemaField instanceof schema.Entity) {
    return schemaField.key;
  }
  return null;
};
const _getEntityDependencyMap = () => {
  const dependencyMap = Object.fromEntries(SCHEMAS.map((s) => [s.key, []]));

  for (const s of SCHEMAS) {
    // Parent dependencies
    for (const field of Object.keys(s.schema)) {
      const childKey = _getFieldKey(s.schema[field]);
      if (!isNil(childKey)) { // Child key is null for generic function typed fields
        dependencyMap[childKey].push({
          isRelatedField: true,
          type: s.key,
          field,
          cascade: (s._cascadingParents ?? []).includes(field),
        });
      }
    }

    // Children dependencies
    for (const field of s._cascadingChildren ?? []) {
      const depKey = _getFieldKey(s.schema[field]);
      if (depKey) { // Dep key is null for generic function typed fields
        dependencyMap[s.key].push({
          isRelatedField: false,
          type: depKey,
          field,
          cascade: true,
        });
      }
    }
  }
  return dependencyMap;
};
export const ENTITY_DEPENDENCY_MAP = _getEntityDependencyMap();
