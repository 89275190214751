import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback, useState } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import Collapse from '@mui/material/Collapse';
import CurrentRelatedDocument from './CurrentRelatedDocument';
import NewRelatedDocument from './NewRelatedDocument';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

function TaskRelatedDocument(props) {
  const {
    related_obj_id,
    related_obj_content_type,
    related_obj_init_data,
    onChange,
    onLaunch,
    launchDisabled,
    hideLaunch,
    hideOpen,
    readOnly: _readOnly,
    paperProps,
  } = props;
  const readOnly = _readOnly || !isDefined(onChange);
  const [open, setOpen] = useState(false);
  const [launchLoading, setLaunchLoading] = useState(false);

  const _onLaunch = useCallback((...args) => {
    if (isDefined(onLaunch)) {
      setLaunchLoading(true);
      onLaunch(...args).finally(() => setLaunchLoading(false));
    }
  }, [onLaunch]);

  const isExisting = isDefined(related_obj_content_type) && isDefined(related_obj_id);
  const isNew = isDefined(related_obj_content_type) && !isDefined(related_obj_id);

  const onClear = useCallback(() => {
    onChange?.('related_obj_id', null);
    onChange?.('related_obj_content_type', null);
    onChange?.('related_obj_init_data', {});
  }, [onChange]);

  return (
    <>
      <CurrentRelatedDocument
        related_obj_id={related_obj_id}
        related_obj_content_type={related_obj_content_type}
        related_obj_init_data={related_obj_init_data}
        readOnly={readOnly}
        isExisting={isExisting}
        isNew={isNew}
        onClear={onClear}
        onLaunch={_onLaunch}
        launchDisabled={launchDisabled}
        launchLoading={launchLoading}
        hideLaunch={hideLaunch}
        hideOpen={hideOpen}
        formOpen={open}
        setFormOpen={setOpen}
      />
      <Collapse in={Boolean(open && !readOnly)} timeout='auto'>
        <Paper
          variant='outlined'
          {...paperProps}
          sx={mergeSx(
            { boxShadow: 0, px: 2, py: 1, mt: 1 },
            paperProps?.sx,
          )}
        >
          <NewRelatedDocument
            related_obj_id={related_obj_id}
            related_obj_content_type={related_obj_content_type}
            related_obj_init_data={related_obj_init_data}
            onChange={onChange}
            isExisting={isExisting}
            isNew={isNew}
            onClear={onClear}
            onLaunch={_onLaunch}
            launchDisabled={launchDisabled}
            launchLoading={launchLoading}
            hideLaunch={hideLaunch}
            hideOpen={hideOpen}
          />
        </Paper>
      </Collapse>
    </>
  );
}

TaskRelatedDocument.propTypes = {
  related_obj_id: PropTypes.number,
  related_obj_content_type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
  related_obj_init_data: PropTypes.object,
  onChange: PropTypes.func,
  onLaunch: PropTypes.func,
  launchDisabled: PropTypes.bool,
  hideLaunch: PropTypes.bool,
  hideOpen: PropTypes.bool,
  readOnly: PropTypes.bool,
  paperProps: PropTypes.object,
};

export default TaskRelatedDocument;
