import React from 'react';
import Team from './Team';
import { useParams } from 'react-router-dom';

function TeamPage() {
  const { id } = useParams();
  return (<Team id={parseFloat(id)} />);
}

export default TeamPage;
