import client from 'services';

export function createComment(data, config) {
  return client({ method: 'post', url: `/api/comments/`, data, ...config });
}

export function patchComment({ id, data }, config) {
  return client({ method: 'patch', url: `/api/comments/${id}/`, data, ...config });
}

export function deleteComment({ id }, config) {
  return client({ method: 'delete', url: `/api/comments/${id}/`, ...config });
}
