import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { ENTITY_NOTE } from 'constants/schemas';
import PropTypes from 'prop-types';
import { ShareIcon } from 'constants/icon.constants';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';
import { formatType } from 'utils/entity.utils';

function NewNoteFormInline(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    containerProps,
  } = props;
  const { title, is_template, permission_relationships } = formData;

  const numShares = (permission_relationships ?? []).length;
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  return (
    <Stack direction='row' alignItems='center' {...containerProps}>
      { excludeFields.includes('title') ? null : (
        <TextField
          variant='standard'
          size='small'
          label='Title'
          value={title}
          onChange={(_, newTitle) => updateFormData('title', newTitle)}
          {...fieldPropsMap?.title}
        />
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <Box sx={{ color: 'text.icon' }}>
          <Button
            variant='text'
            color={numShares > 0 ? 'primary' : 'inherit'}
            onClick={() => setShareDialogOpen(true)}
            startIcon={<ShareIcon />}
          >
            {numShares}
          </Button>
          <Dialog
            title={`Share New ${formatType(ENTITY_NOTE, { template: is_template })}`}
            open={shareDialogOpen}
            onConfirm={() => setShareDialogOpen(false)}
            confirmOnly
            fullWidth
            maxWidth='sm'
          >
            <ShareNewDocumentField
              shares={permission_relationships}
              setShares={(newShares) => updateFormData('permission_relationships', newShares)}
              {...fieldPropsMap?.permission_relationships}
            />
          </Dialog>
        </Box>
      )}
    </Stack>
  );
}

NewNoteFormInline.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewNoteFormInline;
