import React from 'react';
import Task from './Task';
import { useParams } from 'react-router-dom';

function TaskPage() {
  const { id } = useParams();
  return (<Task id={parseFloat(id)} />);
}

export default TaskPage;
