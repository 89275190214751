import {
  ACTION_CLONE,
  ACTION_CREATE,
  ACTION_DELETE,
  ACTION_GENERATE_TEMPLATE,
  ACTION_INSTANTIATE,
  ACTION_NEW_VERSION,
  ACTION_UPDATE,
  ACTION_VIEW,
} from 'constants/permission.constants';
import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_MASTER_NOTE_ITEM,
  ENTITY_MASTER_NOTE_SECTION,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_SOP_SECTION,
  ENTITY_SOP_SUBSECTION,
  ENTITY_TASK,
} from 'constants/schemas';
import {
  Prop,
  directorySelectors,
  entitySelectors,
  permissionSelectors,
  sopSelectors,
} from 'store/entity';

import ActionClone from './ActionClone';
import ActionCopyLink from './ActionCopyLink';
import ActionDelete from './ActionDelete';
import ActionExport from './ActionExport';
import ActionGenerateTemplate from './ActionGenerateTemplate';
import ActionInstantiate from './ActionInstantiate';
import ActionMove from './ActionMove';
import ActionOpen from './ActionOpen';
import ActionPin from './ActionPin';
import ActionRestore from './ActionRestore';
import ActionShare from './ActionShare';
import MasterNoteItemActionDelete from './MasterNoteItemActionDelete';
import MasterNoteItemActionMoveDown from './MasterNoteItemActionMoveDown';
import MasterNoteItemActionMoveSection from './MasterNoteItemActionMoveSection';
import MasterNoteItemActionMoveUp from './MasterNoteItemActionMoveUp';
import MasterNoteItemActionRemove from './MasterNoteItemActionRemove';
import MasterNoteSectionActionHideDescription from './MasterNoteSectionActionHideDescription';
import MasterNoteSectionActionHideItems from './MasterNoteSectionActionHideItems';
import SopActionNewExperiment from './SopActionNewExperiment';
import SopActionNewVersion from './SopActionNewVersion';
import { createSelector } from '@reduxjs/toolkit';
import { createSelectorHook } from 'store/store.selectors.utils';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { isNil } from 'lodash';
import { selectMoveToDirectoryType } from 'utils/directory.utils';

export const ENTITY_ACTION_OPEN = 'open';
export const ENTITY_ACTION_MOVE = 'move';
export const ENTITY_ACTION_PIN = 'pin';
export const ENTITY_ACTION_COPY_LINK = 'copyLink';
export const ENTITY_ACTION_SHARE = 'share';
export const SOP_ACTION_NEW_EXPERIMENT = 'newExperiment';
export const SOP_ACTION_NEW_VERSION = 'newVersion';
export const ENTITY_ACTION_CLONE = 'clone';
export const ENTITY_ACTION_INSTANTIATE = 'instantiate';
export const ENTITY_ACTION_GENERATE_TEMPLATE = 'generateTemplate';
export const MASTER_NOTE_SECTION_HIDE_DESCRIPTION = 'masterNoteSectionHideDescription';
export const MASTER_NOTE_SECTION_HIDE_ITEMS = 'masterNoteSectionHideItems';
export const MASTER_NOTE_ITEM_MOVE_SECTION = 'masterNoteItemMoveSection';
export const MASTER_NOTE_ITEM_MOVE_UP = 'masterNoteItemMoveUp';
export const MASTER_NOTE_ITEM_MOVE_DOWN = 'masterNoteItemMoveDown';
export const ENTITY_ACTION_EXPORT = 'export';
export const MASTER_NOTE_ITEM_REMOVE = 'masterNoteItemRemove';
export const MASTER_NOTE_ITEM_DELETE = 'masterNoteItemDelete';
export const ENTITY_ACTION_DELETE = 'delete';
export const ENTITY_ACTION_RESTORE = 'restore';

export {
  ActionOpen,
  ActionMove,
  ActionPin,
  ActionCopyLink,
  ActionShare,
  SopActionNewExperiment,
  SopActionNewVersion,
  ActionClone,
  ActionInstantiate,
  ActionGenerateTemplate,
  MasterNoteSectionActionHideDescription,
  MasterNoteSectionActionHideItems,
  MasterNoteItemActionMoveSection,
  MasterNoteItemActionMoveUp,
  MasterNoteItemActionMoveDown,
  ActionExport,
  MasterNoteItemActionRemove,
  MasterNoteItemActionDelete,
  ActionDelete,
  ActionRestore,
};

export { default as BaseAction } from './BaseAction';
export { default as MenuItemAction, MenuItemLinkAction } from './MenuItemAction';
export { default as ButtonAction, ButtonLinkAction } from './ButtonAction';
export { default as IconButtonAction, IconButtonLinkAction } from './IconButtonAction';
export { default as EntityActionState } from './EntityActionState';

const directoryItemGenericIdProp = new Prop({ id: 'object_id', type: 'content_type' });

const ENTITY_ACTIONS = [
  {
    id: ENTITY_ACTION_RESTORE,
    component: ActionRestore,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => entitySelectors.selectEntity(state, getGenericId(id, type), 'is_deleted'),
  },
  {
    id: ENTITY_ACTION_INSTANTIATE,
    component: ActionInstantiate,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => entitySelectors.selectEntityPermission(state, getGenericId(id, type), ACTION_INSTANTIATE),
  },
  {
    id: ENTITY_ACTION_OPEN,
    component: ActionOpen,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type) => !isNil(getPath(getGenericId(id, type))),
  },
  {
    id: ENTITY_ACTION_MOVE,
    component: ActionMove,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => {
      const genericId = getGenericId(id, type);
      return !entitySelectors.selectEntity(state, genericId, 'is_deleted') && !isNil(selectMoveToDirectoryType(state, genericId));
    },
  },
  {
    id: ENTITY_ACTION_PIN,
    component: ActionPin,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => {
      const genericId = getGenericId(id, type);
      return (
        [ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK].includes(type) &&
        !entitySelectors.selectEntity(state, genericId, 'is_deleted')
      );
    },
  },
  {
    id: ENTITY_ACTION_COPY_LINK,
    component: ActionCopyLink,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type) => !isNil(getPath(getGenericId(id, type))),
  },
  {
    id: ENTITY_ACTION_SHARE,
    component: ActionShare,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type) => [ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK].includes(type),
  },
  {
    id: SOP_ACTION_NEW_EXPERIMENT,
    component: SopActionNewExperiment,
    propGenerator: (id) => ({ initState: { related_sop: id }}),
    condition: (id, type, state) => {
      if (type !== ENTITY_SOP) return false;
      const isDeleted = sopSelectors.selectSop(state, id, 'is_deleted');
      const isTemplate = sopSelectors.selectSop(state, id, 'is_template');
      const isPublished = sopSelectors.selectSop(state, id, 'is_published');
      const canCreateExp = permissionSelectors.selectMyGlobalPermission(state, ENTITY_EXPERIMENT, ACTION_CREATE);
      return !isDeleted && !isTemplate && isPublished && canCreateExp;
    },
  },
  {
    id: SOP_ACTION_NEW_VERSION,
    component: SopActionNewVersion,
    propGenerator: (id) => ({ id }),
    condition: (id, type, state) => type === ENTITY_SOP && sopSelectors.selectSopPermission(state, id, ACTION_NEW_VERSION),
  },
  {
    id: ENTITY_ACTION_CLONE,
    component: ActionClone,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => entitySelectors.selectEntityPermission(state, getGenericId(id, type), ACTION_CLONE),
  },
  {
    id: ENTITY_ACTION_GENERATE_TEMPLATE,
    component: ActionGenerateTemplate,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => entitySelectors.selectEntityPermission(state, getGenericId(id, type), ACTION_GENERATE_TEMPLATE),
  },
  ...([
    { actionId: MASTER_NOTE_SECTION_HIDE_DESCRIPTION, component: MasterNoteSectionActionHideDescription },
    { actionId: MASTER_NOTE_SECTION_HIDE_ITEMS, component: MasterNoteSectionActionHideItems },
  ].map(({ actionId, component }) => ({
    id: actionId,
    component,
    propGenerator: (id) => ({ id }),
    condition: (id, type, state) => {
      if (type !== ENTITY_MASTER_NOTE_SECTION) return false;
      const masterNote = directorySelectors.selectMasterNoteSection(state, id, 'master_note');
      return directorySelectors.selectMasterNotePermission(state, masterNote, ACTION_UPDATE);
    },
  }))),
  ...([
    { actionId: MASTER_NOTE_ITEM_MOVE_UP, component: MasterNoteItemActionMoveUp },
    { actionId: MASTER_NOTE_ITEM_MOVE_DOWN, component: MasterNoteItemActionMoveDown },
    { actionId: MASTER_NOTE_ITEM_MOVE_SECTION, component: MasterNoteItemActionMoveSection },
  ].map(({ actionId, component }) => ({
    id: actionId,
    component,
    propGenerator: (id) => ({ id }),
    condition: (id, type, state) => {
      if (type !== ENTITY_MASTER_NOTE_ITEM) return false;
      const masterNoteSection = directorySelectors.selectMasterNoteItem(state, id, 'master_note_section');
      const masterNote = directorySelectors.selectMasterNoteSection(state, masterNoteSection, 'master_note');
      return directorySelectors.selectMasterNotePermission(state, masterNote, ACTION_UPDATE);
    },
  }))),
  {
    id: ENTITY_ACTION_EXPORT,
    component: ActionExport,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => (
      [ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK].includes(type) &&
      entitySelectors.selectEntityPermission(state, getGenericId(id, type), ACTION_VIEW)
    ),
  },
  {
    id: MASTER_NOTE_ITEM_REMOVE,
    component: MasterNoteItemActionRemove,
    propGenerator: (id) => ({ id }),
    condition: (id, type, state) => {
      if (type !== ENTITY_MASTER_NOTE_ITEM) return false;
      const masterNoteSection = directorySelectors.selectMasterNoteItem(state, id, 'master_note_section');
      const masterNote = directorySelectors.selectMasterNoteSection(state, masterNoteSection, 'master_note');
      return directorySelectors.selectMasterNotePermission(state, masterNote, ACTION_UPDATE);
    },
  },
  {
    id: MASTER_NOTE_ITEM_DELETE,
    component: MasterNoteItemActionDelete,
    propGenerator: (id) => ({ id }),
    condition: (id, type, state) => {
      if (type !== ENTITY_MASTER_NOTE_ITEM) return false;
      const directoryItem = directorySelectors.selectMasterNoteItem(state, id, 'directory_item');
      const genericId = directorySelectors.selectDirectoryItem(state, directoryItem, directoryItemGenericIdProp);

      const masterNoteSection = directorySelectors.selectMasterNoteItem(state, id, 'master_note_section');
      const masterNote = directorySelectors.selectMasterNoteSection(state, masterNoteSection, 'master_note');

      return (
        entitySelectors.selectEntityPermission(state, genericId, ACTION_DELETE) &&
        directorySelectors.selectMasterNotePermission(state, masterNote, ACTION_UPDATE)
      );
    },
  },
  {
    id: ENTITY_ACTION_DELETE,
    component: ActionDelete,
    propGenerator: (id, type) => ({ id, type }),
    condition: (id, type, state) => {
      if (type === ENTITY_SOP_SUBSECTION) {
        const section = sopSelectors.selectSubsection(state, id, 'section');
        const sop = sopSelectors.selectSection(state, section, 'sop');
        return sopSelectors.selectSopPermission(state, sop, ACTION_UPDATE);
      }
      if (type === ENTITY_SOP_SECTION) {
        const sop = sopSelectors.selectSection(state, id, 'sop');
        return sopSelectors.selectSopPermission(state, sop, ACTION_UPDATE);
      }
      if (type === ENTITY_MASTER_NOTE_SECTION) {
        const masterNote = directorySelectors.selectMasterNoteSection(state, id, 'master_note');
        return directorySelectors.selectMasterNotePermission(state, masterNote, ACTION_UPDATE);
      }
      return entitySelectors.selectEntityPermission(state, getGenericId(id, type), ACTION_DELETE);
    },
  },
];

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const selectEntityActionsCurried = createSelector(
  (id) => id,
  (id, type) => type,
  (id, type, options) => options?.exclude ?? EMPTY_ARRAY,
  (id, type, options) => options?.actionPropsMap ?? EMPTY_OBJECT,
  (id, type, exclude, actionPropsMap) => createSelector(
    ...ENTITY_ACTIONS.map(({ condition }) => (state) => condition(id, type, state)),
    (...conditions) => {
      const { actions } = ENTITY_ACTIONS.reduce((result, action, idx) => {
        if (conditions[idx]) {
          result.actions.push({
            id: action.id,
            component: action.component,
            props: { ...action.propGenerator(id, type), ...actionPropsMap[action.id] },
          });
        }
        return result;
      }, { actions: []});
      return actions.filter((action) => !exclude.includes(action.id));
    },
  ),
);
export const selectEntityActions = (state, id, type, options) => selectEntityActionsCurried(id, type, options)(state);

export const useEntityActions = createSelectorHook(selectEntityActions);
