import { ENTITY_SOP, ENTITY_SOP_SUBSECTION } from 'constants/schemas';
import { EntityActionState, useEntityActions } from 'components/EntityActions';
import { VIEW_DATA, VIEW_MATERIAL, VIEW_NEW_SECTION, VIEW_PROCEDURE, VIEW_QC } from 'constants/sop.constants';
import { useEntityId, useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useSop, useSopSection, useSopSubsection, useUpdateEntity } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Field from 'components/Field';
import { NewComment } from 'components/Comment';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from 'components/SectionHeader';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';
import { usePreviewMode } from './usePreview';

const toolbarProps = { sx: { top: 108 }, elevation: 2 };

function SubsectionDetails(props) {
  const { subsectionId } = props;
  const sopId = useEntityId();
  const subsection = useSopSubsection(subsectionId);
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(sopId, ENTITY_SOP);
  const { previewMode } = usePreviewMode();

  const onTimeChange = (_, value) => {
    update({ type: ENTITY_SOP_SUBSECTION, id: subsectionId, key: 'estimated_time', value });
  };

  const onDescriptionChange = (value) => {
    update({ type: ENTITY_SOP_SUBSECTION, id: subsectionId, key: 'description', value });
  };

  return (
    <>
      <Field
        label='Estimated Time (hours)'
        value={subsection.estimated_time}
        variant={Field.VARIANTS.TEXT_FIELD}
        readOnly={isReadOnly}
        textFieldProps={{ onChange: onTimeChange, type: 'number' }}
      />
      <Field
        label='Description'
        value={subsection.description}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly={isReadOnly}
        interactable={!isReadOnly || previewMode}
        onChangeSerialized={previewMode ? null : onDescriptionChange}
        onChange={previewMode ? null : editorChangeHandler}
        serializeOnBlur={false}
        withVariables
        sx={!isReadOnly ? { minHeight: 58 } : {}}
        toolbarProps={toolbarProps}
        valueProps={{
          key: `section${subsectionId}DescriptionRTE_${previewMode}`, /* Force re-render when preview mode changes */
          containerProps: { 'data-test': 'sop-subsection-description' },
        }}
      />
    </>
  );
}
SubsectionDetails.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};


function SubsectionStepDefinition(props) {
  const { subsectionId } = props;
  const sopId = useEntityId();
  const subsectionDefinition = useSopSubsection(subsectionId, 'definition');
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(sopId, ENTITY_SOP);
  const { previewMode } = usePreviewMode();

  const onChange = (value) => {
    update({ type: ENTITY_SOP_SUBSECTION, id: subsectionId, key: 'definition', value });
  };

  return (
    <Field
      label='Step Definition'
      value={subsectionDefinition}
      variant={Field.VARIANTS.TEXT_EDITOR}
      readOnly={isReadOnly}
      interactable={!isReadOnly || previewMode}
      onChangeSerialized={previewMode ? null : onChange}
      onChange={previewMode ? null : editorChangeHandler}
      serializeOnBlur={false}
      withVariables
      sx={!isReadOnly ? { minHeight: 200 } : {}}
      toolbarProps={toolbarProps}
      valueProps={{
        key: `section${subsectionId}DefinitionRTE_${previewMode}`, /* Force re-render when preview mode changes */
        containerProps: { 'data-test': 'sop-subsection-step-definition' },
      }}
    />
  );
}
SubsectionStepDefinition.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};


function MaterialsSubsection(props) {
  const { subsectionId } = props;
  const sopId = useEntityId();
  const subsection = useSopSubsection(subsectionId);
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(sopId, ENTITY_SOP);
  const { previewMode } = usePreviewMode();

  const onChangeFactory = (key) => (value) => {
    update({ type: ENTITY_SOP_SUBSECTION, id: subsectionId, key, value });
  };

  return (
    <>
      <Field
        value={subsection.description}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly={isReadOnly}
        interactable={!isReadOnly || previewMode}
        onChangeSerialized={previewMode ? null : onChangeFactory('description')}
        onChange={previewMode ? null : editorChangeHandler}
        serializeOnBlur={false}
        withVariables
        sx={[
          { mt: 1 },
          !isReadOnly ? { minHeight: 200 } : {},
        ]}
        toolbarProps={toolbarProps}
        valueProps={{
          key: `section${subsectionId}DescriptionRTE_${previewMode}`, /* Force re-render when preview mode changes */
          containerProps: { 'data-test': 'sop-materials-description' },
        }}
      />
      <Field
        label='Additional Details'
        value={subsection.definition}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly={isReadOnly}
        interactable={!isReadOnly || previewMode}
        onChangeSerialized={previewMode ? null : onChangeFactory('definition')}
        onChange={previewMode ? null : editorChangeHandler}
        serializeOnBlur={false}
        withVariables
        sx={!isReadOnly ? { minHeight: 58 } : {}}
        toolbarProps={toolbarProps}
        valueProps={{
          key: `section${subsectionId}DefinitionRTE_${previewMode}`, /* Force re-render when preview mode changes */
          containerProps: { 'data-test': 'sop-materials-definition' },
        }}
      />
    </>
  );
}
MaterialsSubsection.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};


function SopSubsection(props) {
  const { subsectionId } = props;
  const subsection = useSopSubsection(subsectionId);
  const sopId = useEntityId();
  const sectionIds = useSop(sopId, 'sections');
  const subsectionIds = useSopSection(subsection.section, 'subsections');
  const sectionSequence = (sectionIds ?? []).findIndex((_id) => String(_id) === String(subsection.section));
  const subsectionSequence = (subsectionIds ?? []).findIndex((_id) => String(_id) === String(subsectionId));
  const sectionViewId = useSopSection(subsection.section, 'view_id');
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const { previewMode } = usePreviewMode();

  const onEdit = (value) => {
    if ((value ?? '').length > 0 && value !== subsection.title) {
      update({ type: ENTITY_SOP_SUBSECTION, id: subsectionId, key: 'title', value });
    }
  };

  const extraActions = useEntityActions(subsectionId, ENTITY_SOP_SUBSECTION);

  return (
    <Box sx={{ my: 1, px: 3 }} data-test='sop-subsection-container'>
      <Box id={`subsection_${subsectionId}`} sx={{ position: 'relative', top: -60 }} /> {/** Accounts for sticky section header */}
      <EntityActionState>
        <SectionHeader
          sticky
          title={subsection.title}
          prefix={`${sectionSequence + 2}.${subsectionSequence + 1}.`}
          variant='h5'
          onEdit={!isReadOnly ? onEdit : null}
          extraActions={{ inMenu: extraActions }}
          stickyContainerProps={{ sx: {
            top: 60, /* Accounts for sticky section header */
            zIndex: 'subheader',
          }}}
        />
      </EntityActionState>
      {
        sectionViewId === VIEW_DATA ? (
          <SubsectionStepDefinition subsectionId={subsectionId} />
        ) : sectionViewId === VIEW_MATERIAL ? (
          <MaterialsSubsection subsectionId={subsectionId} />
        ) : [VIEW_NEW_SECTION, VIEW_PROCEDURE, VIEW_QC].includes(sectionViewId) ? (
          <>
            <SubsectionDetails subsectionId={subsectionId} />
            <SubsectionStepDefinition subsectionId={subsectionId} />
          </>
        ) : null
      }
      { previewMode ? (/* Dummy comment section for preview mode */
        <Field label='Documentation'>
          <NewComment
            containerProps={{ sx: { mt: 1 }}}
            customEditorProps={{ disabled: true }}
          />
        </Field>
      ) : null}
    </Box>
  );
}

SopSubsection.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};

export default SopSubsection;
