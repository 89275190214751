import {
  DndContext,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ENTITY_MASTER_NOTE_ITEM, ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMasterNote, useMasterNoteSection, useUpdateEntity } from 'hooks/store.hooks';

import PropTypes from 'prop-types';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

// Note: Adapted from https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx
function MasterNoteItemDndContext(props) {
  const { id: masterNoteId, children } = props;
  const update = useUpdateEntity();
  const sections = useMasterNote(masterNoteId, 'sections');
  const itemsList = useMasterNoteSection(sections, 'items');
  const items = useMemo(() => Object.fromEntries((sections ?? []).map((sectionId, idx) => [sectionId, itemsList[idx]])), [sections, itemsList]);

  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewSection = useRef(false);

  const collisionDetectionStrategy = useCallback((args) => {
    const pointerIntersections = pointerWithin(args);
    const intersections = pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args);
    let overId = getFirstCollision(intersections, 'id');

    if (isDefined(overId)) {
      if (sections.includes(overId)) {
        const sectionItems = items[overId];

        // If a section is matched and it contains items
        if (sectionItems.length > 0) {
          // Return the closest droppable within that section
          overId = closestCenter({
            ...args,
            droppableContainers: args.droppableContainers.filter((container) => (
              container.id !== overId &&
              sectionItems.includes(container.id)
            )),
          })[0]?.id;
        }
      }

      lastOverId.current = overId;
      return [{ id: overId }];
    }

    // When a draggable item moves to a new section, the layout may shift
    // and the `overId` may become `null`. We manually set the cached `lastOverId`
    // to the id of the draggable item that was moved to the new section, otherwise
    // the previous `overId` will be returned which can cause items to incorrectly shift positions
    if (recentlyMovedToNewSection.current) {
      lastOverId.current = activeId;
    }

    // If no droppable is matched, return the last match
    return lastOverId.current ? [{ id: lastOverId.current }] : [];
  }, [activeId, sections, items]);

  const activationConstraint = { distance: 10 };
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
  );

  const findSection = (id) => {
    if (sections.includes(id)) return id;
    return sections.find((section) => items[section].includes(id));
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewSection.current = false;
    });
  }, [items]);

  const onDragStart = ({ active }) => {
    setActiveId(active.id);
  };

  const onDragOver = (event) => {
    const { active, over } = event;
    if (!isDefined(over?.id) || !isDefined(active?.id)) return;

    const overSection = findSection(over.id);
    const activeSection = findSection(active.id);
    if (!isDefined(overSection) || !isDefined(activeSection)) return;

    if (activeSection !== overSection) {
      const activeItems = [...items[activeSection]];
      const overItems = [...items[overSection]];
      const activeIdx = activeItems.indexOf(active.id);
      recentlyMovedToNewSection.current = true;

      const newIdx = overItems.length;
      activeItems.splice(activeIdx, 1);
      overItems.splice(newIdx, 0, active.id);
      update({ type: ENTITY_MASTER_NOTE_ITEM, id: active.id, key: 'master_note_section', value: overSection });
      update({ type: ENTITY_MASTER_NOTE_SECTION, id: activeSection, key: 'items', value: activeItems });
      update({ type: ENTITY_MASTER_NOTE_SECTION, id: overSection, key: 'items', value: overItems });
    }
  };

  const onDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);
    if (!isDefined(over?.id) || !isDefined(active?.id)) return;

    const overSection = findSection(over.id);
    const activeSection = findSection(active.id);
    if (!isDefined(overSection) || !isDefined(activeSection)) return;

    if (activeSection === overSection) {
      const sectionId = activeSection;
      const activeIdx = items[sectionId].indexOf(active.id);
      const overIdx = items[sectionId].indexOf(over.id);
      if (activeIdx !== overIdx) {
        const reorderedItems = arrayMove([...items[sectionId]], activeIdx, overIdx);
        update({ type: ENTITY_MASTER_NOTE_SECTION, id: sectionId, key: 'items', value: reorderedItems });
      }
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      modifiers={[restrictToVerticalAxis]}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
    >
      <SortableContext
        items={sections ?? []}
        strategy={verticalListSortingStrategy}
      >
        { children }
      </SortableContext>
    </DndContext>
  );
}

MasterNoteItemDndContext.propTypes = {
  id: PropTypes.number,
  children: PropTypes.node,
};

export default MasterNoteItemDndContext;
