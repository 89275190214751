import { Button, TextField } from '@acheloisbiosoftware/absui.core';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function ForgotPasswordForm({ emailState, errorState, onSubmit }) {
  const { email, setEmail } = emailState;
  const { error, setError } = errorState;

  const handleChange = (event) => {
    setEmail(event.target.value);
    setError('');
  };

  return (
    <Box component='form' onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography variant='h4'>Forgot password?</Typography>
        <Typography>No worries, we'll send you resent instructions.</Typography>
        <TextField
          label='Email'
          value={email}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
          data-test='email'
        />
        <Button
          variant='contained'
          color='primary'
          onClick={onSubmit}
          type='submit'
          data-test='reset-password'
        >
          Reset Password
        </Button>
      </Stack>
    </Box>
  );
}

ForgotPasswordForm.propTypes = {
  emailState: PropTypes.shape({
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
  }).isRequired,
  errorState: PropTypes.shape({
    error: PropTypes.string.isRequired,
    setError: PropTypes.func.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
