import React, { useCallback, useEffect, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import NavDraggable from './NavDraggable';
import NavDroppable from './NavDroppable';
import NavSubsection from './NavSubsection';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function NavSection(props) {
  const { section, sectionSelector, subsectionSelector, collapsed } = props;
  const {
    id,
    icon,
    text,
    onClick,
    sx,
    draggable,
    subsections,
    onDragEnd,
    keepOpenOnCollapse,
    ...restProps
  } = useSelector((state) => sectionSelector(state, section));
  const hasSubsections = Boolean(subsections?.length);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!keepOpenOnCollapse && collapsed) setOpen(false);
  }, [collapsed, keepOpenOnCollapse]);

  const _onClick = useCallback((...args) => {
    if (hasSubsections && (!collapsed || keepOpenOnCollapse)) setOpen((prev) => !prev);
    onClick?.(...args);
  }, [collapsed, hasSubsections, keepOpenOnCollapse, onClick]);

  return (
    <NavDraggable
      {...restProps}
      id={id}
      icon={icon}
      text={text}
      onClick={_onClick}
      draggable={Boolean(draggable && !collapsed)}
      expandable={hasSubsections}
      isExpanded={open}
      sx={sx}
    >
      {
        hasSubsections ? (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List disablePadding>
              <NavDroppable
                items={subsections}
                onDragEnd={onDragEnd}
              >
                {
                  subsections.map((subsection) => (
                    <NavSubsection
                      key={`NavSubsection${subsection}`}
                      subsection={subsection}
                      subsectionSelector={subsectionSelector}
                      collapsed={collapsed}
                    />
                  ))
                }
              </NavDroppable>
            </List>
          </Collapse>
        ) : null
      }
    </NavDraggable>
  );
}

NavSection.propTypes = {
  section: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  sectionSelector: PropTypes.func.isRequired,
  subsectionSelector: PropTypes.func,
  collapsed: PropTypes.bool,
};

export default NavSection;
