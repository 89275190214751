import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import { Prop, directoryActions } from 'store/entity';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { genericIdEquals, genericIdToString } from 'utils/generic.utils';
import { useDirectory, useDirectoryItem, useMasterNoteItem } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Error from 'components/Error';
import Experiment from 'views/Experiment';
import Loading from 'components/Loading';
import Note from 'views/Note';
import PageContainer from 'components/PageContainer';
import PropTypes from 'prop-types';
import Sop from 'views/Sop';
import Stack from '@mui/material/Stack';
import Task from 'views/Task';
import WorkspaceContent from './WorkspaceContent';
import { isNil } from 'lodash';
import { parseFloatList } from 'utils/helpers';
import { scrollTo } from 'utils/dom.utils';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const ENTITY_DETAIL_MAP = {
  [ENTITY_DIRECTORY]: WorkspaceContent,
  [ENTITY_EXPERIMENT]: Experiment,
  [ENTITY_NOTE]: Note,
  [ENTITY_SOP]: Sop,
  [ENTITY_TASK]: Task,
};

const genericIdProp = new Prop({ id: 'object_id', type: 'content_type' });
const getContentContainerId = (genericId) => `content_${genericIdToString(genericId)}`;

function Workspace(props) {
  const { id: rootId, pageContainerProps } = props;
  const [fetchError, setFetchError] = useState(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const exploreStr = searchParams.get('explore') ?? '';
  const explore = useMemo(() => parseFloatList(exploreStr), [exploreStr]);
  const exploreDirectoryItems = useMasterNoteItem(explore, 'directory_item');
  const rootDirectoryItem = useDirectory(rootId, 'directory_item');
  const directoryItems = useMemo(
    () => [rootDirectoryItem, ...(exploreDirectoryItems ?? [])],
    [exploreDirectoryItems, rootDirectoryItem],
  );
  const rawGenericIds = useDirectoryItem(directoryItems, genericIdProp);
  const genericIds = useMemo(() => {
    const firstNonDirectoryIdx = rawGenericIds.findIndex((genericId) => genericId.type !== ENTITY_DIRECTORY);
    const end = firstNonDirectoryIdx === -1 ? rawGenericIds.length : firstNonDirectoryIdx + 1;
    return rawGenericIds.slice(0, end).filter((genericId) => !isNil(genericId.id) && !isNil(genericId.type));
  }, [rawGenericIds]);
  const previousLastGenericId = useRef(genericIds[genericIds.length - 1]);

  useEffect(() => {
    // Fixes issue where directory is not fetched when navigating directly to a
    // directory that has not been loaded yet.
    if (isNil(rootDirectoryItem)) {
      setFetchError(null);
      dispatch(directoryActions.fetchDirectory({ id: rootId })).then((res) => {
        if (!res.meta.aborted && res.meta.requestStatus === 'rejected') {
          const errors = Object.values(res.payload?.data ?? {});
          setFetchError(errors[0] ?? 'Unknown error...');
        }
      });
    }
  }, [rootDirectoryItem, rootId, dispatch]);

  useEffect(() => {
    // Automatically scroll to the latest content when it changes.
    const newLastGenericId = genericIds[genericIds.length - 1];
    if (!isNil(previousLastGenericId.current) && !genericIdEquals(previousLastGenericId.current, newLastGenericId)) {
      previousLastGenericId.current = newLastGenericId;
      scrollTo(getContentContainerId(newLastGenericId));
    }
  }, [genericIds]);

  return (
    <PageContainer
      component={Stack}
      direction='row'
      {...pageContainerProps}
    >
      { genericIds.map((genericId, idx) => {
        const { id, type } = genericId;
        const Component = ENTITY_DETAIL_MAP[type];
        const isLast = idx === genericIds.length - 1;
        const _props = {
          ...(!isLast ? {
            collapsed: true,
            selectedMasterNoteItem: explore[idx],
          } : {}),
          ...(type !== ENTITY_DIRECTORY ? {
            pageContainerProps: {
              sx: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
            },
          } : {}),
        };
        return !isNil(Component) && !isNil(id) ? (
          <Box
            key={getContentContainerId(genericId)}
            id={getContentContainerId(genericId)}
            sx={isLast ? { minWidth: 1 } : {}}
          >
            <Component id={id} {..._props} />
          </Box>
        ) : null;
      })}
      { genericIds.length === 0 && !fetchError ? (<Loading />) : null}
      { fetchError ? (<Error>{fetchError}</Error>) : null}
    </PageContainer>
  );
}

Workspace.propTypes = {
  id: PropTypes.number.isRequired,
  pageContainerProps: PropTypes.object,
};

export default Workspace;
