import { ConstantProp, GenericProp, SelectorProp, entitySelectors } from 'store/entity';
import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE, COL_TYPE_SELECT, COL_TYPE_USER, FILTER_TYPE_SELECT } from 'components/DataTable';
import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import { EntityActionState, selectEntityActions } from 'components/EntityActions';
import React, { useCallback, useMemo, useState } from 'react';
import { formatType, getIcon, getPath } from 'utils/entity.utils';
import { genericIdFromString, genericIdToString, getGenericId } from 'utils/generic.utils';
import { getDraggableProps, getDroppableProps } from 'components/DirectoryDndProvider';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { EntityPreview } from 'components/SidePreview';
import PropTypes from 'prop-types';
import Status from 'components/Status';
import Typography from '@mui/material/Typography';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useEntity } from 'hooks/store.hooks';
import { useNavigate } from 'react-router-dom';

const TEMPLATE_SUFFIX = '-template';
const createIconSelectorProp = (type) => new SelectorProp((state, entity) => entitySelectors.selectEntityIcon(state, getGenericId(entity?.id, type)));
const childrenItemProp = new GenericProp({
  [ENTITY_DIRECTORY]: {
    objectId: 'id',
    type: new ConstantProp(ENTITY_DIRECTORY),
    Icon: createIconSelectorProp(ENTITY_DIRECTORY),
    isTemplate: 'is_template',
    directory_item: 'directory_item',
    name: 'name',
    owner: 'created_by',
    created: 'created',
    permissions: 'permissions',
    master_note: 'master_note',
  },
  [ENTITY_EXPERIMENT]: {
    objectId: 'id',
    type: new ConstantProp(ENTITY_EXPERIMENT),
    Icon: createIconSelectorProp(ENTITY_EXPERIMENT),
    isTemplate: 'is_template',
    directory_item: 'directory_item',
    name: 'long_title',
    owner: 'created_by',
    status: 'status',
    created: 'created',
    permissions: 'permissions',
  },
  [ENTITY_NOTE]: {
    objectId: 'id',
    type: new ConstantProp(ENTITY_NOTE),
    Icon: createIconSelectorProp(ENTITY_NOTE),
    isTemplate: 'is_template',
    directory_item: 'directory_item',
    name: 'long_title',
    owner: 'created_by',
    created: 'created',
    permissions: 'permissions',
  },
  [ENTITY_SOP]: {
    objectId: 'id',
    type: new ConstantProp(ENTITY_SOP),
    Icon: createIconSelectorProp(ENTITY_SOP),
    isTemplate: 'is_template',
    directory_item: 'directory_item',
    name: 'long_title',
    owner: 'created_by',
    status: 'status',
    created: 'created',
    permissions: 'permissions',
  },
  [ENTITY_TASK]: {
    objectId: 'id',
    type: new ConstantProp(ENTITY_TASK),
    Icon: createIconSelectorProp(ENTITY_TASK),
    isTemplate: 'is_template',
    directory_item: 'directory_item',
    name: 'long_title',
    owner: 'created_by',
    status: 'status',
    created: 'created',
    permissions: 'permissions',
  },
});

function DocumentTable(props) {
  const { ids, columnProps, withSidePreview, ...restProps } = props;
  const navigate = useNavigate();
  const [previewState, setPreviewState] = useState({ open: false, id: null, type: null });

  const onRowClick = useCallback((row, event) => {
    const genericId = genericIdFromString(row.id);
    if (!withSidePreview || event.detail > 1) {
      navigate(getPath(genericId));
      return;
    }
    setPreviewState({ open: true, id: genericId.id, type: genericId.type });
    event.stopPropagation(); /* To prevent the preview from closing */
  }, [navigate, withSidePreview]);

  const onClose = useCallback(() => {
    setPreviewState((oldPreviewState) => ({ ...oldPreviewState, open: false }));
  }, []);

  const childrenItemObjects = useEntity(ids, childrenItemProp);

  const { rows } = useMemo(
    () => (childrenItemObjects ?? []).reduce((result, row) => {
      if (!row?.permissions?.[ACTION_VIEW]) return result;
      result.rows.push({
        ...row,
        id: genericIdToString(getGenericId(row.objectId, row.type)),
        droppableProps: row.type === ENTITY_DIRECTORY ? getDroppableProps(row, { idKey: 'objectId', key: 'DocumentTable' }) : null,
        draggableProps: getDraggableProps(row, row.type, { idKey: 'objectId', key: 'DocumentTable' }),
      });
      return result;
    }, { rows: []}),
    [childrenItemObjects],
  );

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      hidden: true,
      ...columnProps?.checkbox,
    },
    {
      id: 'type',
      label: 'Type',
      field: 'type',
      groupValueGetter: ({ row, value }) => `${value}${row.isTemplate ? TEMPLATE_SUFFIX : ''}`,
      filterValueGetter: ({ row, value }) => `${value}${row.isTemplate ? TEMPLATE_SUFFIX : ''}`,
      renderValue: ({ row }) => {
        const { Icon, isTemplate } = row;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            { Icon ? <Icon colored template={isTemplate} /> : null }
          </Box>
        );
      },
      renderGroupValue: ({ value }) => {
        const isTemplate = value.endsWith(TEMPLATE_SUFFIX);
        const type = isTemplate ? value.slice(0, -TEMPLATE_SUFFIX.length) : value;
        const Icon = getIcon(type);
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            { Icon ? <Icon colored template={isTemplate} /> : null }
            <Typography variant='subtitle2' sx={{ ml: 1 }} noWrap>
              {formatType(type, { template: isTemplate })}
            </Typography>
          </Box>
        );
      },
      cellProps: ({ isGrouping }) => (isGrouping ? {} : { sx: { width: 20, pr: 0 }}),
      filterType: FILTER_TYPE_SELECT,
      filterOptionProps: (value) => {
        const isTemplate = value.endsWith(TEMPLATE_SUFFIX);
        const type = isTemplate ? value.slice(0, -TEMPLATE_SUFFIX.length) : value;
        const Icon = getIcon(type);
        return {
          icon: Icon ? <Icon colored template={isTemplate} /> : null,
          label: formatType(type, { template: isTemplate }),
        };
      },
      groupable: true,
      noHeader: true,
      ...columnProps?.type,
    },
    {
      id: 'name',
      label: 'Name',
      field: 'name',
      renderValue: ({ value }) => value || 'Untitled',
      cellProps: { sx: { maxWidth: 360 }},
      primary: true,
      noWrap: true,
      ...columnProps?.name,
    },
    {
      id: 'owner',
      label: 'Owner',
      field: 'owner',
      type: COL_TYPE_USER,
      cellProps: { sx: { width: 150 }},
      secondary: true,
      noWrap: true,
      ...columnProps?.owner,
    },
    {
      id: 'status',
      label: 'Status',
      field: 'status',
      renderValue: ({ value }) => (isDefined(value) ? <Status>{value}</Status> : null),
      filterable: false,
      groupable: false,
      sortable: false,
      ...columnProps?.status,
    },
    {
      id: 'created',
      label: 'Created',
      field: 'created',
      type: COL_TYPE_DATE,
      groupable: true,
      cellProps: { sx: { width: 200 }},
      secondary: true,
      ...columnProps?.created,
    },
    {
      id: 'actions',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      inMenu: true,
      actions: ({ row, state }) => {
        const genericId = genericIdFromString(row.id);
        return selectEntityActions(state, genericId.id, genericId.type);
      },
      ...columnProps?.actions,
    },
  ], [columnProps]);

  return (
    <>
      <EntityActionState>
        <DataTable
          onRowClick={onRowClick}
          stateInSearchParams
          {...restProps}
          rows={rows}
          columns={columns}
          initState={{
            orderBy: 'created',
            order: 'desc',
            ...restProps?.initState,
          }}
        />
      </EntityActionState>
      { withSidePreview ? (
        <EntityPreview
          id={previewState.id}
          type={previewState.type}
          open={previewState.open}
          onClose={onClose}
        />
      ) : null}
    </>
  );
}

DocumentTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    type: PropTypes.oneOf([
      ENTITY_DIRECTORY,
      ENTITY_EXPERIMENT,
      ENTITY_NOTE,
      ENTITY_SOP,
      ENTITY_TASK,
    ]),
  })),
  columnProps: PropTypes.object,
  withSidePreview: PropTypes.bool,
};

DocumentTable.defaultProps = {
  ids: [],
  columnProps: {},
  withSidePreview: true,
};

export default DocumentTable;
