import PropTypes from 'prop-types';
import React from 'react';
import Task from 'views/Task';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

const pageContainerProps = { sx: { borderRadius: 0 }};

function TasksContent(props) {
  const { selected } = props;
  return !isDefined(selected) ? null : (
    <Task
      id={selected}
      pageContainerProps={pageContainerProps}
    />
  );
}

TasksContent.propTypes = {
  selected: PropTypes.number,
};

export default TasksContent;
