import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@acheloisbiosoftware/absui.core';

function FieldValueDefault(props) {
  const { value, setValue } = props;
  return (
    <TextField
      size='small'
      variant='standard'
      label='Value'
      value={value ?? ''}
      onChange={(_, newValue) => setValue(newValue)}
      sx={{ flexGrow: 1 }}
    />
  );
}

FieldValueDefault.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired,
};

export default FieldValueDefault;
