import { extraReducers, reducers } from './notification.reducers';

import { NOTIFICATION } from 'store/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './notification.initialState';

const slice = createSlice({
  name: NOTIFICATION,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export * as notificationSelectors from './notification.selectors';
export const notificationActions = { ...slice.actions };
export const { reducer: notificationReducer } = slice;
