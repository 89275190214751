import { extraReducers, reducers } from './scope.reducers';

import { SCOPE } from 'store/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './scope.initialState';

const slice = createSlice({
  name: SCOPE,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export * as scopeSelectors from './scope.selectors';
export const scopeActions = { ...slice.actions };
export const { reducer: scopeReducer } = slice;
