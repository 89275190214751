import { SLICES_TO_CACHE, getLocalStoreSliceId } from 'store/store.constants';

import { eventListenerEffect } from '@acheloisbiosoftware/absui.hooks';
import { localStore } from 'utils/localStorage.utils';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const cacheStore = (dispatch, getState) => {
  const state = getState();
  for (const slice of SLICES_TO_CACHE) {
    localStore.save(getLocalStoreSliceId(slice), state[slice]);
  }
};

const useCachedStore = () => {
  const dispatch = useDispatch();
  useEffect(() => eventListenerEffect(window, 'beforeunload', () => {
    dispatch(cacheStore);
  }), [dispatch]);
};

export default useCachedStore;
