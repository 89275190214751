import * as ExperimentService from 'services/ExperimentService';
import * as experimentSelectors from './experiment.selectors';
import * as schemas from 'constants/schemas';

import { createServiceThunk } from 'store/store.actions.utils';

export const fetchExperiments = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/fetchExperiments`,
  service: ExperimentService.fetchExperiments,
  isPaginated: true,
  schema: [schemas.experiment],
});

export const createExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/createExperiment`,
  service: ExperimentService.createExperiment,
  schema: schemas.experiment,
});

export const fetchExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/fetchExperiment`,
  service: ExperimentService.fetchExperiment,
  schema: schemas.experiment,
});

export const patchExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/patchExperiment`,
  service: ExperimentService.patchExperiment,
  schema: schemas.experiment,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: experimentSelectors.selectExperimentPatchData(getState(), id),
  }),
});

export const deleteExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/deleteExperiment`,
  service: ExperimentService.deleteExperiment,
  schema: schemas.experiment,
});

export const deleteExperimentPermanently = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/deleteExperimentPermanently`,
  service: ExperimentService.deleteExperimentPermanently,
  schema: schemas.experiment,
});

export const checkinExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/checkinExperiment`,
  service: ExperimentService.checkinExperiment,
  schema: schemas.experiment,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: experimentSelectors.selectExperimentPatchData(getState(), id),
  }),
});

export const checkoutExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/checkoutExperiment`,
  service: ExperimentService.checkoutExperiment,
  schema: schemas.experiment,
});

export const cloneExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/cloneExperiment`,
  service: ExperimentService.cloneExperiment,
  schema: schemas.experiment,
});

export const completeExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/completeExperiment`,
  service: ExperimentService.completeExperiment,
  schema: schemas.experiment,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: experimentSelectors.selectExperimentPatchData(getState(), id),
  }),
});

export const generateExperimentTemplate = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/generateExperimentTemplate`,
  service: ExperimentService.generateExperimentTemplate,
  schema: schemas.experiment,
});

export const instantiateExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/instantiateExperiment`,
  service: ExperimentService.instantiateExperiment,
  schema: schemas.experiment,
});

export const restoreExperiment = createServiceThunk({
  name: `${schemas.ENTITY_EXPERIMENT}/restoreExperiment`,
  service: ExperimentService.restoreExperiment,
  schema: schemas.experiment,
});
