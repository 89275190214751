import { Button, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { eventListenerEffect } from '@acheloisbiosoftware/absui.hooks';

function InputDefinition(props) {
  const {
    anchorEl,
    open,
    onClose,
    formState,
  } = props;
  const { label, setLabel, inputType, setInputType, units, setUnits } = formState;

  useEffect(() => eventListenerEffect(document, 'keydown', (event) => {
    if (open) {
      if (event.key === 'Enter') {
        onClose(false);
        event.preventDefault();
      } else if (event.key === 'Escape') {
        onClose(true);
      }
    }
  }), [open, onClose]);

  const gridItemSx = { display: 'flex' };
  const labelSx = { ml: 'auto', mr: 1, my: 'auto' };
  const inputSx = { my: 'auto' };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => onClose(true)}
      sx={{ zIndex: 'modal' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        evelation: 8,
        sx: { p: 1, width: 300 },
        onMouseDown: (event) => event.stopPropagation(), /* Allow for focus */
      }}
    >
      <Typography variant='h6'>Define Input Parameters:</Typography>
      <Grid container spacing={1} sx={{ p: 1 }}>
        <Grid item xs={3} sx={gridItemSx}>
          <Typography sx={labelSx}>Label:</Typography>
        </Grid>
        <Grid item xs={9} sx={gridItemSx}>
          <TextField
            value={label}
            onChange={(_, newValue) => setLabel(newValue)}
            size='small'
            sx={[{ width: 1 }, inputSx]}
            variant='standard'
          />
        </Grid>
        <Grid item xs={3} sx={gridItemSx}>
          <Typography sx={labelSx}>Type:</Typography>
        </Grid>
        <Grid item xs={9} sx={gridItemSx}>
          <ToggleButtonGroup
            exclusive
            value={inputType}
            onChange={(_, newValue) => setInputType(newValue ?? inputType)}
            size='small'
            color='primary'
            sx={inputSx}
          >
            <ToggleButton value='text'>Text</ToggleButton>
            <ToggleButton value='number'>Number</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={3} sx={gridItemSx}>
          <Typography sx={labelSx}>Units:</Typography>
        </Grid>
        <Grid item xs={9} sx={gridItemSx}>
          <TextField
            value={units}
            onChange={(_, newValue) => setUnits(newValue)}
            size='small'
            sx={[{ width: 1 }, inputSx]}
            variant='standard'
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={3}>
          <Button
            onClick={() => onClose(true)}
            variant='text'
            sx={{ width: 1 }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={() => onClose(false)}
            variant='outlined'
            sx={{ width: 1 }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}

InputDefinition.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  formState: PropTypes.shape({
    label: PropTypes.string,
    setLabel: PropTypes.func,
    inputType: PropTypes.string,
    setInputType: PropTypes.func,
    units: PropTypes.string,
    setUnits: PropTypes.func,
  }).isRequired,
};

export default InputDefinition;
