import { HideIcon, ShowIcon } from 'constants/icon.constants';
import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import { ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import PropTypes from 'prop-types';
import { entityActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useMasterNoteSection } from 'hooks/store.hooks';

function MasterNoteSectionActionHideItems(props) {
  const { id, ...restProps } = props;
  const dispatch = useDispatch();
  const hideItems = useMasterNoteSection(id, 'hide_items');

  const onAction = useCallback(() => {
    dispatch(entityActions.update({
      type: ENTITY_MASTER_NOTE_SECTION,
      id,
      key: 'hide_items',
      value: !hideItems,
    }));
  }, [id, dispatch, hideItems]);

  return (
    <BaseAction
      icon={hideItems ? <ShowIcon /> : <HideIcon />}
      label={hideItems ? 'Show Items' : 'Hide Items'}
      onAction={onAction}
      {...restProps}
    />
  );
}

MasterNoteSectionActionHideItems.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default MasterNoteSectionActionHideItems;
