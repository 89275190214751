import { DIR_TYPES } from 'constants/directory.constants';
import NewDirectoryFormBlock from './NewDirectoryFormBlock';
import NewDirectoryFormInline from './NewDirectoryFormInline';
import PropTypes from 'prop-types';
import { RELATIONSHIP_PERMISSION_OPTIONS } from 'constants/permission.constants';
import React from 'react';

function NewDirectoryForm(props) {
  const {
    inline = false,
    formData = {},
    updateFormData,
    excludeFields = [],
    fieldPropsMap,
    containerProps,
  } = props;
  const FormComponent = inline ? NewDirectoryFormInline : NewDirectoryFormBlock;

  return (
    <FormComponent
      formData={formData}
      updateFormData={updateFormData}
      excludeFields={excludeFields}
      fieldPropsMap={fieldPropsMap}
      containerProps={containerProps}
    />
  );
}

NewDirectoryForm.propTypes = {
  inline: PropTypes.bool,
  formData: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.oneOf(DIR_TYPES),
    is_template: PropTypes.bool,
    permission_relationships: PropTypes.arrayOf(PropTypes.shape({
      individual_id: PropTypes.number,
      individual_content_type: PropTypes.string,
      permission: PropTypes.oneOf(RELATIONSHIP_PERMISSION_OPTIONS),
    })),
  }),
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['name', 'type', 'permission_relationships'])),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewDirectoryForm;
