import { extraReducers, reducers } from './preference.reducers';

import { PREFERENCE } from 'store/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './preference.initialState';

const slice = createSlice({
  name: PREFERENCE,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export * as preferenceSelectors from './preference.selectors';
export const preferenceActions = { ...slice.actions };
export const { reducer: preferenceReducer } = slice;
