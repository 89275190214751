import { useUser, useUserHasLoaded, useUserName } from 'hooks/store.hooks';

import { AdminIcon } from 'constants/icon.constants';
import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { ENTITY_USER } from 'constants/schemas';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function UserHeader(props) {
  const { id } = props;
  const name = useUserName(id);
  const email = useUser(id, 'email');
  const emailHasLoaded = useUserHasLoaded(id, 'email');
  const isSuperuser = useUser(id, 'is_superuser');
  const isStaff = useUser(id, 'is_staff');
  const isAdmin = isSuperuser || isStaff;
  return (
    <Box sx={{ display: 'flex' }}>
      <Avatar size={64} id={id} type={ENTITY_USER} sx={{ my: 'auto' }} />
      <Box sx={{ my: 'auto', mx: 2 }}>
        <Typography
          variant='h5'
          sx={{ lineHeight: 1.2 }}
        >
          {name ?? <Skeleton width={150} />}
        </Typography>
        <Typography
          variant='subtitle2'
          color='text.secondary'
        >
          {emailHasLoaded ? email : <Skeleton width={200} />}
        </Typography>
      </Box>
      { isAdmin ? (
        <Chip
          icon={<AdminIcon />}
          label='Admin'
          sx={{ my: 'auto', mr: 0, ml: 'auto' }}
        />
      ) : null}
    </Box>
  );
}

UserHeader.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserHeader;
