import { ENTITY, getLocalStoreSliceId } from 'store/store.constants';

import { ALL_ENTITY_TYPES } from 'constants/schemas';
import { localStore } from 'utils/localStorage.utils';

export const getInitialEntityState = () => ({
  byId: {},
  allIds: [],
  updates: {},
});

export const getInitialState = () => ({
  ...Object.fromEntries(ALL_ENTITY_TYPES.map((entityType) => [
    entityType, getInitialEntityState(),
  ])),
  ...localStore.load(getLocalStoreSliceId(ENTITY)) ?? {},
});
