import { round } from '@acheloisbiosoftware/absui.utils';

export function mergeUniqueArr(a1, a2, getKeyFn = (x) => x) {
  const merged = [...a1];
  for (const value of a2) {
    if (!merged.some((el) => getKeyFn(el) === getKeyFn(value))) {
      merged.push(value);
    }
  }
  return merged;
}

export const getFileSize = (size) => {
  if (size < 1024) return `${size} B`;
  if (size < 1024 ** 2) return `${round(size / 1024, 0.1)} KB`;
  if (size < 1024 ** 3) return `${round(size / (1024 ** 2), 0.1)} MB`;
  return `${round(size / (1024 ** 3), 0.1)} GB`;
};

export const parseFloatList = (listString) => (listString ?? '').split(',').map((id) => parseFloat(id)).filter((id) => !isNaN(id));
