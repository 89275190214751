import React, { useCallback, useMemo, useState } from 'react';
import { RowHoverContext, useColumns, useExpandedRows, useGroupByState, useRow } from './DataTableContext';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useDraggable, useDroppable } from '@dnd-kit/core';

import Cell from './Cell';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const RowContents = React.memo((props) => {
  const {
    rowId,
    onClick,
    isLastRow,
    setNodeRef,
    cellProps,
    dndProps,
    ...restProps
  } = props;
  const row = useRow(rowId);
  const columns = useColumns();
  const { id, rowProps, expandedChildren } = row;
  const selected = columns.some((col) => col.isPrimarySelection && col.selection.includes(id));
  const [hover, setHover] = useState(false);

  const {
    draggable,
    droppable,
    droppableIsOver,
    setDroppableNodeRef,
    draggableIsDragging,
    draggableAttributes,
    draggableListeners,
    setDraggableNodeRef,
  } = dndProps;
  const dndIsOver = droppable && droppableIsOver;
  const dndIsDragging = draggable && draggableIsDragging;
  const showOutline = dndIsOver && !draggableIsDragging;

  const expandedRows = useExpandedRows();
  const expanded = expandedRows?.includes(id);
  const { groupBy } = useGroupByState();
  const isGrouping = isDefined(groupBy);
  const expandedCellSx = [
    { p: 0 },
    isLastRow ? { borderBottom: 0 } : {},
  ];

  const _setNodeRef = useCallback((...args) => {
    setNodeRef?.(...args);
    setDroppableNodeRef(...args);
    setDraggableNodeRef(...args);
  }, [setNodeRef, setDroppableNodeRef, setDraggableNodeRef]);

  const _onClick = useCallback((event) => onClick?.(row, event), [onClick, row]);

  const rowSx = useMemo(() => mergeSx(
    dndIsDragging ? { opacity: 0.5 } : {},
    showOutline ? {
      boxShadow: (theme) => `inset 0px 0px 0px 2px ${theme.palette.secondary.main}`,
    } : {},
    isDefined(onClick) ? { cursor: 'pointer' } : {},
    restProps?.sx,
    rowProps?.sx,
  ), [dndIsDragging, showOutline, onClick, restProps?.sx, rowProps?.sx]);

  const cellSx = useMemo(() => mergeSx(
    isLastRow || expandedChildren ? { borderBottom: 0 } : {},
    showOutline ? {
      borderColor: 'transparent',
    } : {},
    cellProps?.sx,
  ), [isLastRow, expandedChildren, showOutline, cellProps?.sx]);

  const _rowProps = useMemo(() => ({
    hover: true,
    selected,
    onClick: _onClick,
    ...restProps,
    ...rowProps,
    ...(draggable ? draggableAttributes : {}),
    ...(draggable ? draggableListeners : {}),
    sx: rowSx,
  }), [selected, _onClick, restProps, rowProps, draggable, draggableAttributes, draggableListeners, rowSx]);

  const onMouseEnter = useCallback((...args) => {
    setHover(true);
    rowProps?.onMouseEnter?.(...args);
  }, [rowProps]);

  const onMouseLeave = useCallback((...args) => {
    setHover(false);
    rowProps?.onMouseLeave?.(...args);
  }, [rowProps]);

  return (
    <RowHoverContext.Provider value={hover}>
      <TableRow
        ref={_setNodeRef}
        {..._rowProps}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        { columns.map((col) => (
          col.hidden && col.id !== groupBy ? null : (
            <Cell
              {...cellProps}
              key={`row${id}_col${col.id}`}
              rowId={id}
              columnId={col.id}
              sx={cellSx}
            />
          )
        ))}
      </TableRow>
      { expandedChildren ? (
        <TableRow>
          { isGrouping ? (
            <TableCell sx={expandedCellSx} />
          ) : null}
          <TableCell sx={expandedCellSx} colSpan={columns.filter((col) => !col.hidden).length - (isGrouping ? 1 : 0)}>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              { expandedChildren }
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </RowHoverContext.Provider>
  );
});

RowContents.displayName = 'RowContents';
RowContents.propTypes = {
  rowId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  isLastRow: PropTypes.bool,
  setNodeRef: PropTypes.func,
  cellProps: PropTypes.object,
  dndProps: PropTypes.shape({
    draggable: PropTypes.bool,
    droppable: PropTypes.bool,
    droppableIsOver: PropTypes.bool,
    setDroppableNodeRef: PropTypes.func,
    draggableIsDragging: PropTypes.bool,
    draggableAttributes: PropTypes.object,
    draggableListeners: PropTypes.object,
    setDraggableNodeRef: PropTypes.func,
  }),
};

function Row(props) {
  const {
    rowId,
    ...restProps
  } = props;
  const row = useRow(rowId);
  const { draggableProps, droppableProps } = row;

  const draggable = isDefined(draggableProps);
  const droppable = isDefined(droppableProps);
  const droppableOptions = useMemo(() => ({
    ...droppableProps,
    disabled: !droppable,
  }), [droppable, droppableProps]);
  const draggableOptions = useMemo(() => ({
    ...draggableProps,
    disabled: !draggable,
  }), [draggable, draggableProps]);

  const {
    isOver: droppableIsOver,
    setNodeRef: setDroppableNodeRef,
  } = useDroppable(droppableOptions);
  const {
    isDragging: draggableIsDragging,
    attributes: draggableAttributes,
    listeners: draggableListeners,
    setNodeRef: setDraggableNodeRef,
  } = useDraggable(draggableOptions);

  const dndProps = useMemo(() => ({
    draggable,
    droppable,
    droppableIsOver,
    setDroppableNodeRef,
    draggableIsDragging,
    draggableAttributes,
    draggableListeners,
    setDraggableNodeRef,
  }), [
    draggable,
    droppable,
    droppableIsOver,
    setDroppableNodeRef,
    draggableIsDragging,
    draggableAttributes,
    draggableListeners,
    setDraggableNodeRef,
  ]);

  return (
    <RowContents
      rowId={rowId}
      dndProps={dndProps}
      {...restProps}
    />
  );
}

Row.propTypes = {
  rowId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  isLastRow: PropTypes.bool,
  setNodeRef: PropTypes.func,
  cellProps: PropTypes.object,
};

export default Row;
