import { BLOCK_SEARCH, BLOCK_VARIABLE } from './FormulaEditor.constants';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import React, { useCallback, useState } from 'react';
import { generateVariable, getSearchText, withFormulas } from './FormulaPlugin';

import Box from '@mui/material/Box';
import { CalcUuidContext } from './hooks';
import { EditorPlugins } from '@acheloisbiosoftware/absui.core';
import Element from './Element';
import PropTypes from 'prop-types';
import { createEditor } from 'slate';
import { useEntityScope } from 'hooks/scope.hooks';
import { useScopeVariables } from 'hooks/store.hooks';
import { withHistory } from 'slate-history';

const { BaseEditor } = EditorPlugins;

const serializeValue = (fragment) => fragment.map((formulaBlock) => (
  formulaBlock.children.map((node) => {
    if (node.type === BLOCK_VARIABLE) return `¿${node.uuid}¿`;
    const text = node.type === BLOCK_SEARCH ? getSearchText(node) : node.text;
    return text.replace('¿', '');
  }).join('')
)).join('').trim();

const deserializeValue = (formula, scope) => {
  const children = formula.split('¿').map((segment, i) => {
    if (i % 2 === 0) return { text: segment };
    const dependency = scope[segment];
    if (dependency?.uuid) return generateVariable(dependency);
    return generateVariable({ label: '#REF!' });
  });
  if (children.length === 0) children.push({ text: '' });
  return [{ children }];
};

function FormulaEditor(props) {
  const { value, onChange, calcUuid } = props;
  /* eslint-disable-next-line react/hook-use-state */
  const [editor] = useState(() => withFormulas(withReact(withHistory(createEditor()))));
  const renderElement = useCallback((props_) => (<Element {...props_} />), []);
  const entityScope = useEntityScope();
  const scope = useScopeVariables(entityScope);
  const initialValue = deserializeValue(value, scope);

  const _onChange = useCallback((newValue) => {
    const isContentChange = editor.operations.some(
      (op) => op.type !== 'set_selection',
    );
    if (isContentChange) {
      onChange?.(serializeValue(newValue));
    }
  }, [editor.operations, onChange]);

  const focusEditor = useCallback((event) => {
    if (event.target === event.currentTarget) {
      ReactEditor.focus(editor);
      event.preventDefault();
    }
  }, [editor]);

  return (
    <Box
      sx={[
        {
          width: 1,
          border: 1,
          borderRadius: 1,
          borderColor: 'textfieldOutline',
          p: 1,
          boxSizing: 'border-box',
          cursor: 'text',
        },
        { ':hover': { borderColor: 'text.primary' }},
        {
          '&:focus-within': {
            'borderWidth': 2,
            'p': (theme) => `calc(${theme.spacing(1)} - 1px)`,
            ':hover': { borderColor: 'primary.main' },
            'borderColor': 'primary.main',
          },
        },
      ]}
      onMouseDown={focusEditor}
    >
      <CalcUuidContext.Provider value={calcUuid}>
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={_onChange}
        >
          <Box
            component={Editable}
            renderElement={renderElement}
            placeholder="Type '@' to insert variables..."
            onKeyDown={() => BaseEditor.keydownVoidBugFix(editor)}
            sx={{ outline: 0, width: 1 }}
          />
        </Slate>
      </CalcUuidContext.Provider>
    </Box>
  );
}

FormulaEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  calcUuid: PropTypes.string,
};

export default FormulaEditor;
