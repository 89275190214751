import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import { ENTITY_EXPERIMENT } from 'constants/schemas';
import { NewExperimentIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import { formatType } from 'utils/entity.utils';
import { useNewExperimentDialogContext } from './EntityActionState';

function SopActionNewExperiment(props) {
  const { initState, ...restProps } = props;
  const { setNewExperimentDialogProps } = useNewExperimentDialogContext();

  const onAction = useCallback(() => {
    setNewExperimentDialogProps?.((oldProps) => ({ ...oldProps, open: true, initState }));
  }, [initState, setNewExperimentDialogProps]);

  return (
    <BaseAction
      icon={<NewExperimentIcon />}
      label={`New ${formatType(ENTITY_EXPERIMENT)}`}
      onAction={onAction}
      {...restProps}
    />
  );
}

SopActionNewExperiment.propTypes = {
  initState: PropTypes.object,
};

export default SopActionNewExperiment;
