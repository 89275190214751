import { ACTION_CHECKIN, ACTION_CHECKOUT } from 'constants/permission.constants';
import { CheckinIcon, CheckoutIcon, RefreshIcon } from 'constants/icon.constants';
import { ENTITY_ATTACHMENT, ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import { GenericProp, attachmentActions, directoryActions, experimentActions, sopActions, taskActions } from 'store/entity';
import React, { useCallback, useState } from 'react';
import { useEntity, useEntityPermission, useMyId, useUserName } from 'hooks/store.hooks';

import Alert from '@mui/material/Alert';
import { Button } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { formatType } from 'utils/entity.utils';
import { intersection } from 'lodash';
import { useDispatch } from 'react-redux';
import { useGenericId } from 'utils/generic.utils';
import { useStageDelayedUpdates } from 'components/DelayedUpdateProvider';

const directoryTypeProp = new GenericProp({ [ENTITY_DIRECTORY]: 'type' }, { ignoreMissing: true });

const checkinActionMap = {
  [ENTITY_ATTACHMENT]: attachmentActions.checkinAttachment,
  [ENTITY_DIRECTORY]: directoryActions.checkinDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.checkinExperiment,
  [ENTITY_SOP]: sopActions.checkinSop,
  [ENTITY_TASK]: taskActions.checkinTask,
};

const checkoutActionMap = {
  [ENTITY_ATTACHMENT]: attachmentActions.checkoutAttachment,
  [ENTITY_DIRECTORY]: directoryActions.checkoutDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.checkoutExperiment,
  [ENTITY_SOP]: sopActions.checkoutSop,
  [ENTITY_TASK]: taskActions.checkoutTask,
};

const fetchActionMap = {
  [ENTITY_ATTACHMENT]: attachmentActions.fetchAttachment,
  [ENTITY_DIRECTORY]: directoryActions.fetchDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.fetchExperiment,
  [ENTITY_SOP]: sopActions.fetchSop,
  [ENTITY_TASK]: taskActions.fetchTask,
};

function CheckoutAlert(props) {
  const { id, type, ...restProps } = props;
  const dispatch = useDispatch();
  const stageDelayedUpdates = useStageDelayedUpdates();
  const genericId = useGenericId(id, type);
  const template = useEntity(genericId, 'is_template');
  const directoryType = useEntity(genericId, directoryTypeProp);
  const myId = useMyId();

  const canCheckout = useEntityPermission(genericId, ACTION_CHECKOUT);
  const canCheckin = useEntityPermission(genericId, ACTION_CHECKIN);
  const isCheckedOut = useEntity(genericId, 'is_checked_out');
  const checkedOutBy = useEntity(genericId, 'checked_out_by');
  const checkedOutByName = useUserName(checkedOutBy, 'full_name');

  const isMyCheckout = checkedOutBy === myId;

  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const checkout = useCallback(() => {
    setCheckoutLoading(true);
    const data = type === ENTITY_ATTACHMENT ? { uuid: id } : { id };
    dispatch(checkoutActionMap[type](data)).then(() => setCheckoutLoading(false));
  }, [dispatch, id, type]);

  const [checkinLoading, setCheckinLoading] = useState(false);
  const checkin = useCallback(() => {
    stageDelayedUpdates();
    setCheckinLoading(true);
    const data = type === ENTITY_ATTACHMENT ? { uuid: id } : { id };
    dispatch(checkinActionMap[type](data)).then(() => setCheckinLoading(false));
  }, [dispatch, id, type, stageDelayedUpdates]);

  const [refreshLoading, setRefreshLoading] = useState(false);
  const refresh = useCallback(() => {
    setRefreshLoading(true);
    const data = type === ENTITY_ATTACHMENT ? { uuid: id } : { id };
    dispatch(fetchActionMap[type](data)).then(() => setRefreshLoading(false));
  }, [dispatch, id, type]);

  if (!isCheckedOut) {
    return canCheckout ? (
      <Alert
        severity='info'
        action={(
          <Button
            variant='text'
            color='inherit'
            size='small'
            startIcon={<CheckoutIcon />}
            onClick={checkout}
            loading={checkoutLoading}
          >
            Checkout
          </Button>
        )}
        {...restProps}
      >
        Checkout this {formatType(type, { lowercase: true, template, directoryType })} to make changes.
      </Alert>
    ) : null;
  }
  return (
    <Alert
      color={isMyCheckout ? 'success' : 'warning'}
      severity='success'
      action={(
        <Stack direction='row' spacing={1}>
          { canCheckin ? (
            <Tooltip arrow title={!isMyCheckout ? `As an admin, you may force ${checkedOutByName} to checkin this ${formatType(type, { lowercase: true, template, directoryType })}.` : null}>
              <Button
                variant='text'
                color='inherit'
                size='small'
                startIcon={<CheckinIcon />}
                onClick={checkin}
                loading={checkinLoading}
              >
                {!isMyCheckout ? 'Force ' : ''} Checkin
              </Button>
            </Tooltip>
          ) : null}
          { isMyCheckout ? null : (
            <Button
              variant='text'
              color='inherit'
              size='small'
              startIcon={<RefreshIcon />}
              onClick={refresh}
              loading={refreshLoading}
            >
              Refresh
            </Button>
          )}
        </Stack>
      )}
      {...restProps}
    >
      This {formatType(type, { lowercase: true, template, directoryType })} is checked out to {isMyCheckout ? 'you' : checkedOutByName}.
    </Alert>
  );
}

CheckoutAlert.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.oneOf(intersection(
    Object.keys(fetchActionMap),
    Object.keys(checkinActionMap),
    Object.keys(checkoutActionMap),
  )),
};

export default CheckoutAlert;
