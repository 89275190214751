import { useTaskHasLoaded, useTaskPermission } from 'hooks/store.hooks';

import { ACTION_COMMENT_CREATE } from 'constants/permission.constants';
import { CommentSection } from 'components/Comment';
import { ENTITY_TASK } from 'constants/schemas';
import PropTypes from 'prop-types';
import React from 'react';
import { useGenericId } from 'utils/generic.utils';

const commentProps = { customEditorProps: { toolbarProps: { elevation: 2 }}};

function TaskComments(props) {
  const { id } = props;
  const commentsHasLoaded = useTaskHasLoaded(id, 'comments');
  const taskGenericId = useGenericId(id, ENTITY_TASK);
  const commentsAllowed = useTaskPermission(id, ACTION_COMMENT_CREATE);
  return (
    <CommentSection
      relatedObjectId={taskGenericId}
      disableNew={!commentsAllowed}
      commentProps={commentProps}
      newCommentProps={commentProps}
      fieldProps={{
        loading: !commentsHasLoaded,
        skeletonProps: {
          variant: 'rounded',
          width: '100%',
          height: 100,
        },
      }}
    />
  );
}

TaskComments.propTypes = {
  id: PropTypes.number.isRequired,
};

export default TaskComments;
