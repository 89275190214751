import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import { GenericProp, directoryActions, directorySelectors, entitySelectors } from 'store/entity';
import React, { useCallback, useState } from 'react';
import { useDirectory, useEntity, useEntityIcon, useMasterNote } from 'hooks/store.hooks';

import GlobalSearch from 'components/GlobalSearch';
import NewMasterNoteItemBase from './NewMasterNoteItemBase';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';

const titleProp = new GenericProp({
  [ENTITY_SOP]: 'title',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_TASK]: 'title',
});

function NewMasterNoteItemExisting(props) {
  const {
    masterNoteId,
    sectionId,
    onRemove,
    addableEntityTypes,
    addableDirectoryTypes,
    genericId: controlledGenericId,
  } = props;
  const dispatch = useDispatch();
  const parentId = useMasterNote(masterNoteId, 'parent');
  const parentIsTemplate = useDirectory(parentId, 'is_template');

  const [genericId, setGenericId] = useState(controlledGenericId ?? getGenericId(null, null));
  const { id, type } = genericId;
  const entityTitle = useEntity(genericId, titleProp);
  const directoryItem = useEntity(genericId, 'directory_item');
  const itemIsTemplate = useEntity(genericId, 'is_template');
  const Icon = useEntityIcon(genericId);

  const createMasterNoteItem = useCallback(async () => {
    if (!isDefined(directoryItem)) return;
    const res = await dispatch(directoryActions.createMasterNoteItem({
      id: masterNoteId,
      data: {
        master_note_section: sectionId,
        directory_item: directoryItem,
      },
    })).unwrap();
    if (isDefined(res.result)) onRemove();
  }, [dispatch, directoryItem, masterNoteId, onRemove, sectionId]);

  const globalSearchFilter = useCallback((dirItem, state) => {
    if (!addableEntityTypes.includes(dirItem.content_type)) return false;
    const isTemplate = entitySelectors.selectEntity(state, getGenericId(dirItem.object_id, dirItem.content_type), 'is_template');
    if (parentIsTemplate !== isTemplate && dirItem.content_type !== ENTITY_SOP) return false;
    if (dirItem.content_type === ENTITY_DIRECTORY) {
      const dirType = directorySelectors.selectDirectory(state, dirItem.object_id, 'type');
      return addableDirectoryTypes.includes(dirType);
    }
    return true;
  }, [addableDirectoryTypes, addableEntityTypes, parentIsTemplate]);

  return (
    <NewMasterNoteItemBase
      onCreate={createMasterNoteItem}
      onRemove={onRemove}
      createButtonProps={{ disabled: !isDefined(id) || !isDefined(type) }}
    >
      { isNil(controlledGenericId) ? (
        <GlobalSearch
          onClickResult={(event, result) => setGenericId(getGenericId(result.id, result.type))}
          types={addableEntityTypes}
          filter={globalSearchFilter}
          textFieldProps={{
            size: 'small',
            variant: 'standard',
            sx: { minWidth: 240, width: 0.33 },
          }}
        />
      ) : (
        <Typography variant='body2'>Add Existing:</Typography>
      )}

      <Stack
        direction='row'
        alignItems='center'
        sx={{
          flexGrow: 1,
          minWidth: 50,
          my: 1,
          ml: 2,
        }}
      >
        {isDefined(entityTitle) ? (
          <>
            {isDefined(Icon) ? <Icon colored template={itemIsTemplate} /> : null}
            <Typography variant='subtitle2' noWrap sx={{ ml: 1 }}>
              {entityTitle}
            </Typography>
          </>
        ) : (
          <Typography noWrap color='text.secondary'>No document chosen...</Typography>
        )}
      </Stack>
    </NewMasterNoteItemBase>
  );
}

NewMasterNoteItemExisting.propTypes = {
  masterNoteId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  addableEntityTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  addableDirectoryTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  genericId: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
};

export default NewMasterNoteItemExisting;
