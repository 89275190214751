import PropTypes from 'prop-types';
import React from 'react';
import Workspace from 'views/Workspace';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

const pageContainerProps = { sx: { borderRadius: 0, overflow: 'unset' }};

function WorkspacesContent(props) {
  const { selected } = props;
  return !isDefined(selected) ? null : (
    <Workspace
      id={selected}
      pageContainerProps={pageContainerProps}
    />
  );
}

WorkspacesContent.propTypes = {
  selected: PropTypes.number,
};

export default WorkspacesContent;
