import * as schemas from 'constants/schemas';

import { clearUpdates, reduceEntitiesFulfilled } from './entity.reducers.utils';
import { getInitialEntityState, getInitialState } from './entity.initialState';
import { reduceError, reduceLoading, reduceLogout } from 'store/store.reducers.utils';

import { ENTITY_MERGE_OPTIONS } from './entity.mergeOptions';
import { attachmentReducer } from './attachment';
import { authActions } from 'store/auth';
import { commentReducer } from './comment';
import { directoryReducer } from './directory';
import { experimentReducer } from './experiment';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { normalize } from 'normalizr';
import { noteReducer } from './note';
import { permissionReducer } from './permission';
import { pinReducer } from './pin';
import { signatureReducer } from './signature';
import { sopReducer } from './sop';
import { taskReducer } from './task';
import { userReducer } from './user';

const reduceAuthUser = (user, state, action) => {
  const normalized = normalize(user ?? {}, schemas.user);
  if (!isDefined(normalized.result)) return;
  const newAction = { ...action, payload: { ...normalized }};
  reduceEntitiesFulfilled(state, newAction);
};

const reduceLogin = (builder, { pending, fulfilled, rejected }) => {
  builder
    .addCase(pending, reduceLoading)
    .addCase(fulfilled, (state, action) => {
      const { user } = action.payload;
      reduceAuthUser(user, state, action);
    })
    .addCase(rejected, reduceError);
};

const reduceVerifyToken = (builder, { pending, fulfilled, rejected }) => {
  builder
    .addCase(pending, reduceLoading)
    .addCase(fulfilled, (state, action) => {
      const user = action.payload;
      reduceAuthUser(user, state, action);
    })
    .addCase(rejected, reduceError);
};

const update = (state, action) => {
  const { id, type, key, value } = action.payload;
  const slice = state[type];
  if (slice.allIds.some((_id) => String(_id) === String(id))) {
    (slice.updates[id] ??= {})[key] = value;
    clearUpdates(slice, id, ENTITY_MERGE_OPTIONS[type]);
  }
};

const clearSlice = (state, action) => {
  const type = action.payload;
  state[type] = getInitialEntityState();
};

export const reducers = { update, clearSlice };
export const extraReducers = (builder) => {
  attachmentReducer(builder);
  commentReducer(builder);
  directoryReducer(builder);
  experimentReducer(builder);
  noteReducer(builder);
  permissionReducer(builder);
  pinReducer(builder);
  signatureReducer(builder);
  sopReducer(builder);
  taskReducer(builder);
  userReducer(builder);

  reduceLogin(builder, authActions.login);
  reduceLogin(builder, authActions.refreshToken);
  reduceVerifyToken(builder, authActions.verifyToken);
  reduceLogout(builder, getInitialState);
};
