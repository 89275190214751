import { Button, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useEffect } from 'react';

import FormulaEditor from './FormulaEditor';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { eventListenerEffect } from '@acheloisbiosoftware/absui.hooks';

function CalculationDefinition(props) {
  const {
    calcUuid,
    anchorEl,
    open,
    onClose,
    formState,
  } = props;
  const { label, setLabel, units, setUnits, formula, setFormula } = formState;

  useEffect(() => eventListenerEffect(document, 'keydown', (event) => {
    if (open) {
      if (event.key === 'Enter' && formula) {
        onClose(false);
        event.preventDefault();
      } else if (event.key === 'Escape') {
        onClose(true);
      }
    }
  }), [open, onClose, formula]);

  const gridItemSx = { display: 'flex' };
  const labelSx = { ml: 'auto', mr: 1, my: 'auto' };
  const inputSx = { width: 1, my: 'auto' };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => onClose(true)}
      sx={{ zIndex: 'modal' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        evelation: 8,
        sx: { p: 1, width: 400, overflow: 'visible' },
        onMouseDown: (event) => event.stopPropagation(), /* Allow for focus */
      }}
    >
      <Typography variant='h6'>Define Calculation Parameters:</Typography>
      <Grid container spacing={1} sx={{ p: 1 }}>
        <Grid item xs={3} sx={gridItemSx}>
          <Typography sx={labelSx}>Label:</Typography>
        </Grid>
        <Grid item xs={9} sx={gridItemSx}>
          <TextField
            value={label}
            onChange={(_, newValue) => setLabel(newValue)}
            size='small'
            sx={inputSx}
            variant='standard'
          />
        </Grid>
        <Grid item xs={3} sx={gridItemSx}>
          <Typography sx={labelSx}>Units:</Typography>
        </Grid>
        <Grid item xs={9} sx={gridItemSx}>
          <TextField
            value={units}
            onChange={(_, newValue) => setUnits(newValue)}
            size='small'
            sx={inputSx}
            variant='standard'
          />
        </Grid>
        <Grid item xs={3} sx={gridItemSx}>
          <Typography sx={labelSx}>Formula:</Typography>
        </Grid>
        <Grid item xs={9} sx={gridItemSx}>
          <FormulaEditor
            value={formula}
            onChange={(newValue) => setFormula(newValue)}
            calcUuid={calcUuid}
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={3}>
          <Button
            onClick={() => onClose(true)}
            variant='text'
            sx={{ width: 1 }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={() => onClose(false)}
            variant='outlined'
            disabled={!formula}
            sx={{ width: 1 }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}

CalculationDefinition.propTypes = {
  calcUuid: PropTypes.string,
  anchorEl: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  formState: PropTypes.shape({
    label: PropTypes.string,
    setLabel: PropTypes.func,
    units: PropTypes.string,
    setUnits: PropTypes.func,
    formula: PropTypes.string,
    setFormula: PropTypes.func,
  }).isRequired,
};

export default CalculationDefinition;
