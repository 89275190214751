import React, { useState } from 'react';
import { useEntityId, useEntityIsReadOnly } from 'hooks/Entity.hooks';

import { AddIcon } from 'constants/icon.constants';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import { ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import { directoryActions } from 'store/entity';
import { formatType } from 'utils/entity.utils';
import { useDispatch } from 'react-redux';

function MasterNoteAddSection() {
  const id = useEntityId();
  const dispatch = useDispatch();
  const isReadOnly = useEntityIsReadOnly();
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    dispatch(directoryActions.createMasterNoteSection({ id }))
      .unwrap()
      .catch(() => null)
      .finally(() => setLoading(false));
  };

  return (
    <Box
      sx={{
        color: 'text.tertiary',
        display: 'flex',
        justifyContent: 'right',
        py: 2,
      }}
    >
      { isReadOnly ? null : (
        <Button
          startIcon={<AddIcon />}
          variant='text'
          color='inherit'
          onClick={onClick}
          loading={loading}
        >
          New {formatType(ENTITY_MASTER_NOTE_SECTION)}
        </Button>
      )}
    </Box>
  );
}

export default MasterNoteAddSection;
