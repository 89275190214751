import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import {
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';
import { Navigate, RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';

import { ACTION_VIEW } from 'constants/permission.constants';
import AllDocuments from 'views/AllDocuments';
import AppRoot from './AppRoot';
import Attachment from 'views/Attachment';
import { DepartmentPage } from 'views/Department';
import Departments from 'views/Departments';
import { ExperimentPage } from 'views/Experiment';
import ForgotPassword from 'views/ForgotPassword';
import Later from 'views/Later';
import LogIn from 'views/LogIn';
import Navigator from 'views/Navigator';
import { NotePage } from 'views/Note';
import Profile from 'views/Profile';
import ProtectedRoute from './ProtectedRoute';
import React from 'react';
import Recent from 'views/Recent';
import ResetPassword from 'views/ResetPassword';
import Settings from 'views/Settings';
import { SopPage } from 'views/Sop';
import Sops from 'views/Sops';
import { TaskPage } from 'views/Task';
import Tasks from 'views/Tasks';
import { TeamPage } from 'views/Team';
import Teams from 'views/Teams';
import Trash from 'views/Trash';
import { UserPage } from 'views/User';
import { UserRolePage } from 'views/UserRole';
import UserRoles from 'views/UserRoles';
import Users from 'views/Users';
import { WorkspacePage } from 'views/Workspace';
import Workspaces from 'views/Workspaces';
import { inBrowser } from 'utils/electron.utils';

const createRoute = (options) => {
  const {
    loginRequirement,
    permissionRequirement,
    element,
    ...restOptions
  } = options;
  return {
    ...restOptions,
    element: (
      <ProtectedRoute
        loginRequirement={loginRequirement}
        permissionRequirement={permissionRequirement}
      >
        {element}
      </ProtectedRoute>
    ),
  };
};

const DefaultRedirect = () => (<Navigate to='/' />);

const createRouter = inBrowser ? createBrowserRouter : createHashRouter;
const router = createRouter([
  {
    path: '/',
    element: (<AppRoot />),
    children: [
      createRoute({
        index: true,
        loginRequirement: true,
        element: (<Navigator />),
      }),
      createRoute({
        path: '/all-documents',
        loginRequirement: true,
        element: (<AllDocuments />),
      }),
      createRoute({
        path: '/templates',
        loginRequirement: true,
        element: (<AllDocuments templates />),
      }),
      createRoute({
        path: '/attachment/:uuid',
        loginRequirement: true,
        element: (<Attachment />),
      }),
      createRoute({
        path: '/experiment/:id',
        loginRequirement: true,
        permissionRequirement: [ENTITY_EXPERIMENT, ACTION_VIEW],
        element: (<ExperimentPage />),
      }),
      createRoute({
        path: '/resetPassword',
        loginRequirement: false,
        element: (<ForgotPassword />),
      }),
      createRoute({
        path: '/recent',
        loginRequirement: true,
        element: (<Recent />),
      }),
      createRoute({
        path: '/later',
        loginRequirement: true,
        element: (<Later />),
      }),
      createRoute({
        path: '/login',
        loginRequirement: false,
        element: (<LogIn />),
      }),
      createRoute({
        path: '/note/:id',
        loginRequirement: true,
        permissionRequirement: [ENTITY_NOTE, ACTION_VIEW],
        element: (<NotePage />),
      }),
      createRoute({
        path: '/resetPassword/confirm',
        loginRequirement: false,
        element: (<ResetPassword />),
      }),
      createRoute({
        path: '/settings',
        loginRequirement: true,
        element: (<Settings />),
        children: [
          createRoute({
            path: '/settings/profile',
            element: (<Profile />),
          }),
          createRoute({
            path: '/settings/users',
            permissionRequirement: [ENTITY_USER, ACTION_VIEW],
            element: (<Users />),
          }),
          createRoute({
            path: '/settings/user/:id',
            permissionRequirement: [ENTITY_USER, ACTION_VIEW],
            element: (<UserPage />),
          }),
          createRoute({
            path: '/settings/user-roles',
            permissionRequirement: [ENTITY_USER_ROLE, ACTION_VIEW],
            element: (<UserRoles />),
          }),
          createRoute({
            path: '/settings/user-role/:id',
            permissionRequirement: [ENTITY_USER_ROLE, ACTION_VIEW],
            element: (<UserRolePage />),
          }),
          createRoute({
            path: '/settings/teams',
            permissionRequirement: [ENTITY_TEAM, ACTION_VIEW],
            element: (<Teams />),
          }),
          createRoute({
            path: '/settings/team/:id',
            permissionRequirement: [ENTITY_TEAM, ACTION_VIEW],
            element: (<TeamPage />),
          }),
          createRoute({
            path: '/settings/departments',
            permissionRequirement: [ENTITY_DEPARTMENT, ACTION_VIEW],
            element: (<Departments />),
          }),
          createRoute({
            path: '/settings/department/:id',
            permissionRequirement: [ENTITY_DEPARTMENT, ACTION_VIEW],
            element: (<DepartmentPage />),
          }),
        ],
      }),
      createRoute({
        path: '/sop/:id',
        loginRequirement: true,
        permissionRequirement: [ENTITY_SOP, ACTION_VIEW],
        element: (<SopPage />),
      }),
      createRoute({
        path: '/sops',
        loginRequirement: true,
        permissionRequirement: [ENTITY_SOP, ACTION_VIEW],
        element: (<Sops />),
      }),
      createRoute({
        path: '/task/:id',
        loginRequirement: true,
        permissionRequirement: [ENTITY_TASK, ACTION_VIEW],
        element: (<TaskPage />),
      }),
      createRoute({
        path: '/tasks',
        loginRequirement: true,
        permissionRequirement: [ENTITY_TASK, ACTION_VIEW],
        element: (<Tasks />),
      }),
      createRoute({
        path: '/workspace/:id',
        loginRequirement: true,
        permissionRequirement: [ENTITY_DIRECTORY, ACTION_VIEW],
        element: (<WorkspacePage />),
      }),
      createRoute({
        path: '/workspaces',
        loginRequirement: true,
        permissionRequirement: [ENTITY_DIRECTORY, ACTION_VIEW],
        element: (<Workspaces key='workspaces' />),
      }),
      createRoute({
        path: '/scripts',
        loginRequirement: true,
        permissionRequirement: [ENTITY_DIRECTORY, ACTION_VIEW],
        element: (<Workspaces key='scripts' templates />),
      }),
      createRoute({
        path: '/studies',
        loginRequirement: true,
        permissionRequirement: [ENTITY_DIRECTORY, ACTION_VIEW],
        element: (<Workspaces key='studies' type={DIR_TYPE_STUDY} />),
      }),
      createRoute({
        path: '/projects',
        loginRequirement: true,
        permissionRequirement: [ENTITY_DIRECTORY, ACTION_VIEW],
        element: (<Workspaces key='projects' type={DIR_TYPE_PROJECT} />),
      }),
      createRoute({
        path: '/programs',
        loginRequirement: true,
        permissionRequirement: [ENTITY_DIRECTORY, ACTION_VIEW],
        element: (<Workspaces key='programs' type={DIR_TYPE_PROGRAM} />),
      }),
      createRoute({
        path: '/trash',
        loginRequirement: true,
        element: (<Trash />),
      }),
      createRoute({
        path: '*',
        element: (<DefaultRedirect />),
      }),
    ],
  },
]);

function Main() {
  return (<RouterProvider router={router} />);
}

export default Main;
