import { deepGet, isDefined } from '@acheloisbiosoftware/absui.utils';

import { ACTION_VIEW } from 'constants/permission.constants';
import { ENTITY } from 'store/store.constants';
import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// #############################################################################
// ################################## Helpers ##################################
// #############################################################################
const createArrayOptionSelector = (...selectors) => {
  const lastSelector = selectors.pop();
  return createSelector(
    ...selectors,
    (state, maybeArray) => maybeArray,
    (...values) => {
      const maybeArray = values.pop();
      if (Array.isArray(maybeArray)) {
        return maybeArray.map((item, idx) => lastSelector(...values, item, idx));
      }
      return lastSelector(...values, maybeArray, -1);
    },
  );
};


// #############################################################################
// ############################### Prop Classes ################################
// #############################################################################
class PropBase {
  constructor(...args) { this.initializeSelectors(...args); }
  initializeSelectors(...args) {
    this.selector = this.createSelector(...args);
    this.hasLoadedSelector = this.createHasLoadedSelector(...args);
  }
  createSelector() { return (state, entity) => entity; }
  createHasLoadedSelector() { return createArrayOptionSelector((entity) => isDefined(entity)); }
  select(state, entity) { return this.selector(state, entity); }
  selectHasLoaded(state, entity) { return this.hasLoadedSelector(state, entity); }
}

export class ConstantProp extends PropBase {
  createSelector(constant) { return createArrayOptionSelector(() => constant); }
  createHasLoadedSelector() { return createArrayOptionSelector(() => true); }
}

export class DeepFieldProp extends PropBase {
  createSelector(keys) { return createArrayOptionSelector((entity) => deepGet(entity, keys)); }
  createHasLoadedSelector(keys) { return createArrayOptionSelector((entity) => isDefined(deepGet(entity, keys))); }
}

export class FunctionProp extends PropBase {
  createSelector(fn) { return createArrayOptionSelector(fn); }
  createHasLoadedSelector(fn) { return createArrayOptionSelector(fn); }
}

export class SelectorProp extends PropBase {
  createSelector(selector) {
    const curriedSelector = createSelector(
      (entity) => entity,
      (entity) => {
        const multiple = Array.isArray(entity);
        const entityList = multiple ? entity : [entity];
        return createSelector(
          ...entityList.map((e) => (state) => selector(state, e)),
          (...values) => (multiple ? values : values[0]),
        );
      },
    );
    return (state, entity) => curriedSelector(entity)(state);
  }
  createHasLoadedSelector(selector) {
    const curriedSelector = createSelector(
      (entity) => entity,
      (entity) => {
        const multiple = Array.isArray(entity);
        const entityList = multiple ? entity : [entity];
        return createSelector(
          ...entityList.map((e) => (state) => isDefined(selector(state, e))),
          (...values) => (multiple ? values : values[0]),
        );
      },
    );
    return (state, entity) => curriedSelector(entity)(state);
  }
}

export class FieldProp extends PropBase {
  createSelector(field) { return createArrayOptionSelector((entity) => entity?.[field]); }
  createHasLoadedSelector(field) { return createArrayOptionSelector((entity) => isDefined(entity) && field in entity); }
}

export class Prop extends PropBase {
  initializeSelectors(prop) {
    if (Array.isArray(prop)) {
      const deepFieldProp = new DeepFieldProp(prop);
      this.selector = deepFieldProp.selector;
      this.hasLoadedSelector = deepFieldProp.hasLoadedSelector;
    } else if (typeof(prop) === 'function') {
      const functionProp = new FunctionProp(prop);
      this.selector = functionProp.selector;
      this.hasLoadedSelector = functionProp.hasLoadedSelector;
    } else if (typeof(prop) === 'string') {
      const fieldProp = new FieldProp(prop);
      this.selector = fieldProp.selector;
      this.hasLoadedSelector = fieldProp.hasLoadedSelector;
    } else if (prop instanceof PropBase) {
      this.selector = prop.selector;
      this.hasLoadedSelector = prop.hasLoadedSelector;
    } else {
      super.initializeSelectors(prop);
    }
  }

  createSelector(prop) {
    if (!isDefined(prop)) return super.createSelector(prop);

    if (typeof(prop) === 'object') {
      const entries = Object.entries(prop);
      const entryProps = entries.map((entry) => ((entry[1] instanceof PropBase) ? entry[1] : new Prop(entry[1])));
      return createArrayOptionSelector(
        ...entryProps.map((entryProp) => (state, entity) => entryProp.select(state, entity)),
        (...values) => {
          const j = values.pop(); // pop off the index
          values.pop(); // pop off the entity
          if (j !== -1) {
            return Object.fromEntries(entries.map((entry, i) => [entry[0], values[i][j]]));
          }
          return Object.fromEntries(entries.map((entry, i) => [entry[0], values[i]]));
        },
      );
    }

    return (new FieldProp(prop)).selector;
  }

  createHasLoadedSelector(prop) {
    if (!isDefined(prop)) return super.createHasLoadedSelector(prop);

    if (typeof(prop) === 'object') {
      const entries = Object.entries(prop);
      const entryProps = entries.map((entry) => ((entry[1] instanceof PropBase) ? entry[1] : new Prop(entry[1])));
      return createArrayOptionSelector(
        ...entryProps.map((entryProp) => (state, entity) => entryProp.selectHasLoaded(state, entity)),
        (...values) => {
          const j = values.pop(); // pop off the index
          values.pop(); // pop off the entity
          if (j !== -1) {
            return Object.fromEntries(entries.map((entry, i) => [entry[0], values[i][j]]));
          }
          return Object.fromEntries(entries.map((entry, i) => [entry[0], values[i]]));
        },
      );
    }

    return (new FieldProp(prop)).hasLoadedSelector;
  }
}

export class GenericProp {
  constructor(mapping, {
    defaultProp = new ConstantProp(),
    ignoreMissing = false,
  } = {}) {
    this.mapping = mapping;
    this.defaultProp = defaultProp instanceof PropBase ? defaultProp : new Prop(defaultProp);
    this.ignoreMissing = ignoreMissing;
  }
  get(type) {
    if (type in this.mapping) {
      return this.mapping[type];
    }
    if (isDefined(type) && !this.ignoreMissing) {
      console.warn(`No prop mapping found for type ${type}.`);
    }
    return this.defaultProp;
  }
}

export class SearchField {
  constructor(prop, weight = 1) {
    this.prop = (prop instanceof PropBase) ? prop : new Prop(prop);
    this.weight = weight;
  }
}


// #############################################################################
// ############################ Selector Generators ############################
// #############################################################################
export const createEntitySliceSelector = (type) => (state) => state[ENTITY][type];

export const createEntitySelector = (entitySliceSelector) => {
  const selectEntity = createArrayOptionSelector(
    (state) => entitySliceSelector(state).byId,
    (state) => entitySliceSelector(state).updates,
    (byId, updates, id) => (isDefined(byId[id]) ? { ...byId[id], ...updates?.[id] } : byId[id]),
  );
  return (state, id, prop) => {
    const entity = selectEntity(state, id);
    if (!isDefined(prop)) return entity;
    const _prop = (prop instanceof PropBase) ? prop : new Prop(prop);
    return _prop.select(state, entity);
  };
};

export const createEntityHasLoadedSelector = (entitySliceSelector) => {
  const selectEntity = createArrayOptionSelector(
    (state) => entitySliceSelector(state).byId,
    (byId, id) => byId[id],
  );
  const selectEntityHasLoaded = createArrayOptionSelector(
    (state) => entitySliceSelector(state).byId,
    (byId, id) => id in byId,
  );
  return (state, id, prop) => {
    if (!isDefined(prop)) return selectEntityHasLoaded(state, id);
    const entity = selectEntity(state, id);
    const _prop = (prop instanceof PropBase) ? prop : new Prop(prop);
    return _prop.hasLoadedSelector(state, entity);
  };
};

export const createEntityPermissionSelector = (entitySelector) => (state, id, action) => entitySelector(state, id, ['permissions', action]);

export const createEntityUpdatesSelector = (entitySliceSelector) => (state, id) => {
  const { updates } = entitySliceSelector(state);
  return isDefined(id) ? updates?.[id] ?? {} : updates;
};

export const createEntitiesWithUpdatesSelector = (entityUpdatesSelector) => createSelector(
  (state) => entityUpdatesSelector(state),
  (updates) => Object.entries(updates).reduce((result, [id, update]) => {
    if (!isEmpty(update)) result.ids.push(id);
    return result;
  }, { ids: []}).ids,
);

export const createEntityListSelector = (entitySliceSelector, {
  defaultSearchFields = [],
} = {}) => {
  const selectAllEntities = createSelector(
    (state) => entitySliceSelector(state).allIds,
    (state) => entitySliceSelector(state).byId,
    (state) => entitySliceSelector(state).updates,
    (allIds, byId, updates) => allIds.map((id) => ({ ...byId[id], ...updates?.[id] })),
  );

  const selectEntitySearchCurried = createSelector(
    (entities) => entities,
    (entities, searchFields) => searchFields,
    (entities, searchFields, search) => search,
    (entities, searchFields, search) => {
      const sortedFields = [...searchFields].sort((sf1, sf2) => sf2.weight - sf1.weight);
      const searchTerms = search.split(/\s/);
      return createSelector(
        ...sortedFields.map((searchField) => (state) => searchField.prop.select(state, entities)),
        (...values) => {
          const entityData = entities.map((entity, idx) => ({
            entity,
            fieldValues: values.map((valueList) => valueList[idx]),
          }));
          const { scoredEntities } = entityData.reduce((result, { entity, fieldValues }) => {
            const score = searchTerms.reduce((currScore, term) => {
              const matchFieldIdx = fieldValues.findIndex((value) => value?.toLowerCase?.()?.includes?.(term.toLowerCase()));
              return currScore + (sortedFields[matchFieldIdx]?.weight ?? 0);
            }, 0);
            if (score > 0) result.scoredEntities.push({ entity, score });
            return result;
          }, { scoredEntities: []});
          scoredEntities.sort((a, b) => b.score - a.score);
          return scoredEntities.map(({ entity }) => entity);
        },
      );
    },
  );

  const selectEntityFilterCurried = createSelector(
    (entities) => entities,
    (entities, filter) => filter,
    (entities, filter) => createSelector(
      ...entities.map((entity) => (state) => (!isDefined(entity?.permissions) || entity.permissions[ACTION_VIEW]) && (!filter || filter(entity, state))),
      (...conditions) => entities.filter((_, idx) => conditions[idx]),
    ),
  );

  const selectEntityTrashFilter = createSelector(
    (entities) => entities,
    (entities, trash) => trash,
    (entities, trash) => entities.filter((entity) => (entity.is_deleted ?? false) === trash),
  );

  return (state, {
    search,
    searchFields = defaultSearchFields,
    sort,
    filter,
    prop,
    trash = false,
  } = {}) => {
    let entities = selectAllEntities(state);
    if (search) entities = selectEntitySearchCurried(entities, searchFields, search)(state);
    if (sort) entities.sort((a, b) => sort(a, b));
    entities = selectEntityFilterCurried(entities, filter)(state);
    entities = selectEntityTrashFilter(entities, trash);
    const _prop = (prop instanceof PropBase) ? prop : new Prop(prop);
    return _prop.select(state, entities);
  };
};


// #############################################################################
// ############################## Hook Generators ##############################
// #############################################################################
export const createEntitySelectorHook = (selector) => (id, prop) => {
  const _prop = useMemo(
    () => (!isDefined(prop) || (prop instanceof PropBase) ? prop : new Prop(prop)),
    [prop],
  );
  return useSelector((state) => selector(state, id, _prop));
};

export const createEntityListSelectorHook = (selector) => (options) => {
  const _options = useMemo(() => ({
    ...options,
    prop: (!isDefined(options?.prop) || (options.prop instanceof PropBase) ? options?.prop : new Prop(options?.prop)),
  }), [options]);
  return useSelector((state) => selector(state, _options));
};
