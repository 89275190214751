import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@acheloisbiosoftware/absui.core';

function FieldOperator(props) {
  const { operator, setOperator, options } = props;
  return (
    <TextField
      size='small'
      variant='standard'
      label='Operator'
      select
      value={operator ?? ''}
      SelectProps={{
        renderValue: (op) => op?.label ?? '',
      }}
      onChange={(_, newValue) => setOperator(newValue?.id)}
      sx={{ width: 150 }}
    >
      { options.map((op) => (
        <MenuItem
          key={`operator_${op.id}`}
          value={op}
          label={op.label}
        />
      ))}
    </TextField>
  );
}

FieldOperator.propTypes = {
  operator: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  setOperator: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
};

export default FieldOperator;
