import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Main from './Main';
import { Provider } from 'react-redux';
import React from 'react';
import ThemeProvider from 'components/ThemeProvider';
import { initServices } from 'services';
import store from 'store';

initServices();

function App() {
  return (
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <ThemeProvider>
            <Main />
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </React.StrictMode>
  );
}

export default App;
