import { Button, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useState } from 'react';
import { useColumn, useColumns, useGroupByState } from './DataTableContext';

import ActionBase from './ActionBase';
import Box from '@mui/material/Box';
import { COL_TYPE_DATE } from './DataTable.constants';
import CategoryIcon from '@mui/icons-material/Category';
import { GROUP_DATES_BY_OPTIONS } from './DataTable.utils';
import MenuItem from 'components/MenuItem';
import Popover from '@mui/material/Popover';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function ActionGroupBy() {
  const columns = useColumns();
  const { groupBy, groupDatesBy, setGroupBy, setGroupDatesBy } = useGroupByState();
  const groupByColumn = useColumn(groupBy);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClear = () => {
    setGroupBy(null);
    onClose();
  };

  return (
    <ActionBase
      label='Group by'
      icon={<CategoryIcon />}
      color={isDefined(groupByColumn) ? 'primary' : 'inherit'}
      onClick={(event) => setAnchorEl(event.currentTarget)}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { px: 2, py: 1 }}}
      >
        <Box sx={{ display: 'flex' }}>
          <TextField
            size='small'
            variant='standard'
            label='Group By'
            select
            value={groupByColumn ?? ''}
            SelectProps={{
              renderValue: (value) => value?.label ?? '',
            }}
            onChange={(_, newColumn) => setGroupBy(newColumn.id)}
            sx={{ width: 150 }}
          >
            { columns.map((col) => (
              <MenuItem
                key={`groupByColumn_${col.id}`}
                value={col}
                label={col.label}
              />
            ))}
          </TextField>
          { groupByColumn?.type === COL_TYPE_DATE ? (
            <TextField
              size='small'
              variant='standard'
              label='Date Precision'
              select
              value={groupDatesBy ?? ''}
              SelectProps={{
                renderValue: (value) => value?.label ?? '',
              }}
              onChange={(_, newOption) => setGroupDatesBy(newOption)}
              sx={{ width: 150 }}
            >
              { GROUP_DATES_BY_OPTIONS.map((option) => (
                <MenuItem
                  key={`option_${option.id}`}
                  value={option}
                  label={option.label}
                />
              ))}
            </TextField>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button
            size='small'
            variant='text'
            onClick={onClear}
            sx={{ mr: 1 }}
          >
            Clear
          </Button>
          <Button
            size='small'
            onClick={onClose}
            disabled={!isDefined(groupByColumn)}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </ActionBase>
  );
}

export default ActionGroupBy;
