import AppSidebar from 'views/AppSidebar';
import AppSource from 'views/AppSource';
import AppToolbar from 'views/AppToolbar';
import Box from '@mui/material/Box';
import DelayedUpdateProvider from 'components/DelayedUpdateProvider';
import DirectoryDndProvider from 'components/DirectoryDndProvider';
import PropTypes from 'prop-types';
import React from 'react';
import useCachedStore from 'hooks/useCachedStore';
import { useIsAuthenticated } from 'hooks/store.hooks';

const COL_GAP = 0.5; // in theme.spacing units
const NAV_WIDTH = 60; // in px
const TOOLBAR_HEIGHT = 36; // in px

function AppBody(props) {
  const { children } = props;
  const isAuthenticated = useIsAuthenticated();

  useCachedStore();

  return !isAuthenticated ? children : (
    <DirectoryDndProvider>
      <DelayedUpdateProvider>
        <Box
          sx={{
            width: 1,
            height: '100vh',
            p: COL_GAP,
            boxSizing: 'border-box',
            overflow: 'hidden',
            bgcolor: 'background.app',
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: `${NAV_WIDTH}px auto`,
            gridTemplateRows: `${TOOLBAR_HEIGHT}px auto`,
            gridColumnGap: (theme) => theme.spacing(COL_GAP),
            gridRowGap: (theme) => theme.spacing(COL_GAP),
          }}
        >
          <AppSource />
          <AppToolbar />
          <AppSidebar />
          { children }
        </Box>
      </DelayedUpdateProvider>
    </DirectoryDndProvider>
  );
}

AppBody.propTypes = {
  children: PropTypes.node,
};

export default AppBody;
