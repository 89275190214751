import { Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import { GenericProp, Prop, directoryActions } from 'store/entity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDirectory, useDirectoryList, useDirectoryPermission, useEntity, useFetchListing } from 'hooks/store.hooks';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { SearchIcon } from 'constants/icon.constants';
import WorkspaceTable from 'components/WorkspaceTable';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { notificationActions } from 'store/notification';
import { selectMoveToDirectoryType } from 'utils/directory.utils';
import { useGenericId } from 'utils/generic.utils';

const titleProp = new GenericProp({
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
});

function MoveDialog(props) {
  const { open, onClose, id, type } = props;
  const dispatch = useDispatch();
  const objectGenericId = useGenericId(id, type);
  const isTemplate = useEntity(objectGenericId, 'is_template');
  const moveToDirectoryType = useSelector((state) => selectMoveToDirectoryType(state, objectGenericId));
  const [quickFilter, setQuickFilter] = useState('');
  const [quickFilterDebounced, setQuickFilterDebounced] = useState(quickFilter);

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (directory) => (
      directory.type === moveToDirectoryType &&
      (type === ENTITY_SOP || directory.is_template === isTemplate)
    ),
  }), [moveToDirectoryType, type, isTemplate]);
  const directoryIds = useDirectoryList(listParams);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setQuickFilterDebounced(quickFilter);
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [quickFilter]);

  const fetchOptions = useMemo(() => ({
    params: {
      search: quickFilterDebounced,
      filters: {
        type: moveToDirectoryType,
        ...(type !== ENTITY_SOP ? { is_template: isTemplate } : {}),
      },
    },
    condition: open,
  }), [open, moveToDirectoryType, type, isTemplate, quickFilterDebounced]);
  const fetchLoading = useFetchListing(directoryActions.fetchDirectories, fetchOptions);

  const [selection, setSelection] = useState(null);
  const columnProps = useMemo(() => ({
    checkbox: {
      hidden: false,
      noHeader: true,
      selection: isDefined(selection) ? [selection] : [],
      onSelect: (newSelection) => setSelection(newSelection?.[newSelection.length - 1] ?? null),
    },
    actions: { hidden: true },
  }), [selection]);

  const onRowClick = useCallback((row) => {
    setSelection(row.id);
  }, []);

  const _onClose = useCallback((...args) => {
    setSelection(null);
    setQuickFilter('');
    onClose(...args);
  }, [onClose]);

  const title = useEntity(objectGenericId, titleProp);
  const directoryItemId = useEntity(objectGenericId, 'directory_item');
  const selectionName = useDirectory(selection, 'name');
  const selectionCanUpdate = useDirectoryPermission(selection, ACTION_UPDATE);
  const selectionMasterNoteId = useDirectory(selection, 'master_note');
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onConfirm = async () => {
    if (!selectionCanUpdate) {
      dispatch(notificationActions.enqueueSnackbar({
        key: `MoveDialogError${Date.now()}`,
        title: 'Permission denied',
        message: 'You do not have editor access to this location',
        variant: 'warning',
      }));
      return;
    }

    setConfirmLoading(true);
    try {
      await dispatch(directoryActions.createMasterNoteItem({
        id: selectionMasterNoteId,
        data: { directory_item: directoryItemId },
      })).unwrap();
      dispatch(notificationActions.enqueueSnackbar({
        key: `MoveDialogSuccess${Date.now()}`,
        message: `Item added to "${selectionName}"`,
        variant: 'success',
      }));
      _onClose();
    } catch {} finally {
      setConfirmLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={_onClose}
      title={`Add "${title}" to ${formatType(ENTITY_DIRECTORY, { directoryType: moveToDirectoryType, template: isTemplate })}`}
      maxWidth='md'
      fullWidth
      onConfirm={onConfirm}
      disableCloseOnConfirm
      loading={confirmLoading}
    >
      <TextField
        size='small'
        value={quickFilter}
        onChange={(_, newValue) => setQuickFilter(newValue)}
        startAdornment={<SearchIcon sx={{ color: 'text.icon' }} />}
        placeholder='Search...'
        clearable
        sx={{ my: 1, width: 1 }}
      />
      <Paper variant='outlined' sx={{ bgcolor: 'background.grey', overflow: 'hidden' }}>
        <WorkspaceTable
          ids={directoryIds}
          columnProps={columnProps}
          quickFilter={quickFilter}
          onRowClick={onRowClick}
          loading={fetchLoading}
        />
      </Paper>
    </Dialog>
  );
}

MoveDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.oneOf([
    ENTITY_EXPERIMENT,
    ENTITY_DIRECTORY,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
};

export default MoveDialog;
