import { Prop, sopActions } from 'store/entity';
import React, { useCallback, useMemo } from 'react';
import { useFetchListing, useSop, useSopList } from 'hooks/store.hooks';

import { ENTITY_SOP } from 'constants/schemas';
import { EmptyField } from 'components/Field';
import PropTypes from 'prop-types';
import SopTable from './SopTable';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';

function SopVersionTable(props) {
  const {
    id,
    columnProps,
    initState,
    tableProps,
    ...restProps
  } = props;
  const navigate = useNavigate();
  const collectionId = useSop(id, 'collection_id');

  const fetchOptions = useMemo(() => ({
    params: { filters: { collection_id: collectionId }},
    condition: !isNil(collectionId),
  }), [collectionId]);
  const loading = useFetchListing(sopActions.fetchSops, fetchOptions);

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (sop) => sop.collection_id === collectionId,
    sort: (s1, s2) => s2.version - s1.version,
  }), [collectionId]);
  const sopIds = useSopList(listParams);

  const onRowClick = useCallback((row) => {
    navigate(getPath(getGenericId(row.id, ENTITY_SOP)));
  }, [navigate]);

  const _columnProps = useMemo(() => ({
    ...columnProps?.columnProps,
    checkbox: {
      selection: [id],
      ...columnProps?.checkbox,
    },
    created_by: {
      hidden: true,
      ...columnProps?.created_by,
    },
    category: {
      hidden: true,
      ...columnProps?.category,
    },
    title: {
      hidden: true,
      ...columnProps?.title,
    },
  }), [id, columnProps]);

  return isNil(collectionId) ? <EmptyField /> : (
    <SopTable
      columnProps={_columnProps}
      disableFilter
      disableGroupBy
      onRowClick={onRowClick}
      noHeader
      {...restProps}
      loading={loading}
      initState={{
        orderBy: 'sop_code',
        order: 'desc',
        ...initState,
      }}
      ids={sopIds}
      tableProps={{ size: 'small', ...tableProps }}
    />
  );
}

SopVersionTable.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  columnProps: PropTypes.object,
  initState: PropTypes.object,
  tableProps: PropTypes.object,
};

export default SopVersionTable;
