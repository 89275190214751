import * as commentActions from './comment.actions';

import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { ENTITY_COMMENT } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, commentActions.createComment);
  reduceEntities(builder, commentActions.patchComment);
  reduceEntities(builder, commentActions.deleteComment, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_COMMENT)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_COMMENT)),
  });
};
