import { ENTITY_SOP_SECTION, ENTITY_SOP_SUBSECTION } from 'constants/schemas';
import { EntityActionState, useEntityActions } from 'components/EntityActions';
import React, { useCallback, useState } from 'react';
import { useEntityId, useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useSop, useSopSection, useUpdateEntity } from 'hooks/store.hooks';

import { AddIcon } from 'constants/icon.constants';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import SopSectionBase from './SopSectionBase';
import SopSubsection from './SopSubsection';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { scrollTo } from 'utils/dom.utils';
import { sopActions } from 'store/entity';
import { useDispatch } from 'react-redux';

function SopSection(props) {
  const { id: sectionId } = props;
  const sopId = useEntityId();
  const sectionTitle = useSopSection(sectionId, 'title');
  const sectionIds = useSop(sopId, 'sections');
  const sectionSequence = (sectionIds ?? []).findIndex((_id) => String(_id) === String(sectionId));
  const subsectionIds = useSopSection(sectionId, 'subsections') ?? [];
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const dispatch = useDispatch();

  const onEdit = useCallback((value) => {
    if ((value ?? '').length > 0 && value !== sectionTitle) {
      update({ type: ENTITY_SOP_SECTION, id: sectionId, key: 'title', value });
    }
  }, [sectionId, sectionTitle, update]);

  const [createSubsectionLoading, setCreateSubsectionLoading] = useState(false);
  const onCreateNewSubsection = useCallback(async () => {
    setCreateSubsectionLoading(true);
    const res = await dispatch(sopActions.createSubsection({ id: sopId, data: { section: sectionId }})).unwrap();
    setCreateSubsectionLoading(false);
    if (isDefined(res?.entities)) {
      const sectionSubsections = res.entities[ENTITY_SOP_SECTION]?.[sectionId]?.subsections ?? [];
      scrollTo(`subsection_${sectionSubsections[sectionSubsections.length - 1]}`);
    }
  }, [dispatch, sopId, sectionId]);

  const extraActions = useEntityActions(sectionId, ENTITY_SOP_SECTION);

  return (
    <EntityActionState>
      <SopSectionBase
        containerProps={{ 'id': `section_${sectionId}`, 'data-test': 'sop-section-container' }}
        sectionHeaderProps={{
          title: sectionTitle,
          prefix: `${sectionSequence + 2}.`,
          onEdit: !isReadOnly ? onEdit : null,
          extraActions: { inMenu: extraActions },
        }}
      >
        { subsectionIds.map((subsectionId) => (
          <SopSubsection
            key={`subsection_${subsectionId}`}
            subsectionId={subsectionId}
          />
        ))}
        { !isReadOnly ? (
          <Box sx={{ width: 1, display: 'flex', color: 'text.tertiary' }}>
            <Button
              onClick={onCreateNewSubsection}
              loading={createSubsectionLoading}
              variant='text'
              color='inherit'
              startIcon={<AddIcon />}
              sx={{ ml: 'auto', mr: 5 }}
              data-test='add-subsection'
            >
              Add New {formatType(ENTITY_SOP_SUBSECTION)}
            </Button>
          </Box>
        ) : null}
      </SopSectionBase>
    </EntityActionState>
  );
}

SopSection.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SopSection;
