import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';

import { isEqual } from 'lodash';

export default {
  [ENTITY_DEPARTMENT]: {
    valueIsEqual: (v1, v2, prop) => {
      if (prop === 'users') {
        const v1Sorted = [...v1].sort();
        const v2Sorted = [...v2].sort();
        return isEqual(v1Sorted, v2Sorted);
      }
      return isEqual(v1, v2);
    },
  },
  [ENTITY_TEAM]: {
    valueIsEqual: (v1, v2, prop) => {
      if (prop === 'users') {
        const v1Sorted = [...v1].sort();
        const v2Sorted = [...v2].sort();
        return isEqual(v1Sorted, v2Sorted);
      }
      return isEqual(v1, v2);
    },
  },
  [ENTITY_USER]: {
    valueIsEqual: (v1, v2, prop) => {
      if (['user_roles', 'teams', 'departments'].includes(prop)) {
        const v1Sorted = [...v1].sort();
        const v2Sorted = [...v2].sort();
        return isEqual(v1Sorted, v2Sorted);
      }
      return isEqual(v1, v2);
    },
    mergeStrategy: (oldValue, newValue) => {
      const merged = Object.assign(oldValue ?? {}, newValue);
      if (!newValue?.avatar || !oldValue?.avatar) return merged;

      const oldAvatarUrl = new URL(oldValue.avatar);
      const newAvatarUrl = new URL(newValue.avatar);

      if (oldAvatarUrl.origin !== newAvatarUrl.origin) return merged;
      if (oldAvatarUrl.pathname !== newAvatarUrl.pathname) return merged;

      const oldExpiration = parseFloat(oldAvatarUrl.searchParams.get('Expires'));
      const timeNow = Date.now() / 1000;

      if (isNaN(oldExpiration) || timeNow >= oldExpiration) return merged;
      merged.avatar = oldValue.avatar;
      return merged;
    },
  },
  [ENTITY_USER_ROLE]: {
    valueIsEqual: (v1, v2, prop) => {
      if (['users', 'permissions_granted'].includes(prop)) {
        const v1Sorted = [...v1].sort();
        const v2Sorted = [...v2].sort();
        return isEqual(v1Sorted, v2Sorted);
      }
      return isEqual(v1, v2);
    },
  },
};
