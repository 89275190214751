import React, { useMemo, useState } from 'react';
import { useFetchListing, useUserList } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { DataTableAction } from 'components/DataTable';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import { ENTITY_USER } from 'constants/schemas';
import { EditIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import UserTable from 'components/UserTable';
import { formatType } from 'utils/entity.utils';
import { userActions } from 'store/entity';

const listParams = { prop: 'id', filter: (user) => user.is_active };
const fetchOptions = { params: { filters: { is_active: true }}};

function ActionUserAssign(props) {
  const { initAssignment = [], onAssign } = props;
  const [assignUserDialogOpen, setAssignUserDialogOpen] = useState(false);
  const [selection, setSelection] = useState(initAssignment);
  const userIds = useUserList(listParams);
  const loading = useFetchListing(userActions.fetchUsers, fetchOptions);

  const onOpen = () => {
    setSelection(initAssignment);
    setAssignUserDialogOpen(true);
  };

  const onConfirm = () => {
    onAssign(selection);
  };

  const onRowClick = (row) => {
    const isSelected = selection.includes(row.id);
    const newSelection = isSelected ? selection.filter((userId) => userId !== row.id) : [...selection, row.id];
    setSelection(newSelection);
  };

  const columnProps = useMemo(() => ({
    checkbox: {
      hidden: false,
      selection,
      onSelect: (newSelection) => setSelection(newSelection),
    },
    is_active: { hidden: true },
    actions: { hidden: true },
  }), [selection]);

  return (
    <DataTableAction
      label={`Edit ${formatType(ENTITY_USER)} Assignments`}
      icon={<EditIcon />}
      onClick={onOpen}
    >
      <Dialog
        open={assignUserDialogOpen}
        title={`Assign ${formatType(ENTITY_USER, { plural: true })}`}
        onClose={() => setAssignUserDialogOpen(false)}
        onConfirm={onConfirm}
        maxWidth='lg'
      >
        <Box
          sx={{
            border: 1,
            borderColor: 'divider',
            borderRadius: 2,
            overflow: 'auto',
            maxHeight: 500,
          }}
        >
          <UserTable
            userIds={userIds}
            columnProps={columnProps}
            onRowClick={onRowClick}
            loading={loading}
            disableFilter
            disableGroupBy
          />
        </Box>
      </Dialog>
    </DataTableAction>
  );
}

ActionUserAssign.propTypes = {
  onAssign: PropTypes.func.isRequired,
  initAssignment: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ])),
};

export default ActionUserAssign;
