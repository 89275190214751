import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE, COL_TYPE_SELECT, COL_TYPE_USER, FILTER_TYPE_SELECT } from 'components/DataTable';
import { ENTITY_CATEGORY, ENTITY_SOP } from 'constants/schemas';
import { EntityActionState, selectEntityActions } from 'components/EntityActions';
import React, { useMemo } from 'react';
import { SelectorProp, sopSelectors } from 'store/entity';

import { ACTION_VIEW } from 'constants/permission.constants';
import PropTypes from 'prop-types';
import { SopIcon } from 'constants/icon.constants';
import Stack from '@mui/material/Stack';
import Status from 'components/Status';
import { formatType } from 'utils/entity.utils';
import { getDraggableProps } from 'components/DirectoryDndProvider';
import { maxBy } from 'lodash';
import { useSop } from 'hooks/store.hooks';

const categoryProp = new SelectorProp((state, sop) => sopSelectors.selectCategory(state, sop?.category, 'name'));

function SopTable(props) {
  const { ids, columnProps, withDnd, latestVersionsOnly, ...restProps } = props;

  const sops = useSop(ids);
  const sopCategoryNames = useSop(ids, categoryProp);
  const { rows } = useMemo(
    () => sops.reduce((result, sop, idx) => {
      if (!sop?.permissions?.[ACTION_VIEW]) return result;

      if (latestVersionsOnly) {
        const latestVersion = maxBy(
          sops.filter((s) => s.collection_id === sop.collection_id),
          'version',
        );
        if (sop.id !== latestVersion.id) return result;
      }

      result.rows.push({
        ...sop,
        category_name: sopCategoryNames[idx],
        ...(withDnd ? { draggableProps: getDraggableProps(sop, ENTITY_SOP, { key: 'SopTable' }) } : {}),
      });
      return result;
    }, { rows: []}),
    [sops, sopCategoryNames, latestVersionsOnly, withDnd],
  );

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      hidden: true,
      ...columnProps?.checkbox,
    },
    {
      id: 'sop_code',
      label: `${formatType(ENTITY_SOP)} Code`,
      field: 'sop_code',
      renderValue: ({ value }) => (
        <Stack direction='row' alignItems='center'>
          <SopIcon colored sx={{ mr: 1 }} />
          {value}
        </Stack>
      ),
      cellProps: { sx: { minWidth: 100 }},
      noWrap: true,
      ...columnProps?.sop_code,
    },
    {
      id: 'title',
      label: `${formatType(ENTITY_SOP)} Title`,
      field: 'title',
      noWrap: true,
      primary: true,
      cellProps: { sx: { maxWidth: 300 }},
      ...columnProps?.title,
    },
    {
      id: 'created_by',
      label: 'Author',
      field: 'created_by',
      type: COL_TYPE_USER,
      secondary: true,
      cellProps: { sx: { maxWidth: 150 }},
      ...columnProps?.created_by,
    },
    {
      id: 'status',
      label: 'Status',
      field: 'status',
      renderValue: ({ value }) => (<Status>{value}</Status>),
      filterType: FILTER_TYPE_SELECT,
      filterOptionProps: (value) => ({ children: (<Status>{value}</Status>) }),
      groupable: true,
      ...columnProps?.status,
    },
    {
      id: 'category',
      label: formatType(ENTITY_CATEGORY),
      field: 'category',
      valueGetter: ({ row }) => row.category_name,
      filterType: FILTER_TYPE_SELECT,
      groupable: true,
      noWrap: true,
      secondary: true,
      cellProps: { sx: { maxWidth: 150 }},
      ...columnProps?.category,
    },
    {
      id: 'created',
      label: 'Date Created',
      field: 'created',
      type: COL_TYPE_DATE,
      groupable: true,
      noWrap: true,
      secondary: true,
      cellProps: { sx: { maxWidth: 200 }},
      ...columnProps?.created,
    },
    {
      id: 'actions',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      inMenu: true,
      actions: ({ row, state }) => selectEntityActions(state, row.id, ENTITY_SOP),
      ...columnProps?.actions,
    },
  ], [columnProps]);

  return (
    <EntityActionState>
      <DataTable
        rows={rows}
        columns={columns}
        {...restProps}
        initState={{
          orderBy: 'created',
          order: 'desc',
          ...restProps?.initState,
        }}
      />
    </EntityActionState>
  );
}

SopTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  withDnd: PropTypes.bool,
  latestVersionsOnly: PropTypes.bool,
  columnProps: PropTypes.object,
};

SopTable.defaultProps = {
  ids: [],
  columnProps: {},
};

export default SopTable;
