import { EntityReadOnlyContext, useEntityIsReadOnly, withEntity } from 'hooks/Entity.hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { getGenericId, useGenericId } from 'utils/generic.utils';
import { sopActions, sopSelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import { ENTITY_SOP } from 'constants/schemas';
import { EntityScopeContext } from 'hooks/scope.hooks';
import Loading from 'components/Loading';
import PageContainer from 'components/PageContainer';
import { PreviewModeContext } from './usePreview';
import PropTypes from 'prop-types';
import SopBody from './SopBody';
import SopNav from './SopNav';
import Stack from '@mui/material/Stack';
import { navigationActions } from 'store/navigation';
import { useDispatch } from 'react-redux';

function Sop(props) {
  const { id, loading, pageContainerProps } = props;
  const dispatch = useDispatch();
  const [previewMode, setPreviewMode] = useState(false);
  const previewModeState = useMemo(() => ({ previewMode, setPreviewMode }), [previewMode]);
  const isReadOnly = useEntityIsReadOnly();
  const genericId = useGenericId(id, ENTITY_SOP);

  useEffect(() => {
    dispatch(navigationActions.addRecentlyViewed(getGenericId(id, ENTITY_SOP)));
  }, [dispatch, id]);

  return (
    <EntityScopeContext.Provider value={genericId}>
      <PreviewModeContext.Provider value={previewModeState}>
        <EntityReadOnlyContext.Provider value={Boolean(isReadOnly || previewMode)}>
          <PageContainer
            key={`sopBody${id}`} /* To force rerender of all children when sopId changes */
            component={Stack}
            direction='row'
            {...pageContainerProps}
          >
            <SopNav sopId={id} loading={loading} />
            { loading ? <Loading containerProps={{ sx: { minWidth: 425 }}} /> : <SopBody sopId={id} /> }
          </PageContainer>
        </EntityReadOnlyContext.Provider>
      </PreviewModeContext.Provider>
    </EntityScopeContext.Provider>
  );
}

Sop.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
  pageContainerProps: PropTypes.object,
};

export default withEntity(Sop, {
  fetchAction: sopActions.fetchSop,
  hasLoadedSelector: sopSelectors.selectSopHasLoaded,
  readOnlySelector: (state, id) => (
    !sopSelectors.selectSopPermission(state, id, ACTION_UPDATE) ||
    !sopSelectors.selectSop(state, id, 'is_checked_out')
  ),
  isInSpaceSelector: sopSelectors.selectSopInSpace,
});
