import { Button, TextField } from '@acheloisbiosoftware/absui.core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { electron, inBrowser } from 'utils/electron.utils';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LandingDialog from 'components/LandingDialog';
import LogoIcon from 'assets/LogoIcon';
import PasswordInput from 'components/PasswordInput';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { authActions } from 'store/auth';
import { useDispatch } from 'react-redux';

const logoSx = { fontSize: 64 };

function LogIn() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!inBrowser) {
      electron.ipcRenderer.invoke('auth:getUserLogin').then((result) => {
        setUsername(result.username ?? '');
        setPassword(result.password ?? '');
      });
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!inBrowser) {
      if (rememberMe) {
        electron.ipcRenderer.send('auth:setUserLogin', { username, password });
      } else {
        electron.ipcRenderer.send('auth:setUserLogin', { username: '', password: '' });
      }
    }

    dispatch(authActions.login({ username, password }))
      .unwrap()
      .then(() => {
        navigate({
          pathname: location.state?.from?.pathname ?? '/',
          search: location.state?.from?.search ?? '',
        });
      })
      .catch((err) => {
        setError(err?.errors?.[0] || 'Incorrect username or password');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === 'username') setUsername(value);
    if (id === 'password') setPassword(value);
    setError('');
  };

  return (
    <LandingDialog
      backgroundProps={{
        component: 'form',
        onSubmit,
        sx: { position: 'absolute', zIndex: 'modal' },
      }}
    >
      <LogoIcon sx={logoSx} />
      <Typography variant='h4'>Welcome back!</Typography>
      <TextField
        id='username'
        data-test='username'
        label='Email'
        value={username}
        onChange={handleChange}
        error={Boolean(error)}
        InputLabelProps={{ shrink: true }}
      />
      <PasswordInput
        id='password'
        data-test='password'
        label='Password'
        value={password}
        error={Boolean(error)}
        helperText={error}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        { inBrowser ? null : (
          <FormControlLabel
            control={<Checkbox />}
            label='Remember Me?'
            checked={rememberMe}
            onChange={(event, checked) => setRememberMe(checked)}
          />
        )}
        <Box
          component={Link}
          to='/resetPassword'
          sx={{
            'typography': 'body1',
            'textDecoration': 'none',
            'color': 'primary.main',
            ':hover': {
              color: 'primary.dark',
            },
          }}
        >
          Forgot password?
        </Box>
      </Stack>
      <Button
        variant='contained'
        data-test='login'
        color='primary'
        onClick={onSubmit}
        type='submit'
        loading={loading}
      >
        Sign In
      </Button>
    </LandingDialog>
  );
}

export default LogIn;
