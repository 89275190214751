import ListingPage from 'components/ListingPage';
import React from 'react';
import TrashContent from './TrashContent';
import TrashTable from './TrashTable';
import { genericIdFromString } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

const parseSelected = (selected) => selected;
const validateSelected = (selected) => {
  const genericId = genericIdFromString(selected);
  return isDefined(genericId?.id) && isDefined(genericId?.type);
};

const sectionHeaderProps = { title: 'Trash' };

function Trash() {
  return (
    <ListingPage
      parseSelected={parseSelected}
      validateSelected={validateSelected}
      sectionHeaderProps={sectionHeaderProps}
      tableComponent={TrashTable}
      contentComponent={TrashContent}
    />
  );
}

export default Trash;
