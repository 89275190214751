import { ACTION_COMMENT_CREATE, ACTION_CREATE } from 'constants/permission.constants';
import Comment, { NewComment } from 'components/Comment';
import { ENTITY_COMMENT, ENTITY_SIGNATURE, ENTITY_SIGNATURE_WORKFLOW_STEP } from 'constants/schemas';
import { useMyGlobalPermission, useSignatureWorkflowStep, useSignatureWorkflowStepActivity, useSignatureWorkflowStepPermission } from 'hooks/store.hooks';

import Field from 'components/Field';
import NewSignatureAssignment from './NewSignatureAssignment';
import PropTypes from 'prop-types';
import { REJECTED } from 'constants/signature.constants';
import React from 'react';
import Signature from './Signature';
import SignatureAssignment from './SignatureAssignment';
import Status from 'components/Status';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function SignatureWorkflowStep(props) {
  const { id, onOpen } = props;
  const step = useSignatureWorkflowStep(id);
  const activity = useSignatureWorkflowStepActivity(id);
  const { name, status, signatures, rules } = step;
  const { min_signatures, max_signatures } = rules ?? {};
  const canCreateComment = useMyGlobalPermission(ENTITY_COMMENT, ACTION_CREATE);
  const commentsAllowed = useSignatureWorkflowStepPermission(id, ACTION_COMMENT_CREATE);
  const canComment = canCreateComment && commentsAllowed;

  const helpText = isDefined(min_signatures) && isDefined(max_signatures) ? (
    `You may add as few as ${min_signatures} or as many as ${max_signatures} signees`
  ) : isDefined(min_signatures) ? (
    `You must add at least ${min_signatures} signees`
  ) : isDefined(max_signatures) ? (
    `You may add as many as ${max_signatures} signees`
  ) : `You may add as many or as few signees as you like`;

  return (
    <>
      <StepButton onClick={onOpen}>
        <StepLabel error={status === REJECTED}>
          { name }
        </StepLabel>
      </StepButton>
      <StepContent>
        <Field label='Status'>
          <Status>{status}</Status>
        </Field>
        <Field label='Signees' labelProps={{ help: helpText }}>
          { signatures.map((signature) => (
            <SignatureAssignment key={`signature_${signature}`} id={signature} />
          ))}
          <NewSignatureAssignment signatureWorkflowStepId={id} />
        </Field>
        <Field label='Activity' empty={activity.length === 0 && !canComment}>
          { activity.map((activityItem) => (
            activityItem.type === ENTITY_SIGNATURE ? (
              <Signature key={`signature_${activityItem.id}`} id={activityItem.id} />
            ) : activityItem.type === ENTITY_COMMENT ? (
              <Comment
                key={`comment_${activityItem.id}`}
                id={activityItem.id}
                containerProps={{ sx: { pl: 2, my: 1 }}}
              />
            ) : null
          ))}
          { canComment ? (
            <NewComment
              relatedObjectId={getGenericId(id, ENTITY_SIGNATURE_WORKFLOW_STEP)}
              containerProps={{ sx: { pl: 2, my: 1 }}}
            />
          ) : null}
        </Field>
      </StepContent>
    </>
  );
}

SignatureWorkflowStep.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onOpen: PropTypes.func,
};

export default SignatureWorkflowStep;
