import client from 'services';

// #############################################################################
// ################################ Permissions ################################
// #############################################################################
export function fetchPermissions(params, config) {
  return client({ method: 'get', url: '/api/permissions/', params, ...config });
}

// #############################################################################
// ######################### Permission Relationships ##########################
// #############################################################################
export function createPermissionRelationship(data, config) {
  return client({ method: 'post', url: '/api/permission-relationships/', data, ...config });
}

export function patchPermissionRelationship({ id, data }, config) {
  return client({ method: 'patch', url: `/api/permission-relationships/${id}/`, data, ...config });
}

export function deletePermissionRelationship({ id }, config) {
  return client({ method: 'delete', url: `/api/permission-relationships/${id}/`, ...config });
}
