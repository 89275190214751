import { ENTITY_ACTION_DELETE, ENTITY_ACTION_OPEN, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_CATEGORY, ENTITY_SOP } from 'constants/schemas';
import React, { useCallback, useMemo, useState } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import { useCategoryList, useFetchListing, useSop, useSopPermission, useUpdateEntity, useUserName } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { DATE_FMT_TIME } from 'constants/date.constants';
import { DELETE_SOFT } from 'constants/deletion.constants';
import Field from 'components/Field';
import Link from 'components/Link';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import SopSectionBase from './SopSectionBase';
import { SopVersionTable } from 'components/SopTable';
import Status from 'components/Status';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { sopActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';
import { usePreviewMode } from './usePreview';

const toolbarProps = { sx: { top: 60 }, elevation: 2 };

function ParameterSection(props) {
  const { sopId } = props;
  const sop = useSop(sopId);
  const [categoryOpen, setCategoryOpen] = useState(false);

  const clonedFromSop = useSop(sop?.cloned_from);
  const canViewClonedFromSop = useSopPermission(sop?.cloned_from, ACTION_VIEW);
  const author = useUserName(sop?.created_by);

  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(sopId, ENTITY_SOP);
  const categories = useCategoryList();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { previewMode } = usePreviewMode();

  const categoryFetchOptions = useMemo(() => ({ condition: categoryOpen }), [categoryOpen]);
  useFetchListing(sopActions.fetchCategories, categoryFetchOptions);
  const category = categories.find((c) => c.id === sop?.category) ?? { id: sop?.category, name: '' };

  const onSectionTitleChange = (value) => {
    if ((value ?? '').length > 0 && value !== sop.section_name) {
      update({ type: ENTITY_SOP, id: sopId, key: 'section_name', value });
    }
  };

  const onTitleChange = (_, value) => {
    if ((value ?? '').length > 0 && value !== sop.title) {
      update({ type: ENTITY_SOP, id: sopId, key: 'title', value });
    }
  };

  const onCategoryChange = (newCategory) => {
    update({ type: ENTITY_SOP, id: sopId, key: 'category', value: newCategory.id });
  };

  const onCreateCategory = async (name) => {
    const { entities, result } = await dispatch(sopActions.createCategory({ name })).unwrap();
    return entities[ENTITY_CATEGORY][result];
  };

  const onDescriptionChange = (value) => {
    update({ type: ENTITY_SOP, id: sopId, key: 'description', value });
  };

  const onDeleteSuccess = useCallback((res, { deletionType }) => {
    if (deletionType !== DELETE_SOFT) {
      navigate('/');
    }
  }, [navigate]);

  const extraActions = useEntityActions(sopId, ENTITY_SOP, {
    exclude: [ENTITY_ACTION_OPEN],
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { label: `Delete ${formatType(ENTITY_SOP, { template: sop?.is_template })}`, onDeleteSuccess }},
  });

  if (!sop) return null;
  return (
    <EntityActionState>
      <SopSectionBase
        containerProps={{ 'id': 'section_parameters', 'data-test': 'SOP-parameters-section' }}
        sectionHeaderProps={{
          title: sop.section_name,
          prefix: '1.',
          onEdit: !isReadOnly ? onSectionTitleChange : null,
          extraActions: { inMenu: extraActions },
        }}
      >
        <Box sx={{ px: 3, mt: 1 }}>
          <Field
            label='Title'
            value={sop.title}
            variant={Field.VARIANTS.TEXT_FIELD}
            readOnly={isReadOnly}
            textFieldProps={{ onChange: onTitleChange }}
          />
          { clonedFromSop ? (
            <Field label='Cloned from'>
              { canViewClonedFromSop ? (
                <Link to={getPath(getGenericId(clonedFromSop.id, ENTITY_SOP))}>
                  {clonedFromSop.long_title}
                </Link>
              ) : (
                <Tooltip title={`You do not have permission to view this ${formatType(ENTITY_SOP, { lowercase: true })}`} arrow>
                  <Typography color='text.disabled' sx={{ width: 'fit-content' }}>[Content Hidden]</Typography>
                </Tooltip>
              )}
            </Field>
          ) : null }
          <Field label='Author'>{author}</Field>
          <Field label='Date Created'>{formatDate(sop.created, DATE_FMT_TIME)}</Field>
          { sop.is_template ? null : (<Field label='Status'><Status>{sop.status}</Status></Field>)}
          <Field
            label={formatType(ENTITY_CATEGORY)}
            value={category}
            variant={Field.VARIANTS.AUTOCOMPLETE}
            readOnly={isReadOnly}
            autocompleteProps={{
              onChange: onCategoryChange,
              onCreate: onCreateCategory,
              onOpen: () => setCategoryOpen(true),
              onClose: () => setCategoryOpen(false),
              options: categories,
              getOptionLabel: (option) => option.name,
              createOptionText: (inputValue) => `Create New ${formatType(ENTITY_CATEGORY)}: "${inputValue}"`,
            }}
          />
          <Field label='Location(s)'>
            <Locations directoryItem={sop.directory_item} />
          </Field>
          { sop.is_template ? null : (
            <Field label='Version History'>
              <SopVersionTable id={sopId} />
            </Field>
          )}
          <Field
            label='Description'
            value={sop.description}
            variant={Field.VARIANTS.TEXT_EDITOR}
            readOnly={isReadOnly}
            interactable={!isReadOnly || previewMode}
            onChangeSerialized={previewMode ? null : onDescriptionChange}
            onChange={previewMode ? null : editorChangeHandler}
            serializeOnBlur={false}
            toolbarProps={toolbarProps}
            valueProps={{
              containerProps: { 'data-test': 'sop-description' },
              key: `parameterSectionRTE_${previewMode}`, /* Force re-render when preview mode changes */
            }}
          />
        </Box>
      </SopSectionBase>
    </EntityActionState>
  );
}

ParameterSection.propTypes = {
  sopId: PropTypes.number.isRequired,
};

export default ParameterSection;
