import React, { useCallback, useState } from 'react';

import { Button } from '@acheloisbiosoftware/absui.core';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { useSortable } from '@dnd-kit/sortable';

function NavDraggable(props) {
  const {
    id,
    icon,
    text,
    onClick,
    draggable,
    expandable,
    isExpanded,
    children,
    sx,
    listItemProps,
    listItemButtonProps,
    listItemIconProps,
    listItemTextProps,
    ...restProps
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const { onMouseEnter, onMouseLeave } = listItemProps ?? {};
  const _onMouseEnter = useCallback((...args) => {
    setIsHovering(true);
    onMouseEnter?.(...args);
  }, [onMouseEnter]);
  const _onMouseLeave = useCallback((...args) => {
    setIsHovering(false);
    onMouseLeave?.(...args);
  }, [onMouseLeave]);

  return (
    <Paper
      {...restProps}
      elevation={isDragging ? 24 : 0}
      square
      ref={setNodeRef}
      sx={mergeSx(
        { bgcolor: 'background.grey' },
        transform && draggable ? {
          transform: CSS.Translate.toString(transform),
          transition,
        } : {},
        isDragging ? { position: 'relative', zIndex: 'containedModal' } : {},
        sx,
      )}
      {...attributes}
    >
      <ListItem
        disablePadding
        {...listItemProps}
        sx={mergeSx({ height: 48 }, listItemProps?.sx)}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMouseLeave}
      >
        <ListItemButton onClick={onClick} {...listItemButtonProps}>
          { icon ? (
            <ListItemIcon
              {...listItemIconProps}
              sx={mergeSx({
                color: 'inherit',
                fontSize: (theme) => theme.spacing(3),
              }, listItemIconProps?.sx)}
            >
              {icon}
            </ListItemIcon>
          ) : null}
          <ListItemText
            primary={text}
            {...listItemTextProps}
            primaryTypographyProps={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              ...listItemTextProps?.primaryTypographyProps,
            }}
          />
          { draggable && isHovering ? (
            <Button
              color='inherit'
              variant='text'
              sx={{ minWidth: 16, px: 0.5, cursor: 'grab', color: 'text.icon' }}
              {...listeners}
            >
              <DragIndicatorIcon fontSize='small' />
            </Button>
          ) : null }
          { expandable ? (
            isExpanded ? <ExpandLess /> : <ExpandMore />
          ) : null}
        </ListItemButton>
      </ListItem>
      { !isDragging ? children : null }
    </Paper>
  );
}

NavDraggable.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  icon: PropTypes.node,
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
  expandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  children: PropTypes.node,
  sx: sxPropType,
  listItemProps: PropTypes.object,
  listItemButtonProps: PropTypes.object,
  listItemIconProps: PropTypes.object,
  listItemTextProps: PropTypes.object,
};

export default NavDraggable;
