import { ENTITY_ACTION_DELETE, ENTITY_ACTION_OPEN, EntityActionState, useEntityActions } from 'components/EntityActions';
import React, { useCallback, useMemo } from 'react';
import { useTask, useUpdateEntity } from 'hooks/store.hooks';

import { DELETE_SOFT } from 'constants/deletion.constants';
import { ENTITY_TASK } from 'constants/schemas';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import TemplateChip from 'components/TemplateChip';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';

function TaskHeader(props) {
  const { id } = props;
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const title = useTask(id, 'title');
  const isTemplate = useTask(id, 'is_template');
  const navigate = useNavigate();

  const onEdit = (newTitle) => {
    if ((newTitle ?? '').length > 0 && newTitle !== title) {
      update({ type: ENTITY_TASK, id, key: 'title', value: newTitle });
    }
  };

  const onDeleteSuccess = useCallback((res, { deletionType }) => {
    if (deletionType !== DELETE_SOFT) navigate('/');
  }, [navigate]);

  const menuActions = useEntityActions(id, ENTITY_TASK, {
    exclude: [ENTITY_ACTION_OPEN],
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess }},
  });

  const extraActions = useMemo(() => ({
    inMenu: menuActions,
    left: isTemplate ? [{
      id: 'template',
      component: TemplateChip,
      props: {
        type: ENTITY_TASK,
        sx: { my: 'auto', mx: 1 },
      },
    }] : [],
  }), [menuActions, isTemplate]);

  return (
    <EntityActionState>
      <SectionHeader
        title={title}
        sticky
        onEdit={!isReadOnly ? onEdit : null}
        extraActions={extraActions}
      />
    </EntityActionState>
  );
}

TaskHeader.propTypes = {
  id: PropTypes.number,
};

export default TaskHeader;
