import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import { MoveIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import { formatType } from 'utils/entity.utils';
import { selectMoveToDirectoryType } from 'utils/directory.utils';
import { useEntity } from 'hooks/store.hooks';
import { useGenericId } from 'utils/generic.utils';
import { useMoveDialogContext } from './EntityActionState';
import { useSelector } from 'react-redux';

function ActionMove(props) {
  const { id, type, ...restProps } = props;
  const genericId = useGenericId(id, type);
  const { setMoveDialogProps } = useMoveDialogContext();
  const moveToDirectoryType = useSelector((state) => selectMoveToDirectoryType(state, genericId));
  const isTemplate = useEntity(genericId, 'is_template');
  const label = `Add to ${formatType(ENTITY_DIRECTORY, { directoryType: moveToDirectoryType, template: isTemplate })}`;

  const onAction = useCallback(() => {
    setMoveDialogProps?.((oldProps) => ({ ...oldProps, open: true, id, type }));
  }, [id, type, setMoveDialogProps]);

  return (
    <BaseAction
      icon={<MoveIcon />}
      label={label}
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionMove.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};

export default ActionMove;
