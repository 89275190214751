import { ConstantProp, GenericProp } from 'store/entity';
import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import {
  ENTITY_ATTACHMENT,
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { formatType } from 'utils/entity.utils';
import { useEntity } from './store.hooks';
import { useGenericId } from 'utils/generic.utils';

const titleProp = new GenericProp({
  [ENTITY_ATTACHMENT]: 'name',
  [ENTITY_DEPARTMENT]: 'name',
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'long_title',
  [ENTITY_TASK]: 'long_title',
  [ENTITY_TEAM]: 'name',
  [ENTITY_USER]: 'full_name',
  [ENTITY_USER_ROLE]: 'name',
}, {
  defaultProp: new ConstantProp(''),
  ignoreMissing: true,
});

const useDocumentTitle = () => {
  const params = useParams();
  const { pathname } = useLocation();

  const entityType = useMemo(() => {
    if (pathname.startsWith('/attachment/')) {
      return ENTITY_ATTACHMENT;
    } else if (pathname.startsWith('/experiment/')) {
      return ENTITY_EXPERIMENT;
    } else if (pathname.startsWith('/note/')) {
      return ENTITY_NOTE;
    } else if (pathname.startsWith('/settings/user/')) {
      return ENTITY_USER;
    } else if (pathname.startsWith('/settings/user-role/')) {
      return ENTITY_USER_ROLE;
    } else if (pathname.startsWith('/settings/team/')) {
      return ENTITY_TEAM;
    } else if (pathname.startsWith('/settings/department/')) {
      return ENTITY_DEPARTMENT;
    } else if (pathname.startsWith('/sop/')) {
      return ENTITY_SOP;
    } else if (pathname.startsWith('/task/')) {
      return ENTITY_TASK;
    } else if (pathname.startsWith('/workspace/')) {
      return ENTITY_DIRECTORY;
    }
    return null;
  }, [pathname]);

  const entityId = useMemo(() => {
    if (pathname.startsWith('/attachment/')) {
      return params.uuid;
    } else if (
      pathname.startsWith('/experiment/') ||
      pathname.startsWith('/note/') ||
      pathname.startsWith('/settings/user/') ||
      pathname.startsWith('/settings/user-role/') ||
      pathname.startsWith('/settings/team/') ||
      pathname.startsWith('/settings/department/') ||
      pathname.startsWith('/sop/') ||
      pathname.startsWith('/task/') ||
      pathname.startsWith('/workspace/')
    ) {
      return params.id;
    }
    return null;
  }, [pathname, params]);

  const genericId = useGenericId(entityId, entityType);
  const entityTitle = useEntity(genericId, titleProp);

  const title = useMemo(() => {
    if (pathname === '/') {
      return 'Home';
    } else if (pathname === '/all-documents') {
      return 'All Docs';
    } else if (pathname === '/templates') {
      return 'Templates';
    } else if (
      pathname === '/resetPassword' ||
      pathname === '/resetPassword/confirm'
    ) {
      return 'Reset Password';
    } else if (pathname === '/recent') {
      return 'Recent';
    } else if (pathname === '/later') {
      return 'Later';
    } else if (pathname === '/login') {
      return 'Login';
    } else if (pathname === '/settings') {
      return 'Settings';
    } else if (pathname === '/settings/profile') {
      return 'Profile';
    } else if (pathname === '/settings/users') {
      return formatType(ENTITY_USER, { plural: true });
    } else if (pathname === '/settings/user-roles') {
      return formatType(ENTITY_USER_ROLE, { plural: true });
    } else if (pathname === '/settings/teams') {
      return formatType(ENTITY_TEAM, { plural: true });
    } else if (pathname === '/settings/departments') {
      return formatType(ENTITY_DEPARTMENT, { plural: true });
    } else if (pathname === '/sops') {
      return formatType(ENTITY_SOP, { plural: true });
    } else if (pathname === '/tasks') {
      return formatType(ENTITY_TASK, { plural: true });
    } else if (pathname === '/workspaces') {
      return formatType(ENTITY_DIRECTORY, { plural: true });
    } else if (pathname === '/scripts') {
      return formatType(ENTITY_DIRECTORY, { plural: true, template: true });
    } else if (pathname === '/studies') {
      return formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_STUDY });
    } else if (pathname === '/projects') {
      return formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_PROJECT });
    } else if (pathname === '/programs') {
      return formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_PROGRAM });
    } else if (pathname === '/trash') {
      return 'Trash';
    } else if (
      pathname.startsWith('/attachment/') ||
      pathname.startsWith('/experiment/') ||
      pathname.startsWith('/note/') ||
      pathname.startsWith('/settings/user/') ||
      pathname.startsWith('/settings/user-role/') ||
      pathname.startsWith('/settings/team/') ||
      pathname.startsWith('/settings/department/') ||
      pathname.startsWith('/sop/') ||
      pathname.startsWith('/task/') ||
      pathname.startsWith('/workspace/')
    ) {
      return entityTitle;
    }
    return '';
  }, [pathname, entityTitle]);

  useEffect(() => {
    document.title = title ? `${title} - acta` : 'acta';
  }, [title]);
};

export default useDocumentTitle;
