import { Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useRef, useState } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';

function SelectDialog(props) {
  const { label, value, dialogProps, SelectProps, ...restProps } = props;
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const onSelectOpen = (...args) => {
    setOpen(true);
    SelectProps?.onOpen?.(...args);
  };

  const onDialogClose = (...args) => {
    setOpen(false);
    dialogProps?.onClose?.(...args);
  };

  const dummyValue = isDefined(value) ? `${value}` : '';

  return (
    <>
      <TextField
        label={label}
        {...restProps}
        ref={ref}
        select
        value={dummyValue}
        SelectProps={{
          renderValue: () => value,
          ...SelectProps,
          open,
          onOpen: onSelectOpen,
          MenuProps: {
            ...SelectProps?.MenuProps,
            sx: mergeSx({ opacity: 0 }, SelectProps?.MenuProps?.sx),
          },
        }}
        sx={mergeSx({ minWidth: 150 }, restProps?.sx)}
      >
        <MenuItem value={dummyValue} />
      </TextField>
      <Dialog
        title={label}
        disableEnforceFocus
        {...dialogProps}
        open={open}
        onClose={onDialogClose}
      />
    </>
  );
}

SelectDialog.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  dialogProps: PropTypes.object,
  SelectProps: PropTypes.object,
};

export default SelectDialog;
