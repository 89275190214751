import { AddIcon, SearchIcon } from 'constants/icon.constants';
import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useMemo, useState } from 'react';
import { useDirectory, useMasterNote } from 'hooks/store.hooks';

import { Button } from '@acheloisbiosoftware/absui.core';
import Divider from '@mui/material/Divider';
import NewMasterNoteItemExisting from './NewMasterNoteItemExisting';
import NewMasterNoteItemNew from './NewMasterNoteItemNew';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

const addButtonProps = {
  color: 'grey',
  size: 'small',
  sx: {
    flexGrow: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: 0,
  },
};

const ROW_EXISTING = 'existing';
const ROW_NEW = 'new';

const ROW_COMPONENT_MAP = {
  [ROW_EXISTING]: NewMasterNoteItemExisting,
  [ROW_NEW]: NewMasterNoteItemNew,
};

const ADDABLE_DIRECTORY_TYPE_MAP = {
  [DIR_TYPE_STUDY]: [],
  [DIR_TYPE_PROJECT]: [DIR_TYPE_STUDY],
  [DIR_TYPE_PROGRAM]: [DIR_TYPE_PROJECT],
};

const ADDABLE_ENTITY_TYPES_MAP = {
  [DIR_TYPE_STUDY]: [ENTITY_NOTE, ENTITY_EXPERIMENT, ENTITY_SOP, ENTITY_TASK],
  [DIR_TYPE_PROJECT]: [ENTITY_DIRECTORY],
  [DIR_TYPE_PROGRAM]: [ENTITY_DIRECTORY],
};

function NewMasterNoteItems(props) {
  const { masterNoteId, sectionId } = props;
  const [stagedRows, setStagedRows] = useState([]);
  const parent = useMasterNote(masterNoteId, 'parent');
  const parentDirType = useDirectory(parent, 'type');
  const addableDirectoryTypes = useMemo(() => ADDABLE_DIRECTORY_TYPE_MAP[parentDirType], [parentDirType]);
  const addableEntityTypes = useMemo(() => ADDABLE_ENTITY_TYPES_MAP[parentDirType], [parentDirType]);

  const addRow = (type) => {
    setStagedRows([
      ...stagedRows,
      { type, key: `${type}_${Date.now()}` },
    ]);
  };

  const removeRow = (idx) => {
    setStagedRows((oldStagedRows) => oldStagedRows.filter((r, i) => i !== idx));
  };

  return (
    <>
      {stagedRows.map((row, idx) => {
        const Component = ROW_COMPONENT_MAP[row.type];
        return (
          <Component
            key={row.key}
            masterNoteId={masterNoteId}
            sectionId={sectionId}
            onRemove={() => removeRow(idx)}
            addableEntityTypes={addableEntityTypes}
            addableDirectoryTypes={addableDirectoryTypes}
          />
        );
      })}
      <Divider />
      <Stack direction='row'>
        <Button
          {...addButtonProps}
          onClick={() => addRow(ROW_NEW)}
          startIcon={<AddIcon />}
        >
          Add New
        </Button>
        <Divider orientation='vertical' flexItem />
        <Button
          {...addButtonProps}
          onClick={() => addRow(ROW_EXISTING)}
          startIcon={<SearchIcon />}
        >
          Add Existing
        </Button>
      </Stack>
    </>
  );
}

NewMasterNoteItems.propTypes = {
  masterNoteId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
};

export default NewMasterNoteItems;
