import { ENTITY_EXPERIMENT, ENTITY_EXPERIMENT_SECTION, ENTITY_EXPERIMENT_SUBSECTION } from 'constants/schemas';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import {
  createEntitiesWithUpdatesSelector,
  createEntityHasLoadedSelector,
  createEntityListSelector,
  createEntityPermissionSelector,
  createEntitySelector,
  createEntityUpdatesSelector,
} from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { createSelector } from '@reduxjs/toolkit';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { isEmpty } from 'lodash';
import { userSelectors } from '../user';

// #############################################################################
// ########################## Experiment Subsections ###########################
// #############################################################################
export const selectSubsections = (state) => state[ENTITY][ENTITY_EXPERIMENT_SUBSECTION];
export const selectSubsection = createEntitySelector(selectSubsections);
export const selectSubsectionPermission = createEntityPermissionSelector(selectSubsection);
const selectSubsectionUpdates = createEntityUpdatesSelector(selectSubsections);
const _selectSubsectionsWithUpdates = createEntitiesWithUpdatesSelector(selectSubsectionUpdates);


// #############################################################################
// ############################ Experiment Sections ############################
// #############################################################################
export const selectSections = (state) => state[ENTITY][ENTITY_EXPERIMENT_SECTION];
export const selectSection = createEntitySelector(selectSections);


// #############################################################################
// ################################ Experiments ################################
// #############################################################################
export const selectExperiments = (state) => state[ENTITY][ENTITY_EXPERIMENT];
export const selectExperimentList = createEntityListSelector(selectExperiments);
export const selectExperiment = createEntitySelector(selectExperiments);
export const selectExperimentHasLoaded = createEntityHasLoadedSelector(selectExperiments);
export const selectExperimentPermission = createEntityPermissionSelector(selectExperiment);

export const selectExperimentInSpace = (state, id, spaceType, spaceGenericId) => {
  const exp = selectExperiment(state, id);
  if (!isDefined(exp)) return true;
  if (spaceType === SPACE_USER) return exp.created_by === spaceGenericId.id;
  if (spaceType === SPACE_TEAM) return userSelectors.selectTeamHasPermission(state, spaceGenericId.id, exp);
  if (spaceType === SPACE_DEPARTMENT) return userSelectors.selectDepartmentHasPermission(state, spaceGenericId.id, exp);
  return true;
};

const selectExperimentUpdates = createEntityUpdatesSelector(selectExperiments);
export const selectExperimentPatchData = (state, id) => {
  const data = { ...(selectExperimentUpdates(state, id) ?? {}) };
  const sectionIds = selectExperiment(state, id, 'sections') ?? [];

  const getSubsectionPatchData = (subsectionId) => {
    const updates = selectSubsectionUpdates(state, subsectionId);
    return isEmpty(updates) ? null : { ...updates, id: subsectionId };
  };

  const getSectionPatchData = (sectionId) => {
    const subsectionIds = selectSection(state, sectionId, 'subsections') ?? [];
    const subsectionData = subsectionIds.map(getSubsectionPatchData).filter((update) => !isEmpty(update));
    return subsectionData.length > 0 ? { id: sectionId, subsection_data: subsectionData } : null;
  };

  const sectionData = sectionIds.map(getSectionPatchData).filter((update) => !isEmpty(update));
  if (sectionData.length > 0) data.section_data = sectionData;
  return data;
};

const _selectExperimentsWithUpdates = createEntitiesWithUpdatesSelector(selectExperimentUpdates);
export const selectExperimentsWithUpdates = createSelector(
  _selectExperimentsWithUpdates,
  _selectSubsectionsWithUpdates,
  selectSections,
  selectSubsections,
  (experimentIds, subsectionIds, sections, subsections) => {
    const sectionIds = subsectionIds.map((id) => subsections.byId[id]?.section);
    const additionalExperimentIds = sectionIds.map((id) => sections.byId[id]?.experiment);
    return [...new Set([...experimentIds, ...additionalExperimentIds.filter((id) => isDefined(id))])];
  },
);

export const selectExperimentHasUpdates = (state, id) => {
  if (isDefined(id)) {
    return !isEmpty(selectExperimentPatchData(state, id));
  }
  return !isEmpty(selectExperimentUpdates(state)) || !isEmpty(selectSubsectionUpdates(state));
};
