import * as taskActions from './task.actions';

import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { ENTITY_TASK } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, taskActions.fetchTasks);
  reduceEntities(builder, taskActions.createTask);
  reduceEntities(builder, taskActions.fetchTask, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_TASK)),
  });
  reduceEntities(builder, taskActions.patchTask);
  reduceEntities(builder, taskActions.deleteTask, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_TASK)),
  });
  reduceEntities(builder, taskActions.deleteTaskPermanently, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_TASK)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_TASK)),
  });
  reduceEntities(builder, taskActions.checkinTask);
  reduceEntities(builder, taskActions.checkoutTask);
  reduceEntities(builder, taskActions.cloneTask);
  reduceEntities(builder, taskActions.generateTaskTemplate);
  reduceEntities(builder, taskActions.instantiateTask);
  reduceEntities(builder, taskActions.launchTask);
  reduceEntities(builder, taskActions.restoreTask);
};
