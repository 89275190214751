import React, { useMemo } from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddchartIcon from '@mui/icons-material/Addchart';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Badge from '@mui/material/Badge';
import BiotechIcon from '@mui/icons-material/Biotech';
import BoltIcon from '@mui/icons-material/Bolt';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Box from '@mui/material/Box';
import BusinessIcon from '@mui/icons-material/Business';
import CommitIcon from '@mui/icons-material/Commit';
import { ConditionalWrapper } from '@acheloisbiosoftware/absui.core';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { Icon } from '@iconify/react';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MapIcon from '@mui/icons-material/Map';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MuiAddIcon from '@mui/icons-material/Add';
import MuiCheckIcon from '@mui/icons-material/Check';
import MuiClearIcon from '@mui/icons-material/Clear';
import MuiDeleteIcon from '@mui/icons-material/Delete';
import MuiEditIcon from '@mui/icons-material/Edit';
import MuiLaunchIcon from '@mui/icons-material/Launch';
import MuiNotificationsIcon from '@mui/icons-material/Notifications';
import MuiRefreshIcon from '@mui/icons-material/Refresh';
import MuiSaveIcon from '@mui/icons-material/Save';
import MuiSearchIcon from '@mui/icons-material/Search';
import MuiSendIcon from '@mui/icons-material/Send';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import PropTypes from 'prop-types';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import ScienceIcon from '@mui/icons-material/Science';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

// *****************************************************************************
// * Wrapper components ********************************************************
// *****************************************************************************
const wrappedIcon = (IconComponent, color, sx) => {
  const WrappedIcon = ({ colored, ...restProps }) => {
    const _sx = useMemo(() => mergeSx(
      colored ? { color } : null, sx, restProps?.sx,
    ), [colored, restProps?.sx]);
    return (<IconComponent {...restProps} sx={_sx} />);
  };
  WrappedIcon.propTypes = {
    colored: PropTypes.bool,
    sx: sxPropType,
  };
  return WrappedIcon;
};

const wrappedIconifyIcon = (icon, color, sx) => {
  const WrappedIcon = ({ colored, ...restProps }) => {
    const _sx = useMemo(() => mergeSx(
      colored ? { color } : null,
      { fontSize: 24 },
      sx,
      restProps?.sx,
    ), [colored, restProps?.sx]);
    return (
      <Box
        component={Icon}
        icon={icon}
        {...restProps}
        sx={_sx}
      />
    );
  };
  WrappedIcon.propTypes = {
    colored: PropTypes.bool,
    sx: sxPropType,
  };
  return WrappedIcon;
};


// *****************************************************************************
// * Template Components *******************************************************
// *****************************************************************************
export const TemplateIcon = wrappedIcon(BoltIcon);
export const ScriptIcon = wrappedIconifyIcon('fluent:script-32-filled', 'text.icon');

const templateWrappedIcon = (IconComponent) => {
  const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };

  function TemplateWrappedIcon(props) {
    const { template, templateBadgeSize = 12, ...restProps } = props;

    const badgeContainerSx = useMemo(() => ({
      bgcolor: 'primary.main',
      border: 1,
      borderColor: 'primary.contrastText',
      borderRadius: '50%',
      height: templateBadgeSize,
      width: templateBadgeSize,
    }), [templateBadgeSize]);

    const badgeIconSx = useMemo(() => ({
      fontSize: templateBadgeSize,
      color: 'primary.contrastText',
    }), [templateBadgeSize]);

    const wrapperProps = useMemo(() => ({
      overlap: 'circular',
      anchorOrigin,
      badgeContent: (
        <Box sx={badgeContainerSx}>
          <TemplateIcon sx={badgeIconSx} />
        </Box>
      ),
    }), [badgeContainerSx, badgeIconSx]);

    return (
      <ConditionalWrapper
        wrapperComponent={Badge}
        wrapperProps={wrapperProps}
        condition={template}
      >
        <IconComponent {...restProps} />
      </ConditionalWrapper>
    );
  }

  TemplateWrappedIcon.propTypes = {
    template: PropTypes.bool,
    templateBadgeSize: PropTypes.number,
  };

  return TemplateWrappedIcon;
};

// *****************************************************************************
// * Entity Icons **************************************************************
// *****************************************************************************
export const AttachmentIcon = wrappedIcon(AttachFileIcon);
export const DepartmentIcon = wrappedIcon(BusinessIcon);
export const DirectoryIcon = templateWrappedIcon(wrappedIcon(MapIcon, 'text.icon'));
export const ExperimentIcon = templateWrappedIcon(wrappedIcon(BiotechIcon, 'logo.a'));
export const MasterNoteIcon = templateWrappedIcon(wrappedIcon(MapIcon, 'text.icon'));
export const NoteIcon = templateWrappedIcon(wrappedIcon(DescriptionIcon, 'logo.t'));
export const SopIcon = templateWrappedIcon(wrappedIcon(AssignmentTurnedInIcon, 'logo.c'));
export const TaskIcon = templateWrappedIcon(wrappedIcon(EventNoteIcon, 'text.icon'));
export const TeamIcon = wrappedIcon(GroupIcon);
export const UserIcon = wrappedIcon(PersonIcon);
export const UserRoleIcon = wrappedIcon(LockPersonIcon);

// *****************************************************************************
// * Directory Type Icons ******************************************************
// *****************************************************************************
export const ProgramIcon = templateWrappedIcon(wrappedIconifyIcon('mdi:drugs', 'text.icon'));
export const ProjectIcon = templateWrappedIcon(wrappedIcon(ConstructionIcon, 'text.icon'));
export const StudyIcon = templateWrappedIcon(wrappedIcon(ScienceIcon, 'text.icon'));

// *****************************************************************************
// * Page Icons ****************************************************************
// *****************************************************************************
export const AllDocumentsIcon = wrappedIcon(AllInboxIcon);
export const LaterIcon = wrappedIcon(BookmarkBorderIcon, 'text.icon');
export const NotificationsIcon = wrappedIcon(MuiNotificationsIcon);
export const ProfileIcon = wrappedIcon(AccountCircleIcon);
export const ToolsIcon = wrappedIcon(HomeRepairServiceIcon);
export const UserManagementIcon = wrappedIcon(ManageAccountsIcon);

// *****************************************************************************
// * Action Icons **************************************************************
// *****************************************************************************
export const BrowseDocumentsIcon = wrappedIcon(ManageSearchIcon);
export const CheckinIcon = wrappedIcon(LoginIcon);
export const CheckoutIcon = wrappedIcon(LogoutIcon);
export const CloneIcon = wrappedIcon(LibraryAddIcon);
export const ExportIcon = wrappedIcon(FileDownloadIcon);
export const LaunchIcon = wrappedIcon(MuiLaunchIcon);
export const MoveIcon = wrappedIcon(DriveFileMoveIcon);
export const NewExperimentIcon = wrappedIcon(AddchartIcon);
export const NewVersionIcon = wrappedIcon(CommitIcon);
export const RefreshIcon = wrappedIcon(MuiRefreshIcon);
export const ShareIcon = wrappedIcon(GroupAddIcon);
export const SignatureIcon = wrappedIconifyIcon('mdi:sign', 'text.icon');
export const ViewIcon = wrappedIcon(VisibilityIcon);

// *****************************************************************************
// * Field Icons ***************************************************************
// *****************************************************************************
export const AdminIcon = wrappedIcon(VerifiedUserIcon);
export const LocationIcon = wrappedIcon(PlaceIcon);
export const StatusIcon = wrappedIcon(HourglassTopIcon);
export const TimeIcon = wrappedIcon(AccessTimeIcon);

// *****************************************************************************
// * Common Icons **************************************************************
// *****************************************************************************
export const AddIcon = wrappedIcon(MuiAddIcon);
export const CheckIcon = wrappedIcon(MuiCheckIcon);
export const ClearIcon = wrappedIcon(MuiClearIcon);
export const DeleteIcon = wrappedIcon(MuiDeleteIcon);
export const EditIcon = wrappedIcon(MuiEditIcon);
export const HideIcon = wrappedIcon(VisibilityOffIcon);
export const MoreOptionsIcon = wrappedIcon(MoreVertIcon);
export const OpenIcon = wrappedIcon(OpenInFullIcon);
export const PinIcon = wrappedIcon(StarBorderIcon);
export const RestoreIcon = wrappedIcon(RestoreFromTrashIcon);
export const SaveIcon = wrappedIcon(MuiSaveIcon);
export const SendIcon = wrappedIcon(MuiSendIcon);
export const ShowIcon = wrappedIcon(VisibilityIcon);
export const SearchIcon = wrappedIcon(MuiSearchIcon);
export const UnpinIcon = wrappedIcon(StarIcon);
