import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import { useEntity, useEntityHasLoaded, useEntityPermission } from 'hooks/store.hooks';

import { ACTION_ADMIN } from 'constants/permission.constants';
import Field from 'components/Field';
import PermissionRelationshipRow from './PermissionRelationshipRow';
import PropTypes from 'prop-types';
import React from 'react';
import ShareObjectAccess from './ShareObjectAccess';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useGenericId } from 'utils/generic.utils';

function ShareExistingDocumentField(props) {
  const { id, type, ...restProps } = props;
  const objectGenericId = useGenericId(id, type);
  const shares = useEntity(objectGenericId, 'permission_relationships');
  const sharesHasLoaded = useEntityHasLoaded(objectGenericId, 'permission_relationships');
  const isAdmin = useEntityPermission(objectGenericId, ACTION_ADMIN);

  return (
    <Field
      label='People With Access'
      loading={!sharesHasLoaded}
      {...restProps}
      skeletonProps={{
        height: 50,
        width: '100%',
        variant: 'rounded',
      }}
      valueProps={{
        sx: mergeSx({ ml: 0 }, restProps?.valueProps?.sx),
      }}
    >
      { (shares ?? []).map((share) => (
        <PermissionRelationshipRow
          key={`PermissionRelationshipRow${share}`}
          id={share}
        />
      ))}

      { isAdmin ? (
        <ShareObjectAccess objectId={id} objectType={type} />
      ) : null}
    </Field>
  );
}

ShareExistingDocumentField.propTypes = {
  id: PropTypes.number,
  type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
};

export default ShareExistingDocumentField;
