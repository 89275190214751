import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback } from 'react';
import {
  directoryActions,
  experimentActions,
  noteActions,
  sopActions,
  taskActions,
} from 'store/entity';

import BaseAction from './BaseAction';
import { CloneIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const cloneActionMap = {
  [ENTITY_DIRECTORY]: directoryActions.cloneDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.cloneExperiment,
  [ENTITY_NOTE]: noteActions.cloneNote,
  [ENTITY_SOP]: sopActions.cloneSop,
  [ENTITY_TASK]: taskActions.cloneTask,
};

function ActionClone(props) {
  const { id, type, ...restProps } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAction = useCallback(async () => {
    try {
      const res = await dispatch(cloneActionMap[type]({ id })).unwrap();
      navigate(getPath(getGenericId(res.result, type)));
    } catch {}
  }, [id, type, dispatch, navigate]);

  return (
    <BaseAction
      icon={<CloneIcon />}
      label='Duplicate'
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionClone.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf(Object.keys(cloneActionMap)),
};

export default ActionClone;
