import * as navigationSelectors from './navigation.selectors';

import { NAVIGATION, getLocalStoreSliceId } from 'store/store.constants';

import { createAction } from '@reduxjs/toolkit';
import { localStore } from 'utils/localStorage.utils';

export const setSpaceAction = createAction(`${NAVIGATION}/setSpace`);
export const setSpace = (payload) => (dispatch, getState) => {
  dispatch(setSpaceAction(payload));
  const navigationLocalStorageId = getLocalStoreSliceId(NAVIGATION);
  const localState = localStore.load(navigationLocalStorageId) ?? {};
  const state = getState();
  localState.spaceType = navigationSelectors.selectSpaceType(state);
  localState.spaceId = navigationSelectors.selectSpaceId(state);
  localStore.save(navigationLocalStorageId, localState);
};
