import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const BoldText = (props) => (
  <Box component='span' sx={{ fontWeight: 'fontWeightBold' }} {...props} />
);

function ForgotPasswordSubmitted({ email, onSubmit }) {
  return (
    <Stack spacing={2}>
      <Typography variant='h4'>Check your email!</Typography>
      <Typography>We sent a password reset link to <BoldText>{email}</BoldText></Typography>
      <Button
        variant='contained'
        color='primary'
        onClick={onSubmit}
        data-test='resend-reset-password'
      >
        Resend link
      </Button>
    </Stack>
  );
}

ForgotPasswordSubmitted.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordSubmitted;
