import * as sopActions from './sop.actions';

import { ENTITY_SOP, ENTITY_SOP_SECTION, ENTITY_SOP_SUBSECTION } from 'constants/schemas';
import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, sopActions.fetchCategories);
  reduceEntities(builder, sopActions.createCategory);
  reduceEntities(builder, sopActions.fetchSops);
  reduceEntities(builder, sopActions.createSop);
  reduceEntities(builder, sopActions.fetchSop, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_SOP)),
  });
  reduceEntities(builder, sopActions.patchSop);
  reduceEntities(builder, sopActions.deleteSop, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_SOP)),
  });
  reduceEntities(builder, sopActions.deleteSopPermanently, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_SOP)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_SOP)),
  });
  reduceEntities(builder, sopActions.checkinSop);
  reduceEntities(builder, sopActions.checkoutSop);
  reduceEntities(builder, sopActions.cloneSop);
  reduceEntities(builder, sopActions.createSection);
  reduceEntities(builder, sopActions.createSubsection);
  reduceEntities(builder, sopActions.deleteSection, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.meta.arg.id, ENTITY_SOP_SECTION)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_SOP_SECTION)),
  });
  reduceEntities(builder, sopActions.deleteSubsection, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.meta.arg.id, ENTITY_SOP_SUBSECTION)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_SOP_SUBSECTION)),
  });
  reduceEntities(builder, sopActions.generateSopTemplate);
  reduceEntities(builder, sopActions.instantiateSop);
  reduceEntities(builder, sopActions.newSopVersion);
  reduceEntities(builder, sopActions.publishSop);
  reduceEntities(builder, sopActions.restoreSop);
};
