import { blue, deepOrange, green, pink, purple, teal } from '@mui/material/colors';

import { createTheme } from '@mui/material/styles';

export const MODE_LIGHT = 'light';
export const MODE_SYSTEM = 'system';
export const MODE_DARK = 'dark';

export const generateTheme = (appearance) => {
  const isDark = appearance === MODE_DARK;
  return createTheme({
    palette: {
      mode: appearance,
      logo: {
        a: isDark ? '#7ac69a' : '#3b8d5d',
        c: isDark ? '#987ac4' : '#5d3b8d',
        t: isDark ? '#c4997a' : '#8d5d3b',
      },
      primary: {
        main: isDark ? '#79c498' : '#47a970',
        light: isDark ? '#b8dfc8' : '#53c582',
        dark: isDark ? '#46a76e' : '#3b8d5d',
        contrastText: isDark ? '#000000' : '#ffffff',
      },
      secondary: {
        main: isDark ? '#79a2c4' : '#467ba8',
        light: isDark ? '#b8cedf' : '#5291c4',
        dark: isDark ? '#467ba7' : '#3a678c',
        contrastText: isDark ? '#000000' : '#ffffff',
      },
      grey: {
        main: isDark ? '#242424' : '#eeeeee',
        light: isDark ? '#2f2f2f' : '#f5f5f5',
        dark: isDark ? '#1b1b1b' : '#e0e0e0',
        contrastText: isDark ? '#e3e3e3' : '#2e2e2e',
      },
      background: {
        app: isDark ? '#383c3a' : '#cae1d3', // NOTE: if these change, also update the background color in electron's public/main.js
        grey: isDark ? '#2f2f2f' : '#f5f5f5',
        error: isDark ? '#735c5c' : '#fbe1e1',
        success: isDark ? '#3d473e' : '#e4f5e5',
      },
      text: {
        primary: isDark ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)',
        secondary: isDark ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
        tertiary: isDark ? 'rgba(255, 255, 255, 0.38)' : 'rgba(0, 0, 0, 0.38)',
        disabled: isDark ? 'rgba(255, 255, 255, 0.38)' : 'rgba(0, 0, 0, 0.38)',
        icon: isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
      },
      selection: (opacity) => `rgba(105, 167, 241, ${opacity})`,
      common: {
        black: '#000000',
        white: '#ffffff',
      },
      material: {
        blue: {
          main: isDark ? blue[200] : blue[500],
          contrastText: isDark ? blue[900] : '#ffffff',
        },
        deepOrange: {
          main: isDark ? deepOrange[200] : deepOrange[500],
          contrastText: isDark ? deepOrange[900] : '#ffffff',
        },
        green: {
          main: isDark ? green[200] : green[600],
          contrastText: isDark ? green[900] : '#ffffff',
        },
        pink: {
          main: isDark ? pink[200] : pink[300],
          contrastText: isDark ? pink[900] : '#ffffff',
        },
        purple: {
          main: isDark ? purple[200] : purple[300],
          contrastText: isDark ? purple[900] : '#ffffff',
        },
        teal: {
          main: isDark ? teal[200] : teal[400],
          contrastText: isDark ? teal[900] : '#ffffff',
        },
      },
      textfieldOutline: isDark ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
    },
    typography: {
      h1: {
        fontWeight: 300,
        fontSize: '3.75rem',
        lineHeight: 1.2,
        letterSpacing: '-0.00833em',
      },
      h2: {
        fontWeight: 400,
        fontSize: '3rem',
        lineHeight: 1.167,
        letterSpacing: '0em',
      },
      h3: {
        fontWeight: 400,
        fontSize: '2.125rem',
        lineHeight: 1.235,
        letterSpacing: '0.00735em',
      },
      h4: {
        fontWeight: 500,
        fontSize: '1.5rem',
        lineHeight: 1.334,
        letterSpacing: '0em',
      },
      h5: {
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
      },
      h6: {
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em',
      },
    },
    opacity: {
      primary: 0.87,
      secondary: 0.6,
      disabled: 0.38,
      light: 0.12,
    },
    zIndex: {
      containedModal: 1099,
      header: 1070,
      subheader: 1060,
    },
  });
};
