import { ACTION_VIEW, RELATIONSHIP_PERMISSION_OPTIONS } from 'constants/permission.constants';
import React, { useMemo } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useFetchListing, useMyGlobalPermission, useUser, useUserList } from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Chip from '@mui/material/Chip';
import { ENTITY_USER } from 'constants/schemas';
import ListItem from '@mui/material/ListItem';
import NewTaskFormBlock from './NewTaskFormBlock';
import NewTaskFormInline from './NewTaskFormInline';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { userActions } from 'store/entity';

const userListParams = {
  filter: (user) => user.is_active,
};

function NewTaskForm(props) {
  const {
    inline = false,
    formData = {},
    updateFormData,
    excludeFields = [],
    fieldPropsMap,
    containerProps,
  } = props;
  const { assignees, due_date } = formData;
  const FormComponent = inline ? NewTaskFormInline : NewTaskFormBlock;

  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const hasAssignees = !excludeFields.includes('assignees');
  const userFetchOptions = useMemo(() => ({
    params: { filters: { is_active: true }},
    condition: canViewUsers && hasAssignees,
  }), [canViewUsers, hasAssignees]);
  useFetchListing(userActions.fetchUsers, userFetchOptions);
  const users = useUserList(userListParams);
  const assigneeObjs = useUser(assignees);

  const momentDueDate = useMemo(() => (isDefined(due_date) ? moment(due_date) : null), [due_date]);

  return (
    <FormComponent
      formData={formData}
      updateFormData={updateFormData}
      excludeFields={excludeFields}
      fieldPropsMap={fieldPropsMap}
      assigneesAutocompleteProps={{
        value: assigneeObjs ?? [],
        options: users,
        onChange: (newAssignees) => updateFormData('assignees', newAssignees.map(({ id }) => id)),
        disableCreateOption: true,
        isOptionEqualToValue: (option, value) => option.id === value.id,
        getOptionLabel: (option) => option.full_name,
        renderOption: (_props, option) => (
          <ListItem {..._props} key={`option_${option.id}`}>
            <Stack direction='row' alignItems='center'>
              <Avatar id={option.id} type={ENTITY_USER} sx={{ mr: 1 }} />
              {option.full_name}
            </Stack>
          </ListItem>
        ),
        renderTags: (value, getTagProps) => value.map((option, index) => {
          const tagProps = getTagProps({ index });
          return (
            <Chip
              {...tagProps}
              key={`tag_${option.id}`}
              label={option.full_name}
              avatar={<Avatar id={option.id} type={ENTITY_USER} size={24} />}
              sx={mergeSx(tagProps?.sx, { '& .MuiChip-avatar': { color: 'background.default' }})}
            />
          );
        }),
      }}
      dueDateDatePickerProps={{
        value: momentDueDate,
        onChange: (newDueDate) => updateFormData('due_date', newDueDate?.toISOString() ?? null),
        slotProps: {
          ...fieldPropsMap?.due_date?.datePickerProps?.slotProps,
          actionBar: {
            actions: ['today', 'clear'],
            ...fieldPropsMap?.due_date?.datePickerProps?.slotProps?.actionBar,
          },
        },
      }}
      containerProps={containerProps}
    />
  );
}

NewTaskForm.propTypes = {
  inline: PropTypes.bool,
  formData: PropTypes.shape({
    title: PropTypes.string,
    assignees: PropTypes.arrayOf(PropTypes.number),
    due_date: PropTypes.string,
    description: PropTypes.object,
    related_obj_content_type: PropTypes.string,
    related_obj_id: PropTypes.number,
    related_obj_init_data: PropTypes.object,
    is_template: PropTypes.bool,
    permission_relationships: PropTypes.arrayOf(PropTypes.shape({
      individual_id: PropTypes.number,
      individual_content_type: PropTypes.string,
      permission: PropTypes.oneOf(RELATIONSHIP_PERMISSION_OPTIONS),
    })),
  }),
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf([
    'title',
    'assignees',
    'due_date',
    'description',
    'related_obj',
    'permission_relationships',
  ])),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewTaskForm;
