import { useColumn, useRow } from './DataTableContext';

import PropTypes from 'prop-types';
import { getRenderValue } from './DataTable.utils';

function ContentDefault(props) {
  const { rowId, columnId } = props;
  const row = useRow(rowId);
  const column = useColumn(columnId);
  return getRenderValue(row, column);
}

ContentDefault.propTypes = {
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  columnId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ContentDefault;
