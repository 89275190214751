import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import { LaunchIcon, OpenIcon } from 'constants/icon.constants';
import { getIcon, getPath } from 'utils/entity.utils';
import { useEntity, useEntityPermission } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import CollapseButton from 'components/CollapseButton';
import ContentButton from 'components/ContentButton';
import { EmptyField } from 'components/Field';
import { GenericProp } from 'store/entity';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useGenericId } from 'utils/generic.utils';

const entityTitleMap = {
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
};
const entityTitleProp = new GenericProp(entityTitleMap);
const directoryTypeMap = { [ENTITY_DIRECTORY]: 'type' };
const directoryTypeProp = new GenericProp(directoryTypeMap, { ignoreMissing: true });

function CurrentRelatedDocument(props) {
  const {
    related_obj_id,
    related_obj_content_type,
    related_obj_init_data,
    readOnly,
    isExisting,
    isNew,
    onClear,
    onLaunch,
    launchDisabled,
    launchLoading,
    hideLaunch,
    hideOpen,
    formOpen,
    setFormOpen,
  } = props;

  const genericId = useGenericId(related_obj_id, related_obj_content_type);
  const titleExistingObj = useEntity(genericId, entityTitleProp);
  const directoryTypeExistingObj = useEntity(genericId, directoryTypeProp);
  const canViewExistingObj = useEntityPermission(genericId, ACTION_VIEW);
  const pathExistingObj = getPath(genericId);

  const titleNewObj = related_obj_init_data?.[entityTitleMap[related_obj_content_type]] || 'Untitled';
  const directoryTypeNewObj = related_obj_content_type === ENTITY_DIRECTORY ? related_obj_init_data?.[directoryTypeMap[related_obj_content_type]] : null;

  const directoryType = isNew ? directoryTypeNewObj : directoryTypeExistingObj;
  const Icon = getIcon(related_obj_content_type, { directoryType });

  if (readOnly) {
    if (isExisting) {
      return (
        <Tooltip title={canViewExistingObj ? null : 'You do not have permission to view this document'} arrow>
          <Stack sx={!canViewExistingObj ? { width: 'fit-content' } : null}>
            <ContentButton
              icon={<Icon colored />}
              content={canViewExistingObj ? titleExistingObj : (
                <Typography color='text.disabled'>[Content Hidden]</Typography>
              )}
              disabled={!canViewExistingObj || hideOpen}
              listItemButtonProps={{
                component: Link,
                to: pathExistingObj,
              }}
              secondaryAction={canViewExistingObj && !hideOpen ? (
                <Tooltip title='Open related document' arrow>
                  <Button
                    icon
                    size='small'
                    color='inherit'
                    component={Link}
                    to={pathExistingObj}
                  >
                    <OpenIcon fontSize='small' />
                  </Button>
                </Tooltip>
              ) : null}
            />
          </Stack>
        </Tooltip>
      );
    }

    if (isNew) {
      return (
        <Tooltip title='This document is planned but has not yet been launched' arrow>
          <Stack direction='row' alignItems='center' sx={{ width: 'fit-content', py: 0.5, px: 1 }}>
            <Icon sx={{ mr: 1, color: 'text.icon' }} />
            <Typography color='text.secondary'>{titleNewObj}</Typography>
          </Stack>
        </Tooltip>
      );
    }

    return (
      <EmptyField sx={{ ml: 0 }} />
    );
  }

  return (
    <ContentButton
      icon={isNew || isExisting ? <Icon colored={isExisting} /> : null}
      content={isNew ? (
        titleNewObj
      ) : isExisting ? (
        canViewExistingObj ? titleExistingObj : (
          <Tooltip title='You do not have permission to view this document' arrow>
            <Typography color='text.disabled' sx={{ width: 'fit-content' }}>[Content Hidden]</Typography>
          </Tooltip>
        )
      ) : (
        <EmptyField sx={{ ml: 0 }} />
      )}
      secondaryAction={(
        <>
          {isNew && !hideLaunch ? (
            <Tooltip title='Launch planned document' arrow>
              <Box sx={{ display: 'inline-flex' }}> {/* Wrapper is needed for tooltip to work when button is disabled */}
                <Button
                  icon
                  size='small'
                  color='inherit'
                  loading={launchLoading}
                  disabled={launchDisabled}
                  onClick={onLaunch}
                >
                  <LaunchIcon fontSize='small' />
                </Button>
              </Box>
            </Tooltip>
          ) : null}
          {isExisting && canViewExistingObj && !hideOpen ? (
            <Tooltip title='Open related document' arrow>
              <Button
                icon
                size='small'
                color='inherit'
                component={Link}
                to={pathExistingObj}
              >
                <OpenIcon fontSize='small' />
              </Button>
            </Tooltip>
          ) : null}
          <CollapseButton open={formOpen} setOpen={setFormOpen} />
        </>
      )}
      onClick={() => setFormOpen((prev) => !prev)}
      onClear={isNew || isExisting ? onClear : null}
      listItemTextProps={isNew ? { primaryTypographyProps: { color: 'text.secondary' }} : {}}
    />
  );
}

CurrentRelatedDocument.propTypes = {
  related_obj_id: PropTypes.number,
  related_obj_content_type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
  related_obj_init_data: PropTypes.object,
  readOnly: PropTypes.bool,
  isExisting: PropTypes.bool,
  isNew: PropTypes.bool,
  onClear: PropTypes.func,
  onLaunch: PropTypes.func,
  launchDisabled: PropTypes.bool,
  launchLoading: PropTypes.bool,
  hideLaunch: PropTypes.bool,
  hideOpen: PropTypes.bool,
  formOpen: PropTypes.bool,
  setFormOpen: PropTypes.func,
};

export default CurrentRelatedDocument;
