import React, { useMemo } from 'react';
import { useCategory, useCategoryList, useFetchListing } from 'hooks/store.hooks';

import { ENTITY_CATEGORY } from 'constants/schemas';
import NewSopFormBlock from './NewSopFormBlock';
import NewSopFormInline from './NewSopFormInline';
import PropTypes from 'prop-types';
import { RELATIONSHIP_PERMISSION_OPTIONS } from 'constants/permission.constants';
import { formatType } from 'utils/entity.utils';
import { sopActions } from 'store/entity';
import { useDispatch } from 'react-redux';

function NewSopForm(props) {
  const {
    inline = false,
    formData = {},
    updateFormData,
    excludeFields = [],
    fieldPropsMap,
    containerProps,
  } = props;
  const { category } = formData;
  const FormComponent = inline ? NewSopFormInline : NewSopFormBlock;
  const dispatch = useDispatch();

  const hasCategory = !excludeFields.includes('category');
  const fetchOptions = useMemo(() => ({ condition: hasCategory }), [hasCategory]);
  useFetchListing(sopActions.fetchCategories, fetchOptions);
  const categoryName = useCategory(category, 'name');
  const categories = useCategoryList();

  const onCreateCategory = async (name) => {
    const res = await dispatch(sopActions.createCategory({ name })).unwrap();
    return res?.entities?.[ENTITY_CATEGORY]?.[res?.result];
  };

  return (
    <FormComponent
      formData={formData}
      updateFormData={updateFormData}
      excludeFields={excludeFields}
      fieldPropsMap={fieldPropsMap}
      categoryAutocompleteProps={{
        value: { id: category, name: categoryName ?? '' },
        options: categories,
        onChange: (newCategory) => updateFormData('category', newCategory?.id),
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option?.id === value?.id,
        onCreate: onCreateCategory,
        createOptionText: (inputValue) => `Create New ${formatType(ENTITY_CATEGORY)}: "${inputValue}"`,
      }}
      containerProps={containerProps}
    />
  );
}

NewSopForm.propTypes = {
  inline: PropTypes.bool,
  formData: PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.number,
    is_template: PropTypes.bool,
    permission_relationships: PropTypes.arrayOf(PropTypes.shape({
      individual_id: PropTypes.number,
      individual_content_type: PropTypes.string,
      permission: PropTypes.oneOf(RELATIONSHIP_PERMISSION_OPTIONS),
    })),
  }),
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['title', 'category', 'permission_relationships'])),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewSopForm;
