import { createEntityPermissionSelector, createEntitySelector } from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { ENTITY_COMMENT } from 'constants/schemas';
import { createSelector } from '@reduxjs/toolkit';
import { momentComparator } from 'utils/date.utils';

export const selectComments = (state) => state[ENTITY][ENTITY_COMMENT];
export const selectComment = createEntitySelector(selectComments);
export const selectCommentPermission = createEntityPermissionSelector(selectComment);

export const selectEntityComments = createSelector(
  (state) => selectComments(state),
  (state, genericId) => genericId,
  (comments, { id, type } = {}) => {
    const { byId, allIds } = comments;
    const genericIdFilter = (_id) => (
      byId[_id].content_type === type &&
      String(byId[_id].object_id) === String(id)
    );
    const createdByComparator = (id1, id2) => -momentComparator(byId[id1].created, byId[id2].created);
    return allIds.filter(genericIdFilter).sort(createdByComparator);
  },
);
