import { EditorHooks } from '@acheloisbiosoftware/absui.core';
import { useAuthAccessToken } from 'hooks/store.hooks';
import { useMemo } from 'react';

const { useEditorWsProvider } = EditorHooks;

const useWsProvider = (room) => {
  const access = useAuthAccessToken();
  const wsOpts = useMemo(() => ({ params: { token: access }}), [access]);
  return useEditorWsProvider(process.env.REACT_APP_WS_BASE_URL, room, wsOpts);
};

export default useWsProvider;
