import { ACTIVE, INACTIVE } from 'constants/user.constants';
import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import React, { useMemo } from 'react';
import {
  useDepartment,
  useDepartmentList,
  useFetchListing,
  useMyGlobalPermission,
  useTeam,
  useTeamList,
  useUpdateEntity,
  useUser,
  useUserHasLoaded,
  useUserRole,
  useUserRoleList,
} from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import Status from 'components/Status';
import { formatDate } from 'utils/date.utils';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { userActions } from 'store/entity';

function UserFields(props) {
  const { id } = props;
  const readOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const user = useUser(id);
  const {
    first_name,
    last_name,
    username,
    date_joined,
    last_login,
    is_active,
    user_roles,
    teams,
    departments,
  } = user ?? {};
  const {
    firstNameHasLoaded,
    lastNameHasLoaded,
    usernameHasLoaded,
    dateJoinedHasLoaded,
    lastLoginHasLoaded,
    isActiveHasLoaded,
    userRolesHasLoaded,
    teamsHasLoaded,
    departmentsHasLoaded,
  } = useUserHasLoaded(id, {
    firstNameHasLoaded: 'first_name',
    lastNameHasLoaded: 'last_name',
    usernameHasLoaded: 'username',
    dateJoinedHasLoaded: 'date_joined',
    lastLoginHasLoaded: 'last_login',
    isActiveHasLoaded: 'is_active',
    userRolesHasLoaded: 'user_roles',
    teamsHasLoaded: 'teams',
    departmentsHasLoaded: 'departments',
  });

  const canViewUserRoles = useMyGlobalPermission(ENTITY_USER_ROLE, ACTION_VIEW);
  const canViewTeams = useMyGlobalPermission(ENTITY_TEAM, ACTION_VIEW);
  const canViewDepartments = useMyGlobalPermission(ENTITY_DEPARTMENT, ACTION_VIEW);

  const userRoleFetchOptions = useMemo(() => ({ condition: canViewUserRoles }), [canViewUserRoles]);
  useFetchListing(userActions.fetchUserRoles, userRoleFetchOptions);

  const teamFetchOptions = useMemo(() => ({ condition: canViewTeams }), [canViewTeams]);
  useFetchListing(userActions.fetchTeams, teamFetchOptions);

  const departmentFetchOptions = useMemo(() => ({ condition: canViewDepartments }), [canViewDepartments]);
  useFetchListing(userActions.fetchDepartments, departmentFetchOptions);

  const roles = useUserRole(user_roles);
  const allRoles = useUserRoleList();

  const teamObjs = useTeam(teams);
  const allTeams = useTeamList();

  const departmentObjs = useDepartment(departments);
  const allDepartments = useDepartmentList();

  const onChangeNameFactory = (key) => (_, value) => {
    if ((value ?? '').length > 0 && value !== user[key]) {
      update({ type: ENTITY_USER, id, key, value });
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      { !readOnly ? (
        <>
          <Field
            label='First Name'
            value={first_name}
            variant={Field.VARIANTS.TEXT_FIELD}
            textFieldProps={{
              onChange: onChangeNameFactory('first_name'),
            }}
            readOnly={readOnly}
            loading={!firstNameHasLoaded}
          />
          <Field
            label='Last Name'
            value={last_name}
            variant={Field.VARIANTS.TEXT_FIELD}
            textFieldProps={{
              onChange: onChangeNameFactory('last_name'),
            }}
            readOnly={readOnly}
            loading={!lastNameHasLoaded}
          />
        </>
      ) : null}
      <Field
        label='Username'
        value={username}
        loading={!usernameHasLoaded}
      />
      <Field label='Status' loading={!isActiveHasLoaded}>
        <Status>{is_active ? ACTIVE : INACTIVE}</Status>
      </Field>
      <Field
        label='Date Joined'
        value={formatDate(date_joined)}
        loading={!dateJoinedHasLoaded}
      />
      <Field
        label='Last Login'
        value={formatDate(last_login)}
        empty={!isDefined(last_login)}
        loading={!lastLoginHasLoaded}
      />
      <Field
        label={formatType(ENTITY_USER_ROLE, { plural: true })}
        value={roles}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: allRoles,
          onChange: (newRoles) => update({ type: ENTITY_USER, id, key: 'user_roles', value: newRoles.map((r) => r.id) }),
          disableCreateOption: true,
          isOptionEqualToValue: (option, value) => option.id === value.id,
        }}
        readOnly={readOnly || !canViewUserRoles}
        empty={roles?.length === 0}
        loading={!userRolesHasLoaded}
      />
      <Field
        label={formatType(ENTITY_TEAM, { plural: true })}
        value={teamObjs}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: allTeams,
          onChange: (newTeams) => update({ type: ENTITY_USER, id, key: 'teams', value: newTeams.map((t) => t.id) }),
          disableCreateOption: true,
          isOptionEqualToValue: (option, value) => option.id === value.id,
        }}
        readOnly={readOnly || !canViewTeams}
        empty={teamObjs?.length === 0}
        loading={!teamsHasLoaded}
      />
      <Field
        label={formatType(ENTITY_DEPARTMENT, { plural: true })}
        value={departmentObjs}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: allDepartments,
          onChange: (newDepartments) => update({ type: ENTITY_USER, id, key: 'departments', value: newDepartments.map((d) => d.id) }),
          disableCreateOption: true,
          isOptionEqualToValue: (option, value) => option.id === value.id,
        }}
        readOnly={readOnly || !canViewDepartments}
        empty={departmentObjs?.length === 0}
        loading={!departmentsHasLoaded}
      />
    </Box>
  );
}

UserFields.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserFields;
