import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@acheloisbiosoftware/absui.core';
import { useColumns } from '../DataTableContext';

function FieldColumn(props) {
  const { column, setColumn } = props;
  const options = useColumns();
  return (
    <TextField
      size='small'
      variant='standard'
      label='Column'
      select
      value={column ?? ''}
      SelectProps={{
        renderValue: (col) => col?.label ?? '',
      }}
      onChange={(_, newValue) => setColumn(newValue?.id)}
      sx={{ width: 150 }}
    >
      { options.map((col) => (
        <MenuItem
          key={`filterColumn_${col.id}`}
          value={col}
          label={col.label}
        />
      ))}
    </TextField>
  );
}

FieldColumn.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  setColumn: PropTypes.func.isRequired,
};

export default FieldColumn;
