import { ACTION_DELETE, ACTION_UPDATE, ACTION_VIEW, RELATIONSHIP_PERMISSION_OWNER } from 'constants/permission.constants';
import { usePermissionRelationship, usePermissionRelationshipPermission } from 'hooks/store.hooks';

import AccessRow from './AccessRow';
import PropTypes from 'prop-types';
import React from 'react';
import { permissionActions } from 'store/entity';
import { useDispatch } from 'react-redux';

function PermissionRelationshipRow(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const individualId = usePermissionRelationship(id, {
    id: 'individual_id',
    type: 'individual_content_type',
  });
  const canView = usePermissionRelationshipPermission(id, ACTION_VIEW);
  const canUpdate = usePermissionRelationshipPermission(id, ACTION_UPDATE);
  const canDelete = usePermissionRelationshipPermission(id, ACTION_DELETE);
  const permission = usePermissionRelationship(id, 'permission');
  const isOwner = permission === RELATIONSHIP_PERMISSION_OWNER;

  const onUpdatePermission = async (newPermission) => {
    if (!canUpdate) return;
    await dispatch(permissionActions.patchPermissionRelationship({
      id,
      data: {
        permission: newPermission,
        is_inherited: false,
      },
    }));
  };

  const onDeletePermission = async () => {
    if (!canDelete) return;
    await dispatch(permissionActions.deletePermissionRelationship({ id }));
  };

  return (
    <AccessRow
      individualId={individualId}
      permission={permission}
      onChange={isOwner ? null : onUpdatePermission}
      onDelete={isOwner ? null : onDeletePermission}
      canUpdate={Boolean(canUpdate && !isOwner)}
      canDelete={Boolean(canDelete && !isOwner)}
      hidden={!canView}
    />
  );
}

PermissionRelationshipRow.propTypes = {
  id: PropTypes.number.isRequired,
};

export default PermissionRelationshipRow;
