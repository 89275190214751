import * as AttachmentService from 'services/AttachmentService';
import * as schemas from 'constants/schemas';

import { electron, inBrowser } from 'utils/electron.utils';

import { createServiceThunk } from 'store/store.actions.utils';

export const fetchAttachments = createServiceThunk({
  name: `${schemas.ENTITY_ATTACHMENT}/fetchAttachments`,
  service: AttachmentService.fetchAttachments,
  schema: [schemas.attachment],
  isPaginated: true,
});

export const createAttachment = createServiceThunk({
  name: `${schemas.ENTITY_ATTACHMENT}/createAttachment`,
  service: AttachmentService.createAttachment,
  schema: schemas.attachment,
});

export const fetchAttachment = createServiceThunk({
  name: `${schemas.ENTITY_ATTACHMENT}/fetchAttachment`,
  service: AttachmentService.fetchAttachment,
  schema: schemas.attachment,
});

export const patchAttachment = createServiceThunk({
  name: `${schemas.ENTITY_ATTACHMENT}/patchAttachment`,
  service: AttachmentService.patchAttachment,
  schema: schemas.attachment,
});

export const checkinAttachment = createServiceThunk({
  name: `${schemas.ENTITY_ATTACHMENT}/checkinAttachment`,
  service: AttachmentService.checkinAttachment,
  schema: schemas.attachment,
  preprocessingFn: (args) => {
    if (!inBrowser) {
      electron.ipcRenderer.send('drive:stopTracking', { id: args.uuid });
    }
    return args;
  },
});

export const checkoutAttachment = createServiceThunk({
  name: `${schemas.ENTITY_ATTACHMENT}/checkoutAttachment`,
  service: AttachmentService.checkoutAttachment,
  schema: schemas.attachment,
});
