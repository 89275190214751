import * as TaskService from 'services/TaskService';
import * as schemas from 'constants/schemas';
import * as taskSelectors from './task.selectors';

import { createServiceThunk } from 'store/store.actions.utils';

export const fetchTasks = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/fetchTasks`,
  service: TaskService.fetchTasks,
  schema: [schemas.task],
  isPaginated: true,
});

export const createTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/createTask`,
  service: TaskService.createTask,
  schema: schemas.task,
});

export const fetchTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/fetchTask`,
  service: TaskService.fetchTask,
  schema: schemas.task,
});

export const patchTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/patchTask`,
  service: TaskService.patchTask,
  schema: schemas.task,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: taskSelectors.selectTaskUpdates(getState(), id),
  }),
});

export const deleteTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/deleteTask`,
  service: TaskService.deleteTask,
  schema: schemas.task,
});

export const deleteTaskPermanently = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/deleteTaskPermanently`,
  service: TaskService.deleteTaskPermanently,
  schema: schemas.task,
});

export const checkinTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/checkinTask`,
  service: TaskService.checkinTask,
  schema: schemas.task,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: taskSelectors.selectTaskUpdates(getState(), id),
  }),
});

export const checkoutTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/checkoutTask`,
  service: TaskService.checkoutTask,
  schema: schemas.task,
});

export const cloneTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/cloneTask`,
  service: TaskService.cloneTask,
  schema: schemas.task,
});

export const generateTaskTemplate = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/generateTaskTemplate`,
  service: TaskService.generateTaskTemplate,
  schema: schemas.task,
});

export const instantiateTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/instantiateTask`,
  service: TaskService.instantiateTask,
  schema: schemas.task,
});

export const launchTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/launchTask`,
  service: TaskService.launchTask,
  schema: schemas.task,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: taskSelectors.selectTaskUpdates(getState(), id),
  }),
});

export const restoreTask = createServiceThunk({
  name: `${schemas.ENTITY_TASK}/restoreTask`,
  service: TaskService.restoreTask,
  schema: schemas.task,
});
