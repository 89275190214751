import { ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import { useMyGlobalPermission, useTeam, useTeamHasLoaded, useUpdateEntity } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import UserTable from 'components/UserTable';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';

function TeamUsers(props) {
  const { id } = props;
  const update = useUpdateEntity();
  const isReadOnly = useEntityIsReadOnly();
  const userIds = useTeam(id, 'users');
  const usersHasLoaded = useTeamHasLoaded(id, 'users');
  const canViewUser = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);

  const onAssign = (selection) => {
    update({ type: ENTITY_TEAM, id, key: 'users', value: selection });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant='h6'>Members</Typography>
      <UserTable
        userIds={userIds ?? []}
        loading={!usersHasLoaded}
        onAssign={!isReadOnly && canViewUser ? onAssign : null}
        initAssignment={userIds ?? []}
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 2,
        }}
      />
    </Box>
  );
}

TeamUsers.propTypes = {
  id: PropTypes.number.isRequired,
};

export default TeamUsers;
