import { getInitialState } from './scope.initialState';
import { reduceLogout } from 'store/store.reducers.utils';

const updateScopeVariable = (state, action) => {
  const { genericId, uuid, variable } = action.payload;
  const { id, type } = genericId ?? {};
  state.scopeUpdates[type] = state.scopeUpdates[type] ?? {};
  state.scopeUpdates[type][id] = state.scopeUpdates[type][id] ?? {};
  state.scopeUpdates[type][id][uuid] = { ...state.scopeUpdates[type][id][uuid], ...variable };
};

const resetScopeUpdates = (state, action) => {
  const { genericId } = action.payload;
  const { id, type } = genericId ?? {};
  delete state.scopeUpdates?.[type]?.[id];
};

export const reducers = {
  updateScopeVariable,
  resetScopeUpdates,
};
export const extraReducers = (builder) => {
  reduceLogout(builder, getInitialState);
};
