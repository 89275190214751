import React, { useState } from 'react';
import { useMyId, useUser } from 'hooks/store.hooks';

import { AdminIcon } from 'constants/icon.constants';
import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { ENTITY_USER } from 'constants/schemas';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { UploadButton } from '@acheloisbiosoftware/absui.core';
import { notificationActions } from 'store/notification';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/entity';

const MAX_AVATAR_SIZE_MB = 5;

function ProfileHeader() {
  const dispatch = useDispatch();
  const [updateAvatarLoading, setUpdateAvatarLoading] = useState(false);
  const myId = useMyId();
  const user = useUser(myId);
  const {
    full_name,
    email,
    is_superuser,
    is_staff,
  } = user;

  const uploadProfileImage = (event) => {
    const { files } = event.target;
    if (files && files.length === 1) {
      const file = files[0];
      const sizeMb = file.size / 1024 / 1024;
      if (sizeMb > MAX_AVATAR_SIZE_MB) {
        dispatch(notificationActions.enqueueSnackbar({
          key: `profile-avatar-too-large-${Date.now()}`,
          message: `Profile picture must be less than ${MAX_AVATAR_SIZE_MB} MB`,
          variant: 'error',
        }));
        event.target.value = null;
      } else {
        setUpdateAvatarLoading(true);
        dispatch(userActions.patchUserPreferences({ id: myId, data: { avatar: files[0] }}))
          .unwrap()
          .finally(() => setUpdateAvatarLoading(false));
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <UploadButton
        icon
        accepts={['.png', '.jpg', '.jpeg']}
        onChange={uploadProfileImage}
        loading={updateAvatarLoading}
        sx={[
          { p: 0, position: 'relative', overflow: 'hidden' },
          { '& > *': { transition: (theme) => theme.transitions.create('opacity') }},
          { '&:hover > *': { opacity: (theme) => theme.opacity.secondary }},
          { '&:hover > span': { display: 'inline-block', opacity: (theme) => theme.opacity.primary }},
        ]}
      >
        <Avatar
          size={64}
          id={myId}
          type={ENTITY_USER}
        />
        <Typography
          component='span'
          variant='caption'
          sx={{
            position: 'absolute',
            bottom: 0,
            color: 'common.white',
            bgcolor: 'common.black',
            width: 1,
            opacity: 0,
          }}
        >
          Edit
        </Typography>
      </UploadButton>
      <Box sx={{ mx: 2 }}>
        <Typography
          variant='h5'
          sx={{ lineHeight: 1.2 }}
        >
          {full_name ?? <Skeleton width={150} />}
        </Typography>
        <Typography
          variant='subtitle2'
          color='text.secondary'
        >
          {email ?? <Skeleton width={200} />}
        </Typography>
      </Box>
      { is_superuser || is_staff ? (
        <Chip
          icon={<AdminIcon />}
          label='Admin'
          sx={{ mr: 0, ml: 'auto' }}
        />
      ) : null}
    </Box>
  );
}

export default ProfileHeader;
