import { EditorConstants } from '@acheloisbiosoftware/absui.core';
import ElectronFileElement from './ElectronFileElement';
import React from 'react';
import { inBrowser } from 'utils/electron.utils';
import { useS3Storage } from './S3Storage';

const { BLOCK_FILE } = EditorConstants;

export default {
  plugin: useS3Storage,
  renderElement: (props) => (!inBrowser && props?.element?.type === BLOCK_FILE ? <ElectronFileElement {...props} /> : null),
};
