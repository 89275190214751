import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE, COL_TYPE_SELECT, COL_TYPE_USER, FILTER_TYPE_SELECT } from 'components/DataTable';
import { EntityActionState, selectEntityActions } from 'components/EntityActions';
import React, { useMemo } from 'react';
import { formatType, getIcon } from 'utils/entity.utils';
import { getDraggableProps, getDroppableProps } from 'components/DirectoryDndProvider';

import { ACTION_VIEW } from 'constants/permission.constants';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useDirectory } from 'hooks/store.hooks';

const TEMPLATE_SUFFIX = '-template';

function WorkspaceTable(props) {
  const { ids, columnProps, withDnd, ...restProps } = props;
  const directories = useDirectory(ids);

  const { rows } = useMemo(
    () => directories.reduce((result, directory) => {
      if (!directory?.permissions?.[ACTION_VIEW]) return result;
      result.rows.push({
        ...directory,
        ...(withDnd ? {
          droppableProps: getDroppableProps(directory, { key: 'WorkspaceTable' }),
          draggableProps: getDraggableProps(directory, ENTITY_DIRECTORY, { key: 'WorkspaceTable' }),
        } : {}),
      });
      return result;
    }, { rows: []}),
    [directories, withDnd],
  );

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      hidden: true,
      ...columnProps?.checkbox,
    },
    {
      id: 'type',
      label: 'Type',
      field: 'type',
      groupValueGetter: ({ row, value }) => `${value}${row?.is_template ? TEMPLATE_SUFFIX : ''}`,
      filterValueGetter: ({ row, value }) => `${value}${row?.is_template ? TEMPLATE_SUFFIX : ''}`,
      renderValue: ({ value, row }) => {
        const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: value });
        return (
          <Stack direction='row' alignItems='center'>
            { Icon ? <Icon colored template={row?.is_template} /> : null }
          </Stack>
        );
      },
      renderGroupValue: ({ value }) => {
        const isTemplate = value.endsWith(TEMPLATE_SUFFIX);
        const type = isTemplate ? value.slice(0, -TEMPLATE_SUFFIX.length) : value;
        const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: type });
        return (
          <Stack direction='row' alignItems='center'>
            { Icon ? <Icon colored template={isTemplate} /> : null }
            <Typography variant='subtitle2' sx={{ ml: 1 }} noWrap>
              {formatType(ENTITY_DIRECTORY, { directoryType: type, template: isTemplate })}
            </Typography>
          </Stack>
        );
      },
      cellProps: ({ isGrouping }) => (isGrouping ? {} : { sx: { width: 20, pr: 0 }}),
      filterType: FILTER_TYPE_SELECT,
      filterOptionProps: (value) => {
        const isTemplate = value.endsWith(TEMPLATE_SUFFIX);
        const type = isTemplate ? value.slice(0, -TEMPLATE_SUFFIX.length) : value;
        const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: type });
        return {
          icon: Icon ? <Icon colored template={isTemplate} /> : null,
          label: formatType(ENTITY_DIRECTORY, { directoryType: type, template: isTemplate }),
        };
      },
      groupable: true,
      noHeader: true,
      ...columnProps?.type,
    },
    {
      id: 'name',
      label: 'Name',
      field: 'name',
      noWrap: true,
      primary: true,
      renderValue: ({ value }) => value || 'Untitled',
      ...columnProps?.name,
    },
    {
      id: 'owner',
      label: 'Owner',
      field: 'created_by',
      type: COL_TYPE_USER,
      secondary: true,
      cellProps: { sx: { width: 150 }},
      ...columnProps?.owner,
    },
    {
      id: 'created',
      label: 'Created',
      field: 'created',
      type: COL_TYPE_DATE,
      groupable: true,
      secondary: true,
      cellProps: { sx: { width: 200 }},
      ...columnProps?.created,
    },
    {
      id: 'actions',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      inMenu: true,
      actions: ({ row, state }) => selectEntityActions(state, row.id, ENTITY_DIRECTORY),
      ...columnProps?.actions,
    },
  ], [columnProps]);

  return (
    <EntityActionState>
      <DataTable
        rows={rows}
        columns={columns}
        {...restProps}
        initState={{
          orderBy: 'created',
          order: 'desc',
          ...restProps?.initState,
        }}
      />
    </EntityActionState>
  );
}

WorkspaceTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  withDnd: PropTypes.bool,
  columnProps: PropTypes.object,
};

WorkspaceTable.defaultProps = {
  ids: [],
  columnProps: {},
};

export default WorkspaceTable;
