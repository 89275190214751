import { ACTION_SIGN, ACTION_VIEW } from 'constants/permission.constants';
import { ENTITY_EXPERIMENT, ENTITY_SIGNATURE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import {
  FunctionProp,
  GenericProp,
  Prop,
  SelectorProp,
  entitySelectors,
  signatureActions,
  signatureSelectors,
  taskActions,
  userSelectors,
} from 'store/entity';
import { IN_PROGRESS, TO_DO } from 'constants/task.constants';
import React, { useMemo } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import { useFetchListing, useMyGlobalPermission, useMyId, useSignatureList, useTaskList } from './store.hooks';

import Typography from '@mui/material/Typography';
import { authSelectors } from 'store/auth';
import { getGenericId } from 'utils/generic.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { momentComparator } from 'utils/date.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function Bold(props) {
  return (
    <Typography
      variant='inherit'
      component='span'
      {...props}
      sx={mergeSx(
        { fontWeight: 'fontWeightBold' },
        props?.sx,
      )}
    />
  );
}

Bold.propTypes = { sx: sxPropType };

const genericIdProp = new Prop({ id: 'object_id', type: 'content_type' });
const selectGenericId = (state, signature) => signatureSelectors.selectSignatureWorkflow(
  state, signature.signature_workflow, genericIdProp,
);
const relatedObjNameProp = new GenericProp({
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_SOP]: 'title',
});
const signatureListParams = {
  prop: new Prop({
    id: 'id',
    assigned: 'created',
    assignedBy: new SelectorProp((state, signature) => userSelectors.selectUserName(state, signature.created_by)),
    Icon: new SelectorProp((state, signature) => {
      const genericId = selectGenericId(state, signature);
      return entitySelectors.selectEntityIcon(state, genericId);
    }),
    relatedObjName: new SelectorProp((state, signature) => {
      const genericId = selectGenericId(state, signature);
      return entitySelectors.selectEntity(state, genericId, relatedObjNameProp);
    }),
    path: new SelectorProp((state, signature) => {
      const genericId = selectGenericId(state, signature);
      return getPath(genericId);
    }),
  }),
  filter: (signature) => signature.permissions?.[ACTION_SIGN],
};

const taskStatuses = [TO_DO, IN_PROGRESS];
const taskListParams = {
  prop: new Prop({
    id: 'id',
    assigned: 'created',
    assignedBy: new SelectorProp((state, task) => userSelectors.selectUserName(state, task.created_by)),
    title: 'long_title',
    path: new FunctionProp((task) => getPath(getGenericId(task.id, ENTITY_TASK))),
  }),
  filter: (task, state) => (
    !task.is_template &&
    taskStatuses.includes(task.status) &&
    (task.assignees ?? []).includes(authSelectors.selectMyId(state))
  ),
};

function useMyNotifications(fetchNotifications = true) {
  const myId = useMyId();
  const signatureFetchOptions = useMemo(() => ({
    params: { filters: {
      signee: myId,
      is_signed: false,
      is_rejected: false,
    }},
    condition: fetchNotifications,
  }), [myId, fetchNotifications]);
  const signaturesLoading = useFetchListing(signatureActions.fetchSignatures, signatureFetchOptions);

  const canViewTasks = useMyGlobalPermission(ENTITY_TASK, ACTION_VIEW);
  const taskFetchOptions = useMemo(() => ({
    params: { filters: {
      assignee: myId,
      statuses: taskStatuses,
      is_template: false,
    }},
    condition: canViewTasks && fetchNotifications,
  }), [myId, canViewTasks, fetchNotifications]);
  const tasksLoading = useFetchListing(taskActions.fetchTasks, taskFetchOptions);

  const signatures = useSignatureList(signatureListParams);
  const tasks = useTaskList(taskListParams);

  const notifications = useMemo(
    () => [
      ...signatures.map((signature) => {
        const IconRelatedObj = signature.Icon;
        return {
          id: signature.id,
          type: ENTITY_SIGNATURE,
          assigned: signature.assigned,
          path: signature.path,
          message: (
            <Typography variant='body2'>
              <Bold>{signature.assignedBy}</Bold>
              {' '}requested you sign{' '}
              { IconRelatedObj ? (
                <IconRelatedObj
                  colored
                  fontSize='small'
                  sx={{ verticalAlign: 'middle' }}
                />
              ) : null}
              {' '}
              <Bold>{signature.relatedObjName}</Bold>
            </Typography>
          ),
        };
      }),
      ...tasks.map((task) => ({
        id: task.id,
        type: ENTITY_TASK,
        assigned: task.assigned,
        path: task.path,
        message: (
          <Typography variant='body2'>
            <Bold>{task.assignedBy}</Bold>
            {' '}assigned you the {formatType(ENTITY_TASK, { lowercase: true })}:{' '}
            <Bold>{task.title}</Bold>
          </Typography>
        ),
      })),
    ].sort((n1, n2) => momentComparator(n1.assigned, n2.assigned)),
    [signatures, tasks],
  );

  return useMemo(() => ({
    notifications,
    loading: signaturesLoading || tasksLoading,
  }), [notifications, signaturesLoading, tasksLoading]);
}

export default useMyNotifications;
