import { GroupedRowOpenStateContext, useColumns, useGroupByState } from './DataTableContext';
import React, { useMemo, useState } from 'react';

import CellGrouped from './CellGrouped';
import PropTypes from 'prop-types';
import Row from './Row';
import TableRow from '@mui/material/TableRow';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function RowGrouped(props) {
  const {
    row,
    onRowClick,
    isLastRow,
    cellProps,
    ...restProps
  } = props;
  const columns = useColumns();
  const { id, childrenRows } = row;
  const { groupBy } = useGroupByState();
  const [open, setOpen] = useState(false);
  const openState = useMemo(() => ({ open, setOpen }), [open, setOpen]);

  const cellSx = mergeSx(
    isLastRow && !open ? { borderBottom: 0 } : {},
    cellProps?.sx,
  );

  return (
    <GroupedRowOpenStateContext.Provider value={openState}>
      <TableRow
        hover
        onClick={() => setOpen(!open)}
        {...restProps}
        sx={mergeSx(
          { cursor: 'pointer' },
          restProps?.sx,
        )}
      >
        { columns.map((col) => (
          col.hidden && groupBy !== col.id ? null : (
            <CellGrouped
              {...cellProps}
              key={`row${id}_col${col.id}`}
              row={row}
              columnId={col.id}
              sx={cellSx}
            />
          )
        ))}
      </TableRow>
      { open ? childrenRows.map((childRow, idx) => (
        <Row
          key={childRow.id}
          rowId={childRow.id}
          onClick={onRowClick}
          isLastRow={Boolean(isLastRow && idx + 1 === childrenRows.length)}
        />
      )) : null}
    </GroupedRowOpenStateContext.Provider>
  );
}

RowGrouped.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    childrenRows: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    })).isRequired,
  }).isRequired,
  onRowClick: PropTypes.any,
  isLastRow: PropTypes.bool,
  cellProps: PropTypes.object,
};

export default RowGrouped;
