import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import LogoIcon from 'assets/LogoIcon';
import { Link as RouterLink } from 'react-router-dom';
import { keyframes } from '@mui/system';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

const containerSx = { mx: 'auto ' };
const iconSx = { fontSize: 28 };

const NUM_SPINS = 1; // reps
const SPIN_DURATION = 1; // seconds
const ANIMATION_REPS = 1; // reps
const SPIN = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(${(NUM_SPINS * 360)}deg);
  }
`;

const buttonSx = { p: 0.5 };
const buttonAnimationSx = { animation: `${SPIN} ${SPIN_DURATION}s ${ANIMATION_REPS}` };

function AppSource() {
  const [animate, setAnimate] = useState(false);
  const sx = useMemo(() => mergeSx(buttonSx, animate ? buttonAnimationSx : {}), [animate]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, (SPIN_DURATION * 1000 * ANIMATION_REPS) + 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [animate]);


  return (
    <Box sx={containerSx}>
      <Button
        icon
        component={RouterLink}
        to='/'
        sx={sx}
        onMouseEnter={() => setAnimate(true)}
      >
        <LogoIcon sx={iconSx} />
      </Button>
    </Box>
  );
}

export default AppSource;
