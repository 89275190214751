import ExperimentSectionBase from './ExperimentSectionBase';
import ExperimentSubsection from './ExperimentSubsection';
import PropTypes from 'prop-types';
import React from 'react';
import { useExpSection } from 'hooks/store.hooks';

function ExperimentSection(props) {
  const { sectionId } = props;
  const subsectionIds = useExpSection(sectionId, 'subsections');
  const title = useExpSection(sectionId, 'title');
  const sequence = useExpSection(sectionId, 'sequence');

  return (
    <ExperimentSectionBase
      containerProps={{ id: `section_${sectionId}` }}
      sectionHeaderProps={{
        title,
        prefix: `${sequence + 1}.`,
      }}
    >
      { (subsectionIds ?? []).map((subsectionId) => (
        <ExperimentSubsection
          key={`subsection_${subsectionId}`}
          subsectionId={subsectionId}
        />
      ))}
    </ExperimentSectionBase>
  );
}

ExperimentSection.propTypes = {
  sectionId: PropTypes.number.isRequired,
};

export default ExperimentSection;
