import {
  AUTH,
  ENTITY,
  NAVIGATION,
  NOTIFICATION,
  PREFERENCE,
  SCOPE,
} from 'store/store.constants';

import { authReducer } from './auth';
import { combineReducers } from 'redux';
import { entityReducer } from './entity';
import { navigationReducer } from './navigation/navigation.slice';
import { notificationReducer } from './notification';
import { preferenceReducer } from './preference';
import { scopeReducer } from './scope';
import { withReduxStateSync } from 'redux-state-sync';

export default withReduxStateSync(combineReducers({
  [AUTH]: authReducer,
  [ENTITY]: entityReducer,
  [NAVIGATION]: navigationReducer,
  [NOTIFICATION]: notificationReducer,
  [PREFERENCE]: preferenceReducer,
  [SCOPE]: scopeReducer,
}));
