import { ENTITY_ACTION_DELETE, ENTITY_ACTION_OPEN, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_EXPERIMENT, ENTITY_SOP } from 'constants/schemas';
import React, { useCallback } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import { useExperiment, useSop, useSopPermission, useUpdateEntity, useUserName } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { DATE_FMT_TIME } from 'constants/date.constants';
import { DELETE_SOFT } from 'constants/deletion.constants';
import ExperimentSectionBase from './ExperimentSectionBase';
import Field from 'components/Field';
import Link from 'components/Link';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import Status from 'components/Status';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';

const toolbarProps = { sx: { top: 60 }, elevation: 2 };

function DetailsSection(props) {
  const { expId } = props;
  const navigate = useNavigate();
  const experiment = useExperiment(expId);
  const relatedSopId = experiment?.related_sop;
  const canViewRelatedSop = useSopPermission(relatedSopId, ACTION_VIEW);
  const relatedSopTitle = useSop(relatedSopId, 'long_title');
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(expId, ENTITY_EXPERIMENT);
  const owner = useUserName(experiment?.created_by);

  const onTitleChange = (_, value) => {
    if ((value ?? '').length > 0 && value !== experiment.title) {
      update({ type: ENTITY_EXPERIMENT, id: expId, key: 'title', value });
    }
  };

  const onDescriptionChange = (value) => {
    update({ type: ENTITY_EXPERIMENT, id: expId, key: 'description', value });
  };

  const onDeleteSuccess = useCallback((res, { deletionType }) => {
    if (deletionType !== DELETE_SOFT) {
      navigate('/');
    }
  }, [navigate]);

  const extraActions = useEntityActions(expId, ENTITY_EXPERIMENT, {
    exclude: [ENTITY_ACTION_OPEN],
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { label: `Delete ${formatType(ENTITY_EXPERIMENT, { template: experiment?.is_template })}`, onDeleteSuccess }},
  });

  if (!experiment) return null;
  return (
    <EntityActionState>
      <ExperimentSectionBase
        containerProps={{ id: 'section_details' }}
        sectionHeaderProps={{
          title: experiment.section_name,
          prefix: '1.',
          extraActions: { inMenu: extraActions },
        }}
      >
        <Box sx={{ px: 3, mt: 1 }}>
          <Field
            label={`${formatType(ENTITY_EXPERIMENT)} Name`}
            value={experiment.title}
            variant={Field.VARIANTS.TEXT_FIELD}
            readOnly={isReadOnly}
            textFieldProps={{ onChange: onTitleChange }}
          />
          <Field label={`Linked ${formatType(ENTITY_SOP)}`}>
            { canViewRelatedSop ? (
              <Link to={getPath(getGenericId(relatedSopId, ENTITY_SOP))}>
                {relatedSopTitle}
              </Link>
            ) : (
              <Tooltip title={`You do not have permission to view this ${formatType(ENTITY_SOP, { lowercase: true })}`} arrow>
                <Typography color='text.disabled' sx={{ width: 'fit-content' }}>[Content Hidden]</Typography>
              </Tooltip>
            )}
          </Field>
          <Field label='Responsible Person'>{owner}</Field>
          <Field label='Date Created'>{formatDate(experiment.created, DATE_FMT_TIME)}</Field>
          {experiment.is_template ? null : <Field label='Status'><Status>{experiment.status}</Status></Field>}
          <Field label='Location(s)'><Locations directoryItem={experiment.directory_item} /></Field>
          <Field
            label='Description'
            value={experiment.description}
            variant={Field.VARIANTS.TEXT_EDITOR}
            readOnly={isReadOnly}
            interactable={!isReadOnly}
            onChangeSerialized={onDescriptionChange}
            onChange={editorChangeHandler}
            toolbarProps={toolbarProps}
          />
        </Box>
      </ExperimentSectionBase>
    </EntityActionState>
  );
}

DetailsSection.propTypes = {
  expId: PropTypes.number.isRequired,
};

export default DetailsSection;
