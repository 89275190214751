import { AllDocumentsIcon, SopIcon, TaskIcon, TemplateIcon } from 'constants/icon.constants';
import { ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useMemo } from 'react';
import { useDirectoryItemList, useRecentlyViewed } from 'hooks/store.hooks';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@acheloisbiosoftware/absui.core';
import Divider from '@mui/material/Divider';
import DocumentTable from 'components/DocumentTable';
import Link from 'components/Link';
import { NavButton } from 'views/AppSidebar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WidgetContainer from './WidgetContainer';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

const navButtonProps = {
  size: 36,
};

const contentTypes = [ENTITY_NOTE, ENTITY_EXPERIMENT, ENTITY_SOP, ENTITY_TASK];

function DocumentsWidget() {
  const recentlyViewed = useRecentlyViewed();

  const listParams = useMemo(() => ({
    prop: { id: 'object_id', type: 'content_type' },
    filter: (directoryItem) => (
      contentTypes.includes(directoryItem.content_type) &&
      isDefined(recentlyViewed.find((recent) => recent.id === directoryItem.object_id && recent.type === directoryItem.content_type))
    ),
    sort: (directoryItem1, directoryItem2) => {
      const idx1 = recentlyViewed.findIndex((recent) => recent.id === directoryItem1.object_id && recent.type === directoryItem1.content_type);
      const idx2 = recentlyViewed.findIndex((recent) => recent.id === directoryItem2.object_id && recent.type === directoryItem2.content_type);
      return idx1 - idx2;
    },
  }), [recentlyViewed]);
  const genericIds = useDirectoryItemList(listParams);

  const columnProps = useMemo(() => ({
    owner: { hidden: true },
    status: { hidden: true },
    created: { hidden: true },
  }), []);

  return (
    <WidgetContainer>
      <Typography variant='h4'>Documents</Typography>
      <Stack
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        sx={{ maxWidth: 800, width: 1, mx: 'auto', mb: 3 }}
      >
        <NavButton label='Docs' to='/all-documents' Icon={AllDocumentsIcon} {...navButtonProps} />
        <NavButton label={formatType(ENTITY_SOP, { plural: true })} to='/sops' Icon={SopIcon} {...navButtonProps} />
        <NavButton label={formatType(ENTITY_TASK, { plural: true })} to='/tasks' Icon={TaskIcon} {...navButtonProps} />
        <NavButton label='Templates' to='/templates' Icon={TemplateIcon} {...navButtonProps} />
      </Stack>
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Recently Viewed</Typography>
      <Divider />
      { genericIds.length === 0 ? (
        <Typography sx={{ my: 2 }} variant='body2' color='text.disabled'>No recently viewed documents...</Typography>
      ) : (
        <DocumentTable
          ids={genericIds}
          columnProps={columnProps}
          withSidePreview={false}
          stateInSearchParams={false}
          disableFilter
          disableGroupBy
          noHeader
          initState={{ orderBy: null }}
          tableProps={{ size: 'small' }}
          sx={{ overflow: 'auto' }}
        />
      )}
      <Stack direction='row' justifyContent='flex-end' alignItems='flex-end' sx={{ flexGrow: 1, mt: 1 }}>
        <Button
          size='small'
          variant='text'
          endIcon={<ArrowForwardIcon />}
          component={Link}
          to='/all-documents'
        >
          View All
        </Button>
      </Stack>
    </WidgetContainer>
  );
}

export default DocumentsWidget;
