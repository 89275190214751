import Box from '@mui/material/Box';
import FieldLabel from './FieldLabel';
import FieldValue from './FieldValue';
import PropTypes from 'prop-types';
import React from 'react';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function Field(props) {
  const {
    children,
    label,
    labelProps,
    containerProps,
    valueProps,
    ...restProps
  } = props;
  return (
    <Box {...containerProps}>
      { isDefined(label) ? (
        <FieldLabel {...labelProps}>{label}</FieldLabel>
      ) : null }
      <FieldValue {...restProps} {...valueProps}>{children}</FieldValue>
    </Box>
  );
}

Field.VARIANTS = FieldValue.VARIANTS;

Field.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node,
  labelProps: PropTypes.object,
  containerProps: PropTypes.object,
  valueProps: PropTypes.object,
};

export default Field;
