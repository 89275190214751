import React from 'react';
import SearchResultBase from './SearchResultBase';
import Skeleton from '@mui/material/Skeleton';

function SearchResultLoading() {
  return (
    <SearchResultBase
      icon={<Skeleton variant='circular' width={24} height={24} />}
      primary={<Skeleton variant='text' />}
      secondary={<Skeleton variant='text' sx={{ width: 0.5 }} />}
    />
  );
}

export default SearchResultLoading;
