import * as extraActions from './navigation.extraActions';

import { extraReducers, reducers } from './navigation.reducers';

import { NAVIGATION } from 'store/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './navigation.initialState';

const slice = createSlice({
  name: NAVIGATION,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export * as navigationSelectors from './navigation.selectors';
export const navigationActions = { ...slice.actions, ...extraActions };
export const { reducer: navigationReducer } = slice;
