import { Button } from '@acheloisbiosoftware/absui.core';
import Link from 'components/Link';
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function ResetPasswordSubmitted() {
  return (
    <Stack spacing={2}>
      <Typography variant='h4'>Password changed</Typography>
      <Typography>Your password has been successfully changed.</Typography>
      <Button
        variant='contained'
        color='primary'
        component={Link}
        to='/login'
        data-test='login'
      >
        Log in
      </Button>
    </Stack>
  );
}

export default ResetPasswordSubmitted;
