import * as PermissionService from 'services/PermissionService';
import * as schemas from 'constants/schemas';

import { createServiceThunk } from 'store/store.actions.utils';

// #############################################################################
// ################################ Permissions ################################
// #############################################################################
export const fetchPermissions = createServiceThunk({
  name: `${schemas.ENTITY_PERMISSION}/fetchPermissions`,
  service: PermissionService.fetchPermissions,
  schema: [schemas.permission],
  isPaginated: true,
});


// #############################################################################
// ######################### Permission Relationships ##########################
// #############################################################################
export const createPermissionRelationship = createServiceThunk({
  name: `${schemas.ENTITY_PERMISSION_RELATIONSHIP}/createPermissionRelationship`,
  service: PermissionService.createPermissionRelationship,
  schema: (payload) => (Array.isArray(payload) ? [schemas.permissionRelationship] : schemas.permissionRelationship),
});

export const patchPermissionRelationship = createServiceThunk({
  name: `${schemas.ENTITY_PERMISSION_RELATIONSHIP}/patchPermissionRelationship`,
  service: PermissionService.patchPermissionRelationship,
  schema: schemas.permissionRelationship,
});

export const deletePermissionRelationship = createServiceThunk({
  name: `${schemas.ENTITY_PERMISSION_RELATIONSHIP}/deletePermissionRelationship`,
  service: PermissionService.deletePermissionRelationship,
  schema: schemas.permissionRelationship,
});
