import {
  ENTITY_ATTACHMENT,
  ENTITY_CATEGORY,
  ENTITY_COMMENT,
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_DIRECTORY_ITEM,
  ENTITY_EXPERIMENT,
  ENTITY_EXPERIMENT_SECTION,
  ENTITY_EXPERIMENT_SUBSECTION,
  ENTITY_MASTER_NOTE,
  ENTITY_MASTER_NOTE_ITEM,
  ENTITY_MASTER_NOTE_SECTION,
  ENTITY_NOTE,
  ENTITY_PERMISSION,
  ENTITY_PERMISSION_RELATIONSHIP,
  ENTITY_PIN,
  ENTITY_SIGNATURE,
  ENTITY_SIGNATURE_WORKFLOW,
  ENTITY_SIGNATURE_WORKFLOW_STEP,
  ENTITY_SOP,
  ENTITY_SOP_SECTION,
  ENTITY_SOP_SUBSECTION,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';

import { GenericProp } from './entity.selectors.utils';
import { attachmentSelectors } from './attachment';
import { commentSelectors } from './comment';
import { createSelector } from '@reduxjs/toolkit';
import { directorySelectors } from './directory';
import { experimentSelectors } from './experiment';
import { getIcon } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { noteSelectors } from './note';
import { permissionSelectors } from './permission';
import { pinSelectors } from './pin';
import { signatureSelectors } from './signature';
import { sopSelectors } from './sop';
import { taskSelectors } from './task';
import { userSelectors } from './user';

const ENTITY_TYPE_SELECTOR_MAP = {
  [ENTITY_ATTACHMENT]: attachmentSelectors.selectAttachment,
  [ENTITY_CATEGORY]: sopSelectors.selectCategory,
  [ENTITY_COMMENT]: commentSelectors.selectComment,
  [ENTITY_DEPARTMENT]: userSelectors.selectDepartment,
  [ENTITY_DIRECTORY]: directorySelectors.selectDirectory,
  [ENTITY_DIRECTORY_ITEM]: directorySelectors.selectDirectoryItem,
  [ENTITY_EXPERIMENT]: experimentSelectors.selectExperiment,
  [ENTITY_EXPERIMENT_SECTION]: experimentSelectors.selectSection,
  [ENTITY_EXPERIMENT_SUBSECTION]: experimentSelectors.selectSubsection,
  [ENTITY_MASTER_NOTE]: directorySelectors.selectMasterNote,
  [ENTITY_MASTER_NOTE_ITEM]: directorySelectors.selectMasterNoteItem,
  [ENTITY_MASTER_NOTE_SECTION]: directorySelectors.selectMasterNoteSection,
  [ENTITY_NOTE]: noteSelectors.selectNote,
  [ENTITY_PERMISSION]: permissionSelectors.selectPermission,
  [ENTITY_PERMISSION_RELATIONSHIP]: permissionSelectors.selectPermissionRelationship,
  [ENTITY_PIN]: pinSelectors.selectPin,
  [ENTITY_SIGNATURE]: signatureSelectors.selectSignature,
  [ENTITY_SIGNATURE_WORKFLOW]: signatureSelectors.selectSignatureWorkflow,
  [ENTITY_SIGNATURE_WORKFLOW_STEP]: signatureSelectors.selectSignatureWorkflowStep,
  [ENTITY_SOP]: sopSelectors.selectSop,
  [ENTITY_SOP_SECTION]: sopSelectors.selectSection,
  [ENTITY_SOP_SUBSECTION]: sopSelectors.selectSubsection,
  [ENTITY_TASK]: taskSelectors.selectTask,
  [ENTITY_TEAM]: userSelectors.selectTeam,
  [ENTITY_USER]: userSelectors.selectUser,
  [ENTITY_USER_ROLE]: userSelectors.selectUserRole,
};

const ENTITY_TYPE_PERMISSION_SELECTOR_MAP = {
  [ENTITY_ATTACHMENT]: attachmentSelectors.selectAttachmentPermission,
  [ENTITY_CATEGORY]: null,
  [ENTITY_COMMENT]: commentSelectors.selectCommentPermission,
  [ENTITY_DEPARTMENT]: userSelectors.selectDepartmentPermission,
  [ENTITY_DIRECTORY]: directorySelectors.selectDirectoryPermission,
  [ENTITY_DIRECTORY_ITEM]: directorySelectors.selectDirectoryItemPermission,
  [ENTITY_EXPERIMENT]: experimentSelectors.selectExperimentPermission,
  [ENTITY_EXPERIMENT_SECTION]: null,
  [ENTITY_EXPERIMENT_SUBSECTION]: experimentSelectors.selectSubsectionPermission,
  [ENTITY_MASTER_NOTE]: directorySelectors.selectMasterNotePermission,
  [ENTITY_MASTER_NOTE_ITEM]: null,
  [ENTITY_MASTER_NOTE_SECTION]: null,
  [ENTITY_NOTE]: noteSelectors.selectNotePermission,
  [ENTITY_PERMISSION]: null,
  [ENTITY_PERMISSION_RELATIONSHIP]: permissionSelectors.selectPermissionRelationshipPermission,
  [ENTITY_PIN]: null,
  [ENTITY_SIGNATURE]: signatureSelectors.selectSignaturePermission,
  [ENTITY_SIGNATURE_WORKFLOW]: signatureSelectors.selectSignatureWorkflowPermission,
  [ENTITY_SIGNATURE_WORKFLOW_STEP]: signatureSelectors.selectSignatureWorkflowStepPermission,
  [ENTITY_SOP]: sopSelectors.selectSopPermission,
  [ENTITY_SOP_SECTION]: null,
  [ENTITY_SOP_SUBSECTION]: null,
  [ENTITY_TASK]: taskSelectors.selectTaskPermission,
  [ENTITY_TEAM]: userSelectors.selectTeamPermission,
  [ENTITY_USER]: userSelectors.selectUserPermission,
  [ENTITY_USER_ROLE]: userSelectors.selectUserRolePermission,
};

const ENTITY_TYPE_IN_SPACE_SELECTOR_MAP = {
  [ENTITY_ATTACHMENT]: null,
  [ENTITY_CATEGORY]: null,
  [ENTITY_COMMENT]: null,
  [ENTITY_DEPARTMENT]: null,
  [ENTITY_DIRECTORY]: directorySelectors.selectDirectoryInSpace,
  [ENTITY_DIRECTORY_ITEM]: null,
  [ENTITY_EXPERIMENT]: experimentSelectors.selectExperimentInSpace,
  [ENTITY_EXPERIMENT_SECTION]: null,
  [ENTITY_EXPERIMENT_SUBSECTION]: null,
  [ENTITY_MASTER_NOTE]: null,
  [ENTITY_MASTER_NOTE_ITEM]: null,
  [ENTITY_MASTER_NOTE_SECTION]: null,
  [ENTITY_NOTE]: noteSelectors.selectNoteInSpace,
  [ENTITY_PERMISSION]: null,
  [ENTITY_PERMISSION_RELATIONSHIP]: null,
  [ENTITY_PIN]: null,
  [ENTITY_SIGNATURE]: null,
  [ENTITY_SIGNATURE_WORKFLOW]: null,
  [ENTITY_SIGNATURE_WORKFLOW_STEP]: null,
  [ENTITY_SOP]: sopSelectors.selectSopInSpace,
  [ENTITY_SOP_SECTION]: null,
  [ENTITY_SOP_SUBSECTION]: null,
  [ENTITY_TASK]: taskSelectors.selectTaskInSpace,
  [ENTITY_TEAM]: null,
  [ENTITY_USER]: null,
  [ENTITY_USER_ROLE]: null,
};

const ENTITY_TYPE_HAS_LOADED_SELECTOR_MAP = {
  [ENTITY_ATTACHMENT]: null,
  [ENTITY_CATEGORY]: null,
  [ENTITY_COMMENT]: null,
  [ENTITY_DEPARTMENT]: userSelectors.selectDepartmentHasLoaded,
  [ENTITY_DIRECTORY]: directorySelectors.selectDirectoryHasLoaded,
  [ENTITY_DIRECTORY_ITEM]: null,
  [ENTITY_EXPERIMENT]: experimentSelectors.selectExperimentHasLoaded,
  [ENTITY_EXPERIMENT_SECTION]: null,
  [ENTITY_EXPERIMENT_SUBSECTION]: null,
  [ENTITY_MASTER_NOTE]: directorySelectors.selectMasterNoteHasLoaded,
  [ENTITY_MASTER_NOTE_ITEM]: null,
  [ENTITY_MASTER_NOTE_SECTION]: null,
  [ENTITY_NOTE]: noteSelectors.selectNoteHasLoaded,
  [ENTITY_PERMISSION]: null,
  [ENTITY_PERMISSION_RELATIONSHIP]: null,
  [ENTITY_PIN]: null,
  [ENTITY_SIGNATURE]: null,
  [ENTITY_SIGNATURE_WORKFLOW]: null,
  [ENTITY_SIGNATURE_WORKFLOW_STEP]: null,
  [ENTITY_SOP]: sopSelectors.selectSopHasLoaded,
  [ENTITY_SOP_SECTION]: null,
  [ENTITY_SOP_SUBSECTION]: null,
  [ENTITY_TASK]: taskSelectors.selectTaskHasLoaded,
  [ENTITY_TEAM]: userSelectors.selectTeamHasLoaded,
  [ENTITY_USER]: userSelectors.selectUserHasLoaded,
  [ENTITY_USER_ROLE]: userSelectors.selectUserRoleHasLoaded,
};

const selectEntityCurried = createSelector(
  (genericId) => genericId,
  (genericId, prop) => prop,
  (genericId, prop, options) => options?.idKey ?? 'id',
  (genericId, prop, options) => options?.typeKey ?? 'type',
  (genericId, prop, idKey, typeKey) => {
    const multiple = Array.isArray(genericId);
    const genericIds = multiple ? genericId : [genericId];
    const ids = genericIds.map((_genericId) => _genericId?.[idKey]);
    const types = genericIds.map((_genericId) => _genericId?.[typeKey]);
    const selectors = types.map((type) => ENTITY_TYPE_SELECTOR_MAP[type]);
    const _prop = types.map((type) => ((prop instanceof GenericProp) ? prop.get(type) : prop));
    return createSelector(
      ...selectors.map((selector, idx) => (state) => selector?.(state, ids[idx], _prop[idx])),
      (...entities) => (multiple ? entities : entities[0]),
    );
  },
);
export const selectEntity = (state, genericId, prop, options) => selectEntityCurried(genericId, prop, options)(state);

const selectEntityPermissionCurried = createSelector(
  (genericId) => genericId,
  (genericId, action) => action,
  (genericId, action, options) => options?.idKey ?? 'id',
  (genericId, action, options) => options?.typeKey ?? 'type',
  (genericId, action, idKey, typeKey) => {
    const multiple = Array.isArray(genericId);
    const genericIds = multiple ? genericId : [genericId];
    const ids = genericIds.map((_genericId) => _genericId?.[idKey]);
    const types = genericIds.map((_genericId) => _genericId?.[typeKey]);
    const selectors = types.map((type) => ENTITY_TYPE_PERMISSION_SELECTOR_MAP[type]);
    return createSelector(
      ...selectors.map((selector, idx) => (state) => selector?.(state, ids[idx], action)),
      (...permissions) => (multiple ? permissions : permissions[0]),
    );
  },
);
export const selectEntityPermission = (state, genericId, action, options) => selectEntityPermissionCurried(genericId, action, options)(state);

export const selectEntityInSpace = (state, genericId, spaceType, spaceGenericId, {
  idKey = 'id',
  typeKey = 'type',
} = {}) => {
  const id = genericId?.[idKey];
  const type = genericId?.[typeKey];
  const selector = ENTITY_TYPE_IN_SPACE_SELECTOR_MAP[type];
  if (!isDefined(selector)) {
    console.warn(`In space checking not implemented for type ${type}.`);
    return true;
  }
  return selector(state, id, spaceType, spaceGenericId);
};

const selectEntityHasLoadedCurried = createSelector(
  (genericId) => genericId,
  (genericId, prop) => prop,
  (genericId, prop, options) => options?.idKey ?? 'id',
  (genericId, prop, options) => options?.typeKey ?? 'type',
  (genericId, prop, idKey, typeKey) => {
    const multiple = Array.isArray(genericId);
    const genericIds = multiple ? genericId : [genericId];
    const ids = genericIds.map((_genericId) => _genericId?.[idKey]);
    const types = genericIds.map((_genericId) => _genericId?.[typeKey]);
    const selectors = types.map((type) => ENTITY_TYPE_HAS_LOADED_SELECTOR_MAP[type]);
    const _prop = types.map((type) => ((prop instanceof GenericProp) ? prop.get(type) : prop));
    return createSelector(
      ...selectors.map((selector, idx) => (state) => selector?.(state, ids[idx], _prop[idx])),
      (...entities) => (multiple ? entities : entities[0]),
    );
  },
);
export const selectEntityHasLoaded = (state, genericId, prop, options) => selectEntityHasLoadedCurried(genericId, prop, options)(state);

export const selectEntityIcon = (state, genericId, {
  idKey = 'id',
  typeKey = 'type',
} = {}) => {
  const id = genericId?.[idKey];
  const type = genericId?.[typeKey];
  const options = { directoryType: null };
  if (type === ENTITY_DIRECTORY) {
    options.directoryType = ENTITY_TYPE_SELECTOR_MAP[type](state, id, 'type');
  }
  return getIcon(type, options);
};
