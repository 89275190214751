import { ENTITY_SOP, ENTITY_SOP_SECTION, ENTITY_SOP_SUBSECTION } from 'constants/schemas';

import { clearUpdate } from '../entity.reducers.utils';
import { isEqual } from 'lodash';

export default {
  [ENTITY_SOP]: {
    mergeStrategy: (oldValue, newValue, { id, slice }) => {
      if (!isEqual(oldValue?.sections, newValue?.sections)) {
        clearUpdate(slice, id, 'sections');
      }
      return Object.assign(oldValue ?? {}, newValue);
    },
  },
  [ENTITY_SOP_SECTION]: {
    mergeStrategy: (oldValue, newValue, { id, slice }) => {
      if (!isEqual(oldValue?.subsections, newValue?.subsections)) {
        clearUpdate(slice, id, 'subsections');
      }
      return Object.assign(oldValue ?? {}, newValue);
    },
  },
  [ENTITY_SOP_SUBSECTION]: {
    valueIsEqual: (v1, v2, prop) => {
      if (prop === 'estimated_time') {
        const v1Float = parseFloat(v1);
        const v2Float = parseFloat(v2);
        return v1Float.toFixed(2) === v2Float.toFixed(2);
      }
      return isEqual(v1, v2);
    },
  },
};
