import { CheckIcon, ClearIcon } from 'constants/icon.constants';
import React, { useState } from 'react';

import { Button } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function NewMasterNoteItemBase(props) {
  const { onCreate, onRemove, children, createButtonProps } = props;
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    setLoading(true);
    try {
      await onCreate();
    } catch {} finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        borderTop: 1,
        borderColor: 'divider',
        px: 2,
        py: 1,
      }}
    >
      { children }

      <Stack direction='row' alignItems='center'>
        <Button
          icon
          onClick={handleCreate}
          loading={loading}
          {...createButtonProps}
          sx={mergeSx({ color: 'text.icon' }, createButtonProps?.sx)}
        >
          <CheckIcon />
        </Button>
        <Button
          icon
          onClick={onRemove}
          sx={{ color: 'text.icon' }}
        >
          <ClearIcon />
        </Button>
      </Stack>
    </Stack>
  );
}

NewMasterNoteItemBase.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  children: PropTypes.node,
  createButtonProps: PropTypes.object,
};

export default NewMasterNoteItemBase;
