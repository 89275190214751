import React, { useCallback } from 'react';
import { useDirectoryItem, useMasterNoteItem } from 'hooks/store.hooks';

import BaseAction from './BaseAction';
import { DeleteIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import { directoryActions } from 'store/entity';
import { useDeleteDialogContext } from './EntityActionState';
import { useDispatch } from 'react-redux';

function MasterNoteItemActionDelete(props) {
  const { id, onBeforeDelete, onDeleteSuccess, onDeleteFailure, ...restProps } = props;
  const dispatch = useDispatch();
  const directoryItemId = useMasterNoteItem(id, 'directory_item');
  const objectId = useDirectoryItem(directoryItemId, 'object_id');
  const objectType = useDirectoryItem(directoryItemId, 'content_type');
  const { setDeleteDialogProps } = useDeleteDialogContext();

  const _onBeforeDelete = useCallback(async (...args) => {
    onBeforeDelete?.(...args);
    await dispatch(directoryActions.deleteMasterNoteItem({ id }));
  }, [onBeforeDelete, dispatch, id]);

  const onAction = useCallback(() => {
    setDeleteDialogProps?.((oldProps) => ({
      ...oldProps,
      open: true,
      id: objectId,
      type: objectType,
      onBeforeDelete: _onBeforeDelete,
      onDeleteSuccess,
      onDeleteFailure,
    }));
  }, [objectId, objectType, setDeleteDialogProps, _onBeforeDelete, onDeleteSuccess, onDeleteFailure]);

  return (
    <BaseAction
      icon={<DeleteIcon />}
      label='Delete'
      onAction={onAction}
      {...restProps}
    />
  );
}

MasterNoteItemActionDelete.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onBeforeDelete: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
};

export default MasterNoteItemActionDelete;
