import client from 'services';

export function fetchNotes(params, config) {
  return client({ method: 'get', url: '/api/notes/', params, ...config });
}

export function createNote(data, config) {
  return client({ method: 'post', url: '/api/notes/', data, ...config });
}

export function fetchNote({ id }, config) {
  return client({ method: 'get', url: `/api/notes/${id}/`, ...config });
}

export function patchNote({ id, data }, config) {
  return client({ method: 'patch', url: `/api/notes/${id}/`, data, ...config });
}

export function deleteNote({ id }, config) {
  return client({ method: 'delete', url: `/api/notes/${id}/`, ...config });
}

export function deleteNotePermanently({ id }, config) {
  const data = { permanently: true };
  return client({ method: 'delete', url: `/api/notes/${id}/`, data, ...config });
}

export function cloneNote({ id }, config) {
  return client({ method: 'post', url: `/api/notes/${id}/clone/`, ...config });
}

export function exportNote({ id }, config) {
  return client({ method: 'get', url: `/api/notes/${id}/export/`, ...config });
}

export function generateNoteTemplate({ id }, config) {
  return client({ method: 'post', url: `/api/notes/${id}/generate-template/`, ...config });
}

export function instantiateNote({ id }, config) {
  return client({ method: 'post', url: `/api/notes/${id}/instantiate/`, ...config });
}

export function restoreNote({ id }, config) {
  return client({ method: 'patch', url: `/api/notes/${id}/restore/`, ...config });
}
