import client from 'services';

export function login({ username, password }, config) {
  return client({
    method: 'post',
    url: '/api/auth/login/',
    data: { username, password },
    ...config,
  }, true);
}

export function resetPassword({ email }, config) {
  return client({
    method: 'post',
    url: '/api/auth/password-reset/',
    data: { email },
    ...config,
  }, true);
}

export function resetPasswordConfirm({ password, token }, config) {
  return client({
    method: 'post',
    url: '/api/auth/password-reset/confirm/',
    data: { password, token },
    ...config,
  }, true);
}

export function validatePasswordResetToken({ token }, config) {
  return client({
    method: 'post',
    url: '/api/auth/password-reset/validate-token/',
    data: { token },
    ...config,
  }, true);
}

export function refreshToken({ refresh }, config) {
  return client({
    method: 'post',
    url: '/api/auth/token/refresh/',
    data: { refresh },
    ...config,
  }, true);
}

export function verifyToken({ token }, config) {
  return client({
    method: 'post',
    url: '/api/auth/token/verify/',
    data: { token },
    ...config,
  }, true);
}
