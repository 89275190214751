import { SPACE_OPUS_U, SPACE_TYPE_ENTITY_MAP } from 'constants/space.constants';

import Avatar from './Avatar';
import OpusUIcon from 'assets/OpusUIcon';
import PropTypes from 'prop-types';
import React from 'react';

const getOpusUAvatarProps = (size) => ({
  id: null,
  type: null,
  children: <OpusUIcon sx={{ fontSize: size }} />,
  sx: {
    bgcolor: 'secondary.main',
    color: 'secondary.contrastText',
  },
});

function AvatarSpace(props) {
  const { spaceType, spaceId, size, ...restProps } = props;
  const type = SPACE_TYPE_ENTITY_MAP[spaceType];
  const isOpusU = spaceType === SPACE_OPUS_U;
  return (
    <Avatar
      variant='rounded'
      id={spaceId}
      type={type}
      size={size}
      {...(isOpusU ? getOpusUAvatarProps(size) : {})}
      {...restProps}
    />
  );
}

AvatarSpace.propTypes = {
  spaceType: PropTypes.string,
  spaceId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.any,
};

export default AvatarSpace;
