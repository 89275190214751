import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import { ENTITY_SOP } from 'constants/schemas';
import { NewVersionIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { sopActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function SopActionNewVersion(props) {
  const { id, ...restProps } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAction = useCallback(async () => {
    try {
      const res = await dispatch(sopActions.newSopVersion({ id })).unwrap();
      navigate(getPath(getGenericId(res.result, ENTITY_SOP)));
    } catch {}
  }, [id, dispatch, navigate]);

  return (
    <BaseAction
      icon={<NewVersionIcon />}
      label='New Version'
      onAction={onAction}
      {...restProps}
    />
  );
}

SopActionNewVersion.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default SopActionNewVersion;
