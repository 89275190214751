import { ENTITY_ACTION_DELETE, ENTITY_ACTION_OPEN, EntityActionState, useEntityActions } from 'components/EntityActions';
import React, { useCallback } from 'react';
import { useTeam, useUpdateEntity } from 'hooks/store.hooks';

import { ENTITY_TEAM } from 'constants/schemas';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';

function TeamDetails(props) {
  const { id } = props;
  const navigate = useNavigate();

  const update = useUpdateEntity();
  const isReadOnly = useEntityIsReadOnly();

  const name = useTeam(id, 'name');

  const onEdit = (value) => {
    if ((value ?? '').length > 0 && value !== name) {
      update({ type: ENTITY_TEAM, id, key: 'name', value });
    }
  };

  const onDeleteSuccess = useCallback(() => navigate('/settings/teams'), [navigate]);

  const extraActions = useEntityActions(id, ENTITY_TEAM, {
    exclude: [ENTITY_ACTION_OPEN],
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess }},
  });

  return (
    <EntityActionState>
      <SectionHeader
        sticky
        title={name}
        onEdit={isReadOnly ? null : onEdit}
        extraActions={{ inMenu: extraActions }}
      />
    </EntityActionState>
  );
}

TeamDetails.propTypes = {
  id: PropTypes.number.isRequired,
};

export default TeamDetails;
