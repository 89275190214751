import * as SignatureService from 'services/SignatureService';
import * as schemas from 'constants/schemas';

import { createServiceThunk } from 'store/store.actions.utils';

// #############################################################################
// ############################ Signature Workflows ############################
// #############################################################################
export const createSignatureWorkflow = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE_WORKFLOW}/createSignatureWorkflow`,
  service: SignatureService.createSignatureWorkflow,
  schema: schemas.signatureWorkflow,
});


// #############################################################################
// ################################ Signatures #################################
// #############################################################################
export const fetchSignatures = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE}/fetchSignatures`,
  service: SignatureService.getSignatures,
  schema: [schemas.signature],
  isPaginated: true,
});

export const createSignature = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE}/createSignature`,
  service: SignatureService.createSignature,
  schema: schemas.signature,
});

export const patchSignature = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE}/patchSignature`,
  service: SignatureService.patchSignature,
  schema: schemas.signature,
});

export const deleteSignature = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE}/deleteSignature`,
  service: SignatureService.deleteSignature,
  schema: schemas.signature,
});

export const rejectSignature = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE}/rejectSignature`,
  service: SignatureService.rejectSignature,
  schema: schemas.signature,
});

export const signSignature = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE}/signSignature`,
  service: SignatureService.signSignature,
  schema: schemas.signature,
});

export const verifySignature = createServiceThunk({
  name: `${schemas.ENTITY_SIGNATURE}/verifySignature`,
  service: SignatureService.verifySignature,
  schema: schemas.signature,
});
