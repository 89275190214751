import Box from '@mui/material/Box';
import { MODE_DARK } from 'components/ThemeProvider';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useTheme } from '@mui/material/styles';

function LandingDialog(props) {
  const { children, containerProps, backgroundProps } = props;
  const theme = useTheme();
  const isDark = theme.palette.mode === MODE_DARK;
  const dialogPadding = 2;

  return (
    <Box
      {...backgroundProps}
      sx={mergeSx({
        display: 'flex',
        width: `calc(100% - ${theme.spacing(dialogPadding * 2)})`,
        height: `calc(100vh - ${theme.spacing(dialogPadding * 2)})`,
        p: dialogPadding,
        background: `linear-gradient(to bottom right, ${isDark ? theme.palette.primary.dark : theme.palette.primary.light}, ${isDark ? theme.palette.secondary.dark : theme.palette.secondary.dark})`,
      }, backgroundProps?.sx)}
    >
      <Paper sx={{ height: 500, display: 'flex', m: 'auto' }} elevation={24}>
        <Stack sx={{ width: 360, p: { xs: 4, sm: 8 }, overflow: 'hidden' }} spacing={2} {...containerProps}>
          { children }
        </Stack>
      </Paper>
    </Box>
  );
}

LandingDialog.propTypes = {
  children: PropTypes.node.isRequired,
  containerProps: PropTypes.object,
  backgroundProps: PropTypes.object,
};

export default LandingDialog;
