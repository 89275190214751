import { useUpdateEntity, useUser } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import { ENTITY_USER } from 'constants/schemas';
import { EditIcon } from 'constants/icon.constants';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PropTypes from 'prop-types';
import React from 'react';
import { formatType } from 'utils/entity.utils';

function UserActions(props) {
  const { id, viewOnly, editingState } = props;
  const { editing, setEditing } = editingState;
  const update = useUpdateEntity();
  const isActive = useUser(id, 'is_active');

  const toggleActive = () => {
    update({ type: ENTITY_USER, id, key: 'is_active', value: !isActive });
  };

  return viewOnly ? null : (
    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        variant='text'
        onClick={toggleActive}
        startIcon={isActive ? <PersonOffIcon /> : <PersonIcon />}
        color={isActive ? 'error' : 'primary'}
        requireConfirmation={isActive}
        dialogProps={{
          title: `Deactivate ${formatType(ENTITY_USER)}?`,
          children: `Are you sure you want to deactivate this ${formatType(ENTITY_USER, { lowercase: true })}? Doing so will prevent them from accessing the application. This action can be undone.`,
        }}
      >
        {isActive ? 'Deactivate' : 'Activate'} {formatType(ENTITY_USER)}
      </Button>
      <Button
        variant='outlined'
        startIcon={editing ? null : <EditIcon />}
        onClick={() => setEditing((prev) => !prev)}
        sx={{ ml: 1 }}
      >
        {editing ? 'Confirm' : 'Edit'}
      </Button>
    </Box>
  );
}

UserActions.propTypes = {
  id: PropTypes.number.isRequired,
  viewOnly: PropTypes.bool,
  editingState: PropTypes.shape({
    editing: PropTypes.bool.isRequired,
    setEditing: PropTypes.func.isRequired,
  }).isRequired,
};

export default UserActions;
