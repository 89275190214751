import { ENTITY_MASTER_NOTE_ITEM, ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import React, { useCallback, useMemo, useState } from 'react';
import { useMasterNote, useMasterNoteItem, useMasterNoteSection, useUpdateEntity } from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import BaseAction from './BaseAction';
import { NavListItem } from 'components/NavList';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import WrapTextIcon from '@mui/icons-material/WrapText';
import { formatType } from 'utils/entity.utils';
import { useDialogContext } from './EntityActionState';

function MoveSectionDialogContent(props) {
  const { id } = props;
  const update = useUpdateEntity();
  const { setDialogProps } = useDialogContext();

  const section = useMasterNoteItem(id, 'master_note_section');
  const masterNote = useMasterNoteSection(section, 'master_note');
  const sectionIds = useMasterNote(masterNote, 'sections');
  const sectionTitles = useMasterNoteSection(sectionIds, 'title');
  const [selectedSection, setSelectedSection] = useState(section);
  const sectionItemsList = useMasterNoteSection(sectionIds, 'items');
  const sectionItems = useMemo(
    () => Object.fromEntries((sectionIds ?? []).map((sectionId, idx) => [sectionId, sectionItemsList[idx]])),
    [sectionIds, sectionItemsList],
  );

  const onSelect = useCallback((sectionId) => {
    setSelectedSection(sectionId);
    setDialogProps((oldProps) => ({
      ...oldProps,
      onConfirm: () => {
        const oldSectionItems = [...sectionItems[section]];
        const newSectionItems = [...sectionItems[sectionId]];
        const oldIdx = oldSectionItems.indexOf(id);
        const newIdx = newSectionItems.length;
        if (oldIdx < 0) return;

        oldSectionItems.splice(oldIdx, 1);
        newSectionItems.splice(newIdx, 0, id);
        update({ type: ENTITY_MASTER_NOTE_ITEM, id, key: 'master_note_section', value: sectionId });
        update({ type: ENTITY_MASTER_NOTE_SECTION, id: section, key: 'items', value: oldSectionItems });
        update({ type: ENTITY_MASTER_NOTE_SECTION, id: sectionId, key: 'items', value: newSectionItems });
      },
    }));
  }, [setDialogProps, id, section, update, sectionItems]);

  return (
    <Paper variant='outlined' sx={{ minWidth: 320, px: 2, py: 1, bgcolor: 'background.grey' }}>
      {sectionTitles.map((title, idx) => (
        <NavListItem
          key={`section-${sectionIds[idx]}`}
          text={title}
          icon={<Avatar>{idx + 1}</Avatar>}
          selected={sectionIds[idx] === selectedSection}
          onClick={() => onSelect(sectionIds[idx])}
        />
      ))}
    </Paper>
  );
}

MoveSectionDialogContent.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

function MasterNoteItemActionMoveSection(props) {
  const { id, ...restProps } = props;
  const { setDialogProps } = useDialogContext();

  const onAction = useCallback(() => {
    setDialogProps((oldProps) => ({
      ...oldProps,
      open: true,
      title: `Move ${formatType(ENTITY_MASTER_NOTE_SECTION)}`,
      children: <MoveSectionDialogContent id={id} />,
    }));
  }, [setDialogProps, id]);


  return (
    <BaseAction
      icon={<WrapTextIcon />}
      label={`Move ${formatType(ENTITY_MASTER_NOTE_SECTION)}`}
      onAction={onAction}
      {...restProps}
    />
  );
}

MasterNoteItemActionMoveSection.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default MasterNoteItemActionMoveSection;
