import { ENTITY_COMMENT, ENTITY_USER } from 'constants/schemas';
import React, { useRef, useState } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import CustomEditor from 'components/CustomEditor';
import PropTypes from 'prop-types';
import { commentActions } from 'store/entity';
import { formatType } from 'utils/entity.utils';
import { useDispatch } from 'react-redux';
import { useMyId } from 'hooks/store.hooks';
import { useNavBlocker } from 'hooks/navigation.hooks';

function NewComment(props) {
  const { relatedObjectId, containerProps, avatarProps, customEditorProps } = props;
  const { size: avatarSize = 24 } = avatarProps ?? {};
  const [commentContent, setCommentContent] = useState(null);
  const [draftOpen, setDraftOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const editorRef = useRef(null);
  const serializationCallbackRef = useRef(null);
  const dispatch = useDispatch();
  const myId = useMyId();
  useNavBlocker({
    when: draftOpen && !disabled,
    message: `Are you sure you would like to navigate away? There is a new ${formatType(ENTITY_COMMENT, { lowercase: true })} draft that has not been saved yet which will be lost.`,
  });

  const onChangeSerialized = (newValue) => {
    setCommentContent(newValue);
  };

  const onChange = (newValue, editor, { serializationCallback }) => {
    if (editor) setDisabled(!editor.hasContent());
    serializationCallbackRef.current = serializationCallback;
  };

  const closeDraft = () => {
    serializationCallbackRef.current?.cancel?.();
    editorRef.current?.clearContent?.();
    setCommentContent(null);
    setDraftOpen(false);
  };

  const postComment = async () => {
    if (!disabled && isDefined(relatedObjectId?.type) && isDefined(relatedObjectId?.id)) {
      serializationCallbackRef.current?.flush?.();
      try {
        const res = await dispatch(commentActions.createComment({
          content: commentContent,
          content_type: relatedObjectId?.type,
          object_id: relatedObjectId?.id,
        })).unwrap();
        if (res.result) closeDraft();
      } catch {}
    }
  };

  return (
    <Box {...containerProps}>
      <Box sx={{ display: 'flex' }}>
        <Avatar id={myId} type={ENTITY_USER} {...avatarProps} sx={mergeSx({ mt: 1 }, avatarProps?.sx)} />
        <CustomEditor
          ref={editorRef}
          onChangeSerialized={onChangeSerialized}
          onChange={onChange}
          onFocus={() => setDraftOpen(true)}
          placeholder={`Add a ${formatType(ENTITY_COMMENT, { lowercase: true })}...`}
          hideToolbarOnBlur={!draftOpen}
          dense={!draftOpen}
          {...customEditorProps}
          sx={mergeSx({ ml: 1, flexGrow: 1 }, customEditorProps?.sx)}
        />
      </Box>
      { draftOpen ? (
        <Box sx={{ display: 'flex', mt: 1, ml: (theme) => `calc(${avatarSize}px + ${theme.spacing(1)})` }}>
          <Button onClick={postComment} disabled={disabled}>Save</Button>
          <Button
            variant='text'
            onClick={closeDraft}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}

NewComment.propTypes = {
  relatedObjectId: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    type: PropTypes.string.isRequired,
  }),
  containerProps: PropTypes.object,
  avatarProps: PropTypes.object,
  customEditorProps: PropTypes.object,
};

export default NewComment;
