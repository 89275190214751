import * as permissionActions from './permission.actions';

import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { ENTITY_PERMISSION_RELATIONSHIP } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, permissionActions.fetchPermissions);
  reduceEntities(builder, permissionActions.createPermissionRelationship);
  reduceEntities(builder, permissionActions.patchPermissionRelationship);
  reduceEntities(builder, permissionActions.deletePermissionRelationship, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_PERMISSION_RELATIONSHIP)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_PERMISSION_RELATIONSHIP)),
  });
};
