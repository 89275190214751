import { ENTITY_CATEGORY } from 'constants/schemas';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import React from 'react';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';
import { formatType } from 'utils/entity.utils';

function NewSopFormBlock(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    categoryAutocompleteProps,
    containerProps,
  } = props;
  const { title, permission_relationships } = formData;
  const autocompleteProps = {
    ...fieldPropsMap?.category?.autocompleteProps,
    ...categoryAutocompleteProps,
  };

  return (
    <Stack {...containerProps}>
      { excludeFields.includes('title') ? null : (
        <Field
          label='Title'
          variant={Field.VARIANTS.TEXT_FIELD}
          readOnly={false}
          value={title}
          {...fieldPropsMap?.title}
          labelProps={{ required: true, ...fieldPropsMap?.title?.labelProps }}
          textFieldProps={{
            onChange: (_, newTitle) => updateFormData('title', newTitle),
            ...fieldPropsMap?.title?.textFieldProps,
          }}
        />
      )}
      { excludeFields.includes('category') ? null : (
        <Field
          label={formatType(ENTITY_CATEGORY)}
          variant={Field.VARIANTS.AUTOCOMPLETE}
          readOnly={false}
          {...fieldPropsMap?.category}
          labelProps={{ required: true, ...fieldPropsMap?.category?.labelProps }}
          value={autocompleteProps?.value}
          autocompleteProps={{
            ...autocompleteProps,
            options: autocompleteProps.options.map((cat, idx) => ({ ...cat, renderProps: { 'data-test': `SOP-category-option-${idx}` }})),
            ...fieldPropsMap?.category?.autocompleteProps,
          }}
        />
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <ShareNewDocumentField
          shares={permission_relationships}
          setShares={(newShares) => updateFormData('permission_relationships', newShares)}
          {...fieldPropsMap?.permission_relationships}
        />
      )}
    </Stack>
  );
}

NewSopFormBlock.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  categoryAutocompleteProps: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewSopFormBlock;
