import NewNoteFormBlock from './NewNoteFormBlock';
import NewNoteFormInline from './NewNoteFormInline';
import PropTypes from 'prop-types';
import { RELATIONSHIP_PERMISSION_OPTIONS } from 'constants/permission.constants';
import React from 'react';

function NewNoteForm(props) {
  const {
    inline = false,
    formData = {},
    updateFormData,
    excludeFields = [],
    fieldPropsMap,
    containerProps,
  } = props;
  const FormComponent = inline ? NewNoteFormInline : NewNoteFormBlock;

  return (
    <FormComponent
      formData={formData}
      updateFormData={updateFormData}
      excludeFields={excludeFields}
      fieldPropsMap={fieldPropsMap}
      containerProps={containerProps}
    />
  );
}

NewNoteForm.propTypes = {
  inline: PropTypes.bool,
  formData: PropTypes.shape({
    title: PropTypes.string,
    is_template: PropTypes.bool,
    permission_relationships: PropTypes.arrayOf(PropTypes.shape({
      individual_id: PropTypes.number,
      individual_content_type: PropTypes.string,
      permission: PropTypes.oneOf(RELATIONSHIP_PERMISSION_OPTIONS),
    })),
  }),
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['title', 'permission_relationships'])),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewNoteForm;
