import { ACTION_CHECKIN, ACTION_UPDATE } from 'constants/permission.constants';
import { Button, EditorHooks } from '@acheloisbiosoftware/absui.core';
import { ENTITY_ATTACHMENT, ENTITY_USER } from 'constants/schemas';
import React, { useCallback, useState } from 'react';
import { useAttachment, useAttachmentPermission, useMyId, useUserName } from 'hooks/store.hooks';
import { useReadOnly, useSelected, useSlateStatic } from 'slate-react';

import Box from '@mui/material/Box';
import { CheckinIcon } from 'constants/icon.constants';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DownloadIcon from '@mui/icons-material/Download';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import { attachmentActions } from 'store/entity';
import { downloadFile } from '@acheloisbiosoftware/absui.utils';
import { electron } from 'utils/electron.utils';
import { formatType } from 'utils/entity.utils';
import { notificationActions } from 'store/notification';
import { useDispatch } from 'react-redux';
import { usePromise } from '@acheloisbiosoftware/absui.hooks';

const { useFocusedDisplay } = EditorHooks;

// NOTE: This component is ONLY used when the editor is in electron, not in the browser
// NOTE: This is very similar to the FileElement from absui.editor
function ElectronFileElement(props) {
  const { attributes, children, element, borrowFocus } = props;
  const {
    id,
    uuid = id,
    name = 'Unkown Document',
  } = element;
  const editorStatic = useSlateStatic();
  const src = editorStatic.Attachment.useSrc(element);
  const selected = useSelected();
  const focusedDisplay = useFocusedDisplay();
  const readOnly = useReadOnly();
  const selectedDisplay = selected && (focusedDisplay || readOnly);

  const dispatch = useDispatch();
  const myId = useMyId();
  const isCheckedOut = useAttachment(uuid, 'is_checked_out');
  const checkedOutBy = useAttachment(uuid, 'checked_out_by');
  const checkedOutByName = useUserName(checkedOutBy);
  const isMyCheckout = isCheckedOut && checkedOutBy === myId;
  const canUpdate = useAttachmentPermission(uuid, ACTION_UPDATE);
  const canCheckin = useAttachmentPermission(uuid, ACTION_CHECKIN);

  const [anchorEl, setAnchorEl] = useState(null);
  const { promiseRef, defer } = usePromise();
  const onOpen = useCallback((event) => {
    event.preventDefault();
    borrowFocus(() => defer());
    setAnchorEl(event.currentTarget);
  }, [borrowFocus, defer]);

  const onClose = useCallback(() => {
    setAnchorEl(null);
    promiseRef.current.resolve(() => null);
  }, [promiseRef]);

  const onDownload = useCallback(async () => {
    await downloadFile({ name, file: src });
    onClose();
  }, [onClose, name, src]);

  const onOpenFile = useCallback(async () => {
    try {
      const res = await dispatch(attachmentActions.checkoutAttachment({ uuid })).unwrap();
      const resAttachment = res.entities[ENTITY_ATTACHMENT][res.result];
      if (resAttachment.is_checked_out && resAttachment.checked_out_by === myId) {
        electron.ipcRenderer.send('drive:startTracking', {
          id: uuid,
          filename: name,
          url: src,
        });
        onClose();
      } else {
        const otherUser = res.entities[ENTITY_USER]?.[resAttachment.checked_out_by]?.full_name ?? 'another user';
        dispatch(notificationActions.enqueueSnackbar({
          key: `attachmentCheckoutFailure${Date.now()}`,
          message: `This ${formatType(ENTITY_ATTACHMENT, { lowercase: true })} is checked out to ${otherUser}.`,
          variant: 'warning',
        }));
      }
    } catch {}
  }, [dispatch, onClose, name, src, uuid, myId]);

  const onCheckinFile = useCallback(async () => {
    await dispatch(attachmentActions.checkinAttachment({ uuid }));
  }, [dispatch, uuid]);

  return (
    <Box
      {...attributes}
      component='span'
      contentEditable={false}
      data-test='ElectronFileElement'
    >
      <Button
        startIcon={<FileOpenIcon />}
        onMouseDown={onOpen}
        variant='text'
        sx={[
          {
            typography: 'body2',
            textTransform: 'none',
            mx: 0.5,
          },
          selectedDisplay ? {
            boxShadow: `0 0 0 2px Highlight`,
          } : {},
        ]}
      >
        {name}
        {children}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        <MenuItem
          icon={<DownloadIcon />}
          label='Download File'
          listItemTextProps={{ secondary: 'Changes are not tracked' }}
          onClick={onDownload}
        />
        <MenuItem
          icon={<CloudSyncIcon />}
          label='Checkout & Open File'
          listItemTextProps={{
            secondary: isCheckedOut && !isMyCheckout ? `This ${formatType(ENTITY_ATTACHMENT, { lowercase: true })} is currently checked out to ${checkedOutByName}` : 'Changes are synced to the cloud',
          }}
          onClick={onOpenFile}
          disabled={readOnly || (isCheckedOut && !isMyCheckout) || !canUpdate}
        />
        { canCheckin ? (
          <MenuItem
            icon={<CheckinIcon />}
            label='Checkin File'
            listItemTextProps={{
              secondary: isMyCheckout ? 'Halt file tracking' : `As an admin, you may force ${checkedOutByName} to checkin this ${formatType(ENTITY_ATTACHMENT, { lowercase: true })}.`,
            }}
            onClick={onCheckinFile}
          />
        ) : null}
      </Menu>
    </Box>
  );
}

ElectronFileElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.shape({
    id: PropTypes.string,
    uuid: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  borrowFocus: PropTypes.func,
};

export default ElectronFileElement;
