import React from 'react';
import UserRole from './UserRole';
import { useParams } from 'react-router-dom';

function UserRolePage() {
  const { id } = useParams();
  return (<UserRole id={parseFloat(id)} />);
}

export default UserRolePage;
