import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import {
  createEntitiesWithUpdatesSelector,
  createEntityHasLoadedSelector,
  createEntityListSelector,
  createEntityPermissionSelector,
  createEntitySelector,
  createEntityUpdatesSelector,
} from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { ENTITY_TASK } from 'constants/schemas';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { isEmpty } from 'lodash';
import { userSelectors } from '../user';

const selectTasks = (state) => state[ENTITY][ENTITY_TASK];

export const selectTaskList = createEntityListSelector(selectTasks);
export const selectTask = createEntitySelector(selectTasks);
export const selectTaskHasLoaded = createEntityHasLoadedSelector(selectTasks);
export const selectTaskUpdates = createEntityUpdatesSelector(selectTasks);
export const selectTasksWithUpdates = createEntitiesWithUpdatesSelector(selectTaskUpdates);
export const selectTaskHasUpdates = (state, id) => !isEmpty(selectTaskUpdates(state, id));

export const selectTaskPermission = createEntityPermissionSelector(selectTask);
export const selectTaskInSpace = (state, id, spaceType, spaceGenericId) => {
  const task = selectTask(state, id);
  if (!isDefined(task)) return true;
  if (spaceType === SPACE_USER) return task.created_by === spaceGenericId.id;
  if (spaceType === SPACE_TEAM) return userSelectors.selectTeamHasPermission(state, spaceGenericId.id, task);
  if (spaceType === SPACE_DEPARTMENT) return userSelectors.selectDepartmentHasPermission(state, spaceGenericId.id, task);
  return true;
};
