import React, { useCallback, useMemo, useRef, useState } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

function MenuItem(props) {
  const {
    nested,
    icon,
    label,
    children,
    menuItemProps,
    listItemIconProps,
    listItemTextProps,
    nestedMenuProps,
    circularProgressProps,
    ...restProps
  } = props;
  const [loading, setLoading] = useState(false);

  const _menuItemProps = useMemo(() => ({
    ...restProps,
    ...menuItemProps,
  }), [menuItemProps, restProps]);

  const _onClick = _menuItemProps?.onClick;
  const onClick = useCallback(async (...args) => {
    setLoading(true);
    try {
      await _onClick?.(...args);
    } catch {} finally {
      setLoading(false);
    }
  }, [_onClick]);

  const itemRef = useRef(null);
  const [overMenuItem, setOverMenuItem] = useState(false);
  const [overNestedMenu, setOverNestedMenu] = useState(false);
  const open = (overMenuItem || overNestedMenu) && (!isDefined(nestedMenuProps?.open) || nestedMenuProps.open);

  const onMouseEnterItem = (...args) => {
    setOverMenuItem(true);
    _menuItemProps.onMouseEnter?.(...args);
  };

  const onMouseLeaveItem = (...args) => {
    setOverMenuItem(false);
    _menuItemProps.onMouseLeave?.(...args);
  };

  const onMouseEnterNestedMenu = (...args) => {
    setOverNestedMenu(true);
    nestedMenuProps?.onMouseEnter?.(...args);
  };

  const onMouseLeaveNestedMenu = (...args) => {
    setOverNestedMenu(false);
    nestedMenuProps?.onMouseLeave?.(...args);
  };

  return (
    <>
      <MuiMenuItem
        ref={itemRef}
        disabled={loading}
        {..._menuItemProps}
        onClick={onClick}
        onMouseEnter={onMouseEnterItem}
        onMouseLeave={onMouseLeaveItem}
      >
        { icon ? (
          <ListItemIcon
            {...listItemIconProps}
            sx={mergeSx(
              { color: 'text.icon' },
              listItemIconProps?.sx,
            )}
          >
            {icon}
          </ListItemIcon>
        ) : null}
        { label ? (
          <ListItemText
            primary={label}
            {...listItemTextProps}
          />
        ) : null}
        { !nested ? children : null}
        { isDefined(_onClick) ? (
          <ListItemIcon sx={{ justifyContent: 'right' }}>
            { loading ? (<CircularProgress size={16} {...circularProgressProps} />) : null}
          </ListItemIcon>
        ) : null}
        { nested ? (<ArrowRightIcon />) : null}
      </MuiMenuItem>
      { nested ? (
        <Menu
          keepMounted
          anchorEl={itemRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          {...nestedMenuProps}
          open={open}
          onMouseEnter={onMouseEnterNestedMenu}
          onMouseLeave={onMouseLeaveNestedMenu}
          sx={mergeSx({ pointerEvents: 'none' }, nestedMenuProps?.sx)}
        >
          <Box sx={{ pointerEvents: 'auto' }}>
            { children }
          </Box>
        </Menu>
      ) : null}
    </>
  );
}

MenuItem.propTypes = {
  nested: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.any,
  children: PropTypes.node,
  onClick: PropTypes.func,
  menuItemProps: PropTypes.object,
  listItemIconProps: PropTypes.object,
  listItemTextProps: PropTypes.object,
  nestedMenuProps: PropTypes.object,
  circularProgressProps: PropTypes.object,
};

export default MenuItem;
