import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import { useEntity, useEntityIcon } from 'hooks/store.hooks';

import { GenericProp } from 'store/entity';
import PropTypes from 'prop-types';
import React from 'react';
import SearchResultBase from './SearchResultBase';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';

const primaryProp = new GenericProp({
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
});

const secondaryProp = new GenericProp({
  [ENTITY_DIRECTORY]: 'directory_code',
  [ENTITY_EXPERIMENT]: 'exp_code',
  [ENTITY_NOTE]: 'note_code',
  [ENTITY_SOP]: 'sop_code',
  [ENTITY_TASK]: 'task_code',
});

function SearchResultEntity(props) {
  const { id, type, navigateToResult, onClick, ...restProps } = props;
  const genericId = getGenericId(id, type);
  const primary = useEntity(genericId, primaryProp);
  const secondary = useEntity(genericId, secondaryProp);
  const isTemplate = useEntity(genericId, 'is_template');
  const Icon = useEntityIcon(genericId);
  const path = navigateToResult ? getPath(genericId) : null;
  const _onClick = (event) => onClick?.(event, genericId);
  return (
    <SearchResultBase
      icon={Icon ? (<Icon colored template={isTemplate} />) : null}
      primary={primary}
      secondary={secondary}
      path={path}
      onClick={_onClick}
      {...restProps}
    />
  );
}

SearchResultEntity.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]).isRequired,
  navigateToResult: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SearchResultEntity;
