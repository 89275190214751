import CheckoutAlert from 'components/CheckoutAlert';
import DeletedAlert from 'components/DeletedAlert';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import PropTypes from 'prop-types';
import React from 'react';

function WorkspaceWarnings(props) {
  const { id, collapsed } = props;
  return (
    <>
      <DeletedAlert id={id} type={ENTITY_DIRECTORY} />
      {collapsed ? null : <CheckoutAlert id={id} type={ENTITY_DIRECTORY} />}
    </>
  );
}

WorkspaceWarnings.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  collapsed: PropTypes.bool,
};

export default WorkspaceWarnings;
