import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import { GenericProp, userActions } from 'store/entity';
import { SPACE_DEPARTMENT, SPACE_OPUS_U, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { useEffect, useMemo } from 'react';
import { useEntity, useEntityHasLoaded, useMyGlobalPermission, useMyId, useSpaceGenericId, useSpaceType } from './store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import { useDispatch } from 'react-redux';

const nameProp = new GenericProp({
  [ENTITY_USER]: 'full_name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_DEPARTMENT]: 'name',
});

const useSpaceName = () => {
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const individualName = useEntity(spaceGenericId, nameProp);
  const individualNameHasLoaded = useEntityHasLoaded(spaceGenericId, nameProp);
  const myId = useMyId();
  const dispatch = useDispatch();

  const canViewUser = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const canViewTeam = useMyGlobalPermission(ENTITY_TEAM, ACTION_VIEW);
  const canViewDepartment = useMyGlobalPermission(ENTITY_DEPARTMENT, ACTION_VIEW);

  useEffect(() => {
    if (!individualNameHasLoaded && canViewUser && spaceGenericId.type === ENTITY_USER) {
      dispatch(userActions.fetchUser({ id: spaceGenericId.id }));
    }
  }, [dispatch, individualNameHasLoaded, canViewUser, spaceGenericId]);

  useEffect(() => {
    if (!individualNameHasLoaded && canViewTeam && spaceGenericId.type === ENTITY_TEAM) {
      dispatch(userActions.fetchTeam({ id: spaceGenericId.id }));
    }
  }, [dispatch, individualNameHasLoaded, canViewTeam, spaceGenericId]);

  useEffect(() => {
    if (!individualNameHasLoaded && canViewDepartment && spaceGenericId.type === ENTITY_DEPARTMENT) {
      dispatch(userActions.fetchDepartment({ id: spaceGenericId.id }));
    }
  }, [dispatch, individualNameHasLoaded, canViewDepartment, spaceGenericId]);

  return useMemo(() => {
    if (spaceType === SPACE_OPUS_U) {
      return 'Opus U';
    } else if (spaceType === SPACE_USER) {
      return spaceGenericId.id === myId ? 'My Space' : individualName ? `${individualName} (Opus User)` : '';
    } else if (spaceType === SPACE_TEAM) {
      return individualName ? `${individualName} (Opus Team)` : '';
    } else if (spaceType === SPACE_DEPARTMENT) {
      return individualName ? `${individualName} (Opus Org)` : '';
    }
    return '';
  }, [spaceType, spaceGenericId, individualName, myId]);
};

export default useSpaceName;
