import { ENTITY_EXPERIMENT, ENTITY_SOP } from 'constants/schemas';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckoutAlert from 'components/CheckoutAlert';
import DeletedAlert from 'components/DeletedAlert';
import PropTypes from 'prop-types';
import React from 'react';
import { formatType } from 'utils/entity.utils';
import { useExperiment } from 'hooks/store.hooks';

function ExperimentWarnings(props) {
  const { expId } = props;
  const isTemplate = useExperiment(expId, 'is_template');
  const isEffective = useExperiment(expId, 'related_sop__is_effective_version');
  return (
    <>
      <DeletedAlert id={expId} type={ENTITY_EXPERIMENT} />

      <CheckoutAlert id={expId} type={ENTITY_EXPERIMENT} />

      { !isEffective ? (
        <Alert
          severity='warning'
          sx={{ borderRadius: 0 }}
        >
          <AlertTitle>Outdated</AlertTitle>
          The {formatType(ENTITY_SOP, { lowercase: true })} used for this {formatType(ENTITY_EXPERIMENT, { lowercase: true, template: isTemplate })} is outdated.
        </Alert>
      ) : null}
    </>
  );
}

ExperimentWarnings.propTypes = {
  expId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ExperimentWarnings;
