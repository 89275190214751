import { Prop, directoryActions } from 'store/entity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDirectory, useMasterNote, useMasterNoteSection } from 'hooks/store.hooks';

import CollapseButton from 'components/CollapseButton';
import DataTable from 'components/DataTable';
import MasterNoteSectionTable from 'components/MasterNoteSectionTable';
import NavigatorColumn from './NavigatorColumn';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigatorExplore } from './Navigator.utils';

const EMPTY_ARRAY = [];
const masterNoteSectionProp = new Prop({
  id: 'id',
  title: 'title',
});
const dataTableSx = { overflow: 'auto' };
const tableProps = { size: 'small' };

function MasterNoteNavigator(props) {
  const { title, depth } = props;
  const dispatch = useDispatch();
  const { parent, selected } = useNavigatorExplore(depth);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!isNil(parent)) {
      setLoading(true);
      dispatch(directoryActions.fetchDirectory({ id: parent })).finally(() => setLoading(false));
    }
  }, [dispatch, parent]);

  const masterNoteId = useDirectory(parent, 'master_note');
  const masterNoteSectionIds = useMasterNote(masterNoteId, 'sections');
  const masterNoteSections = useMasterNoteSection(masterNoteSectionIds ?? EMPTY_ARRAY, masterNoteSectionProp);

  const rows = useMemo(() => masterNoteSections.map((section) => ({
    ...section,
    expandedChildren: (
      <MasterNoteSectionTable
        id={section.id}
        collapsed
        selectedItem={selected}
      />
    ),
  })), [masterNoteSections, selected]);

  const [collapsedRows, setCollapsedRows] = useState([]);
  const expandedRows = useMemo(
    () => rows.filter((row) => !collapsedRows.includes(row.id)).map((row) => row.id),
    [collapsedRows, rows],
  );
  const toggleRowExpanded = useCallback((row) => {
    setCollapsedRows((prev) => (prev.includes(row.id) ? prev.filter((_id) => _id !== row.id) : [...prev, row.id]));
  }, []);

  const columns = useMemo(() => [{
    id: 'title',
    field: 'title',
    renderValue: ({ row, value }) => (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography
          variant='caption'
          color='text.secondary'
          sx={{ fontWeight: 'fontWeightBold' }}
        >
          {value || 'Untitled'}
        </Typography>
        <CollapseButton
          open={expandedRows.includes(row.id)}
          iconProps={{
            fontSize: 'small',
          }}
        />
      </Stack>
    ),
    cellProps: { sx: { bgcolor: 'background.grey' }},
  }], [expandedRows]);

  return (
    <NavigatorColumn title={title}>
      <DataTable
        rows={rows}
        columns={columns}
        onRowClick={toggleRowExpanded}
        loading={loading}
        expandedRows={expandedRows}
        noHeader
        disableFilter
        disableGroupBy
        tableProps={tableProps}
        sx={dataTableSx}
      />
    </NavigatorColumn>
  );
}

MasterNoteNavigator.propTypes = {
  title: PropTypes.any,
  depth: PropTypes.number,
};

export default MasterNoteNavigator;
