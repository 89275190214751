import { useCallback, useMemo } from 'react';
import { useDirectoryItem, useMasterNoteItem } from 'hooks/store.hooks';

import { generateSearchParams } from 'utils/dom.utils';
import { isNil } from 'lodash';
import { parseFloatList } from 'utils/helpers';
import { useSearchParams } from 'react-router-dom';

export const useNavigatorSelected = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selected = useMemo(() => {
    const s = parseFloat(searchParams.get('selected'));
    return isNaN(s) ? null : s;
  }, [searchParams]);

  const setSelected = useCallback((newSelected) => {
    setSearchParams((oldSearchParams) => {
      const newSearchParams = generateSearchParams({ selected: newSelected }, oldSearchParams);
      newSearchParams.delete('explore'); // removes the Workspace explore view
      if (isNil(newSelected)) {
        newSearchParams.delete('selected');
      }
      return newSearchParams;
    });
  }, [setSearchParams]);

  return useMemo(() => [selected, setSelected], [selected, setSelected]);
};

export const useNavigatorExplore = (depth) => {
  const selected = useNavigatorSelected()[0];
  const searchParams = useSearchParams()[0];

  const exploreStr = searchParams.get('explore') ?? '';
  const masterNoteIds = useMemo(() => parseFloatList(exploreStr), [exploreStr]);
  const directoryItemIds = useMasterNoteItem(masterNoteIds, 'directory_item');
  const exploreDirectoryIds = useDirectoryItem(directoryItemIds, 'object_id');
  const directoryIds = useMemo(() => [selected, ...exploreDirectoryIds], [selected, exploreDirectoryIds]);

  return useMemo(
    () => ({ parent: directoryIds[depth], selected: masterNoteIds[depth] }),
    [directoryIds, masterNoteIds, depth],
  );
};
