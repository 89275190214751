import { Autocomplete, TextField } from '@acheloisbiosoftware/absui.core';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CustomEditor from 'components/CustomEditor';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EmptyField from './EmptyField';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { formatDate } from 'utils/date.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const TEXT_EDITOR = 'text-editor';
const TEXT_FIELD = 'text-field';
const SELECT = 'select';
const AUTOCOMPLETE = 'autocomplete';
const CHIPS = 'chips';
const DATE = 'date';
const CUSTOM = 'custom';

function FieldValue(props) {
  const {
    children,
    value,
    variant,
    readOnly,
    empty,
    loading,
    sx,
    customEditorProps = {},
    autocompleteProps = {},
    textFieldProps = {},
    chipProps = {},
    datePickerProps = {},
    skeletonProps = {},
    ...restProps
  } = props;

  const _value = value ?? children;

  if (!loading && readOnly && (empty || !isDefined(_value))) {
    return (<EmptyField sx={sx} />);
  }

  if (variant === TEXT_EDITOR) {
    return loading ? (
      <Skeleton
        variant='rounded'
        width='100%'
        height={150}
        {...skeletonProps}
      />
    ) : (
      <CustomEditor
        readOnly={readOnly}
        initialValue={_value}
        {...restProps}
        {...customEditorProps}
        sx={mergeSx(
          { width: 1 },
          !readOnly ? { minHeight: 150 } : {
            width: (theme) => `calc(100% - ${theme.spacing(1)})`,
            ml: 1,
            my: -1,
          },
          sx,
          customEditorProps?.sx,
        )}
      />
    );
  }

  if ([TEXT_FIELD, SELECT].includes(variant) && !readOnly) {
    return (
      <TextField
        value={_value}
        debounceChange={variant === TEXT_FIELD}
        {...textFieldProps}
        select={variant === SELECT}
        sx={mergeSx(
          { width: 1 },
          textFieldProps?.sx,
        )}
      />
    );
  }

  if (variant === AUTOCOMPLETE && !readOnly) {
    return (
      <Autocomplete
        value={_value ?? null}
        {...autocompleteProps}
        sx={mergeSx(
          { width: 1 },
          autocompleteProps?.sx,
        )}
      />
    );
  }

  if (variant === CHIPS && !readOnly) {
    return (
      <Autocomplete
        multiple
        value={_value ?? null}
        {...autocompleteProps}
        sx={mergeSx(
          { width: 1 },
          autocompleteProps?.sx,
        )}
      />
    );
  }

  if (variant === DATE && !readOnly) {
    return (
      <DatePicker
        value={value}
        {...datePickerProps}
        slotProps={{
          ...datePickerProps?.slotProps,
          textField: {
            ...datePickerProps?.slotProps?.textField,
            sx: mergeSx({ width: 1 }, datePickerProps?.slotProps?.textField?.sx),
          },
        }}
      />
    );
  }

  let displayValue = _value;
  let loadingProps = { width: 200, ...skeletonProps };

  if (variant === AUTOCOMPLETE && isDefined(_value)) {
    displayValue = autocompleteProps.getOptionLabel(_value);
  } else if (variant === CHIPS) {
    displayValue = autocompleteProps?.renderTags?.(_value, ({ index }) => ({ key: `tag_${index}`, sx: { mr: 1, mt: 0.5 }})) ?? (_value ?? []).map((item, idx) => {
      const _chipProps = typeof(chipProps) === 'function' ? chipProps(item) : chipProps;
      return (
        <Chip
          key={`tag_${idx}`} /* eslint-disable-line react/no-array-index-key */
          label={autocompleteProps.getOptionLabel(item)}
          {..._chipProps}
          sx={mergeSx({ mr: 1, mt: 0.5 }, _chipProps?.sx)}
        />
      );
    });
    loadingProps = {
      ...loadingProps,
      variant: 'rounded',
      height: 32,
      sx: mergeSx(loadingProps?.sx, { mt: 0.5 }),
    };
  } else if (variant === DATE) {
    displayValue = formatDate(_value);
  } else if (variant === SELECT) {
    displayValue = textFieldProps?.SelectProps?.renderValue?.(_value) ?? _value;
  }

  return (
    <Box
      {...restProps}
      sx={mergeSx({
        ml: 2,
        typography: 'body1',
      }, sx)}
    >
      { loading ? (<Skeleton {...loadingProps} />) : displayValue }
    </Box>
  );
}

FieldValue.VARIANTS = {
  TEXT_EDITOR,
  TEXT_FIELD,
  SELECT,
  AUTOCOMPLETE,
  CHIPS,
  DATE,
  CUSTOM,
};

FieldValue.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  variant: PropTypes.oneOf(Object.values(FieldValue.VARIANTS)),
  readOnly: PropTypes.bool,
  empty: PropTypes.bool,
  loading: PropTypes.bool,
  sx: sxPropType,
  customEditorProps: PropTypes.object,
  autocompleteProps: PropTypes.object,
  textFieldProps: PropTypes.object,
  chipProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  datePickerProps: PropTypes.object,
  skeletonProps: PropTypes.object,
};

FieldValue.defaultProps = {
  readOnly: true,
  varaint: CUSTOM,
};


export default FieldValue;
