import CellBase from './CellBase';
import ContentGrouped from './ContentGrouped';
import PropTypes from 'prop-types';
import React from 'react';

function GroupedCell(props) {
  const { row, columnId, ...restProps } = props;

  return (
    <CellBase
      row={row}
      columnId={columnId}
      {...restProps}
    >
      <ContentGrouped row={row} columnId={columnId} />
    </CellBase>
  );
}

GroupedCell.propTypes = {
  row: PropTypes.any,
  columnId: PropTypes.any,
};

export default GroupedCell;
