import { useAttachment, useAttachments } from 'hooks/store.hooks';
import { useCallback, useEffect } from 'react';

import { AWS_QUERYSTRING_EXPIRE } from 'constants/service.constants';
import { EditorPlugins } from '@acheloisbiosoftware/absui.core';
import { attachmentActions } from 'store/entity';
import { notificationActions } from 'store/notification';
import { useDispatch } from 'react-redux';

const { Attachment, BaseEditor } = EditorPlugins;

/**
 * Note: The Django object UUID and the Slate element ID use to be stored as one
 * in the same. However, the Slate element ID must be unique, while there may be
 * multiple occurences of the same image in an editor (i.e. copy and pasting) or
 * across multiple editors. Therefore, the UUID is now stored in the data under
 * the field `uuid`. However, to maintain backwards compatibility, the `id`
 * field is still used as a fallback when `uuid` is not present.
 */
export const useS3Storage = (editor) => {
  const dispatch = useDispatch();
  const { byId } = useAttachments();

  const fetchAttachments = useCallback(() => {
    const attachments = BaseEditor.nodes(editor, {
      at: [],
      match: (n) => Attachment.isAttachment(n),
    });
    const attachmentIds = Array.from(attachments).map(([attachment]) => attachment.uuid ?? attachment.id);
    if (attachmentIds.length > 0) {
      dispatch(attachmentActions.fetchAttachments({ filters: { uuid: attachmentIds }}));
    }
  }, [editor, dispatch]);

  useEffect(() => {
    fetchAttachments();
    const interval = setInterval(() => {
      fetchAttachments();
    }, 1000 * AWS_QUERYSTRING_EXPIRE);
    return () => clearInterval(interval);
  }, [fetchAttachments]);

  const postAttachment = async (file) => {
    try {
      const res = await dispatch(attachmentActions.createAttachment({ name: file.name, file })).unwrap();
      const { entities, result } = res;
      const attachment = entities.attachment[result];
      return { name: attachment.name, uuid: attachment.uuid };
    } catch {
      dispatch(notificationActions.enqueueSnackbar({
        key: `attachmentFailure${Date.now()}`,
        message: 'Error uploading file',
        variant: 'error',
      }));
      return { name: 'Broken File' };
    }
  };

  const useSrc = (element) => useAttachment(element?.uuid ?? element?.id, 'file');
  editor.Attachment.getSrc = (element) => byId[element?.uuid ?? element?.id]?.file;
  editor.Attachment.useSrc = useSrc;

  editor.insertFile = (file) => {
    const [mime] = file.type.split('/');
    if (mime === 'image') {
      editor.Attachment.insertImage(postAttachment(file), { name: file.name });
    } else {
      editor.Attachment.insertFile(postAttachment(file), { name: file.name });
    }
  };

  return editor;
};
