import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function NavDroppable(props) {
  const { children, onDragEnd, items } = props;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const _onDragEnd = (event) => {
    const { active, over } = event;
    if (isDefined(active?.id) && isDefined(over?.id) && active.id !== over.id) {
      onDragEnd(event);
    }
  };

  return (
    <Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={_onDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={items}
          strategy={verticalListSortingStrategy}
        >
          {children}
        </SortableContext>
      </DndContext>
    </Box>
  );
}

NavDroppable.propTypes = {
  children: PropTypes.node,
  onDragEnd: PropTypes.func,
  items: PropTypes.array.isRequired,
};

export default NavDroppable;
