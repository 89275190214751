import { useEntityComments, useMyGlobalPermission } from 'hooks/store.hooks';

import { ACTION_CREATE } from 'constants/permission.constants';
import Comment from './Comment';
import { ENTITY_COMMENT } from 'constants/schemas';
import Field from 'components/Field';
import NewComment from './NewComment';
import PropTypes from 'prop-types';
import React from 'react';
import { formatType } from 'utils/entity.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

const AVATAR_SIZE = 24;

function CommentSection(props) {
  const {
    relatedObjectId,
    disableNew,
    fieldProps,
    commentProps,
    newCommentProps,
  } = props;
  const comments = useEntityComments(relatedObjectId);
  const canCreateComment = useMyGlobalPermission(ENTITY_COMMENT, ACTION_CREATE);
  const showNewComment = !disableNew && canCreateComment;
  return (
    <Field
      label={formatType(ENTITY_COMMENT, { plural: true })}
      empty={comments.length === 0 && !showNewComment}
      {...fieldProps}
    >
      { comments.map((commentId) => {
        const _commentProps = typeof(commentProps) === 'function' ? commentProps(commentId) : (commentProps ?? {});
        return (
          <Comment
            key={`comment${commentId}`}
            id={commentId}
            {..._commentProps}
            containerProps={{
              ..._commentProps?.containerProps,
              sx: mergeSx({ my: 1 }, _commentProps?.containerProps?.sx),
            }}
            avatarProps={{
              size: AVATAR_SIZE,
              ..._commentProps?.avatarProps,
            }}
          />
        );
      })}
      { showNewComment ? (
        <NewComment
          relatedObjectId={relatedObjectId}
          {...newCommentProps}
          avatarProps={{
            size: AVATAR_SIZE,
            ...newCommentProps?.avatarProps,
          }}
          containerProps={{
            ...newCommentProps?.containerProps,
            sx: mergeSx({ mt: 1 }, newCommentProps?.containerProps?.sx),
          }}
        />
      ) : null}
    </Field>
  );
}

CommentSection.propTypes = {
  relatedObjectId: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  disableNew: PropTypes.bool,
  fieldProps: PropTypes.object,
  commentProps: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  newCommentProps: PropTypes.object,
};

export default CommentSection;
