import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import { useEntity, useEntityIcon } from 'hooks/store.hooks';

import { GenericProp } from 'store/entity';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useGenericId } from 'utils/generic.utils';

const nameProp = new GenericProp({
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'long_title',
  [ENTITY_NOTE]: 'long_title',
  [ENTITY_SOP]: 'long_title',
  [ENTITY_TASK]: 'long_title',
});

function ItemOverlay(props) {
  const { id, type } = props;
  const genericId = useGenericId(id, type);
  const name = useEntity(genericId, nameProp);
  const Icon = useEntityIcon(genericId);
  const isTemplate = useEntity(genericId, 'is_template');
  return (
    <Paper
      variant='outlined'
      sx={{ p: 1, borderRadius: 2, maxWidth: 'fit-content' }}
    >
      <Stack
        direction='row'
        alignItems='center'
      >
        <Icon colored template={isTemplate} />
        <Typography variant='subtitle2' sx={{ ml: 1 }}>{name}</Typography>
      </Stack>
    </Paper>
  );
}

ItemOverlay.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]).isRequired,
};

export default ItemOverlay;
