import { Prop, sopActions, sopSelectors } from 'store/entity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import SopTable, { ActionAddSop } from 'components/SopTable';
import { isNil, minBy, zip } from 'lodash';
import { useCategory, useFetchListing, useSop, useSopList, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { ENTITY_SOP } from 'constants/schemas';
import { EntityPreview } from 'components/SidePreview';
import PropTypes from 'prop-types';
import { SopIcon } from 'constants/icon.constants';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { useNavigate } from 'react-router-dom';

function SopsTable(props) {
  const { listView, selected, setSelected } = props;
  const navigate = useNavigate();
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const [previewState, setPreviewState] = useState({ open: false, id: null });

  const fetchOptions = useMemo(() => ({
    params: { filters: {
      is_template: false,
      ...(spaceType === SPACE_USER ? { sop_author: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_TEAM ? { team: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_DEPARTMENT ? { department: spaceGenericId.id } : {}),
    }},
  }), [spaceType, spaceGenericId]);
  const loading = useFetchListing(sopActions.fetchSops, fetchOptions);

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (sop, state) => (
      !sop.is_template &&
      sopSelectors.selectSopInSpace(state, sop.id, spaceType, spaceGenericId)
    ),
  }), [spaceType, spaceGenericId]);
  const sopIds = useSopList(listParams);

  const categoryIds = useSop(sopIds, 'category');
  const categoryNames = useCategory(categoryIds, 'name');
  useEffect(() => {
    if (isNil(selected)) {
      const minPair = minBy(zip(sopIds, categoryNames), (p) => p[1].toLowerCase());
      setSelected(minPair?.[0], { inParams: false });
    }
  }, [selected, setSelected, sopIds, categoryNames]);

  const onRowClick = useCallback((row, event) => {
    if (event.detail > 1) {
      navigate(getPath(getGenericId(row.id, ENTITY_SOP)));
      return;
    }
    if (listView) {
      setSelected(row.id, { inParams: true });
    } else {
      setPreviewState({ open: true, id: row.id });
      event.stopPropagation(); /* To prevent the preview from closing */
    }
  }, [navigate, listView, setSelected]);

  const listViewColumnProps = useMemo(() => ({
    checkbox: {
      selection: isNil(selected) ? [] : [selected],
    },
    title: {
      renderValue: ({ row, value }) => (
        <Stack direction='row' alignItems='center'>
          <SopIcon colored />
          <Stack sx={{ ml: 1 }}>
            <Typography variant='subtitle2'>{value || 'Untitled'}</Typography>
            <Typography color='text.secondary' variant='caption'>{row.sop_code}</Typography>
          </Stack>
        </Stack>
      ),
      cellProps: { sx: { whiteSpace: 'normal' }},
    },
    sop_code: { hidden: true },
    created_by: { hidden: true },
    status: { hidden: true },
    category: { hidden: true },
    created: { hidden: true },
  }), [selected]);

  const onClose = useCallback(() => {
    setPreviewState((oldPreviewState) => ({ ...oldPreviewState, open: false }));
  }, []);

  return (
    <Box sx={{ flexGrow: 1, position: 'relative', display: 'flex', overflow: 'auto' }}>
      <SopTable
        ids={sopIds}
        withDnd
        latestVersionsOnly
        onRowClick={onRowClick}
        stateInSearchParams
        initState={{
          orderBy: 'category',
          order: 'asc',
          groupBy: listView ? null : 'category',
        }}
        loading={loading}
        noHeader={listView}
        disableFilter={listView}
        disableGroupBy={listView}
        columnProps={listView ? listViewColumnProps : null}
        actions={listView ? null : (<ActionAddSop />)}
      />
      <EntityPreview
        id={previewState.id}
        type={ENTITY_SOP}
        open={previewState.open}
        onClose={onClose}
        width={510}
      />
    </Box>
  );
}

SopsTable.propTypes = {
  listView: PropTypes.bool,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
};

export default SopsTable;
