import AppNavigation from './AppNavigation';
import AppSearch from './AppSearch';
import AppSpace from './AppSpace';
import AppTabs from './AppTabs';
import CheckinButton from './CheckinButton';
import Divider from '@mui/material/Divider';
import React from 'react';
import Stack from '@mui/material/Stack';
import SyncButton from './SyncButton';

const dividerProps = {
  orientation: 'vertical',
  flexItem: true,
  variant: 'middle',
  sx: { mx: 2 },
};

function AppToolbar() {
  return (
    <Stack direction='row' alignItems='center'>
      <AppNavigation />
      <SyncButton />
      <CheckinButton />
      <Divider {...dividerProps} />
      <AppSpace />
      <Divider {...dividerProps} />
      <AppTabs />
      <AppSearch />
    </Stack>
  );
}

export default AppToolbar;
