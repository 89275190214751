import CustomEditor, { useWsProvider } from 'components/CustomEditor';
import { useNote, useNoteHasLoaded, useUpdateEntity } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ENTITY_NOTE } from 'constants/schemas';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';

const toolbarProps = { elevation: 2 };

function NoteBody(props) {
  const { id } = props;
  const content = useNote(id, 'content');
  const contentHasLoaded = useNoteHasLoaded(id, 'content');
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(id, ENTITY_NOTE);
  const isReadOnly = useEntityIsReadOnly();

  const { connected, wsProvider } = useWsProvider(`note/${id}/content`);

  return (
    <Box sx={{ position: 'relative', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      { !contentHasLoaded || !connected ? (
        <>
          <Divider sx={{ mt: 2 }} />
          <Loading />
        </>
      ) : (
        <CustomEditor
          key={`content_${id}`}
          hideToolbarOnBlur={false}
          fullScreen
          withDays
          disabled={isReadOnly}
          interactable={!isReadOnly}
          keepEditorMounted
          placeholder={!isReadOnly ? 'Start writing...' : null}
          sx={{ position: 'absolute' }}
          initialValue={content}
          onChange={editorChangeHandler}
          onChangeSerialized={(value) => update({ type: ENTITY_NOTE, id, key: 'content', value })}
          toolbarProps={toolbarProps}
          collaborative
          wsProvider={wsProvider}
        />
      )}
    </Box>
  );
}

NoteBody.propTypes = {
  id: PropTypes.number.isRequired,
};

export default NoteBody;
