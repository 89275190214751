import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';

import { directorySelectors } from 'store/entity';

const MOVE_ENTITY_TO_DIRECTORY_TYPE_MAP = {
  [ENTITY_EXPERIMENT]: DIR_TYPE_STUDY,
  [ENTITY_NOTE]: DIR_TYPE_STUDY,
  [ENTITY_SOP]: DIR_TYPE_STUDY,
  [ENTITY_TASK]: DIR_TYPE_STUDY,
};

const MOVE_DIRECTORY_TO_DIRECTORY_TYPE_MAP = {
  [DIR_TYPE_STUDY]: DIR_TYPE_PROJECT,
  [DIR_TYPE_PROJECT]: DIR_TYPE_PROGRAM,
  [DIR_TYPE_PROGRAM]: null,
};

export const selectMoveToDirectoryType = (state, genericId) => {
  const { id, type } = genericId ?? {};
  if (type === ENTITY_DIRECTORY) {
    const directoryType = directorySelectors.selectDirectory(state, id, 'type');
    return MOVE_DIRECTORY_TO_DIRECTORY_TYPE_MAP[directoryType];
  }
  return MOVE_ENTITY_TO_DIRECTORY_TYPE_MAP[type];
};
