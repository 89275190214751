import { ColumnsContext, useColumns } from './DataTableContext';
import React, { useMemo } from 'react';

import ActionFilter from './ActionFilter';
import ActionGroupBy from './ActionGroupBy';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function TableActions(props) {
  const {
    disableFilter,
    disableGroupBy,
    actions,
  } = props;
  const columns = useColumns();

  const filterableColumns = useMemo(() => columns.filter((col) => col.filterable), [columns]);
  const showFilter = !disableFilter && filterableColumns.length > 0;

  const groupableColumns = useMemo(() => columns.filter((col) => col.groupable), [columns]);
  const showGroupBy = !disableGroupBy && groupableColumns.length > 0;

  return !showFilter && !showGroupBy && !actions ? null : (
    <Box
      sx={{
        borderLeft: 1,
        borderColor: 'divider',
        position: 'sticky',
        top: 0,
        right: 0,
        bgcolor: 'background.grey',
        zIndex: 2,
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      { showFilter ? (
        <ColumnsContext.Provider value={filterableColumns}>
          <ActionFilter />
        </ColumnsContext.Provider>
      ) : null}
      { showGroupBy ? (
        <ColumnsContext.Provider value={groupableColumns}>
          <ActionGroupBy />
        </ColumnsContext.Provider>
      ) : null}
      { actions }
    </Box>
  );
}

TableActions.propTypes = {
  disableFilter: PropTypes.bool,
  disableGroupBy: PropTypes.bool,
  actions: PropTypes.node,
};

export default TableActions;
