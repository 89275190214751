import * as AuthService from 'services/AuthService';
import * as authSelectors from './auth.selectors';

import { AUTH, SLICES_TO_CACHE, getLocalStoreSliceId } from 'store/store.constants';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { locationRef, navigateRef } from 'utils/dom.utils';

import { localStore } from 'utils/localStorage.utils';
import { pauseQueue } from 'services';
import { responseToPayload } from 'store/store.actions.utils';

export const login = createAsyncThunk(
  `${AUTH}/login`,
  async ({ username, password }, thunkAPI) => {
    const res = await AuthService.login({ username, password });
    return responseToPayload(res, thunkAPI);
  },
);

export const resetPassword = createAsyncThunk(
  `${AUTH}/resetPassword`,
  async ({ email }, thunkAPI) => {
    const res = await AuthService.resetPassword({ email });
    return responseToPayload(res, thunkAPI);
  },
);

export const resetPasswordConfirm = createAsyncThunk(
  `${AUTH}/resetPassword/confirm`,
  async ({ password, token }, thunkAPI) => {
    const res = await AuthService.resetPasswordConfirm({ password, token });
    return responseToPayload(res, thunkAPI);
  },
);

export const validatePasswordResetToken = createAsyncThunk(
  `${AUTH}/resetPassword/validateToken`,
  async ({ token }, thunkAPI) => {
    const res = await AuthService.validatePasswordResetToken({ token });
    return responseToPayload(res, thunkAPI);
  },
);

export const verifyToken = createAsyncThunk(
  `${AUTH}/token/verify`,
  async (_, thunkAPI) => {
    const { getState } = thunkAPI;
    const stateToken = authSelectors.selectToken(getState());
    const res = await AuthService.verifyToken({ token: stateToken.access });
    return responseToPayload(res, thunkAPI);
  },
);

export const logoutAction = createAction(`${AUTH}/logout`);
export const logout = () => (dispatch) => {
  pauseQueue();
  for (const slice of SLICES_TO_CACHE) {
    localStore.clear(getLocalStoreSliceId(slice));
  }
  navigateRef?.current?.('/login', { state: { from: locationRef?.current }});
  dispatch(logoutAction());
};

export const refreshToken = createAsyncThunk(
  `${AUTH}/token/refresh`,
  async (_, thunkAPI) => {
    const { dispatch, getState, rejectWithValue } = thunkAPI;
    pauseQueue(); // NOTE: queue is released in useAuthVerify()
    const stateToken = authSelectors.selectToken(getState());
    if (!stateToken.refresh) {
      dispatch(logout());
      return rejectWithValue({ error: { message: 'No refresh token' }});
    }

    const res = await AuthService.refreshToken({ refresh: stateToken.refresh });

    if (res.error) {
      dispatch(logout());
    }

    return responseToPayload(res, thunkAPI);
  },
);
