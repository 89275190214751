import { DayPlugin, MetadataPlugin, S3StoragePlugin, VariablePlugin } from './plugin';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { deserializer, serializer } from './serializers';

import { Editor } from '@acheloisbiosoftware/absui.core';
import { EmptyField } from 'components/Field';
import PropTypes from 'prop-types';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import useCursorData from './hooks/useCursorData';
import { useDebouncedFn } from '@acheloisbiosoftware/absui.hooks';

const CustomEditor = React.forwardRef((props, ref) => {
  const {
    debounceSerialization,
    onChangeSerialized,
    onChange,
    onFocus,
    onBlur,
    initialValue,
    readOnly,
    disabled,
    dense,
    serializeOnBlur,
    withVariables,
    withDays,
    collaborative,
    emptyFieldProps,
    containerProps,
    ...restProps
  } = props;
  const _ref = useRef();
  const editorRef = ref ?? _ref;
  const focused = useRef(false);
  const awaitingSerialization = useRef(false);
  const [showEmpty, setShowEmpty] = useState(false);
  const [version, setVersion] = useState(0); /* Used to update content after initial render (ONLY if not focused) */
  const cursorData = useCursorData();

  const serialize = useCallback((newValue, editor) => {
    if (isDefined(onChangeSerialized)) {
      onChangeSerialized(serializer(editor, newValue), editor);
      awaitingSerialization.current = false;
    }
  }, [onChangeSerialized]);

  const isDebounced = isDefined(debounceSerialization) && debounceSerialization;
  const debouncedSerialize = useDebouncedFn(serialize, typeof(debounceSerialization) === 'number' ? debounceSerialization : (readOnly ? 1000 : 10000));

  const _onChange = (newValue, editor) => {
    const serializationCallback = isDebounced ? debouncedSerialize : serialize;
    onChange?.(newValue, editor, {
      serializationCallback,
      isNewSerialization: !awaitingSerialization.current,
    });
    awaitingSerialization.current = true;
    serializationCallback(newValue, editor);
  };

  const _onFocus = (...args) => {
    focused.current = true;
    onFocus?.(...args);
  };

  const _onBlur = (...args) => {
    focused.current = false;
    if (isDebounced && serializeOnBlur) debouncedSerialize.flush();
    onBlur?.(...args);
  };

  useEffect(() => {
    /**
     * NOTE: If collaborative, the editor will update itself to the current
     * websocket content, so we don't need to worry about updating the initial
     * value. If we did not include this check, collaborative editors with
     * images or files get stuck in a loop: (1) onChange is called initially,
     * (2) this triggers a serialization at some point, (3) the serialization
     * creates a change in the intial value (since S3 URLs are updated), (4) if
     * the user is not focused on the editor, this will re-render the entire
     * editor, which will trigger another onChange, and so on.
     */
    if (isDefined(initialValue) && !focused.current && !collaborative) {
      setVersion((oldVersion) => oldVersion + 1);
    }
  }, [initialValue, collaborative]);

  useEffect(() => {
    const editor = editorRef?.current;
    if (readOnly) setShowEmpty(isDefined(editor) && !editor.hasContent());
  }, [ref, readOnly, editorRef]);

  const plugins = useMemo(() => {
    const _plugins = [S3StoragePlugin, MetadataPlugin];
    if (withVariables) _plugins.push(VariablePlugin);
    if (withDays) _plugins.push(DayPlugin);
    return _plugins;
  }, [withVariables, withDays]);

  const _containerProps = useMemo(() => ({
    ...containerProps,
    onFocus: (...args) => {
      focused.current = true;
      containerProps?.onFocus?.(...args);
    },
    onBlur: (...args) => {
      focused.current = false;
      containerProps?.onBlur?.(...args);
    },
  }), [containerProps]);

  const deserializedInitialValue = isDefined(initialValue) ? deserializer(initialValue) : [];

  if (readOnly && (deserializedInitialValue.length === 0 || showEmpty)) {
    return (<EmptyField {...emptyFieldProps} />);
  }

  return (
    <Editor
      ref={editorRef}
      hideToolbarOnBlur
      readOnly={readOnly || disabled}
      outlined={!readOnly}
      collaborative={collaborative}
      withCursors={collaborative}
      {...restProps}
      key={`editor_v${version}_${restProps?.key ?? '0'}`}
      initialValue={deserializedInitialValue}
      dense={readOnly || dense}
      onChange={_onChange}
      onFocus={_onFocus}
      onBlur={_onBlur}
      plugins={plugins}
      containerProps={_containerProps}
      cursorData={collaborative ? cursorData : null}
    />
  );
});

CustomEditor.displayName = 'CustomEditor';

CustomEditor.propTypes = {
  debounceSerialization: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onChangeSerialized: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  initialValue: PropTypes.shape({
    webComponents: PropTypes.array,
  }),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  dense: PropTypes.bool,
  serializeOnBlur: PropTypes.bool,
  withVariables: PropTypes.bool,
  withDays: PropTypes.bool,
  collaborative: PropTypes.bool,
  emptyFieldProps: PropTypes.object,
  containerProps: PropTypes.object,
};

CustomEditor.defaultProps = {
  debounceSerialization: true,
  serializeOnBlur: true,
};

export default CustomEditor;
