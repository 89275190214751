import { useCallback, useMemo, useState } from 'react';

import { DIR_TYPE_STUDY } from 'constants/directory.constants';
import useSpaceInitialPermissionRelationships from 'hooks/useSpaceInitialPermissionRelationships';

const DEFAULT_INIT_STATE = {
  name: '',
  type: DIR_TYPE_STUDY,
  is_template: false,
  permission_relationships: [],
};

export const validateDirectoryFormData = (formData) => {
  const { name } = formData ?? {};
  return Boolean(name);
};

const useNewDirectoryFormData = (initState) => {
  const initPermissionRelationships = useSpaceInitialPermissionRelationships();
  const initFormData = useMemo(() => ({
    ...DEFAULT_INIT_STATE,
    permission_relationships: initPermissionRelationships,
    ...initState,
  }), [initState, initPermissionRelationships]);

  const [formData, setFormData] = useState(initFormData);
  const valid = validateDirectoryFormData(formData);

  const resetFormData = useCallback(() => setFormData(initFormData), [initFormData]);

  const updateFormData = useCallback((key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: typeof(value) === 'function' ? value(prev[key]) : value,
    }));
  }, []);

  return useMemo(() => ({
    formData,
    valid,
    resetFormData,
    updateFormData,
  }), [formData, valid, resetFormData, updateFormData]);
};

export default useNewDirectoryFormData;
