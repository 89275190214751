import { getInitialState } from './preference.initialState';
import { reduceLogout } from 'store/store.reducers.utils';

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const setPreference = (state, action) => {
  const { key, value } = action.payload;
  state[key] = value;
};

export const reducers = { setPreference };
export const extraReducers = (builder) => {
  reduceLogout(builder, getInitialState);
};
