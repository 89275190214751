import React, { useMemo } from 'react';

import Box from '@mui/material/Box';
import { IconButtonAction } from 'components/EntityActions';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import Typography from '@mui/material/Typography';
import ViewListIcon from '@mui/icons-material/ViewList';

function ListingPageHeader(props) {
  const { listView, setListView, sectionHeaderProps, headerDescription } = props;
  const extraActions = useMemo(() => ({
    ...sectionHeaderProps?.extraActions,
    right: [
      ...(sectionHeaderProps?.extraActions?.right || []),
      {
        id: 'viewOptions',
        component: IconButtonAction,
        props: {
          label: 'List View',
          icon: <ViewListIcon />,
          size: 'small',
          color: listView ? 'primary' : 'inherit',
          onClick: () => setListView((prev) => !prev),
        },
      },
    ],
  }), [sectionHeaderProps, listView, setListView]);

  return (
    <Box sx={{ borderBottom: listView ? 2 : 1, borderColor: 'divider', px: 1 }}>
      <SectionHeader
        {...sectionHeaderProps}
        extraActions={extraActions}
      />
      { headerDescription ? (
        <Typography variant='caption' sx={{ display: 'block', mx: 1, mt: -1 }}>
          {headerDescription}
        </Typography>
      ) : null}
    </Box>
  );
}

ListingPageHeader.propTypes = {
  listView: PropTypes.bool.isRequired,
  setListView: PropTypes.func.isRequired,
  sectionHeaderProps: PropTypes.object,
  headerDescription: PropTypes.node,
};

export default ListingPageHeader;
