import {
  DirectoryIcon,
  LocationIcon,
  TimeIcon,
  UserIcon,
} from 'constants/icon.constants';
import { ENTITY_ACTION_DELETE, EntityActionState, useEntityActions } from 'components/EntityActions';
import React, { useEffect } from 'react';
import { getIcon, getPath } from 'utils/entity.utils';
import { useDirectory, useMasterNote, useMasterNoteSection, useUserName } from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import { DATE_FMT_SHORT } from 'constants/date.constants';
import DeletedAlert from 'components/DeletedAlert';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import Field from 'components/Field';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import SidePreviewActions from './SidePreviewActions';
import SidePreviewContent from './SidePreviewContent';
import SidePreviewHeader from './SidePreviewHeader';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { directoryActions } from 'store/entity';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { useOnClose } from './SidePreview';

function DirectoryPreviewContent(props) {
  const { id } = props;
  const onClose = useOnClose();
  const dispatch = useDispatch();
  const directory = useDirectory(id);
  const owner = useUserName(directory?.created_by);
  const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: directory?.type });
  const masterNoteSectionIds = useMasterNote(directory?.master_note, 'sections');
  const masterNoteSectionTitles = useMasterNoteSection(masterNoteSectionIds, 'title');

  useEffect(() => {
    if (id) dispatch(directoryActions.fetchDirectory({ id }));
  }, [dispatch, id]);

  const createRow = (title, value, icon) => ({ title, value, icon });
  const iconSx = { fontSize: 20, my: 'auto' };
  const tableProperties = [
    createRow('Owner', owner, <UserIcon sx={iconSx} />),
    createRow('Date Created', isDefined(directory?.created) ? formatDate(directory?.created, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    createRow('Last Modified', isDefined(directory?.modified) ? formatDate(directory?.modified, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    directory?.is_deleted ? createRow('Date Deleted', formatDate(directory?.deleted, DATE_FMT_SHORT), <TimeIcon sx={iconSx} />) : null,
    createRow('Location(s)', (<Locations directoryItem={directory?.directory_item} />), <LocationIcon sx={iconSx} />),
  ];

  const actions = useEntityActions(id, ENTITY_DIRECTORY, {
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess: onClose }},
  });

  return (
    <>
      <EntityActionState>
        <SidePreviewActions path={getPath(getGenericId(id, ENTITY_DIRECTORY))} actions={actions} />
      </EntityActionState>

      <SidePreviewHeader
        icon={Icon ? <Icon colored template={directory?.is_template} /> : <DirectoryIcon />}
        title={directory?.name || 'Untitled'}
        subtitle={directory?.directory_code}
      />

      <SidePreviewContent>
        <DeletedAlert id={id} type={ENTITY_DIRECTORY} sx={{ mb: 1 }} />

        <Table size='small'>
          <TableBody>
            {tableProperties.map((row) => (!isDefined(row) ? null : (
              <TableRow key={row.title}>
                <TableCell sx={{ minWidth: 137, width: 137 }}>
                  <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                    {row.icon}
                    <Typography variant='body2' sx={{ my: 'auto', ml: 1 }}>
                      {row.title ?? <Skeleton />}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ typography: 'body1' }}>
                  {row.value ?? <Skeleton />}
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        { (masterNoteSectionIds ?? []).length > 0 ? (
          <Box sx={{ mt: 2, position: 'relative' }}>
            <Field label='Overview'>
              <List>
                {masterNoteSectionIds.map((sectionId, idx) => (
                  <ListItem key={`section_${sectionId}`} disablePadding>
                    <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
                      <Avatar>{idx + 1}</Avatar>
                    </ListItemIcon>
                    <ListItemText primary={masterNoteSectionTitles[idx]} />
                  </ListItem>
                ))}
              </List>
            </Field>
          </Box>
        ) : null }
      </SidePreviewContent>
    </>
  );
}

DirectoryPreviewContent.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default DirectoryPreviewContent;
