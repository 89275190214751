import MasterNoteNavigator from './MasterNoteNavigator';
import PageContainer from 'components/PageContainer';
import ProgramNavigator from './ProgramNavigator';
import React from 'react';
import SpaceNavigator from './SpaceNavigator';
import Stack from '@mui/material/Stack';

function Navigator() {
  return (
    <PageContainer
      component={Stack}
      direction='row'
    >
      <SpaceNavigator />
      <ProgramNavigator />
      <MasterNoteNavigator title='Projects' depth={0} />
      <MasterNoteNavigator title='Studies' depth={1} />
      <MasterNoteNavigator title='Documents' depth={2} />
    </PageContainer>
  );
}

export default Navigator;
