import { Prop, userActions } from 'store/entity';
import { useFetchListing, useUserList } from 'hooks/store.hooks';

import ActionAddUser from './ActionAddUser';
import { EntityActionState } from 'components/EntityActions';
import React from 'react';
import UserTable from 'components/UserTable';

const listParams = { prop: new Prop('id') };

function Users() {
  const userIds = useUserList(listParams);
  const loading = useFetchListing(userActions.fetchUsers);

  return (
    <EntityActionState>
      <UserTable
        userIds={userIds}
        loading={loading}
        actions={(<ActionAddUser />)}
      />
    </EntityActionState>
  );
}

export default Users;
