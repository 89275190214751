import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import React, { useMemo } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { useEntity, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { GenericProp } from 'store/entity';
import LaterContent from './LaterContent';
import LaterTable from './LaterTable';
import ListingPage from 'components/ListingPage';
import { genericIdFromString } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

const parseSelected = (selected) => selected;
const validateSelected = (selected) => {
  const genericId = genericIdFromString(selected);
  return isDefined(genericId?.id) && isDefined(genericId?.type);
};

const nameProp = new GenericProp({
  [ENTITY_USER]: 'full_name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_DEPARTMENT]: 'name',
});

function Later() {
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const individualName = useEntity(spaceGenericId, nameProp);
  const title = [SPACE_USER, SPACE_TEAM, SPACE_DEPARTMENT].includes(spaceType) ? (
    individualName ? `Later (${individualName})` : null
  ) : 'Later';

  const sectionHeaderProps = useMemo(() => ({
    title,
    skeletonProps: { width: 300 },
  }), [title]);

  return (
    <ListingPage
      parseSelected={parseSelected}
      validateSelected={validateSelected}
      sectionHeaderProps={sectionHeaderProps}
      headerDescription='This is a holding place for all unplaced documents in the space.'
      tableComponent={LaterTable}
      contentComponent={LaterContent}
    />
  );
}

export default Later;
