import * as userActions from './user.actions';

import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, userActions.fetchDepartments);
  reduceEntities(builder, userActions.createDepartment);
  reduceEntities(builder, userActions.fetchDepartment, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_DEPARTMENT)),
  });
  reduceEntities(builder, userActions.patchDepartment);
  reduceEntities(builder, userActions.deleteDepartment, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_DEPARTMENT)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_DEPARTMENT)),
  });
  reduceEntities(builder, userActions.fetchTeams);
  reduceEntities(builder, userActions.createTeam);
  reduceEntities(builder, userActions.fetchTeam, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_TEAM)),
  });
  reduceEntities(builder, userActions.patchTeam);
  reduceEntities(builder, userActions.deleteTeam, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_TEAM)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_TEAM)),
  });
  reduceEntities(builder, userActions.fetchUserRoles);
  reduceEntities(builder, userActions.createUserRole);
  reduceEntities(builder, userActions.fetchUserRole, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_USER_ROLE)),
  });
  reduceEntities(builder, userActions.patchUserRole);
  reduceEntities(builder, userActions.deleteUserRole, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_USER_ROLE)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_USER_ROLE)),
  });
  reduceEntities(builder, userActions.fetchUsers);
  reduceEntities(builder, userActions.createUser);
  reduceEntities(builder, userActions.fetchUser, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_USER)),
  });
  reduceEntities(builder, userActions.patchUser);
  reduceEntities(builder, userActions.patchUserPreferences);
};
