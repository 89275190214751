import { DepartmentIcon, ProfileIcon, TeamIcon, UserManagementIcon, UserRoleIcon } from 'constants/icon.constants';
import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import React, { useState } from 'react';
import { useMyGlobalPermission, useMyId, useUser, useUserName } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Avatar from 'components/Avatar';
import { Button } from '@acheloisbiosoftware/absui.core';
import Divider from '@mui/material/Divider';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Link from 'components/Link';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import { authActions } from 'store/auth';
import { formatType } from 'utils/entity.utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function SettingsButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myId = useMyId();
  const myName = useUserName(myId);
  const myEmail = useUser(myId, 'email');

  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const canViewUserRoles = useMyGlobalPermission(ENTITY_USER_ROLE, ACTION_VIEW);
  const canViewTeams = useMyGlobalPermission(ENTITY_TEAM, ACTION_VIEW);
  const canViewDepartments = useMyGlobalPermission(ENTITY_DEPARTMENT, ACTION_VIEW);
  const userSettingsPages = [
    {
      label: `Manage ${formatType(ENTITY_USER, { plural: true })}`,
      Icon: UserManagementIcon,
      path: '/settings/users',
      canView: canViewUsers,
    },
    {
      label: `Manage ${formatType(ENTITY_USER_ROLE, { plural: true })}`,
      Icon: UserRoleIcon,
      path: '/settings/user-roles',
      canView: canViewUserRoles,
    },
    {
      label: `Manage ${formatType(ENTITY_TEAM, { plural: true })}`,
      Icon: TeamIcon,
      path: '/settings/teams',
      canView: canViewTeams,
    },
    {
      label: `Manage ${formatType(ENTITY_DEPARTMENT, { plural: true })}`,
      Icon: DepartmentIcon,
      path: '/settings/departments',
      canView: canViewDepartments,
    },
  ];

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  const onDownloadErrorLogs = () => {
    window.__acta__?.downloadErrorLogs?.();
    closeMenu();
  };

  const onLogout = () => {
    dispatch(authActions.logout({ navigate }));
    closeMenu();
  };

  return (
    <>
      <Tooltip arrow placement='right' title='Profile & Settings'>
        <Button
          icon
          onClick={openMenu}
          sx={{ color: 'text.icon' }}
        >
          <SettingsIcon />
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem
          label={myName}
          icon={<Avatar id={myId} type={ENTITY_USER} />}
          listItemTextProps={{ secondary: myEmail }}
          disabled
          sx={{ '&.Mui-disabled': { opacity: 1 }}}
        />
        <Divider />
        <MenuItem
          label='Profile & Preferences'
          icon={<ProfileIcon />}
          component={Link}
          to='/settings/profile'
          onClick={closeMenu}
          listItemTextProps={{ primaryTypographyProps: { variant: 'body2' }}}
        />
        { userSettingsPages.map((page) => {
          const { label, Icon, path, canView } = page;
          return canView ? (
            <MenuItem
              key={`userSettingsPage_${label}`}
              label={label}
              icon={<Icon />}
              component={Link}
              to={path}
              onClick={closeMenu}
              listItemTextProps={{ primaryTypographyProps: { variant: 'body2' }}}
            />
          ) : null;
        })}
        <Divider />
        <MenuItem
          label='Download Error Logs'
          icon={<HelpCenterIcon fontSize='small' />}
          onClick={onDownloadErrorLogs}
          listItemTextProps={{ primaryTypographyProps: { variant: 'body2', color: 'text.secondary' }}}
        />
        <Divider />
        <MenuItem
          label='Logout'
          icon={<LogoutIcon />}
          onClick={onLogout}
          listItemTextProps={{ primaryTypographyProps: { variant: 'body2' }}}
        />
      </Menu>
    </>
  );
}

export default SettingsButton;
