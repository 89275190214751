import { BaseAction, MenuItemAction } from 'components/EntityActions';
import { ENTITY_DEPARTMENT, ENTITY_TASK, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import React, { useMemo, useState } from 'react';
import { SPACE_DEPARTMENT, SPACE_OPUS_U, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { VIEW_ALL, VIEW_INBOX, VIEW_OUTBOX } from './Tasks.constants';
import { useEntity, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { GenericProp } from 'store/entity';
import InboxIcon from '@mui/icons-material/Inbox';
import ListingPage from 'components/ListingPage';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import OutboxIcon from '@mui/icons-material/Outbox';
import TasksContent from './TasksContent';
import TasksTable from './TasksTable';
import { formatType } from 'utils/entity.utils';

const parseSelected = (selected) => parseFloat(selected);
const validateSelected = (selected) => !isNaN(selected);

const nameProp = new GenericProp({
  [ENTITY_USER]: 'full_name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_DEPARTMENT]: 'name',
});

const restrictView = (view, spaceType) => {
  if (spaceType === SPACE_USER) return ![VIEW_INBOX, VIEW_OUTBOX].includes(view) ? VIEW_INBOX : view;
  if ([SPACE_TEAM, SPACE_DEPARTMENT].includes(spaceType)) return VIEW_ALL;
  return view;
};

const getTitle = (view, spaceType, individualName) => {
  const viewTitleMap = {
    [VIEW_ALL]: formatType(ENTITY_TASK, { plural: true }),
    [VIEW_INBOX]: `${formatType(ENTITY_TASK)} Inbox`,
    [VIEW_OUTBOX]: `${formatType(ENTITY_TASK)} Outbox`,
  };
  const viewTitle = viewTitleMap[view];
  if (spaceType === SPACE_USER) {
    return individualName ? `${individualName}'s ${viewTitle}` : null;
  }
  if ([SPACE_TEAM, SPACE_DEPARTMENT].includes(spaceType)) {
    return individualName ? `${individualName} ${viewTitle}` : null;
  }
  return view === VIEW_ALL ? `All ${viewTitle}` : `Your ${viewTitle}`;
};

function Tasks() {
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();

  const [view, setView] = useState(VIEW_ALL);
  const _view = restrictView(view, spaceType);

  const individualName = useEntity(spaceGenericId, nameProp);
  const title = getTitle(_view, spaceType, individualName);

  const sectionHeaderProps = useMemo(() => ({
    title,
    skeletonProps: { width: 300 },
    extraActions: { inMenu: [
      ...(spaceType === SPACE_OPUS_U ? [{
        id: 'viewAll',
        component: BaseAction,
        props: {
          component: MenuItemAction,
          label: 'Show all',
          icon: <InboxIcon />,
          onAction: () => setView(VIEW_ALL),
        },
      }] : []),
      ...([SPACE_OPUS_U, SPACE_USER].includes(spaceType) ? [
        {
          id: 'viewInbox',
          component: BaseAction,
          props: {
            component: MenuItemAction,
            label: 'Show inbox',
            icon: <MoveToInboxIcon />,
            onAction: () => setView(VIEW_INBOX),
          },
        },
        {
          id: 'viewOutbox',
          component: BaseAction,
          props: {
            component: MenuItemAction,
            label: 'Show outbox',
            icon: <OutboxIcon />,
            onAction: () => setView(VIEW_OUTBOX),
          },
        },
      ] : []),
    ]},
  }), [title, spaceType]);

  return (
    <ListingPage
      parseSelected={parseSelected}
      validateSelected={validateSelected}
      sectionHeaderProps={sectionHeaderProps}
      tableComponent={TasksTable}
      contentComponent={TasksContent}
      tableComponentProps={{ view: _view }}
    />
  );
}

export default Tasks;
