import { Button, ConditionalWrapper, EditorConstants, EditorHooks, EditorPlugins, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { eventListenerEffect, usePromise } from '@acheloisbiosoftware/absui.hooks';
import { useReadOnly, useSelected, useSlateStatic } from 'slate-react';

import Box from '@mui/material/Box';
import CalculationDefinition from './CalculationDefinition';
import { EditIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { VARIABLE_UPDATE_EVENT } from './Variable.constants';
import { Variable } from './Variable';

const { BLOCK_LIST_CHECKBOX, BLOCK_LIST_RADIO } = EditorConstants;
const { useFocusedDisplay } = EditorHooks;
const { List, Table } = EditorPlugins;

function CalculationElement(props) {
  const { attributes, children, element, borrowFocus } = props;
  const { uuid, label, units, formula } = element;
  const editorStatic = useSlateStatic();
  const readOnly = useReadOnly();
  const selected = useSelected();
  const [fakeSelected, setFakeSelected] = useState(false);
  const focusedDisplay = useFocusedDisplay();
  const validVariables = Variable.validFormulaVariables(editorStatic, formula);
  const validValues = Variable.validFormulaValues(editorStatic, formula);
  const validFormula = Variable.validFormula(editorStatic, formula);
  const error = !validVariables || (validValues && !validFormula);

  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(inputRef?.current);
  const [open, setOpen] = useState(false);
  const { promiseRef, defer } = usePromise();
  const [formLabel, setFormLabel] = useState(label);
  const [formUnits, setFormUnits] = useState(units);
  const [formFormula, setFormFormula] = useState(formula);

  useEffect(() => eventListenerEffect(document, VARIABLE_UPDATE_EVENT, (event) => {
    const { detail: updatedNode } = event;
    const { uuid: dependencyUuid } = updatedNode;
    if (formula.includes(`¿${dependencyUuid}¿`)) {
      editorStatic.Variable.updateCalculation(uuid, updatedNode);
    }
  }), [editorStatic, formula, uuid]);

  useEffect(() => {
    /*
     * When open gets toggled, the TextField must rerender since the
     * ConditionalWrapper/Tooltip rerender, so it gets a new ref. Hence, we must
     * wait for the TextField to mount, then update the anchorEl for the dialog.
     */
    if (open) {
      setAnchorEl(inputRef.current);
    } else {
      setAnchorEl(null);
    }
  }, [open]);

  const onClose = useCallback((exited) => {
    setOpen(false);
    promiseRef.current.resolve(() => {
      if (!exited && formFormula) {
        editorStatic.Variable.setCalculation(uuid, {
          label: formLabel,
          units: formUnits,
          formula: formFormula,
        });
      }
      setFakeSelected(false);
    });
    if (exited) {
      setFormLabel(label);
      setFormUnits(units);
      setFormFormula(formula);
    }
  }, [editorStatic, formLabel, formUnits, formFormula, label, units, formula, uuid, promiseRef]);

  const onOpen = useCallback((event) => {
    event.preventDefault();
    if (!readOnly) {
      setFakeSelected(true);
      borrowFocus(() => defer());
      setOpen(true);
    }
  }, [readOnly, borrowFocus, defer]);

  const onCopy = useCallback((event) => {
    event.stopPropagation();
  }, []);

  /* Alignment in lists looks bad, so correct alignment if in a list */
  const parentListType = List.hooks.useListType();
  const verticalAlign = parentListType && (parentListType === BLOCK_LIST_CHECKBOX || parentListType === BLOCK_LIST_RADIO) ? 'top' : 'baseline';

  /* No need for showing selection when table cells being selected */
  const tableSelection = Table.hooks.useTableSelection();

  const showSelectionHighlight = (
    (selected || fakeSelected) &&
    focusedDisplay &&
    !readOnly &&
    !tableSelection?.bounds
  );
  const showEditButton = showSelectionHighlight && !open;

  return (
    <Box {...attributes} component='span'>
      <ConditionalWrapper
        wrapperComponent={Tooltip}
        wrapperProps={{
          title: Variable.prettifyFormula(editorStatic, formula),
          arrow: true,
        }}
        condition={!open}
      >
        <TextField
          ref={inputRef}
          label={label}
          value={Variable.displayFormula(editorStatic, formula)}
          onCopy={onCopy}
          endAdornment={(
            <>
              {showEditButton ? (
                <Button onMouseDown={onOpen} size='small' icon>
                  <EditIcon fontSize='small' />
                </Button>
              ) : null}
              {units}
            </>
          )}
          error={error}
          size='small'
          sx={{
            verticalAlign,
            mx: 1,
            my: 0.5,
            userSelect: 'none',
            width: 200,
          }}
          InputSx={{ bgcolor: showSelectionHighlight ? 'Highlight' : null }}
          inputProps={{ disabled: true }}
        />
      </ConditionalWrapper>
      <CalculationDefinition
        calcUuid={uuid}
        anchorEl={anchorEl}
        open={Boolean(anchorEl && open)}
        onClose={onClose}
        formState={{
          label: formLabel,
          setLabel: setFormLabel,
          units: formUnits,
          setUnits: setFormUnits,
          formula: formFormula,
          setFormula: setFormFormula,
        }}
      />
      {children}
    </Box>
  );
}

CalculationElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    units: PropTypes.string,
    formula: PropTypes.string,
  }).isRequired,
  borrowFocus: PropTypes.func.isRequired,
};

export default CalculationElement;
