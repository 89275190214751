import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import { TemplateIcon } from 'constants/icon.constants';
import { formatType } from 'utils/entity.utils';

function TemplateChip(props) {
  const { type, ...restProps } = props;
  return (
    <Chip
      label={formatType(type, { template: true, includeTemplateType: false })}
      icon={<TemplateIcon />}
      {...restProps}
    />
  );
}

TemplateChip.propTypes = {
  type: PropTypes.string,
};

export default TemplateChip;
