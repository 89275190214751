import { MASTER_NOTE_CONTENT_MAX_WIDTH, MASTER_NOTE_CONTENT_MIN_WIDTH } from './MasterNote.constants';

import Box from '@mui/material/Box';
import Loading from 'components/Loading';
import MasterNoteAddSection from './MasterNoteAddSection';
import MasterNoteOverview from './MasterNoteOverview';
import MasterNoteSection from './MasterNoteSection';
import PropTypes from 'prop-types';
import React from 'react';
import { useMasterNote } from 'hooks/store.hooks';

function MasterNoteContent(props) {
  const { id, loading } = props;
  const sectionIds = useMasterNote(id, 'sections');
  return (
    <Box
      sx={{
        px: 2,
        position: 'relative',
        height: 1,
        width: 1,
        minWidth: MASTER_NOTE_CONTENT_MIN_WIDTH,
        boxSizing: 'border-box',
        overflow: 'auto',
        display: 'flex',
      }}
    >
      <Box sx={{ width: 1, maxWidth: MASTER_NOTE_CONTENT_MAX_WIDTH, mx: 'auto' }}>
        { loading ? (<Loading containerProps={{ sx: { minWidth: MASTER_NOTE_CONTENT_MIN_WIDTH }}} />) : (
          <>
            <MasterNoteOverview id={id} />
            { (sectionIds ?? []).map((sectionId) => (
              <MasterNoteSection
                key={`master-note-section-${sectionId}`}
                id={sectionId}
              />
            ))}
            <MasterNoteAddSection />
          </>
        )}
      </Box>
    </Box>
  );
}

MasterNoteContent.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
};

export default MasterNoteContent;
