import { AddIcon, SearchIcon } from 'constants/icon.constants';
import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import React, { useState } from 'react';

import Field from 'components/Field';
import PlanNewDocument from './PlanNewDocument';
import PropTypes from 'prop-types';
import SelectExistingDocument from './SelectExistingDocument';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const PLAN_NEW = 'plan-new';
const SELECT_EXISTING = 'select-existing';

const sourceOptions = [
  { value: PLAN_NEW, label: 'Plan New Document', Icon: AddIcon },
  { value: SELECT_EXISTING, label: 'Select Existing', Icon: SearchIcon },
];

function NewRelatedDocument(props) {
  const {
    related_obj_id,
    related_obj_content_type,
    related_obj_init_data,
    onChange,
    isNew,
    isExisting,
    onClear,
    onLaunch,
    launchDisabled,
    launchLoading,
    hideLaunch,
    hideOpen,
  } = props;
  const [source, setSource] = useState(isNew ? PLAN_NEW : (isExisting ? SELECT_EXISTING : null));

  return (
    <>
      <Field label='Source' labelProps={{ sx: { mt: 0 }}}>
        <ToggleButtonGroup
          value={source}
          size='small'
          exclusive
          onChange={(_, newValue) => setSource(newValue)}
        >
          { sourceOptions.map(({ label, value, Icon }) => (
            <ToggleButton key={value} value={value}>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ px: 2, whiteSpace: 'nowrap' }}
              >
                <Icon sx={{ mr: 1 }} />
                {label}
              </Stack>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Field>
      { source === PLAN_NEW ? (
        <PlanNewDocument
          related_obj_content_type={related_obj_content_type}
          related_obj_init_data={related_obj_init_data}
          onChange={onChange}
          isNew={isNew}
          onClear={onClear}
          onLaunch={onLaunch}
          launchDisabled={launchDisabled}
          launchLoading={launchLoading}
          hideLaunch={hideLaunch}
        />
      ) : source === SELECT_EXISTING ? (
        <SelectExistingDocument
          related_obj_id={related_obj_id}
          related_obj_content_type={related_obj_content_type}
          onChange={onChange}
          isExisting={isExisting}
          onClear={onClear}
          hideOpen={hideOpen}
        />
      ) : null}
    </>
  );
}

NewRelatedDocument.propTypes = {
  related_obj_id: PropTypes.number,
  related_obj_content_type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
  related_obj_init_data: PropTypes.object,
  onChange: PropTypes.func,
  isExisting: PropTypes.bool,
  isNew: PropTypes.bool,
  onClear: PropTypes.func,
  onLaunch: PropTypes.func,
  launchDisabled: PropTypes.bool,
  launchLoading: PropTypes.bool,
  hideLaunch: PropTypes.bool,
  hideOpen: PropTypes.bool,
};

export default NewRelatedDocument;
