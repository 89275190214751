import * as signatureActions from './signature.actions';

import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { ENTITY_SIGNATURE } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, signatureActions.createSignatureWorkflow);
  reduceEntities(builder, signatureActions.fetchSignatures);
  reduceEntities(builder, signatureActions.createSignature);
  reduceEntities(builder, signatureActions.patchSignature);
  reduceEntities(builder, signatureActions.deleteSignature, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_SIGNATURE)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_SIGNATURE)),
  });
  reduceEntities(builder, signatureActions.rejectSignature);
  reduceEntities(builder, signatureActions.signSignature);
  reduceEntities(builder, signatureActions.verifySignature);
};
