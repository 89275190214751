import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import React, { useMemo, useState } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import { useDepartmentList, useFetchListing, useMyGlobalPermission, useTeamList, useUserRoleList } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import { getGenericId } from 'utils/generic.utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/entity';

const initFormData = {
  first_name: '',
  last_name: '',
  email: '',
  teams: [],
  departments: [],
  user_roles: [],
};

function NewUserDialog(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initFormData);
  const [loading, setLoading] = useState(false);
  const {
    first_name,
    last_name,
    email,
    teams,
    departments,
    user_roles,
  } = formData;
  const submitDisabled = (
    !first_name ||
    !last_name ||
    !email ||
    teams.length === 0 ||
    departments.length === 0 ||
    user_roles.length === 0
  );

  const canViewUserRoles = useMyGlobalPermission(ENTITY_USER_ROLE, ACTION_VIEW);
  const canViewTeams = useMyGlobalPermission(ENTITY_TEAM, ACTION_VIEW);
  const canViewDepartments = useMyGlobalPermission(ENTITY_DEPARTMENT, ACTION_VIEW);

  const allRoles = useUserRoleList();
  const allTeams = useTeamList();
  const allDepartments = useDepartmentList();

  const userRoleFetchOptions = useMemo(() => ({ condition: canViewUserRoles }), [canViewUserRoles]);
  useFetchListing(userActions.fetchUserRoles, userRoleFetchOptions);

  const teamFetchOptions = useMemo(() => ({ condition: canViewTeams }), [canViewTeams]);
  useFetchListing(userActions.fetchTeams, teamFetchOptions);

  const departmentFetchOptions = useMemo(() => ({ condition: canViewDepartments }), [canViewDepartments]);
  useFetchListing(userActions.fetchDepartments, departmentFetchOptions);

  const setFormField = (key, value) => setFormData((oldFormData) => ({ ...oldFormData, [key]: value }));

  const onSubmit = () => {
    if (submitDisabled) return;
    setLoading(true);
    dispatch(userActions.createUser({
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      email: email.trim(),
      teams: teams.map((t) => t.id),
      departments: departments.map((d) => d.id),
      user_roles: user_roles.map((r) => r.id),
      username: email.trim(),
    }))
      .unwrap()
      .then((res) => navigate(getPath(getGenericId(res.result, ENTITY_USER))))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      title={`Create New ${formatType(ENTITY_USER)}`}
      open={open}
      onClose={onClose}
      confirmButtonText='Submit'
      onConfirm={onSubmit}
      fullWidth
      disableCloseOnConfirm
      loading={loading}
      confirmDisabled={submitDisabled}
    >
      <Box sx={{ display: 'flex', width: 1 }}>
        <Box sx={{ flexGrow: 1, mr: 2 }}>
          <Field
            label='First Name'
            value={first_name}
            variant={Field.VARIANTS.TEXT_FIELD}
            textFieldProps={{
              onChange: (_, value) => setFormField('first_name', value),
            }}
            labelProps={{ required: true }}
            readOnly={false}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Field
            label='Last Name'
            value={last_name}
            variant={Field.VARIANTS.TEXT_FIELD}
            textFieldProps={{
              onChange: (_, value) => setFormField('last_name', value),
            }}
            labelProps={{ required: true }}
            readOnly={false}
          />
        </Box>
      </Box>
      <Field
        label='Email'
        value={email}
        variant={Field.VARIANTS.TEXT_FIELD}
        textFieldProps={{
          onChange: (_, value) => setFormField('email', value),
        }}
        labelProps={{ required: true }}
        readOnly={false}
      />
      <Field
        label={formatType(ENTITY_USER_ROLE, { plural: true })}
        value={user_roles}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: allRoles,
          onChange: (newRoles) => setFormField('user_roles', newRoles),
          disableCreateOption: true,
          isOptionEqualToValue: (option, value) => option.id === value.id,
        }}
        labelProps={{ required: true }}
        readOnly={false}
      />
      <Field
        label={formatType(ENTITY_TEAM, { plural: true })}
        value={teams}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: allTeams,
          onChange: (newTeams) => setFormField('teams', newTeams),
          disableCreateOption: true,
          isOptionEqualToValue: (option, value) => option.id === value.id,
        }}
        labelProps={{ required: true }}
        readOnly={false}
      />
      <Field
        label={formatType(ENTITY_DEPARTMENT, { plural: true })}
        value={departments}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: allDepartments,
          onChange: (newDepartments) => setFormField('departments', newDepartments),
          disableCreateOption: true,
          isOptionEqualToValue: (option, value) => option.id === value.id,
        }}
        labelProps={{ required: true }}
        readOnly={false}
      />
    </Dialog>
  );
}

NewUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default NewUserDialog;
