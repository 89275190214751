import * as extraActions from './auth.extraActions';

import { reduceError, reduceLoading } from 'store/store.reducers.utils';

import { getInitialState } from './auth.initialState';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const reduceLogout = () => getInitialState();

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const reduceLogin = (builder, { pending, fulfilled, rejected }) => {
  builder
    .addCase(pending, reduceLoading)
    .addCase(fulfilled, (state, action) => {
      const { token, user } = action.payload;
      state.isAuthenticated = true;
      state.token = { ...state.token, ...token };
      state.myId = user?.id ?? state.myId;
    })
    .addCase(rejected, reduceLogout);
};

const reduceVerifyToken = (builder, { pending, fulfilled, rejected }) => {
  builder
    .addCase(pending, reduceLoading)
    .addCase(fulfilled, (state, action) => {
      const user = action.payload;
      state.isAuthenticated = true;
      state.myId = user?.id ?? state.myId;
    })
    .addCase(rejected, reduceError);
};

export const reducers = {};
export const extraReducers = (builder) => {
  reduceLogin(builder, extraActions.login);
  reduceLogin(builder, extraActions.refreshToken);
  reduceVerifyToken(builder, extraActions.verifyToken);
  builder.addCase(extraActions.logoutAction, reduceLogout);
};
