import NavDraggable from './NavDraggable';
import PropTypes from 'prop-types';
import React from 'react';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useSelector } from 'react-redux';

function NavSubsection(props) {
  const { subsection, subsectionSelector, collapsed } = props;
  const {
    id,
    text,
    onClick,
    draggable,
    sx,
    ...restProps
  } = useSelector((state) => subsectionSelector(state, subsection));

  return (
    <NavDraggable
      {...restProps}
      id={id}
      text={text}
      onClick={onClick}
      draggable={draggable}
      sx={mergeSx(
        { transition: (theme) => theme.transitions.create('margin-left') },
        !collapsed ? { ml: 4 } : { ml: 0 },
        sx,
      )}
    />
  );
}

NavSubsection.propTypes = {
  subsection: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  subsectionSelector: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
};

export default NavSubsection;
