import { ACTION_VIEW, RELATIONSHIP_PERMISSION_READ } from 'constants/permission.constants';
import { Autocomplete, Button } from '@acheloisbiosoftware/absui.core';
import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import { GenericProp, userActions } from 'store/entity';
import React, { useMemo, useState } from 'react';
import { genericIdEquals, getGenericId } from 'utils/generic.utils';
import {
  useDepartmentList,
  useEntity,
  useFetchListing,
  useMyGlobalPermission,
  useTeamList,
  useUserList,
} from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import PermissionOptionButton from './PermissionOptionButton';
import PropTypes from 'prop-types';
import { ShareIcon } from 'constants/icon.constants';

const nameProp = new GenericProp({
  [ENTITY_DEPARTMENT]: 'name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_USER]: 'full_name',
});

function ShareAccess(props) {
  const { onShare, existingIndividuals = []} = props;
  const [individuals, setIndividuals] = useState([]);
  const [permission, setPermission] = useState(RELATIONSHIP_PERMISSION_READ);
  const [loading, setLoading] = useState(false);
  const addingNewIndividuals = individuals.length > 0;

  const departmentListParams = useMemo(() => ({
    prop: 'id',
    filter: (department) => !existingIndividuals.some((individual) => individual.type === ENTITY_DEPARTMENT && individual.id === department.id),
  }), [existingIndividuals]);
  const nonSharedDepartmentIds = useDepartmentList(departmentListParams);

  const teamListParams = useMemo(() => ({
    prop: 'id',
    filter: (team) => !existingIndividuals.some((individual) => individual.type === ENTITY_TEAM && individual.id === team.id),
  }), [existingIndividuals]);
  const nonSharedTeamIds = useTeamList(teamListParams);

  const userListParams = useMemo(() => ({
    prop: 'id',
    filter: (user) => user.is_active && !existingIndividuals.some((individual) => individual.type === ENTITY_USER && individual.id === user.id),
  }), [existingIndividuals]);
  const nonSharedUsers = useUserList(userListParams);

  const nonSharedGenericIds = [
    ...nonSharedUsers.map((id) => getGenericId(id, ENTITY_USER)),
    ...nonSharedTeamIds.map((id) => getGenericId(id, ENTITY_TEAM)),
    ...nonSharedDepartmentIds.map((id) => getGenericId(id, ENTITY_DEPARTMENT)),
  ];

  const nonSharedIndividualNames = useEntity(nonSharedGenericIds, nameProp);

  const options = nonSharedGenericIds.map((genericId, idx) => ({
    ...genericId,
    name: nonSharedIndividualNames[idx],
  }));

  const canViewDepartments = useMyGlobalPermission(ENTITY_DEPARTMENT, ACTION_VIEW);
  const departmentFetchOptions = useMemo(() => ({ condition: canViewDepartments }), [canViewDepartments]);
  useFetchListing(userActions.fetchDepartments, departmentFetchOptions);

  const canViewTeams = useMyGlobalPermission(ENTITY_TEAM, ACTION_VIEW);
  const teamFetchOptions = useMemo(() => ({ condition: canViewTeams }), [canViewTeams]);
  useFetchListing(userActions.fetchTeams, teamFetchOptions);

  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const userFetchOptions = useMemo(() => ({ params: { filters: { is_active: true }}, condition: canViewUsers }), [canViewUsers]);
  useFetchListing(userActions.fetchUsers, userFetchOptions);

  const clearField = () => {
    setIndividuals([]);
    setPermission(RELATIONSHIP_PERMISSION_READ);
  };

  const _onShare = async () => {
    setLoading(true);
    try {
      await onShare?.(individuals, permission);
      clearField();
    } catch {} finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={{ width: 1, display: 'flex', mt: 1 }}>
        <Autocomplete
          multiple
          value={individuals}
          options={options}
          onChange={(newValue) => setIndividuals(newValue)}
          getOptionLabel={(option) => option.name}
          renderOption={(_props, option) => (
            <ListItem {..._props} key={`option_${option.type}-${option.id}`}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar id={option.id} type={option.type} sx={{ mr: 1 }} />
                {option.name}
              </Box>
            </ListItem>
          )}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={`tag_${option.type}-${option.id}`}
              label={option.name}
              avatar={<Avatar id={option.id} type={option.type} size={24} />}
              sx={{ '& .MuiChip-avatar': { color: 'background.default' }}}
            />
          ))}
          isOptionEqualToValue={(option, value) => genericIdEquals(option, value)}
          disableCreateOption
          inputProps={(params) => ({
            ...params,
            size: 'small',
            placeholder: addingNewIndividuals ? null : 'Add people or groups',
          })}
          sx={{ width: 1, mr: 1 }}
        />
        <Collapse
          in={addingNewIndividuals}
          orientation='horizontal'
          sx={{ '& .MuiCollapse-wrapperInner': { display: 'flex', width: 1 }}}
        >
          <PermissionOptionButton
            value={permission}
            onChange={(newValue) => setPermission(newValue)}
            canUpdate
          />
        </Collapse>
      </Box>
      <Collapse in={addingNewIndividuals}>
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'end' }}>
          <Button
            size='small'
            variant='text'
            onClick={clearField}
          >
            Cancel
          </Button>
          <Button
            size='small'
            startIcon={<ShareIcon />}
            onClick={_onShare}
            loading={loading}
            sx={{ ml: 1 }}
          >
            Share
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
}

ShareAccess.propTypes = {
  onShare: PropTypes.func,
  existingIndividuals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    type: PropTypes.string,
  })),
};

export default ShareAccess;
