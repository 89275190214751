import React, { useCallback, useMemo, useState } from 'react';
import { isFunction, isNil } from 'lodash';

import ListingPageContent from './ListingPageContent';
import ListingPageHeader from './ListingPageHeader';
import PageContainer from 'components/PageContainer';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { generateSearchParams } from 'utils/dom.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useResizableWidth } from '@acheloisbiosoftware/absui.hooks';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_INIT_LIST_VIEW = false;
const DEFAULT_PARSE_SELECTED = (selected) => selected;
const DEFAULT_VALIDATE_SELECTED = (selected) => !isNil(selected);
const DEFAULT_RESIZABLE_INIT_WIDTH = 300;
const DEFAULT_RESIZABLE_OPTIONS = { minWidth: 200, maxWidth: 600 };
const DEFAULT_RESIZER_WIDTH = 8;

function ListingPage(props) {
  const {
    initListView = DEFAULT_INIT_LIST_VIEW,
    parseSelected = DEFAULT_PARSE_SELECTED,
    validateSelected = DEFAULT_VALIDATE_SELECTED,
    pageContainerProps,
    sectionHeaderProps,
    headerDescription,
    tableComponent: TableComponent,
    tableComponentProps,
    contentComponent,
    contentComponentProps,
    contentContainerProps,
    resizableInitWidth = DEFAULT_RESIZABLE_INIT_WIDTH,
    resizableOptions,
    resizerWidth = DEFAULT_RESIZER_WIDTH,
  } = props;
  const [listView, setListView] = useState(initListView);
  const [stateSelected, setStateSelected] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsSelected = parseSelected(searchParams.get('selected'));
  const selected = validateSelected(paramsSelected) ? paramsSelected : stateSelected;

  const setSelected = useCallback((newSelected, { inParams } = {}) => {
    setStateSelected(newSelected);
    if (inParams) {
      setSearchParams((oldSearchParams) => {
        const newSearchParams = generateSearchParams({ selected: newSelected }, oldSearchParams);
        newSearchParams.delete('explore'); // removes the Workspace explore view
        return newSearchParams;
      });
    }
  }, [setSearchParams]);

  const _resizableOptions = useMemo(() => ({
    ...DEFAULT_RESIZABLE_OPTIONS,
    ...resizableOptions,
  }), [resizableOptions]);
  const { width, Resizer, isResizing } = useResizableWidth(resizableInitWidth, _resizableOptions);

  return (
    <PageContainer component={Stack} direction='row' {...pageContainerProps}>
      <Stack
        component={Paper}
        elevation={listView ? 1 : 2}
        sx={[
          {
            height: 1,
            borderRadius: 0,
            boxSizing: 'border-box',
            transition: (theme) => (isResizing ? null : theme.transitions.create('width')),
          },
          listView ? {
            borderRight: 2,
            borderColor: 'divider',
            width,
            minWidth: width,
          } : { width: 1 },
        ]}
      >
        <ListingPageHeader
          listView={listView}
          setListView={setListView}
          sectionHeaderProps={sectionHeaderProps}
          headerDescription={headerDescription}
        />
        { !isNil(TableComponent) ? (
          <TableComponent
            listView={listView}
            selected={selected}
            setSelected={setSelected}
            {...tableComponentProps}
          />
        ) : null}
      </Stack>
      <Stack
        {...contentContainerProps}
        sx={mergeSx(
          [
            {
              height: 1,
              transition: (theme) => (isResizing ? null : theme.transitions.create('width')),
            },
            listView ? { width: `calc(100% - ${width}px)` } : { width: 0 },
          ],
          isFunction(contentContainerProps?.sx) ? contentContainerProps.sx(listView, selected) : contentContainerProps?.sx,
        )}
      >
        { listView ? (
          <ListingPageContent
            selected={selected}
            contentComponent={contentComponent}
            contentComponentProps={contentComponentProps}
          />
        ) : null}
      </Stack>
      <Resizer
        sx={{
          width: resizerWidth,
          left: width - resizerWidth,
          zIndex: 'header',
        }}
      />
    </PageContainer>
  );
}

ListingPage.propTypes = {
  initListView: PropTypes.bool,
  parseSelected: PropTypes.func,
  validateSelected: PropTypes.func,
  pageContainerProps: PropTypes.object,
  sectionHeaderProps: PropTypes.object,
  headerDescription: PropTypes.node,
  tableComponent: PropTypes.elementType,
  tableComponentProps: PropTypes.object,
  contentComponent: PropTypes.elementType,
  contentComponentProps: PropTypes.object,
  contentContainerProps: PropTypes.object,
  resizableInitWidth: PropTypes.number,
  resizableOptions: PropTypes.object,
  resizerWidth: PropTypes.number,
};

export default ListingPage;
