import { createContext, useContext, useMemo } from 'react';

export const FilterStateContext = createContext();
export const useFilterState = () => useContext(FilterStateContext);

export const SortStateContext = createContext();
export const useSortState = () => useContext(SortStateContext);

export const GroupByStateContext = createContext();
export const useGroupByState = () => useContext(GroupByStateContext);

export const GroupedRowOpenStateContext = createContext();
export const useGroupedRowOpenState = () => useContext(GroupedRowOpenStateContext);

export const ExpandedRowsContext = createContext();
export const useExpandedRows = () => useContext(ExpandedRowsContext);

export const ColumnsContext = createContext();
export const useColumns = () => useContext(ColumnsContext);
export const useColumn = (id) => {
  const columns = useColumns();
  return useMemo(() => columns.find((col) => col.id === id), [columns, id]);
};

export const RowsContext = createContext();
export const useRows = () => useContext(RowsContext);
export const useRow = (id) => {
  const rows = useRows();
  return useMemo(() => rows.find((row) => row.id === id), [rows, id]);
};

export const RowHoverContext = createContext(false);
export const useRowHover = () => useContext(RowHoverContext);
