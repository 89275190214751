import { electron, inBrowser } from 'utils/electron.utils';
import { useCallback, useEffect } from 'react';
import { useCanGoBack, useCanGoForward } from './navigation.hooks';

import { attachmentActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useIsAuthenticated } from './store.hooks';
import { useNavigate } from 'react-router-dom';

export const useElectronContextMenuHandler = (options) => {
  const canGoBack = useCanGoBack();
  const canGoForward = useCanGoForward();
  const electronHandler = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    electron.ipcRenderer.send('window:showContextMenu', {
      backDisabled: !canGoBack,
      forwardDisabled: !canGoForward,
      ...options,
    });
  }, [canGoBack, canGoForward, options]);
  const browserHandler = useCallback(() => null, []);

  return inBrowser ? browserHandler : electronHandler;
};

export const useElectronContextMenu = (options) => {
  const handleContextMenu = useElectronContextMenuHandler(options);

  useEffect(() => {
    // Use default context menu in browser
    if (inBrowser) return () => null;

    window.addEventListener('contextmenu', handleContextMenu);
    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [handleContextMenu]);
};

export const useElectronNavigateListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (inBrowser) return () => null;
    const unsubscribe = electron.ipcRenderer.on('nav:navigate', (to) => {
      navigate(to);
    });
    return () => {
      unsubscribe();
    };
  }, [navigate]);
};

export const useElectronDriveUpdateListener = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (inBrowser || !isAuthenticated) return () => null;
    const unsubscribe = electron.ipcRenderer.on('drive:update', ({ id: uuid, filename, data }) => {
      const file = new File([data], filename);
      dispatch(attachmentActions.patchAttachment({ uuid, file }));
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch, isAuthenticated]);
};
