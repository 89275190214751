import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordSubmitted from './ForgotPasswordSubmitted';
import LandingDialog from 'components/LandingDialog';
import LogoIcon from 'assets/LogoIcon';
import Slide from '@mui/material/Slide';
import { authActions } from 'store/auth';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const logoSx = { fontSize: 64 };

function ForgotPassword() {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const res = await dispatch(authActions.resetPassword({ email }));
    if (res.error) {
      setError(res?.payload?.errors?.[0] || 'Something went wrong...');
    } else {
      setSubmitted(true);
    }
  };

  const theme = useTheme();

  return (
    <LandingDialog containerProps={{ ref: containerRef }}>
      <LogoIcon sx={logoSx} />
      <Slide
        direction='right'
        in={!submitted}
        unmountOnExit
        appear={false}
        container={containerRef.current}
      >
        <Box>
          <ForgotPasswordForm
            emailState={{ email, setEmail }}
            errorState={{ error, setError }}
            onSubmit={onSubmit}
          />
        </Box>
      </Slide>
      <Slide
        direction='left'
        in={submitted}
        style={{ transitionDelay: theme.transitions.duration.leavingScreen + 1 }}
        container={containerRef.current}
      >
        <Box>
          <ForgotPasswordSubmitted
            email={email}
            onSubmit={onSubmit}
          />
        </Box>
      </Slide>
    </LandingDialog>
  );
}

export default ForgotPassword;
