import React, { useState } from 'react';
import { formatType, getPath } from 'utils/entity.utils';

import { Dialog } from '@acheloisbiosoftware/absui.core';
import { ENTITY_DEPARTMENT } from 'constants/schemas';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import { getGenericId } from 'utils/generic.utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/entity';

function NewDepartmentDialog(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const submitDisabled = !name;

  const onSubmit = () => {
    if (submitDisabled) return;
    setLoading(true);
    dispatch(userActions.createDepartment({ name }))
      .unwrap()
      .then((res) => navigate(getPath(getGenericId(res.result, ENTITY_DEPARTMENT))))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      title={`Create New ${formatType(ENTITY_DEPARTMENT)}`}
      open={open}
      onClose={onClose}
      confirmButtonText='Submit'
      onConfirm={onSubmit}
      fullWidth
      maxWidth='xs'
      disableCloseOnConfirm
      loading={loading}
      confirmDisabled={submitDisabled}
    >
      <Field
        label={`${formatType(ENTITY_DEPARTMENT)} Name`}
        value={name}
        variant={Field.VARIANTS.TEXT_FIELD}
        textFieldProps={{
          onChange: (_, value) => setName(value),
        }}
        labelProps={{ required: true }}
        readOnly={false}
      />
    </Dialog>
  );
}

NewDepartmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default NewDepartmentDialog;
