import * as experimentActions from './experiment.actions';

import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { ENTITY_EXPERIMENT } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, experimentActions.fetchExperiments);
  reduceEntities(builder, experimentActions.createExperiment);
  reduceEntities(builder, experimentActions.fetchExperiment, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_EXPERIMENT)),
  });
  reduceEntities(builder, experimentActions.patchExperiment);
  reduceEntities(builder, experimentActions.deleteExperiment, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_EXPERIMENT)),
  });
  reduceEntities(builder, experimentActions.deleteExperimentPermanently, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_EXPERIMENT)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_EXPERIMENT)),
  });
  reduceEntities(builder, experimentActions.checkinExperiment);
  reduceEntities(builder, experimentActions.checkoutExperiment);
  reduceEntities(builder, experimentActions.cloneExperiment);
  reduceEntities(builder, experimentActions.completeExperiment);
  reduceEntities(builder, experimentActions.generateExperimentTemplate);
  reduceEntities(builder, experimentActions.instantiateExperiment);
  reduceEntities(builder, experimentActions.restoreExperiment);
};
