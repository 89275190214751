import { COL_TYPE_ACTIONS, COL_TYPE_SELECT } from './DataTable.constants';
import { useColumn, useGroupByState, useRow, useRowHover } from './DataTableContext';

import Box from '@mui/material/Box';
import CellBase from './CellBase';
import ContentActions from './ContentActions';
import ContentCheckbox from './ContentCheckbox';
import ContentDefault from './ContentDefault';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import React from 'react';

const TYPE_CONTENT_MAP = {
  [COL_TYPE_ACTIONS]: ContentActions,
  [COL_TYPE_SELECT]: ContentCheckbox,
};

function Cell(props) {
  const { rowId, columnId, ...restProps } = props;
  const row = useRow(rowId);
  const column = useColumn(columnId);
  const { type, showOnHover } = column;
  const { groupBy } = useGroupByState();
  const ContentComponent = TYPE_CONTENT_MAP[type] ?? ContentDefault;
  const rowHover = useRowHover();

  return (
    <CellBase
      row={row}
      columnId={columnId}
      {...restProps}
    >
      { columnId === groupBy ? null : (
        <Fade in={!showOnHover || rowHover} appear={false}>
          <Box>
            <ContentComponent rowId={rowId} columnId={columnId} />
          </Box>
        </Fade>
      )}
    </CellBase>
  );
}

Cell.propTypes = {
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  columnId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default Cell;
