import { Button, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function PasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <TextField
      {...props}
      type={showPassword ? props.type || null : 'password'}
      endAdornment={(
        <Button
          icon
          size='small'
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VisibilityIcon fontSize='inherit' /> : <VisibilityOffIcon fontSize='inherit' />}
        </Button>
      )}
    />
  );
}

PasswordInput.propTypes = {
  type: PropTypes.string,
  InputProps: PropTypes.object,
};

export default PasswordInput;
