import * as NoteService from 'services/NoteService';
import * as noteSelectors from './note.selectors';
import * as schemas from 'constants/schemas';

import { createServiceThunk } from 'store/store.actions.utils';

export const fetchNotes = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/fetchNotes`,
  service: NoteService.fetchNotes,
  schema: [schemas.note],
  isPaginated: true,
});

export const createNote = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/createNote`,
  service: NoteService.createNote,
  schema: schemas.note,
});

export const fetchNote = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/fetchNote`,
  service: NoteService.fetchNote,
  schema: schemas.note,
});

export const patchNote = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/patchNote`,
  service: NoteService.patchNote,
  schema: schemas.note,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: noteSelectors.selectNoteUpdates(getState(), id),
  }),
}, {
  condition: ({ id }, { getState }) => noteSelectors.selectNoteHasUpdates(getState(), id),
});

export const deleteNote = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/deleteNote`,
  service: NoteService.deleteNote,
  schema: schemas.note,
});

export const deleteNotePermanently = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/deleteNotePermanently`,
  service: NoteService.deleteNotePermanently,
  schema: schemas.note,
});

export const cloneNote = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/cloneNote`,
  service: NoteService.cloneNote,
  schema: schemas.note,
});

export const generateNoteTemplate = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/generateNoteTemplate`,
  service: NoteService.generateNoteTemplate,
  schema: schemas.note,
});

export const instantiateNote = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/instantiateNote`,
  service: NoteService.instantiateNote,
  schema: schemas.note,
});

export const restoreNote = createServiceThunk({
  name: `${schemas.ENTITY_NOTE}/restoreNote`,
  service: NoteService.restoreNote,
  schema: schemas.note,
});
