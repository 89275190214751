import * as CommentService from 'services/CommentService';
import * as schemas from 'constants/schemas';

import { createServiceThunk } from 'store/store.actions.utils';

export const createComment = createServiceThunk({
  name: `${schemas.ENTITY_COMMENT}/createComment`,
  service: CommentService.createComment,
  schema: schemas.comment,
});

export const patchComment = createServiceThunk({
  name: `${schemas.ENTITY_COMMENT}/patchComment`,
  service: CommentService.patchComment,
  schema: schemas.comment,
});

export const deleteComment = createServiceThunk({
  name: `${schemas.ENTITY_COMMENT}/deleteComment`,
  service: CommentService.deleteComment,
  schema: schemas.comment,
});
