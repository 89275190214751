import { EditorPlugins } from '@acheloisbiosoftware/absui.core';
import { timeNow } from 'utils/date.utils';
import { useMyId } from 'hooks/store.hooks';

const { List, Transforms } = EditorPlugins;

export const useMetadata = (editor) => {
  const { normalizeNode } = editor;
  const { checkItem, selectItem } = editor.List;
  const user = useMyId();

  editor.List = {
    ...editor.List,
    checkItem: (path, props, unselectedProps) => checkItem(
      path,
      { ...props, checked: { user, at: timeNow() }},
      { ...unselectedProps, checked: null },
    ),
    selectItem: (path, props, unselectedProps) => selectItem(
      path,
      { ...props, selected: { user, at: timeNow() }},
      { ...unselectedProps, selected: null },
    ),
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (List.isListItem(node)) {
      const [parent] = List.getParentList(editor, path);
      const options = { at: path, match: (n) => List.isListItem(n) };
      if ('checked' in node) {
        if (!List.isCheckboxList(parent)) {
          Transforms.unsetNodes(editor, 'checked', options);
          return;
        }
        if (!node.isChecked && node.checked !== null) {
          Transforms.setNodes(editor, { checked: null }, options);
          return;
        }
      }
      if ('selected' in node) {
        if (!List.isRadioList(parent)) {
          Transforms.unsetNodes(editor, 'selected', options);
          return;
        }
        if (!node.isSelected && node.selected !== null) {
          Transforms.setNodes(editor, { selected: null }, options);
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};
