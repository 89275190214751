import { ENTITY_ACTION_DELETE, EntityActionState, useEntityActions } from 'components/EntityActions';
import {
  LocationIcon,
  NoteIcon,
  TimeIcon,
  UserIcon,
} from 'constants/icon.constants';
import React, { useEffect } from 'react';
import { useNote, useUserName } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { DATE_FMT_SHORT } from 'constants/date.constants';
import DeletedAlert from 'components/DeletedAlert';
import { ENTITY_NOTE } from 'constants/schemas';
import Field from 'components/Field';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import SidePreviewActions from './SidePreviewActions';
import SidePreviewContent from './SidePreviewContent';
import SidePreviewHeader from './SidePreviewHeader';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { noteActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useOnClose } from './SidePreview';

function NotePreviewContent(props) {
  const { id } = props;
  const onClose = useOnClose();
  const dispatch = useDispatch();
  const note = useNote(id);
  const author = useUserName(note?.created_by);

  useEffect(() => {
    if (id) dispatch(noteActions.fetchNote({ id }));
  }, [dispatch, id]);

  const createRow = (title, value, icon) => ({ title, value, icon });
  const iconSx = { fontSize: 20, my: 'auto' };
  const tableProperties = [
    createRow('Author', author, <UserIcon sx={iconSx} />),
    createRow('Date Created', isDefined(note?.created) ? formatDate(note?.created, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    createRow('Last Modified', isDefined(note?.modified) ? formatDate(note?.modified, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    note?.is_deleted ? createRow('Date Deleted', formatDate(note?.deleted, DATE_FMT_SHORT), <TimeIcon sx={iconSx} />) : null,
    createRow('Location(s)', (<Locations directoryItem={note?.directory_item} />), <LocationIcon sx={iconSx} />),
  ];

  const actions = useEntityActions(id, ENTITY_NOTE, {
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess: onClose }},
  });

  return (
    <>
      <EntityActionState>
        <SidePreviewActions path={getPath(getGenericId(id, ENTITY_NOTE))} actions={actions} />
      </EntityActionState>

      <SidePreviewHeader
        icon={<NoteIcon colored template={note?.is_template} />}
        title={note?.title || 'Untitled'}
        subtitle={note?.note_code}
      />

      <SidePreviewContent>
        <DeletedAlert id={id} type={ENTITY_NOTE} sx={{ mb: 1 }} />

        <Table size='small'>
          <TableBody>
            {tableProperties.map((row) => (!isDefined(row) ? null : (
              <TableRow key={row.title}>
                <TableCell sx={{ minWidth: 137, width: 137 }}>
                  <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                    {row.icon}
                    <Typography variant='body2' sx={{ my: 'auto', ml: 1 }}>
                      {row.title ?? <Skeleton />}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ typography: 'body1' }}>
                  {row.value ?? <Skeleton />}
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        <Box sx={{ mt: 2, position: 'relative' }}>
          { note?.content ? (
            <Field
              value={note.content}
              variant={Field.VARIANTS.TEXT_EDITOR}
              readOnly
              interactable={false}
              withDays
              valueProps={{ key: `content${id}` }}
            />
          ) : null}
        </Box>
      </SidePreviewContent>
    </>
  );
}

NotePreviewContent.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default NotePreviewContent;
