import { AddIcon, CheckIcon, ClearIcon } from 'constants/icon.constants';
import { Autocomplete, Button } from '@acheloisbiosoftware/absui.core';
import React, { useCallback, useMemo, useState } from 'react';
import { useFetchListing, useMyGlobalPermission, useUser, useUserList } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ENTITY_USER } from 'constants/schemas';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { userActions } from 'store/entity';

const userProps = { id: 'id', full_name: 'full_name' };

const STATUS_REJECTED = 'rejected';
const STATUS_SIGNED = 'signed';
const STATUS_NEW = 'new';
const STATUS_PENDING = 'pending';

function SignatureAssignmentBase(props) {
  const { signee, status, onConfirm, onDelete } = props;

  const listParams = useMemo(() => ({
    prop: 'id',
    filter: (user) => user.is_active || user.id === signee,
  }), [signee]);
  const userIds = useUserList(listParams);
  const signeeObj = useUser(signee, userProps);
  const users = useUser(userIds, userProps);
  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);

  const editable = ![STATUS_REJECTED, STATUS_SIGNED].includes(status) && isDefined(onConfirm);
  const [editing, setEditing] = useState(status === STATUS_NEW);
  const [stagedSignee, setStagedSignee] = useState(signeeObj);

  const fetchOptions = useMemo(() => ({
    params: { filters: { is_active: true }},
    condition: editing && canViewUsers,
  }), [editing, canViewUsers]);
  useFetchListing(userActions.fetchUsers, fetchOptions);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const _onConfirm = useCallback(async () => {
    if (isDefined(stagedSignee?.id)) {
      if (stagedSignee.id !== signee) {
        setConfirmLoading(true);
        try {
          await onConfirm?.(stagedSignee.id);
        } catch {} finally {
          setConfirmLoading(false);
        }
      }
      setEditing(false);
    }
  }, [stagedSignee, signee, onConfirm]);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const _onDelete = useCallback(async (...args) => {
    setDeleteLoading(true);
    try {
      await onDelete?.(...args);
    } catch {} finally {
      setDeleteLoading(false);
    }
  }, [onDelete]);

  const onClickAway = () => {
    if (editing) {
      setEditing(false);
      if (status === STATUS_NEW) {
        onDelete?.();
      } else {
        setStagedSignee(signeeObj);
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box sx={{ display: 'flex', alignItems: 'center', height: 34, width: 1 }}>
        { status === STATUS_REJECTED ? (
          <WarningIcon color='error' />
        ) : status === STATUS_SIGNED ? (
          <CheckCircleIcon color='success' />
        ) : status === STATUS_NEW ? (
          <AddIcon sx={{ color: 'text.icon' }} />
        ) : (
          <HourglassTopIcon sx={{ color: 'text.icon' }} />
        )}
        { editing ? (
          <>
            <Autocomplete
              value={stagedSignee}
              onChange={(newValue) => setStagedSignee(newValue)}
              getOptionLabel={(option) => option.full_name ?? ''}
              options={users}
              disableCreateOption
              inputProps={(params) => ({ ...params, variant: 'standard' })}
              sx={{ ml: 1, width: 1 }}
            />
            <Button
              icon
              size='small'
              onClick={_onConfirm}
              loading={confirmLoading}
              sx={{ color: 'text.icon' }}
            >
              <CheckIcon />
            </Button>
            { isDefined(onDelete) ? (
              <Button
                icon
                size='small'
                onClick={_onDelete}
                loading={deleteLoading}
                sx={{ color: 'text.icon' }}
              >
                <ClearIcon />
              </Button>
            ) : null}
          </>
        ) : (
          <Typography
            sx={[
              { ml: 0.5, p: 0.5, width: 1, borderRadius: 1 },
              !editing && editable ? {
                '&:hover': { bgcolor: 'grey.light', cursor: 'text' },
              } : {},
            ]}
            onClick={!editing && editable ? () => setEditing(true) : null}
          >
            {stagedSignee?.full_name}
          </Typography>
        )}
      </Box>
    </ClickAwayListener>
  );
}

SignatureAssignmentBase.propTypes = {
  signee: PropTypes.number,
  status: PropTypes.oneOf([STATUS_REJECTED, STATUS_SIGNED, STATUS_NEW, STATUS_PENDING]),
  onConfirm: PropTypes.func,
  onDelete: PropTypes.func,
};

SignatureAssignmentBase.STATUS_REJECTED = STATUS_REJECTED;
SignatureAssignmentBase.STATUS_SIGNED = STATUS_SIGNED;
SignatureAssignmentBase.STATUS_NEW = STATUS_NEW;
SignatureAssignmentBase.STATUS_PENDING = STATUS_PENDING;

export default SignatureAssignmentBase;
