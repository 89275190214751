import React, { useState } from 'react';

import Collapse from '@mui/material/Collapse';
import CollapseButton from 'components/CollapseButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function NavList(props) {
  const { title, children, collapsable, defaultCollapsed, sx, titleProps, ...restProps } = props;
  const [open, setOpen] = useState(!defaultCollapsed);
  const showCollapse = children && collapsable;
  return (
    <List
      dense
      disablePadding
      {...restProps}
      sx={mergeSx({ pb: 2 }, sx)}
      subheader={(
        <ListSubheader
          component={Paper}
          elevation={2}
          square
          {...titleProps}
          sx={mergeSx({
            lineHeight: '1.5em',
            p: 0,
            pb: showCollapse ? 0 : 0.5,
            boxShadow: 0,
          }, titleProps?.sx)}
        >
          { title }
          { showCollapse ? (
            <CollapseButton
              open={open}
              setOpen={setOpen}
              buttonProps={{ sx: { p: 0, ml: 0.5 }}}
            />
          ) : null}
        </ListSubheader>
      )}
    >
      { showCollapse ? (
        <Collapse in={open} timeout='auto'>
          { children }
        </Collapse>
      ) : children }
    </List>
  );
}

NavList.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  collapsable: PropTypes.bool,
  defaultCollapsed: PropTypes.bool,
  sx: sxPropType,
  titleProps: PropTypes.object,
};

NavList.defaultProps = {
  collapsable: true,
};

export default NavList;
