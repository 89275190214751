import React, { useMemo, useState } from 'react';

import { CollapseAllContext } from './CollapseAllContext';
import MasterNoteContent from './MasterNoteContent';
import MasterNoteContentCollapsed from './MasterNoteContentCollapsed';
import MasterNoteItemDndContext from './MasterNoteItemDndContext';
import PropTypes from 'prop-types';

function MasterNoteBody(props) {
  const { id, loading, collapsed, selectedItem } = props;
  const [isAllCollapsed, setIsAllCollapsed] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(!isAllCollapsed);
  const collapseAllContextValue = useMemo(() => ({
    isAllCollapsed,
    isAllExpanded,
    collapseAll: () => {
      setIsAllCollapsed(true);
      setIsAllExpanded(false);
    },
    expandAll: () => {
      setIsAllCollapsed(false);
      setIsAllExpanded(true);
    },
    uncontrolCollapse: () => {
      setIsAllCollapsed(false);
      setIsAllExpanded(false);
    },
  }), [isAllCollapsed, isAllExpanded]);

  return (
    <MasterNoteItemDndContext id={id}>
      <CollapseAllContext.Provider value={collapseAllContextValue}>
        { collapsed ? (
          <MasterNoteContentCollapsed
            id={id}
            loading={loading}
            selectedItem={selectedItem}
          />
        ) : (
          <MasterNoteContent id={id} loading={loading} />
        )}
      </CollapseAllContext.Provider>
    </MasterNoteItemDndContext>
  );
}

MasterNoteBody.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
  collapsed: PropTypes.bool,
  selectedItem: PropTypes.number,
};

export default MasterNoteBody;
