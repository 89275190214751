import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE } from 'components/DataTable';
import { EntityActionState, selectEntityActions } from 'components/EntityActions';
import { formatType, getPath } from 'utils/entity.utils';
import { useDepartmentList, useFetchListing } from 'hooks/store.hooks';

import ActionAddDepartment from './ActionAddDepartment';
import { ENTITY_DEPARTMENT } from 'constants/schemas';
import React from 'react';
import { getGenericId } from 'utils/generic.utils';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/entity';

const columns = [
  {
    id: 'name',
    label: `${formatType(ENTITY_DEPARTMENT)} Name`,
    field: 'name',
    primary: true,
  },
  {
    id: 'created',
    label: 'Date Created',
    field: 'created',
    type: COL_TYPE_DATE,
    secondary: true,
  },
  {
    id: 'actions',
    type: COL_TYPE_ACTIONS,
    showOnHover: true,
    inMenu: true,
    actions: ({ row, state }) => selectEntityActions(state, row.id, ENTITY_DEPARTMENT),
  },
];

function Departments() {
  const allDepartments = useDepartmentList();
  const navigate = useNavigate();
  const loading = useFetchListing(userActions.fetchDepartments);

  return (
    <EntityActionState>
      <DataTable
        rows={allDepartments}
        columns={columns}
        actions={(<ActionAddDepartment />)}
        loading={loading}
        onRowClick={(row) => navigate(getPath(getGenericId(row.id, ENTITY_DEPARTMENT)))}
        initState={{
          orderBy: 'name',
        }}
      />
    </EntityActionState>
  );
}

export default Departments;
