import * as DirectoryService from 'services/DirectoryService';
import * as ExperimentService from 'services/ExperimentService';
import * as NoteService from 'services/NoteService';
import * as SopService from 'services/SopService';
import * as TaskService from 'services/TaskService';

import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import { ExportIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import { downloadFile } from '@acheloisbiosoftware/absui.utils';
import { notificationActions } from 'store/notification';
import { useDispatch } from 'react-redux';

const exportServiceMap = {
  [ENTITY_DIRECTORY]: DirectoryService.exportDirectory,
  [ENTITY_EXPERIMENT]: ExperimentService.exportExperiment,
  [ENTITY_NOTE]: NoteService.exportNote,
  [ENTITY_SOP]: SopService.exportSop,
  [ENTITY_TASK]: TaskService.exportTask,
};

function ActionExport(props) {
  const { id, type, ...restProps } = props;
  const dispatch = useDispatch();

  const onError = useCallback(() => {
    dispatch(notificationActions.enqueueSnackbar({
      key: `exportError${Date.now()}`,
      message: 'Failed to export document',
      variant: 'error',
    }));
  }, [dispatch]);

  const onDownload = useCallback(async () => {
    const res = await exportServiceMap[type]({ id });
    return res.error ? res : {
      name: res.file_name,
      file: `data:${res.content_type},${res.file}`,
    };
  }, [id, type]);

  const onAction = useCallback(async () => {
    await downloadFile(onDownload, onError);
  }, [onDownload, onError]);

  return (
    <BaseAction
      icon={<ExportIcon />}
      label='Export'
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionExport.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
};

export default ActionExport;
