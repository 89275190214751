import { electron, inBrowser } from 'utils/electron.utils';
import { navigationActions, navigationSelectors } from 'store/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { v4 as generateUuid } from 'uuid';
import { useLocation } from 'react-router-dom';

export const useCanGoBack = () => {
  const location = useLocation();

  // See: https://github.com/remix-run/react-router/discussions/9788
  return location.key !== 'default';
};

// See: https://stackoverflow.com/questions/73049396/how-can-i-determine-whether-the-app-can-navigate-forward-in-react-router-dom-v6
export const useCanGoForward = () => Boolean(!window.navigation || window.navigation.canGoForward || window.navigation.canGoForward !== false);

export const useNavBlocker = ({ when, message, onBlock, onReset }) => {
  const dispatch = useDispatch();
  const key = useMemo(() => generateUuid(), []);
  const blockerAdded = useRef(false);
  const amBlocking = useSelector((state) => navigationSelectors.selectKey(state) === key);
  const isBlocked = useSelector(navigationSelectors.selectIsBlocked);
  const prevBlocked = useRef(isBlocked);

  useEffect(() => {
    if (amBlocking && !prevBlocked.current && isBlocked) onBlock?.();
    else if (amBlocking && prevBlocked.current && !isBlocked) onReset?.();
  }, [amBlocking, isBlocked, onBlock, onReset]);

  useEffect(() => {
    if (!blockerAdded.current && when) {
      dispatch(navigationActions.addBlocker({ key, message }));
      blockerAdded.current = true;
    } else if (blockerAdded.current && !when) {
      dispatch(navigationActions.removeBlocker(key));
      blockerAdded.current = false;
    }
    return () => {
      if (blockerAdded.current) {
        dispatch(navigationActions.removeBlocker(key));
        blockerAdded.current = false;
      }
    };
  }, [dispatch, key, when, message]);

  useEffect(() => {
    prevBlocked.current = isBlocked;
  }, [isBlocked]);
};

export const useUnloadBlocker = (when) => {
  useEffect(() => {
    const blockBrowserUnload = (event) => {
      // Prompts the user before closing the page, see:
      // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
      event.preventDefault();
      event.returnValue = '';
    };

    const startBlocking = () => {
      if (!inBrowser) {
        // NOTE: this does NOT block the window from reloading in electron
        electron.ipcRenderer.send('nav:setBlocker', true);
      } else {
        // NOTE: this DOES block the window from reloading in browsers
        window.addEventListener('beforeunload', blockBrowserUnload);
      }
    };

    const stopBlocking = () => {
      if (!inBrowser) {
        electron.ipcRenderer.send('nav:setBlocker', false);
      } else {
        window.removeEventListener('beforeunload', blockBrowserUnload);
      }
    };

    if (when) startBlocking();
    if (!when) stopBlocking();
    return () => stopBlocking();
  }, [when]);
};
