import { ACTA_API_ERROR } from 'constants/event.constants';
import axios from 'axios';

const clientState = {
  axiosClient: null,
  queue: [],
  queueReleased: false,
};

export const initServices = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000,
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };
  clientState.axiosClient = axios.create(defaultOptions);
  clientState.axiosClient.interceptors.response.use(
    (res) => res.data,
    (error) => {
      document.dispatchEvent(new CustomEvent(ACTA_API_ERROR, { detail: error }));
      return { error };
    },
  );

  clientState.queue = [];
  clientState.queueReleased = false;
};

const client = (config, noTokenNeeded) => {
  if (clientState.queueReleased || noTokenNeeded) return clientState.axiosClient.request(config);
  return new Promise((resolve, reject) => {
    clientState.queue.push({ resolve, reject, config });
  });
};

export const releaseQueue = (token) => {
  clientState.axiosClient.defaults.headers.Authorization = `Bearer ${token}`;
  clientState.queueReleased = true;
  clientState.queue.forEach(({ resolve, reject, config }) => client(config).then(resolve).catch(reject));
  clientState.queue = [];
};

export const pauseQueue = () => {
  clientState.queueReleased = false;
};

export default client;
