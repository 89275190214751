import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER_ROLE } from 'constants/schemas';
import { useDepartment, useMyId, useTeam, useUser, useUserRole } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Field from 'components/Field';
import React from 'react';
import { formatDate } from 'utils/date.utils';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function ProfileDetails() {
  const myId = useMyId();
  const user = useUser(myId);
  const {
    username,
    date_joined,
    user_roles,
    teams,
    departments,
  } = user;

  const roles = useUserRole(user_roles);
  const teamObjs = useTeam(teams);
  const departmentObjs = useDepartment(departments);

  return (
    <Box sx={{ mt: 3 }}>
      <Field
        label='Username'
        value={username}
        loading={!isDefined(username)}
      />
      <Field
        label='Date Joined'
        value={formatDate(date_joined)}
        loading={!isDefined(date_joined)}
      />
      <Field
        label={formatType(ENTITY_USER_ROLE, { plural: true })}
        value={roles}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: roles,
        }}
        empty={roles?.length === 0}
        loading={!isDefined(roles)}
      />
      <Field
        label={formatType(ENTITY_TEAM, { plural: true })}
        value={teamObjs}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: teamObjs,
        }}
        empty={teamObjs?.length === 0}
        loading={!isDefined(teamObjs)}
      />
      <Field
        label={formatType(ENTITY_DEPARTMENT, { plural: true })}
        value={departmentObjs}
        variant={Field.VARIANTS.CHIPS}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          options: departmentObjs,
        }}
        empty={departmentObjs?.length === 0}
        loading={!isDefined(departmentObjs)}
      />
    </Box>
  );
}

export default ProfileDetails;
