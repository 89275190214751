import React, { useCallback } from 'react';
import { useMasterNoteItem, useMasterNoteSection, useUpdateEntity } from 'hooks/store.hooks';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BaseAction from './BaseAction';
import { ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import PropTypes from 'prop-types';

function MasterNoteItemActionMoveUp(props) {
  const { id, ...restProps } = props;
  const update = useUpdateEntity();
  const section = useMasterNoteItem(id, 'master_note_section');
  const sectionItems = useMasterNoteSection(section, 'items');
  const sequence = sectionItems.indexOf(id);

  const onAction = useCallback(() => {
    const newSectionItems = [...sectionItems];
    const newSequence = sequence - 1;
    newSectionItems.splice(sequence, 1);
    newSectionItems.splice(newSequence, 0, id);
    update({ type: ENTITY_MASTER_NOTE_SECTION, id: section, key: 'items', value: newSectionItems });
  }, [id, section, sectionItems, sequence, update]);

  return (
    <BaseAction
      icon={<ArrowUpwardIcon />}
      label='Move Up'
      onAction={onAction}
      disabled={sequence < 0 || sequence === 0}
      {...restProps}
    />
  );
}

MasterNoteItemActionMoveUp.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default MasterNoteItemActionMoveUp;
