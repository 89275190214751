import { extraReducers, reducers } from './entity.reducers';

import { ENTITY } from 'store/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './entity.initialState';

const slice = createSlice({
  name: ENTITY,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export * as entitySelectors from './entity.selectors';
export const {
  actions: entityActions,
  reducer: entityReducer,
} = slice;
