import React from 'react';
import { ReactComponent as SignatureValid } from './SignatureValid.svg';
import SvgIcon from '@mui/material/SvgIcon';

const SignatureValidIcon = React.forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    inheritViewBox
    component={SignatureValid}
  />
));

SignatureValidIcon.displayName = 'SignatureValidIcon';

export default SignatureValidIcon;
