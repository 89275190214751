import { BrowseDocumentsIcon, SopIcon } from 'constants/icon.constants';
import React, { useMemo, useState } from 'react';
import { useSop, useSopHasLoaded, useSopList } from 'hooks/store.hooks';

import Alert from '@mui/material/Alert';
import { Button } from '@acheloisbiosoftware/absui.core';
import Collapse from '@mui/material/Collapse';
import ContentButton from 'components/ContentButton';
import { ENTITY_SOP } from 'constants/schemas';
import Field from 'components/Field';
import Link from 'components/Link';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { SelectSop } from 'components/SopTable';
import { ShareNewDocumentField } from 'components/Shares';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function NewExperimentFormBlock(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    containerProps,
  } = props;
  const { title, related_sop, permission_relationships } = formData;
  const sopLongTitle = useSop(related_sop, 'long_title');
  const isEffective = useSop(related_sop, 'is_effective_version');

  const collectionId = useSop(related_sop, 'collection_id');
  const listParams = useMemo(() => ({
    filter: (sop) => sop?.collection_id === collectionId && sop?.is_effective_version && sop?.id !== related_sop,
    prop: 'id',
  }), [collectionId, related_sop]);
  const effectiveVersionSearch = useSopList(listParams);
  const canViewEffectiveVersion = effectiveVersionSearch.length > 0;
  const effectiveVersionId = effectiveVersionSearch[0];

  const sopHasLoaded = useSopHasLoaded(related_sop);
  const hasSop = isDefined(related_sop);
  const sopReadOnly = excludeFields.includes('related_sop');
  const [sopSelectOpen, setSopSelectOpen] = useState(false);
  const toggleSopSelectOpen = () => setSopSelectOpen((prev) => !prev);

  return (
    <Stack {...containerProps}>
      { excludeFields.includes('title') ? null : (
        <Field
          label='Title'
          variant={Field.VARIANTS.TEXT_FIELD}
          readOnly={false}
          value={title}
          {...fieldPropsMap?.title}
          labelProps={{ required: true, ...fieldPropsMap?.title?.labelProps }}
          textFieldProps={{
            onChange: (_, newTitle) => updateFormData('title', newTitle),
            ...fieldPropsMap?.title?.textFieldProps,
          }}
        />
      )}
      <Field
        label={`Linked ${formatType(ENTITY_SOP)}`}
        {...fieldPropsMap?.related_sop}
        labelProps={{ required: true, ...fieldPropsMap?.related_sop?.labelProps }}
      >
        { hasSop && !sopHasLoaded ? (
          <Skeleton variant='rounded' height={40} />
        ) : (
          <ContentButton
            icon={hasSop ? (<SopIcon colored />) : null}
            content={hasSop ? sopLongTitle : 'None'}
            onClick={toggleSopSelectOpen}
            disabled={sopReadOnly}
            onClear={hasSop && !sopReadOnly ? () => updateFormData('related_sop', null) : null}
            secondaryAction={!sopReadOnly ? (
              <Button
                icon
                size='small'
                color={sopSelectOpen ? 'primary' : 'inherit'}
                onClick={toggleSopSelectOpen}
              >
                <BrowseDocumentsIcon />
              </Button>
            ) : null}
            listProps={{ sx: { mx: -1 }}}
            listItemTextProps={hasSop ? {} : {
              sx: { fontStyle: 'italic', color: 'text.disabled' },
            }}
          />
        )}
        { hasSop && sopHasLoaded && !isEffective ? (
          <Alert severity='warning' sx={{ mt: 1 }}>
            This {formatType(ENTITY_SOP, { lowercase: true })} is outdated.
            { canViewEffectiveVersion ? (
              <>
                {' If you meant to use the latest effective version, click '}
                <Link
                  onClick={() => updateFormData('related_sop', effectiveVersionId)}
                  component='a'
                  sx={{ cursor: 'pointer' }}
                >
                  here
                </Link>
                .
              </>
            ) : (
              ' You may not have permission to view the latest effective version.'
            )}
          </Alert>
        ) : null}
        <Collapse in={sopSelectOpen} timeout='auto'>
          <Paper variant='outlined' sx={{ bgcolor: 'background.grey', px: 2, py: 1, my: 1 }}>
            <SelectSop
              value={related_sop}
              onChange={(newRelatedSop) => updateFormData('related_sop', newRelatedSop)}
            />
          </Paper>
        </Collapse>
      </Field>
      { excludeFields.includes('permission_relationships') ? null : (
        <ShareNewDocumentField
          shares={permission_relationships}
          setShares={(newShares) => updateFormData('permission_relationships', newShares)}
          {...fieldPropsMap?.permission_relationships}
        />
      )}
    </Stack>
  );
}

NewExperimentFormBlock.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewExperimentFormBlock;
