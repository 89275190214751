import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

function IconButtonAction(props) {
  const {
    label,
    icon,
    ...restProps
  } = props;
  return (
    <Box sx={{ my: 'auto', color: 'text.icon' }}> {/* NOTE: container Box keeps the icon button circular */}
      <Tooltip arrow title={label}>
        <Box component='span'>
          <Button icon {...restProps}>{icon}</Button>
        </Box>
      </Tooltip>
    </Box>
  );
}

export function IconButtonLinkAction(props) {
  return (<IconButtonAction {...props} component={Link} />);
}

IconButtonAction.propTypes = {
  label: PropTypes.node,
  icon: PropTypes.node,
};

export default IconButtonAction;
