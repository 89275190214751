import React, { useState } from 'react';
import { generateFilter, getFilterObject, isValidFilter } from '../DataTable.utils';
import { useColumns, useFilterState } from '../DataTableContext';

import ActionBase from '../ActionBase';
import { AddIcon } from 'constants/icon.constants';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterRow from './FilterRow';
import Popover from '@mui/material/Popover';

function ActionFilter() {
  const { filters, setFilters, applyFilters } = useFilterState();
  const columns = useColumns();
  const [anchorEl, setAnchorEl] = useState(null);
  const filterObjs = filters.map((filter) => getFilterObject(filter, columns));

  const onApply = () => {
    applyFilters();
    setAnchorEl(null);
  };

  const handleAddFilter = () => {
    setFilters((oldFilters) => [...oldFilters, generateFilter()]);
  };

  const handleClearFilters = () => {
    setFilters([generateFilter()]);
  };

  return (
    <ActionBase
      label='Filter'
      icon={<FilterAltIcon />}
      color={filterObjs.some(isValidFilter) ? 'primary' : 'inherit'}
      onClick={(event) => setAnchorEl(event.currentTarget)}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onApply}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { px: 2, py: 1 }}}
      >
        { filters.map((filter, idx) => (
          <FilterRow
            key={`filter_${idx}`} /* eslint-disable-line react/no-array-index-key */
            filter={filter}
            setFilters={setFilters}
          />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size='small'
            variant='text'
            color='inherit'
            startIcon={<AddIcon />}
            onClick={handleAddFilter}
            sx={{ mr: 1, color: 'text.tertiary' }}
          >
            Add Filter
          </Button>
          <Button
            size='small'
            variant='text'
            onClick={handleClearFilters}
            sx={{ mr: 1 }}
          >
            Clear
          </Button>
          <Button
            size='small'
            onClick={onApply}
            disabled={!filterObjs.every(isValidFilter)}
          >
            Apply
          </Button>
        </Box>
      </Popover>
    </ActionBase>
  );
}

export default ActionFilter;
