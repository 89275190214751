import {
  FILTER_TYPE_BOOLEAN,
  FILTER_TYPE_DATE,
  FILTER_TYPE_SELECT,
  FILTER_TYPE_TEXT,
} from './DataTable.constants';

import { formatDate } from 'utils/date.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import moment from 'moment';

export const getValue = (row, column) => {
  const { field, valueGetter } = column;
  const value = isDefined(field) ? row[field] : row;
  return isDefined(valueGetter) ? valueGetter({ row, value }) : value;
};

const getSortValue = (row, column) => {
  const { sortValueGetter } = column;
  const value = getValue(row, column);
  return isDefined(sortValueGetter) ? sortValueGetter({ row, value }) : value;
};

export const getGroupValue = (row, column) => {
  const { groupValueGetter } = column;
  const value = getValue(row, column);
  return isDefined(groupValueGetter) ? groupValueGetter({ row, value }) : value;
};

const getQuickFilterValue = (row, column) => {
  const { quickFilterValueGetter, filterValueGetter } = column;
  const value = getValue(row, column);
  if (isDefined(quickFilterValueGetter)) return quickFilterValueGetter({ row, value });
  if (isDefined(filterValueGetter)) return filterValueGetter({ row, value });
  return value;
};

export const getFilterValue = (row, column) => {
  const { filterValueGetter } = column;
  const value = getValue(row, column);
  return isDefined(filterValueGetter) ? filterValueGetter({ row, value }) : value;
};


export const getRenderValue = (row, column) => {
  const { renderValue } = column;
  const value = getValue(row, column);
  return isDefined(renderValue) ? renderValue({ row, value }) : value;
};

export const getRenderGroupValue = (row, column) => {
  const { renderGroupValue, renderValue } = column;
  const { groupValue } = row;
  if (isDefined(renderGroupValue)) return renderGroupValue({ row, value: groupValue });
  if (isDefined(renderValue)) return renderValue({ row, value: groupValue });
  return groupValue;
};

const defaultComparator = (a, b) => {
  if (typeof(a) === 'string' && typeof(b) === 'string') return b.localeCompare(a);
  if (b < a) return -1;
  if (b > a) return 1;
  return 0;
};

export const getComparator = (column, order) => {
  const { comparator } = column;
  const _getComparatorValue = ({ row: r1, value: r1Value }, { row: r2, value: r2Value }) => (
    isDefined(comparator) ? comparator({ row: r1, value: r1Value }, { row: r2, value: r2Value }) : defaultComparator(r1Value, r2Value)
  );
  const orderFactor = order === 'desc' ? 1 : -1;
  const _comparator = (r1Data, r2Data) => orderFactor * _getComparatorValue(r1Data, r2Data);

  return (r1, r2) => {
    const r1Value = getSortValue(r1, column);
    const r2Value = getSortValue(r2, column);
    const result = _comparator({ row: r1, value: r1Value }, { row: r2, value: r2Value });
    return result !== 0 ? result : defaultComparator(r1.id, r2.id);
  };
};

/** ************************************************************************* */
/** *********************** Boolean Filter Operators ************************ */
/** ************************************************************************* */
const opIsTrue = {
  id: 'is_true',
  label: 'is true',
  operator: (rowValue) => Boolean(rowValue),
};

const opIsFalse = {
  id: 'is_false',
  label: 'is false',
  operator: (rowValue) => !rowValue,
};

/** ************************************************************************* */
/** ************************* Date Filter Operators ************************* */
/** ************************************************************************* */
const opIsEqTime = {
  id: 'is_eq_time',
  label: 'is',
  operator: (rowValue, filterValue) => moment(filterValue).isSame(rowValue, 'day'),
};

const opIsNotEqTime = {
  id: 'is_not_eq_time',
  label: 'is not',
  operator: (rowValue, filterValue) => !moment(filterValue).isSame(rowValue, 'day'),
};

const opIsAfter = {
  id: 'is_after',
  label: 'is after',
  operator: (rowValue, filterValue) => moment(rowValue).isAfter(filterValue, 'day'),
};

const opIsOnOrAfter = {
  id: 'is_on_or_after',
  label: 'is on or after',
  operator: (rowValue, filterValue) => moment(rowValue).isSameOrAfter(filterValue, 'day'),
};

const opIsBefore = {
  id: 'is_before',
  label: 'is before',
  operator: (rowValue, filterValue) => moment(rowValue).isBefore(filterValue, 'day'),
};

const opIsOnOrBefore = {
  id: 'is_on_or_before',
  label: 'is on or before',
  operator: (rowValue, filterValue) => moment(rowValue).isSameOrBefore(filterValue, 'day'),
};

/** ************************************************************************* */
/** ************************ Select Filter Operators ************************ */
/** ************************************************************************* */
const opIs = {
  id: 'is',
  label: 'is',
  operator: (rowValue, filterValue) => rowValue === filterValue,
};

const opIsNot = {
  id: 'is_not',
  label: 'is not',
  operator: (rowValue, filterValue) => rowValue !== filterValue,
};

/** ************************************************************************* */
/** ************************ String Filter Operators ************************ */
/** ************************************************************************* */
const opContains = {
  id: 'contains',
  label: 'contains',
  operator: (rowValue, filterValue) => String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase()),
};

const opStartsWith = {
  id: 'starts_with',
  label: 'starts with',
  operator: (rowValue, filterValue) => String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()),
};

const opEndsWith = {
  id: 'ends_with',
  label: 'ends with',
  operator: (rowValue, filterValue) => String(rowValue).toLowerCase().endsWith(String(filterValue).toLowerCase()),
};

const opIsEq = {
  id: 'equals',
  label: 'equals',
  operator: (rowValue, filterValue) => String(rowValue).toLowerCase() === String(filterValue).toLowerCase(),
};

/** ************************************************************************* */
/** ***************************** Filter Utils ****************************** */
/** ************************************************************************* */
const FILTER_TYPE_OPERATOR_MAP = {
  [FILTER_TYPE_BOOLEAN]: [opIsTrue, opIsFalse],
  [FILTER_TYPE_DATE]: [opIsEqTime, opIsNotEqTime, opIsAfter, opIsOnOrAfter, opIsBefore, opIsOnOrBefore],
  [FILTER_TYPE_SELECT]: [opIs, opIsNot],
  [FILTER_TYPE_TEXT]: [opContains, opStartsWith, opEndsWith, opIsEq],
};

export const getFiltersForColumn = (column) => {
  const { filterType = FILTER_TYPE_TEXT } = column ?? {};
  return FILTER_TYPE_OPERATOR_MAP[filterType];
};

export const generateFilter = (initState) => ({
  column: null,
  operator: null,
  value: null,
  ...initState,
});

export const getFilterObject = (filter, columns) => {
  const column = columns.find((c) => c.id === filter.column) ?? null;
  const operator = getFiltersForColumn(column).find((o) => o.id === filter.operator) ?? null;
  return {
    column,
    operator,
    value: filter.value,
  };
};

export const isValidFilter = (filter) => (
  isDefined(filter.column) &&
  isDefined(filter.operator) &&
  (filter.value || filter.column.filterType === FILTER_TYPE_BOOLEAN)
);

export const testFilter = (filter, row) => {
  const { column, operator, value: filterValue } = filter;
  const rowValue = getFilterValue(row, column);
  return operator.operator(rowValue, filterValue);
};

export const testQuickFilter = (quickFilter, row, column) => {
  const value = getQuickFilterValue(row, column);
  return String(value).toLowerCase().includes(String(quickFilter).toLowerCase());
};

/** ************************************************************************* */
/** ************************* Group Date By Options ************************* */
/** ************************************************************************* */
const blankDateText = 'No Date';

const datePrecisionNatural = {
  id: 'natural',
  label: 'Natural',
  getGroupValue: (date) => {
    const monthAgo = moment().subtract(1, 'months');
    const yearAgo = moment().subtract(1, 'years');
    if (monthAgo.isSameOrBefore(date)) {
      return formatDate(date, 'MM/DD/YYYY', blankDateText);
    }
    if (yearAgo.isSameOrBefore(date)) {
      return formatDate(date, 'MMMM YYYY', blankDateText);
    }
    return formatDate(date, 'YYYY', blankDateText);
  },
};

const datePrecisionDay = {
  id: 'day',
  label: 'Day',
  getGroupValue: (date) => formatDate(date, 'MM/DD/YYYY', blankDateText),
};

const datePrecisionWeek = {
  id: 'week',
  label: 'Week',
  getGroupValue: (date) => formatDate(isDefined(date) ? moment(date).startOf('isoWeek') : date, '[Week] MM/DD/YYYY', blankDateText),
};

const datePrecisionMonth = {
  id: 'month',
  label: 'Month',
  getGroupValue: (date) => formatDate(date, 'MMMM YYYY', blankDateText),
};

const datePrecisionQuarter = {
  id: 'quarter',
  label: 'Quarter',
  getGroupValue: (date) => formatDate(date, '[Q]Q YYYY', blankDateText),
};

const datePrecisionYear = {
  id: 'year',
  label: 'Year',
  getGroupValue: (date) => formatDate(date, 'YYYY', blankDateText),
};

export const DEFAULT_GROUP_DATES_BY = datePrecisionNatural.id;
export const GROUP_DATES_BY_OPTIONS = [datePrecisionNatural, datePrecisionDay, datePrecisionWeek, datePrecisionMonth, datePrecisionQuarter, datePrecisionYear];
