import { ActionRestore, ButtonAction } from 'components/EntityActions';

import Alert from '@mui/material/Alert';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import { GenericProp } from 'store/entity';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { formatType } from 'utils/entity.utils';
import { useEntity } from 'hooks/store.hooks';
import { useGenericId } from 'utils/generic.utils';

const directoryTypeProp = new GenericProp({ [ENTITY_DIRECTORY]: 'type' }, { ignoreMissing: true });

function DeletedAlert(props) {
  const { id, type, ...restProps } = props;
  const genericId = useGenericId(id, type);
  const isDeleted = useEntity(genericId, 'is_deleted');
  const template = useEntity(genericId, 'is_template');
  const directoryType = useEntity(genericId, directoryTypeProp);

  return !isDeleted ? null : (
    <Alert
      severity='error'
      action={(
        <ActionRestore
          id={id}
          type={type}
          component={ButtonAction}
          variant='text'
          color='inherit'
          size='small'
        />
      )}
      {...restProps}
    >
      This {formatType(type, { lowercase: true, template, directoryType })} is in the <Link to='/trash' color='inherit'>trash</Link>.
    </Alert>
  );
}

DeletedAlert.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.string,
};

export default DeletedAlert;
