import { Button, ConditionalWrapper, EditorConstants, EditorHooks, EditorPlugins, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMyGlobalPermission, useUserHasLoaded, useUserName } from 'hooks/store.hooks';
import { useReadOnly, useSelected, useSlateStatic } from 'slate-react';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { ENTITY_USER } from 'constants/schemas';
import { EditIcon } from 'constants/icon.constants';
import InputDefinition from './InputDefinition';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from 'utils/date.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { usePromise } from '@acheloisbiosoftware/absui.hooks';
import { userActions } from 'store/entity';

const { BLOCK_LIST_CHECKBOX, BLOCK_LIST_RADIO } = EditorConstants;
const { useInteractable, useFocusedDisplay } = EditorHooks;
const { List, Table } = EditorPlugins;

function InputElement(props) {
  const { attributes, children, element, borrowFocus } = props;
  const { uuid, label, value, units, inputType, inputted } = element;
  const dispatch = useDispatch();
  const editorStatic = useSlateStatic();
  const selected = useSelected();
  const [fakeSelected, setFakeSelected] = useState(false);
  const readOnly = useReadOnly();
  const interactable = useInteractable();
  const focusedDisplay = useFocusedDisplay();
  const inputDisabled = !(readOnly && interactable);

  const fetchedUser = useRef(false);
  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const inputtedUserHasLoaded = useUserHasLoaded(inputted?.user);
  const inputtedUser = useUserName(inputted?.user);
  const inputDetails = `${formatDate(inputted?.at)} by ${inputtedUser ?? 'unknown user'}`;

  useEffect(() => {
    if (!fetchedUser.current && canViewUsers && isDefined(inputted?.user) && !inputtedUserHasLoaded) {
      dispatch(userActions.fetchUser({ id: inputted?.user }));
      fetchedUser.current = true;
    }
  }, [dispatch, canViewUsers, inputted?.user, inputtedUserHasLoaded]);

  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(inputRef?.current);
  const [open, setOpen] = useState(false);
  const { promiseRef, defer } = usePromise();
  const [formLabel, setFormLabel] = useState(label);
  const [formInputType, setFormInputType] = useState(inputType);
  const [formUnits, setFormUnits] = useState(units);

  useEffect(() => {
    /*
     * When open gets toggled, the TextField must rerender since the
     * ConditionalWrapper/Tooltip rerender, so it gets a new ref. Hence, we must
     * wait for the TextField to mount, then update the anchorEl for the dialog.
     */
    if (open) {
      setAnchorEl(inputRef.current);
    } else {
      setAnchorEl(null);
    }
  }, [open]);

  const onClose = useCallback((exited) => {
    setOpen(false);
    promiseRef.current.resolve(() => {
      if (!exited) {
        editorStatic.Variable.setInput(uuid, {
          label: formLabel,
          inputType: formInputType,
          units: formUnits,
        });
      }
      setFakeSelected(false);
    });
    if (exited) {
      setFormLabel(label);
      setFormInputType(inputType);
      setFormUnits(units);
    }
  }, [editorStatic, formLabel, formInputType, formUnits, label, inputType, units, uuid, promiseRef]);

  const onOpen = useCallback((event) => {
    event.preventDefault();
    if (inputDisabled) {
      setFakeSelected(true);
      borrowFocus(() => defer());
      setOpen(true);
    }
  }, [inputDisabled, borrowFocus, defer]);

  const onCopy = useCallback((event) => {
    event.stopPropagation();
  }, []);

  /* Alignment in lists looks bad, so correct alignment if in a list */
  const parentListType = List.hooks.useListType();
  const verticalAlign = parentListType && (parentListType === BLOCK_LIST_CHECKBOX || parentListType === BLOCK_LIST_RADIO) ? 'top' : 'baseline';

  /* No need for showing selection when table cells being selected */
  const tableSelection = Table.hooks.useTableSelection();

  const showSelectionHighlight = (
    (selected || fakeSelected) &&
    focusedDisplay &&
    inputDisabled &&
    !tableSelection?.bounds
  );
  const showEditButton = showSelectionHighlight && !open;

  return (
    <Box {...attributes} component='span'>
      <ConditionalWrapper
        wrapperComponent={Tooltip}
        wrapperProps={{
          title: value && inputted?.at ? inputDetails : null,
          arrow: true,
          enterDelay: 500,
          enterNextDelay: 500,
        }}
        condition={!open}
      >
        <TextField
          ref={inputRef}
          label={label}
          value={value}
          onChange={(_, newValue) => editorStatic.Variable.onInputChange(uuid, newValue)}
          onCopy={onCopy}
          InputLabelProps={{ shrink: true }}
          endAdornment={(
            <>
              {showEditButton ? (
                <Button onMouseDown={onOpen} size='small' icon>
                  <EditIcon fontSize='small' />
                </Button>
              ) : null}
              {units}
            </>
          )}
          type={inputType}
          debounceChange={0} /* This allows "e" to be entered for numbers */
          size='small'
          sx={{
            verticalAlign,
            mx: 1,
            my: 0.5,
            userSelect: 'none',
            width: 200,
          }}
          InputSx={inputDisabled ? { bgcolor: showSelectionHighlight ? 'Highlight' : 'action.disabledBackground' } : {}}
          inputProps={{ disabled: inputDisabled }}
        />
      </ConditionalWrapper>
      <InputDefinition
        anchorEl={anchorEl}
        open={Boolean(anchorEl && open)}
        onClose={onClose}
        formState={{
          label: formLabel,
          setLabel: setFormLabel,
          inputType: formInputType,
          setInputType: setFormInputType,
          units: formUnits,
          setUnits: setFormUnits,
        }}
      />
      {children}
    </Box>
  );
}

InputElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    units: PropTypes.string,
    inputType: PropTypes.string,
    inputted: PropTypes.shape({
      user: PropTypes.number,
      at: PropTypes.string,
    }),
  }),
  borrowFocus: PropTypes.func.isRequired,
};

export default InputElement;
