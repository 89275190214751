import React, { useState } from 'react';

import Badge from '@mui/material/Badge';
import { Button } from '@acheloisbiosoftware/absui.core';
import CircularProgress from '@mui/material/CircularProgress';
import Link from 'components/Link';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import { NotificationsIcon } from 'constants/icon.constants';
import Tooltip from '@mui/material/Tooltip';
import { formatDate } from 'utils/date.utils';
import { getIcon } from 'utils/entity.utils';
import useMyNotifications from 'hooks/useMyNotifications';

function NotificationButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const closeMenu = () => setAnchorEl(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { notifications, loading } = useMyNotifications(open);

  return (
    <>
      <Tooltip arrow placement='right' title='View Notifications'>
        <Button
          icon
          onClick={openMenu}
          sx={{ color: 'text.icon' }}
        >
          <Badge
            badgeContent={notifications.length}
            color='primary'
            overlap='circular'
          >
            <NotificationsIcon />
          </Badge>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ maxHeight: 600 }}
      >
        { notifications.length === 0 && !loading ? (
          <MenuItem disabled label='No Notifications' />
        ) : (
          notifications.map((notification) => {
            const Icon = getIcon(notification.type);
            return (
              <MenuItem
                key={`${notification.type}-${notification.id}`}
                icon={Icon ? <Icon colored /> : null}
                label={notification.message}
                component={Link}
                to={notification.path}
                onClick={closeMenu}
                listItemTextProps={{ secondary: formatDate(notification.assigned) }}
                sx={{ whiteSpace: 'normal', maxWidth: 480 }}
              />
            );
          })
        )}
        { loading ? (
          <MenuItem disabled>
            <CircularProgress size={24} sx={{ m: 'auto' }} />
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}

export default NotificationButton;
