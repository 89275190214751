import * as extraActions from './navigation.extraActions';

import { SPACE_OPUS_U } from 'constants/space.constants';
import { getInitialState } from './navigation.initialState';
import { reduceLogout } from 'store/store.reducers.utils';
import { timeNow } from 'utils/date.utils';

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const addBlocker = (state, action) => {
  state.blockers.push(action.payload);
};

const removeBlocker = (state, action) => {
  const key = action.payload;
  state.blockers = state.blockers.filter((blocker) => blocker.key !== key);
};

const setIsBlocked = (state, action) => {
  state.isBlocked = action.payload;
};

const setSpace = (state, action) => {
  const { spaceType, spaceId } = action.payload;
  state.spaceType = spaceType;
  if (spaceType === SPACE_OPUS_U) {
    state.spaceId = null;
  } else {
    state.spaceId = spaceId;
  }
};

const addRecentlyViewed = (state, action) => {
  const { id, type } = action.payload;
  const newRecentlyViewed = state.recentlyViewed.filter((recent) => !(recent.id === id && recent.type === type));
  newRecentlyViewed.unshift({ id, type, at: timeNow() });
  state.recentlyViewed = newRecentlyViewed.slice(0, 64);
};

export const reducers = {
  addBlocker,
  removeBlocker,
  setIsBlocked,
  addRecentlyViewed,
};
export const extraReducers = (builder) => {
  builder.addCase(extraActions.setSpaceAction, setSpace);
  reduceLogout(builder, getInitialState);
};
