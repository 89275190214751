import client from 'services';

// #############################################################################
// ################################ Departments ################################
// #############################################################################
export function fetchDepartments(params, config) {
  return client({ method: 'get', url: '/api/departments/', params, ...config });
}

export function createDepartment(data, config) {
  return client({ method: 'post', url: '/api/departments/', data, ...config });
}

export function fetchDepartment({ id }, config) {
  return client({ method: 'get', url: `/api/departments/${id}/`, ...config });
}

export function patchDepartment({ id, data }, config) {
  return client({ method: 'patch', url: `/api/departments/${id}/`, data, ...config });
}

export function deleteDepartment({ id }, config) {
  return client({ method: 'delete', url: `/api/departments/${id}/`, ...config });
}

// #############################################################################
// ################################### Teams ###################################
// #############################################################################
export function fetchTeams(params, config) {
  return client({ method: 'get', url: '/api/teams/', params, ...config });
}

export function createTeam(data, config) {
  return client({ method: 'post', url: '/api/teams/', data, ...config });
}

export function fetchTeam({ id }, config) {
  return client({ method: 'get', url: `/api/teams/${id}/`, ...config });
}

export function patchTeam({ id, data }, config) {
  return client({ method: 'patch', url: `/api/teams/${id}/`, data, ...config });
}

export function deleteTeam({ id }, config) {
  return client({ method: 'delete', url: `/api/teams/${id}/`, ...config });
}

// #############################################################################
// ################################ User Roles #################################
// #############################################################################
export function fetchUserRoles(params, config) {
  return client({ method: 'get', url: '/api/user-roles/', params, ...config });
}

export function createUserRole(data, config) {
  return client({ method: 'post', url: '/api/user-roles/', data, ...config });
}

export function fetchUserRole({ id }, config) {
  return client({ method: 'get', url: `/api/user-roles/${id}/`, ...config });
}

export function patchUserRole({ id, data }, config) {
  return client({ method: 'patch', url: `/api/user-roles/${id}/`, data, ...config });
}

export function deleteUserRole({ id }, config) {
  return client({ method: 'delete', url: `/api/user-roles/${id}/`, ...config });
}

// #############################################################################
// ################################### Users ###################################
// #############################################################################
export function fetchUsers(params, config) {
  return client({ method: 'get', url: '/api/users/', params, ...config });
}

export function createUser(data, config) {
  return client({ method: 'post', url: '/api/users/', data, ...config });
}

export function fetchUser({ id }, config) {
  return client({ method: 'get', url: `/api/users/${id}/`, ...config });
}

export function patchUser({ id, data }, config) {
  return client({ method: 'patch', url: `/api/users/${id}/`, data, ...config });
}

export function patchUserPreferences({ id, data }, config) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }
  return client({ method: 'patch', url: `/api/users/${id}/preferences/`, data: formData, ...config });
}
