import React, { useCallback, useState } from 'react';
import { useSignatureWorkflowPermission, useSignatureWorkflowStep } from 'hooks/store.hooks';

import { ACTION_UPDATE } from 'constants/permission.constants';
import { AddIcon } from 'constants/icon.constants';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import { EmptyField } from 'components/Field';
import PropTypes from 'prop-types';
import SignatureAssignmentBase from './SignatureAssignmentBase';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { signatureActions } from 'store/entity';
import { useDispatch } from 'react-redux';

function NewSignatureAssignment(props) {
  const { signatureWorkflowStepId } = props;
  const signatureWorkflowId = useSignatureWorkflowStep(signatureWorkflowStepId, 'signature_workflow');
  const canUpdate = useSignatureWorkflowPermission(signatureWorkflowId, ACTION_UPDATE);
  const maxSignatures = useSignatureWorkflowStep(signatureWorkflowStepId, ['rules', 'max_signatures']);
  const signatureIds = useSignatureWorkflowStep(signatureWorkflowStepId, 'signatures');
  const signatureCount = signatureIds.length;
  const isClosed = useSignatureWorkflowStep(signatureWorkflowStepId, 'is_closed');
  const [addSignee, setAddSignee] = useState(false);
  const dispatch = useDispatch();

  const onConfirm = useCallback(async (signee) => {
    await dispatch(signatureActions.createSignature({
      signee,
      signature_workflow_step: signatureWorkflowStepId,
    })).unwrap();
    setAddSignee(false);
  }, [dispatch, signatureWorkflowStepId]);

  const onDelete = () => {
    setAddSignee(false);
  };

  if (!canUpdate) {
    return signatureCount === 0 ? (<EmptyField />) : null;
  }

  if (isClosed || (isDefined(maxSignatures) && signatureCount >= maxSignatures)) {
    return null;
  }

  return addSignee ? (
    <SignatureAssignmentBase
      status={SignatureAssignmentBase.STATUS_NEW}
      onConfirm={onConfirm}
      onDelete={onDelete}
    />
  ) : (
    <Box sx={{ color: 'text.tertiary', mt: 0.5 }}>
      <Button
        startIcon={<AddIcon />}
        variant='text'
        color='inherit'
        size='small'
        onClick={() => setAddSignee(true)}
      >
        Add Signee
      </Button>
    </Box>
  );
}

NewSignatureAssignment.propTypes = {
  signatureWorkflowStepId: PropTypes.number.isRequired,
};

export default NewSignatureAssignment;
