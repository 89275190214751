import React, { useState } from 'react';
import { formatType, getPath } from 'utils/entity.utils';

import { Dialog } from '@acheloisbiosoftware/absui.core';
import { ENTITY_USER_ROLE } from 'constants/schemas';
import Field from 'components/Field';
import PermissionMatrix from 'components/PermissionMatrix';
import PropTypes from 'prop-types';
import { getGenericId } from 'utils/generic.utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/entity';

const initFormData = {
  name: '',
  permissionObjs: [],
};

function NewUserRoleDialog(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initFormData);
  const [loading, setLoading] = useState(false);
  const { name, permissionObjs } = formData;
  const submitDisabled = !name || permissionObjs.length === 0;

  const onSubmit = () => {
    if (submitDisabled) return;
    setLoading(true);
    dispatch(userActions.createUserRole({
      name,
      permissions_granted: permissionObjs.map((p) => p.id),
    }))
      .unwrap()
      .then((res) => navigate(getPath(getGenericId(res.result, ENTITY_USER_ROLE))))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      title={`Create New ${formatType(ENTITY_USER_ROLE)}`}
      open={open}
      onClose={onClose}
      confirmButtonText='Submit'
      onConfirm={onSubmit}
      disableCloseOnConfirm
      maxWidth='md'
      loading={loading}
      confirmDisabled={submitDisabled}
    >
      <Field
        label={`${formatType(ENTITY_USER_ROLE)} Name`}
        value={name}
        variant={Field.VARIANTS.TEXT_FIELD}
        textFieldProps={{
          onChange: (_, value) => setFormData({ ...formData, name: value }),
        }}
        labelProps={{ required: true }}
        readOnly={false}
      />
      <PermissionMatrix
        value={permissionObjs}
        onChange={(newPermissions) => setFormData({ ...formData, permissionObjs: newPermissions })}
        sx={{ mt: 2 }}
      />
    </Dialog>
  );
}

NewUserRoleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default NewUserRoleDialog;
