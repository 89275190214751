import { ENTITY_SIGNATURE, ENTITY_USER } from 'constants/schemas';
import React, { useEffect } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useSignature, useSignaturePermission, useUserName } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import CustomEditor from 'components/CustomEditor';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import PropTypes from 'prop-types';
import SignatureValidIcon from 'assets/SignatureValidIcon';
import SignatureWarningIcon from 'assets/SignatureWarningIcon';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { formatType } from 'utils/entity.utils';
import { signatureActions } from 'store/entity';
import { useDispatch } from 'react-redux';

function Signature(props) {
  const { id, containerProps, avatarProps } = props;
  const { size: avatarSize = 24 } = avatarProps ?? {};
  const dispatch = useDispatch();
  const signature = useSignature(id);
  const canView = useSignaturePermission(id, ACTION_VIEW);
  const {
    signee,
    signed_at,
    rejected_at,
    content,
    signature_hash,
    is_rejected,
    is_verified,
    verified_by,
  } = signature;
  const signeeName = useUserName(signee);
  const SignatureIcon = is_verified ? SignatureValidIcon : SignatureWarningIcon;
  const signatureMessage = is_verified ? `${formatType(ENTITY_SIGNATURE)} verified by ${verified_by}` : `${formatType(ENTITY_SIGNATURE)} could not be verified by ${verified_by}`;

  useEffect(() => {
    dispatch(signatureActions.verifySignature({ id }));
  }, [dispatch, id]);

  return canView ? (
    <Box
      {...containerProps}
      sx={mergeSx({
        bgcolor: is_rejected ? 'background.error' : 'background.success',
        borderRadius: 2,
        p: 2,
        my: 1,
      }, containerProps?.sx)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          id={signee}
          type={ENTITY_USER}
          {...avatarProps}
        />
        <Typography sx={{ ml: 1 }}>
          <Box component='span' sx={{ color: 'text.secondary' }}>
            { signeeName ?? <Skeleton width={120} sx={{ display: 'inline-block' }} />}
          </Box>
          <Box
            component='span'
            sx={{ ml: 2, typography: 'caption', color: 'text.tertiary' }}
          >
            {is_rejected ? formatDate(rejected_at) : formatDate(signed_at)}
          </Box>
        </Typography>
      </Box>
      <CustomEditor
        initialValue={content}
        readOnly
        interactable={false}
        hideToolbarOnBlur
        sx={{ ml: `${avatarSize}px`, mt: -1 }}
        emptyFieldProps={{ sx: { display: 'none' }}}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 1,
        }}
      >
        { is_rejected ? (
          <>
            <DoNotDisturbIcon color='error' sx={{ mr: 1 }} />
            <Typography variant='button'>{formatType(ENTITY_SIGNATURE)} Rejected</Typography>
          </>
        ) : (
          <>
            { !isDefined(is_verified) ? (
              <Skeleton variant='rounded' width={30} height={30} sx={{ mr: 1 }} />
            ) : (
              <Tooltip arrow title={signatureMessage}>
                <SignatureIcon sx={{ fontSize: 30, color: 'text.primary', mr: 1 }} />
              </Tooltip>
            ) }
            <Typography variant='button' noWrap>{formatType(ENTITY_SIGNATURE)} ID: {signature_hash}</Typography>
          </>
        )}
      </Box>
    </Box>
  ) : (
    <Tooltip title={`You do not have permission to view this ${formatType(ENTITY_SIGNATURE, { lowercase: true })}`} arrow>
      <Box
        {...containerProps}
        sx={mergeSx({
          p: 1,
          my: 1,
          height: 40,
          bgcolor: 'background.grey',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }, containerProps?.sx)}
      >
        <Typography color='text.secondary'>[Content Hidden]</Typography>
      </Box>
    </Tooltip>
  );
}

Signature.propTypes = {
  id: PropTypes.number.isRequired,
  containerProps: PropTypes.object,
  avatarProps: PropTypes.object,
};

export default Signature;
