import { AUTH, getLocalStoreSliceId } from 'store/store.constants';

import { localStore } from 'utils/localStorage.utils';

export const getInitialState = () => ({
  isAuthenticated: false,
  token: null,
  myId: null,
  ...localStore.load(getLocalStoreSliceId(AUTH)) ?? {},
  error: null,
});
