import React, { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import PasswordInput from 'components/PasswordInput';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { authActions } from 'store/auth';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

function ResetPasswordForm({ setSubmitted }) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [invalidTokenError, setInvalidTokenError] = useState('');

  useEffect(() => {
    const validateToken = async () => {
      const res = await dispatch(authActions.validatePasswordResetToken({ token }));
      if (res.error) {
        setInvalidTokenError(res?.payload?.errors?.[0] || 'Invalid password reset token.');
      }
    };
    validateToken();
  }, [token, dispatch]);


  const onSubmit = async (event) => {
    await event.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    const res = await dispatch(authActions.resetPasswordConfirm({ password, token }));
    if (res.error) {
      setError(res?.payload?.errors?.[0] || 'Something went wrong...');
    } else {
      setSubmitted(true);
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === 'password') setPassword(value);
    if (id === 'confirmPassword') setConfirmPassword(value);
    setError('');
  };

  return (
    <Box component='form' onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Typography variant='h4'>Reset Password</Typography>
        <Typography>Please choose your new password.</Typography>
        <PasswordInput
          label='New Password'
          id='password'
          value={password}
          onChange={handleChange}
          error={Boolean(error)}
          disabled={Boolean(invalidTokenError)}
          data-test='new-password'
        />
        <PasswordInput
          label='Confirm Password'
          id='confirmPassword'
          value={confirmPassword}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
          disabled={Boolean(invalidTokenError)}
          data-test='confirm-password'
        />
        <Button
          variant='contained'
          color='primary'
          onClick={onSubmit}
          type='submit'
          disabled={Boolean(invalidTokenError)}
          data-test='reset-password'
        >
          Reset
        </Button>
        {
          invalidTokenError ? (
            <Alert
              severity='error'
              data-test='invalid-token-error'
            >
              {invalidTokenError}
            </Alert>
          ) : null
        }
      </Stack>
    </Box>
  );
}

ResetPasswordForm.propTypes = {
  setSubmitted: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
