import { ACTION_DELETE, ACTION_VIEW } from 'constants/permission.constants';
import {
  ConstantProp,
  GenericProp,
  Prop,
  SelectorProp,
  directorySelectors,
  entitySelectors,
} from 'store/entity';
import DataTable, { COL_TYPE_DATE, COL_TYPE_SELECT } from 'components/DataTable';
import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import React, { useCallback, useMemo } from 'react';
import { genericIdFromString, genericIdToString, getGenericId } from 'utils/generic.utils';

import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material';
import { formatType } from 'utils/entity.utils';
import { isNil } from 'lodash';
import { useEntity } from 'hooks/store.hooks';

const createIconSelectorProp = (type) => new SelectorProp((state, entity) => entitySelectors.selectEntityIcon(state, getGenericId(entity?.id, type)));
const genericIdProp = new Prop({ id: 'object_id', type: 'content_type' });
const existsElsewhereProp = new SelectorProp((state, entity) => {
  const locations = directorySelectors.selectDirectoryItem(state, entity?.directory_item, 'locations');
  return isNil(locations) || locations.length > 1;
});
const EMPTY_ARRAY = [];
const commonFragment = {
  objectId: 'id',
  isTemplate: 'is_template',
  created: 'created',
  canView: ['permissions', ACTION_VIEW],
  canDelete: ['permissions', ACTION_DELETE],
  existsElsewhere: existsElsewhereProp,
};
const itemObjectProp = new GenericProp({
  [ENTITY_DIRECTORY]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_DIRECTORY),
    Icon: createIconSelectorProp(ENTITY_DIRECTORY),
    name: 'name',
    children: new SelectorProp((state, directory) => {
      const directoryItems = directorySelectors.selectDirectoryChildren(state, directory?.id) ?? EMPTY_ARRAY;
      return directorySelectors.selectDirectoryItem(state, directoryItems, genericIdProp);
    }),
  },
  [ENTITY_EXPERIMENT]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_EXPERIMENT),
    Icon: createIconSelectorProp(ENTITY_EXPERIMENT),
    name: 'long_title',
  },
  [ENTITY_NOTE]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_NOTE),
    Icon: createIconSelectorProp(ENTITY_NOTE),
    name: 'long_title',
  },
  [ENTITY_SOP]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_SOP),
    Icon: createIconSelectorProp(ENTITY_SOP),
    name: 'long_title',
  },
  [ENTITY_TASK]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_TASK),
    Icon: createIconSelectorProp(ENTITY_TASK),
    name: 'long_title',
  },
});
const rowProps = { sx: {
  '&.Mui-selected': { bgcolor: (theme) => alpha(theme.palette.error.main, theme.opacity.light) },
  '&.Mui-selected:hover': { bgcolor: (theme) => alpha(theme.palette.error.main, theme.opacity.light * 1.5) },
}};
const canDeleteRow = (row) => row.canDelete && !row.existsElsewhere;

function ChildrenDocumentTable(props) {
  const { ids, selection, setSelection, ...restProps } = props;
  const childrenItemObjects = useEntity(ids, itemObjectProp);

  const { rows } = useMemo(
    () => (childrenItemObjects ?? []).reduce((result, row) => {
      if (!row?.canView) return result;
      result.rows.push({
        ...row,
        id: genericIdToString(getGenericId(row.objectId, row.type)),
        rowProps,
        expandedChildren: (row.children ?? []).length > 0 ? (
          <ChildrenDocumentTable
            ids={row.children}
            selection={selection}
            setSelection={setSelection}
            sx={{ pl: 3, boxSizing: 'border-box' }}
            noHeader
          />
        ) : null,
      });
      return result;
    }, { rows: []}),
    [childrenItemObjects, selection, setSelection],
  );

  const onSelect = useCallback((rowIds) => {
    const genericIds = rowIds.map((rowId) => genericIdFromString(rowId));
    const outsideSelection = selection.filter((genericId) => rows.every((row) => genericIdToString(genericId) !== row.id));
    setSelection([...outsideSelection, ...genericIds]);
  }, [rows, selection, setSelection]);

  const tableSelection = useMemo(
    () => selection.filter((genericId) => rows.some((row) => genericIdToString(genericId) === row.id)),
    [selection, rows],
  );
  const selectedRowIds = useMemo(
    () => tableSelection.map((genericId) => genericIdToString(genericId)),
    [tableSelection],
  );

  const disabledRowIds = useMemo(
    () => rows.filter((row) => !canDeleteRow(row)).map((row) => row.id),
    [rows],
  );

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      selection: selectedRowIds,
      onSelect,
      disabled: disabledRowIds,
      disabledMessage: (row) => (row.canDelete && row.existsElsewhere ? `This item exists in other ${formatType(ENTITY_DIRECTORY, { lowercase: true })}` : 'You do not have permission to delete this item'),
      checkboxProps: {
        checkedIcon: (<DeleteIcon />),
        icon: (<DeleteOutlineIcon />),
        color: 'error',
      },
    },
    {
      id: 'type',
      label: 'Type',
      field: 'type',
      renderValue: ({ row }) => {
        const { Icon, isTemplate } = row;
        return (
          <Box
            sx={[
              { display: 'flex', alignItems: 'center' },
              canDeleteRow(row) ? {} : { opacity: (theme) => theme.opacity.disabled },
            ]}
          >
            { Icon ? <Icon colored template={isTemplate} /> : null }
          </Box>
        );
      },
      cellProps: { sx: { width: 20, pr: 0 }},
      noHeader: true,
    },
    {
      id: 'name',
      label: 'Items to delete',
      field: 'name',
      renderValue: ({ value }) => value || 'Untitled',
      cellProps: ({ row }) => ({ sx: {
        maxWidth: 360,
        ...(selectedRowIds.includes(row.id) ? { textDecoration: 'line-through' } : {}),
        ...(canDeleteRow(row) ? {} : { color: 'text.disabled' }),
      }}),
      primary: true,
      sortable: false,
      noWrap: true,
    },
    {
      id: 'created',
      label: 'Created',
      field: 'created',
      type: COL_TYPE_DATE,
      hidden: true,
    },
  ], [selectedRowIds, onSelect, disabledRowIds]);

  return (
    <DataTable
      rows={rows}
      columns={columns}
      expandedRows={selectedRowIds}
      disableFilter
      disableGroupBy
      noHeader
      {...restProps}
      initState={{
        orderBy: 'created',
        order: 'desc',
        ...restProps?.initState,
      }}
    />
  );
}

ChildrenDocumentTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  })),
  selection: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
  })),
  setSelection: PropTypes.func,
};

export default ChildrenDocumentTable;
