import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import NewDirectoryForm, { useNewDirectoryFormData } from 'components/NewDirectoryForm';
import NewExperimentForm, { useNewExperimentFormData } from 'components/NewExperimentForm';
import NewNoteForm, { useNewNoteFormData } from 'components/NewNoteForm';
import NewSopForm, { useNewSopFormData } from 'components/NewSopForm';
import NewTaskForm, { useNewTaskFormData } from 'components/NewTaskForm';
import React, { useMemo, useState } from 'react';
import {
  directoryActions,
  experimentActions,
  noteActions,
  sopActions,
  taskActions,
} from 'store/entity';
import { formatType, getIcon } from 'utils/entity.utils';
import { useDirectory, useMasterNote } from 'hooks/store.hooks';

import Divider from '@mui/material/Divider';
import MenuItem from 'components/MenuItem';
import NewMasterNoteItemBase from './NewMasterNoteItemBase';
import NewMasterNoteItemExisting from './NewMasterNoteItemExisting';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { TextField } from '@acheloisbiosoftware/absui.core';
import Typography from '@mui/material/Typography';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { isNil } from 'lodash';
import { useDispatch } from 'react-redux';

const ENTITY_CREATE_ACTION_MAP = {
  [ENTITY_NOTE]: noteActions.createNote,
  [ENTITY_EXPERIMENT]: experimentActions.createExperiment,
  [ENTITY_SOP]: sopActions.createSop,
  [ENTITY_DIRECTORY]: directoryActions.createDirectory,
  [ENTITY_TASK]: taskActions.createTask,
};

function NewMasterNoteItemNew(props) {
  const {
    masterNoteId,
    sectionId,
    onRemove,
    addableEntityTypes,
    addableDirectoryTypes,
  } = props;
  const dispatch = useDispatch();
  const parentId = useMasterNote(masterNoteId, 'parent');
  const parentIsTemplate = useDirectory(parentId, 'is_template');

  const initFormDataMap = useMemo(() => ({
    [ENTITY_NOTE]: { is_template: parentIsTemplate, permission_relationships: []},
    [ENTITY_EXPERIMENT]: { is_template: parentIsTemplate, permission_relationships: []},
    [ENTITY_SOP]: { is_template: false, permission_relationships: []},
    [ENTITY_DIRECTORY]: { is_template: parentIsTemplate, type: addableDirectoryTypes[0], permission_relationships: []},
    [ENTITY_TASK]: { is_template: parentIsTemplate, permission_relationships: []},
  }), [addableDirectoryTypes, parentIsTemplate]);

  const [existingItem, setExistingItem] = useState(null);
  const [type, setType] = useState(addableEntityTypes[0]);
  const newNoteData = useNewNoteFormData(initFormDataMap[ENTITY_NOTE]);
  const newExperimentData = useNewExperimentFormData(initFormDataMap[ENTITY_EXPERIMENT]);
  const newSopData = useNewSopFormData(initFormDataMap[ENTITY_SOP]);
  const newTaskData = useNewTaskFormData(initFormDataMap[ENTITY_TASK]);
  const newDirectoryData = useNewDirectoryFormData(initFormDataMap[ENTITY_DIRECTORY]);
  const newDataMap = {
    [ENTITY_NOTE]: newNoteData,
    [ENTITY_EXPERIMENT]: newExperimentData,
    [ENTITY_SOP]: newSopData,
    [ENTITY_TASK]: newTaskData,
    [ENTITY_DIRECTORY]: newDirectoryData,
  };
  const newData = newDataMap[type] ?? {};
  const onTypeChange = (_, newType) => {
    setType(newType);
  };

  const createMasterNoteItem = async () => {
    const createAction = ENTITY_CREATE_ACTION_MAP[type];
    const createData = newData.formData;

    const masterNoteItemData = {};
    const newEntityGenericId = {};

    try {
      const resCreate = await dispatch(createAction(createData)).unwrap();
      if (!isDefined(resCreate.result) || !isDefined(resCreate.entities)) return;
      const { result, entities } = resCreate;
      const entity = entities[type]?.[result] ?? {};
      const directoryItem = entity.directory_item;
      Object.assign(masterNoteItemData, {
        id: masterNoteId,
        data: {
          master_note_section: sectionId,
          directory_item: directoryItem,
        },
      });
      Object.assign(newEntityGenericId, getGenericId(result, type));
    } catch {}

    try {
      const res = await dispatch(directoryActions.createMasterNoteItem(masterNoteItemData)).unwrap();
      if (isDefined(res.result)) onRemove();
    } catch {
      setExistingItem(newEntityGenericId);
    }
  };

  return isNil(existingItem) ? (
    <NewMasterNoteItemBase
      onCreate={createMasterNoteItem}
      onRemove={onRemove}
      createButtonProps={{ disabled: !newData.valid }}
    >
      <Stack direction='row' alignItems='center' sx={{ width: 150 }}>
        <TextField
          variant='standard'
          label='Document Type'
          select
          value={type}
          SelectProps={{
            renderValue: (value) => {
              const Icon = getIcon(value);
              const isTemplate = newDataMap[value].formData.is_template;
              return (
                <Stack direction='row' spacing={1}>
                  <Icon colored template={isTemplate} />
                  <Typography variant='inherit' noWrap>{formatType(value, { template: isTemplate, defaultValue: '' })}</Typography>
                </Stack>
              );
            },
          }}
          onChange={onTypeChange}
          sx={{ width: 150 }}
          size='small'
        >
          { addableEntityTypes.map((t) => {
            const Icon = getIcon(t);
            const isTemplate = newDataMap[t].formData.is_template;
            return (
              <MenuItem
                key={`type_${t}`}
                value={t}
                label={formatType(t, { template: isTemplate })}
                icon={<Icon colored template={isTemplate} />}
              />
            );
          })}
        </TextField>
      </Stack>

      <Divider orientation='vertical' variant='middle' flexItem sx={{ mx: 2 }} />

      <Stack direction='row' alignItems='center' sx={{ flexGrow: 1 }}>
        { type === ENTITY_NOTE ? (
          <NewNoteForm
            inline
            formData={newNoteData.formData}
            updateFormData={newNoteData.updateFormData}
            excludeFields={['permission_relationships']}
            fieldPropsMap={{ title: { sx: { width: 1 }}}}
            containerProps={{ sx: { width: 1 }}}
          />
        ) : type === ENTITY_EXPERIMENT ? (
          <NewExperimentForm
            inline
            formData={newExperimentData.formData}
            updateFormData={newExperimentData.updateFormData}
            excludeFields={['permission_relationships']}
            fieldPropsMap={{ title: { sx: { width: 1, mr: 1 }}, related_sop: { sx: { width: 1, ml: 1 }}}}
            containerProps={{ sx: { width: 1 }}}
          />
        ) : type === ENTITY_SOP ? (
          <NewSopForm
            inline
            formData={newSopData.formData}
            updateFormData={newSopData.updateFormData}
            excludeFields={['permission_relationships']}
            fieldPropsMap={{ title: { sx: { width: 1, mr: 1 }}, category: { sx: { width: 1, ml: 1 }}}}
            containerProps={{ sx: { width: 1 }}}
          />
        ) : type === ENTITY_DIRECTORY ? (
          <NewDirectoryForm
            inline
            formData={newDirectoryData.formData}
            updateFormData={newDirectoryData.updateFormData}
            excludeFields={['permission_relationships']}
            fieldPropsMap={{
              name: { sx: { width: 1, mr: 1 }},
              type: {
                disabled: true,
                sx: { width: 1, ml: 1 },
              },
            }}
            containerProps={{ sx: { width: 1 }}}
          />
        ) : type === ENTITY_TASK ? (
          <NewTaskForm
            inline
            formData={newTaskData.formData}
            updateFormData={newTaskData.updateFormData}
            excludeFields={['assignees', 'due_date', 'description', 'permission_relationships']}
            fieldPropsMap={{ title: { sx: { width: 1, mr: 1 }}, related_obj: { sx: { width: 1, ml: 1 }}}}
            containerProps={{ sx: { width: 1 }}}
          />
        ) : null}
      </Stack>
    </NewMasterNoteItemBase>
  ) : (
    <NewMasterNoteItemExisting
      masterNoteId={masterNoteId}
      sectionId={sectionId}
      onRemove={onRemove}
      addableEntityTypes={addableEntityTypes}
      addableDirectoryTypes={addableDirectoryTypes}
      genericId={existingItem}
    />
  );
}

NewMasterNoteItemNew.propTypes = {
  masterNoteId: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  addableEntityTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  addableDirectoryTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NewMasterNoteItemNew;
