import {
  COMPLETED as EXPERIMENT_COMPLETED,
  IN_PROGRESS as EXPERIMENT_IN_PROGRESS,
  SIGNED as EXPERIMENT_SIGNED,
  SIGNING as EXPERIMENT_SIGNING,
} from 'constants/experiment.constants';
import {
  COMPLETED as SIGNATURE_COMPLETED,
  IN_PROGRESS as SIGNATURE_IN_PROGRESS,
  NOT_STARTED as SIGNATURE_NOT_STARTED,
  REJECTED as SIGNATURE_REJECTED,
  SKIPPED as SIGNATURE_SKIPPED,
} from 'constants/signature.constants';
import {
  DRAFT as SOP_DRAFT,
  INACTIVE as SOP_INACTIVE,
  PUBLISHED as SOP_PUBLISHED,
  SIGNING as SOP_SIGNING,
} from 'constants/sop.constants';
import {
  DONE as TASK_DONE,
  IN_PROGRESS as TASK_IN_PROGRESS,
  TO_DO as TASK_TO_DO,
} from 'constants/task.constants';
import {
  ACTIVE as USER_ACTIVE,
  INACTIVE as USER_INACTIVE,
} from 'constants/user.constants';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

// NOTE: Be wary of the fact that some of these key constants are actually the
// SAME STRING VALUE, therefore some of these values are getting overwritten.
const sxSchemes = {
  [EXPERIMENT_IN_PROGRESS]: { color: 'warning.main' },
  [EXPERIMENT_COMPLETED]: { color: 'success.main' },
  [EXPERIMENT_SIGNING]: { color: 'info.main' },
  [EXPERIMENT_SIGNED]: { color: 'success.main' },
  [SOP_INACTIVE]: { color: 'error.main' },
  [SOP_DRAFT]: { color: 'warning.main' },
  [SOP_SIGNING]: { color: 'info.main' },
  [SOP_PUBLISHED]: { color: 'success.main' },
  [USER_ACTIVE]: { color: 'success.main' },
  [USER_INACTIVE]: { color: 'error.main' },
  [SIGNATURE_NOT_STARTED]: { color: 'grey.500' },
  [SIGNATURE_IN_PROGRESS]: { color: 'warning.main' },
  [SIGNATURE_COMPLETED]: { color: 'success.main' },
  [SIGNATURE_REJECTED]: { color: 'error.main' },
  [SIGNATURE_SKIPPED]: { color: 'grey.500' },
  [TASK_TO_DO]: { color: 'grey.500' },
  [TASK_IN_PROGRESS]: { color: 'warning.main' },
  [TASK_DONE]: { color: 'success.main' },
};

const childrenDisplay = {
  [EXPERIMENT_IN_PROGRESS]: 'In Progress',
  [EXPERIMENT_COMPLETED]: 'Completed',
  [EXPERIMENT_SIGNING]: 'Signing',
  [EXPERIMENT_SIGNED]: 'Signed',
  [SOP_INACTIVE]: 'Inactive',
  [SOP_DRAFT]: 'Draft',
  [SOP_SIGNING]: 'Signing',
  [SOP_PUBLISHED]: 'Published',
  [USER_ACTIVE]: 'Active',
  [USER_INACTIVE]: 'Inactive',
  [SIGNATURE_NOT_STARTED]: 'Not Started',
  [SIGNATURE_IN_PROGRESS]: 'In Progress',
  [SIGNATURE_COMPLETED]: 'Completed',
  [SIGNATURE_REJECTED]: 'Rejected',
  [SIGNATURE_SKIPPED]: 'Skipped',
  [TASK_TO_DO]: 'To Do',
  [TASK_IN_PROGRESS]: 'In Progress',
  [TASK_DONE]: 'Done',
};

function Status(props) {
  const { children, size } = props;
  const sxScheme = sxSchemes[children];
  return !isDefined(children) ? (
    <Skeleton variant='rounded' width={80} height={size} />
  ) : (
    <Box
      sx={mergeSx(
        { display: 'flex', height: size },
        sxScheme,
      )}
    >
      <Box
        sx={{
          bgcolor: sxScheme?.color ?? 'text.primary',
          height: size / 3,
          width: size / 3,
          borderRadius: '50%',
          my: 'auto',
          mr: size / 3 / 8,
        }}
      />
      <Typography noWrap sx={{ my: 'auto', fontSize: `${size / 23}rem` }}>
        {childrenDisplay[children] ?? children}
      </Typography>
    </Box>
  );
}

Status.propTypes = {
  children: PropTypes.string,
  size: PropTypes.number,
};

Status.defaultProps = {
  size: 23,
};

export default Status;
