import { ActionPin, ENTITY_ACTION_PIN, EntityActionState, IconButtonAction, selectEntityActions } from 'components/EntityActions';
import DataTable, { COL_TYPE_ACTIONS } from 'components/DataTable';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_PIN, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import { GenericProp, Prop, SelectorProp, entityActions, entitySelectors } from 'store/entity';
import React, { useCallback, useEffect, useMemo } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import { useDirectoryItem, useEntity, useFetchListing, useMyId, usePin, usePinList, useUpdateEntity } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WidgetContainer from './WidgetContainer';
import { arrayMove } from '@dnd-kit/sortable';
import { getGenericId } from 'utils/generic.utils';
import { isNil } from 'lodash';
import { pinActions } from 'store/entity/pin';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const pinProp = new Prop({
  id: 'id',
  sequence: 'sequence',
});

const genericIdProp = new Prop({
  id: 'object_id',
  type: 'content_type',
});

const createIconSelectorProp = (type) => new SelectorProp((state, entity) => entitySelectors.selectEntityIcon(state, getGenericId(entity?.id, type)));
const entityProp = new GenericProp({
  [ENTITY_DIRECTORY]: {
    Icon: createIconSelectorProp(ENTITY_DIRECTORY),
    isTemplate: 'is_template',
    title: 'name',
  },
  [ENTITY_EXPERIMENT]: {
    Icon: createIconSelectorProp(ENTITY_EXPERIMENT),
    isTemplate: 'is_template',
    title: 'long_title',
  },
  [ENTITY_NOTE]: {
    Icon: createIconSelectorProp(ENTITY_NOTE),
    isTemplate: 'is_template',
    title: 'long_title',
  },
  [ENTITY_SOP]: {
    Icon: createIconSelectorProp(ENTITY_SOP),
    isTemplate: 'is_template',
    title: 'long_title',
  },
  [ENTITY_TASK]: {
    Icon: createIconSelectorProp(ENTITY_TASK),
    isTemplate: 'is_template',
    title: 'long_title',
  },
});

const initState = {
  orderBy: 'sequence',
  order: 'asc',
};

const tableProps = {
  size: 'small',
};

function PinnedWidget() {
  const navigate = useNavigate();
  const update = useUpdateEntity();
  const dispatch = useDispatch();
  const myId = useMyId();

  useEffect(() => {
    // Clear out the pin slice to load in only the current pins
    dispatch(entityActions.clearSlice(ENTITY_PIN));
  }, [dispatch]);
  const loading = useFetchListing(pinActions.fetchPins);

  const listParams = useMemo(() => ({
    prop: 'id',
    filter: (pin) => pin.user === myId, // Should only ever have access to own pins anyway
    sort: (pin1, pin2) => pin1.sequence - pin2.sequence,
  }), [myId]);
  const pinIds = usePinList(listParams);

  const pinData = usePin(pinIds, pinProp);
  const directoryItemIds = usePin(pinIds, 'directory_item');
  const genericIds = useDirectoryItem(directoryItemIds, genericIdProp);
  const entityData = useEntity(genericIds, entityProp);

  const rows = useMemo(() => pinData.map((pin, idx) => ({
    genericId: genericIds[idx],
    directoryId: directoryItemIds[idx],
    ...entityData[idx],
    ...pin,
  })), [pinData, directoryItemIds, genericIds, entityData]);

  const columns = useMemo(() => [
    {
      id: 'sequence',
      field: 'sequence',
      hidden: true,
    },
    {
      id: 'type',
      valueGetter: ({ row }) => row.genericId.type,
      renderValue: ({ row }) => {
        const { Icon, isTemplate } = row;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            { Icon ? <Icon colored template={isTemplate} /> : null }
          </Box>
        );
      },
      cellProps: { sx: { width: 20, pr: 0 }},
    },
    {
      id: 'title',
      field: 'title',
      renderValue: ({ value }) => value ?? (
        <Tooltip title='You do not have permission to view this item' arrow>
          <Typography
            variant='subtitle2'
            color='text.secondary'
            sx={{ width: 'fit-content' }}
          >
            [Content Hidden]
          </Typography>
        </Tooltip>
      ),
      primary: true,
      cellProps: { sx: { cursor: 'pointer' }},
    },
    {
      id: 'pin',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      actions: ({ row }) => [{
        id: ENTITY_ACTION_PIN,
        component: ActionPin,
        props: {
          id: row.genericId.id,
          type: row.genericId.type,
          pinId: row.id,
          component: IconButtonAction,
          size: 'small',
        },
      }],
      cellProps: { sx: { pr: 0 }},
    },
    {
      id: 'actions',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      inMenu: true,
      actions: ({ row, state }) => selectEntityActions(state, row.genericId.id, row.genericId.type),
      cellProps: { sx: { pl: 0 }},
    },
  ], []);

  const onRowClick = useCallback((row) => {
    navigate(getPath(row.genericId));
  }, [navigate]);

  const dndContextProps = useMemo(() => ({
    onDragEnd: async (event) => {
      const { active, over } = event;
      const { index: srcIdx, items } = active?.data?.current?.sortable ?? {};
      const { index: destIdx } = over?.data?.current?.sortable ?? {};
      if (isNil(srcIdx) || isNil(destIdx) || srcIdx === destIdx) return;
      const newItems = arrayMove([...items], srcIdx, destIdx);
      newItems.forEach((pinId, idx) => {
        update({ type: ENTITY_PIN, id: pinId, key: 'sequence', value: idx });
      });
      for (const pinId of newItems) {
        try {
          await dispatch(pinActions.patchPin({ id: pinId })).unwrap();
        } catch {}
      }
    },
  }), [update, dispatch]);

  return (
    <WidgetContainer md={12} xl={12} paperProps={{ sx: { height: 'unset' }}}>
      <Typography variant='h4'>{formatType(ENTITY_PIN, { plural: true })}</Typography>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mb: 1 }}>
        { !loading && pinIds.length === 0 ? (
          <Typography variant='body2' color='text.disabled'>No favorited documents...</Typography>
        ) : (
          <EntityActionState>
            <DataTable
              rows={rows}
              columns={columns}
              onRowClick={onRowClick}
              loading={loading}
              initState={initState}
              enableDnd
              dndContextProps={dndContextProps}
              enableSortable
              noHeader
              disableFilter
              tableProps={tableProps}
              sx={{ minHeight: 150 }}
            />
          </EntityActionState>
        )}
      </Box>
    </WidgetContainer>
  );
}

export default PinnedWidget;
