import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

function FieldValueDate(props) {
  const { value, setValue } = props;
  return (
    <DatePicker
      label='Value'
      value={moment(value)}
      onChange={(newValue) => setValue(newValue)}
      slotProps={{ textField: { variant: 'standard', size: 'small', sx: { flexGrow: 1 }}}}
    />
  );
}

FieldValueDate.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired,
};

export default FieldValueDate;
