import * as attachmentActions from './attachment.actions';

import { reduce404, reduceEntities } from '../entity.reducers.utils';

import { ENTITY_ATTACHMENT } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, attachmentActions.fetchAttachments);
  reduceEntities(builder, attachmentActions.createAttachment);
  reduceEntities(builder, attachmentActions.fetchAttachment, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.uuid, ENTITY_ATTACHMENT)),
  });
  reduceEntities(builder, attachmentActions.patchAttachment);
  reduceEntities(builder, attachmentActions.checkinAttachment);
  reduceEntities(builder, attachmentActions.checkoutAttachment);
};
