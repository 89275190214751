import { EntityActionState, useEntityActions } from 'components/EntityActions';
import { useEntityId, useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useMasterNote, useMasterNoteSection, useUpdateEntity } from 'hooks/store.hooks';

import { Button } from '@acheloisbiosoftware/absui.core';
import { ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import MasterNoteSectionBase from './MasterNoteSectionBase';
import MasterNoteSectionDescription from './MasterNoteSectionDescription';
import MasterNoteSectionTable from 'components/MasterNoteSectionTable';
import MuiNotesIcon from '@mui/icons-material/Notes';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import TableChartIcon from '@mui/icons-material/TableChart';
import Typography from '@mui/material/Typography';
import { formatType } from 'utils/entity.utils';

function MasterNoteSection(props) {
  const { id } = props;
  const update = useUpdateEntity();
  const masterNoteId = useEntityId();
  const sectionIds = useMasterNote(masterNoteId, 'sections');
  const title = useMasterNoteSection(id, 'title');
  const sequence = (sectionIds ?? []).findIndex((sectionId) => String(sectionId) === String(id));
  const hideDescription = useMasterNoteSection(id, 'hide_description');
  const hideItems = useMasterNoteSection(id, 'hide_items');
  const isReadOnly = useEntityIsReadOnly();

  const onEdit = (value) => {
    if ((value ?? '').length > 0 && value !== title) {
      update({ type: ENTITY_MASTER_NOTE_SECTION, id, key: 'title', value });
    }
  };

  const extraActions = useEntityActions(id, ENTITY_MASTER_NOTE_SECTION);

  return (
    <EntityActionState>
      <MasterNoteSectionBase
        containerProps={{
          id: `section_${id}`,
          sx: { mt: 2 },
        }}
        sectionHeaderProps={{
          prefix: `${sequence + 1}.`,
          title,
          onEdit: isReadOnly ? null : onEdit,
          extraActions: { inMenu: extraActions },
        }}
      >
        { hideDescription ? null : (
          <MasterNoteSectionDescription id={id} />
        )}
        { hideItems ? null : (
          <MasterNoteSectionTable id={id} />
        )}
        { hideDescription && hideItems ? (
          <Stack sx={{ mt: 1 }} alignItems='center'>
            <Typography
              variant='body2'
              color='text.secondary'
            >
              Both {formatType(ENTITY_MASTER_NOTE_SECTION, { lowercase: true })} description and items are hidden.
            </Typography>
            <Stack
              direction='row'
              justifyContent='space-evenly'
              sx={{ color: 'text.secondary', width: 500, mt: 1 }}
            >
              <Button
                color='inherit'
                variant='outlined'
                size='small'
                startIcon={<MuiNotesIcon />}
                onClick={() => update({ type: ENTITY_MASTER_NOTE_SECTION, id, key: 'hide_description', value: false })}
                sx={{ width: 200 }}
              >
                Show Description
              </Button>
              <Button
                color='inherit'
                variant='outlined'
                size='small'
                startIcon={<TableChartIcon />}
                onClick={() => update({ type: ENTITY_MASTER_NOTE_SECTION, id, key: 'hide_items', value: false })}
                sx={{ width: 200 }}
              >
                Show Items
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </MasterNoteSectionBase>
    </EntityActionState>
  );
}

MasterNoteSection.propTypes = {
  id: PropTypes.number.isRequired,
};

export default MasterNoteSection;
