import React, { useMemo } from 'react';

import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import Stack from '@mui/material/Stack';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useResizableWidth } from '@acheloisbiosoftware/absui.hooks';

const RESIZABLE_INIT_WIDTH = 300;
const RESIZABLE_OPTIONS = { minWidth: 200, maxWidth: 600 };
const RESIZER_WIDTH = 8;

function NavigatorColumn(props) {
  const { title, sectionHeaderProps, children } = props;
  const { width, Resizer, isResizing } = useResizableWidth(RESIZABLE_INIT_WIDTH, RESIZABLE_OPTIONS);

  const sectionHeaderSx = useMemo(
    () => mergeSx({ borderBottom: 2, borderColor: 'divider' }, sectionHeaderProps?.sx),
    [sectionHeaderProps?.sx],
  );
  const titleTypographyProps = useMemo(
    () => ({ ...sectionHeaderProps?.titleTypographyProps, sx: mergeSx({ mx: 1 }, sectionHeaderProps?.titleTypographyProps?.sx) }),
    [sectionHeaderProps?.titleTypographyProps],
  );

  return (
    <Stack
      component={Paper}
      elevation={2}
      sx={{
        height: 1,
        borderRadius: 0,
        boxSizing: 'border-box',
        transition: (theme) => (isResizing ? null : theme.transitions.create('width')),
        borderRight: 2,
        borderColor: 'divider',
        width,
        minWidth: width,
        position: 'relative',
      }}
    >
      <SectionHeader
        title={title}
        {...sectionHeaderProps}
        sx={sectionHeaderSx}
        titleTypographyProps={titleTypographyProps}
      />
      {children}
      <Resizer
        sx={{
          width: RESIZER_WIDTH,
          left: width - RESIZER_WIDTH,
          zIndex: 'header',
        }}
      />
    </Stack>
  );
}

NavigatorColumn.propTypes = {
  title: PropTypes.any,
  sectionHeaderProps: PropTypes.object,
  children: PropTypes.node,
};

export default NavigatorColumn;
