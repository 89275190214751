import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

function SidePreviewContent(props) {
  const { children } = props;
  return (
    <Box sx={{ mt: 1, mb: 2, px: 2, position: 'relative' }}>
      { children }
    </Box>
  );
}

SidePreviewContent.propTypes = {
  children: PropTypes.node,
};

export default SidePreviewContent;
