import { BLOCK_CALCULATION, BLOCK_INPUT } from './Variable.constants';

import CalculationButton from './CalculationButton';
import CalculationElement from './CalculationElement';
import InputButton from './InputButton';
import InputElement from './InputElement';
import React from 'react';
import { useVariables } from './Variable';

export default {
  plugin: useVariables,
  renderElement: (props) => {
    switch (props?.element?.type) {
      case BLOCK_INPUT:
        return (<InputElement {...props} />);
      case BLOCK_CALCULATION:
        return (<CalculationElement {...props} />);
      default:
        return null;
    }
  },
  renderToolbarActions: [InputButton, CalculationButton],
};
