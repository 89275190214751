import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import PropTypes from 'prop-types';
import { ShareIcon } from 'constants/icon.constants';
import { useShareDialogContext } from './EntityActionState';

function ActionShare(props) {
  const { id, type, ...restProps } = props;
  const { setShareDialogProps } = useShareDialogContext();

  const onAction = useCallback(() => {
    setShareDialogProps?.((oldProps) => ({ ...oldProps, open: true, id, type }));
  }, [id, type, setShareDialogProps]);

  return (
    <BaseAction
      icon={<ShareIcon />}
      label='Share'
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionShare.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};

export default ActionShare;
