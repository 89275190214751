import React, { useEffect, useState } from 'react';
import { notificationActions, notificationSelectors } from 'store/notification';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useTheme } from '@mui/material/styles';

function SnackbarNotifier() {
  const notification = useSelector(notificationSelectors.selectFirstNotification);

  const { key, message, variant, title, snackbarProps, alertProps, ...restProps } = notification || {};
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    setOpen(true);
  }, [key]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setTimeout(() => dispatch(notificationActions.removeSnackbar(key)), theme.transitions.duration.leavingScreen + 1);
  };

  if (!notification) return null;

  return (
    <Snackbar
      key={key}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
      {...snackbarProps}
    >
      <Alert
        variant='filled'
        severity={variant || 'info'}
        {...restProps}
        {...alertProps}
        onClose={handleClose}
      >
        { isDefined(title) ? (<AlertTitle>{title}</AlertTitle>) : null}
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarNotifier;
