import { ENTITY_ACTION_DELETE, ENTITY_ACTION_OPEN, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_NOTE, ENTITY_USER } from 'constants/schemas';
import React, { useCallback, useMemo, useState } from 'react';
import { useNote, useNoteHasLoaded, useUpdateEntity, useUserName } from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CollapseButton from 'components/CollapseButton';
import { DELETE_SOFT } from 'constants/deletion.constants';
import Field from 'components/Field';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TemplateChip from 'components/TemplateChip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';

const AVATAR_SIZE = 20;
const AVATAR_SX = { my: 'auto', mr: 1 };

function NoteHeader(props) {
  const { id } = props;
  const note = useNote(id);
  const {
    note_code,
    title,
    created,
    modified,
    created_by,
    directory_item,
    is_template,
  } = note ?? {};
  const {
    noteCodeHasLoaded,
    createdHasLoaded,
    modifiedHasLoaded,
    createdByHasLoaded,
  } = useNoteHasLoaded(id, {
    noteCodeHasLoaded: 'note_code',
    createdHasLoaded: 'created',
    modifiedHasLoaded: 'modified',
    createdByHasLoaded: 'created_by',
  });

  const navigate = useNavigate();
  const author = useUserName(created_by);
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();

  const [detailsOpen, setDetailsOpen] = useState(false);

  const onEdit = (value) => {
    if (isReadOnly) return;
    if (value !== title) {
      update({ type: ENTITY_NOTE, id, key: 'title', value });
    }
  };

  const onDeleteSuccess = useCallback((res, { deletionType }) => {
    if (deletionType !== DELETE_SOFT) {
      navigate('/');
    }
  }, [navigate]);

  const extraActionsInMenu = useEntityActions(id, ENTITY_NOTE, {
    exclude: [ENTITY_ACTION_OPEN],
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess }},
  });

  const extraActions = useMemo(() => ({
    left: is_template ? [{
      id: 'template',
      component: TemplateChip,
      props: {
        type: ENTITY_NOTE,
        sx: { my: 'auto', mx: 1 },
      },
    }] : [],
    inMenu: extraActionsInMenu,
    right: [{
      id: 'collapse',
      component: Box,
      props: {
        sx: { my: 'auto' },
        children: (
          <Tooltip title={detailsOpen ? 'Hide details' : 'View details'} placement='bottom' arrow>
            <CollapseButton
              open={detailsOpen}
              setOpen={setDetailsOpen}
              buttonProps={{ size: 'small' }}
            />
          </Tooltip>
        ),
      },
    }],
  }), [extraActionsInMenu, detailsOpen, setDetailsOpen, is_template]);

  return (
    <Box sx={{ mt: 1, mx: 3 }}>
      <Typography
        variant='caption'
        sx={{ color: 'text.secondary' }}
      >
        {noteCodeHasLoaded ? note_code : <Skeleton width={150} />}
      </Typography>
      <EntityActionState>
        <SectionHeader
          title={title}
          variant='h4'
          onEdit={!isReadOnly ? onEdit : null}
          extraActions={extraActions}
          skeletonProps={{ width: 300 }}
          sx={{ ml: -1, pt: 0 }}
        />
      </EntityActionState>
      <Collapse in={!detailsOpen}>
        <Stack direction='row' alignItems='baseline' sx={{ mb: 0.5 }}>
          { createdByHasLoaded ? (
            <Avatar
              id={created_by}
              type={ENTITY_USER}
              size={AVATAR_SIZE}
              sx={AVATAR_SX}
            />
          ) : (
            <Skeleton
              variant='circular'
              width={AVATAR_SIZE}
              height={AVATAR_SIZE}
              sx={AVATAR_SX}
            />
          )}
          <Typography color='text.secondary'>
            {createdByHasLoaded ? author : <Skeleton width={120} />}
          </Typography>
          <Tooltip
            title={`Last edited: ${formatDate(modified)}`}
            placement='bottom'
            arrow
          >
            <Typography
              variant='caption'
              color='text.tertiary'
              sx={{ ml: 2 }}
            >
              {createdHasLoaded ? formatDate(created) : <Skeleton width={80} />}
            </Typography>
          </Tooltip>
        </Stack>
      </Collapse>
      <Collapse in={detailsOpen}>
        <Stack direction='row'>
          <Stack sx={{ flexGrow: 1 }}>
            <Field
              label='Author'
              loading={!createdByHasLoaded}
              labelProps={{ sx: { mt: 0 }}}
            >
              {author}
            </Field>
            <Field label='Location(s)'><Locations directoryItem={directory_item} /></Field>
          </Stack>
          <Stack sx={{ flexGrow: 1 }}>
            <Field label='Date Created' loading={!createdHasLoaded}>{formatDate(created)}</Field>
            <Field label='Last Modified' loading={!modifiedHasLoaded}>{formatDate(modified)}</Field>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
}

NoteHeader.propTypes = {
  id: PropTypes.number.isRequired,
};

export default NoteHeader;
