import { EditIcon, ViewIcon } from 'constants/icon.constants';

import { ACTION_UPDATE } from 'constants/permission.constants';
import BaseAction from './BaseAction';
import { MenuItemLinkAction } from './MenuItemAction';
import PropTypes from 'prop-types';
import React from 'react';
import { getPath } from 'utils/entity.utils';
import { useEntityPermission } from 'hooks/store.hooks';
import { useGenericId } from 'utils/generic.utils';

function ActionOpen(props) {
  const { id, type, ...restProps } = props;
  const genericId = useGenericId(id, type);
  const canUpdate = useEntityPermission(genericId, ACTION_UPDATE);

  return (
    <BaseAction
      icon={canUpdate ? <EditIcon /> : <ViewIcon />}
      label={canUpdate ? 'Edit' : 'View'}
      component={MenuItemLinkAction}
      to={getPath(genericId)}
      {...restProps}
    />
  );
}

ActionOpen.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};

export default ActionOpen;
