import { Button, TextField } from '@acheloisbiosoftware/absui.core';
import { ClearIcon, SearchIcon } from 'constants/icon.constants';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mergeSx, range } from '@acheloisbiosoftware/absui.utils';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import SearchResultEntity from './SearchResultEntity';
import SearchResultLoading from './SearchResultLoading';
import useGlobalSearch from './useGlobalSearch';

function GlobalSearch(props) {
  const {
    types,
    excludeTypes,
    filter,
    debounce = 500,
    textFieldProps,
    popperProps,
    paperProps,
    listProps,
    searchIconProps,
    circularProgressProps,
    clearButtonProps,
    clearIconProps,
    onClickResult,
    navigateToResult,
  } = props;
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(ref.current);
  const _anchorEl = popperProps?.anchorEl ?? anchorEl;

  const searchOptions = useMemo(() => ({ types, excludeTypes, filter, debounce }), [types, excludeTypes, filter, debounce]);
  const { loading, debounceLoading, results } = useGlobalSearch(search, searchOptions);

  const showResults = Boolean(open && search && _anchorEl);
  const [displayLoading, setDisplayLoading] = useState(loading || debounceLoading);

  useEffect(() => {
    /**
     * NOTE: there is single render cycle in between the debounce loading state
     * turning off and the loading state turning on. Using a timeout here
     * creates a smoother user experience so that "No Results" doesn't quickly
     * flash in this single render cycle.
     */
    const timeoutRef = { current: null };
    if (loading || debounceLoading) {
      setDisplayLoading(true);
    } else {
      timeoutRef.current = setTimeout(() => setDisplayLoading(false), debounce);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [loading, debounceLoading, debounce]);


  const clearSearch = useCallback((...args) => {
    setSearch('');
    clearButtonProps?.onClick?.(...args);
  }, [clearButtonProps]);

  const onChange = useCallback((e, newSearch) => {
    setSearch(newSearch);
    textFieldProps?.onChange?.(e, newSearch);
  }, [textFieldProps]);

  const onFocus = useCallback((...args) => {
    setOpen(true);
    setAnchorEl(ref.current);
    textFieldProps?.onFocus?.(...args);
  }, [textFieldProps]);

  const onBlur = useCallback((...args) => {
    setOpen(false);
    textFieldProps?.onBlur?.(...args);
  }, [textFieldProps]);

  return (
    <>
      <TextField
        ref={ref}
        startAdornment={<SearchIcon {...searchIconProps} sx={mergeSx({ color: 'text.icon' }, searchIconProps?.sx)} />}
        placeholder='Search acta...'
        {...textFieldProps}
        value={search}
        onChange={onChange}
        endAdornment={(
          <>
            { loading && open ? <CircularProgress size={20} {...circularProgressProps} /> : null }
            { search ? (
              <Button icon size='small' {...clearButtonProps} onClick={clearSearch}>
                <ClearIcon fontSize='small' {...clearIconProps} />
              </Button>
            ) : null }
            { textFieldProps?.endAdornment }
          </>
        )}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Popper
        open={showResults}
        transition
        placement='bottom-start'
        {...popperProps}
        anchorEl={_anchorEl}
        sx={mergeSx({ zIndex: 'modal' }, popperProps?.sx)}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper
              {...paperProps}
              sx={mergeSx({ width: _anchorEl?.clientWidth }, paperProps?.sx)}
            >
              <List
                dense
                disablePadding
                {...listProps}
                sx={mergeSx({
                  maxHeight: 240,
                  overflowY: 'auto',
                }, listProps?.sx)}
              >
                { results.length > 0 ? results.map((result) => (
                  <SearchResultEntity
                    key={`${result.type}-${result.id}`}
                    id={result.id}
                    type={result.type}
                    onClick={onClickResult}
                    navigateToResult={navigateToResult}
                  />
                )) : (
                  displayLoading ? (
                    range(3).map((i) => (<SearchResultLoading key={`skeleton-${i}`} />))
                  ) : (
                    <ListItem>
                      <ListItemText primary='No results' />
                    </ListItem>
                  )
                )}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}

GlobalSearch.propTypes = {
  types: PropTypes.arrayOf(PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ])),
  excludeTypes: PropTypes.arrayOf(PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ])),
  filter: PropTypes.func,
  debounce: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  textFieldProps: PropTypes.object,
  popperProps: PropTypes.object,
  paperProps: PropTypes.object,
  listProps: PropTypes.object,
  searchIconProps: PropTypes.object,
  circularProgressProps: PropTypes.object,
  clearButtonProps: PropTypes.object,
  clearIconProps: PropTypes.object,
  onClickResult: PropTypes.func,
  navigateToResult: PropTypes.bool,
};

export default GlobalSearch;
