import { useColumn, useRow } from './DataTableContext';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';

function ContentCheckbox(props) {
  const { rowId, columnId } = props;
  const row = useRow(rowId);
  const column = useColumn(columnId);
  const {
    selection,
    disabled: disabledRows,
    onSelect,
    disabledMessage,
    checkboxProps,
  } = column;

  const selected = (selection ?? []).includes(rowId);
  const disabled = (disabledRows ?? []).includes(rowId);
  const tooltipMessage = !disabled ? null : (typeof(disabledMessage) === 'function' ? disabledMessage(row) : disabledMessage);

  const onClick = (event) => {
    const newSelection = selected ? selection.filter((id) => id !== rowId) : [...selection, rowId];
    onSelect?.(newSelection, event);
    event.stopPropagation();
  };

  return (
    <Tooltip title={tooltipMessage} arrow>
      <Box>
        <Checkbox
          checked={selected}
          onClick={onClick}
          disabled={disabled}
          {...checkboxProps}
        />
      </Box>
    </Tooltip>
  );
}

ContentCheckbox.propTypes = {
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  columnId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ContentCheckbox;
