import Field from 'components/Field';
import PropTypes from 'prop-types';
import React from 'react';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';

function NewNoteFormBlock(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    containerProps,
  } = props;
  const { title, permission_relationships } = formData;

  return (
    <Stack {...containerProps}>
      { excludeFields.includes('title') ? null : (
        <Field
          label='Title'
          variant={Field.VARIANTS.TEXT_FIELD}
          readOnly={false}
          value={title}
          {...fieldPropsMap?.title}
          textFieldProps={{
            onChange: (_, newTitle) => updateFormData('title', newTitle),
            ...fieldPropsMap?.title?.textFieldProps,
          }}
        />
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <ShareNewDocumentField
          shares={permission_relationships}
          setShares={(newShares) => updateFormData('permission_relationships', newShares)}
          {...fieldPropsMap?.permission_relationships}
        />
      )}
    </Stack>
  );
}

NewNoteFormBlock.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewNoteFormBlock;
