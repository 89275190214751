import { useMyId, useUserName } from 'hooks/store.hooks';

import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useMemo } from 'react';

const CURSOR_COLOR_SCHEMAS = [
  { bgcolor: 'material.teal.main', color: 'material.teal.contrastText' },
  { bgcolor: 'material.purple.main', color: 'material.purple.contrastText' },
  { bgcolor: 'material.blue.main', color: 'material.blue.contrastText' },
  { bgcolor: 'material.pink.main', color: 'material.pink.contrastText' },
  { bgcolor: 'material.green.main', color: 'material.green.contrastText' },
  { bgcolor: 'material.deepOrange.main', color: 'material.deepOrange.contrastText' },
];

const getCursorColorSchema = (id) => {
  if (!isDefined(id)) return {};
  return CURSOR_COLOR_SCHEMAS[id % CURSOR_COLOR_SCHEMAS.length];
};

const useCursorData = () => {
  const myId = useMyId();
  const myName = useUserName(myId);
  return useMemo(() => ({
    label: myName,
    ...getCursorColorSchema(myId),
  }), [myName, myId]);
};

export default useCursorData;
