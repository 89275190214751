import { DomUtils, isDefined } from '@acheloisbiosoftware/absui.utils';
import { EditorConstants, EditorPlugins, EditorUtils } from '@acheloisbiosoftware/absui.core';

import { BLOCK_DAY } from './Day.constants';
import { jsx } from 'slate-hyperscript';
import moment from 'moment';
import { timeNow } from 'utils/date.utils';

const { BLOCK_TEXT } = EditorConstants;
const { BaseEditor, Element, Range, Text, Transforms } = EditorPlugins;
const { nodeGenerator } = EditorUtils;

// #############################################################################
// ######################### Retrieval & Check Methods #########################
// #############################################################################
const isDay = (n, date) => (
  Element.isElement(n, BLOCK_DAY) &&
  (!isDefined(date) || moment(date).isSame(n.date, 'day'))
);

const getPrevDay = (editor, at = editor.selection) => {
  if (!at) return null;
  const beforePoint = BaseEditor.before(editor, at);
  if (!beforePoint) return null;
  const beforeRange = {
    anchor: BaseEditor.start(editor, []),
    focus: beforePoint,
  };
  const [prevDay] = BaseEditor.nodes(editor, {
    at: beforeRange,
    match: (n) => isDay(n),
    reverse: true,
  });
  return prevDay;
};

const getNextDay = (editor, at = editor.selection) => {
  if (!at) return null;
  const afterPoint = BaseEditor.after(editor, at);
  if (!afterPoint) return null;
  const afterRange = {
    anchor: afterPoint,
    focus: BaseEditor.end(editor, []),
  };
  const [nextDay] = BaseEditor.nodes(editor, {
    at: afterRange,
    match: (n) => isDay(n),
  });
  return nextDay;
};

const canInsertDay = (editor, at = editor.selection) => {
  for (const [node] of BaseEditor.levels(editor, { at })) {
    if (
      !BaseEditor.isEditor(node) &&
      !Element.isElement(node, BLOCK_TEXT) &&
      !Text.isText(node)
    ) {
      return false;
    }
  }
  const prevDay = getPrevDay(editor, at);
  const nextDay = getNextDay(editor, at);
  if (!prevDay || !nextDay) return true;
  const prevDate = moment(prevDay[0].date);
  const nextDate = moment(nextDay[0].date);
  const betweenDate = moment(prevDate).add(1, 'day');
  if (betweenDate.isSame(nextDate, 'day')) return false;
  return true;
};

// #############################################################################
// ############################# Generate Methods ##############################
// #############################################################################
const generateDay = (props) => ({
  type: BLOCK_DAY,
  date: timeNow(),
  children: [{ text: '' }],
  ...props,
});

// #############################################################################
// ########################### Manipulation Methods ############################
// #############################################################################
const insertDay = (editor, props) => {
  const { selection } = editor;
  if (!canInsertDay(editor, selection)) return;
  if (Range.isExpanded(selection)) {
    editor.deleteFragment();
  }
  const _props = { ...props };
  const nextDay = getNextDay(editor, selection);
  if (nextDay) {
    const nextDate = moment(nextDay[0].date);
    if (nextDate.isSameOrBefore(moment(), 'day')) {
      _props.date = nextDate.subtract(1, 'day').toISOString();
    }
  }
  const prevDay = getPrevDay(editor, selection);
  if (prevDay) {
    const prevDate = moment(prevDay[0].date);
    if (prevDate.isSameOrAfter(moment(), 'day')) {
      _props.date = prevDate.add(1, 'day').toISOString();
    }
  }
  Transforms.insertNodes(editor, [
    generateDay(_props),
    nodeGenerator(BLOCK_TEXT),
  ]);
};

const setDay = (editor, at = editor.selection, props = {}) => {
  Transforms.setNodes(editor, props, { at, match: (n) => isDay(n) });
};

// #############################################################################
// ################################ Interfaces #################################
// #############################################################################
export const Day = {
  isDay,
  getPrevDay,
  getNextDay,
  canInsertDay,
  generator: {
    [BLOCK_DAY]: generateDay,
  },
  insertDay,
  setDay,
};

// #############################################################################
// ################################## Plugin ###################################
// #############################################################################
export const useDay = (editor) => {
  const {
    hasContent,
    isVoid,
    deleteBackward,
    deleteForward,
    htmlToNode,
    nodeToHtml,
  } = editor;

  editor.Day = {
    insertDay: (...args) => Day.insertDay(editor, ...args),
    setDay: (...args) => Day.setDay(editor, ...args),
  };

  editor.hasContent = (at = []) => {
    const [dayMatch] = BaseEditor.nodes(editor, { at, match: (n) => Day.isDay(n) });
    return Boolean(dayMatch) || hasContent?.(at);
  };

  editor.isVoid = (element) => Day.isDay(element) || isVoid(element);

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [dayBefore] = BaseEditor.nodes(editor, {
        at: BaseEditor.before(editor, selection),
        match: (n) => Day.isDay(n),
      });
      const [[currBlock]] = BaseEditor.nodes(editor, {
        match: (n) => editor.isBlock(n),
        mode: 'lowest',
      });
      if (dayBefore && Element.isElement(currBlock, BLOCK_TEXT)) {
        /* Select day for deletion if deleting right before day */
        Transforms.select(editor, BaseEditor.range(editor, selection, dayBefore[1]));
        return;
      }
    }

    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [dayAfter] = BaseEditor.nodes(editor, {
        at: BaseEditor.after(editor, selection),
        match: (n) => Day.isDay(n),
      });
      const [[currBlock]] = BaseEditor.nodes(editor, {
        match: (n) => editor.isBlock(n),
        mode: 'lowest',
      });
      if (dayAfter && Element.isElement(currBlock, BLOCK_TEXT)) {
        /* Select day for deletion if deleting forward right after day */
        Transforms.select(editor, BaseEditor.range(editor, selection, dayAfter[1]));
        return;
      }
    }

    deleteForward(...args);
  };

  editor.htmlToNode = (element, styles = {}, parentage = []) => {
    if (DomUtils.isElementNode(element, 'HR')) {
      return jsx(
        'element',
        Day.generator[BLOCK_DAY]({
          date: element.getAttribute('data-acta-date') ?? timeNow(),
        }),
        [{ text: '' }],
      );
    }

    return htmlToNode?.(element, styles, parentage);
  };

  editor.nodeToHtml = (nodeEntry, at, theme) => {
    const [node] = nodeEntry;

    if (Day.isDay(node)) {
      const htmlNode = document.createElement('hr');
      htmlNode.setAttribute('data-acta-date', node.date);
      return htmlNode;
    }

    return nodeToHtml?.(nodeEntry, at, theme);
  };

  return editor;
};
