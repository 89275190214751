import {
  attachmentSelectors,
  commentSelectors,
  createEntityListSelectorHook,
  createEntitySelectorHook,
  directorySelectors,
  entityActions,
  entitySelectors,
  experimentSelectors,
  noteSelectors,
  permissionSelectors,
  signatureSelectors,
  sopSelectors,
  taskSelectors,
  userSelectors,
} from 'store/entity';
import { useCallback, useEffect, useState } from 'react';

import { ACTA_API_PAGINATION_COMPLETE } from 'constants/event.constants';
import { authSelectors } from 'store/auth';
import { createSelectorHook } from 'store/store.selectors.utils';
import { eventListenerEffect } from '@acheloisbiosoftware/absui.hooks';
import { v4 as generateUuid } from 'uuid';
import { navigationSelectors } from 'store/navigation';
import { pinSelectors } from 'store/entity/pin';
import { preferenceSelectors } from 'store/preference';
import { scopeSelectors } from 'store/scope';
import { useDispatch } from 'react-redux';

/** ************************************************************************ **/
/** ************************** Factory Functions *************************** **/
/** ************************************************************************ **/
export const useFetchListing = (action, { params, condition = true } = {}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [batchId, setBatchId] = useState(null);

  useEffect(() => {
    const promise = { current: null };
    if (condition) {
      const id = generateUuid();
      setLoading(true);
      setBatchId(id);
      promise.current = dispatch(action({
        ...params,
        paginationAPI: { id },
      }));
    }
    return () => {
      promise.current?.abort?.();
      setLoading(false);
      setBatchId(null);
    };
  }, [condition, params, action, dispatch]);

  useEffect(() => eventListenerEffect(document, ACTA_API_PAGINATION_COMPLETE, (event) => {
    const { detail: id } = event;
    if (id === batchId) {
      setLoading(false);
      setBatchId(null);
    }
  }), [batchId]);

  return loading;
};


/** ************************************************************************ **/
/** ********************************* Auth ********************************* **/
/** ************************************************************************ **/
export const useMyId = createSelectorHook(authSelectors.selectMyId);
export const useIsAuthenticated = createSelectorHook(authSelectors.selectIsAuthenticated);
export const useAuthToken = createSelectorHook(authSelectors.selectToken);
export const useAuthAccessToken = createSelectorHook(authSelectors.selectAccessToken);
export const useAuthTokenExpiration = createSelectorHook(authSelectors.selectTokenExpiration);


/** ************************************************************************ **/
/** ******************************** Entity ******************************** **/
/** ************************************************************************ **/
export const useEntity = createSelectorHook(entitySelectors.selectEntity);
export const useEntityHasLoaded = createSelectorHook(entitySelectors.selectEntityHasLoaded);
export const useEntityPermission = createSelectorHook(entitySelectors.selectEntityPermission);
export const useEntityIcon = createSelectorHook(entitySelectors.selectEntityIcon);
export const useUpdateEntity = () => {
  const dispatch = useDispatch();
  return useCallback((...args) => {
    dispatch(entityActions.update(...args));
  }, [dispatch]);
};

/** Attachments ************************************************************ **/
export const useAttachment = createEntitySelectorHook(attachmentSelectors.selectAttachment);
export const useAttachments = createSelectorHook(attachmentSelectors.selectAttachments);
export const useAttachmentList = createEntityListSelectorHook(attachmentSelectors.selectAttachmentList);
export const useAttachmentPermission = createSelectorHook(attachmentSelectors.selectAttachmentPermission);

/** Categories ************************************************************* **/
export const useCategory = createEntitySelectorHook(sopSelectors.selectCategory);
export const useCategoryList = createEntityListSelectorHook(sopSelectors.selectCategoryList);
export const useCategories = createSelectorHook(sopSelectors.selectCategories);

/** Comments *************************************************************** **/
export const useComment = createEntitySelectorHook(commentSelectors.selectComment);
export const useEntityComments = createSelectorHook(commentSelectors.selectEntityComments);
export const useCommentPermission = createSelectorHook(commentSelectors.selectCommentPermission);

/** Departments ************************************************************ **/
export const useDepartment = createEntitySelectorHook(userSelectors.selectDepartment);
export const useDepartmentList = createEntityListSelectorHook(userSelectors.selectDepartmentList);
export const useDepartmentHasLoaded = createEntitySelectorHook(userSelectors.selectDepartmentHasLoaded);
export const useDepartmentPermission = createSelectorHook(userSelectors.selectDepartmentPermission);

/** Directories ************************************************************ **/
export const useDirectoryList = createEntityListSelectorHook(directorySelectors.selectDirectoryList);
export const useDirectory = createEntitySelectorHook(directorySelectors.selectDirectory);
export const useDirectoryHasLoaded = createEntitySelectorHook(directorySelectors.selectDirectoryHasLoaded);
export const useDirectoryPermission = createSelectorHook(directorySelectors.selectDirectoryPermission);
export const useDirectoryChildren = createSelectorHook(directorySelectors.selectDirectoryChildren);

/** Directory Items ******************************************************** **/
export const useDirectoryItems = createSelectorHook(directorySelectors.selectDirectoryItems);
export const useDirectoryItemList = createEntityListSelectorHook(directorySelectors.selectDirectoryItemList);
export const useDirectoryItem = createEntitySelectorHook(directorySelectors.selectDirectoryItem);
export const useDirectoryItemPermission = createSelectorHook(directorySelectors.selectDirectoryItemPermission);

/** Experiments ************************************************************ **/
export const useExperiment = createEntitySelectorHook(experimentSelectors.selectExperiment);
export const useExperimentList = createEntityListSelectorHook(experimentSelectors.selectExperimentList);
export const useExperimentHasLoaded = createEntitySelectorHook(experimentSelectors.selectExperimentHasLoaded);
export const useExperimentPermission = createSelectorHook(experimentSelectors.selectExperimentPermission);

/** Experiment Sections **************************************************** **/
export const useExpSection = createEntitySelectorHook(experimentSelectors.selectSection);

/** Experiment Subsections ************************************************* **/
export const useExpSubsection = createEntitySelectorHook(experimentSelectors.selectSubsection);
export const useExpSubsectionPermission = createSelectorHook(experimentSelectors.selectSubsectionPermission);

/** Master Notes *********************************************************** **/
export const useMasterNote = createEntitySelectorHook(directorySelectors.selectMasterNote);
export const useMasterNoteList = createEntityListSelectorHook(directorySelectors.selectMasterNoteList);
export const useMasterNotePermission = createSelectorHook(directorySelectors.selectMasterNotePermission);

/** Master Note Items ****************************************************** **/
export const useMasterNoteItem = createEntitySelectorHook(directorySelectors.selectMasterNoteItem);

/** Master Note Sections *************************************************** **/
export const useMasterNoteSection = createEntitySelectorHook(directorySelectors.selectMasterNoteSection);

/** Notes ****************************************************************** **/
export const useNote = createEntitySelectorHook(noteSelectors.selectNote);
export const useNoteList = createEntityListSelectorHook(noteSelectors.selectNoteList);
export const useNoteHasLoaded = createEntitySelectorHook(noteSelectors.selectNoteHasLoaded);
export const useNotePermission = createSelectorHook(noteSelectors.selectNotePermission);

/** Permissions ************************************************************ **/
export const usePermission = createEntitySelectorHook(permissionSelectors.selectPermission);
export const usePermissionList = createEntityListSelectorHook(permissionSelectors.selectPermissionList);
export const useMyGlobalPermission = createSelectorHook(permissionSelectors.selectMyGlobalPermission);

/** Permission Relationships *********************************************** **/
export const usePermissionRelationship = createEntitySelectorHook(permissionSelectors.selectPermissionRelationship);
export const usePermissionRelationshipPermission = createSelectorHook(permissionSelectors.selectPermissionRelationshipPermission);

/** Pins ******************************************************************* **/
export const usePin = createEntitySelectorHook(pinSelectors.selectPin);
export const usePinList = createEntityListSelectorHook(pinSelectors.selectPinList);

/** Signatures ************************************************************* **/
export const useSignature = createEntitySelectorHook(signatureSelectors.selectSignature);
export const useSignatureList = createEntityListSelectorHook(signatureSelectors.selectSignatureList);
export const useSignaturePermission = createSelectorHook(signatureSelectors.selectSignaturePermission);

/** Signature Workflows **************************************************** **/
export const useSignatureWorkflow = createEntitySelectorHook(signatureSelectors.selectSignatureWorkflow);
export const useSignatureWorkflowPermission = createSelectorHook(signatureSelectors.selectSignatureWorkflowPermission);

/** Signature Workflow Steps *********************************************** **/
export const useSignatureWorkflowStep = createEntitySelectorHook(signatureSelectors.selectSignatureWorkflowStep);
export const useSignatureWorkflowStepActivity = createSelectorHook(signatureSelectors.selectSignatureWorkflowStepActivity);
export const useSignatureWorkflowStepPermission = createSelectorHook(signatureSelectors.selectSignatureWorkflowStepPermission);

/** SOPs ******************************************************************* **/
export const useSop = createEntitySelectorHook(sopSelectors.selectSop);
export const useSopList = createEntityListSelectorHook(sopSelectors.selectSopList);
export const useSopHasLoaded = createEntitySelectorHook(sopSelectors.selectSopHasLoaded);
export const useSopPermission = createSelectorHook(sopSelectors.selectSopPermission);

/** SOP Sections *********************************************************** **/
export const useSopSection = createEntitySelectorHook(sopSelectors.selectSection);

/** SOP Subsections ******************************************************** **/
export const useSopSubsection = createEntitySelectorHook(sopSelectors.selectSubsection);

/** Tasks ****************************************************************** **/
export const useTask = createEntitySelectorHook(taskSelectors.selectTask);
export const useTaskList = createEntityListSelectorHook(taskSelectors.selectTaskList);
export const useTaskHasLoaded = createEntitySelectorHook(taskSelectors.selectTaskHasLoaded);
export const useTaskPermission = createSelectorHook(taskSelectors.selectTaskPermission);

/** Teams ****************************************************************** **/
export const useTeam = createEntitySelectorHook(userSelectors.selectTeam);
export const useTeamList = createEntityListSelectorHook(userSelectors.selectTeamList);
export const useTeamHasLoaded = createEntitySelectorHook(userSelectors.selectTeamHasLoaded);
export const useTeamPermission = createSelectorHook(userSelectors.selectTeamPermission);

/** Users ****************************************************************** **/
export const useUser = createEntitySelectorHook(userSelectors.selectUser);
export const useUserList = createEntityListSelectorHook(userSelectors.selectUserList);
export const useUsers = createSelectorHook(userSelectors.selectUsers);
export const useUserHasLoaded = createEntitySelectorHook(userSelectors.selectUserHasLoaded);
export const useUserName = createSelectorHook(userSelectors.selectUserName);
export const useUserInitials = createSelectorHook(userSelectors.selectUserInitials);
export const useUserPermission = createSelectorHook(userSelectors.selectUserPermission);

/** User Roles ************************************************************* **/
export const useUserRole = createEntitySelectorHook(userSelectors.selectUserRole);
export const useUserRoleList = createEntityListSelectorHook(userSelectors.selectUserRoleList);
export const useUserRoleHasLoaded = createEntitySelectorHook(userSelectors.selectUserRoleHasLoaded);
export const useUserRolePermission = createSelectorHook(userSelectors.selectUserRolePermission);


/** ************************************************************************ **/
/** ****************************** Navigation ****************************** **/
/** ************************************************************************ **/
export const useSpaceType = createSelectorHook(navigationSelectors.selectSpaceType);
export const useSpaceGenericId = createSelectorHook(navigationSelectors.selectSpaceGenericId);
export const useRecentlyViewed = createSelectorHook(navigationSelectors.selectRecentlyViewed);


/** ************************************************************************ **/
/** ****************************** Preference ****************************** **/
/** ************************************************************************ **/
export const useAppearance = createSelectorHook(preferenceSelectors.selectAppearance);


/** ************************************************************************ **/
/** ******************************** Scope ********************************* **/
/** ************************************************************************ **/
export const useScopeVariables = createSelectorHook(scopeSelectors.selectScopeVariables);
export const useScopeVariableList = createSelectorHook(scopeSelectors.selectScopeVariableList);
