import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const syncMiddlewareConfig = {
  channel: 'acta_broadcast_channel',
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware(syncMiddlewareConfig)),
});
initStateWithPrevTab(store);
(window.__acta__ ??= {}).store = store; // For troubleshotting

export default store;
