import { ENTITY_SIGNATURE } from 'constants/schemas';
import PropTypes from 'prop-types';
import React from 'react';
import SignatureWorkflow from 'components/SignatureWorkflow';
import SopSectionBase from './SopSectionBase';
import { formatType } from 'utils/entity.utils';

function SignaturesSection(props) {
  const { signatureWorkflowId } = props;
  return (
    <SopSectionBase
      containerProps={{ id: 'section_signatureWorkflow' }}
      sectionHeaderProps={{
        title: formatType(ENTITY_SIGNATURE, { plural: true }),
      }}
    >
      <SignatureWorkflow
        id={signatureWorkflowId}
        stepperProps={{ sx: { mt: 1 }}}
      />
    </SopSectionBase>
  );
}

SignaturesSection.propTypes = {
  signatureWorkflowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default SignaturesSection;
