import { MODE_DARK, MODE_LIGHT, MODE_SYSTEM } from 'components/ThemeProvider';
import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Field from 'components/Field';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { preferenceActions } from 'store/preference';
import { useAppearance } from 'hooks/store.hooks';
import { useDispatch } from 'react-redux';

const modeOptions = [
  { value: MODE_LIGHT, label: 'Light', Icon: LightModeIcon },
  { value: MODE_SYSTEM, label: 'System', Icon: SettingsBrightnessIcon },
  { value: MODE_DARK, label: 'Dark', Icon: DarkModeIcon },
];

function ProfilePreferences() {
  const appearance = useAppearance();
  const dispatch = useDispatch();

  const onChange = useCallback((e, newAppearance) => {
    if (newAppearance) {
      dispatch(preferenceActions.setPreference({ key: 'appearance', value: newAppearance }));
    }
  }, [dispatch]);

  return (
    <Box>
      <Field label='Appearance' sx={{ mt: 0 }}>
        <ToggleButtonGroup
          color='primary'
          size='small'
          value={appearance}
          exclusive
          onChange={onChange}
          sx={{ my: 0.5 }}
        >
          { modeOptions.map(({ value, label, Icon }) => (
            <ToggleButton key={value} value={value}>
              <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                <Icon fontSize='small' sx={{ mx: 1 }} />
                {label}
              </Box>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Field>
    </Box>
  );
}

export default ProfilePreferences;
