import { ENTITY_DIRECTORY, ENTITY_MASTER_NOTE, ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import {
  MASTER_NOTE_NAV_INIT_WIDTH,
  MASTER_NOTE_NAV_MAX_WIDTH,
  MASTER_NOTE_NAV_MIN_WIDTH,
} from './MasterNote.constants';
import React, { useCallback, useMemo, useState } from 'react';
import { directoryActions, directorySelectors } from 'store/entity';
import { useDirectory, useMasterNote, useUpdateEntity } from 'hooks/store.hooks';

import { AddIcon } from 'constants/icon.constants';
import Avatar from 'components/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import NavDrawer from 'components/NavDrawer';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TemplateChip from 'components/TemplateChip';
import Typography from '@mui/material/Typography';
import { createSelector } from '@reduxjs/toolkit';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { scrollTo } from 'utils/dom.utils';
import { useDispatch } from 'react-redux';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';

const MASTER_NOTE_OVERVIEW = 'overview';
const NEW_SECTION = 'newSection';

function MasterNoteNav(props) {
  const { id: masterNoteId, loading } = props;
  const dispatch = useDispatch();
  const masterNoteSections = useMasterNote(masterNoteId, 'sections');
  const parent = useMasterNote(masterNoteId, 'parent');
  const directoryName = useDirectory(parent, 'name');
  const directoryCode = useDirectory(parent, 'directory_code');
  const isTemplate = useDirectory(parent, 'is_template');
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const sections = [
    MASTER_NOTE_OVERVIEW,
    ...(masterNoteSections ?? []),
    ...(!isReadOnly ? [NEW_SECTION] : []),
  ];

  const onDragEnd = (event) => {
    const { active, over } = event;
    const reorderedSections = [...masterNoteSections];
    const oldIdx = reorderedSections.indexOf(active.id);
    const newIdx = reorderedSections.indexOf(over.id);
    const movedSection = reorderedSections.splice(oldIdx, 1)[0];
    reorderedSections.splice(newIdx, 0, movedSection);
    update({ type: ENTITY_MASTER_NOTE, id: masterNoteId, key: 'sections', value: reorderedSections });
  };

  const [createSectionLoading, setCreateSectionLoading] = useState(false);
  const createSection = useCallback(() => {
    setCreateSectionLoading(true);
    dispatch(directoryActions.createMasterNoteSection({ id: masterNoteId }))
      .unwrap()
      .then((res) => {
        if (isDefined(res?.result) && isDefined(res?.entities)) {
          const { entities, result } = res;
          const newSections = entities[ENTITY_MASTER_NOTE]?.[result]?.sections ?? [];
          scrollTo(`section_${newSections[newSections.length - 1]}`);
        }
      })
      .catch(() => null)
      .finally(() => setCreateSectionLoading(false));
  }, [dispatch, masterNoteId]);

  const clickHandlerSection = (id) => () => {
    if (id !== NEW_SECTION) {
      scrollTo(`section_${id}`);
    }
  };

  const sectionSelector = useMemo(() => createSelector(
    (state, id) => id,
    (state, id) => directorySelectors.selectMasterNoteSection(state, id),
    (id, section) => {
      if (id === MASTER_NOTE_OVERVIEW) {
        return {
          id,
          icon: (<Avatar>O</Avatar>),
          text: 'Overview',
          onClick: clickHandlerSection(id),
        };
      }

      if (id === NEW_SECTION) {
        return {
          id,
          icon: (
            <>
              <AddIcon sx={{ color: 'text.tertiary' }} />
              {createSectionLoading ? (<CircularProgress size={24} sx={{ position: 'absolute' }} />) : null}
            </>
          ),
          text: `Add New ${formatType(ENTITY_MASTER_NOTE_SECTION)}`,
          onClick: createSection,
          sx: { color: 'text.tertiary' },
          listItemButtonProps: { disabled: createSectionLoading },
        };
      }

      const { title } = section ?? {};
      const sequence = (masterNoteSections ?? []).findIndex((_id) => String(_id) === String(id));
      return {
        id,
        icon: sequence !== -1 ? (<Avatar>{sequence + 1}</Avatar>) : <Avatar>{' '}</Avatar>,
        text: title ?? '',
        onClick: clickHandlerSection(id),
        draggable: !isReadOnly,
      };
    },
  ), [isReadOnly, createSection, masterNoteSections, createSectionLoading]);

  return (
    <NavDrawer
      header={(
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Stack sx={{ overflow: 'hidden' }}>
            <Typography variant='inherit' noWrap>{directoryName}</Typography>
            <Typography variant='caption' noWrap>{directoryCode}</Typography>
          </Stack>
          { isTemplate ? (<TemplateChip type={ENTITY_DIRECTORY} size='small' sx={{ ml: 1 }} />) : null }
        </Stack>
      )}
      sections={sections}
      sectionSelector={sectionSelector}
      onDragEnd={onDragEnd}
      initWidth={MASTER_NOTE_NAV_INIT_WIDTH}
      minWidth={MASTER_NOTE_NAV_MIN_WIDTH}
      maxWidth={MASTER_NOTE_NAV_MAX_WIDTH}
      loading={loading}
    />
  );
}

MasterNoteNav.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
};

export default MasterNoteNav;
