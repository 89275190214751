import { BLOCK_CALCULATION, BLOCK_INPUT, VARIABLE_UPDATE_EVENT } from './Variable.constants';
import { DomUtils, getThemeValue, isDefined, round } from '@acheloisbiosoftware/absui.utils';
import { all, create } from 'mathjs';
import { useMyId, useScopeVariables } from 'hooks/store.hooks';

import { EditorPlugins } from '@acheloisbiosoftware/absui.core';
import { v4 as generateUuid } from 'uuid';
import { jsx } from 'slate-hyperscript';
import { scopeActions } from 'store/scope';
import { timeNow } from 'utils/date.utils';
import { useDispatch } from 'react-redux';
import { useEntityScope } from 'hooks/scope.hooks';

const math = create(all);
const { BaseEditor, Transforms, Range, Element } = EditorPlugins;

// #############################################################################
// ######################### Retrieval & Check Methods #########################
// #############################################################################
const isInput = (n, uuid) => (
  Element.isElement(n, BLOCK_INPUT) &&
  (!isDefined(uuid) || n.uuid === uuid)
);

const isCalculation = (n, uuid) => (
  Element.isElement(n, BLOCK_CALCULATION) &&
  (!isDefined(uuid) || n.uuid === uuid)
);

/** NOTE: if this is changed, be sure to also update store/scope/scope.selectors */
const isVariable = (n, uuid) => isInput(n, uuid) || isCalculation(n, uuid);

const getFormulaVariableUuids = (formula) => [...formula.matchAll(/¿(.*?)¿/g)].map((match) => match[1]);
const _validValue = (node) => (
  isDefined(node?.value) && node.value !== ''
);

const validFormulaVariables = (editor, formula, scope = editor.Variable.scope) => {
  const dependencies = getFormulaVariableUuids(formula);
  for (const dependency of dependencies) {
    const depNode = scope[dependency];
    if (!isDefined(depNode)) return false;
  }
  return true;
};

const validFormulaValues = (editor, formula, scope = editor.Variable.scope) => {
  const dependencies = getFormulaVariableUuids(formula);
  for (const dependency of dependencies) {
    const depNode = scope[dependency];
    if (!_validValue(depNode)) return false;
  }
  return true;
};

const prettifyFormula = (editor, formula, scope = editor.Variable.scope) => {
  const dependencies = getFormulaVariableUuids(formula);
  let prettyFormula = formula;
  for (const dependency of dependencies) {
    const depNode = scope[dependency];
    prettyFormula = prettyFormula.replace(`¿${dependency}¿`, depNode?.label ? depNode.label : '#REF!');
  }
  return prettyFormula;
};

const evaluableFormula = (editor, formula, scope = editor.Variable.scope) => {
  const dependencies = getFormulaVariableUuids(formula);
  let evalFormula = formula;
  for (const dependency of dependencies) {
    const depNode = scope[dependency];
    if (_validValue(depNode)) {
      const value = (isInput(depNode) && depNode.inputType === 'text') ? `'${depNode.value}'` : depNode.value;
      evalFormula = evalFormula.replace(`¿${dependency}¿`, value);
    }
  }
  return evalFormula;
};

const validFormula = (editor, formula, scope = editor.Variable.scope) => {
  const evalFormula = evaluableFormula(editor, formula, scope);
  try {
    math.evaluate(evalFormula);
    return true;
  } catch {
    return false;
  }
};

const executeFormula = (editor, formula, scope = editor.Variable.scope) => {
  const evalFormula = evaluableFormula(editor, formula, scope);
  try {
    return round(math.evaluate(evalFormula), 0.001);
  } catch {
    return '#ERROR!';
  }
};

const displayFormula = (editor, formula, scope = editor.Variable.scope) => (validFormulaValues(editor, formula, scope) ? executeFormula(editor, formula, scope) : prettifyFormula(editor, formula, scope));

// #############################################################################
// ############################# Generate Methods ##############################
// #############################################################################
const generateInput = (props) => ({
  type: BLOCK_INPUT,
  uuid: generateUuid(),
  label: '',
  inputType: 'text',
  units: '',
  value: '',
  inputted: null,
  children: [{ text: '' }],
  ...props,
});

const generateCalculation = (props) => ({
  type: BLOCK_CALCULATION,
  uuid: generateUuid(),
  label: '',
  units: '',
  formula: '',
  value: null,
  children: [{ text: '' }],
  ...props,
});

// #############################################################################
// ########################### Manipulation Methods ############################
// #############################################################################
const insertInput = (editor, props) => {
  const { selection } = editor;
  if (Range.isExpanded(selection)) {
    editor.deleteFragment();
  }
  const inputNode = generateInput(props);
  Transforms.insertNodes(editor, inputNode);
  Transforms.move(editor);
  editor.Variable.updateScopeVariable(inputNode.uuid, inputNode);
};

const setInput = (editor, uuid, props) => {
  Transforms.setNodes(editor, props, {
    at: [],
    match: (n) => isInput(n, uuid),
  });
};

const onInputChange = (editor, uuid, value, props) => {
  const options = { at: [], match: (n) => isInput(n, uuid) };
  Transforms.setNodes(editor, { value, ...props }, options);

  const [updatedNodeMatch] = BaseEditor.nodes(editor, options);
  if (!updatedNodeMatch) return;
  const [updatedNode] = updatedNodeMatch;
  editor.Variable.updateScopeVariable(uuid, updatedNode);
  document.dispatchEvent(new CustomEvent(VARIABLE_UPDATE_EVENT, { detail: updatedNode }));
};

const insertCalculation = (editor, props) => {
  const { selection } = editor;
  if (Range.isExpanded(selection)) {
    editor.deleteFragment();
  }
  const calculationNode = generateCalculation(props);
  Transforms.insertNodes(editor, calculationNode);
  Transforms.move(editor);
  editor.Variable.updateScopeVariable(calculationNode.uuid, calculationNode);
  editor.Variable.updateCalculation(calculationNode.uuid);
};

const setCalculation = (editor, uuid, props) => {
  Transforms.setNodes(editor, props, {
    at: [],
    match: (n) => isCalculation(n, uuid),
  });
};

const updateCalculation = (editor, uuid, updatedDependency) => {
  const [calcMatch] = BaseEditor.nodes(editor, {
    at: [],
    match: (n) => isCalculation(n, uuid),
  });
  if (!calcMatch) return;
  const [calcNode, calcPath] = calcMatch;
  const { formula } = calcNode;
  const { scope, updateScopeVariable } = editor.Variable;
  const newScope = { ...scope };
  if (isDefined(updatedDependency)) {
    newScope[updatedDependency.uuid] = updatedDependency;
    updateScopeVariable(updatedDependency.uuid, updatedDependency);
  }
  const value = validFormulaValues(editor, formula, newScope) ? executeFormula(editor, formula, newScope) : '';
  Transforms.setNodes(editor, { value }, {
    at: calcPath,
    match: (n) => isCalculation(n, uuid),
  });
  document.dispatchEvent(new CustomEvent(VARIABLE_UPDATE_EVENT, { detail: { ...calcNode, value }}));
};

// #############################################################################
// ################################ Interfaces #################################
// #############################################################################
export const Variable = {
  isInput,
  isCalculation,
  isVariable,
  getFormulaVariableUuids,
  validFormulaVariables,
  validFormulaValues,
  prettifyFormula,
  evaluableFormula,
  validFormula,
  executeFormula,
  displayFormula,
  generator: {
    [BLOCK_INPUT]: generateInput,
    [BLOCK_CALCULATION]: generateCalculation,
  },
  onInputChange,
  insertInput,
  setInput,
  updateCalculation,
  insertCalculation,
  setCalculation,
};

// #############################################################################
// ################################## Plugin ###################################
// #############################################################################
export function useVariables(editor) {
  const {
    isInline,
    isVoid,
    hasContent,
    htmlToNode,
    nodeToHtml,
  } = editor;

  const entityScope = useEntityScope();
  const scope = useScopeVariables(entityScope);
  const dispatch = useDispatch();
  const user = useMyId();

  editor.Variable = {
    scope,
    updateScopeVariable: (uuid, variable) => dispatch(scopeActions.updateScopeVariable({ genericId: entityScope, uuid, variable })),
    insertInput: (...args) => Variable.insertInput(editor, ...args),
    setInput: (...args) => Variable.setInput(editor, ...args),
    onInputChange: (uuid, value, props) => Variable.onInputChange(editor, uuid, value, {
      ...props,
      inputted: { user, at: timeNow() },
    }),
    insertCalculation: (...args) => Variable.insertCalculation(editor, ...args),
    setCalculation: (...args) => Variable.setCalculation(editor, ...args),
    updateCalculation: (...args) => Variable.updateCalculation(editor, ...args),
  };

  editor.isInline = (element) => Variable.isVariable(element) || isInline(element);
  editor.isVoid = (element) => Variable.isVariable(element) || isVoid(element);

  editor.hasContent = (at = []) => {
    const [variableNode] = BaseEditor.nodes(editor, {
      at,
      match: (n) => Variable.isVariable(n),
    });
    return Boolean(variableNode) || hasContent?.(at);
  };

  editor.htmlToNode = (element, styles = {}, parentage = []) => {
    if (DomUtils.isElementNode(element, 'SPAN')) {
      if (element.getAttribute('data-acta-type') === BLOCK_INPUT) {
        return jsx(
          'element',
          Variable.generator[BLOCK_INPUT]({
            label: element.getAttribute('data-acta-label') ?? '',
            inputType: element.getAttribute('data-acta-inputType') ?? 'text',
            units: element.getAttribute('data-acta-units') ?? '',
            value: element.getAttribute('data-acta-value') ?? '',
          }),
          [{ text: '' }],
        );
      }
      if (element.getAttribute('data-acta-type') === BLOCK_CALCULATION) {
        return jsx(
          'element',
          Variable.generator[BLOCK_CALCULATION]({
            label: element.getAttribute('data-acta-label') ?? '',
            units: element.getAttribute('data-acta-units') ?? '',
            formula: element.getAttribute('data-acta-formula') ?? '',
            value: element.getAttribute('data-acta-value') ?? '',
          }),
          [{ text: '' }],
        );
      }
    }

    return htmlToNode?.(element, styles, parentage);
  };

  editor.nodeToHtml = (nodeEntry, at, theme) => {
    const [node] = nodeEntry;

    if (Variable.isVariable(node)) {
      const _isInput = Variable.isInput(node);
      const _isCalc = Variable.isCalculation(node);

      const htmlNode = document.createElement('span');
      htmlNode.setAttribute('data-acta-type', node.type);
      htmlNode.setAttribute('data-acta-label', node.label);
      htmlNode.setAttribute('data-acta-units', node.units);
      htmlNode.setAttribute('data-acta-value', node.value);

      if (_isInput) htmlNode.setAttribute('data-acta-inputType', node.inputType);
      if (_isCalc) htmlNode.setAttribute('data-acta-formula', node.formula);

      const borderColor = getThemeValue(theme, 'textfieldOutline', 'palette');
      const secondaryText = getThemeValue(theme, 'text.secondary', 'palette');
      const captionTypography = getThemeValue(theme, 'caption', 'typography');
      const fieldsetNode = document.createElement('fieldset');
      fieldsetNode.setAttribute('data-absui-void', true);
      DomUtils.applyCss(fieldsetNode, {
        display: 'inline',
        padding: '8px',
        borderRadius: '4px',
        borderColor,
        paddingTop: node.label ? 0 : '0.5rem',
        minWidth: '100px',
        minHeight: '1rem',
      });
      if (node.label) {
        const labelNode = document.createElement('legend');
        DomUtils.applyCss(labelNode, { ...captionTypography, marginBottom: '0px', color: secondaryText });
        const labelText = document.createTextNode(node.label);
        labelNode.appendChild(labelText);
        fieldsetNode.appendChild(labelNode);
      }
      const fieldsetContent = document.createElement('div');
      DomUtils.applyCss(fieldsetContent, { display: 'flex' });
      const valueNode = document.createElement('span');
      if (_isInput) valueNode.appendChild(document.createTextNode(`${node.value}\u00A0`));
      if (_isCalc) valueNode.appendChild(document.createTextNode(displayFormula(editor, node.formula)));
      fieldsetContent.appendChild(valueNode);
      if (node.units) {
        const unitNode = document.createElement('span');
        DomUtils.applyCss(unitNode, {
          color: secondaryText,
          paddingLeft: '8px',
          marginLeft: 'auto',
          marginRight: 0,
        });
        unitNode.appendChild(document.createTextNode(` ${node.units}`));
        fieldsetContent.appendChild(unitNode);
      }
      fieldsetNode.appendChild(fieldsetContent);
      htmlNode.appendChild(fieldsetNode);

      return htmlNode;
    }

    return nodeToHtml?.(nodeEntry, at, theme);
  };

  return editor;
}
