import { ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import { useMyGlobalPermission, useUpdateEntity, useUserRole, useUserRoleHasLoaded } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import UserTable from 'components/UserTable';
import { formatType } from 'utils/entity.utils';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';

function UserRoleUsers(props) {
  const { id } = props;
  const update = useUpdateEntity();
  const isReadOnly = useEntityIsReadOnly();
  const userIds = useUserRole(id, 'users');
  const userIdsHasLoaded = useUserRoleHasLoaded(id, 'users');
  const canViewUser = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);

  const onAssign = (selection) => {
    update({ type: ENTITY_USER_ROLE, id, key: 'users', value: selection });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant='h6'>Associated {formatType(ENTITY_USER, { plural: true })}</Typography>
      <UserTable
        userIds={userIds ?? []}
        loading={!userIdsHasLoaded}
        onAssign={!isReadOnly && canViewUser ? onAssign : null}
        initAssignment={userIds ?? []}
        sx={{
          border: 1,
          borderColor: 'divider',
          borderRadius: 2,
        }}
      />
    </Box>
  );
}

UserRoleUsers.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserRoleUsers;
