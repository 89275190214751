import { EntityReadOnlyContext, useEntityIsReadOnly, withEntity } from 'hooks/Entity.hooks';
import React, { useState } from 'react';
import { userActions, userSelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import UserActions from './UserActions';
import UserFields from './UserFields';
import UserHeader from './UserHeader';

function User(props) {
  const { id } = props;
  const viewOnly = useEntityIsReadOnly();
  const [editing, setEditing] = useState(false);

  return (
    <EntityReadOnlyContext.Provider value={viewOnly || !editing}>
      <Box sx={{ px: 5, py: 4, flexGrow: 1, display: 'flex' }}>
        <Box sx={{ width: 400, mx: 'auto' }}>
          <UserHeader id={id} />
          <UserFields id={id} />
          <UserActions
            id={id}
            viewOnly={viewOnly}
            editingState={{ editing, setEditing }}
          />
        </Box>
      </Box>
    </EntityReadOnlyContext.Provider>
  );
}

User.propTypes = {
  id: PropTypes.number.isRequired,
};

export default withEntity(User, {
  fetchAction: userActions.fetchUser,
  hasLoadedSelector: userSelectors.selectUserHasLoaded,
  readOnlySelector: (state, id) => !userSelectors.selectUserPermission(state, id, ACTION_UPDATE),
});
