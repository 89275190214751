import * as PinService from 'services/PinService';
import * as pinSelectors from './pin.selectors';
import * as schemas from 'constants/schemas';

import { createServiceThunk } from 'store/store.actions.utils';

export const fetchPins = createServiceThunk({
  name: `${schemas.ENTITY_PIN}/fetchPins`,
  service: PinService.fetchPins,
  schema: [schemas.pin],
  isPaginated: true,
});

export const createPin = createServiceThunk({
  name: `${schemas.ENTITY_PIN}/createPin`,
  service: PinService.createPin,
  schema: schemas.pin,
});

export const fetchPin = createServiceThunk({
  name: `${schemas.ENTITY_PIN}/fetchPin`,
  service: PinService.fetchPin,
  schema: schemas.pin,
});

export const patchPin = createServiceThunk({
  name: `${schemas.ENTITY_PIN}/patchPin`,
  service: PinService.patchPin,
  schema: schemas.pin,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: pinSelectors.selectPinUpdates(getState(), id),
  }),
}, {
  condition: ({ id }, { getState }) => pinSelectors.selectPinHasUpdates(getState(), id),
});

export const deletePin = createServiceThunk({
  name: `${schemas.ENTITY_PIN}/deletePin`,
  service: PinService.deletePin,
  schema: schemas.pin,
});
