import { ENTITY_EXPERIMENT, ENTITY_SIGNATURE } from 'constants/schemas';
import React, { useMemo } from 'react';
import { useExperiment, useExperimentHasLoaded } from 'hooks/store.hooks';

import NavDrawer from 'components/NavDrawer';
import PropTypes from 'prop-types';
import { SECTION_ICONS } from 'constants/sop.constants';
import { SignatureIcon } from 'constants/icon.constants';
import Stack from '@mui/material/Stack';
import TemplateChip from 'components/TemplateChip';
import Typography from '@mui/material/Typography';
import { createSelector } from '@reduxjs/toolkit';
import { experimentSelectors } from 'store/entity';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { scrollTo } from 'utils/dom.utils';

const EXP_DETAILS = 'details';
const SIGNATURE_WORKFLOW = 'signatureWorkflow';

function ExperimentNav(props) {
  const { expId, loading } = props;
  const experiment = useExperiment(expId);
  const {
    section_name: detailSectionName,
    sections: sectionIds,
    signature_workflow: signatureWorkflow,
    title: expTitle,
    exp_code: expCode,
    is_template: isTemplate,
  } = experiment ?? {};
  const {
    detailSectionNameHasLoaded,
    sectionIdsHasLoaded,
    signatureWorkflowHasLoaded,
    expTitleHasLoaded,
    expCodeHasLoaded,
  } = useExperimentHasLoaded(expId, {
    detailSectionNameHasLoaded: 'section_name',
    sectionIdsHasLoaded: 'sections',
    signatureWorkflowHasLoaded: 'signature_workflow',
    expTitleHasLoaded: 'title',
    expCodeHasLoaded: 'exp_code',
  });

  const sections = [
    ...(detailSectionName ? [EXP_DETAILS] : []),
    ...(sectionIds ?? []),
    ...(isDefined(signatureWorkflow) ? [SIGNATURE_WORKFLOW] : []),
  ];

  const sectionSelector = useMemo(() => createSelector(
    (state, id) => id,
    (state, id) => experimentSelectors.selectSection(state, id),
    (id, section) => {
      if (id === EXP_DETAILS) {
        return {
          id,
          icon: SECTION_ICONS.parameters,
          text: `1. ${detailSectionName}`,
          onClick: () => scrollTo(`section_${id}`),
        };
      }

      if (id === SIGNATURE_WORKFLOW) {
        return {
          id,
          icon: (<SignatureIcon />),
          text: formatType(ENTITY_SIGNATURE, { plural: true }),
          onClick: () => scrollTo(`section_${id}`),
        };
      }

      return {
        id,
        icon: SECTION_ICONS[section.image],
        text: `${section.sequence + 1}. ${section.title}`,
        onClick: () => scrollTo(`section_${id}`),
        subsections: section.subsections,
      };
    },
  ), [detailSectionName]);

  const subsectionSelector = useMemo(() => createSelector(
    (state, id) => id,
    (state, id) => experimentSelectors.selectSubsection(state, id),
    (state, id) => experimentSelectors.selectSection(state, experimentSelectors.selectSubsection(state, id, 'section')),
    (id, subsection, section) => ({
      id,
      text: `${section.sequence + 1}.${subsection.sequence}. ${subsection.title}`,
      onClick: () => scrollTo(`subsection_${id}`),
    }),
  ), []);

  return (
    <NavDrawer
      header={(
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Stack sx={{ overflow: 'hidden' }}>
            <Typography variant='inherit' noWrap>{expTitle}</Typography>
            <Typography variant='caption' noWrap>{expCode}</Typography>
          </Stack>
          { isTemplate ? (<TemplateChip type={ENTITY_EXPERIMENT} size='small' sx={{ ml: 1 }} />) : null}
        </Stack>
      )}
      sections={sections}
      sectionSelector={sectionSelector}
      subsectionSelector={subsectionSelector}
      loading={
        loading ||
        !detailSectionNameHasLoaded ||
        !sectionIdsHasLoaded ||
        !signatureWorkflowHasLoaded ||
        !expTitleHasLoaded ||
        !expCodeHasLoaded
      }
    />
  );
}

ExperimentNav.propTypes = {
  expId: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default ExperimentNav;
