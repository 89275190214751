export const SUPER_ADMIN = 'SUPER_ADMIN';
export const SCIENTIST = 'SCIENTIST';
export const QUALITY_ASSURANCE = 'QUALITY_ASSURANCE';
export const USER_ROLES = [SUPER_ADMIN, SCIENTIST, QUALITY_ASSURANCE];

export const MALE = 'MALE';
export const FEMALE = 'FEMALE';
export const UNKNOWN = 'UNKNOWN';
export const GENDERS = [MALE, FEMALE, UNKNOWN];

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
