import { ENTITY_DEPARTMENT, ENTITY_SOP, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import React, { useMemo } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { useEntity, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { GenericProp } from 'store/entity';
import ListingPage from 'components/ListingPage';
import SopsContent from './SopsContent';
import SopsTable from './SopsTable';
import { formatType } from 'utils/entity.utils';

const parseSelected = (selected) => parseFloat(selected);
const validateSelected = (selected) => !isNaN(selected);

const nameProp = new GenericProp({
  [ENTITY_USER]: 'full_name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_DEPARTMENT]: 'name',
});

function Sops() {
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const individualName = useEntity(spaceGenericId, nameProp);
  const sopDisplayName = formatType(ENTITY_SOP, { plural: true });
  const title = spaceType === SPACE_USER ? (
    individualName ? `${individualName}'s ${sopDisplayName}` : null
  ) : [SPACE_TEAM, SPACE_DEPARTMENT].includes(spaceType) ? (
    individualName ? `${individualName} ${sopDisplayName}` : null
  ) : `All ${sopDisplayName}`;

  const sectionHeaderProps = useMemo(() => ({
    title,
    skeletonProps: { width: 300 },
  }), [title]);

  return (
    <ListingPage
      parseSelected={parseSelected}
      validateSelected={validateSelected}
      sectionHeaderProps={sectionHeaderProps}
      tableComponent={SopsTable}
      contentComponent={SopsContent}
    />
  );
}

export default Sops;
