import { AddIcon, SignatureIcon } from 'constants/icon.constants';
import { ENTITY_SIGNATURE, ENTITY_SOP, ENTITY_SOP_SECTION } from 'constants/schemas';
import {
  ICON_DATA,
  ICON_MATERIAL,
  ICON_PROCEDURE,
  ICON_QC,
  SECTION_ICONS,
  VIEW_DATA,
  VIEW_MATERIAL,
  VIEW_NEW_SECTION,
  VIEW_PROCEDURE,
  VIEW_QC,
} from 'constants/sop.constants';
import React, { useCallback, useMemo, useState } from 'react';
import { sopActions, sopSelectors } from 'store/entity';
import { useSop, useSopHasLoaded, useUpdateEntity } from 'hooks/store.hooks';

import CircularProgress from '@mui/material/CircularProgress';
import NavDrawer from 'components/NavDrawer';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TemplateChip from 'components/TemplateChip';
import Typography from '@mui/material/Typography';
import { createSelector } from '@reduxjs/toolkit';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { scrollTo } from 'utils/dom.utils';
import { useDispatch } from 'react-redux';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';

const SOP_PARAMETERS = 'parameters';
const NEW_SECTION = 'newSection';
const SIGNATURE_WORKFLOW = 'signatureWorkflow';

const NEW_SECTION_SUBSECTIONS = {
  [VIEW_MATERIAL]: {
    label: `New Material ${formatType(ENTITY_SOP_SECTION)}`,
    image: ICON_MATERIAL,
    title: `New Material ${formatType(ENTITY_SOP_SECTION)}`,
  },
  [VIEW_PROCEDURE]: {
    label: `New Procedure ${formatType(ENTITY_SOP_SECTION)}`,
    image: ICON_PROCEDURE,
    title: `New Procedure Overview ${formatType(ENTITY_SOP_SECTION)}`,
  },
  [VIEW_DATA]: {
    label: `New Data ${formatType(ENTITY_SOP_SECTION)}`,
    image: ICON_DATA,
    title: `New Data ${formatType(ENTITY_SOP_SECTION)}`,
  },
  [VIEW_QC]: {
    label: `New QC ${formatType(ENTITY_SOP_SECTION)}`,
    image: ICON_QC,
    title: `New Quality Control ${formatType(ENTITY_SOP_SECTION)}`,
  },
  [VIEW_NEW_SECTION]: {
    label: `New Custom ${formatType(ENTITY_SOP_SECTION)}`,
    image: ICON_PROCEDURE,
    title: `New Custom ${formatType(ENTITY_SOP_SECTION)}`,
  },
};
const VIEW_IDS = [VIEW_MATERIAL, VIEW_PROCEDURE, VIEW_DATA, VIEW_QC, VIEW_NEW_SECTION];

function SopNav(props) {
  const { sopId, loading } = props;
  const dispatch = useDispatch();

  const sop = useSop(sopId);
  const {
    section_name: parameterSectionName,
    sections: sectionIds,
    signature_workflow: signatureWorkflow,
    title: sopTitle,
    sop_code: sopCode,
    is_template: isTemplate,
  } = sop ?? {};
  const {
    parameterSectionNameHasLoaded,
    sectionIdsHasLoaded,
    signatureWorkflowHasLoaded,
    sopTitleHasLoaded,
    sopCodeHasLoaded,
  } = useSopHasLoaded(sopId, {
    parameterSectionNameHasLoaded: 'section_name',
    sectionIdsHasLoaded: 'sections',
    signatureWorkflowHasLoaded: 'signature_workflow',
    sopTitleHasLoaded: 'title',
    sopCodeHasLoaded: 'sop_code',
  });

  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const sections = [
    ...(parameterSectionNameHasLoaded ? [SOP_PARAMETERS] : []),
    ...(sectionIds ?? []),
    ...(!isReadOnly ? [NEW_SECTION] : []),
    ...(isDefined(signatureWorkflow) ? [SIGNATURE_WORKFLOW] : []),
  ];

  const handleDragEndSection = (event) => {
    const { active, over } = event;
    const reorderedSections = [...sectionIds];
    const oldIdx = reorderedSections.indexOf(active.id);
    const newIdx = reorderedSections.indexOf(over.id);
    const movedSection = reorderedSections.splice(oldIdx, 1)[0];
    reorderedSections.splice(newIdx, 0, movedSection);
    update({ type: ENTITY_SOP, id: sopId, key: 'sections', value: reorderedSections });
  };

  const [createSectionLoading, setCreateSectionLoading] = useState(null);
  const createSectionHandler = useCallback((data) => async () => {
    setCreateSectionLoading(data.view_id);
    const res = await dispatch(sopActions.createSection({ id: sopId, data })).unwrap();
    setCreateSectionLoading(null);
    if (isDefined(res?.result) && isDefined(res?.entities)) {
      const { entities, result } = res;
      const sopSections = entities[ENTITY_SOP]?.[result]?.sections ?? [];
      scrollTo(`section_${sopSections[sopSections.length - 1]}`);
    }
  }, [dispatch, sopId]);

  const clickHandlerSection = (id) => () => {
    if (id !== NEW_SECTION) {
      scrollTo(`section_${id}`);
    }
  };

  const subsectionDragHandler = useCallback((section) => (event) => {
    const { active, over } = event;
    const reorderedSubsections = [...section.subsections];
    const oldIdx = reorderedSubsections.indexOf(active.id);
    const newIdx = reorderedSubsections.indexOf(over.id);
    const movedSubsection = reorderedSubsections.splice(oldIdx, 1)[0];
    reorderedSubsections.splice(newIdx, 0, movedSubsection);
    update({ type: ENTITY_SOP_SECTION, id: section.id, key: 'subsections', value: reorderedSubsections });
  }, [update]);

  const sectionSelector = useMemo(() => createSelector(
    (state, id) => id,
    (state, id) => sopSelectors.selectSection(state, id),
    (id, section) => {
      if (id === SOP_PARAMETERS) {
        return {
          id,
          'icon': SECTION_ICONS.parameters,
          'text': `1. ${parameterSectionName}`,
          'onClick': clickHandlerSection(id),
          'data-test': 'NavDrawer-parameters-section',
        };
      }

      if (id === SIGNATURE_WORKFLOW) {
        return {
          id,
          icon: (<SignatureIcon />),
          text: formatType(ENTITY_SIGNATURE, { plural: true }),
          onClick: () => scrollTo(`section_${id}`),
        };
      }

      if (id === NEW_SECTION) {
        return {
          id,
          'icon': (<AddIcon sx={{ color: 'text.tertiary' }} />),
          'text': `Add New ${formatType(ENTITY_SOP_SECTION)}`,
          'onClick': clickHandlerSection(id),
          'subsections': VIEW_IDS,
          'keepOpenOnCollapse': true,
          'sx': { color: 'text.tertiary' },
          'data-test': 'NavDrawer-add-section',
        };
      }

      const sequence = (sectionIds ?? []).findIndex((_id) => String(_id) === String(id));
      return {
        id,
        'icon': SECTION_ICONS[section.image],
        'text': `${sequence + 2}. ${section.title}`,
        'onClick': clickHandlerSection(id),
        'subsections': section.subsections,
        'draggable': !isReadOnly,
        'onDragEnd': subsectionDragHandler(section),
        'data-test': 'NavDrawer-section',
      };

    },
  ), [isReadOnly, parameterSectionName, subsectionDragHandler, sectionIds]);

  const subsectionSelector = useMemo(() => createSelector(
    (state, id) => id,
    (state, id) => sopSelectors.selectSubsection(state, id),
    (state, id) => sopSelectors.selectSection(state, sopSelectors.selectSubsection(state, id, 'section'), 'subsections'),
    (id, subsection, subsectionIds) => {
      if (VIEW_IDS.includes(id)) {
        const { label, image, title } = NEW_SECTION_SUBSECTIONS[id];
        return {
          id,
          'icon': (
            <>
              {SECTION_ICONS[image]}
              {createSectionLoading === id ? (<CircularProgress size={24} sx={{ position: 'absolute' }} />) : null}
            </>
          ),
          'text': label,
          'onClick': createSectionHandler({ view_id: id, title }),
          'sx': { color: 'text.tertiary' },
          'data-test': 'NavDrawer-new-material-section',
          'listItemButtonProps': { disabled: createSectionLoading === id },
        };
      }

      const sectionSequence = (sectionIds ?? []).findIndex((_id) => String(_id) === String(subsection.section));
      const subsectionSequence = (subsectionIds ?? []).findIndex((_id) => String(_id) === String(id));
      return {
        id,
        'text': `${sectionSequence + 2}.${subsectionSequence + 1}. ${subsection.title}`,
        'onClick': () => scrollTo(`subsection_${id}`),
        'draggable': !isReadOnly,
        'data-test': 'NavDrawer-subsection',
      };
    },
  ), [createSectionHandler, isReadOnly, sectionIds, createSectionLoading]);

  return (
    <NavDrawer
      header={(
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Stack sx={{ overflow: 'hidden' }}>
            <Typography variant='inherit' noWrap>{sopTitle}</Typography>
            <Typography variant='caption' noWrap>{sopCode}</Typography>
          </Stack>
          { isTemplate ? (<TemplateChip type={ENTITY_SOP} size='small' sx={{ ml: 1 }} />) : null }
        </Stack>
      )}
      sections={sections}
      sectionSelector={sectionSelector}
      subsectionSelector={subsectionSelector}
      onDragEnd={handleDragEndSection}
      loading={
        loading ||
        !parameterSectionNameHasLoaded ||
        !sectionIdsHasLoaded ||
        !signatureWorkflowHasLoaded ||
        !sopTitleHasLoaded ||
        !sopCodeHasLoaded
      }
    />
  );
}

SopNav.propTypes = {
  sopId: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default SopNav;
