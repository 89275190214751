import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { useMyId, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { ENTITY_USER } from 'constants/schemas';
import { RELATIONSHIP_PERMISSION_READ } from 'constants/permission.constants';
import { useMemo } from 'react';

const useSpaceInitialPermissionRelationships = () => {
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const myId = useMyId();
  const individualGenericIds = useMemo(() => {
    if (
      spaceType === SPACE_USER &&
      !(spaceGenericId.id === myId && spaceGenericId.type === ENTITY_USER)
    ) return [spaceGenericId];
    if (spaceType === SPACE_TEAM) return [spaceGenericId];
    if (spaceType === SPACE_DEPARTMENT) return [spaceGenericId];
    return [];
  }, [spaceType, spaceGenericId, myId]);

  return useMemo(() => individualGenericIds.map((genericId) => ({
    individual_id: genericId.id,
    individual_content_type: genericId.type,
    permission: RELATIONSHIP_PERMISSION_READ,
  })), [individualGenericIds]);
};

export default useSpaceInitialPermissionRelationships;
