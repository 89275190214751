import { ENTITY_CATEGORY, ENTITY_SOP, ENTITY_SOP_SECTION, ENTITY_SOP_SUBSECTION } from 'constants/schemas';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import {
  createEntitiesWithUpdatesSelector,
  createEntityHasLoadedSelector,
  createEntityListSelector,
  createEntityPermissionSelector,
  createEntitySelector,
  createEntityUpdatesSelector,
} from '../entity.selectors.utils';
import { isEmpty, isNaN, isNil } from 'lodash';

import { ENTITY } from 'store/store.constants';
import { createSelector } from '@reduxjs/toolkit';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { userSelectors } from '../user';

// #############################################################################
// ################################ Categories #################################
// #############################################################################
export const selectCategories = (state) => state[ENTITY][ENTITY_CATEGORY];
export const selectCategoryList = createEntityListSelector(selectCategories);
export const selectCategory = createEntitySelector(selectCategories);


// #############################################################################
// ############################## SOP Subsections ##############################
// #############################################################################
export const selectSubsections = (state) => state[ENTITY][ENTITY_SOP_SUBSECTION];
export const selectSubsection = createEntitySelector(selectSubsections);
const selectSubsectionUpdates = createEntityUpdatesSelector(selectSubsections);
const _selectSubsectionsWithUpdates = createEntitiesWithUpdatesSelector(selectSubsectionUpdates);


// #############################################################################
// ############################### SOP Sections ################################
// #############################################################################
export const selectSections = (state) => state[ENTITY][ENTITY_SOP_SECTION];
export const selectSection = createEntitySelector(selectSections);
const selectSectionUpdates = createEntityUpdatesSelector(selectSections);
const _selectSectionsWithUpdates = createEntitiesWithUpdatesSelector(selectSectionUpdates);


// #############################################################################
// ################################### SOPs ####################################
// #############################################################################
export const selectSops = (state) => state[ENTITY][ENTITY_SOP];
export const selectSopList = createEntityListSelector(selectSops);
export const selectSop = createEntitySelector(selectSops);
export const selectSopHasLoaded = createEntityHasLoadedSelector(selectSops);
export const selectSopPermission = createEntityPermissionSelector(selectSop);

export const selectSopInSpace = (state, id, spaceType, spaceGenericId) => {
  const sop = selectSop(state, id);
  if (!isDefined(sop)) return true;
  if (spaceType === SPACE_USER) return sop.created_by === spaceGenericId.id;
  if (spaceType === SPACE_TEAM) return userSelectors.selectTeamHasPermission(state, spaceGenericId.id, sop);
  if (spaceType === SPACE_DEPARTMENT) return userSelectors.selectDepartmentHasPermission(state, spaceGenericId.id, sop);
  return true;
};

const selectSopUpdates = createEntityUpdatesSelector(selectSops);
export const selectSopPatchData = (state, id) => {
  const data = { ...(selectSopUpdates(state, id) ?? {}) };
  delete data.sections; // Handled in getSectionPatchData
  const sectionIds = selectSop(state, id, 'sections') ?? [];

  const getSubsectionPatchData = (subsectionId, idx) => {
    const { estimated_time, ...updates } = { ...(selectSubsectionUpdates(state, subsectionId) ?? {}) };
    if (!isNil(estimated_time)) {
      const time = parseFloat(estimated_time);
      if (!isNaN(time)) updates.estimated_time = time.toFixed(2);
    }
    if (idx + 1 !== selectSubsection(state, subsectionId, 'sequence')) {
      updates.sequence = idx + 1;
    }
    return isEmpty(updates) ? null : { ...updates, id: subsectionId };
  };

  const getSectionPatchData = (sectionId, idx) => {
    const updates = { ...(selectSectionUpdates(state, sectionId) ?? {}) };
    delete updates.subsections; // Handled in getSubsectionPatchData
    if (idx + 1 !== selectSection(state, sectionId, 'sequence')) {
      updates.sequence = idx + 1;
    }
    const subsectionIds = selectSection(state, sectionId, 'subsections') ?? [];
    const subsectionData = subsectionIds.map(getSubsectionPatchData).filter((update) => !isEmpty(update));
    if (subsectionData.length > 0) updates.subsection_data = subsectionData;
    return isEmpty(updates) ? null : { ...updates, id: sectionId };
  };

  const sectionData = sectionIds.map(getSectionPatchData).filter((update) => !isEmpty(update));
  if (sectionData.length > 0) data.section_data = sectionData;
  return data;
};

const _selectSopsWithUpdates = createEntitiesWithUpdatesSelector(selectSopUpdates);
export const selectSopsWithUpdates = createSelector(
  _selectSopsWithUpdates,
  _selectSectionsWithUpdates,
  _selectSubsectionsWithUpdates,
  selectSops,
  selectSections,
  selectSubsections,
  (sopIds, sectionIds, subsectionIds, sopSlice, sectionSlice, subsectionSlice) => {
    const additionalSectionIds = subsectionIds.reduce((result, subsectionId) => {
      const sectionId = subsectionSlice.byId[subsectionId]?.section;
      if ((sectionSlice.byId[sectionId]?.subsections ?? []).some((id) => String(id) === String(subsectionId))) result.push(sectionId); // In the case that the subsection has been deleted remotely
      return result;
    }, []);

    const additionalSopIds = [...sectionIds, ...additionalSectionIds].reduce((result, sectionId) => {
      const sopId = sectionSlice.byId[sectionId]?.sop;
      if ((sopSlice.byId[sopId]?.sections ?? []).some((id) => String(id) === String(sectionId))) result.push(sopId); // In the case that the section has been deleted remotely
      return result;
    }, []);

    return [...new Set([...sopIds, ...additionalSopIds.filter((id) => isDefined(id))])];
  },
);

export const selectSopHasUpdates = (state, id) => {
  if (isDefined(id)) {
    return !isEmpty(selectSopPatchData(state, id));
  }
  return (
    !isEmpty(selectSopUpdates(state)) ||
    !isEmpty(selectSectionUpdates(state)) ||
    !isEmpty(selectSubsectionUpdates(state))
  );
};
