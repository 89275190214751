import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import {
  GenericProp,
  directoryActions,
  experimentActions,
  noteActions,
  sopActions,
  taskActions,
} from 'store/entity';
import React, { useCallback } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import { getGenericId, useGenericId } from 'utils/generic.utils';

import BaseAction from './BaseAction';
import PropTypes from 'prop-types';
import { TemplateIcon } from 'constants/icon.constants';
import { useDispatch } from 'react-redux';
import { useEntity } from 'hooks/store.hooks';
import { useNavigate } from 'react-router-dom';

const instantiateActionMap = {
  [ENTITY_DIRECTORY]: directoryActions.instantiateDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.instantiateExperiment,
  [ENTITY_NOTE]: noteActions.instantiateNote,
  [ENTITY_SOP]: sopActions.instantiateSop,
  [ENTITY_TASK]: taskActions.instantiateTask,
};

const directoryTypeProp = new GenericProp({ [ENTITY_DIRECTORY]: 'type' }, { ignoreMissing: true });

function ActionInstantiate(props) {
  const { id, type, ...restProps } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const genericId = useGenericId(id, type);
  const directoryType = useEntity(genericId, directoryTypeProp);

  const onAction = useCallback(async () => {
    try {
      const res = await dispatch(instantiateActionMap[type]({ id })).unwrap();
      navigate(getPath(getGenericId(res.result, type)));
    } catch {}
  }, [id, type, dispatch, navigate]);

  return (
    <BaseAction
      icon={<TemplateIcon />}
      label={`New ${formatType(type, { directoryType })} from ${formatType(type, { directoryType, template: true, includeTemplateType: false })}`}
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionInstantiate.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf(Object.keys(instantiateActionMap)),
};

export default ActionInstantiate;
