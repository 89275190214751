import { ACTION_UPDATE, ACTION_VIEW } from 'constants/permission.constants';
import { BaseAction, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_COMMENT, ENTITY_USER } from 'constants/schemas';
import { EditIcon, MoreOptionsIcon } from 'constants/icon.constants';
import React, { useEffect, useRef, useState } from 'react';
import { useComment, useCommentPermission, useUserName } from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import CustomEditor from 'components/CustomEditor';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { commentActions } from 'store/entity';
import { formatDate } from 'utils/date.utils';
import { formatType } from 'utils/entity.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { useNavBlocker } from 'hooks/navigation.hooks';

function Comment(props) {
  const { id, containerProps, avatarProps, customEditorProps } = props;
  const { size: avatarSize = 24 } = avatarProps ?? {};
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [commentContent, setCommentContent] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const editorRef = useRef(null);
  const serializationCallbackRef = useRef(null);
  const comment = useComment(id);
  const authorName = useUserName(comment?.created_by);
  const dispatch = useDispatch();
  const [version, setVersion] = useState(0); /* Used to reset & rerender on cancel */
  useNavBlocker({
    when: isEditing && !saveDisabled,
    message: `Are you sure you would like to navigate away? There is a ${formatType(ENTITY_COMMENT, { lowercase: true })} edit that has not been saved yet which will be lost.`,
  });

  const canView = useCommentPermission(id, ACTION_VIEW);
  const canUpdate = useCommentPermission(id, ACTION_UPDATE);

  useEffect(() => {
    /*
     * Note: this has to be outside of startEditing() to allow for focus to
     * transfer from Menu to CustomEditor.
     */
    if (isEditing) {
      editorRef.current?.focus?.();
    }
  }, [isEditing]);

  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const onChangeSerialized = (newValue) => {
    setCommentContent(newValue);
  };

  const onChange = (newValue, editor, { serializationCallback }) => {
    if (editor) setSaveDisabled(!editor.hasContent());
    serializationCallbackRef.current = serializationCallback;
  };

  const closeEdit = () => {
    serializationCallbackRef.current?.cancel?.();
    setCommentContent(null);
    setIsEditing(false);
    setSaveDisabled(true);
    setVersion((oldVersion) => oldVersion + 1);
  };

  const saveEdit = async () => {
    if (!saveDisabled) {
      serializationCallbackRef.current?.flush?.();
      const res = await dispatch(commentActions.patchComment({
        id, data: { content: commentContent },
      })).unwrap();
      if (res.result) closeEdit();
    }
  };

  const extraActions = useEntityActions(id, ENTITY_COMMENT);
  const actions = [
    ...(canUpdate ? [{
      id: 'edit',
      component: BaseAction,
      props: {
        label: 'Edit',
        icon: <EditIcon />,
        onAction: startEditing,
      },
    }] : []),
    ...extraActions,
  ];
  return canView ? (
    <Box {...containerProps}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          id={comment.created_by}
          type={ENTITY_USER}
          {...avatarProps}
        />
        <Typography
          sx={{ ml: 1 }}
        >
          <Box
            component='span'
            sx={{ color: 'text.secondary' }}
          >
            { authorName ?? <Skeleton width={120} sx={{ display: 'inline-block' }} />}
          </Box>
          <Box
            component='span'
            sx={{ ml: 2, typography: 'caption', color: 'text.tertiary' }}
          >
            {formatDate(comment.created)}
            { comment?.is_edited ? (
              <Tooltip
                title={formatDate(comment.modified)}
                placement='right'
                arrow
              >
                <Box component='span' sx={{ ml: 0.5 }}>(edited)</Box>
              </Tooltip>
            ) : null}
          </Box>
        </Typography>
        { actions.length > 0 ? (
          <EntityActionState>
            <Button
              size='small'
              onClick={openMenu}
              icon
              sx={{ ml: 'auto', mr: 0, color: 'text.icon' }}
            >
              <MoreOptionsIcon fontSize='small' />
            </Button>
            <Menu
              anchorEl={menuAnchor}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={closeMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              disableEnforceFocus
            >
              { actions.map((action) => {
                const { id: actionId, component: Component, props: actionProps } = action;
                return (
                  <Component
                    key={`action_${actionId}`}
                    {...actionProps}
                    onClick={closeMenu}
                  />
                );
              })}
            </Menu>
          </EntityActionState>
        ) : null}
      </Box>
      <CustomEditor
        key={`commentVersion${version}`}
        ref={editorRef}
        initialValue={comment.content}
        readOnly={!isEditing}
        interactable={isEditing}
        hideToolbarOnBlur={!isEditing}
        onChangeSerialized={onChangeSerialized}
        onChange={onChange}
        {...customEditorProps}
        emptyFieldProps={{
          ...customEditorProps?.emptyFieldProps,
          sx: mergeSx({ display: 'none' }, customEditorProps?.emptyFieldProps?.sx),
        }}
        sx={mergeSx(
          { ml: `${avatarSize}px` },
          isEditing ? { my: 1 } : { mt: -1 },
          customEditorProps?.sx,
        )}
      />
      { isEditing ? (
        <Box sx={{ display: 'flex', ml: (theme) => `calc(${avatarSize}px + ${theme.spacing(1)})` }}>
          <Button onClick={saveEdit} disabled={saveDisabled}>Save</Button>
          <Button
            variant='text'
            onClick={closeEdit}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      ) : null}
    </Box>
  ) : (
    <Tooltip title={`You do not have permission to view this ${formatType(ENTITY_COMMENT, { lowercase: true })}`} arrow>
      <Box
        {...containerProps}
        sx={mergeSx({
          p: 1,
          height: 40,
          bgcolor: 'background.grey',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }, containerProps?.sx)}
      >
        <Typography color='text.secondary'>[Content Hidden]</Typography>
      </Box>
    </Tooltip>
  );
}

Comment.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  containerProps: PropTypes.object,
  avatarProps: PropTypes.object,
  customEditorProps: PropTypes.object,
};

export default Comment;
