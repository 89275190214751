import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';

import DirectoryPreviewContent from './DirectoryPreviewContent';
import ExperimentPreviewContent from './ExperimentPreviewContent';
import NotePreviewContent from './NotePreviewContent';
import PropTypes from 'prop-types';
import React from 'react';
import SidePreview from 'components/SidePreview';
import SopPreviewContent from './SopPreviewContent';
import TaskPreviewContent from './TaskPreviewContent';

const CONTENT_MAP = {
  [ENTITY_DIRECTORY]: DirectoryPreviewContent,
  [ENTITY_EXPERIMENT]: ExperimentPreviewContent,
  [ENTITY_NOTE]: NotePreviewContent,
  [ENTITY_SOP]: SopPreviewContent,
  [ENTITY_TASK]: TaskPreviewContent,
};

function EntityPreview(props) {
  const { id, type, ...sidePreviewProps } = props;
  const PreviewContent = CONTENT_MAP[type];
  return (
    <SidePreview {...sidePreviewProps}>
      { PreviewContent ? <PreviewContent id={id} /> : null }
    </SidePreview>
  );
}

EntityPreview.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf(Object.keys(CONTENT_MAP)),
};

export default EntityPreview;
