import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback } from 'react';
import { directoryActions, experimentActions, noteActions, sopActions, taskActions } from 'store/entity';

import { ACTION_RESTORE } from 'constants/permission.constants';
import BaseAction from './BaseAction';
import PropTypes from 'prop-types';
import { RestoreIcon } from 'constants/icon.constants';
import { useDispatch } from 'react-redux';
import { useEntityPermission } from 'hooks/store.hooks';
import { useGenericId } from 'utils/generic.utils';

const restoreActionMap = {
  [ENTITY_DIRECTORY]: directoryActions.restoreDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.restoreExperiment,
  [ENTITY_NOTE]: noteActions.restoreNote,
  [ENTITY_SOP]: sopActions.restoreSop,
  [ENTITY_TASK]: taskActions.restoreTask,
};

function ActionRestore(props) {
  const { id, type, ...restProps } = props;
  const dispatch = useDispatch();
  const genericId = useGenericId(id, type);
  const canRestore = useEntityPermission(genericId, ACTION_RESTORE);

  const onAction = useCallback(() => {
    dispatch(restoreActionMap[type]({ id }));
  }, [id, type, dispatch]);

  return (
    <BaseAction
      icon={<RestoreIcon />}
      label='Restore'
      onAction={onAction}
      disabled={!canRestore}
      {...restProps}
    />
  );
}

ActionRestore.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf(Object.keys(restoreActionMap)),
};

export default ActionRestore;
