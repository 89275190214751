import React, { useMemo, useRef, useState } from 'react';

import GlobalSearch from 'components/GlobalSearch';
import { MODE_DARK } from 'components/ThemeProvider';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

const WIDTH = 300;

function AppSearch() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === MODE_DARK;
  const [focused, setFocused] = useState(false);
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(ref.current);

  const textFieldProps = useMemo(() => ({
    onFocus: () => {
      setFocused(true);
      setAnchorEl(ref.current);
    },
    onBlur: () => setFocused(false),
    variant: 'borderless',
    size: 'small',
    sx: { width: 1 },
    inputProps: { sx: { p: 0 }},
  }), []);

  const popperProps = useMemo(() => ({ anchorEl }), [anchorEl]);

  const paperProps = useMemo(() => ({
    elevation: 5,
    sx: {
      width: WIDTH,
      borderTop: 1,
      borderColor: 'divider',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  }), []);

  return (
    <Paper
      ref={ref}
      elevation={isDarkMode ? 5 : (focused ? 5 : 0)}
      sx={[
        {
          width: WIDTH,
          boxSizing: 'border-box',
          ml: 'auto',
          mr: 0,
          px: 1,
          py: 0.5,
          borderRadius: 5,
          border: 1,
          borderColor: 'textfieldOutline',
          transition: theme.transitions.create(['border-radius', 'border-color', 'box-shadow']),
        },
        { '&:focus-within': {
          borderRadius: 1,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: 'transparent',
        }},
        isDarkMode ? {
          borderColor: 'transparent',
        } : {},
        isDarkMode ? {
          '&:not(:focus-within)': {
            boxShadow: 0,
          },
        } : {},
      ]}
    >
      <GlobalSearch
        navigateToResult
        textFieldProps={textFieldProps}
        popperProps={popperProps}
        paperProps={paperProps}
        searchIconProps={{ fontSize: 'small' }}
        clearButtonProps={{ sx: { p: 0.25 }}}
        circularProgressProps={{ size: 16 }}
      />
    </Paper>
  );
}

export default AppSearch;
