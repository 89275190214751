import CheckoutAlert from 'components/CheckoutAlert';
import DeletedAlert from 'components/DeletedAlert';
import { ENTITY_TASK } from 'constants/schemas';
import PropTypes from 'prop-types';
import React from 'react';

function TaskWarnings(props) {
  const { id } = props;
  return (
    <>
      <DeletedAlert id={id} type={ENTITY_TASK} />
      <CheckoutAlert id={id} type={ENTITY_TASK} />
    </>
  );
}

TaskWarnings.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default TaskWarnings;
