import { Button, ConditionalWrapper, TextField } from '@acheloisbiosoftware/absui.core';
import React, { useRef, useState } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import { MoreOptionsIcon } from 'constants/icon.constants';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const defaultTitle = 'Untitled';

function SectionHeader(props) {
  const {
    sticky,
    prefix,
    title,
    variant,
    onEdit,
    extraActions,
    sx,
    stickyContainerProps,
    titleTypographyProps,
    dividerProps,
    skeletonProps,
  } = props;
  const menuRef = useRef(null);
  const [editingTitle, setEditingTitle] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const titleLoading = !isDefined(title);

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const onKeyDown = (event) => {
    if (event.code === 'Enter') {
      onEdit(event.target.value);
      setEditingTitle(false);
    }
  };

  const onTextFieldBlur = (event) => {
    if (!isDefined(menuRef.current)) {
      onEdit(event.target.value);
      setEditingTitle(false);
    }
  };

  const menuActions = extraActions?.inMenu ?? [];

  const inputPaddingTop = '4px';
  const inputPaddingBottom = '5px';
  const inputHeight = 1.4375; /* em */
  const totalInputHeight = (theme) => `calc((${theme.typography?.[variant]?.fontSize} * ${inputHeight}) + ${inputPaddingTop} + ${inputPaddingBottom})`;
  const paddingAdjustment = { /* Prevents text from "jumping" when toggling edit mode */
    h1: { pt: '- 2px' },
    h2: { pt: '- 2px' },
    h3: { pt: '- 2px' },
    h4: {},
    h5: {},
    h6: {},
  };

  return (
    <ConditionalWrapper
      condition={sticky}
      wrapperComponent={Paper}
      wrapperProps={{
        square: true,
        elevation: 2,
        ...stickyContainerProps,
        sx: mergeSx({
          boxShadow: 0,
          position: 'sticky',
          top: 0,
          width: 1,
          zIndex: 'header',
        }, stickyContainerProps?.sx),
      }}
    >
      <Box sx={mergeSx({ display: 'flex', width: 1, minHeight: totalInputHeight, py: 0.5 }, sx)}>
        { !titleLoading && isDefined(prefix) ? (
          <Typography
            variant={variant}
            color='text.primary'
            sx={{ my: 'auto', mr: 0.5, pt: inputPaddingTop, pb: inputPaddingBottom }}
          >
            {prefix}
          </Typography>
        ) : null}
        { !titleLoading && editingTitle ? (
          <TextField
            variant='standard'
            value={title}
            placeholder={defaultTitle}
            sx={{ my: 'auto', width: 1, px: 1 }}
            inputProps={{
              style: {
                paddingTop: `calc(${inputPaddingTop} ${paddingAdjustment[variant]?.pt ?? ''})`,
                paddingBottom: `calc(${inputPaddingBottom} ${paddingAdjustment[variant]?.pb ?? ''})`,
                height: `${inputHeight}em`,
              },
            }}
            InputSx={{
              typography: variant,
            }}
            onChange={(_, newValue) => onEdit(newValue)}
            triggerChangeOnBlur
            onKeyDown={onKeyDown}
            onBlur={onTextFieldBlur}
            autoFocus
          />
        ) : (
          <Typography
            variant={variant}
            onClick={onEdit ? () => setEditingTitle(true) : null}
            color='text.primary'
            {...titleTypographyProps}
            sx={mergeSx(
              {
                my: 'auto',
                pt: inputPaddingTop,
                pb: inputPaddingBottom,
                px: 1,
                borderRadius: 1,
                width: 1,
              },
              onEdit ? { '&:hover': { bgcolor: 'grey.light' }} : null,
              titleTypographyProps?.sx,
            )}
          >
            {!titleLoading ? title || defaultTitle : (<Skeleton width={150} {...skeletonProps} />)}
          </Typography>
        )}
        { (extraActions?.left ?? []).map((action) => {
          const { id, component: Component, props: actionProps } = action;
          return (<Component key={`actionLeft_${id}`} {...actionProps} />);
        }) }
        { menuActions.length > 0 ? (
          <>
            <Button
              icon
              size='small'
              onClick={(event) => setMenuAnchor(event.currentTarget)}
              sx={{ my: 'auto', mr: 0, color: 'text.icon' }}
            >
              <MoreOptionsIcon />
            </Button>
            <Menu
              ref={menuRef}
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={closeMenu}
              disableEnforceFocus
            >
              { menuActions.map((action) => {
                const { id, component: Component, props: actionProps } = action;
                return (
                  <Component
                    key={`actionMenu_${id}`}
                    {...actionProps}
                    onClick={closeMenu}
                  />
                );
              })}
            </Menu>
          </>
        ) : null}
        { (extraActions?.right ?? []).map((action) => {
          const { id, component: Component, props: actionProps } = action;
          return (<Component key={`actionRight_${id}`} {...actionProps} />);
        }) }
      </Box>
      { sticky ? (<Divider {...dividerProps} />) : null}
    </ConditionalWrapper>
  );
}

SectionHeader.propTypes = {
  sticky: PropTypes.bool,
  prefix: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  onEdit: PropTypes.func,
  extraActions: PropTypes.shape({
    left: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      component: PropTypes.elementType.isRequired,
      props: PropTypes.object,
    })),
    inMenu: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      component: PropTypes.elementType.isRequired,
      props: PropTypes.object,
    })),
    right: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      component: PropTypes.elementType.isRequired,
      props: PropTypes.object,
    })),
  }),
  sx: sxPropType,
  stickyContainerProps: PropTypes.object,
  titleTypographyProps: PropTypes.object,
  editLabel: PropTypes.string,
  dividerProps: PropTypes.object,
  skeletonProps: PropTypes.object,
};

SectionHeader.defaultProps = {
  variant: 'h4',
  editLabel: 'Rename',
};

export default SectionHeader;
