import { Autocomplete, Button, Dialog, EditorPlugins, TextField } from '@acheloisbiosoftware/absui.core';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useEffect, useRef, useState } from 'react';
import { formatType, getIcon } from 'utils/entity.utils';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Field from 'components/Field';
import { GenericProp } from 'store/entity';
import PropTypes from 'prop-types';
import SelectDialog from 'components/SelectDialog';
import { ShareIcon } from 'constants/icon.constants';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';
import TaskRelatedDocument from 'components/TaskRelatedDocument';
import Typography from '@mui/material/Typography';
import { getGenericId } from 'utils/generic.utils';
import { useEntity } from 'hooks/store.hooks';

const { BaseEditor } = EditorPlugins;

const entityTitleMap = {
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
};
const entityTitleProp = new GenericProp(entityTitleMap);

function NewTaskFormInline(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    assigneesAutocompleteProps,
    dueDateDatePickerProps,
    containerProps,
  } = props;
  const {
    title,
    description,
    related_obj_content_type,
    related_obj_id,
    related_obj_init_data,
    is_template,
    permission_relationships,
  } = formData;

  const numShares = (permission_relationships ?? []).length;
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const editorRef = useRef(null);
  const [stagedDescription, setStagedDescription] = useState(description);
  const [editorKey, setEditorKey] = useState(0);
  const [textDescription, setTextDescription] = useState('');

  const onCloseDescriptionDialog = () => {
    setStagedDescription(description);
    setEditorKey((prev) => prev + 1);
  };

  useEffect(() => {
    if (isDefined(description) && isDefined(editorRef.current)) {
      setTextDescription(BaseEditor.string(editorRef.current, []));
    }
  }, [description]);

  const relatedObjTitle = useEntity(getGenericId(related_obj_id, related_obj_content_type), entityTitleProp);
  const RelatedObjIcon = getIcon(related_obj_content_type);
  const relatedObjLabel = isDefined(related_obj_id) ? relatedObjTitle : (
    related_obj_init_data?.[entityTitleMap[related_obj_content_type]] || formatType(related_obj_content_type)
  );

  return (
    <Stack direction='row' alignItems='center' {...containerProps}>
      { excludeFields.includes('title') ? null : (
        <TextField
          variant='standard'
          size='small'
          label='Title'
          value={title}
          onChange={(_, newTitle) => updateFormData('title', newTitle)}
          {...fieldPropsMap?.title}
        />
      )}
      { excludeFields.includes('assignees') ? null : (
        <Autocomplete
          {...assigneesAutocompleteProps}
          multiple
          label='Assignee(s)'
          {...fieldPropsMap?.assignees}
          inputProps={(params) => ({
            ...params,
            size: 'small',
            variant: 'standard',
            ...assigneesAutocompleteProps?.inputProps?.(params),
            ...fieldPropsMap?.assignees?.inputProps?.(params),
          })}
          sx={mergeSx(
            { minWidth: 150 },
            assigneesAutocompleteProps?.sx,
            fieldPropsMap?.assignees?.sx,
          )}
        />
      )}
      { excludeFields.includes('due_date') ? null : (
        <DatePicker
          label='Due Date'
          {...dueDateDatePickerProps}
          {...fieldPropsMap?.due_date}
          sx={mergeSx(
            { width: 160 },
            dueDateDatePickerProps?.sx,
            fieldPropsMap?.due_date?.sx,
          )}
          slotProps={{
            ...dueDateDatePickerProps?.slotProps,
            ...fieldPropsMap?.due_date?.slotProps,
            textField: {
              size: 'small',
              variant: 'standard',
              ...dueDateDatePickerProps?.slotProps?.textField,
              ...fieldPropsMap?.due_date?.slotProps?.textField,
            },
            openPickerButton: {
              size: 'small',
              ...dueDateDatePickerProps?.slotProps?.openPickerButton,
              ...fieldPropsMap?.due_date?.slotProps?.openPickerButton,
            },
            openPickerIcon: {
              fontSize: 'small',
              ...dueDateDatePickerProps?.slotProps?.openPickerIcon,
              ...fieldPropsMap?.due_date?.slotProps?.openPickerIcon,
            },
          }}
        />
      )}
      { excludeFields.includes('description') ? null : (
        <SelectDialog
          variant='standard'
          size='small'
          label='Description'
          value={textDescription}
          {...fieldPropsMap?.description}
          dialogProps={{
            fullWidth: true,
            onClose: onCloseDescriptionDialog,
            onConfirm: () => updateFormData('description', stagedDescription),
            children: (
              <Field
                value={description}
                variant={Field.VARIANTS.TEXT_EDITOR}
                readOnly={false}
                debounceSerialization={false}
                onChangeSerialized={(newDescription) => setStagedDescription(newDescription)}
                hideToolbarOnBlur={false}
                toolbarProps={{ elevation: 16 }}
                customEditorProps={{ ref: editorRef, key: `editorVersion${editorKey}` }}
              />
            ),
            ...fieldPropsMap?.description?.dialogProps,
          }}
          sx={mergeSx({ width: 200 }, fieldPropsMap?.description?.sx)}
        />
      )}
      { excludeFields.includes('related_obj') ? null : (
        <SelectDialog
          variant='standard'
          size='small'
          label='Related Document'
          value={isDefined(related_obj_content_type) ? (
            <Stack direction='row' alignItems='center'>
              <RelatedObjIcon colored sx={{ mx: 1 }} />
              <Typography noWrap>{relatedObjLabel}</Typography>
            </Stack>
          ) : null}
          {...fieldPropsMap?.related_obj}
          dialogProps={{
            maxWidth: 'md',
            fullWidth: true,
            children: (
              <TaskRelatedDocument
                related_obj_id={related_obj_id}
                related_obj_content_type={related_obj_content_type}
                related_obj_init_data={related_obj_init_data}
                onChange={updateFormData}
                paperProps={{ sx: { bgcolor: 'background.grey' }}}
                hideLaunch
                hideOpen
              />
            ),
            ...fieldPropsMap?.related_obj?.dialogProps,
          }}
          sx={mergeSx(
            { minWidth: 160 },
            fieldPropsMap?.related_obj?.sx,
          )}
        />
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <Box sx={{ color: 'text.icon' }}>
          <Button
            variant='text'
            color={numShares > 0 ? 'primary' : 'inherit'}
            onClick={() => setShareDialogOpen(true)}
            startIcon={<ShareIcon />}
          >
            {numShares}
          </Button>
          <Dialog
            title={`Share New ${formatType(ENTITY_TASK, { template: is_template })}`}
            open={shareDialogOpen}
            onConfirm={() => setShareDialogOpen(false)}
            confirmOnly
            fullWidth
            maxWidth='sm'
          >
            <ShareNewDocumentField
              shares={permission_relationships}
              setShares={(newShares) => updateFormData('permission_relationships', newShares)}
              {...fieldPropsMap?.permission_relationships}
            />
          </Dialog>
        </Box>
      )}
    </Stack>
  );
}

NewTaskFormInline.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  assigneesAutocompleteProps: PropTypes.object,
  dueDateDatePickerProps: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewTaskFormInline;
