import { ENTITY_ACTION_DELETE, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import { GenericProp, taskActions } from 'store/entity';
import {
  LocationIcon,
  StatusIcon,
  TaskIcon,
  TimeIcon,
  UserIcon,
} from 'constants/icon.constants';
import React, { useEffect } from 'react';
import { formatType, getIcon, getPath } from 'utils/entity.utils';
import { getGenericId, useGenericId } from 'utils/generic.utils';
import {
  useEntity,
  useEntityPermission,
  useTask,
  useUserName,
} from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import { DATE_FMT_SHORT } from 'constants/date.constants';
import DeletedAlert from 'components/DeletedAlert';
import Field from 'components/Field';
import Link from 'components/Link';
import LinkIcon from '@mui/icons-material/Link';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import SidePreviewActions from './SidePreviewActions';
import SidePreviewContent from './SidePreviewContent';
import SidePreviewHeader from './SidePreviewHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Status from 'components/Status';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { useOnClose } from './SidePreview';

const entityTitleMap = {
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
};
const entityTitleProp = new GenericProp(entityTitleMap);
const directoryTypeMap = { [ENTITY_DIRECTORY]: 'type' };
const directoryTypeProp = new GenericProp(directoryTypeMap, { ignoreMissing: true });

function TaskPreviewContent(props) {
  const { id } = props;
  const onClose = useOnClose();
  const dispatch = useDispatch();
  const task = useTask(id);
  const reporter = useUserName(task?.created_by);
  const assigneeNames = useUserName(task?.assignees);

  const relatedId = task?.related_obj_id;
  const relatedType = task?.related_obj_content_type;
  const relatedInitData = task?.related_obj_init_data;
  const relatedIsExisting = isDefined(relatedId) && isDefined(relatedType);
  const relatedIsNew = !isDefined(relatedId) && isDefined(relatedType);
  const relatedGenericId = useGenericId(relatedId, relatedType);
  const canViewRelated = useEntityPermission(relatedGenericId, ACTION_VIEW);
  const relatedObjTitle = useEntity(relatedGenericId, entityTitleProp);
  const relatedObjDirectoryType = useEntity(relatedGenericId, directoryTypeProp);

  const canViewRelatedTitle = !relatedIsExisting || canViewRelated;
  const relatedTitle = relatedIsExisting ? relatedObjTitle : (relatedInitData?.[entityTitleMap[relatedType]] || 'Untitled');
  const relatedDirectoryType = relatedIsExisting ? relatedObjDirectoryType : (relatedType === ENTITY_DIRECTORY ? relatedInitData?.[directoryTypeMap[relatedType]] : null);
  const RelatedIcon = getIcon(relatedType, { directoryType: relatedDirectoryType });

  useEffect(() => {
    if (isDefined(id)) dispatch(taskActions.fetchTask({ id }));
  }, [dispatch, id]);

  const createRow = (title, value, icon) => ({ title, value, icon });
  const iconSx = { fontSize: 20, my: 'auto' };
  const tableProperties = [
    createRow('Reporter', reporter, <UserIcon sx={iconSx} />),
    createRow('Assignee(s)', (task?.assignees ?? []).length > 0 ? (
      <Stack>
        {task.assignees.map((assignee, idx) => (
          <Typography key={`assignee_${assignee}`}>
            {assigneeNames[idx] ?? <Skeleton />}
          </Typography>
        ))}
      </Stack>
    ) : (
      <Typography color='text.disabled'>None</Typography>
    ), <UserIcon sx={iconSx} />),
    createRow('Due Date', (
      <Typography color={!isDefined(task?.due_date) ? 'text.disabled' : null}>
        {isDefined(task?.due_date) ? formatDate(task.due_date, DATE_FMT_SHORT) : 'None'}
      </Typography>
    ), <TimeIcon sx={iconSx} />),
    task?.is_deleted ? createRow('Date Deleted', formatDate(task.deleted, DATE_FMT_SHORT), <TimeIcon sx={iconSx} />) : null,
    createRow('Status', isDefined(task?.status) ? <Status>{task.status}</Status> : null, <StatusIcon sx={iconSx} />),
    createRow(`Related ${formatType(relatedType, { directoryType: relatedDirectoryType, defaultValue: 'Document' })}`, canViewRelatedTitle ? (
      <Typography
        component={relatedIsExisting ? Link : null}
        to={relatedIsExisting ? getPath(getGenericId(relatedId, relatedType)) : null}
        color={!relatedIsExisting && !relatedIsNew ? 'text.disabled' : null}
      >
        {relatedIsExisting || relatedIsNew ? relatedTitle : 'None'}
      </Typography>
    ) : (
      <Tooltip title='You do not have permission to view this document' arrow>
        <Typography color='text.disabled'>[Content Hidden]</Typography>
      </Tooltip>
    ), RelatedIcon ? <RelatedIcon sx={iconSx} /> : <LinkIcon sx={iconSx} />),
    createRow('Location(s)', (<Locations directoryItem={task?.directory_item} />), <LocationIcon sx={iconSx} />),
  ];

  const actions = useEntityActions(id, ENTITY_TASK, {
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess: onClose }},
  });

  return (
    <>
      <EntityActionState>
        <SidePreviewActions path={getPath(getGenericId(id, ENTITY_TASK))} actions={actions} />
      </EntityActionState>

      <SidePreviewHeader
        icon={<TaskIcon colored template={task?.is_template} />}
        title={task?.title}
        subtitle={task?.task_code}
      />

      <SidePreviewContent>
        <DeletedAlert id={id} type={ENTITY_TASK} sx={{ mb: 1 }} />

        <Table size='small'>
          <TableBody>
            {tableProperties.map((row) => (!isDefined(row) ? null : (
              <TableRow key={row.title}>
                <TableCell sx={{ minWidth: 155, width: 155 }}>
                  <Stack direction='row' alignItems='center' sx={{ color: 'text.secondary' }}>
                    {row.icon}
                    <Typography variant='body2' sx={{ ml: 1 }}>
                      {row.title ?? <Skeleton />}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={{ typography: 'body1' }}>
                  {row.value ?? <Skeleton />}
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        <Stack sx={{ mt: 1, position: 'relative' }}>
          { task && 'description' in task ? (
            <Field
              label='Description'
              value={task.description}
              variant={Field.VARIANTS.TEXT_EDITOR}
              readOnly
              interactable={false}
              valueProps={{ key: `description${id}` }}
            />
          ) : null}
        </Stack>
      </SidePreviewContent>
    </>
  );
}

TaskPreviewContent.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default TaskPreviewContent;
