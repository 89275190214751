import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';

import Experiment from 'views/Experiment';
import Note from 'views/Note';
import PropTypes from 'prop-types';
import React from 'react';
import Sop from 'views/Sop';
import Task from 'views/Task';
import Workspace from 'views/Workspace';
import { genericIdFromString } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

const ENTITY_DETAIL_MAP = {
  [ENTITY_DIRECTORY]: Workspace,
  [ENTITY_EXPERIMENT]: Experiment,
  [ENTITY_NOTE]: Note,
  [ENTITY_SOP]: Sop,
  [ENTITY_TASK]: Task,
};

const pageContainerProps = { sx: { borderRadius: 0 }};

function AllDocumentsContent(props) {
  const { selected } = props;
  const genericId = genericIdFromString(selected);
  const DetailComponent = ENTITY_DETAIL_MAP[genericId.type];
  return !isDefined(DetailComponent) || !isDefined(selected) ? null : (
    <DetailComponent
      id={genericId.id}
      pageContainerProps={pageContainerProps}
    />
  );
}

AllDocumentsContent.propTypes = {
  selected: PropTypes.string,
};

export default AllDocumentsContent;
