import { Prop, directoryActions, directorySelectors } from 'store/entity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { isNil, minBy, zip } from 'lodash';
import { useDirectory, useDirectoryList, useFetchListing, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { ActionAddDocuments } from 'components/DocumentTable';
import { DIR_TYPES } from 'constants/directory.constants';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import { EntityPreview } from 'components/SidePreview';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WorkspaceTable from 'components/WorkspaceTable';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { useNavigate } from 'react-router-dom';

const templatesInitState = { [ENTITY_DIRECTORY]: { is_template: true }};

function WorkspacesTable(props) {
  const { type, templates, listView, selected, setSelected } = props;
  const navigate = useNavigate();
  const isTypeSpecific = !isNil(type);
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const [previewState, setPreviewState] = useState({ open: false, id: null });

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (directory, state) => (
      (!isTypeSpecific || directory.type === type) &&
      (directory.is_template ?? false) === templates &&
      directorySelectors.selectDirectoryInSpace(state, directory.id, spaceType, spaceGenericId)
    ),
  }), [isTypeSpecific, type, spaceType, spaceGenericId, templates]);
  const directoryIds = useDirectoryList(listParams);

  const fetchOptions = useMemo(
    () => ({ params: { filters: {
      ...(isTypeSpecific ? { type } : {}),
      is_template: templates,
      ...(spaceType === SPACE_USER ? { created_by: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_TEAM ? { team: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_DEPARTMENT ? { department: spaceGenericId.id } : {}),
    }}}),
    [isTypeSpecific, type, spaceType, spaceGenericId, templates],
  );
  const loading = useFetchListing(directoryActions.fetchDirectories, fetchOptions);

  const directoryNames = useDirectory(directoryIds, 'name');
  useEffect(() => {
    if (isNil(selected)) {
      const minPair = minBy(zip(directoryIds, directoryNames), (p) => p[1].toLowerCase());
      setSelected(minPair?.[0], { inParams: false });
    }
  }, [selected, setSelected, directoryIds, directoryNames]);

  const onRowClick = useCallback((row, event) => {
    if (event.detail > 1) {
      navigate(getPath(getGenericId(row.id, ENTITY_DIRECTORY)));
      return;
    }
    if (listView) {
      setSelected(row.id, { inParams: true });
    } else {
      setPreviewState({ open: true, id: row.id });
      event.stopPropagation(); /* To prevent the preview from closing */
    }
  }, [navigate, listView, setSelected]);

  const listViewColumnProps = useMemo(() => ({
    checkbox: {
      selection: isNil(selected) ? [] : [selected],
    },
    name: {
      renderValue: ({ row, value }) => (
        <Stack>
          <Typography variant='subtitle2'>{value || 'Untitled'}</Typography>
          <Typography color='text.secondary' variant='caption'>{formatDate(row.created)}</Typography>
        </Stack>
      ),
      cellProps: { sx: { whiteSpace: 'normal' }},
    },
    owner: { hidden: true },
    created: { hidden: true },
  }), [selected]);

  const onClose = useCallback(() => {
    setPreviewState((oldPreviewState) => ({ ...oldPreviewState, open: false }));
  }, []);

  return (
    <>
      <WorkspaceTable
        ids={directoryIds}
        withDnd
        columnProps={listView ? listViewColumnProps : null}
        onRowClick={onRowClick}
        stateInSearchParams
        loading={loading}
        noHeader={listView}
        disableFilter={listView}
        disableGroupBy={listView}
        initState={{
          orderBy: 'name',
          order: 'asc',
        }}
        actions={listView ? null : (
          <ActionAddDocuments
            entityTypes={[ENTITY_DIRECTORY]}
            directoryTypes={isTypeSpecific ? [type] : DIR_TYPES}
            initStateMap={templates ? templatesInitState : null}
          />
        )}
        sx={{ overflowY: 'auto' }}
      />
      <EntityPreview
        id={previewState.id}
        type={ENTITY_DIRECTORY}
        open={previewState.open}
        onClose={onClose}
      />
    </>
  );
}

WorkspacesTable.propTypes = {
  type: PropTypes.string,
  templates: PropTypes.bool,
  listView: PropTypes.bool,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
};

export default WorkspacesTable;
