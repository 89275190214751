import React, { useCallback } from 'react';

import MenuItemAction from './MenuItemAction';
import PropTypes from 'prop-types';

function BaseAction(props) {
  const {
    component: Component = MenuItemAction,
    onClick,
    onAction,
    ...restProps
  } = props;

  const _onClick = useCallback(async (...args) => {
    await onAction?.(...args);
    await onClick?.(...args);
  }, [onAction, onClick]);

  return (
    <Component
      {...restProps}
      onClick={_onClick}
    />
  );
}

BaseAction.propTypes = {
  component: PropTypes.elementType,
  onClick: PropTypes.func,
  onAction: PropTypes.func,
};

export default BaseAction;
