import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE, COL_TYPE_SELECT, COL_TYPE_USER, FILTER_TYPE_SELECT } from 'components/DataTable';
import { ENTITY_TASK, ENTITY_USER } from 'constants/schemas';
import { EntityActionState, selectEntityActions } from 'components/EntityActions';
import { Prop, SelectorProp, taskActions, taskSelectors, userSelectors } from 'store/entity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SPACE_DEPARTMENT, SPACE_OPUS_U, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { VIEW_ALL, VIEW_INBOX, VIEW_OUTBOX } from './Tasks.constants';
import { formatType, getPath } from 'utils/entity.utils';
import { isNil, maxBy } from 'lodash';
import { useFetchListing, useSpaceGenericId, useSpaceType, useTask, useTaskList } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import ActionAddTask from './ActionAddTask';
import Avatar from 'components/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import { EntityPreview } from 'components/SidePreview';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Status from 'components/Status';
import { TaskIcon } from 'constants/icon.constants';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { getDraggableProps } from 'components/DirectoryDndProvider';
import { getGenericId } from 'utils/generic.utils';
import { momentValue } from 'utils/date.utils';
import { useNavigate } from 'react-router-dom';

const assigneesNamesProp = new SelectorProp((state, task) => userSelectors.selectUserName(state, task?.assignees));

function TasksTable(props) {
  const { listView, selected, setSelected, view } = props;
  const navigate = useNavigate();
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const [previewState, setPreviewState] = useState({ open: false, id: null });

  const fetchOptions = useMemo(() => ({
    params: { filters: {
      is_template: false,
      ...([SPACE_USER, SPACE_OPUS_U].includes(spaceType) && view === VIEW_INBOX ? { assignee: spaceGenericId.id } : {}),
      ...([SPACE_USER, SPACE_OPUS_U].includes(spaceType) && view === VIEW_OUTBOX ? { created_by: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_TEAM ? { team: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_DEPARTMENT ? { department: spaceGenericId.id } : {}),
    }},
  }), [spaceType, spaceGenericId, view]);
  const loading = useFetchListing(taskActions.fetchTasks, fetchOptions);

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (task, state) => {
      if (task.is_template) return false;
      if ([SPACE_TEAM, SPACE_DEPARTMENT].includes(spaceType)) return taskSelectors.selectTaskInSpace(state, task.id, spaceType, spaceGenericId);
      if (view === VIEW_INBOX) {
        return (task.assignees ?? []).includes(spaceGenericId.id);
      }
      if (view === VIEW_OUTBOX) {
        return task.created_by === spaceGenericId.id;
      }
      return true;
    },
  }), [spaceType, spaceGenericId, view]);
  const taskIds = useTaskList(listParams);
  const tasks = useTask(taskIds);
  const assigneesNames = useTask(taskIds, assigneesNamesProp);
  const { rows } = useMemo(
    () => tasks.reduce((result, task, idx) => {
      if (!task?.permissions?.[ACTION_VIEW]) return result;

      result.rows.push({
        ...task,
        assignees_names: assigneesNames[idx],
        draggableProps: getDraggableProps(task, ENTITY_TASK, { key: 'TasksTable' }),
      });
      return result;
    }, { rows: []}),
    [tasks, assigneesNames],
  );

  useEffect(() => {
    if (isNil(selected)) {
      const firstRow = maxBy(rows, (row) => momentValue(row.due_date));
      setSelected(firstRow?.id, { inParams: false });
    }
  }, [selected, setSelected, rows]);

  const onRowClick = useCallback((row, event) => {
    if (event.detail > 1) {
      navigate(getPath(getGenericId(row.id, ENTITY_TASK)));
      return;
    }
    if (listView) {
      setSelected(row.id, { inParams: true });
    } else {
      setPreviewState({ open: true, id: row.id });
      event.stopPropagation(); /* To prevent the preview from closing */
    }
  }, [navigate, listView, setSelected]);

  const listViewColumnProps = useMemo(() => ({
    checkbox: {
      selection: isNil(selected) ? [] : [selected],
    },
    task_code: { hidden: true },
    title: {
      renderValue: ({ row, value }) => (
        <Stack direction='row' alignItems='center'>
          <TaskIcon colored />
          <Stack sx={{ ml: 1 }}>
            <Typography variant='subtitle2'>{value || 'Untitled'}</Typography>
            <Typography color='text.secondary' variant='caption'>{row.task_code}</Typography>
          </Stack>
        </Stack>
      ),
      cellProps: { sx: { whiteSpace: 'normal' }},
    },
    created_by: { hidden: true },
    assignees: { hidden: true },
    status: { hidden: true },
    due_date: { hidden: true },
    created: { hidden: true },
  }), [selected]);

  const inboxViewColumnProps = useMemo(() => ({
    assignees: { hidden: true },
  }), []);

  const outboxViewColumnProps = useMemo(() => ({
    created_by: { hidden: true },
  }), []);

  const columnProps = useMemo(
    () => (listView ? listViewColumnProps : {
      [VIEW_ALL]: {},
      [VIEW_INBOX]: inboxViewColumnProps,
      [VIEW_OUTBOX]: outboxViewColumnProps,
    }[view]),
    [listView, listViewColumnProps, inboxViewColumnProps, outboxViewColumnProps, view],
  );

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      hidden: true,
      ...columnProps?.checkbox,
    },
    {
      id: 'task_code',
      label: `${formatType(ENTITY_TASK)} Code`,
      field: 'task_code',
      renderValue: ({ value }) => (
        <Stack direction='row' alignItems='center'>
          <TaskIcon colored sx={{ mr: 1 }} />
          {value}
        </Stack>
      ),
      cellProps: { sx: { minWidth: 100 }},
      noWrap: true,
      ...columnProps?.task_code,
    },
    {
      id: 'title',
      label: 'Title',
      field: 'title',
      noWrap: true,
      primary: true,
      cellProps: { sx: { maxWidth: 240 }},
      ...columnProps?.title,
    },
    {
      id: 'created_by',
      label: 'Reporter',
      field: 'created_by',
      type: COL_TYPE_USER,
      secondary: true,
      cellProps: { sx: { maxWidth: 150 }},
      ...columnProps?.created_by,
    },
    {
      id: 'assignees',
      label: 'Assignees',
      field: 'assignees',
      renderValue: ({ value, row }) => (
        <Stack sx={{ width: 'fit-content' }}>
          <Tooltip title={(row.assignees_names ?? []).join(', ')} arrow sx={{ width: 1, height: 1 }}>
            <AvatarGroup>
              { (value ?? []).map((assignee) => (
                <Avatar
                  key={`assignee${assignee}`}
                  id={assignee}
                  type={ENTITY_USER}
                />
              ))}
            </AvatarGroup>
          </Tooltip>
        </Stack>
      ),
      noWrap: true,
      sortable: false,
      filterable: false,
      ...columnProps?.assignees,
    },
    {
      id: 'status',
      label: 'Status',
      field: 'status',
      renderValue: ({ value }) => (<Status>{value}</Status>),
      filterType: FILTER_TYPE_SELECT,
      filterOptionProps: (value) => ({ children: (<Status>{value}</Status>) }),
      groupable: true,
      ...columnProps?.status,
    },
    {
      id: 'due_date',
      label: 'Due',
      field: 'due_date',
      type: COL_TYPE_DATE,
      groupable: true,
      secondary: true,
      ...columnProps?.due_date,
    },
    {
      id: 'created',
      label: 'Date Created',
      field: 'created',
      type: COL_TYPE_DATE,
      noWrap: true,
      groupable: true,
      hidden: true,
      secondary: true,
      ...columnProps?.created,
    },
    {
      id: 'actions',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      inMenu: true,
      actions: ({ row, state }) => selectEntityActions(state, row.id, ENTITY_TASK),
      ...columnProps?.actions,
    },
  ], [columnProps]);

  const onClose = useCallback(() => {
    setPreviewState((oldPreviewState) => ({ ...oldPreviewState, open: false }));
  }, []);

  return (
    <Box sx={{ flexGrow: 1, position: 'relative', display: 'flex', overflow: 'auto' }}>
      <EntityActionState>
        <DataTable
          rows={rows}
          columns={columns}
          onRowClick={onRowClick}
          stateInSearchParams
          initState={{
            orderBy: 'due_date',
            order: 'desc',
          }}
          loading={loading}
          noHeader={listView}
          disableFilter={listView}
          disableGroupBy={listView}
          actions={listView ? null : (<ActionAddTask />)}
        />
      </EntityActionState>
      <EntityPreview
        id={previewState.id}
        type={ENTITY_TASK}
        open={previewState.open}
        onClose={onClose}
      />
    </Box>
  );
}

TasksTable.propTypes = {
  listView: PropTypes.bool,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
  view: PropTypes.oneOf([VIEW_ALL, VIEW_INBOX, VIEW_OUTBOX]),
};

export default TasksTable;
