import {
  RELATIONSHIP_PERMISSION_DISPLAY_MAP,
  RELATIONSHIP_PERMISSION_OPTIONS,
} from 'constants/permission.constants';
import React, { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@acheloisbiosoftware/absui.core';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

function PermissionOptionButton(props) {
  const {
    value,
    onChange,
    onDelete,
    canUpdate,
    canDelete,
    width = 100,
  } = props;
  const hasDropdown = onChange || onDelete;

  const [anchorEl, setAnchorEl] = useState(null);
  const closeMenu = () => setAnchorEl(null);

  const _onChange = async (...args) => {
    await onChange(...args);
    closeMenu();
  };

  const _onDelete = async (...args) => {
    await onDelete(...args);
    closeMenu();
  };

  return (
    <>
      <Button
        variant='text'
        endIcon={hasDropdown ? <ArrowDropDownIcon /> : null}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={!hasDropdown || (!canUpdate && !canDelete)}
        sx={{
          textTransform: 'none',
          width,
          ml: 'auto',
        }}
      >
        {RELATIONSHIP_PERMISSION_DISPLAY_MAP[value] ?? <Skeleton width={64} />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        { onChange ? RELATIONSHIP_PERMISSION_OPTIONS.map((option) => (
          <MenuItem
            key={`PermissionOption${option}`}
            label={RELATIONSHIP_PERMISSION_DISPLAY_MAP[option]}
            onClick={async () => await _onChange(option)}
            disabled={!canUpdate}
            sx={{ minWidth: width }}
          />
        )) : null}
        { onChange && onDelete ? <Divider /> : null}
        { onDelete ? (
          <MenuItem
            key='PermissionOptionDelete'
            onClick={_onDelete}
            label='Remove access'
            disabled={!canDelete}
            sx={{ minWidth: width }}
          />
        ) : null}
      </Menu>
    </>
  );
}

PermissionOptionButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  canUpdate: PropTypes.bool,
  canDelete: PropTypes.bool,
  width: PropTypes.number,
};

export default PermissionOptionButton;
