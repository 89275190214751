import { ENTITY_TASK } from 'constants/schemas';
import { isEqual } from 'lodash';
import { isTimeApproxEq } from 'utils/date.utils';
import moment from 'moment';

export default {
  [ENTITY_TASK]: {
    valueIsEqual: (v1, v2, prop) => {
      if (prop === 'due_date') {
        const momentV1 = moment(v1);
        const momentV2 = moment(v2);
        return isTimeApproxEq(momentV1, momentV2);
      }
      if (prop === 'assignees') {
        const v1Sorted = [...v1].sort();
        const v2Sorted = [...v2].sort();
        return isEqual(v1Sorted, v2Sorted);
      }
      return isEqual(v1, v2);
    },
  },
};
