import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
  ENTITY_USER,
} from 'constants/schemas';
import {
  GenericProp,
  directoryActions,
  experimentActions,
  noteActions,
  sopActions,
  taskActions,
} from 'store/entity';
import React, { useEffect } from 'react';
import { formatType, getLink } from 'utils/entity.utils';
import { useEntity, useEntityHasLoaded, useEntityPermission } from 'hooks/store.hooks';

import { ACTION_ADMIN } from 'constants/permission.constants';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import Field from 'components/Field';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import ShareExistingDocumentField from './ShareExistingDocumentField';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { useGenericId } from 'utils/generic.utils';

const titleProp = new GenericProp({
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
});

const fetchActionMap = {
  [ENTITY_DIRECTORY]: directoryActions.fetchDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.fetchExperiment,
  [ENTITY_NOTE]: noteActions.fetchNote,
  [ENTITY_SOP]: sopActions.fetchSop,
  [ENTITY_TASK]: taskActions.fetchTask,
};

function ShareDialog(props) {
  const { open, onClose, id, type } = props;
  const dispatch = useDispatch();
  const objectGenericId = useGenericId(id, type);
  const title = useEntity(objectGenericId, titleProp);
  const titleHasLoaded = useEntityHasLoaded(objectGenericId, titleProp);
  const isAdmin = useEntityPermission(objectGenericId, ACTION_ADMIN);
  const link = getLink({ id, type });

  useEffect(() => {
    if (open && isDefined(id) && isDefined(type)) {
      dispatch(fetchActionMap[type]({ id }));
    }
  }, [open, id, type, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={titleHasLoaded ? `Share "${title}"` : <Skeleton width={150} />}
      noDefaultDialogActions
      maxWidth='sm'
      fullWidth
    >
      { !isAdmin ? (
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{ mb: 1 }}
        >
          You do not have permission to manage access.
        </Typography>
      ) : null}
      <Field
        label='Link'
        labelProps={{ help: `${formatType(ENTITY_USER, { plural: true })} still need to be granted access to open the link.` }}
        readOnly
      >
        <Link
          to={link}
          target='_blank'
          rel='noopener noreferrer'
        >
          {link}
        </Link>
      </Field>
      <ShareExistingDocumentField id={id} type={type} />
    </Dialog>
  );
}

ShareDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  id: PropTypes.number,
  type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
};

export default ShareDialog;
