import { userActions, userSelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import TeamDetails from './TeamDetails';
import TeamUsers from './TeamUsers';
import { withEntity } from 'hooks/Entity.hooks';

function Team(props) {
  const { id } = props;
  return (
    <Box sx={{ px: 5, py: 4, flexGrow: 1, display: 'flex' }}>
      <Box sx={{ minWidth: 400, maxWidth: 800, mx: 'auto' }}>
        <TeamDetails id={id} />
        <TeamUsers id={id} />
      </Box>
    </Box>
  );
}

Team.propTypes = {
  id: PropTypes.number.isRequired,
};

export default withEntity(Team, {
  fetchAction: userActions.fetchTeam,
  hasLoadedSelector: userSelectors.selectTeamHasLoaded,
  readOnlySelector: (state, id) => !userSelectors.selectTeamPermission(state, id, ACTION_UPDATE),
});
