import React, { useMemo, useState } from 'react';
import { useDepartment, useMyId, useUser } from 'hooks/store.hooks';

import { AvatarSpace } from 'components/Avatar';
import { ENTITY_DEPARTMENT } from 'constants/schemas';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import { SPACE_DEPARTMENT } from 'constants/space.constants';
import { formatType } from 'utils/entity.utils';

function OpusOrgMenuItem(props) {
  const { onSelect } = props;
  const [nestedMenuOpen, setNestedMenuOpen] = useState(true);
  const myId = useMyId();
  const myDepartmentIds = useUser(myId, 'departments');
  const myDepartmentNames = useDepartment(myDepartmentIds, 'name');
  const myDepartments = useMemo(() => {
    const departments = (myDepartmentIds ?? []).map((id, idx) => ({ id, name: myDepartmentNames[idx] }));
    departments.sort((d1, d2) => d1.name.localeCompare(d2.name));
    return departments;
  }, [myDepartmentIds, myDepartmentNames]);

  const onClick = (departmentId) => {
    onSelect(SPACE_DEPARTMENT, departmentId);
    setNestedMenuOpen(false);
  };

  return (
    <MenuItem
      icon={<AvatarSpace spaceType={SPACE_DEPARTMENT} />}
      label='Opus Org'
      listItemTextProps={{
        secondary: `Content shared with one of your ${formatType(ENTITY_DEPARTMENT, { plural: true, lowercase: true })}`,
      }}
      nested
      nestedMenuProps={{ open: nestedMenuOpen }}
    >
      { myDepartments.map((department) => (
        <MenuItem
          key={`department-${department.id}`}
          icon={<AvatarSpace spaceType={SPACE_DEPARTMENT} spaceId={department.id} />}
          label={department.name}
          onClick={() => onClick(department.id)}
        />
      ))}
      { myDepartments.length === 0 ? (
        <MenuItem label={`No ${formatType(ENTITY_DEPARTMENT, { plural: true })} Found`} disabled />
      ) : null}
    </MenuItem>
  );
}

OpusOrgMenuItem.propTypes = {
  onSelect: PropTypes.func,
};

export default OpusOrgMenuItem;
