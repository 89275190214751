import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { ENTITY_DIRECTORY, ENTITY_MASTER_NOTE } from 'constants/schemas';
import React, { useCallback, useMemo, useState } from 'react';

import { ACTION_UPDATE } from 'constants/permission.constants';
import { ActiveDragContext } from './useActiveDrag';
import ItemOverlay from './ItemOverlay';
import PropTypes from 'prop-types';
import { directoryActions } from 'store/entity';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { notificationActions } from 'store/notification';
import { useDispatch } from 'react-redux';

export const getDroppableProps = (directory, {
  idKey = 'id',
  masterNoteKey = 'master_note',
  permissionsKey = 'permissions',
  key = 'getDroppableProps',
} = {}) => ({
  id: `${ENTITY_DIRECTORY}-${directory[idKey]}-${key}`,
  data: {
    id: directory[idKey],
    type: ENTITY_DIRECTORY,
    masterNoteId: directory[masterNoteKey],
    permissions: directory[permissionsKey],
  },
});

export const getDraggableProps = (entity, entityType, {
  idKey = 'id',
  directoryItemKey = 'directory_item',
  key = 'getDraggableProps',
} = {}) => ({
  id: `${entityType}-${entity[idKey]}-${key}`,
  data: {
    id: entity[idKey],
    type: entityType,
    directoryItemId: entity[directoryItemKey],
  },
});

function DirectoryDndProvider(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(null);

  const onDragStart = useCallback((event) => {
    setActiveItem(event.active.data.current);
  }, []);

  const onDragEnd = useCallback((event) => {
    const { active, over } = event;
    const { current: src } = active?.data ?? {};
    const { current: dest } = over?.data ?? {};
    if (!isDefined(src) || !isDefined(dest)) return;

    const isValidDrop = dest.type === ENTITY_DIRECTORY && isDefined(dest.id);
    if (!isValidDrop) return;

    const isValidDrag = (
      isDefined(src.id) && isDefined(src.type) &&
      !(src.type === dest.type && src.id === dest.id)
    );
    if (!isValidDrag) return;

    const hasDropPermission = dest.permissions?.[ACTION_UPDATE];
    if (!hasDropPermission) {
      dispatch(notificationActions.enqueueSnackbar({
        key: `dndFailure${Date.now()}`,
        title: 'Permission denied',
        message: 'You do not have editor access to this location',
        variant: 'warning',
      }));
      return;
    }

    if (!isDefined(src.directoryItemId) || !isDefined(dest.masterNoteId)) {
      dispatch(notificationActions.enqueueSnackbar({
        key: `dndFailure${Date.now()}`,
        message: 'An unexpected error occurred while moving the selected item',
        variant: 'error',
      }));
      return;
    }

    dispatch(directoryActions.createMasterNoteItem({
      id: dest.masterNoteId,
      data: { directory_item: src.directoryItemId },
    }))
      .unwrap()
      .then((res) => {
        const masterNote = res?.entities?.[ENTITY_MASTER_NOTE]?.[res?.result];
        const template = masterNote?.is_template;
        const parentId = masterNote?.parent;
        const directoryType = res?.entities?.[ENTITY_DIRECTORY]?.[parentId]?.type;
        dispatch(notificationActions.enqueueSnackbar({
          key: `dndSuccess${Date.now()}`,
          message: `Item added to ${formatType(ENTITY_MASTER_NOTE, { lowercase: true, template, directoryType })}`,
          variant: 'success',
        }));
      })
      .catch(() => null); // Error notification already handled in notification.reduces.js
  }, [dispatch]);

  const pointSensorOptions = useMemo(() => ({ activationConstraint: { distance: 10 }}), []);
  const keyboardSensorOptions = useMemo(() => ({}), []);
  const mouseSensor = useSensor(MouseSensor, pointSensorOptions);
  const touchSensor = useSensor(TouchSensor, pointSensorOptions);
  const keyboardSensor = useSensor(KeyboardSensor, keyboardSensorOptions);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  return (
    <DndContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      <ActiveDragContext.Provider value={activeItem}>
        {children}

        <DragOverlay dropAnimation={null} zIndex={9999}>
          {activeItem ? (
            <ItemOverlay {...activeItem} />
          ) : null}
        </DragOverlay>
      </ActiveDragContext.Provider>
    </DndContext>
  );
}

DirectoryDndProvider.propTypes = {
  children: PropTypes.node,
};

export default DirectoryDndProvider;
