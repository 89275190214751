import { ENTITY_ACTION_DELETE, ENTITY_ACTION_OPEN, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import React, { useCallback } from 'react';
import { useUpdateEntity, useUserName, useUserRole, useUserRoleHasLoaded } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import { formatDate } from 'utils/date.utils';
import { formatType } from 'utils/entity.utils';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';

function UserRoleDetails(props) {
  const { id } = props;
  const navigate = useNavigate();
  const update = useUpdateEntity();
  const roleName = useUserRole(id, 'name');
  const created = useUserRole(id, 'created');
  const createdHasLoaded = useUserRoleHasLoaded(id, 'created');
  const createdBy = useUserRole(id, 'created_by');
  const createdByHasLoaded = useUserRoleHasLoaded(id, 'created_by');
  const user = useUserName(createdBy);
  const users = useUserRole(id, 'users');
  const usersHasLoaded = useUserRoleHasLoaded(id, 'users');
  const modified = useUserRole(id, 'modified');
  const modifiedHasLoaded = useUserRoleHasLoaded(id, 'modified');
  const isReadOnly = useEntityIsReadOnly();

  const onEdit = (value) => {
    if ((value ?? '').length > 0 && value !== roleName) {
      update({ type: ENTITY_USER_ROLE, id, key: 'name', value });
    }
  };

  const onDeleteSuccess = useCallback(() => navigate('/settings/user-roles'), [navigate]);

  const extraActions = useEntityActions(id, ENTITY_USER_ROLE, {
    exclude: [ENTITY_ACTION_OPEN],
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess }},
  });

  return (
    <EntityActionState>
      <SectionHeader
        sticky
        title={roleName}
        onEdit={isReadOnly ? null : onEdit}
        extraActions={{ inMenu: extraActions }}
      />
      <Box sx={{ columns: '2 auto', mt: 1 }}>
        <Field
          label='Created By'
          value={user}
          loading={!createdByHasLoaded}
          labelProps={{ sx: { mt: 0 }}}
        />
        <Field
          label={`Number of Associated ${formatType(ENTITY_USER, { plural: true })}`}
          value={users?.length}
          loading={!usersHasLoaded}
        />
        <Field
          label='Date Created'
          value={formatDate(created)}
          loading={!createdHasLoaded}
        />
        <Field
          label='Last Modified'
          value={formatDate(modified)}
          loading={!modifiedHasLoaded}
        />
      </Box>
    </EntityActionState>
  );
}

UserRoleDetails.propTypes = {
  id: PropTypes.number.isRequired,
};

export default UserRoleDetails;
