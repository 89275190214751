import { BLOCK_CALCULATION, BLOCK_INPUT } from 'components/CustomEditor/plugin/Variable/Variable.constants';
import {
  ENTITY_EXPERIMENT,
  ENTITY_EXPERIMENT_SECTION,
  ENTITY_EXPERIMENT_SUBSECTION,
  ENTITY_SOP,
  ENTITY_SOP_SECTION,
  ENTITY_SOP_SUBSECTION,
} from 'constants/schemas';
import { Prop, entitySelectors } from 'store/entity';

import { EditorPlugins } from '@acheloisbiosoftware/absui.core';
import { SCOPE } from 'store/store.constants';
import { createSelector } from '@reduxjs/toolkit';
import { getGenericId } from 'utils/generic.utils';

const { Element } = EditorPlugins;

const selectScopeSlice = (state) => state[SCOPE];
const selectScopeUpdates = (state) => selectScopeSlice(state).scopeUpdates;
const selectEntityScopeUpdates = (state, genericId) => selectScopeUpdates(state)[genericId?.type]?.[genericId?.id];

const extractVariableNodes = createSelector(
  (fragment) => fragment,
  (fragment = []) => {
    const nodes = [];
    for (const node of fragment) {
    /**
     * NOTE: this is dependent on Variable.isVariable in CustomEditor/plugin/Variable.
     * If that changes, this will need to change as well. Isn't using the method
     * itself to avoid circular dependencies.
     */
      if (Element.isElement(node, BLOCK_INPUT) || Element.isElement(node, BLOCK_CALCULATION)) {
        nodes.push(node);
      } else if (node.children) {
        nodes.push(...extractVariableNodes(node.children));
      }
    }
    return nodes;
  },
);

const sectionsProp = new Prop('sections');
const sectionTypeMapping = {
  [ENTITY_SOP]: ENTITY_SOP_SECTION,
  [ENTITY_EXPERIMENT]: ENTITY_EXPERIMENT_SECTION,
};
const subsectionsProp = new Prop('subsections');
const subsectionTypeMapping = {
  [ENTITY_SOP]: ENTITY_SOP_SUBSECTION,
  [ENTITY_EXPERIMENT]: ENTITY_EXPERIMENT_SUBSECTION,
};
const descriptionProp = new Prop('description');
const definitionProp = new Prop('definition');
const selectEntityVariablesCurried = createSelector(
  (state, genericId) => genericId,
  (state, genericId) => entitySelectors.selectEntity(state, genericId, sectionsProp),
  (genericId, sectionIds) => {
    const sectionType = sectionTypeMapping[genericId?.type];
    const genericSectionIds = sectionIds.map((id) => getGenericId(id, sectionType));
    return createSelector(
      (state) => entitySelectors.selectEntity(state, genericSectionIds, subsectionsProp),
      (subsectionIds) => {
        const subsectionType = subsectionTypeMapping[genericId?.type];
        const genericSubsectionIds = subsectionIds.flat().map((id) => getGenericId(id, subsectionType));
        return createSelector(
          (state) => entitySelectors.selectEntity(state, genericSubsectionIds, descriptionProp),
          (state) => entitySelectors.selectEntity(state, genericSubsectionIds, definitionProp),
          (descriptions = [], definitions = []) => [
            ...descriptions.flatMap((description) => extractVariableNodes(description?.webComponents)),
            ...definitions.flatMap((definition) => extractVariableNodes(definition?.webComponents)),
          ],
        );
      },
    );
  },
);

export const selectScopeVariableList = createSelector(
  (state, genericId) => selectEntityVariablesCurried(state, genericId)(state)(state),
  (state, genericId) => selectEntityScopeUpdates(state, genericId),
  (variableList, scopeUpdates) => {
    const variables = variableList.map((variable) => ({ ...variable, ...scopeUpdates?.[variable.uuid] }));
    for (const [uuid, variable] of Object.entries(scopeUpdates ?? {})) {
      if (!variables.some((v) => v.uuid === uuid)) {
        variables.push(variable);
      }
    }

    return variables;
  },
);

export const selectScopeVariables = createSelector(
  selectScopeVariableList,
  (variableList) => Object.fromEntries(variableList.map((variable) => [variable.uuid, variable])),
);
