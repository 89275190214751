import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import {
  SearchField,
  SelectorProp,
  directoryActions,
  entitySelectors,
  sopSelectors,
  userSelectors,
} from 'store/entity';
import { useDirectoryItemList, useFetchListing, useMyGlobalPermission } from 'hooks/store.hooks';
import { useEffect, useMemo, useState } from 'react';

import { ACTION_VIEW } from 'constants/permission.constants';
import { getGenericId } from 'utils/generic.utils';
import { useDebouncedFn } from '@acheloisbiosoftware/absui.hooks';

const createSearchField = (weight, type, getField) => new SearchField(
  new SelectorProp((state, directoryItem) => {
    if (directoryItem.content_type !== type) return '';
    const genericId = getGenericId(directoryItem.object_id, directoryItem.content_type);
    const entity = entitySelectors.selectEntity(state, genericId);
    return getField(entity, state) ?? '';
  }),
  weight,
);

const searchFields = [
  createSearchField(1, ENTITY_DIRECTORY, (directory) => directory?.name),
  createSearchField(0.5, ENTITY_DIRECTORY, (directory, state) => userSelectors.selectUserName(state, directory?.created_by)),
  createSearchField(1, ENTITY_EXPERIMENT, (exp) => exp?.title),
  createSearchField(1, ENTITY_EXPERIMENT, (exp) => exp?.exp_code),
  createSearchField(0.5, ENTITY_EXPERIMENT, (exp, state) => userSelectors.selectUserName(state, exp?.created_by)),
  createSearchField(0.2, ENTITY_EXPERIMENT, (exp, state) => sopSelectors.selectSop(state, exp?.related_sop, 'title')),
  createSearchField(1, ENTITY_NOTE, (note) => note?.title),
  createSearchField(1, ENTITY_NOTE, (note) => note?.note_code),
  createSearchField(0.5, ENTITY_NOTE, (note, state) => userSelectors.selectUserName(state, note?.created_by)),
  createSearchField(1, ENTITY_SOP, (sop) => sop?.long_title),
  createSearchField(0.5, ENTITY_SOP, (sop, state) => sopSelectors.selectCategory(state, sop?.category, 'name')),
  createSearchField(0.5, ENTITY_SOP, (sop, state) => userSelectors.selectUserName(state, sop?.created_by)),
  createSearchField(1, ENTITY_TASK, (task) => task?.title),
  createSearchField(1, ENTITY_TASK, (task) => task?.task_code),
  createSearchField(0.5, ENTITY_TASK, (task, state) => userSelectors.selectUserName(state, task?.created_by)),
];

const DEFAULT_TYPES = [ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK];
const DEFAULT_EXCLUDE_TYPES = [];

const useGlobalSearch = (search, {
  types = DEFAULT_TYPES,
  excludeTypes = DEFAULT_EXCLUDE_TYPES,
  filter = () => true,
  debounce = 500,
} = {}) => {
  const canViewDirectories = useMyGlobalPermission(ENTITY_DIRECTORY, ACTION_VIEW);
  const canViewExperiments = useMyGlobalPermission(ENTITY_EXPERIMENT, ACTION_VIEW);
  const canViewNotes = useMyGlobalPermission(ENTITY_NOTE, ACTION_VIEW);
  const canViewSops = useMyGlobalPermission(ENTITY_SOP, ACTION_VIEW);
  const canViewTasks = useMyGlobalPermission(ENTITY_TASK, ACTION_VIEW);

  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const _setDebouncedSearch = useDebouncedFn(setDebouncedSearch, debounce);
  const debounceLoading = debounce && debouncedSearch !== search;
  const _search = debounce ? debouncedSearch : search;

  useEffect(() => {
    _setDebouncedSearch(search);
  }, [search, _setDebouncedSearch]);

  const viewPermissionMap = useMemo(() => ({
    [ENTITY_DIRECTORY]: canViewDirectories,
    [ENTITY_EXPERIMENT]: canViewExperiments,
    [ENTITY_NOTE]: canViewNotes,
    [ENTITY_SOP]: canViewSops,
    [ENTITY_TASK]: canViewTasks,
  }), [canViewDirectories, canViewExperiments, canViewNotes, canViewSops, canViewTasks]);

  const _types = useMemo(() => types.filter((type) => (
    viewPermissionMap[type] && !excludeTypes.includes(type)
  )), [types, viewPermissionMap, excludeTypes]);

  const fetchOptions = useMemo(() => ({
    params: { search: _search, filters: { content_types: _types }},
    condition: _search && _types.length > 0,
  }), [_search, _types]);
  const loading = useFetchListing(directoryActions.fetchDirectoryItems, fetchOptions);

  const listParams = useMemo(() => ({
    prop: { id: 'object_id', type: 'content_type' },
    filter: (directoryItem, state) => _types.includes(directoryItem.content_type) && filter(directoryItem, state),
    search,
    searchFields,
  }), [search, _types, filter]);

  const showResults = Boolean(search);
  const genericIds = useDirectoryItemList(listParams);
  return useMemo(() => ({
    results: showResults ? genericIds : [],
    loading,
    debounceLoading,
  }), [genericIds, loading, showResults, debounceLoading]);
};

export default useGlobalSearch;
