import NewNoteForm, { useNewNoteFormData } from 'components/NewNoteForm';
import React, { useEffect, useState } from 'react';
import { formatType, getPath } from 'utils/entity.utils';

import { Dialog } from '@acheloisbiosoftware/absui.core';
import { ENTITY_NOTE } from 'constants/schemas';
import PropTypes from 'prop-types';
import { getGenericId } from 'utils/generic.utils';
import { noteActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function NewNoteDialog(props) {
  const { open, onClose, initState } = props;
  const {
    formData,
    valid,
    resetFormData,
    updateFormData,
  } = useNewNoteFormData(initState);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (open) resetFormData();
  }, [open, resetFormData]);

  const _onClose = () => {
    resetFormData();
    onClose();
  };

  const onConfirm = () => {
    setLoading(true);
    dispatch(noteActions.createNote(formData))
      .unwrap()
      .then((res) => {
        _onClose();
        navigate(getPath(getGenericId(res.result, ENTITY_NOTE)));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      title={`New ${formatType(ENTITY_NOTE, { template: formData?.is_template })}`}
      open={open}
      onClose={_onClose}
      confirmButtonText='Create'
      disableCloseOnConfirm
      confirmDisabled={!valid}
      onConfirm={onConfirm}
      loading={loading}
      fullWidth
      maxWidth='xs'
    >
      <NewNoteForm
        formData={formData}
        updateFormData={updateFormData}
      />
    </Dialog>
  );
}

NewNoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  initState: PropTypes.object,
};

export default NewNoteDialog;
