import { DepartmentIcon, TeamIcon, UserRoleIcon } from 'constants/icon.constants';
import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import React, { useRef, useState } from 'react';
import { blue, deepOrange, green, pink, purple, teal } from '@mui/material/colors';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useUser, useUserInitials } from 'hooks/store.hooks';

import { Image } from '@acheloisbiosoftware/absui.core';
import { MODE_LIGHT } from 'components/ThemeProvider';
import MuiAvatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const AVATAR_COLORS_LIGHT = [
  teal[400],
  purple[400],
  blue[400],
  pink[400],
  green[400],
  deepOrange[400],
];

const AVATAR_COLORS_DARK = [
  teal[100],
  purple[100],
  blue[100],
  pink[100],
  green[100],
  deepOrange[100],
];

const getAvatarColor = (id, type, appearance) => {
  if (!isDefined(id)) return null;
  const colors = appearance === MODE_LIGHT ? AVATAR_COLORS_LIGHT : AVATAR_COLORS_DARK;
  return colors[id % colors.length];
};

function Avatar(props) {
  const {
    size,
    id,
    type,
    sx,
    children,
    ...restProps
  } = props;
  const userInitials = useUserInitials(id);
  const userAvatar = useUser(id, 'avatar');
  const [imageLoading, setImageLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [isVerticalImage, setIsVerticalImage] = useState(true);
  const imageRef = useRef(null);
  const showingAvatar = type === ENTITY_USER && isDefined(userAvatar) && !imageLoading && !imageError;

  const onImageLoad = () => {
    setImageError(false);
    setIsVerticalImage((imageRef.current?.naturalWidth ?? 0) < (imageRef.current?.naturalHeight ?? 0));
    setImageLoading(false);
  };

  const onImageError = () => {
    setImageError(true);
    setImageLoading(false);
  };

  const _children = type === ENTITY_DEPARTMENT ? (
    <DepartmentIcon sx={{ fontSize: size * 0.7 }} />
  ) : type === ENTITY_TEAM ? (
    <TeamIcon sx={{ fontSize: size * 0.7 }} />
  ) : type === ENTITY_USER ? (
    userAvatar ? (
      <>
        <Image
          ref={imageRef}
          src={userAvatar}
          sx={[
            { objectFit: 'cover', width: 1, height: 1 },
            !showingAvatar ? { display: 'none' } : {},
            !imageLoading && isVerticalImage ? { width: 1, height: 'auto' } : {},
            !imageLoading && !isVerticalImage ? { width: 'auto', height: 1 } : {},
          ]}
          onLoad={onImageLoad}
          onError={onImageError}
        />
        {!showingAvatar ? userInitials : null}
      </>
    ) : userInitials
  ) : type === ENTITY_USER_ROLE ? (
    <UserRoleIcon sx={{ fontSize: size * 0.7 }} />
  ) : children;

  return isDefined(id) && !isDefined(_children) ? (
    <Skeleton variant='circular' width={size} height={size} />
  ) : (
    <MuiAvatar
      {...restProps}
      sx={mergeSx({
        width: size,
        height: size,
        fontSize: size / 2,
        bgcolor: (theme) => (showingAvatar ? null : getAvatarColor(id, type, theme.palette.mode)),
      }, sx)}
    >
      { _children }
    </MuiAvatar>
  );
}

Avatar.propTypes = {
  size: PropTypes.number,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.oneOf([
    ENTITY_DEPARTMENT,
    ENTITY_TEAM,
    ENTITY_USER,
    ENTITY_USER_ROLE,
  ]),
  sx: sxPropType,
  children: PropTypes.node,
};

Avatar.defaultProps = {
  size: 24,
};

export default Avatar;
