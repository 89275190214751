import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import { ENTITY_DEPARTMENT, ENTITY_DIRECTORY, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import React, { useMemo } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { useEntity, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { GenericProp } from 'store/entity';
import ListingPage from 'components/ListingPage';
import PropTypes from 'prop-types';
import WorkspacesContent from './WorkspacesContent';
import WorkspacesTable from './WorkspacesTable';
import { formatType } from 'utils/entity.utils';

const parseSelected = (selected) => parseFloat(selected);
const validateSelected = (selected) => !isNaN(selected);

const nameProp = new GenericProp({
  [ENTITY_USER]: 'full_name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_DEPARTMENT]: 'name',
});

function Workspaces(props) {
  const { type, templates = false, pageContainerProps } = props;

  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const individualName = useEntity(spaceGenericId, nameProp);
  const workspaceDisplayType = formatType(ENTITY_DIRECTORY, { plural: true, directoryType: type, template: templates });
  const title = spaceType === SPACE_USER ? (
    individualName ? `${individualName}'s ${workspaceDisplayType}` : null
  ) : [SPACE_TEAM, SPACE_DEPARTMENT].includes(spaceType) ? (
    individualName ? `${individualName} ${workspaceDisplayType}` : null
  ) : workspaceDisplayType;

  const sectionHeaderProps = useMemo(() => ({
    title,
    skeletonProps: { width: 300 },
  }), [title]);

  const tableComponentProps = useMemo(() => ({ type, templates }), [type, templates]);

  return (
    <ListingPage
      initListView
      parseSelected={parseSelected}
      validateSelected={validateSelected}
      pageContainerProps={pageContainerProps}
      sectionHeaderProps={sectionHeaderProps}
      tableComponent={WorkspacesTable}
      tableComponentProps={tableComponentProps}
      contentComponent={WorkspacesContent}
    />
  );
}

Workspaces.propTypes = {
  type: PropTypes.oneOf([
    DIR_TYPE_STUDY,
    DIR_TYPE_PROJECT,
    DIR_TYPE_PROGRAM,
  ]),
  templates: PropTypes.bool,
  pageContainerProps: PropTypes.object,
};

export default Workspaces;
