import React, { useCallback, useMemo, useState } from 'react';
import { useMasterNote, useMasterNoteSection } from 'hooks/store.hooks';

import CollapseButton from 'components/CollapseButton';
import DataTable from 'components/DataTable';
import MasterNoteSectionTable from 'components/MasterNoteSectionTable';
import { Prop } from 'store/entity';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const EMPTY_ARRAY = [];
const masterNoteSectionProp = new Prop({
  id: 'id',
  title: 'title',
});
const dataTableSx = { overflow: 'auto' };
const tableProps = { size: 'small' };

function MasterNoteContentCollapsed(props) {
  const { id, loading, selectedItem } = props;
  const masterNoteSectionIds = useMasterNote(id, 'sections');
  const masterNoteSections = useMasterNoteSection(masterNoteSectionIds ?? EMPTY_ARRAY, masterNoteSectionProp);

  const rows = useMemo(() => masterNoteSections.map((section) => ({
    ...section,
    expandedChildren: (
      <MasterNoteSectionTable
        id={section.id}
        collapsed
        selectedItem={selectedItem}
      />
    ),
  })), [masterNoteSections, selectedItem]);

  const [expandedRows, setExpandedRows] = useState(() => rows.map((row) => row.id));

  const toggleRowExpanded = useCallback((row) => {
    setExpandedRows((prev) => (prev.includes(row.id) ? prev.filter((_id) => _id !== row.id) : [...prev, row.id]));
  }, []);

  const columns = useMemo(() => [{
    id: 'title',
    field: 'title',
    renderValue: ({ row, value }) => (
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography
          variant='caption'
          color='text.secondary'
          sx={{ fontWeight: 'fontWeightBold' }}
        >
          {value || 'Untitled'}
        </Typography>
        <CollapseButton
          open={expandedRows.includes(row.id)}
          iconProps={{
            fontSize: 'small',
          }}
        />
      </Stack>
    ),
    cellProps: { sx: { bgcolor: 'background.grey' }},
  }], [expandedRows]);

  return (
    <DataTable
      rows={rows}
      columns={columns}
      onRowClick={toggleRowExpanded}
      loading={loading}
      expandedRows={expandedRows}
      noHeader
      disableFilter
      disableGroupBy
      tableProps={tableProps}
      sx={dataTableSx}
    />
  );
}

MasterNoteContentCollapsed.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
  selectedItem: PropTypes.number,
};

export default MasterNoteContentCollapsed;
