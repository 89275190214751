import { eventListenerEffect } from '@acheloisbiosoftware/absui.hooks';
import { isMetaEvent } from '@acheloisbiosoftware/absui.utils';

const saveShortcutEffect = (callbackFn) => eventListenerEffect(document, 'keydown', (event) => {
  if (isMetaEvent(event) && event.key === 's') {
    callbackFn?.(event);
    event.preventDefault();
  }
});

export default saveShortcutEffect;
