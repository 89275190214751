import * as UserService from 'services/UserService';
import * as schemas from 'constants/schemas';
import * as userSelectors from './user.selectors';

import { createServiceThunk } from 'store/store.actions.utils';

// #############################################################################
// ################################ Departments ################################
// #############################################################################
export const fetchDepartments = createServiceThunk({
  name: `${schemas.ENTITY_DEPARTMENT}/fetchDepartments`,
  service: UserService.fetchDepartments,
  schema: [schemas.department],
  isPaginated: true,
});

export const createDepartment = createServiceThunk({
  name: `${schemas.ENTITY_DEPARTMENT}/createDepartment`,
  service: UserService.createDepartment,
  schema: schemas.department,
});

export const fetchDepartment = createServiceThunk({
  name: `${schemas.ENTITY_DEPARTMENT}/fetchDepartment`,
  service: UserService.fetchDepartment,
  schema: schemas.department,
});

export const patchDepartment = createServiceThunk({
  name: `${schemas.ENTITY_DEPARTMENT}/patchDepartment`,
  service: UserService.patchDepartment,
  schema: schemas.department,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: userSelectors.selectDepartmentUpdates(getState(), id),
  }),
});

export const deleteDepartment = createServiceThunk({
  name: `${schemas.ENTITY_DEPARTMENT}/deleteDepartment`,
  service: UserService.deleteDepartment,
  schema: schemas.department,
});


// #############################################################################
// ################################### Teams ###################################
// #############################################################################
export const fetchTeams = createServiceThunk({
  name: `${schemas.ENTITY_TEAM}/fetchTeams`,
  service: UserService.fetchTeams,
  schema: [schemas.team],
  isPaginated: true,
});

export const createTeam = createServiceThunk({
  name: `${schemas.ENTITY_TEAM}/createTeam`,
  service: UserService.createTeam,
  schema: schemas.team,
});

export const fetchTeam = createServiceThunk({
  name: `${schemas.ENTITY_TEAM}/fetchTeam`,
  service: UserService.fetchTeam,
  schema: schemas.team,
});

export const patchTeam = createServiceThunk({
  name: `${schemas.ENTITY_TEAM}/patchTeam`,
  service: UserService.patchTeam,
  schema: schemas.team,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: userSelectors.selectTeamUpdates(getState(), id),
  }),
});

export const deleteTeam = createServiceThunk({
  name: `${schemas.ENTITY_TEAM}/deleteTeam`,
  service: UserService.deleteTeam,
  schema: schemas.team,
});


// #############################################################################
// ################################ User Roles #################################
// #############################################################################
export const fetchUserRoles = createServiceThunk({
  name: `${schemas.ENTITY_USER_ROLE}/fetchUserRoles`,
  service: UserService.fetchUserRoles,
  schema: [schemas.userRole],
  isPaginated: true,
});

export const createUserRole = createServiceThunk({
  name: `${schemas.ENTITY_USER_ROLE}/createUserRole`,
  service: UserService.createUserRole,
  schema: schemas.userRole,
});

export const fetchUserRole = createServiceThunk({
  name: `${schemas.ENTITY_USER_ROLE}/fetchUserRole`,
  service: UserService.fetchUserRole,
  schema: schemas.userRole,
});

export const patchUserRole = createServiceThunk({
  name: `${schemas.ENTITY_USER_ROLE}/patchUserRole`,
  service: UserService.patchUserRole,
  schema: schemas.userRole,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: userSelectors.selectUserRoleUpdates(getState(), id),
  }),
});

export const deleteUserRole = createServiceThunk({
  name: `${schemas.ENTITY_USER_ROLE}/deleteUserRole`,
  service: UserService.deleteUserRole,
  schema: schemas.userRole,
});


// #############################################################################
// ################################### Users ###################################
// #############################################################################
export const fetchUsers = createServiceThunk({
  name: `${schemas.ENTITY_USER}/fetchUsers`,
  service: UserService.fetchUsers,
  schema: [schemas.user],
  isPaginated: true,
});

export const createUser = createServiceThunk({
  name: `${schemas.ENTITY_USER}/createUser`,
  service: UserService.createUser,
  schema: schemas.user,
});

export const fetchUser = createServiceThunk({
  name: `${schemas.ENTITY_USER}/fetchUser`,
  service: UserService.fetchUser,
  schema: schemas.user,
});

export const patchUser = createServiceThunk({
  name: `${schemas.ENTITY_USER}/patchUser`,
  service: UserService.patchUser,
  schema: schemas.user,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: userSelectors.selectUserUpdates(getState(), id),
  }),
});

export const patchUserPreferences = createServiceThunk({
  name: `${schemas.ENTITY_USER}/patchUserPreferences`,
  service: UserService.patchUserPreferences,
  schema: schemas.user,
});
