import React, { useState } from 'react';

import { ACTION_CREATE } from 'constants/permission.constants';
import { AddIcon } from 'constants/icon.constants';
import { DataTableAction } from 'components/DataTable';
import { ENTITY_DEPARTMENT } from 'constants/schemas';
import NewDepartmentDialog from './NewDepartmentDialog';
import { formatType } from 'utils/entity.utils';
import { useMyGlobalPermission } from 'hooks/store.hooks';

function ActionAddDepartment() {
  const [newDepartmentDialogOpen, setNewDepartmentDialogOpen] = useState(false);
  const canCreateDepartment = useMyGlobalPermission(ENTITY_DEPARTMENT, ACTION_CREATE);
  return !canCreateDepartment ? null : (
    <DataTableAction
      label={`Add ${formatType(ENTITY_DEPARTMENT)}`}
      icon={<AddIcon />}
      onClick={() => setNewDepartmentDialogOpen(true)}
    >
      <NewDepartmentDialog
        open={newDepartmentDialogOpen}
        onClose={() => setNewDepartmentDialogOpen(false)}
      />
    </DataTableAction>
  );
}


export default ActionAddDepartment;
