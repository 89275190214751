import * as directoryActions from './directory.actions';

import { ENTITY_DIRECTORY, ENTITY_MASTER_NOTE, ENTITY_MASTER_NOTE_ITEM, ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, directoryActions.fetchDirectories);
  reduceEntities(builder, directoryActions.createDirectory);
  reduceEntities(builder, directoryActions.fetchDirectory, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_DIRECTORY)),
  });
  reduceEntities(builder, directoryActions.patchDirectory);
  reduceEntities(builder, directoryActions.deleteDirectory, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_DIRECTORY)),
  });
  reduceEntities(builder, directoryActions.deleteDirectoryPermanently, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_DIRECTORY)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_DIRECTORY)),
  });
  reduceEntities(builder, directoryActions.checkinDirectory);
  reduceEntities(builder, directoryActions.checkoutDirectory);
  reduceEntities(builder, directoryActions.cloneDirectory);
  reduceEntities(builder, directoryActions.generateDirectoryTemplate);
  reduceEntities(builder, directoryActions.instantiateDirectory);
  reduceEntities(builder, directoryActions.restoreDirectory);
  reduceEntities(builder, directoryActions.fetchDirectoryItems);
  reduceEntities(builder, directoryActions.fetchMasterNote, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_MASTER_NOTE)),
  });
  reduceEntities(builder, directoryActions.patchMasterNote);
  reduceEntities(builder, directoryActions.createMasterNoteItem);
  reduceEntities(builder, directoryActions.createMasterNoteSection);
  reduceEntities(builder, directoryActions.deleteMasterNoteItem, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.meta.arg.id, ENTITY_MASTER_NOTE_ITEM)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_MASTER_NOTE_ITEM)),
  });
  reduceEntities(builder, directoryActions.deleteMasterNoteSection, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.meta.arg.id, ENTITY_MASTER_NOTE_SECTION)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_MASTER_NOTE_SECTION)),
  });
};
