import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import { DeleteIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import { useDeleteDialogContext } from './EntityActionState';

function ActionDelete(props) {
  const {
    id,
    type,
    onBeforeDelete,
    onDeleteSuccess,
    onDeleteFailure,
    ...restProps
  } = props;
  const { setDeleteDialogProps } = useDeleteDialogContext();

  const onAction = useCallback(() => {
    setDeleteDialogProps?.((oldProps) => ({
      ...oldProps,
      open: true,
      id,
      type,
      onBeforeDelete,
      onDeleteSuccess,
      onDeleteFailure,
    }));
  }, [setDeleteDialogProps, id, type, onBeforeDelete, onDeleteSuccess, onDeleteFailure]);

  return (
    <BaseAction
      icon={<DeleteIcon />}
      label='Delete'
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionDelete.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
  onBeforeDelete: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
};

export default ActionDelete;
