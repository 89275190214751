import {
  AllDocumentsIcon,
  SopIcon,
  TaskIcon,
  TemplateIcon,
  ToolsIcon,
} from 'constants/icon.constants';
import { AutoclonerLogo, ElimBioLogo, OktaLogo, SimplerQMSLogo } from 'assets/Images';
import { Button, Image } from '@acheloisbiosoftware/absui.core';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import CollapseButton from 'components/CollapseButton';
import ContentButton from 'components/ContentButton';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { formatType } from 'utils/entity.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMyGlobalPermission } from 'hooks/store.hooks';

function TabButton(props) {
  const { label, icon, selected, onSelect, to, dropDownOptions } = props;
  const location = useLocation();
  const hasDropDown = (dropDownOptions ?? []).length > 0;
  const [anchorEl, setAnchorEl] = useState(null);
  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const onOpen = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const onClose = useCallback(() => setAnchorEl(null), []);

  const toProps = useMemo(() => (to ? { to, component: RouterLink } : {}), [to]);
  const colorSx = useMemo(() => (selected ? { color: 'text.primary' } : {}), [selected]);
  const bgcolorSx = useMemo(() => (selected ? {
    'bgcolor': 'action.selected',
    '&:hover': {
      bgcolor: 'action.selected',
    },
  } : {}), [selected]);

  const listItemProps = useMemo(() => ({
    sx: { '& > .MuiListItemSecondaryAction-root': { right: (theme) => theme.spacing(0.75) }},
  }), []);
  const contentButtonListItemButtonProps = useMemo(() => ({
    ...toProps,
    sx: mergeSx({
      py: 0.25,
      px: 1,
      minHeight: 0,
    },
    colorSx,
    bgcolorSx),
  }), [toProps, colorSx, bgcolorSx]);
  const contentButtonListItemIconProps = useMemo(() => ({ sx: colorSx }), [colorSx]);
  const contentButtonListItemTextProps = useMemo(() => ({
    primaryTypographyProps: {
      variant: 'subtitle2',
    },
  }), []);
  const buttonSx = useMemo(() => mergeSx(colorSx, bgcolorSx), [colorSx, bgcolorSx]);

  const onClick = onSelect ?? onOpen;

  return (
    <>
      <Tooltip arrow title={!isMd ? label : null}>
        <Box component='span' sx={{ color: 'text.icon' }}>
          {isMd ? (
            <ContentButton
              icon={icon}
              content={label}
              onClick={onClick}
              listItemProps={listItemProps}
              listItemButtonProps={contentButtonListItemButtonProps}
              listItemIconProps={contentButtonListItemIconProps}
              listItemTextProps={contentButtonListItemTextProps}
            >
              {hasDropDown ? (
                <CollapseButton
                  open={Boolean(anchorEl)}
                  sx={{ p: 0.25, ml: 0.5 }}
                  iconProps={{ fontSize: 'small' }}
                  disableRipple
                />
              ) : null}
            </ContentButton>
          ) : (
            <Button
              icon
              size='small'
              color='inherit'
              onClick={onClick}
              sx={buttonSx}
              {...toProps}
            >
              {icon}
            </Button>
          )}
        </Box>
      </Tooltip>
      { hasDropDown ? (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
          slotProps={{ paper: { sx: { maxWidth: 400 }}}}
        >
          {dropDownOptions.map((option) => (
            <MenuItem
              key={option.id}
              {...option.menuItemProps}
              {...(option.path ? {
                component: RouterLink,
                to: option.path,
                selected: location.pathname === option.path,
              } : {})}
              onClick={() => {
                onSelect?.();
                onClose();
              }}
            />
          ))}
        </Menu>
      ) : null}
    </>
  );
}

TabButton.propTypes = {
  label: PropTypes.node,
  icon: PropTypes.node,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  to: PropTypes.any,
  dropDownOptions: PropTypes.arrayOf(PropTypes.object),
};

const docsDropDownOptions = [
  {
    id: 'nav-docs',
    menuItemProps: {
      icon: <AllDocumentsIcon />,
      label: 'All Docs',
    },
    path: '/all-documents',
    entityTypes: [ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK],
  },
  {
    id: 'nav-sops',
    menuItemProps: {
      icon: <SopIcon />,
      label: formatType(ENTITY_SOP, { plural: true }),
    },
    path: '/sops',
    entityTypes: [ENTITY_SOP],
  },
  {
    id: 'nav-tasks',
    menuItemProps: {
      icon: <TaskIcon />,
      label: formatType(ENTITY_TASK, { plural: true }),
    },
    path: '/tasks',
    entityTypes: [ENTITY_TASK],
  },
  {
    id: 'nav-templates',
    menuItemProps: {
      icon: <TemplateIcon />,
      label: 'Templates',
    },
    path: '/templates',
    entityTypes: [ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK],
  },
];

const imgProps = {
  width: 24,
  height: 24,
  sx: {
    borderRadius: 1,
    bgcolor: 'common.white',
    p: 0,
    border: 1,
    borderColor: 'divider',
  },
};
const getDescriptionProp = (description) => ({
  listItemTextProps: {
    secondary: description,
    sx: { whiteSpace: 'normal' },
  },
});
const getLinkProps = (href) => ({
  component: 'a',
  href,
  target: '_blank',
  rel: 'noopener noreferrer',
});
const toolsDropDownOptions = [
  {
    id: 'nav-autocloner',
    menuItemProps: {
      icon: <Image
        {...imgProps}
        src={AutoclonerLogo}
        alt='Autocloner'
      />,
      label: 'Autocloner',
      ...getDescriptionProp('Achelois utility application for plasmid design, cloning, and production.'),
      ...getLinkProps('https://autocloner.acheloisbio.com'),
    },
  },
  {
    id: 'nav-okta',
    menuItemProps: {
      icon: <Image
        {...imgProps}
        src={OktaLogo}
        alt='Okta'
      />,
      label: 'Okta',
      ...getDescriptionProp('Identity and access management for Achelois applications.'),
      ...getLinkProps('https://acheloispharma.okta.com'),
    },
  },
  {
    id: 'nav-simplerqms',
    menuItemProps: {
      icon: <Image
        {...imgProps}
        src={SimplerQMSLogo}
        alt='SimplerQMS'
      />,
      label: 'SimplerQMS',
      ...getDescriptionProp("Achelois' quality management system."),
      ...getLinkProps('https://achelois.cloudvault.m-files.com'),
    },
  },
  {
    id: 'nav-elimbio',
    menuItemProps: {
      icon: <Image
        {...imgProps}
        src={ElimBioLogo}
        alt='ElimBio'
      />,
      label: 'ElimBio',
      ...getDescriptionProp('Service company for DNA sequencing.'),
      ...getLinkProps('https://www.elimbio.com'),
    },
  },
];

const useFilteredOptions = (options, viewPermissions) => useMemo(
  () => options.filter((option) => option.entityTypes.some((entityType) => viewPermissions[entityType])),
  [options, viewPermissions],
);

function AppTabs() {
  const location = useLocation();
  const canViewDirectories = useMyGlobalPermission(ENTITY_DIRECTORY, ACTION_VIEW);
  const canViewExperiments = useMyGlobalPermission(ENTITY_EXPERIMENT, ACTION_VIEW);
  const canViewNotes = useMyGlobalPermission(ENTITY_NOTE, ACTION_VIEW);
  const canViewSops = useMyGlobalPermission(ENTITY_SOP, ACTION_VIEW);
  const canViewTasks = useMyGlobalPermission(ENTITY_TASK, ACTION_VIEW);

  const viewPermissions = useMemo(() => ({
    [ENTITY_DIRECTORY]: canViewDirectories,
    [ENTITY_EXPERIMENT]: canViewExperiments,
    [ENTITY_NOTE]: canViewNotes,
    [ENTITY_SOP]: canViewSops,
    [ENTITY_TASK]: canViewTasks,
  }), [canViewDirectories, canViewExperiments, canViewNotes, canViewSops, canViewTasks]);

  const docsDropDownOptionsFiltered = useFilteredOptions(docsDropDownOptions, viewPermissions);
  const docsIsSelected = useMemo(
    () => docsDropDownOptionsFiltered.some((option) => location.pathname === option.path),
    [docsDropDownOptionsFiltered, location.pathname],
  );

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={1}
    >
      <TabButton
        label='Docs'
        icon={<AllDocumentsIcon />}
        selected={docsIsSelected}
        dropDownOptions={docsDropDownOptionsFiltered}
      />
      <TabButton
        label='Tools'
        icon={<ToolsIcon />}
        dropDownOptions={toolsDropDownOptions}
      />
    </Stack>
  );
}

export default AppTabs;
