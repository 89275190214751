import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import { useEntity, useMyId } from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import { GenericProp } from 'store/entity';
import PermissionOptionButton from './PermissionOptionButton';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const nameProp = new GenericProp({
  [ENTITY_DEPARTMENT]: 'name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_USER]: 'full_name',
});

function AccessRow(props) {
  const {
    individualId,
    permission,
    onChange,
    onDelete,
    canUpdate,
    canDelete,
    hidden,
  } = props;
  const { id, type } = individualId ?? {};
  const name = useEntity(individualId, nameProp);
  const myId = useMyId();

  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          px: 1,
          py: 0.5,
          borderRadius: 2,
        },
        { '&:hover': { bgcolor: 'background.grey' }},
      ]}
    >
      <Avatar id={hidden ? null : id} type={type} size={32} sx={{ mr: 1 }} />
      { !hidden ? (
        <Typography>
          {(name ?? <Skeleton width={120} />)}
          {id === myId && type === ENTITY_USER ? (
            <Box
              component='span'
              sx={{ ml: 1, color: 'text.disabled', typography: 'body2' }}
            >
              (you)
            </Box>
          ) : null}
        </Typography>
      ) : (
        <Tooltip title='You do not have permission to view this access row' arrow>
          <Typography color='text.secondary'>[Content Hidden]</Typography>
        </Tooltip>
      )}
      { !hidden ? (
        <PermissionOptionButton
          value={permission}
          onChange={onChange}
          onDelete={onDelete}
          canUpdate={canUpdate}
          canDelete={canDelete}
        />
      ) : null}
    </Box>
  );
}

AccessRow.propTypes = {
  individualId: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    type: PropTypes.oneOf([
      ENTITY_USER,
      ENTITY_USER_ROLE,
      ENTITY_TEAM,
      ENTITY_DEPARTMENT,
    ]),
  }),
  permission: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  canUpdate: PropTypes.bool,
  canDelete: PropTypes.bool,
  hidden: PropTypes.bool,
};

export default AccessRow;
