import {
  ENTITY_ATTACHMENT,
  ENTITY_CATEGORY,
  ENTITY_COMMENT,
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_MASTER_NOTE_ITEM,
  ENTITY_MASTER_NOTE_SECTION,
  ENTITY_NOTE,
  ENTITY_PERMISSION_RELATIONSHIP,
  ENTITY_SIGNATURE,
  ENTITY_SIGNATURE_WORKFLOW,
  ENTITY_SOP,
  ENTITY_SOP_SECTION,
  ENTITY_SOP_SUBSECTION,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';
import {
  attachmentActions,
  commentActions,
  directoryActions,
  experimentActions,
  noteActions,
  permissionActions,
  signatureActions,
  sopActions,
  taskActions,
  userActions,
} from 'store/entity';

import { formatType } from 'utils/entity.utils';
import { getInitialState } from './notification.initialState';
import { pinActions } from 'store/entity/pin';
import { reduceLogout } from 'store/store.reducers.utils';

// #############################################################################
// ############################# Reused Reducers ###############################
// #############################################################################
const enqueueFromRequest = ({
  message = 'Success!',
  variant = 'success',
  ...restProps
} = {}) => (state, action) => {
  const { payload, meta } = action;
  const { requestId } = meta;
  return [{
    key: requestId,
    message: typeof(message) === 'function' ? message(payload) : message,
    variant,
    ...restProps,
  }, ...state];
};

const enqueueFromRequestError = ({
  message = 'Unkown error!',
  ...restProps
} = {}) => (state, action) => {
  const { payload, meta } = action;
  const { requestId } = meta;
  return [{
    key: requestId,
    message: typeof(message) === 'function' ? message(payload) : message,
    variant: 'error',
    ...restProps,
  }, ...state];
};

// #############################################################################
// ################################# Reducers ##################################
// #############################################################################
const enqueueSnackbar = (state, action) => {
  const { key, message, variant, ...restProps } = action.payload;
  return [{ key, message, variant, ...restProps }, ...state];
};

const removeSnackbar = (state, action) => {
  const key = action.payload;
  return state.filter((notification) => notification.key !== key);
};

// #############################################################################
// ########################### Extra Action Reducers ###########################
// #############################################################################
const enqueueRequestStatus = (builder, { fulfilled, rejected }, successMessage, errorMessage) => {
  builder.addCase(fulfilled, enqueueFromRequest({ message: successMessage }))
    .addCase(rejected, enqueueFromRequestError({ message: errorMessage }));
};

const enqueueRequestError = (builder, { rejected }, errorMessage) => {
  builder.addCase(rejected, enqueueFromRequestError({ message: errorMessage }));
};

export const reducers = { enqueueSnackbar, removeSnackbar };
export const extraReducers = (builder) => {
  enqueueRequestError(builder, attachmentActions.createAttachment, `Failed to create ${formatType(ENTITY_ATTACHMENT, { lowercase: true })}`);
  enqueueRequestStatus(builder, attachmentActions.patchAttachment, `${formatType(ENTITY_ATTACHMENT)} updated`, `Failed to update ${formatType(ENTITY_ATTACHMENT, { lowercase: true })}`);
  enqueueRequestError(builder, attachmentActions.checkinAttachment, `Failed to check in ${formatType(ENTITY_ATTACHMENT, { lowercase: true })}`);
  enqueueRequestError(builder, attachmentActions.checkoutAttachment, `Failed to check out ${formatType(ENTITY_ATTACHMENT, { lowercase: true })}`);

  enqueueRequestError(builder, commentActions.createComment, `Failed to post ${formatType(ENTITY_COMMENT, { lowercase: true })}`);
  enqueueRequestStatus(builder, commentActions.patchComment, `${formatType(ENTITY_COMMENT)} saved`, `Failed to save ${formatType(ENTITY_COMMENT, { lowercase: true })}`);
  enqueueRequestStatus(builder, commentActions.deleteComment, `${formatType(ENTITY_COMMENT)} deleted`, `Failed to delete ${formatType(ENTITY_COMMENT, { lowercase: true })}`);

  enqueueRequestError(builder, directoryActions.createDirectory, `Failed to create ${formatType(ENTITY_DIRECTORY, { lowercase: true })}`);
  enqueueRequestStatus(builder, directoryActions.deleteDirectory, `${formatType(ENTITY_DIRECTORY)} moved to trash`, `Failed to move ${formatType(ENTITY_DIRECTORY, { lowercase: true })} to trash`);
  enqueueRequestStatus(builder, directoryActions.deleteDirectoryPermanently, `${formatType(ENTITY_DIRECTORY)} deleted permanently`, `Failed to permanently delete ${formatType(ENTITY_DIRECTORY, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.checkinDirectory, `Failed to check in ${formatType(ENTITY_DIRECTORY, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.checkoutDirectory, `Failed to check out ${formatType(ENTITY_DIRECTORY, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.cloneDirectory, `Failed to clone ${formatType(ENTITY_DIRECTORY, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.generateDirectoryTemplate, `Failed to create ${formatType(ENTITY_DIRECTORY, { lowercase: true, template: true, includeTemplateType: false })} from ${formatType(ENTITY_DIRECTORY, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.instantiateDirectory, `Failed to create ${formatType(ENTITY_DIRECTORY, { lowercase: true })} from ${formatType(ENTITY_DIRECTORY, { lowercase: true, template: true, includeTemplateType: false })}`);
  enqueueRequestStatus(builder, directoryActions.restoreDirectory, `${formatType(ENTITY_DIRECTORY)} restored`, `Failed to restore ${formatType(ENTITY_DIRECTORY, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.createMasterNoteItem, (payload) => payload?.directory_item?.[0] ?? `Failed to create ${formatType(ENTITY_MASTER_NOTE_ITEM, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.createMasterNoteSection, `Failed to create ${formatType(ENTITY_MASTER_NOTE_SECTION, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.deleteMasterNoteItem, `Failed to remove ${formatType(ENTITY_MASTER_NOTE_ITEM, { lowercase: true })}`);
  enqueueRequestError(builder, directoryActions.deleteMasterNoteSection, `Failed to remove ${formatType(ENTITY_MASTER_NOTE_SECTION, { lowercase: true })}`);

  enqueueRequestError(builder, experimentActions.createExperiment, `Failed to create ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);
  enqueueRequestStatus(builder, experimentActions.deleteExperiment, `${formatType(ENTITY_EXPERIMENT)} moved to trash`, `Failed to move ${formatType(ENTITY_EXPERIMENT, { lowercase: true })} to trash`);
  enqueueRequestStatus(builder, experimentActions.deleteExperimentPermanently, `${formatType(ENTITY_EXPERIMENT)} deleted permanently`, `Failed to permanently delete ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);
  enqueueRequestError(builder, experimentActions.checkinExperiment, `Failed to check in ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);
  enqueueRequestError(builder, experimentActions.checkoutExperiment, `Failed to check out ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);
  enqueueRequestError(builder, experimentActions.cloneExperiment, `Failed to clone ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);
  enqueueRequestStatus(builder, experimentActions.completeExperiment, `${formatType(ENTITY_EXPERIMENT)} completed`, `Failed to complete ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);
  enqueueRequestError(builder, experimentActions.generateExperimentTemplate, `Failed to create ${formatType(ENTITY_EXPERIMENT, { lowercase: true, template: true, includeTemplateType: false })} from ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);
  enqueueRequestError(builder, experimentActions.instantiateExperiment, `Failed to create ${formatType(ENTITY_EXPERIMENT, { lowercase: true })} from ${formatType(ENTITY_EXPERIMENT, { lowercase: true, template: true, includeTemplateType: false })}`);
  enqueueRequestStatus(builder, experimentActions.restoreExperiment, `${formatType(ENTITY_EXPERIMENT)} restored`, `Failed to restore ${formatType(ENTITY_EXPERIMENT, { lowercase: true })}`);

  enqueueRequestError(builder, noteActions.createNote, `Failed to create ${formatType(ENTITY_NOTE, { lowercase: true })}`);
  enqueueRequestStatus(builder, noteActions.deleteNote, `${formatType(ENTITY_NOTE)} moved to trash`, `Failed to move ${formatType(ENTITY_NOTE, { lowercase: true })} to trash`);
  enqueueRequestStatus(builder, noteActions.deleteNotePermanently, `${formatType(ENTITY_NOTE)} deleted permanently`, `Failed to permanently delete ${formatType(ENTITY_NOTE, { lowercase: true })}`);
  enqueueRequestError(builder, noteActions.cloneNote, `Failed to clone ${formatType(ENTITY_NOTE, { lowercase: true })}`);
  enqueueRequestError(builder, noteActions.generateNoteTemplate, `Failed to create ${formatType(ENTITY_NOTE, { lowercase: true, template: true, includeTemplateType: false })} from ${formatType(ENTITY_NOTE, { lowercase: true })}`);
  enqueueRequestError(builder, noteActions.instantiateNote, `Failed to create ${formatType(ENTITY_NOTE, { lowercase: true })} from ${formatType(ENTITY_NOTE, { lowercase: true, template: true, includeTemplateType: false })}`);
  enqueueRequestStatus(builder, noteActions.restoreNote, `${formatType(ENTITY_NOTE)} restored`, `Failed to restore ${formatType(ENTITY_NOTE, { lowercase: true })}`);

  enqueueRequestStatus(builder, permissionActions.createPermissionRelationship, 'Item shared', 'Failed to share item');
  enqueueRequestError(builder, permissionActions.patchPermissionRelationship, `Failed to update ${formatType(ENTITY_PERMISSION_RELATIONSHIP, { lowercase: true })}`);
  enqueueRequestError(builder, permissionActions.deletePermissionRelationship, `Failed to remove ${formatType(ENTITY_PERMISSION_RELATIONSHIP, { lowercase: true })}`);

  enqueueRequestStatus(builder, pinActions.createPin, 'Item added to favorites', 'Failed to add item to favorites');
  enqueueRequestError(builder, pinActions.patchPin, 'Failed to update favorites');
  enqueueRequestStatus(builder, pinActions.deletePin, 'Item removed from favorites', 'Failed to remove item from favorites');

  enqueueRequestError(builder, sopActions.createCategory, `Failed to create ${formatType(ENTITY_CATEGORY, { lowercase: true })}`);
  enqueueRequestError(builder, sopActions.createSop, `Failed to create ${formatType(ENTITY_SOP, { lowercase: true })}`);
  enqueueRequestStatus(builder, sopActions.deleteSop, `${formatType(ENTITY_SOP)} moved to trash`, `Failed to move ${formatType(ENTITY_SOP, { lowercase: true })} to trash`);
  enqueueRequestStatus(builder, sopActions.deleteSopPermanently, `${formatType(ENTITY_SOP)} permanently deleted`, `Failed to permanently delete ${formatType(ENTITY_SOP, { lowercase: true })}`);
  enqueueRequestError(builder, sopActions.checkinSop, `Failed to check in ${formatType(ENTITY_SOP, { lowercase: true })}`);
  enqueueRequestError(builder, sopActions.checkoutSop, `Failed to check out ${formatType(ENTITY_SOP, { lowercase: true })}`);
  enqueueRequestError(builder, sopActions.cloneSop, `Failed to clone ${formatType(ENTITY_SOP, { lowercase: true })}`);
  enqueueRequestError(builder, sopActions.generateSopTemplate, `Failed to create ${formatType(ENTITY_SOP, { lowercase: true, template: true, includeTemplateType: false })} from ${formatType(ENTITY_SOP, { lowercase: true })}`);
  enqueueRequestError(builder, sopActions.instantiateSop, `Failed to create ${formatType(ENTITY_SOP, { lowercase: true })} from ${formatType(ENTITY_SOP, { lowercase: true, template: true, includeTemplateType: false })}`);
  enqueueRequestError(builder, sopActions.newSopVersion, `Failed to create new ${formatType(ENTITY_SOP, { lowercase: true })} version`);
  enqueueRequestError(builder, sopActions.createSection, `Failed to create new ${formatType(ENTITY_SOP_SECTION, { lowercase: true })}`);
  enqueueRequestError(builder, sopActions.createSubsection, `Failed to create new ${formatType(ENTITY_SOP_SUBSECTION, { lowercase: true })}`);
  enqueueRequestStatus(builder, sopActions.deleteSection, `${formatType(ENTITY_SOP_SECTION)} deleted`, `Failed to delete ${formatType(ENTITY_SOP_SECTION, { lowercase: true })}`);
  enqueueRequestStatus(builder, sopActions.deleteSubsection, `${formatType(ENTITY_SOP_SUBSECTION)} deleted`, `Failed to delete ${formatType(ENTITY_SOP_SUBSECTION, { lowercase: true })}`);
  enqueueRequestStatus(builder, sopActions.publishSop, `${formatType(ENTITY_SOP)} published`, `Failed to publish ${formatType(ENTITY_SOP, { lowercase: true })}`);
  enqueueRequestStatus(builder, sopActions.restoreSop, `${formatType(ENTITY_SOP)} restored`, `Failed to restore ${formatType(ENTITY_SOP, { lowercase: true })}`);

  enqueueRequestError(builder, userActions.createDepartment, `Failed to create ${formatType(ENTITY_DEPARTMENT, { lowercase: true })}`);
  enqueueRequestError(builder, userActions.patchDepartment, `Failed to save ${formatType(ENTITY_DEPARTMENT, { lowercase: true })}`);
  enqueueRequestStatus(builder, userActions.deleteDepartment, `${formatType(ENTITY_DEPARTMENT)} deleted`, `Failed to delete ${formatType(ENTITY_DEPARTMENT, { lowercase: true })}`);
  enqueueRequestError(builder, userActions.createTeam, `Failed to create ${formatType(ENTITY_TEAM, { lowercase: true })}`);
  enqueueRequestError(builder, userActions.patchTeam, `Failed to save ${formatType(ENTITY_TEAM, { lowercase: true })}`);
  enqueueRequestStatus(builder, userActions.deleteTeam, `${formatType(ENTITY_TEAM)} deleted`, `Failed to delete ${formatType(ENTITY_TEAM, { lowercase: true })}`);
  enqueueRequestError(builder, userActions.createUserRole, `Failed to create ${formatType(ENTITY_USER_ROLE, { lowercase: true })}`);
  enqueueRequestStatus(builder, userActions.deleteUserRole, `${formatType(ENTITY_USER_ROLE)} deleted`, `Failed to delete ${formatType(ENTITY_USER_ROLE, { lowercase: true })}`);
  enqueueRequestError(builder, userActions.createUser, `Failed to create ${formatType(ENTITY_USER, { lowercase: true })}`);
  enqueueRequestError(builder, userActions.patchUserPreferences, `Failed to update ${formatType(ENTITY_USER, { lowercase: true })}`);

  enqueueRequestError(builder, signatureActions.createSignatureWorkflow, `Failed to create ${formatType(ENTITY_SIGNATURE_WORKFLOW, { lowercase: true })}`);
  enqueueRequestError(builder, signatureActions.createSignature, `Failed to create ${formatType(ENTITY_SIGNATURE, { lowercase: true })} assignment`);
  enqueueRequestError(builder, signatureActions.patchSignature, `Failed to update ${formatType(ENTITY_SIGNATURE, { lowercase: true })} assignment`);
  enqueueRequestError(builder, signatureActions.deleteSignature, `Failed to delete ${formatType(ENTITY_SIGNATURE, { lowercase: true })} assignment`);
  enqueueRequestError(builder, signatureActions.rejectSignature, `Failed to reject ${formatType(ENTITY_SIGNATURE, { lowercase: true })}`);
  enqueueRequestError(builder, signatureActions.signSignature, `Failed to sign ${formatType(ENTITY_SIGNATURE, { lowercase: true })}`);

  enqueueRequestError(builder, taskActions.createTask, `Failed to create ${formatType(ENTITY_TASK, { lowercase: true })}`);
  enqueueRequestStatus(builder, taskActions.deleteTask, `${formatType(ENTITY_TASK)} moved to trash`, `Failed to move ${formatType(ENTITY_TASK, { lowercase: true })} to trash`);
  enqueueRequestStatus(builder, taskActions.deleteTaskPermanently, `${formatType(ENTITY_TASK)} deleted permanently`, `Failed to permanently delete ${formatType(ENTITY_TASK, { lowercase: true })}`);
  enqueueRequestError(builder, taskActions.checkinTask, `Failed to check in ${formatType(ENTITY_TASK, { lowercase: true })}`);
  enqueueRequestError(builder, taskActions.checkoutTask, `Failed to check out ${formatType(ENTITY_TASK, { lowercase: true })}`);
  enqueueRequestError(builder, taskActions.cloneTask, `Failed to clone ${formatType(ENTITY_TASK, { lowercase: true })}`);
  enqueueRequestError(builder, taskActions.generateTaskTemplate, `Failed to create ${formatType(ENTITY_TASK, { lowercase: true, template: true, includeTemplateType: false })} from ${formatType(ENTITY_TASK, { lowercase: true })}`);
  enqueueRequestError(builder, taskActions.instantiateTask, `Failed to create ${formatType(ENTITY_TASK, { lowercase: true })} from ${formatType(ENTITY_TASK, { lowercase: true, template: true, includeTemplateType: false })}`);
  enqueueRequestError(builder, taskActions.launchTask, 'Failed to launch planned document');
  enqueueRequestStatus(builder, taskActions.restoreTask, `${formatType(ENTITY_TASK)} restored`, `Failed to restore ${formatType(ENTITY_TASK, { lowercase: true })}`);

  reduceLogout(builder, getInitialState);
};
