import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import LinkIcon from '@mui/icons-material/Link';
import PropTypes from 'prop-types';
import { getLink } from 'utils/entity.utils';

function ActionCopyLink(props) {
  const { id, type, ...restProps } = props;

  const onAction = useCallback(() => {
    navigator.clipboard.writeText(getLink({ id, type }));
  }, [id, type]);

  return (
    <BaseAction
      icon={<LinkIcon />}
      label='Copy Link'
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionCopyLink.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
};

export default ActionCopyLink;
