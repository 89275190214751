import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP } from 'constants/schemas';
import NewDirectoryForm, { useNewDirectoryFormData } from 'components/NewDirectoryForm';
import NewExperimentForm, { useNewExperimentFormData } from 'components/NewExperimentForm';
import NewNoteForm, { useNewNoteFormData } from 'components/NewNoteForm';
import NewSopForm, { useNewSopFormData } from 'components/NewSopForm';
import React, { useMemo } from 'react';
import { formatType, getIcon } from 'utils/entity.utils';

import { Button } from '@acheloisbiosoftware/absui.core';
import Field from 'components/Field';
import { LaunchIcon } from 'constants/icon.constants';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const typeOptions = [
  ENTITY_NOTE,
  ENTITY_EXPERIMENT,
  ENTITY_SOP,
  ENTITY_DIRECTORY,
];

const useInitData = (entityType, relatedObjContentType, relatedObjInitData) => useMemo(() => ({
  permission_relationships: [],
  ...(relatedObjContentType === entityType ? relatedObjInitData : {}),
}), [entityType, relatedObjContentType, relatedObjInitData]);

function PlanNewDocument(props) {
  const {
    related_obj_content_type,
    related_obj_init_data,
    onChange,
    isNew,
    onClear,
    onLaunch,
    launchDisabled,
    launchLoading,
    hideLaunch,
  } = props;

  const initNoteData = useInitData(ENTITY_NOTE, related_obj_content_type, related_obj_init_data);
  const initExperimentData = useInitData(ENTITY_EXPERIMENT, related_obj_content_type, related_obj_init_data);
  const initSopData = useInitData(ENTITY_SOP, related_obj_content_type, related_obj_init_data);
  const initDirectoryData = useInitData(ENTITY_DIRECTORY, related_obj_content_type, related_obj_init_data);

  const newNoteData = useNewNoteFormData(initNoteData);
  const newExperimentData = useNewExperimentFormData(initExperimentData);
  const newSopData = useNewSopFormData(initSopData);
  const newDirectoryData = useNewDirectoryFormData(initDirectoryData);
  const newDataMap = {
    [ENTITY_NOTE]: newNoteData,
    [ENTITY_EXPERIMENT]: newExperimentData,
    [ENTITY_SOP]: newSopData,
    [ENTITY_DIRECTORY]: newDirectoryData,
  };
  const newData = newDataMap[related_obj_content_type] ?? {};

  const onChangeType = (_, newType) => {
    onChange('related_obj_id', null);
    onChange('related_obj_content_type', newType);
    onChange('related_obj_init_data', newDataMap[newType]?.formData);
  };

  const onChangeFormData = (key, value) => {
    newData.updateFormData(key, value);
    onChange('related_obj_init_data', { ...related_obj_init_data, [key]: value });
  };

  return (
    <>
      <Field
        label='Type'
        readOnly={false}
        variant={Field.VARIANTS.SELECT}
        value={isNew ? (related_obj_content_type ?? '') : ''}
        textFieldProps={{
          onChange: onChangeType,
          children: (typeOptions.map((entityType) => {
            const Icon = getIcon(entityType);
            return (
              <MenuItem
                key={`option_${entityType}`}
                value={entityType}
                icon={<Icon colored />}
                label={formatType(entityType)}
              />
            );
          })),
          SelectProps: {
            renderValue: (value) => {
              const Icon = getIcon(value);
              return (
                <Stack direction='row' alignItems='center'>
                  <Icon colored sx={{ mr: 1 }} />
                  <Typography noWrap>
                    {formatType(value)}
                  </Typography>
                </Stack>
              );
            },
          },
        }}
      />
      { !isNew ? null : related_obj_content_type === ENTITY_NOTE ? (
        <NewNoteForm
          formData={newNoteData.formData}
          updateFormData={onChangeFormData}
          excludeFields={['permission_relationships']}
        />
      ) : related_obj_content_type === ENTITY_EXPERIMENT ? (
        <NewExperimentForm
          formData={newExperimentData.formData}
          updateFormData={onChangeFormData}
          excludeFields={['permission_relationships']}
        />
      ) : related_obj_content_type === ENTITY_SOP ? (
        <NewSopForm
          formData={newSopData.formData}
          updateFormData={onChangeFormData}
          excludeFields={['permission_relationships']}
        />
      ) : related_obj_content_type === ENTITY_DIRECTORY ? (
        <NewDirectoryForm
          formData={newDirectoryData.formData}
          updateFormData={onChangeFormData}
          excludeFields={['permission_relationships']}
        />
      ) : null}
      <Stack direction='row' justifyContent='flex-end' sx={{ mt: 1 }}>
        <Button
          variant='text'
          onClick={onClear}
        >
          Clear
        </Button>
        { !hideLaunch ? (
          <Button
            startIcon={<LaunchIcon />}
            loading={launchLoading}
            disabled={launchDisabled}
            onClick={onLaunch}
            sx={{ ml: 2 }}
          >
            Launch
          </Button>
        ) : null}
      </Stack>
    </>
  );
}

PlanNewDocument.propTypes = {
  related_obj_content_type: PropTypes.string,
  related_obj_init_data: PropTypes.object,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
  onClear: PropTypes.func,
  onLaunch: PropTypes.func,
  launchDisabled: PropTypes.bool,
  launchLoading: PropTypes.bool,
  hideLaunch: PropTypes.bool,
};

export default PlanNewDocument;
