import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import React, { useState } from 'react';

import { ACTION_CREATE } from 'constants/permission.constants';
import ActionAddDirectory from './ActionAddDirectory';
import ActionAddExperiment from './ActionAddExperiment';
import ActionAddNote from './ActionAddNote';
import ActionAddSop from './ActionAddSop';
import ActionAddTask from './ActionAddTask';
import { AddIcon } from 'constants/icon.constants';
import Collapse from '@mui/material/Collapse';
import { DIR_TYPES } from 'constants/directory.constants';
import { DataTableAction } from 'components/DataTable';
import Divider from '@mui/material/Divider';
import { MODE_LIGHT } from 'components/ThemeProvider';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { useMyGlobalPermission } from 'hooks/store.hooks';

const ADD_ENTITY_COMPONENT_MAP = {
  [ENTITY_NOTE]: ActionAddNote,
  [ENTITY_EXPERIMENT]: ActionAddExperiment,
  [ENTITY_SOP]: ActionAddSop,
  [ENTITY_DIRECTORY]: ActionAddDirectory,
  [ENTITY_TASK]: ActionAddTask,
};

function ActionAddDocuments(props) {
  const { entityTypes, directoryTypes, initStateMap } = props;
  const [open, setOpen] = useState(false);

  const canCreateNote = useMyGlobalPermission(ENTITY_NOTE, ACTION_CREATE);
  const canCreateExperiment = useMyGlobalPermission(ENTITY_EXPERIMENT, ACTION_CREATE);
  const canCreateSop = useMyGlobalPermission(ENTITY_SOP, ACTION_CREATE);
  const canCreateDirectory = useMyGlobalPermission(ENTITY_DIRECTORY, ACTION_CREATE);
  const canCreateTask = useMyGlobalPermission(ENTITY_TASK, ACTION_CREATE);
  const createPermissions = {
    [ENTITY_NOTE]: canCreateNote,
    [ENTITY_EXPERIMENT]: canCreateExperiment,
    [ENTITY_SOP]: canCreateSop,
    [ENTITY_DIRECTORY]: canCreateDirectory,
    [ENTITY_TASK]: canCreateTask,
  };
  const _entityTypes = entityTypes.filter((type) => createPermissions[type]);

  return _entityTypes.length === 0 ? null : (
    <Paper
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      sx={[
        {
          py: 1,
          px: 0.5,
          mx: -0.5,
          borderRadius: 2,
          boxShadow: 0,
          border: (theme) => (theme.palette.mode === MODE_LIGHT ? 1 : 0),
          transition: (theme) => theme.transitions.create('border-color'),
          borderColor: 'transparent',
        },
        open ? {
          borderColor: 'divider',
        } : { background: 'none' },
      ]}
    >
      <DataTableAction
        icon={(
          <AddIcon
            sx={[
              { transition: (theme) => theme.transitions.create('transform') },
              open ? { transform: 'rotate(45deg)' } : {},
            ]}
          />
        )}
        onClick={() => setOpen(!open)}
        containerProps={{ sx: { mt: 0 }}}
      />
      <Collapse in={open} timeout='auto'>
        <Divider sx={{ mt: 1 }} />
        { _entityTypes.map((type) => {
          const ActionComponent = ADD_ENTITY_COMPONENT_MAP[type];
          const actionProps = {
            initState: initStateMap?.[type],
            ...(type === ENTITY_DIRECTORY ? { directoryTypes } : {}),
          };
          return (<ActionComponent key={`new-${type}`} {...actionProps} />);
        })}
      </Collapse>
    </Paper>
  );
}

ActionAddDocuments.propTypes = {
  entityTypes: PropTypes.arrayOf(PropTypes.oneOf([
    ENTITY_NOTE,
    ENTITY_EXPERIMENT,
    ENTITY_SOP,
    ENTITY_DIRECTORY,
    ENTITY_TASK,
  ])),
  directoryTypes: PropTypes.arrayOf(PropTypes.oneOf(DIR_TYPES)),
  initStateMap: PropTypes.shape({
    [ENTITY_NOTE]: PropTypes.object,
    [ENTITY_EXPERIMENT]: PropTypes.object,
    [ENTITY_SOP]: PropTypes.object,
    [ENTITY_DIRECTORY]: PropTypes.object,
    [ENTITY_TASK]: PropTypes.object,
  }),
};

ActionAddDocuments.defaultProps = {
  entityTypes: [],
  directoryTypes: DIR_TYPES,
};

export default ActionAddDocuments;
