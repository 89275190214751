import { SPACE_OPUS_U, SPACE_TYPE_ENTITY_MAP } from 'constants/space.constants';

import { NAVIGATION } from 'store/store.constants';
import { authSelectors } from 'store/auth';
import { createSelector } from '@reduxjs/toolkit';
import { getGenericId } from 'utils/generic.utils';

const selectNavigationSlice = (state) => state[NAVIGATION];
export const selectIsBlocked = (state) => selectNavigationSlice(state).isBlocked;
export const selectBlockers = (state) => selectNavigationSlice(state).blockers;
export const selectWhen = (state) => selectBlockers(state).length > 0;
export const selectLiveBlocker = (state) => selectBlockers(state)[0];
export const selectKey = (state) => selectLiveBlocker(state)?.key;
export const selectMessage = (state) => selectLiveBlocker(state)?.message;

export const selectSpaceType = (state) => selectNavigationSlice(state).spaceType;
export const selectSpaceId = (state) => selectNavigationSlice(state).spaceId;

export const selectSpaceGenericId = createSelector(
  selectSpaceType,
  selectSpaceId,
  authSelectors.selectMyId,
  (spaceType, spaceId, myId) => {
    const entityType = SPACE_TYPE_ENTITY_MAP[spaceType];
    const entityId = spaceType === SPACE_OPUS_U ? myId : spaceId;
    return getGenericId(entityId, entityType);
  },
);

export const selectRecentlyViewed = (state) => selectNavigationSlice(state).recentlyViewed;
