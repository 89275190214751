import {
  useEntity,
  usePermissionRelationship,
} from 'hooks/store.hooks';

import PropTypes from 'prop-types';
import React from 'react';
import ShareAccess from './ShareAccess';
import { permissionActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useGenericId } from 'utils/generic.utils';

function ShareObjectAccess(props) {
  const { objectId, objectType } = props;
  const dispatch = useDispatch();

  const objectGenericId = useGenericId(objectId, objectType);
  const existingShares = useEntity(objectGenericId, 'permission_relationships');
  const existingIndividuals = usePermissionRelationship(existingShares, {
    id: 'individual_id',
    type: 'individual_content_type',
  });

  const addNewShares = (individuals, permission) => dispatch(
    permissionActions.createPermissionRelationship({
      _bulk: individuals.map((individual) => ({
        object_id: objectId,
        object_content_type: objectType,
        individual_id: individual.id,
        individual_content_type: individual.type,
        permission,
      })),
    }),
  ).unwrap();

  return (
    <ShareAccess
      onShare={addNewShares}
      existingIndividuals={existingIndividuals}
    />
  );
}

ShareObjectAccess.propTypes = {
  objectId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  objectType: PropTypes.string.isRequired,
};

export default ShareObjectAccess;
