import React from 'react';
import { useTheme } from '@mui/material/styles';

const size = 24;
const r = 5.5;
const rArrangement = 5.5;

const sind = (theta) => Math.sin((theta * Math.PI) / 180);
const cosd = (theta) => Math.cos((theta * Math.PI) / 180);
const getCx = (idx, numCircles) => (size / 2) + (rArrangement * cosd(idx * 360 / numCircles));
const getCy = (idx, numCircles) => (size / 2) - (rArrangement * sind(idx * 360 / numCircles));

function Logo() {
  const theme = useTheme();
  const colors = [
    theme.palette.logo.a,
    theme.palette.logo.c,
    theme.palette.logo.t,
  ];
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox={`0 0 ${size} ${size}`}
      stroke='transparent'
      strokeWidth={1.5}
    >
      <g transform={`rotate(-90 ${size / 2} ${size / 2})`}>
        <defs>
          {colors.map((color, idx) => (
            <mask
              key={`${color}${idx}`} // eslint-disable-line react/no-array-index-key
              id={`mask${idx}`}
            >
              <rect x={0} y={0} width={size} height={size} fill='white' />
              <circle
                cx={getCx(idx, colors.length)}
                cy={getCy(idx, colors.length)}
                r={r}
                fill='black'
                stroke='black'
              />
            </mask>
          ))}
        </defs>
        {colors.map((color, idx) => (
          <circle
            key={`${color}${idx}`} // eslint-disable-line react/no-array-index-key
            cx={getCx(idx, colors.length)}
            cy={getCy(idx, colors.length)}
            r={r}
            stroke='white'
            fill='white'
          />
        ))}
        {colors.map((color, idx) => (
          <circle
            key={`${color}${idx}`} // eslint-disable-line react/no-array-index-key
            cx={getCx(idx, colors.length)}
            cy={getCy(idx, colors.length)}
            r={r}
            fill={color}
            mask={`url(#mask${(idx + 1) % colors.length})`}
          />
        ))}
      </g>
    </svg>
  );
}

export default Logo;
