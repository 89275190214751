import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE, COL_TYPE_SELECT, COL_TYPE_USER } from 'components/DataTable';
import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_MASTER_NOTE,
  ENTITY_MASTER_NOTE_ITEM,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
  ENTITY_USER,
} from 'constants/schemas';
import { EntityActionState, IconButtonLinkAction, selectEntityActions } from 'components/EntityActions';
import { FunctionProp, GenericProp, Prop, SelectorProp, directorySelectors, entitySelectors, experimentSelectors, sopSelectors } from 'store/entity';
import React, { useCallback, useMemo } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import {
  useDirectory,
  useMasterNote,
  useMasterNoteItem,
  useMasterNoteSection,
  useUserName,
} from 'hooks/store.hooks';
import { useEntityId, useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ACTION_VIEW } from 'constants/permission.constants';
import ActionOpen from 'components/EntityActions/ActionOpen';
import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DIR_TYPE_STUDY } from 'constants/directory.constants';
import { LaunchIcon } from 'constants/icon.constants';
import NewMasterNoteItems from './NewMasterNoteItems';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Status from 'components/Status';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { createSelector } from '@reduxjs/toolkit';
import { formatDate } from 'utils/date.utils';
import { generateSearchParams } from 'utils/dom.utils';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { parseFloatList } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';

const EMPTY_ARRAY = [];
const selectAllMasterNoteItemIds = createSelector(
  (state, masterNoteId) => directorySelectors.selectMasterNoteSection(state, directorySelectors.selectMasterNote(state, masterNoteId, 'sections'), 'items'),
  (masterNoteItemIds) => (masterNoteItemIds ?? EMPTY_ARRAY).flat(),
);
const titleProp = new GenericProp({
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
});
const genericIdProp = new Prop({ id: 'object_id', type: 'content_type' });
const selectGenericId = (state, masterNoteItem) => directorySelectors.selectDirectoryItem(
  state, masterNoteItem.directory_item, genericIdProp,
);
const allMasterNoteItemsProp = new Prop({ id: 'id', directory_item: 'directory_item' });

function OwnerCell(props) {
  const { id } = props;
  const ownerName = useUserName(id);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar id={id} type={ENTITY_USER} sx={{ mr: 1 }} />
      <Typography color='text.secondary' variant='body2' noWrap>{ownerName}</Typography>
    </Box>
  );
}
OwnerCell.propTypes = { id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) };

function MasterNoteSectionTable(props) {
  const { id: sectionId, collapsed, selectedItem } = props;
  const masterNoteId = useEntityId();
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];
  const isReadOnly = useEntityIsReadOnly();
  const parent = useMasterNote(masterNoteId, 'parent');
  const parentType = useDirectory(parent, 'type');
  const parentIsTemplate = useDirectory(parent, 'is_template');

  const allMasterNoteItemIds = useSelector((state) => selectAllMasterNoteItemIds(state, masterNoteId));
  const allMasterNoteItems = useMasterNoteItem(allMasterNoteItemIds, allMasterNoteItemsProp);

  const masterNoteItemProp = useMemo(() => new Prop({
    id: 'id',
    sequence: new FunctionProp((masterNoteItem, idx) => idx),
    isDuplicate: new FunctionProp((masterNoteItem) => {
      const { id, directory_item } = masterNoteItem;
      const otherItems = allMasterNoteItems.filter((item) => item.id !== id);
      const otherDirectoryItemIds = otherItems.map((item) => item.directory_item);
      return otherDirectoryItemIds.includes(directory_item);
    }),
    objectId: new SelectorProp((state, masterNoteItem) => directorySelectors.selectDirectoryItem(state, masterNoteItem.directory_item, 'object_id')),
    type: new SelectorProp((state, masterNoteItem) => directorySelectors.selectDirectoryItem(state, masterNoteItem.directory_item, 'content_type')),
    title: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      return entitySelectors.selectEntity(state, genericId, titleProp);
    }),
    created: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      return entitySelectors.selectEntity(state, genericId, 'created');
    }),
    created_by: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      return entitySelectors.selectEntity(state, genericId, 'created_by');
    }),
    status: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      return entitySelectors.selectEntity(state, genericId, 'status');
    }),
    canView: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      return entitySelectors.selectEntityPermission(state, genericId, ACTION_VIEW);
    }),
    Icon: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      return entitySelectors.selectEntityIcon(state, genericId);
    }),
    isTemplate: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      return entitySelectors.selectEntity(state, genericId, 'is_template');
    }),
    outdatedMessage: new SelectorProp((state, masterNoteItem) => {
      const genericId = selectGenericId(state, masterNoteItem);
      const isTemplate = entitySelectors.selectEntity(state, genericId, 'is_template');
      if (
        genericId.type === ENTITY_EXPERIMENT &&
        !experimentSelectors.selectExperiment(state, genericId.id, 'related_sop__is_effective_version')
      ) {
        return `The ${formatType(ENTITY_SOP, { lowercase: true })} used for this ${formatType(ENTITY_EXPERIMENT, { lowercase: true, template: isTemplate })} is outdated.`;
      } else if (
        genericId.type === ENTITY_SOP &&
        !sopSelectors.selectSop(state, genericId.id, 'is_effective_version') &&
        !sopSelectors.selectSop(state, genericId.id, 'is_current_version')
      ) {
        return `This ${formatType(ENTITY_SOP, { lowercase: true, template: isTemplate })} is outdated.`;
      }
      return '';
    }),
  }), [allMasterNoteItems]);

  const itemIds = useMasterNoteSection(sectionId, 'items');
  const rows = useMasterNoteItem(itemIds, masterNoteItemProp);

  const columns = useMemo(() => [
    ...(collapsed ? [{
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      hidden: true,
      selection: [selectedItem],
      isPrimarySelection: true,
    }] : []),
    {
      id: 'sequence',
      field: 'sequence',
      hidden: collapsed,
      sortable: false,
      valueGetter: ({ value }) => value + 1,
      renderValue: ({ value }) => `${value}.`,
      noHeader: true,
      secondary: true,
    },
    {
      id: 'title',
      label: 'Title',
      field: 'title',
      sortable: false,
      renderValue: ({ value, row }) => {
        const { Icon, canView, isDuplicate, isTemplate } = row;
        return canView ? (
          <Stack direction='row' alignItems='center' sx={{ cursor: 'pointer' }}>
            {Icon ? <Icon colored template={isTemplate} /> : null}
            <Tooltip title={value} arrow enterDelay={500} enterNextDelay={500}>
              <Stack sx={{ ml: 2, overflow: 'hidden' }}>
                <Typography variant='subtitle2' noWrap={!collapsed}>
                  {value || 'Untitled'}
                </Typography>
                { collapsed ? (
                  <Typography color='text.secondary' variant='caption'>
                    {formatDate(row.created)}
                  </Typography>
                ) : null}
              </Stack>
            </Tooltip>
            { !collapsed && row.outdatedMessage ? (
              <Tooltip title={row.outdatedMessage} arrow>
                <WarningAmberIcon
                  sx={{ fontSize: 16, color: 'warning.main', ml: 1 }}
                />
              </Tooltip>
            ) : null}
            { !collapsed && isDuplicate ? (
              <Tooltip title={`This item is duplicated within this ${formatType(ENTITY_MASTER_NOTE, { lowercase: true, directoryType: parentType, template: parentIsTemplate })}`} arrow>
                <ContentCopyIcon
                  sx={{ fontSize: 16, color: 'warning.main', ml: 1 }}
                />
              </Tooltip>
            ) : null}
          </Stack>
        ) : (
          <Tooltip title='You do not have permission to view this document' arrow>
            <Typography variant='subtitle2' color='text.secondary' noWrap={!collapsed}>
              [Content hidden]
            </Typography>
          </Tooltip>
        );
      },
      cellProps: { sx: { maxWidth: collapsed ? null : 300 }},
    },
    {
      id: 'launch',
      type: COL_TYPE_ACTIONS,
      actions: ({ row }) => [{
        id: 'launch',
        component: ActionOpen,
        props: {
          id: row.objectId,
          type: row.type,
          component: IconButtonLinkAction,
          label: 'Open',
          icon: <LaunchIcon fontSize='small' />,
          disabled: !row.canView,
          size: 'small',
          color: 'primary',
        },
      }],
    },
    {
      id: 'created_by',
      label: 'Owner',
      field: 'created_by',
      type: COL_TYPE_USER,
      sortable: false,
      hidden: collapsed,
      renderValue: ({ value, row }) => (row.canView ? (<OwnerCell id={value} />) : null),
    },
    ...(parentType === DIR_TYPE_STUDY && !parentIsTemplate ? [{
      id: 'status',
      label: 'Status',
      field: 'status',
      renderValue: ({ value }) => (isDefined(value) ? <Status>{value}</Status> : null),
      hidden: collapsed,
      sortable: false,
    }] : []),
    {
      id: 'created',
      label: 'Created',
      field: 'created',
      sortable: false,
      type: COL_TYPE_DATE,
      secondary: true,
      hidden: collapsed,
    },
    ...(!isReadOnly ? [
      {
        id: 'actions',
        type: COL_TYPE_ACTIONS,
        showOnHover: true,
        inMenu: true,
        hidden: collapsed,
        actions: ({ row, state }) => selectEntityActions(state, row.id, ENTITY_MASTER_NOTE_ITEM),
      },
    ] : []),
  ], [isReadOnly, parentType, collapsed, selectedItem, parentIsTemplate]);

  const onRowClick = useCallback((row, event) => {
    if (!row.canView) return;
    if (event.detail > 1) {
      navigate(getPath(getGenericId(row.objectId, row.type)));
    } else {
      setSearchParams((oldSearchParams) => {
        const oldExploreStr = oldSearchParams.get('explore') ?? '';
        const oldExplore = parseFloatList(oldExploreStr);
        const pathIdx = oldExplore.indexOf(selectedItem);
        const slicedExplore = pathIdx === -1 ? oldExplore : oldExplore.slice(0, pathIdx);
        return generateSearchParams({ explore: [...slicedExplore, row.id]}, oldSearchParams);
      });
    }
  }, [setSearchParams, navigate, selectedItem]);

  const { setNodeRef } = useSortable({ id: sectionId });

  return (
    <EntityActionState>
      <Box
        ref={setNodeRef}
        sx={collapsed ? {} : {
          mt: 1,
          border: 1,
          borderColor: 'divider',
          borderRadius: 1,
        }}
      >
        <DataTable
          rows={rows}
          columns={columns}
          disableGroupBy
          disableFilter
          noHeader={collapsed}
          enableSortable={!isReadOnly && !collapsed}
          onRowClick={onRowClick}
          initState={{
            orderBy: 'sequence',
            order: 'asc',
          }}
          tableProps={{ size: 'small' }}
          sx={collapsed ? {
            borderTop: 1,
            borderColor: 'divider',
          } : { borderRadius: 1 }}
        />
        { isReadOnly || collapsed ? null : (
          <NewMasterNoteItems
            masterNoteId={masterNoteId}
            sectionId={sectionId}
          />
        )}
      </Box>
    </EntityActionState>
  );
}

MasterNoteSectionTable.propTypes = {
  id: PropTypes.number.isRequired,
  collapsed: PropTypes.bool,
  selectedItem: PropTypes.number,
};

export default MasterNoteSectionTable;
