import Field from 'components/Field';
import PropTypes from 'prop-types';
import React from 'react';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';
import TaskRelatedDocument from 'components/TaskRelatedDocument';

function NewTaskFormBlock(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    assigneesAutocompleteProps,
    dueDateDatePickerProps,
    containerProps,
  } = props;
  const {
    title,
    description,
    related_obj_content_type,
    related_obj_id,
    related_obj_init_data,
    permission_relationships,
  } = formData;
  const _assigneesAutocompleteProps = {
    ...assigneesAutocompleteProps,
    ...fieldPropsMap?.assignees?.autocompleteProps,
  };
  const _dueDateDatePickerProps = {
    ...fieldPropsMap?.due_date?.datePickerProps,
    ...dueDateDatePickerProps, // NOTE: this can be under fieldPropsMap because merged in NewTaskForm
  };

  return (
    <Stack {...containerProps}>
      { excludeFields.includes('title') ? null : (
        <Field
          label='Title'
          variant={Field.VARIANTS.TEXT_FIELD}
          readOnly={false}
          value={title}
          {...fieldPropsMap?.title}
          labelProps={{ required: true, ...fieldPropsMap?.title?.labelProps }}
          textFieldProps={{
            onChange: (_, newTitle) => updateFormData('title', newTitle),
            ...fieldPropsMap?.title?.textFieldProps,
          }}
        />
      )}
      { excludeFields.includes('assignees') ? null : (
        <Field
          label='Assignee(s)'
          variant={Field.VARIANTS.CHIPS}
          readOnly={false}
          value={_assigneesAutocompleteProps.value}
          {...fieldPropsMap?.assignees}
          autocompleteProps={_assigneesAutocompleteProps}
        />
      )}
      { excludeFields.includes('due_date') ? null : (
        <Field
          label='Due Date'
          variant={Field.VARIANTS.DATE}
          readOnly={false}
          value={_dueDateDatePickerProps.value}
          {...fieldPropsMap?.due_date}
          datePickerProps={_dueDateDatePickerProps}
        />
      )}
      { excludeFields.includes('description') ? null : (
        <Field
          label='Description'
          value={description}
          variant={Field.VARIANTS.TEXT_EDITOR}
          readOnly={false}
          onChangeSerialized={(newDescription) => updateFormData('description', newDescription)}
          debounceSerialization={false}
          {...fieldPropsMap?.description}
          toolbarProps={{ elevation: 16, ...fieldPropsMap?.description?.toolbarProps }}
        />
      )}
      { excludeFields.includes('related_obj') ? null : (
        <Field
          label='Related Document'
          readOnly={false}
          {...fieldPropsMap?.related_obj}
        >
          <TaskRelatedDocument
            related_obj_id={related_obj_id}
            related_obj_content_type={related_obj_content_type}
            related_obj_init_data={related_obj_init_data}
            onChange={updateFormData}
            paperProps={{ sx: { bgcolor: 'background.grey' }}}
            hideLaunch
            hideOpen
          />
        </Field>
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <ShareNewDocumentField
          shares={permission_relationships}
          setShares={(newShares) => updateFormData('permission_relationships', newShares)}
          {...fieldPropsMap?.permission_relationships}
        />
      )}
    </Stack>
  );
}

NewTaskFormBlock.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  assigneesAutocompleteProps: PropTypes.object,
  dueDateDatePickerProps: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewTaskFormBlock;
