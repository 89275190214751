import React, { useCallback } from 'react';

import BaseAction from './BaseAction';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PropTypes from 'prop-types';
import { directoryActions } from 'store/entity';
import { useDispatch } from 'react-redux';

function MasterNoteItemActionRemove(props) {
  const { id, ...restProps } = props;
  const dispatch = useDispatch();

  const onAction = useCallback(async () => {
    await dispatch(directoryActions.deleteMasterNoteItem({ id }));
  }, [id, dispatch]);

  return (
    <BaseAction
      icon={<PlaylistRemoveIcon />}
      label='Remove'
      onAction={onAction}
      {...restProps}
    />
  );
}

MasterNoteItemActionRemove.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default MasterNoteItemActionRemove;
