import {
  CloneIcon,
  LocationIcon,
  SopIcon,
  StatusIcon,
  TimeIcon,
  UserIcon,
} from 'constants/icon.constants';
import { ENTITY_ACTION_DELETE, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_CATEGORY, ENTITY_SOP } from 'constants/schemas';
import React, { useEffect, useMemo } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import {
  useCategory,
  useSop,
  useSopPermission,
  useSopSection,
  useSopSubsection,
  useUserName,
} from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import CategoryIcon from '@mui/icons-material/Category';
import { DATE_FMT_SHORT } from 'constants/date.constants';
import DeletedAlert from 'components/DeletedAlert';
import Field from 'components/Field';
import Link from 'components/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import { SECTION_ICONS } from 'constants/sop.constants';
import SidePreviewActions from './SidePreviewActions';
import SidePreviewContent from './SidePreviewContent';
import SidePreviewHeader from './SidePreviewHeader';
import Skeleton from '@mui/material/Skeleton';
import { SopVersionTable } from 'components/SopTable';
import Status from 'components/Status';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { sopActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useOnClose } from './SidePreview';

const EMPTY_ARR = [];

function SopPreviewContent(props) {
  const { id } = props;
  const onClose = useOnClose();
  const dispatch = useDispatch();
  const sop = useSop(id);
  const author = useUserName(sop?.created_by);
  const clonedFromTitle = useSop(sop?.cloned_from, 'long_title');
  const canViewClonedFromSop = useSopPermission(sop?.cloned_from, ACTION_VIEW);
  const category = useCategory(sop?.category, 'name');

  const sectionIds = sop?.sections ?? EMPTY_ARR;
  const sections = useSopSection(sectionIds);
  const allSubsectionIds = useMemo(() => sections.flatMap((section) => section?.subsections ?? []), [sections]);
  const subsections = useSopSubsection(allSubsectionIds);
  const subsectionsById = Object.fromEntries(subsections.map((subsection) => [subsection.id, subsection]));

  useEffect(() => {
    if (isDefined(id)) dispatch(sopActions.fetchSop({ id }));
  }, [id, dispatch]);

  const createRow = (title, value, icon) => ({ title, value, icon });
  const iconSx = { fontSize: 20, my: 'auto' };
  const tableProperties = [
    createRow('Author', author, <UserIcon sx={iconSx} />),
    isDefined(sop?.cloned_from) ? createRow('Cloned From', canViewClonedFromSop ? (
      <Link to={getPath(getGenericId(sop.cloned_from, ENTITY_SOP))}>
        {clonedFromTitle}
      </Link>
    ) : (
      <Tooltip title={`You do not have permission to view this ${formatType(ENTITY_SOP, { lowercase: true })}`} arrow>
        <Typography color='text.disabled'>[Content Hidden]</Typography>
      </Tooltip>
    ), <CloneIcon sx={iconSx} />) : null,
    createRow(formatType(ENTITY_CATEGORY), category, <CategoryIcon sx={iconSx} />),
    !sop?.is_template ? createRow('Status', isDefined(sop?.status) ? <Status>{sop.status}</Status> : null, <StatusIcon sx={iconSx} />) : null,
    createRow('Date Created', isDefined(sop?.created) ? formatDate(sop.created, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    createRow('Last Modified', isDefined(sop?.modified) ? formatDate(sop.modified, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    isDefined(sop?.published_at) ? createRow('Publish Date', formatDate(sop.published_at, DATE_FMT_SHORT), <TimeIcon sx={iconSx} />) : null,
    sop?.is_deleted ? createRow('Date Deleted', formatDate(sop.deleted, DATE_FMT_SHORT), <TimeIcon sx={iconSx} />) : null,
    createRow('Location(s)', (<Locations directoryItem={sop?.directory_item} />), <LocationIcon sx={iconSx} />),
  ];

  const actions = useEntityActions(id, ENTITY_SOP, {
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess: onClose }},
  });

  return (
    <>
      <EntityActionState>
        <SidePreviewActions path={getPath(getGenericId(id, ENTITY_SOP))} actions={actions} />
      </EntityActionState>

      <SidePreviewHeader
        icon={<SopIcon colored template={sop?.is_template} />}
        title={sop?.title}
        subtitle={sop?.sop_code}
      />

      <SidePreviewContent>
        <DeletedAlert id={id} type={ENTITY_SOP} sx={{ mb: 1 }} />

        <Table size='small'>
          <TableBody>
            {tableProperties.map((row) => (!isDefined(row) ? null : (
              <TableRow key={row.title}>
                <TableCell sx={{ minWidth: 120, width: 120 }}>
                  <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                    {row.icon}
                    <Typography variant='body2' sx={{ my: 'auto', ml: 1 }}>
                      {row.title ?? <Skeleton />}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ typography: 'body1' }}>
                  {row.value ?? <Skeleton />}
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        { sop?.is_template ? null : (
          <Field label='Version History'>
            { isDefined(sop?.collection_id) ? (
              <SopVersionTable
                id={id}
                sx={{ maxWidth: 600 }}
              />
            ) : <Skeleton variant='rounded' height={200} sx={{ my: 1, mx: 2 }} />}
          </Field>
        )}

        <Box sx={{ mt: 2, position: 'relative' }}>
          { sop && 'description' in sop ? (
            <Field
              label='Description'
              value={sop.description}
              variant={Field.VARIANTS.TEXT_EDITOR}
              readOnly
              interactable={false}
              valueProps={{ key: `description${id}` }}
            />
          ) : null }
        </Box>

        <Box sx={{ mt: 2, position: 'relative' }}>
          { isDefined(sop?.section_name) || sections.length > 0 ? (
            <Field label='Overview'>
              <List sx={{ pl: 2 }}>
                <ListItem disablePadding>
                  <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
                    {SECTION_ICONS.parameters}
                  </ListItemIcon>
                  <ListItemText primary={`1. ${sop?.section_name}`} />
                </ListItem>
                {sections.map((section, sectionIdx) => (
                  <React.Fragment key={`section_${section.id}`}>
                    <ListItem disablePadding>
                      <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(4) }}>
                        {SECTION_ICONS[section.image]}
                      </ListItemIcon>
                      <ListItemText primary={`${sectionIdx + 2}. ${section.title}`} />
                    </ListItem>
                    {section.subsections.map((subsectionId, subsectionIdx) => {
                      const subsection = subsectionsById[subsectionId];
                      return (
                        <ListItem
                          key={`subsection_${subsection.id}`}
                          sx={{ pl: 8 }}
                          disablePadding
                        >
                          <ListItemText primary={`${sectionIdx + 2}.${subsectionIdx + 1} ${subsection.title}`} />
                        </ListItem>
                      );
                    })}
                  </React.Fragment>
                ))}
              </List>
            </Field>
          ) : null }
        </Box>
      </SidePreviewContent>
    </>
  );
}

SopPreviewContent.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default SopPreviewContent;
