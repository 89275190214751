import * as pinActions from './pin.actions';

import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { ENTITY_PIN } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, pinActions.fetchPins);
  reduceEntities(builder, pinActions.createPin);
  reduceEntities(builder, pinActions.fetchPin, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_PIN)),
  });
  reduceEntities(builder, pinActions.patchPin);
  reduceEntities(builder, pinActions.deletePin, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_PIN)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_PIN)),
  });
};
