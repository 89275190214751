import * as DirectoryService from 'services/DirectoryService';
import * as directorySelectors from './directory.selectors';
import * as schemas from 'constants/schemas';

import { createServiceThunk } from 'store/store.actions.utils';

// #############################################################################
// ################################ Directories ################################
// #############################################################################
export const fetchDirectories = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/fetchDirectories`,
  service: DirectoryService.fetchDirectories,
  schema: [schemas.directory],
  isPaginated: true,
});

export const createDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/createDirectory`,
  service: DirectoryService.createDirectory,
  schema: schemas.directory,
});

export const fetchDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/fetchDirectory`,
  service: DirectoryService.fetchDirectory,
  schema: schemas.directory,
});

export const patchDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/patchDirectory`,
  service: DirectoryService.patchDirectory,
  schema: schemas.directory,
  preprocessingFn: ({ id, data }, { getState }) => ({
    id,
    data: {
      ...directorySelectors.selectDirectoryUpdates(getState(), id),
      ...data,
    },
  }),
});

export const deleteDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/deleteDirectory`,
  service: DirectoryService.deleteDirectory,
  schema: schemas.directory,
});

export const deleteDirectoryPermanently = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/deleteDirectoryPermanently`,
  service: DirectoryService.deleteDirectoryPermanently,
  schema: schemas.directory,
});

export const checkinDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/checkinDirectory`,
  service: DirectoryService.checkinDirectory,
  schema: schemas.directory,
  preprocessingFn: ({ id }, { getState }) => {
    const state = getState();
    return {
      id,
      data: {
        ...directorySelectors.selectDirectoryUpdates(state, id),
        master_note: directorySelectors.selectMasterNotePatchData(state, directorySelectors.selectDirectory(state, id, 'master_note')),
      },
    };
  },
});

export const checkoutDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/checkoutDirectory`,
  service: DirectoryService.checkoutDirectory,
  schema: schemas.directory,
});

export const cloneDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/cloneDirectory`,
  service: DirectoryService.cloneDirectory,
  schema: schemas.directory,
});

export const generateDirectoryTemplate = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/generateDirectoryTemplate`,
  service: DirectoryService.generateDirectoryTemplate,
  schema: schemas.directory,
});

export const instantiateDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/instantiateDirectory`,
  service: DirectoryService.instantiateDirectory,
  schema: schemas.directory,
});

export const restoreDirectory = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY}/restoreDirectory`,
  service: DirectoryService.restoreDirectory,
  schema: schemas.directory,
});


// #############################################################################
// ############################## Directory Items ##############################
// #############################################################################
export const fetchDirectoryItems = createServiceThunk({
  name: `${schemas.ENTITY_DIRECTORY_ITEM}/fetchDirectoryItems`,
  service: DirectoryService.fetchDirectoryItems,
  schema: [schemas.directoryItem],
  isPaginated: true,
});


// #############################################################################
// ############################### Master Notes ################################
// #############################################################################
export const fetchMasterNote = createServiceThunk({
  name: `${schemas.ENTITY_MASTER_NOTE}/fetchMasterNote`,
  service: DirectoryService.fetchMasterNote,
  schema: schemas.masterNote,
});

export const patchMasterNote = createServiceThunk({
  name: `${schemas.ENTITY_MASTER_NOTE}/patchMasterNote`,
  service: DirectoryService.patchMasterNote,
  schema: schemas.masterNote,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: directorySelectors.selectMasterNotePatchData(getState(), id),
  }),
});

export const createMasterNoteItem = createServiceThunk({
  name: `${schemas.ENTITY_MASTER_NOTE}/createMasterNoteItem`,
  service: DirectoryService.createMasterNoteItem,
  schema: schemas.masterNote,
  preprocessingFn: ({ id, data }, { getState }) => ({
    id,
    data: {
      ...directorySelectors.selectMasterNotePatchData(getState(), id),
      _post_data: data,
    },
  }),
});

export const createMasterNoteSection = createServiceThunk({
  name: `${schemas.ENTITY_MASTER_NOTE}/createMasterNoteSection`,
  service: DirectoryService.createMasterNoteSection,
  schema: schemas.masterNote,
  preprocessingFn: ({ id, data }, { getState }) => ({
    id,
    data: {
      ...directorySelectors.selectMasterNotePatchData(getState(), id),
      _post_data: data,
    },
  }),
});

export const deleteMasterNoteItem = createServiceThunk({
  name: `${schemas.ENTITY_MASTER_NOTE}/deleteMasterNoteItem`,
  service: DirectoryService.deleteMasterNoteItem,
  schema: schemas.masterNote,
  preprocessingFn: ({ id }, { getState }) => {
    const state = getState();
    const sectionId = directorySelectors.selectMasterNoteItem(state, id, 'master_note_section');
    const masterNoteId = directorySelectors.selectMasterNoteSection(state, sectionId, 'master_note');
    return {
      id: masterNoteId,
      data: {
        ...directorySelectors.selectMasterNotePatchData(getState(), masterNoteId),
        _delete_data: { id },
      },
    };
  },
});

export const deleteMasterNoteSection = createServiceThunk({
  name: `${schemas.ENTITY_MASTER_NOTE}/deleteMasterNoteSection`,
  service: DirectoryService.deleteMasterNoteSection,
  schema: schemas.masterNote,
  preprocessingFn: ({ id }, { getState }) => {
    const state = getState();
    const masterNoteId = directorySelectors.selectMasterNoteSection(state, id, 'master_note');
    return {
      id: masterNoteId,
      data: {
        ...directorySelectors.selectMasterNotePatchData(getState(), masterNoteId),
        _delete_data: { id },
      },
    };
  },
});
