import client from 'services';

// #############################################################################
// ############################ Signature Workflows ############################
// #############################################################################
export function createSignatureWorkflow(data, config) {
  return client({ method: 'post', url: '/api/signature-workflows/', data, ...config });
}

// #############################################################################
// ################################ Signatures #################################
// #############################################################################
export function getSignatures(params, config) {
  return client({ method: 'get', url: '/api/signatures/', params, ...config });
}

export function createSignature(data, config) {
  return client({ method: 'post', url: '/api/signatures/', data, ...config });
}

export function patchSignature({ id, data }, config) {
  return client({ method: 'patch', url: `/api/signatures/${id}/`, data, ...config });
}

export function deleteSignature({ id }, config) {
  return client({ method: 'delete', url: `/api/signatures/${id}/`, ...config });
}

export function rejectSignature({ id, data }, config) {
  return client({ method: 'patch', url: `/api/signatures/${id}/reject/`, data, ...config });
}

export function signSignature({ id, data }, config) {
  return client({ method: 'patch', url: `/api/signatures/${id}/sign/`, data, ...config });
}

export function verifySignature({ id }, config) {
  return client({ method: 'get', url: `/api/signatures/${id}/verify/`, ...config });
}
