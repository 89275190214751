import React, { useMemo, useState } from 'react';
import { useMyId, useTeam, useUser } from 'hooks/store.hooks';

import { AvatarSpace } from 'components/Avatar';
import { ENTITY_TEAM } from 'constants/schemas';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import { SPACE_TEAM } from 'constants/space.constants';
import { formatType } from 'utils/entity.utils';

function OpusTeamMenuItem(props) {
  const { onSelect } = props;
  const [nestedMenuOpen, setNestedMenuOpen] = useState(true);
  const myId = useMyId();
  const myTeamIds = useUser(myId, 'teams');
  const myTeamNames = useTeam(myTeamIds, 'name');
  const myTeams = useMemo(() => {
    const teams = (myTeamIds ?? []).map((id, idx) => ({ id, name: myTeamNames[idx] }));
    teams.sort((t1, t2) => t1.name.localeCompare(t2.name));
    return teams;
  }, [myTeamIds, myTeamNames]);

  const onClick = (teamId) => {
    onSelect(SPACE_TEAM, teamId);
    setNestedMenuOpen(false);
  };

  return (
    <MenuItem
      icon={<AvatarSpace spaceType={SPACE_TEAM} />}
      label='Opus Team'
      listItemTextProps={{
        secondary: `Content shared with one of your ${formatType(ENTITY_TEAM, { plural: true, lowercase: true })}`,
      }}
      nested
      nestedMenuProps={{ open: nestedMenuOpen }}
    >
      { myTeams.map((team) => (
        <MenuItem
          key={`team-${team.id}`}
          icon={<AvatarSpace spaceType={SPACE_TEAM} spaceId={team.id} />}
          label={team.name}
          onClick={() => onClick(team.id)}
        />
      ))}
      { myTeams.length === 0 ? (
        <MenuItem label={`No ${formatType(ENTITY_TEAM, { plural: true })} Found`} disabled />
      ) : null}
    </MenuItem>
  );
}

OpusTeamMenuItem.propTypes = {
  onSelect: PropTypes.func,
};

export default OpusTeamMenuItem;
