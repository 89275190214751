import { Navigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMyGlobalPermission } from 'hooks/store.hooks';

import PropTypes from 'prop-types';
import React from 'react';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function ProtectedRoute(props) {
  const { loginRequirement, permissionRequirement, children } = props;
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const hasPermissions = useMyGlobalPermission(...(permissionRequirement ?? []));
  const matchesLoginRequirement = !isDefined(loginRequirement) || loginRequirement === isAuthenticated;
  const isAllowed = matchesLoginRequirement && (hasPermissions || !isDefined(permissionRequirement));
  const redirectTo = !isAuthenticated ? '/login' : location.state?.from?.pathname ?? '/';

  return isAllowed ? children : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
}

ProtectedRoute.propTypes = {
  loginRequirement: PropTypes.bool,
  permissionRequirement: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

export default ProtectedRoute;
