import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function Loading(props) {
  const { containerProps, circularProgressProps } = props;
  return (
    <Box
      {...containerProps}
      sx={mergeSx({ display: 'flex', width: 1, height: 1 }, containerProps?.sx)}
    >
      <CircularProgress
        {...circularProgressProps}
        sx={mergeSx({ m: 'auto' }, circularProgressProps?.sx)}
      />
    </Box>
  );
}

Loading.propTypes = {
  containerProps: PropTypes.object,
  circularProgressProps: PropTypes.object,
};

export default Loading;
