import React from 'react';
import { ReactComponent as SignatureWarning } from './SignatureWarning.svg';
import SvgIcon from '@mui/material/SvgIcon';

const SignatureWarningIcon = React.forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    inheritViewBox
    component={SignatureWarning}
  />
));

SignatureWarningIcon.displayName = 'SignatureWarningIcon';

export default SignatureWarningIcon;
