import React from 'react';
import User from './User';
import { useParams } from 'react-router-dom';

function UserPage() {
  const { id } = useParams();
  return (<User id={parseFloat(id)} />);
}

export default UserPage;
