import { Day, useDay } from './Day';

import { BLOCK_DAY } from './Day.constants';
import DayElement from './DayElement';
import { EditorComponents } from '@acheloisbiosoftware/absui.core';
import React from 'react';
import TodayIcon from '@mui/icons-material/Today';

const { generateToolbarButtonAction } = EditorComponents;

export default {
  plugin: useDay,
  renderElement: (props) => (props?.element?.type === BLOCK_DAY ? (<DayElement {...props} />) : null),
  renderToolbarActions: generateToolbarButtonAction({
    icon: TodayIcon,
    label: 'Insert New Day',
    onClick: (editor) => editor.Day.insertDay(),
    isDisabled: (editor) => !Day.canInsertDay(editor, editor.selection),
  }),
};
