import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SPACE_OPUS_U, SPACE_USER } from 'constants/space.constants';
import { useFetchListing, useMyGlobalPermission, useMyId, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import { AvatarSpace } from 'components/Avatar';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import ContentButton from 'components/ContentButton';
import { ENTITY_USER } from 'constants/schemas';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import OpusOrgMenuItem from './OpusOrgMenuItem';
import OpusTeamMenuItem from './OpusTeamMenuItem';
import OpusUserMenuItem from './OpusUserMenuItem';
import Tooltip from '@mui/material/Tooltip';
import { navigationActions } from 'store/navigation';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import useSpaceName from 'hooks/useSpaceName';
import { userActions } from 'store/entity';

function AppSpace() {
  const containerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const spaceName = useSpaceName();
  const myId = useMyId();

  const openDialog = useCallback(() => setAnchorEl(containerRef.current), []);
  const closeDialog = useCallback(() => setAnchorEl(null), []);

  const onSelect = useCallback((newSpaceType, spaceId) => {
    dispatch(navigationActions.setSpace({ spaceType: newSpaceType, spaceId }));
    closeDialog();
  }, [dispatch, closeDialog]);

  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const fetchParams = useMemo(() => ({
    condition: canViewUsers && Boolean(anchorEl),
    params: { filters: { is_active: true }},
  }), [canViewUsers, anchorEl]);
  useFetchListing(userActions.fetchUsers, fetchParams);

  return (
    <Box ref={containerRef}>
      <Tooltip arrow title={spaceName} enterDelay={isLg ? 1000 : 100}>
        <Box component='span'>
          {isLg ? (
            <ContentButton
              listItemButtonProps={{ sx: { py: 0.25, px: 1, minHeight: 0, color: 'text.secondary' }}}
              onClick={openDialog}
              icon={(<AvatarSpace spaceType={spaceType} spaceId={spaceGenericId.id} />)}
              content={spaceName}
              listItemTextProps={{
                primaryTypographyProps: {
                  variant: 'subtitle2',
                  sx: { maxWidth: 200 },
                },
              }}
            />
          ) : (
            <Button onClick={openDialog} icon size='small' sx={{ p: 0 }}>
              <AvatarSpace spaceType={spaceType} spaceId={spaceGenericId.id} />
            </Button>
          )}
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeDialog}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem
          icon={<AvatarSpace spaceType={SPACE_OPUS_U} />}
          label='Opus U'
          listItemTextProps={{
            secondary: 'All content you have permission to access',
          }}
          onClick={() => onSelect(SPACE_OPUS_U)}
        />
        <MenuItem
          icon={<AvatarSpace spaceType={SPACE_USER} spaceId={myId} />}
          label='My Space'
          listItemTextProps={{
            secondary: 'Only content you own',
          }}
          onClick={() => onSelect(SPACE_USER, myId)}
        />
        <OpusUserMenuItem onSelect={onSelect} />
        <OpusTeamMenuItem onSelect={onSelect} />
        <OpusOrgMenuItem onSelect={onSelect} />
      </Menu>
    </Box>
  );
}

export default AppSpace;
