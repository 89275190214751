import { BLOCK_SEARCH, BLOCK_VARIABLE } from './FormulaEditor.constants';
import React, { useEffect, useRef, useState } from 'react';
import { ReactEditor, useSelected, useSlate } from 'slate-react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { insertVariable } from './FormulaPlugin';
import { useCalcUuid } from './hooks';
import { useEntityScope } from 'hooks/scope.hooks';
import { useScopeVariableList } from 'hooks/store.hooks';

function VariableElement(props) {
  const { attributes, element, children } = props;
  const selected = useSelected();

  return (
    <Box {...attributes} component='span' contentEditable={false}>
      <Chip
        label={element.label}
        color={selected ? 'primary' : (element.uuid ? 'default' : 'error')}
        sx={{ userSelect: 'none', m: 0.5 }}
      />
      {children}
    </Box>
  );
}

VariableElement.propTypes = {
  attributes: PropTypes.object,
  element: PropTypes.shape({
    label: PropTypes.string,
    uuid: PropTypes.string,
  }),
  children: PropTypes.node,
};

function SearchElement(props) {
  const { attributes, element, children } = props;
  const editor = useSlate();
  const selected = useSelected();
  const calcUuid = useCalcUuid();
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = useState(ref?.current);
  const path = ReactEditor.findPath(editor, element);
  const search = element.children.map((child) => child.text).join('').slice(1);
  const entityScope = useEntityScope();
  const variables = useScopeVariableList(entityScope);
  const variableOptions = variables.filter((v) => (
    v.uuid !== calcUuid &&
    v.label &&
    v.label.toLowerCase().includes(search.toLowerCase())
  ));

  const selectOption = (option) => {
    Transforms.select(editor, path);
    editor.deleteFragment();
    insertVariable(editor, option);
  };

  useEffect(() => {
    setAnchorEl(ref?.current);
  }, []);

  return (
    <Box
      {...attributes}
      component='span'
      sx={{ typography: 'body1', position: 'relative' }}
      ref={ref}
    >
      {children}
      <Popper
        open={Boolean(anchorEl && selected)}
        anchorEl={anchorEl}
        sx={{ zIndex: 'modal' }}
        placement='bottom-start'
      >
        <Paper
          elevation={8}
          sx={{ width: 200, maxHeight: 300, overflowY: 'auto' }}
          contentEditable={false}
        >
          <List>
            {variableOptions.map((option) => (
              <ListItem key={`option_${option.uuid}`} disablePadding>
                <ListItemButton
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => selectOption(option)}
                >
                  <ListItemText primary={option.label} />
                </ListItemButton>
              </ListItem>
            ))}
            { variableOptions.length === 0 ? (
              <ListItem disablePadding>
                <ListItemButton disabled onMouseDown={(event) => event.preventDefault()}>
                  <ListItemText primary='No variables found' />
                </ListItemButton>
              </ListItem>
            ) : null}
          </List>
        </Paper>
      </Popper>
    </Box>
  );
}

SearchElement.propTypes = {
  attributes: PropTypes.object,
  element: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
    })),
  }),
  children: PropTypes.node,
};

function Element(props) {
  const { attributes, children, element } = props;
  if (element.type === BLOCK_VARIABLE) return (<VariableElement {...props} />);
  if (element.type === BLOCK_SEARCH) return (<SearchElement {...props} />);
  return (
    <Box
      {...attributes}
      component='span'
      sx={{ typography: 'body1', display: 'inline-block', minWidth: 4 }}
    >
      {children}
    </Box>
  );

}

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
    uuid: PropTypes.string,
  }),
};

export default Element;
