import { COL_TYPE_ACTIONS, COL_TYPE_SELECT } from './DataTable.constants';
import { useColumn, useGroupByState } from './DataTableContext';

import PropTypes from 'prop-types';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function CellBase(props) {
  const { row, columnId, children, ...restProps } = props;
  const column = useColumn(columnId);
  const { type, cellProps, noWrap, primary, secondary } = column;
  const { groupBy } = useGroupByState();
  const isGrouping = groupBy === columnId;

  const _cellProps = typeof(cellProps) === 'function' ? (
    cellProps({ row, groupBy, isGrouping })
  ) : cellProps ?? {};

  return (
    <TableCell
      padding={type === COL_TYPE_SELECT ? 'checkbox' : null}
      {...restProps}
      {..._cellProps}
      sx={mergeSx(
        type === COL_TYPE_ACTIONS ? { width: 30 } : {},
        noWrap ? {
          whiteSpace: 'nowrap',
          overflowX: 'hidden',
          textOverflow: 'ellipsis',
        } : {},
        primary ? { color: 'text.primary', typography: 'subtitle2' } : {},
        secondary && !isGrouping ? { color: 'text.secondary', typography: 'body2' } : {},
        restProps?.sx,
        _cellProps?.sx,
      )}
    >
      { children }
    </TableCell>
  );
}

CellBase.propTypes = {
  row: PropTypes.object.isRequired,
  columnId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  children: PropTypes.node,
};

export default CellBase;
