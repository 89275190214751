export const scrollTo = (elementId, options = { behavior: 'smooth' }) => {
  document.getElementById(elementId)?.scrollIntoView(options);
};

export const generateSearchParams = (params, initSearchParams) => {
  const searchParams = new URLSearchParams(initSearchParams);
  Object.keys(params).forEach((key) => {
    searchParams.set(key, params[key]);
  });
  return searchParams;
};

export const navigateRef = { current: null };
export const locationRef = { current: null };
