import Paper from '@mui/material/Paper';
import React from 'react';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function PageContainer(props) {
  return (
    <Paper
      elevation={2}
      {...props}
      sx={mergeSx({
        bgcolor: 'background.default',
        borderRadius: 4,
        height: 1,
        width: 1,
        overflow: 'auto',
        position: 'relative',
        boxShadow: 0,
      }, props?.sx)}
    />
  );
}

PageContainer.propTypes = {
  sx: sxPropType,
};

export default PageContainer;
