import { userActions, userSelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import DepartmentDetails from './DepartmentDetails';
import DepartmentUsers from './DepartmentUsers';
import PropTypes from 'prop-types';
import React from 'react';
import { withEntity } from 'hooks/Entity.hooks';

function Department(props) {
  const { id } = props;
  return (
    <Box sx={{ px: 5, py: 4, flexGrow: 1, display: 'flex' }}>
      <Box sx={{ minWidth: 400, maxWidth: 800, mx: 'auto' }}>
        <DepartmentDetails id={id} />
        <DepartmentUsers id={id} />
      </Box>
    </Box>
  );
}

Department.propTypes = {
  id: PropTypes.number.isRequired,
};

export default withEntity(Department, {
  fetchAction: userActions.fetchDepartment,
  hasLoadedSelector: userSelectors.selectDepartmentHasLoaded,
  readOnlySelector: (state, id) => !userSelectors.selectDepartmentPermission(state, id, ACTION_UPDATE),
});
