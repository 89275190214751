import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import { FunctionProp, Prop, directorySelectors, userActions } from 'store/entity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SPACE_DEPARTMENT, SPACE_OPUS_U, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import {
  useDepartment,
  useFetchListing,
  useMyGlobalPermission,
  useMyId,
  useSpaceGenericId,
  useSpaceType,
  useTeam,
  useUser,
  useUserList,
} from 'hooks/store.hooks';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_VIEW } from 'constants/permission.constants';
import { AvatarSpace } from 'components/Avatar';
import Collapse from '@mui/material/Collapse';
import CollapseButton from 'components/CollapseButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavigatorColumn from './NavigatorColumn';
import { formatType } from 'utils/entity.utils';
import { isNil } from 'lodash';
import { navigationActions } from 'store/navigation';
import { useNavigatorSelected } from './Navigator.utils';

const EMPTY_ARRAY = [];
const userListParams = {
  prop: new Prop({
    id: 'id',
    label: 'full_name',
    icon: new FunctionProp((user) => <AvatarSpace spaceType={SPACE_USER} id={user.id} />),
  }),
  filter: (user) => user.is_active,
  sort: (u1, u2) => u1.last_name.localeCompare(u2.last_name) || u1.first_name.localeCompare(u2.first_name),
};
const teamProp = new Prop({
  id: 'id',
  label: 'name',
  icon: new FunctionProp((team) => <AvatarSpace spaceType={SPACE_TEAM} id={team.id} />),
});
const departmentProp = new Prop({
  id: 'id',
  label: 'name',
  icon: new FunctionProp((department) => <AvatarSpace spaceType={SPACE_DEPARTMENT} id={department.id} />),
});
const useAlphabeticalSort = (items) => useMemo(() => items.sort((a, b) => a.label.localeCompare(b.label)), [items]);

function SpaceNavigator() {
  const dispatch = useDispatch();
  const myId = useMyId();
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const [openRows, setOpenRows] = useState([]);
  const [selected, setSelected] = useNavigatorSelected();

  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const fetchParams = useMemo(() => ({
    condition: canViewUsers,
    params: { filters: { is_active: true }},
  }), [canViewUsers]);
  useFetchListing(userActions.fetchUsers, fetchParams);
  const users = useUserList(userListParams);

  const myTeamIds = useUser(myId, 'teams');
  const myTeams = useTeam(myTeamIds ?? EMPTY_ARRAY, teamProp);
  const myTeamsSorted = useAlphabeticalSort(myTeams);

  const myDepartmentIds = useUser(myId, 'departments');
  const myDepartments = useDepartment(myDepartmentIds ?? EMPTY_ARRAY, departmentProp);
  const myDepartmentsSorted = useAlphabeticalSort(myDepartments);

  const directoryInSpace = useSelector((state) => directorySelectors.selectDirectoryInSpace(state, selected, spaceType, spaceGenericId));
  useEffect(() => {
    if (!isNil(selected) && !directoryInSpace) {
      setSelected(null);
    }
  }, [selected, setSelected, directoryInSpace]);

  const setSpace = useCallback((newSpaceType, spaceId) => {
    dispatch(navigationActions.setSpace({ spaceType: newSpaceType, spaceId }));
  }, [dispatch]);

  const toggleOpenRow = useCallback((id) => {
    setOpenRows((prevOpenRows) => {
      if (prevOpenRows.includes(id)) {
        return prevOpenRows.filter((rowId) => rowId !== id);
      }
      return [...prevOpenRows, id];
    });
  }, []);

  const rows = useMemo(() => [
    {
      id: SPACE_OPUS_U,
      icon: <AvatarSpace size={32} spaceType={SPACE_OPUS_U} />,
      label: 'Opus U',
      description: 'All content you have permission to access',
      onClick: () => setSpace(SPACE_OPUS_U),
      selected: spaceType === SPACE_OPUS_U,
    },
    {
      id: 'my-space',
      icon: <AvatarSpace size={32} spaceType={SPACE_USER} spaceId={myId} />,
      label: 'My Space',
      description: 'Only content you own',
      onClick: () => setSpace(SPACE_USER, myId),
      selected: spaceType === SPACE_USER && spaceGenericId.id === myId,
    },
    {
      id: SPACE_USER,
      icon: <AvatarSpace size={32} spaceType={SPACE_USER} />,
      label: 'Opus User',
      description: `Only content owned by a specific ${formatType(ENTITY_USER, { lowercase: true })}`,
      onClick: () => toggleOpenRow(SPACE_USER),
      onClickChild: (user) => setSpace(SPACE_USER, user.id),
      childrenRows: users,
      selected: spaceType === SPACE_USER && spaceGenericId.id !== myId,
      childSelected: spaceGenericId.id,
    },
    {
      id: SPACE_TEAM,
      icon: <AvatarSpace size={32} spaceType={SPACE_TEAM} />,
      label: 'Opus Team',
      description: `Content shared with one of your ${formatType(ENTITY_TEAM, { plural: true, lowercase: true })}`,
      onClick: () => toggleOpenRow(SPACE_TEAM),
      onClickChild: (team) => setSpace(SPACE_TEAM, team.id),
      childrenRows: myTeamsSorted,
      selected: spaceType === SPACE_TEAM,
      childSelected: spaceGenericId.id,
    },
    {
      id: SPACE_DEPARTMENT,
      icon: <AvatarSpace size={32} spaceType={SPACE_DEPARTMENT} />,
      label: 'Opus Org',
      description: `Content shared with one of your ${formatType(ENTITY_DEPARTMENT, { plural: true, lowercase: true })}`,
      onClick: () => toggleOpenRow(SPACE_DEPARTMENT),
      onClickChild: (department) => setSpace(SPACE_DEPARTMENT, department.id),
      childrenRows: myDepartmentsSorted,
      selected: spaceType === SPACE_DEPARTMENT,
      childSelected: spaceGenericId.id,
    },
  ], [myId, toggleOpenRow, setSpace, users, myTeamsSorted, myDepartmentsSorted, spaceType, spaceGenericId]);

  return (
    <NavigatorColumn title='Spaces'>
      <List disablePadding sx={{ overflow: 'auto' }}>
        {rows.map((row) => (
          <React.Fragment key={row.id}>
            <ListItem disableGutters disablePadding>
              <ListItemButton
                onClick={row.onClick}
                selected={Boolean(row.selected && !openRows.includes(row.id))}
              >
                <ListItemIcon>{row.icon}</ListItemIcon>
                <ListItemText primary={row.label} secondary={row.description} />
                {(row.childrenRows ?? []).length > 0 ? (
                  <CollapseButton open={openRows.includes(row.id)} disableRipple />
                ) : null}
              </ListItemButton>
            </ListItem>

            {(row.childrenRows ?? []).length > 0 ? (
              <Collapse in={openRows.includes(row.id)} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {row.childrenRows.map((child) => (
                    <ListItemButton
                      key={child.id}
                      onClick={() => row.onClickChild(child)}
                      selected={Boolean(row.selected && row.childSelected === child.id)}
                      sx={{ pl: 5 }}
                    >
                      <ListItemIcon>{child.icon}</ListItemIcon>
                      <ListItemText primary={child.label} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </React.Fragment>
        ))}
      </List>
    </NavigatorColumn>
  );
}

export default SpaceNavigator;
