import React, { useState } from 'react';

import { AvatarSpace } from 'components/Avatar';
import { ENTITY_USER } from 'constants/schemas';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import { SPACE_USER } from 'constants/space.constants';
import { formatType } from 'utils/entity.utils';
import { useUserList } from 'hooks/store.hooks';

const listParams = {
  prop: { id: 'id', name: 'full_name' },
  filter: (user) => user.is_active,
  sort: (u1, u2) => u1.last_name.localeCompare(u2.last_name) || u1.first_name.localeCompare(u2.first_name),
};

function OpusUserMenuItem(props) {
  const { onSelect } = props;
  const [nestedMenuOpen, setNestedMenuOpen] = useState(true);
  const users = useUserList(listParams);

  const onClick = (userId) => {
    onSelect(SPACE_USER, userId);
    setNestedMenuOpen(false);
  };

  return (
    <MenuItem
      icon={<AvatarSpace spaceType={SPACE_USER} />}
      label='Opus User'
      listItemTextProps={{
        secondary: `Only content owned by a specific ${formatType(ENTITY_USER, { lowercase: true })}`,
      }}
      nested
      nestedMenuProps={{ open: nestedMenuOpen }}
    >
      { users.map((user) => (
        <MenuItem
          key={`user-${user.id}`}
          icon={<AvatarSpace spaceType={SPACE_USER} spaceId={user.id} />}
          label={user.name}
          onClick={() => onClick(user.id)}
        />
      ))}
      { (users ?? []).length === 0 ? (
        <MenuItem label={`No ${formatType(ENTITY_USER, { plural: true })} Found`} disabled />
      ) : null}
    </MenuItem>
  );
}

OpusUserMenuItem.propTypes = {
  onSelect: PropTypes.func,
};

export default OpusUserMenuItem;
