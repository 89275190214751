import { isDefined } from '@acheloisbiosoftware/absui.utils';
import moment from 'moment';

export function formatDate(date, format, defaultValue = '–') {
  if (!date) return defaultValue;
  const momentDate = moment(date);
  if (!momentDate.isValid()) return defaultValue;
  if (!isDefined(format)) return momentDate.calendar();
  return momentDate.format(format);
}

export function timeNow() {
  return (new Date()).toISOString();
}

/**
 * Tests whether or not two times are within a certain amount of time of each
 * other. Units can be seconds, minutes, hours, days, days, isoWeek, weeks,
 * quarters, months, years.
 */
export function isTimeApproxEq(t1, t2, quantity = 0.01, unit = 'seconds') {
  const minT = moment(t1);
  minT.subtract(quantity, unit);
  const maxT = moment(t1);
  maxT.add(quantity, unit);
  return moment(t2).isBetween(minT, maxT);
}

export function momentValue(m) {
  const mMoment = moment(m);
  if (!mMoment.isValid()) return -1;
  return mMoment.valueOf();
}

export function momentComparator(m1, m2) {
  return momentValue(m2) - momentValue(m1);
}
