import { ENTITY_MASTER_NOTE, ENTITY_MASTER_NOTE_ITEM, ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';

import { clearUpdate } from '../entity.reducers.utils';
import { isEqual } from 'lodash';

export default {
  [ENTITY_MASTER_NOTE]: {
    mergeStrategy: (oldValue, newValue, { id, slice }) => {
      if (!isEqual(oldValue?.sections, newValue?.sections)) {
        clearUpdate(slice, id, 'sections');
      }
      return Object.assign(oldValue ?? {}, newValue);
    },
  },
  [ENTITY_MASTER_NOTE_SECTION]: {
    mergeStrategy: (oldValue, newValue, { id, state, action }) => {
      const sectionSlice = state[ENTITY_MASTER_NOTE_SECTION];
      const itemSlice = state[ENTITY_MASTER_NOTE_ITEM];
      const newSections = action.payload.entities?.[ENTITY_MASTER_NOTE_SECTION] ?? {};
      const newItems = action.payload.entities?.[ENTITY_MASTER_NOTE_ITEM] ?? {};
      const visited = {};

      const clearOrderingUpdate = (sectionId) => {
        if (visited[sectionId]) return;
        visited[sectionId] = true;

        const itemsUpdates = sectionSlice.updates[sectionId]?.items ?? [];
        const newSectionItems = newSections[sectionId]?.items ?? [];
        clearUpdate(sectionSlice, sectionId, 'items');
        [...itemsUpdates, ...newSectionItems].forEach((itemId) => {
          const sectionUpdate = itemSlice.updates[itemId]?.master_note_section;
          const newSection = newItems[itemId]?.master_note_section;
          clearUpdate(itemSlice, itemId, 'master_note_section');
          if (sectionUpdate) clearOrderingUpdate(sectionUpdate);
          if (newSection) clearOrderingUpdate(newSection);
        });
      };

      if (!isEqual(oldValue?.items, newValue?.items)) {
        clearOrderingUpdate(id);
      }
      return Object.assign(oldValue ?? {}, newValue);
    },
  },
};
