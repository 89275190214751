import { PinIcon, UnpinIcon } from 'constants/icon.constants';
import React, { useCallback } from 'react';
import { useDirectoryItem, useEntity } from 'hooks/store.hooks';

import BaseAction from './BaseAction';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { pinActions } from 'store/entity/pin';
import { useDispatch } from 'react-redux';
import { useGenericId } from 'utils/generic.utils';

function ActionPin(props) {
  const { id, type, pinId, ...restProps } = props;
  const dispatch = useDispatch();
  const genericId = useGenericId(id, type);
  const directoryItemId = useEntity(genericId, 'directory_item');
  const _pinId = useDirectoryItem(directoryItemId, 'pin') ?? pinId;
  const isPinned = !isNil(_pinId);

  const onPin = useCallback(async () => {
    try {
      await dispatch(pinActions.createPin({
        directory_item: directoryItemId,
      })).unwrap();
    } catch {}
  }, [dispatch, directoryItemId]);

  const onUnpin = useCallback(async () => {
    try {
      await dispatch(pinActions.deletePin({ id: _pinId })).unwrap();
    } catch {}
  }, [dispatch, _pinId]);

  return (
    <BaseAction
      icon={isPinned ? <UnpinIcon /> : <PinIcon />}
      label={isPinned ? 'Remove from Favorites' : 'Add to Favorites'}
      onAction={isPinned ? onUnpin : onPin}
      {...restProps}
    />
  );
}

ActionPin.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
  pinId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ActionPin;
