import React, { useEffect } from 'react';

import NewExperimentFormBlock from './NewExperimentFormBlock';
import NewExperimentFormInline from './NewExperimentFormInline';
import PropTypes from 'prop-types';
import { RELATIONSHIP_PERMISSION_OPTIONS } from 'constants/permission.constants';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { sopActions } from 'store/entity';
import { useDispatch } from 'react-redux';
import { useSopHasLoaded } from 'hooks/store.hooks';

function NewExperimentForm(props) {
  const {
    inline = false,
    formData = {},
    updateFormData,
    excludeFields = [],
    fieldPropsMap,
    containerProps,
  } = props;
  const dispatch = useDispatch();
  const { related_sop } = formData;
  const sopLoaded = useSopHasLoaded(related_sop);

  useEffect(() => {
    if (isDefined(related_sop) && !sopLoaded) {
      dispatch(sopActions.fetchSop({ id: related_sop }));
    }
  }, [sopLoaded, dispatch, related_sop]);

  const FormComponent = inline ? NewExperimentFormInline : NewExperimentFormBlock;

  return (
    <FormComponent
      formData={formData}
      updateFormData={updateFormData}
      excludeFields={excludeFields}
      fieldPropsMap={fieldPropsMap}
      containerProps={containerProps}
    />
  );
}

NewExperimentForm.propTypes = {
  inline: PropTypes.bool,
  formData: PropTypes.shape({
    title: PropTypes.string,
    related_sop: PropTypes.number,
    is_template: PropTypes.bool,
    permission_relationships: PropTypes.arrayOf(PropTypes.shape({
      individual_id: PropTypes.number,
      individual_content_type: PropTypes.string,
      permission: PropTypes.oneOf(RELATIONSHIP_PERMISSION_OPTIONS),
    })),
  }),
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.oneOf(['title', 'related_sop', 'permission_relationships'])),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewExperimentForm;
