import React, { useState } from 'react';

import { DataTableAction } from 'components/DataTable';
import { ENTITY_TASK } from 'constants/schemas';
import NewTaskDialog from 'components/NewTaskDialog';
import PropTypes from 'prop-types';
import { TaskIcon } from 'constants/icon.constants';
import { formatType } from 'utils/entity.utils';

function ActionAddTask(props) {
  const { initState } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const isTemplate = initState?.is_template;
  return (
    <>
      <DataTableAction
        label={`New ${formatType(ENTITY_TASK, { template: isTemplate })}`}
        icon={<TaskIcon colored template={isTemplate} />}
        onClick={() => setDialogOpen(true)}
      />
      <NewTaskDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        initState={initState}
      />
    </>
  );
}

ActionAddTask.propTypes = {
  initState: PropTypes.object,
};

export default ActionAddTask;
