import PropTypes from 'prop-types';
import React from 'react';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

function ListingPageContent(props) {
  const { selected, contentComponent: Component, contentComponentProps } = props;
  return isDefined(Component) ? (
    <Component selected={selected} {...contentComponentProps} />
  ) : null;
}

ListingPageContent.propTypes = {
  selected: PropTypes.any,
  contentComponent: PropTypes.elementType,
  contentComponentProps: PropTypes.object,
};

export default ListingPageContent;
