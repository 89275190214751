import { directoryActions, directorySelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import MasterNoteBody from './MasterNoteBody';
import MasterNoteNav from './MasterNoteNav';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import { withEntity } from 'hooks/Entity.hooks';

function MasterNote(props) {
  const { id, loading, collapsed, selectedItem } = props;
  return (
    <Stack direction='row' sx={{ height: 1 }}>
      { !collapsed ? (<MasterNoteNav id={id} loading={loading} />) : null }
      <MasterNoteBody
        id={id}
        loading={loading}
        collapsed={collapsed}
        selectedItem={selectedItem}
      />
    </Stack>
  );
}

MasterNote.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
  collapsed: PropTypes.bool,
  selectedItem: PropTypes.number,
};

export default withEntity(MasterNote, {
  fetchAction: directoryActions.fetchMasterNote,
  hasLoadedSelector: directorySelectors.selectMasterNoteHasLoaded,
  readOnlySelector: (state, id) => (
    !directorySelectors.selectMasterNotePermission(state, id, ACTION_UPDATE) ||
    !directorySelectors.selectDirectory(
      state,
      directorySelectors.selectMasterNote(state, id, 'parent'),
      'is_checked_out',
    )
  ),
});
