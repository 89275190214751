import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function FieldLabel(props) {
  const { required, help, children, sx, ...restProps } = props;
  return (
    <Typography
      variant='subtitle2'
      color='text.secondary'
      {...restProps}
      sx={mergeSx({ mt: 1 }, sx)}
    >
      {children}
      { required ? (
        <Typography component='span' sx={{ color: 'error.main' }}> *</Typography>
      ) : null}
      { help ? (
        <Tooltip title={help} arrow placement='right'>
          <HelpOutlineIcon sx={{ fontSize: 16, verticalAlign: 'text-top', ml: 1 }} />
        </Tooltip>
      ) : null}
    </Typography>
  );
}

FieldLabel.propTypes = {
  required: PropTypes.bool,
  help: PropTypes.any,
  children: PropTypes.node,
  sx: sxPropType,
};

export default FieldLabel;
