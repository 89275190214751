import {
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import { GenericProp, entitySelectors } from 'store/entity';
import { getGenericId, useGenericId } from 'utils/generic.utils';
import { useEntity, useEntityIcon, useEntityPermission } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import { Button } from '@acheloisbiosoftware/absui.core';
import ContentButton from 'components/ContentButton';
import Field from 'components/Field';
import GlobalSearch from 'components/GlobalSearch';
import Link from 'components/Link';
import { OpenIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { getPath } from 'utils/entity.utils';

const entityTitleProp = new GenericProp({
  [ENTITY_SOP]: 'long_title',
  [ENTITY_EXPERIMENT]: 'long_title',
  [ENTITY_NOTE]: 'long_title',
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_TASK]: 'long_title',
});

const excludedSearchTypes = [ENTITY_TASK];

function SelectExistingDocument(props) {
  const {
    related_obj_id,
    related_obj_content_type,
    onChange,
    isExisting,
    onClear,
    hideOpen,
  } = props;

  const genericId = useGenericId(related_obj_id, related_obj_content_type);
  const canViewEntity = useEntityPermission(genericId, ACTION_VIEW);
  const entityTitle = useEntity(genericId, entityTitleProp);
  const RelatedObjectIcon = useEntityIcon(genericId);
  const entityPath = getPath(genericId);

  const onClickResult = (_, result) => {
    onChange('related_obj_id', result.id);
    onChange('related_obj_content_type', result.type);
    onChange('related_obj_init_data', {});
  };

  const globalSearchFilter = (dirItem, state) => !entitySelectors.selectEntity(state, getGenericId(dirItem.object_id, dirItem.content_type), 'is_template');

  return (
    <Field label='Document'>
      <Stack direction='row' alignItems='center'>
        <GlobalSearch
          onClickResult={onClickResult}
          textFieldProps={{ sx: { width: 0.4, mr: 1 }}}
          filter={globalSearchFilter}
          excludeTypes={excludedSearchTypes}
        />
        <Stack
          direction='row'
          alignItems='center'
          sx={{ width: 0.6, ml: 1 }}
        >
          {isExisting ? (
            <Tooltip title={canViewEntity ? null : 'You do not have permission to view this document'} arrow>
              <Stack sx={{ width: 1 }}>
                <ContentButton
                  icon={<RelatedObjectIcon colored />}
                  content={canViewEntity ? entityTitle : (
                    <Typography color='text.disabled'>[Content Hidden]</Typography>
                  )}
                  disabled={!canViewEntity || hideOpen}
                  listItemButtonProps={{
                    component: Link,
                    to: entityPath,
                  }}
                  onClear={onClear}
                  secondaryAction={canViewEntity && !hideOpen ? (
                    <Tooltip title='Open' arrow>
                      <Button
                        icon
                        size='small'
                        color='inherit'
                        component={Link}
                        to={entityPath}
                      >
                        <OpenIcon fontSize='small' />
                      </Button>
                    </Tooltip>
                  ) : null}
                />
              </Stack>
            </Tooltip>
          ) : (
            <Typography noWrap color='text.secondary'>No document chosen...</Typography>
          )}
        </Stack>
      </Stack>
    </Field>
  );
}

SelectExistingDocument.propTypes = {
  related_obj_id: PropTypes.number,
  related_obj_content_type: PropTypes.oneOf([
    ENTITY_DIRECTORY,
    ENTITY_EXPERIMENT,
    ENTITY_NOTE,
    ENTITY_SOP,
    ENTITY_TASK,
  ]),
  onChange: PropTypes.func,
  isExisting: PropTypes.bool,
  onClear: PropTypes.func,
  hideOpen: PropTypes.bool,
};

export default SelectExistingDocument;
