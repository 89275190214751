import { useCanGoBack, useCanGoForward } from 'hooks/navigation.hooks';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@acheloisbiosoftware/absui.core';
import React from 'react';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';

function AppNavigation() {
  const navigate = useNavigate();
  const canGoBack = useCanGoBack();
  const canGoForward = useCanGoForward();

  return (
    <Stack sx={{ my: 'auto', color: 'text.icon' }} direction='row'>
      <Button
        icon
        size='small'
        color='inherit'
        disabled={!canGoBack}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon fontSize='small' />
      </Button>
      <Button
        icon
        size='small'
        color='inherit'
        disabled={!canGoForward}
        onClick={() => navigate(1)}
      >
        <ArrowForwardIcon fontSize='small' />
      </Button>
    </Stack>
  );
}

export default AppNavigation;
