import React, { useMemo } from 'react';
import { useColumn, useGroupByState, useSortState } from './DataTableContext';

import Box from '@mui/material/Box';
import { COL_TYPE_SELECT } from './DataTable.constants';
import Checkbox from '@mui/material/Checkbox';
import { ConditionalWrapper } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function CheckboxHeaderContent(props) {
  const { columnId, rowIds } = props;
  const column = useColumn(columnId);
  const { selection, onSelect, disabled } = column;

  const checkedDisabledRows = useMemo(
    () => rowIds.filter((rowId) => (disabled ?? []).includes(rowId) && (selection ?? []).includes(rowId)),
    [rowIds, disabled, selection],
  );
  const selectableRows = useMemo(
    () => rowIds.filter((rowId) => !(disabled ?? []).includes(rowId)),
    [rowIds, disabled],
  );

  const onSelectAll = (event) => {
    onSelect?.(event.target.checked ? [...selectableRows, ...checkedDisabledRows] : [...checkedDisabledRows], event);
  };

  const numSelectedRows = selection.length - checkedDisabledRows.length;

  return (
    <Checkbox
      indeterminate={numSelectedRows > 0 && numSelectedRows < selectableRows.length}
      checked={numSelectedRows === selectableRows.length}
      onChange={onSelectAll}
    />
  );
}

CheckboxHeaderContent.propTypes = {
  columnId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  rowIds: PropTypes.array.isRequired,
};

function CellHeader(props) {
  const { columnId, rowIds, isLastColumn } = props;
  const column = useColumn(columnId);
  const { type, label, sortable, headerProps, noHeader } = column;
  const { order, orderBy, setOrderState } = useSortState();
  const { groupBy } = useGroupByState();

  const sortHandler = () => {
    if (orderBy === columnId) {
      if (order === 'asc') {
        setOrderState({ order: 'desc', orderBy: columnId });
      } else if (order === 'desc') {
        setOrderState({ order: 'asc', orderBy: null });
      }
    } else {
      setOrderState({ order: 'asc', orderBy: columnId });
    }
  };

  return (
    <TableCell
      {...headerProps}
      padding={type === COL_TYPE_SELECT ? 'checkbox' : null}
      sx={mergeSx({ bgcolor: 'background.grey', pr: 0 }, headerProps?.sx)}
    >
      <Box sx={{ display: 'flex', width: 1 }}>
        { noHeader && (columnId !== groupBy) ? null : (
          <>
            { type === COL_TYPE_SELECT ? (
              <CheckboxHeaderContent columnId={columnId} rowIds={rowIds} />
            ) : (
              <ConditionalWrapper
                condition={sortable}
                wrapperComponent={TableSortLabel}
                wrapperProps={{
                  active: orderBy === columnId,
                  direction: orderBy === columnId ? order : 'asc',
                  onClick: sortHandler,
                  sx: [
                    { flexGrow: 1 },
                    { '&.MuiTableSortLabel-root:focus': { color: 'text.primary' }},
                  ],
                }}
              >
                {label}
              </ConditionalWrapper>
            )}
            { isLastColumn ? null : (
              <Box
                sx={{
                  width: 2,
                  height: 16,
                  bgcolor: 'divider',
                  borderRadius: 1,
                  my: 'auto',
                  mr: 0,
                  ml: 'auto',
                }}
              />
            )}
          </>
        )}
      </Box>
    </TableCell>
  );
}

CellHeader.propTypes = {
  columnId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  rowIds: PropTypes.any,
  isLastColumn: PropTypes.bool,
};

export default CellHeader;
