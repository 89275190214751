import { Prop, sopActions } from 'store/entity';
import React, { useCallback, useMemo, useState } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useFetchListing, useSopList } from 'hooks/store.hooks';

import { ENTITY_SOP } from 'constants/schemas';
import PropTypes from 'prop-types';
import { SearchIcon } from 'constants/icon.constants';
import SopTable from 'components/SopTable';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { TextField } from '@acheloisbiosoftware/absui.core';
import Typography from '@mui/material/Typography';
import { formatType } from 'utils/entity.utils';

function SelectSop(props) {
  const {
    value,
    onChange,
    containerProps,
    sopTableContainerProps,
    sopTableProps,
  } = props;
  const [quickFilter, setQuickFilter] = useState('');
  const [showOldVersions, setShowOldVersions] = useState(false);

  const fetchOptions = useMemo(() => ({
    params: { filters: {
      is_template: false,
      ...(showOldVersions ? { is_published: true } : { is_effective_version: true }),
    }},
  }), [showOldVersions]);
  const loading = useFetchListing(sopActions.fetchSops, fetchOptions);

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (sop) => !sop.is_template && (showOldVersions ? sop.is_published : sop.is_effective_version),
  }), [showOldVersions]);
  const sopIds = useSopList(listParams);
  const selection = useMemo(() => (isDefined(value) ? [value] : []), [value]);

  const onSelect = useCallback((newSelection) => {
    onChange?.(newSelection[newSelection.length - 1] ?? null);
  }, [onChange]);

  const onRowClick = useCallback((row) => {
    onSelect([row.id]);
  }, [onSelect]);

  const columnProps = useMemo(() => ({
    ...sopTableProps?.columnProps,
    checkbox: {
      hidden: false,
      noHeader: true,
      selection,
      onSelect,
      ...sopTableProps?.columnProps?.checkbox,
    },
    status: {
      hidden: true,
      ...sopTableProps?.columnProps?.status,
    },
    actions: {
      hidden: true,
      ...sopTableProps?.columnProps?.actions,
    },
  }), [sopTableProps, selection, onSelect]);

  return (
    <Stack {...containerProps}>
      <TextField
        size='small'
        value={quickFilter}
        onChange={(_, newValue) => setQuickFilter(newValue)}
        startAdornment={<SearchIcon sx={{ color: 'text.icon' }} />}
        placeholder='Search...'
        clearable
        sx={{ my: 1 }}
      />
      <Stack direction='row' justifyContent='space-between' sx={{ my: 1 }}>
        <Typography variant='caption'>
          Note: if you don't see the {formatType(ENTITY_SOP, { lowercase: true })} you're looking for, ensure that it has been published.
        </Typography>
        <Stack direction='row' alignItems='center'>
          <Switch
            size='small'
            checked={showOldVersions}
            onChange={(event) => setShowOldVersions(event.target.checked)}
          />
          <Typography variant='body2'>Show Old Versions</Typography>
        </Stack>
      </Stack>
      <Stack
        {...sopTableContainerProps}
        sx={mergeSx({
          border: 1,
          borderColor: 'divider',
        }, sopTableContainerProps?.sx)}
      >
        <SopTable
          ids={sopIds}
          {...sopTableProps}
          loading={loading}
          columnProps={columnProps}
          onRowClick={onRowClick}
          quickFilter={quickFilter}
        />
      </Stack>
    </Stack>
  );
}

SelectSop.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  containerProps: PropTypes.object,
  sopTableContainerProps: PropTypes.object,
  sopTableProps: PropTypes.object,
};

export default SelectSop;
