import MasterNote, { MasterNoteConstants } from 'views/MasterNote';
import React, { useCallback, useEffect, useState } from 'react';
import { directoryActions, directorySelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import WorkspaceHeader from './WorkspaceHeader';
import WorkspaceTable from './WorkspaceTable';
import WorkspaceWarnings from './WorkspaceWarnings';
import { generateSearchParams } from 'utils/dom.utils';
import { getGenericId } from 'utils/generic.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { navigationActions } from 'store/navigation';
import { parseFloatList } from 'utils/helpers';
import { useDirectory } from 'hooks/store.hooks';
import { useDispatch } from 'react-redux';
import { useResizableWidth } from '@acheloisbiosoftware/absui.hooks';
import { useSearchParams } from 'react-router-dom';
import { withEntity } from 'hooks/Entity.hooks';

function TabContent(props) {
  const { in: _in, ...restProps } = props;
  return (
    <Box
      {...restProps}
      sx={mergeSx(
        { flexGrow: 1, overflow: 'auto' },
        _in ? {} : { display: 'none' },
        restProps?.sx,
      )}
    />
  );
}

TabContent.propTypes = { in: PropTypes.bool };

const RESIZER_WIDTH = 8;
const RESIZABLE_OPTIONS = {
  minWidth: 200,
  maxWidth: 600,
};

function WorkspaceContent(props) {
  const {
    id,
    collapsed,
    selectedMasterNoteItem,
    loading,
  } = props;
  const dispatch = useDispatch();
  const [viewMasterNote, setViewMasterNote] = useState(true);
  const masterNoteId = useDirectory(id, 'master_note');
  const setSearchParams = useSearchParams()[1];

  useEffect(() => {
    dispatch(navigationActions.addRecentlyViewed(getGenericId(id, ENTITY_DIRECTORY)));
  }, [dispatch, id]);

  const onExpand = useCallback(() => {
    setSearchParams((oldSearchParams) => {
      const oldExploreStr = oldSearchParams.get('explore') ?? '';
      const oldExplore = parseFloatList(oldExploreStr);
      const pathIdx = oldExplore.indexOf(selectedMasterNoteItem);
      const slicedExplore = pathIdx === -1 ? oldExplore : oldExplore.slice(0, pathIdx);
      const newSearchParams = generateSearchParams({ explore: slicedExplore }, oldSearchParams);
      if (slicedExplore.length === 0) {
        newSearchParams.delete('explore');
      }
      return newSearchParams;
    });
  }, [setSearchParams, selectedMasterNoteItem]);

  const { width, Resizer, isResizing } = useResizableWidth(300, RESIZABLE_OPTIONS);

  return (
    <Stack
      sx={[
        {
          height: 1,
          transition: (theme) => (isResizing ? null : theme.transitions.create('width')),
          overflow: 'auto',
        },
        collapsed ? {
          borderRight: 2,
          borderColor: 'divider',
          width,
          minWidth: width,
          position: 'relative',
        } : {
          width: 1,
          minWidth: MasterNoteConstants.MASTER_NOTE_CONTENT_MIN_WIDTH + MasterNoteConstants.MASTER_NOTE_NAV_MAX_WIDTH,
        },
      ]}
    >
      <WorkspaceWarnings id={id} collapsed={collapsed} />
      <WorkspaceHeader
        id={id}
        viewMasterNote={viewMasterNote}
        setViewMasterNote={setViewMasterNote}
        collapsed={collapsed}
        onExpand={onExpand}
      />
      { collapsed ? (
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          <MasterNote
            id={masterNoteId}
            preloaded
            collapsed
            selectedItem={selectedMasterNoteItem}
          />
        </Box>
      ) : (
        <>
          <TabContent in={viewMasterNote}>
            <MasterNote id={masterNoteId} preloaded />
          </TabContent>
          <TabContent in={!viewMasterNote}>
            <WorkspaceTable id={id} loading={loading} />
          </TabContent>
        </>
      )}
      { collapsed ? (
        <Resizer
          sx={{
            width: RESIZER_WIDTH,
            left: width - RESIZER_WIDTH,
            zIndex: 'header',
          }}
        />
      ) : null}
    </Stack>
  );
}

WorkspaceContent.propTypes = {
  id: PropTypes.number.isRequired,
  collapsed: PropTypes.bool,
  selectedMasterNoteItem: PropTypes.number,
  loading: PropTypes.bool,
};

export default withEntity(WorkspaceContent, {
  fetchAction: directoryActions.fetchDirectory,
  hasLoadedSelector: directorySelectors.selectDirectoryHasLoaded,
  readOnlySelector: (state, id) => (
    !directorySelectors.selectDirectoryPermission(state, id, ACTION_UPDATE) ||
    !directorySelectors.selectDirectory(state, id, 'is_checked_out')
  ),
  isInSpaceSelector: directorySelectors.selectDirectoryInSpace,
});
