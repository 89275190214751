import * as extraActions from './auth.extraActions';

import { extraReducers, reducers } from './auth.reducers';

import { AUTH } from 'store/store.constants';
import { createSlice } from '@reduxjs/toolkit';
import { getInitialState } from './auth.initialState';

const slice = createSlice({
  name: AUTH,
  initialState: getInitialState(),
  reducers,
  extraReducers,
});

export * as authSelectors from './auth.selectors';
export const authActions = { ...slice.actions, ...extraActions };
export const { reducer: authReducer } = slice;
