import { ActionOpen, IconButtonLinkAction } from 'components/EntityActions';
import { Prop, directoryActions, directorySelectors } from 'store/entity';
import React, { useCallback, useMemo } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { useDirectoryList, useFetchListing, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import { DIR_TYPE_PROGRAM } from 'constants/directory.constants';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import { LaunchIcon } from 'constants/icon.constants';
import NavigatorColumn from './NavigatorColumn';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WorkspaceTable from 'components/WorkspaceTable';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useNavigatorSelected } from './Navigator.utils';

function ProgramNavigator() {
  const navigate = useNavigate();
  const [selected, setSelected] = useNavigatorSelected();
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (directory, state) => (
      directory.type === DIR_TYPE_PROGRAM &&
      !directory.is_template &&
      directorySelectors.selectDirectoryInSpace(state, directory.id, spaceType, spaceGenericId)
    ),
  }), [spaceType, spaceGenericId]);
  const directoryIds = useDirectoryList(listParams);

  const fetchOptions = useMemo(
    () => ({ params: { filters: {
      type: DIR_TYPE_PROGRAM,
      is_template: false,
      ...(spaceType === SPACE_USER ? { created_by: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_TEAM ? { team: spaceGenericId.id } : {}),
      ...(spaceType === SPACE_DEPARTMENT ? { department: spaceGenericId.id } : {}),
    }}}),
    [spaceType, spaceGenericId],
  );
  const loading = useFetchListing(directoryActions.fetchDirectories, fetchOptions);

  const onRowClick = useCallback((row, event) => {
    if (event.detail > 1) {
      navigate(getPath(getGenericId(row.id, ENTITY_DIRECTORY)));
    } else {
      setSelected(row.id);
    }
  }, [setSelected, navigate]);

  const columnProps = useMemo(() => ({
    checkbox: {
      selection: isNil(selected) ? [] : [selected],
    },
    name: {
      renderValue: ({ row, value }) => (
        <Stack>
          <Typography variant='subtitle2'>{value || 'Untitled'}</Typography>
          <Typography color='text.secondary' variant='caption'>{formatDate(row.created)}</Typography>
        </Stack>
      ),
      cellProps: { sx: { whiteSpace: 'normal' }},
    },
    owner: { hidden: true },
    created: { hidden: true },
    actions: {
      showOnHover: false,
      inMenu: false,
      actions: ({ row }) => [{
        id: 'launch',
        component: ActionOpen,
        props: {
          id: row.id,
          type: ENTITY_DIRECTORY,
          component: IconButtonLinkAction,
          label: 'Open',
          icon: <LaunchIcon fontSize='small' />,
          size: 'small',
          color: 'primary',
        },
      }],
    },
  }), [selected]);

  return (
    <NavigatorColumn title='Programs'>
      <WorkspaceTable
        ids={directoryIds}
        columnProps={columnProps}
        onRowClick={onRowClick}
        stateInSearchParams
        loading={loading}
        noHeader
        disableFilter
        disableGroupBy
        initState={{
          orderBy: 'name',
          order: 'asc',
        }}
        sx={{ overflowY: 'auto' }}
      />
    </NavigatorColumn>
  );
}

export default ProgramNavigator;
