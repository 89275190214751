import client from 'services';

export function fetchTasks(params, config) {
  return client({ method: 'get', url: '/api/tasks/', params, ...config });
}

export function createTask(data, config) {
  return client({ method: 'post', url: '/api/tasks/', data, ...config });
}

export function fetchTask({ id }, config) {
  return client({ method: 'get', url: `/api/tasks/${id}/`, ...config });
}

export function patchTask({ id, data }, config) {
  return client({ method: 'patch', url: `/api/tasks/${id}/`, data, ...config });
}

export function deleteTask({ id }, config) {
  return client({ method: 'delete', url: `/api/tasks/${id}/`, ...config });
}

export function deleteTaskPermanently({ id }, config) {
  const data = { permanently: true };
  return client({ method: 'delete', url: `/api/tasks/${id}/`, data, ...config });
}

export function checkinTask({ id, data }, config) {
  return client({ method: 'patch', url: `/api/tasks/${id}/checkin/`, data, ...config });
}

export function checkoutTask({ id }, config) {
  return client({ method: 'get', url: `/api/tasks/${id}/checkout/`, ...config });
}

export function cloneTask({ id }, config) {
  return client({ method: 'post', url: `/api/tasks/${id}/clone/`, ...config });
}

export function exportTask({ id }, config) {
  return client({ method: 'get', url: `/api/tasks/${id}/export/`, ...config });
}

export function generateTaskTemplate({ id }, config) {
  return client({ method: 'post', url: `/api/tasks/${id}/generate-template/`, ...config });
}

export function instantiateTask({ id }, config) {
  return client({ method: 'post', url: `/api/tasks/${id}/instantiate/`, ...config });
}

export function launchTask({ id, data }, config) {
  return client({ method: 'post', url: `/api/tasks/${id}/launch/`, data, ...config });
}

export function restoreTask({ id }, config) {
  return client({ method: 'patch', url: `/api/tasks/${id}/restore/`, ...config });
}
