import { Button, Image } from '@acheloisbiosoftware/absui.core';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { ErrorImage } from 'assets/Images';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Error(props) {
  const {
    children,
    navigatePath = -1,
    navigateText = 'Go Back',
  } = props;
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', width: 1, height: 0.66 }}>
      <Box sx={{ m: 'auto', width: 400 }}>
        <Alert severity='error'>
          <AlertTitle>Oops! We've got a problem!</AlertTitle>
          The lab mice must be nibbling at the cables again...
        </Alert>
        <Image
          src={ErrorImage}
          width='100%'
          sx={{ my: 1, borderRadius: 1 }}
        />
        <Alert
          severity='error'
          icon={false}
          action={(
            <Button
              onClick={() => navigate(navigatePath)}
              color='inherit'
              size='small'
              variant='text'
            >
              {navigateText}
            </Button>
          )}
        >
          {children}
        </Alert>
      </Box>
    </Box>
  );
}

Error.propTypes = {
  children: PropTypes.node,
  navigatePath: PropTypes.any,
  navigateText: PropTypes.node,
};

export default Error;
