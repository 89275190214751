import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

function ActionBase(props) {
  const { label, icon, children, containerProps, ...buttonProps } = props;
  return (
    <Box
      {...containerProps}
      sx={mergeSx(
        { color: 'text.icon', mt: 1 },
        containerProps?.sx,
      )}
    >
      <Tooltip title={label} placement='left' arrow>
        <Box component='span'>
          <Button
            icon
            size='small'
            color='inherit'
            {...buttonProps}
          >
            { icon }
          </Button>
        </Box>
      </Tooltip>
      { children }
    </Box>
  );
}

ActionBase.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  containerProps: PropTypes.object,
};

export default ActionBase;
