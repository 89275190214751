import {
  AttachmentIcon,
  DepartmentIcon,
  DirectoryIcon,
  ExperimentIcon,
  MasterNoteIcon,
  NoteIcon,
  ProgramIcon,
  ProjectIcon,
  SignatureIcon,
  SopIcon,
  StudyIcon,
  TaskIcon,
  TeamIcon,
  UserIcon,
  UserRoleIcon,
} from '../constants/icon.constants';
import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from '../constants/directory.constants';
import {
  ENTITY_ATTACHMENT,
  ENTITY_CATEGORY,
  ENTITY_COMMENT,
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_DIRECTORY_ITEM,
  ENTITY_EXPERIMENT,
  ENTITY_EXPERIMENT_SECTION,
  ENTITY_EXPERIMENT_SUBSECTION,
  ENTITY_MASTER_NOTE,
  ENTITY_MASTER_NOTE_ITEM,
  ENTITY_MASTER_NOTE_SECTION,
  ENTITY_NOTE,
  ENTITY_PERMISSION,
  ENTITY_PERMISSION_RELATIONSHIP,
  ENTITY_PIN,
  ENTITY_SIGNATURE,
  ENTITY_SIGNATURE_WORKFLOW,
  ENTITY_SIGNATURE_WORKFLOW_STEP,
  ENTITY_SOP,
  ENTITY_SOP_SECTION,
  ENTITY_SOP_SUBSECTION,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';

const ENTITY_ICON_MAP = {
  [ENTITY_ATTACHMENT]: AttachmentIcon,
  [ENTITY_DEPARTMENT]: DepartmentIcon,
  [ENTITY_DIRECTORY]: DirectoryIcon,
  [ENTITY_EXPERIMENT]: ExperimentIcon,
  [ENTITY_MASTER_NOTE]: MasterNoteIcon,
  [ENTITY_NOTE]: NoteIcon,
  [ENTITY_SIGNATURE]: SignatureIcon,
  [ENTITY_SOP]: SopIcon,
  [ENTITY_TASK]: TaskIcon,
  [ENTITY_TEAM]: TeamIcon,
  [ENTITY_USER]: UserIcon,
  [ENTITY_USER_ROLE]: UserRoleIcon,
};

const DIRECTORY_TYPE_ICON_MAP = {
  [DIR_TYPE_STUDY]: StudyIcon,
  [DIR_TYPE_PROJECT]: ProjectIcon,
  [DIR_TYPE_PROGRAM]: ProgramIcon,
};

export const getIcon = (type, { directoryType = null } = {}) => {
  const EntityIcon = ENTITY_ICON_MAP[type];
  if (type === ENTITY_DIRECTORY) {
    return DIRECTORY_TYPE_ICON_MAP[directoryType] ?? EntityIcon;
  }
  return EntityIcon;
};

const ENTITY_DISPLAY_NAME_MAP = {
  [ENTITY_ATTACHMENT]: 'Attachment',
  [ENTITY_CATEGORY]: 'Category',
  [ENTITY_COMMENT]: 'Comment',
  [ENTITY_DEPARTMENT]: 'Department',
  [ENTITY_DIRECTORY]: 'acta',
  [ENTITY_DIRECTORY_ITEM]: 'Item',
  [ENTITY_EXPERIMENT]: 'Experiment',
  [ENTITY_EXPERIMENT_SECTION]: 'Section',
  [ENTITY_EXPERIMENT_SUBSECTION]: 'Subsection',
  [ENTITY_MASTER_NOTE]: 'acta',
  [ENTITY_MASTER_NOTE_ITEM]: 'acta Item',
  [ENTITY_MASTER_NOTE_SECTION]: 'Section',
  [ENTITY_NOTE]: 'Note',
  [ENTITY_PERMISSION]: 'Permission',
  [ENTITY_PERMISSION_RELATIONSHIP]: 'Permission',
  [ENTITY_PIN]: 'Favorite',
  [ENTITY_SIGNATURE]: 'Signature',
  [ENTITY_SIGNATURE_WORKFLOW]: 'Signature Workflow',
  [ENTITY_SIGNATURE_WORKFLOW_STEP]: 'Signature Workflow Step',
  [ENTITY_SOP]: 'SOP',
  [ENTITY_SOP_SECTION]: 'Section',
  [ENTITY_SOP_SUBSECTION]: 'Subsection',
  [ENTITY_TASK]: 'Task',
  [ENTITY_TEAM]: 'Team',
  [ENTITY_USER]: 'User',
  [ENTITY_USER_ROLE]: 'User Role',
};

const ENTITY_DISPLAY_NAME_PLURAL_MAP = {
  [ENTITY_ATTACHMENT]: 'Attachments',
  [ENTITY_CATEGORY]: 'Categories',
  [ENTITY_COMMENT]: 'Comments',
  [ENTITY_DEPARTMENT]: 'Departments',
  [ENTITY_DIRECTORY]: 'acta',
  [ENTITY_DIRECTORY_ITEM]: 'Items',
  [ENTITY_EXPERIMENT]: 'Experiments',
  [ENTITY_EXPERIMENT_SECTION]: 'Sections',
  [ENTITY_EXPERIMENT_SUBSECTION]: 'Subsections',
  [ENTITY_MASTER_NOTE]: 'acta',
  [ENTITY_MASTER_NOTE_ITEM]: 'acta Items',
  [ENTITY_MASTER_NOTE_SECTION]: 'Sections',
  [ENTITY_NOTE]: 'Notes',
  [ENTITY_PERMISSION]: 'Permissions',
  [ENTITY_PERMISSION_RELATIONSHIP]: 'Permissions',
  [ENTITY_PIN]: 'Favorites',
  [ENTITY_SIGNATURE]: 'Signatures',
  [ENTITY_SIGNATURE_WORKFLOW]: 'Signature Workflows',
  [ENTITY_SIGNATURE_WORKFLOW_STEP]: 'Signature Workflow Steps',
  [ENTITY_SOP]: 'SOPs',
  [ENTITY_SOP_SECTION]: 'Sections',
  [ENTITY_SOP_SUBSECTION]: 'Subsections',
  [ENTITY_TASK]: 'Tasks',
  [ENTITY_TEAM]: 'Teams',
  [ENTITY_USER]: 'Users',
  [ENTITY_USER_ROLE]: 'User Roles',
};

const DIRECTORY_TYPE_DISPLAY_NAME_MAP = {
  [DIR_TYPE_STUDY]: 'Study',
  [DIR_TYPE_PROJECT]: 'Project',
  [DIR_TYPE_PROGRAM]: 'Program',
};

const DIRECTORY_TYPE_DISPLAY_NAME_PLURAL_MAP = {
  [DIR_TYPE_STUDY]: 'Studies',
  [DIR_TYPE_PROJECT]: 'Projects',
  [DIR_TYPE_PROGRAM]: 'Programs',
};

export const formatType = (type, {
  plural = false,
  lowercase = false,
  directoryType = null,
  template = false,
  includeTemplateType = true,
  defaultValue = 'Item',
} = {}) => {
  const pluralEntity = plural && !template; // template plurals will be handled independently
  const isDirectory = type === ENTITY_DIRECTORY || type === ENTITY_MASTER_NOTE;
  const directorySpecific = (
    isDirectory &&
    directoryType &&
    Object.keys(pluralEntity ? DIRECTORY_TYPE_DISPLAY_NAME_PLURAL_MAP : DIRECTORY_TYPE_DISPLAY_NAME_MAP).includes(directoryType)
  );
  let entityDisplay = pluralEntity ? (ENTITY_DISPLAY_NAME_PLURAL_MAP[type] ?? defaultValue) : (ENTITY_DISPLAY_NAME_MAP[type] ?? defaultValue);
  if (directorySpecific) {
    entityDisplay = pluralEntity ? DIRECTORY_TYPE_DISPLAY_NAME_PLURAL_MAP[directoryType] : DIRECTORY_TYPE_DISPLAY_NAME_MAP[directoryType];
  }
  if (lowercase && type !== ENTITY_SOP) {
    entityDisplay = entityDisplay.toLocaleLowerCase();
  }
  if (!template) return entityDisplay;

  let templateDisplay = isDirectory ? `Script${plural ? 's' : ''}` : `Template${plural ? 's' : ''}`;
  if (lowercase) {
    templateDisplay = templateDisplay.toLocaleLowerCase();
  }

  if (isDirectory && !directorySpecific) return templateDisplay;
  return includeTemplateType ? `${entityDisplay} ${templateDisplay}` : templateDisplay;
};

export const getPath = ({ id, type } = {}) => {
  if (type === ENTITY_ATTACHMENT) return `/attachment/${id}`;
  if (type === ENTITY_DEPARTMENT) return `/settings/department/${id}`;
  if (type === ENTITY_DIRECTORY) return `/workspace/${id}`;
  if (type === ENTITY_EXPERIMENT) return `/experiment/${id}`;
  if (type === ENTITY_NOTE) return `/note/${id}`;
  if (type === ENTITY_SOP) return `/sop/${id}`;
  if (type === ENTITY_TASK) return `/task/${id}`;
  if (type === ENTITY_TEAM) return `/settings/team/${id}`;
  if (type === ENTITY_USER) return `/settings/user/${id}`;
  if (type === ENTITY_USER_ROLE) return `/settings/user-role/${id}`;
  return null;
};

export const getLink = ({ id, type } = {}) => `${window.location.origin}${getPath({ id, type })}`;
