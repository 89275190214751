import { MODE_DARK, MODE_LIGHT, MODE_SYSTEM, generateTheme } from './theme';
import React, { useMemo } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useAppearance } from 'hooks/store.hooks';
import useSystemModeDetector from 'hooks/useSystemDarkMode';

function ThemeProvider(props) {
  const { children } = props;
  const appearance = useAppearance();
  const isSystemDarkMode = useSystemModeDetector();
  const _appearance = appearance === MODE_SYSTEM ? (isSystemDarkMode ? MODE_DARK : MODE_LIGHT) : appearance;
  const theme = useMemo(() => generateTheme(_appearance), [_appearance]);

  return (
    <MuiThemeProvider theme={theme}>
      {children}
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
