import { DepartmentIcon, ProfileIcon, TeamIcon, UserManagementIcon, UserRoleIcon } from 'constants/icon.constants';
import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER, ENTITY_USER_ROLE } from 'constants/schemas';
import NavList, { NavListItem } from 'components/NavList';
import { Outlet, useLocation } from 'react-router-dom';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import PageContainer from 'components/PageContainer';
import Paper from '@mui/material/Paper';
import React from 'react';
import { formatType } from 'utils/entity.utils';
import { useMyGlobalPermission } from 'hooks/store.hooks';

function Settings() {
  const { pathname } = useLocation();

  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const canViewUserRoles = useMyGlobalPermission(ENTITY_USER_ROLE, ACTION_VIEW);
  const canViewTeams = useMyGlobalPermission(ENTITY_TEAM, ACTION_VIEW);
  const canViewDepartments = useMyGlobalPermission(ENTITY_DEPARTMENT, ACTION_VIEW);
  const showUserManagement = canViewUsers || canViewUserRoles || canViewTeams || canViewDepartments;

  return (
    <PageContainer sx={{ display: 'flex' }}>
      <Box
        sx={{
          height: (theme) => `calc(100% - ${theme.spacing(2)})`,
          pt: 2,
          minWidth: 200,
          borderRight: 1,
          borderColor: 'divider',
        }}
      >
        <Box sx={{ height: 1, overflow: 'auto' }}>
          <NavList
            title='Account Settings'
            collapsable={false}
            component={Paper}
            square
            elevation={2}
            sx={{ px: 2, boxShadow: 0 }}
          >
            <NavListItem
              text='Profile'
              icon={<ProfileIcon />}
              path='/settings/profile'
              selected={pathname === '/settings/profile'}
            />
          </NavList>
          { showUserManagement ? (
            <NavList
              title={`${formatType(ENTITY_USER)} Management`}
              collapsable={false}
              component={Paper}
              square
              elevation={2}
              sx={{ px: 2, boxShadow: 0 }}
            >
              { canViewUsers ? (
                <NavListItem
                  text={formatType(ENTITY_USER, { plural: true })}
                  icon={<UserManagementIcon />}
                  path='/settings/users'
                  selected={pathname === '/settings/users' || pathname.startsWith('/settings/user/')}
                />
              ) : null}
              { canViewUserRoles ? (
                <NavListItem
                  text={formatType(ENTITY_USER_ROLE, { plural: true })}
                  icon={<UserRoleIcon />}
                  path='/settings/user-roles'
                  selected={pathname === '/settings/user-roles' || pathname.startsWith('/settings/user-role/')}
                />
              ) : null }
              { canViewTeams ? (
                <NavListItem
                  text={formatType(ENTITY_TEAM, { plural: true })}
                  icon={<TeamIcon />}
                  path='/settings/teams'
                  selected={pathname === '/settings/teams' || pathname.startsWith('/settings/team/')}
                />
              ) : null}
              { canViewDepartments ? (
                <NavListItem
                  text={formatType(ENTITY_DEPARTMENT, { plural: true })}
                  icon={<DepartmentIcon />}
                  path='/settings/departments'
                  selected={pathname === '/settings/departments' || pathname.startsWith('/settings/department/')}
                />
              ) : null}
            </NavList>
          ) : null}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Outlet />
      </Box>
    </PageContainer>
  );
}

export default Settings;
