import Experiment from './Experiment';
import React from 'react';
import { useParams } from 'react-router-dom';

function ExperimentPage() {
  const { id } = useParams();
  return (<Experiment id={parseFloat(id)} />);
}

export default ExperimentPage;
