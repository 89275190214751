import client from 'services';
import { isNil } from 'lodash';

export function fetchAttachments(params, config) {
  return client({ method: 'get', url: `/api/attachments/`, params, ...config });
}

export function createAttachment({ name, file }, config) {
  const formData = new FormData();
  if (!isNil(name)) formData.append('name', name);
  formData.append('file', file);
  return client({
    method: 'post',
    url: '/api/attachments/',
    data: formData,
    ...config,
  });
}

export function fetchAttachment({ uuid }, config) {
  return client({ method: 'get', url: `/api/attachments/${uuid}/`, ...config });
}

export function patchAttachment({ uuid, name, file }, config) {
  const formData = new FormData();
  if (!isNil(name)) formData.append('name', name);
  formData.append('file', file);
  return client({
    method: 'patch',
    url: `/api/attachments/${uuid}/`,
    data: formData,
    ...config,
  });
}

export function checkinAttachment({ uuid, data }, config) {
  return client({ method: 'patch', url: `/api/attachments/${uuid}/checkin/`, data, ...config });
}

export function checkoutAttachment({ uuid }, config) {
  return client({ method: 'get', url: `/api/attachments/${uuid}/checkout/`, ...config });
}
