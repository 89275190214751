import { logoutAction } from 'store/auth/auth.extraActions';

export const reduceLogout = (builder, reducer) => {
  builder.addCase(logoutAction, reducer);
};

export const reduceLoading = (state) => {
  state.error = null;
};

export const reduceError = (state, action) => {
  state.error = action.error;
};
