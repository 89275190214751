import { DeleteIcon, LaterIcon } from 'constants/icon.constants';
import React, { useMemo } from 'react';
import { formatType, getIcon, getPath } from 'utils/entity.utils';
import { useDirectory, useDirectoryHasLoaded, useDirectoryItem, useFetchListing } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import ContentButton from 'components/ContentButton';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { directoryActions } from 'store/entity';
import { getGenericId } from 'utils/generic.utils';

function Locations(props) {
  const { directoryItem, containerProps, contentButtonProps, iconProps } = props;

  const isDeleted = useDirectoryItem(directoryItem, 'is_deleted');
  const inSandbox = useDirectoryItem(directoryItem, 'in_sandbox');
  const locationIds = useDirectoryItem(directoryItem, 'locations');
  const locationsHasLoaded = useDirectoryHasLoaded(locationIds ?? []);
  const allLocationsHaveLoaded = locationsHasLoaded.every((loaded) => loaded);
  const locationsData = useDirectory(locationIds ?? [], {
    name: 'name',
    type: 'type',
    isTemplate: 'is_template',
    isDeleted: 'is_deleted',
    canView: ['permissions', ACTION_VIEW],
  });
  const locationFetchOptions = useMemo(() => ({
    condition: !allLocationsHaveLoaded && (locationIds ?? []).length > 0,
    params: { filters: { ids: locationIds }},
  }), [allLocationsHaveLoaded, locationIds]);
  const directoriesLoading = useFetchListing(directoryActions.fetchDirectories, locationFetchOptions);

  if (!allLocationsHaveLoaded && directoriesLoading) {
    return <Skeleton variant='rounded' height={40} />;
  }

  if ((locationIds ?? []).length === 0 && !isDeleted && !inSandbox) {
    return (<Typography color='text.disabled'>Unplaced</Typography>);
  }

  return (
    <Stack {...containerProps}>
      {isDeleted ? (
        <Stack>
          <ContentButton
            icon={<DeleteIcon />}
            content='Trash'
            {...contentButtonProps}
            listItemButtonProps={{
              component: Link,
              to: '/trash',
              ...contentButtonProps?.listItemButtonProps,
            }}
          />
        </Stack>
      ) : null}

      {inSandbox ? (
        <Stack>
          <ContentButton
            icon={<LaterIcon />}
            content='Later'
            {...contentButtonProps}
            listItemButtonProps={{
              component: Link,
              to: '/later',
              ...contentButtonProps?.listItemButtonProps,
            }}
          />
        </Stack>
      ) : null}

      {locationIds.map((locId, idx) => {
        const loc = locationsData?.[idx] ?? {};
        const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: loc.type });
        return (
          <Tooltip
            key={`location_${locId}`}
            title={
              loc.canView ? (
                loc.isDeleted ? `This ${formatType(ENTITY_DIRECTORY, { lowercase: true, directoryType: loc.type, template: loc.isTemplate })} is in the trash` : null
              ) : `You do not have permission to view this ${formatType(ENTITY_DIRECTORY, { lowercase: true, directoryType: loc.type, template: loc.isTemplate })}`
            }
            arrow
          >
            <Stack sx={!loc.canView ? { width: 'fit-content' } : null}>
              <ContentButton
                icon={<Icon colored {...iconProps} template={loc.isTemplate} />}
                content={loc.canView ? (
                  <Typography sx={loc.isDeleted ? { textDecoration: 'line-through' } : {}}>{loc.name}</Typography>
                ) : (
                  <Typography color='text.disabled'>[Content Hidden]</Typography>
                )}
                disabled={!loc.canView}
                {...contentButtonProps}
                listItemButtonProps={{
                  component: Link,
                  to: getPath(getGenericId(locId, ENTITY_DIRECTORY)),
                  ...contentButtonProps?.listItemButtonProps,
                }}
                listItemTextProps={{
                  ...contentButtonProps?.listItemTextProps,
                  primaryTypographyProps: {
                    noWrap: false,
                    ...contentButtonProps?.listItemTextProps?.primaryTypographyProps,
                  },
                }}
              />
            </Stack>
          </Tooltip>
        );
      })}
    </Stack>
  );
}

Locations.propTypes = {
  directoryItem: PropTypes.number,
  containerProps: PropTypes.object,
  contentButtonProps: PropTypes.object,
  iconProps: PropTypes.object,
};

export default Locations;
