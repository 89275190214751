import { ENTITY_COMMENT, ENTITY_SIGNATURE, ENTITY_SIGNATURE_WORKFLOW, ENTITY_SIGNATURE_WORKFLOW_STEP } from 'constants/schemas';
import {
  createEntityListSelector,
  createEntityPermissionSelector,
  createEntitySelector,
} from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { commentSelectors } from '../comment';
import { createSelector } from '@reduxjs/toolkit';
import { getGenericId } from 'utils/generic.utils';
import { momentComparator } from 'utils/date.utils';

// #############################################################################
// ################################ Signatures #################################
// #############################################################################
const selectSignatures = (state) => state[ENTITY][ENTITY_SIGNATURE];
export const selectSignature = createEntitySelector(selectSignatures);
export const selectSignatureList = createEntityListSelector(selectSignatures);
export const selectSignaturePermission = createEntityPermissionSelector(selectSignature);


// #############################################################################
// ######################### Signature Workflow Steps ##########################
// #############################################################################
const selectSignatureWorkflowSteps = (state) => state[ENTITY][ENTITY_SIGNATURE_WORKFLOW_STEP];
export const selectSignatureWorkflowStep = createEntitySelector(selectSignatureWorkflowSteps);
export const selectSignatureWorkflowStepPermission = createEntityPermissionSelector(selectSignatureWorkflowStep);


// #############################################################################
// ############################ Signature Workflows ############################
// #############################################################################
const selectSignatureWorkflows = (state) => state[ENTITY][ENTITY_SIGNATURE_WORKFLOW];
export const selectSignatureWorkflow = createEntitySelector(selectSignatureWorkflows);
export const selectSignatureWorkflowPermission = createEntityPermissionSelector(selectSignatureWorkflow);


// #############################################################################
// ################################### Other ###################################
// #############################################################################
export const selectSignatureWorkflowStepActivity = createSelector(
  (state, id) => commentSelectors.selectComment(state, selectSignatureWorkflowStep(state, id, 'comments') ?? []),
  (state, id) => selectSignature(state, selectSignatureWorkflowStep(state, id, 'signatures') ?? []),
  (comments, signatures) => {
    const activeSignatures = signatures.filter((signature) => signature.is_signed || signature.is_rejected);
    const activity = [
      ...comments.map((comment) => ({ ...comment, type: ENTITY_COMMENT })),
      ...activeSignatures.map((signature) => ({ ...signature, type: ENTITY_SIGNATURE })),
    ];
    activity.sort((a1, a2) => -momentComparator(
      a1.type === ENTITY_SIGNATURE ? a1.signed_at ?? a1.rejected_at : a1.created,
      a2.type === ENTITY_SIGNATURE ? a2.signed_at ?? a2.rejected_at : a2.created,
    ));
    return activity.map((item) => (getGenericId(item.id, item.type)));
  },
);
