import React, { useState } from 'react';

import { ACTION_CREATE } from 'constants/permission.constants';
import { AddIcon } from 'constants/icon.constants';
import { DataTableAction } from 'components/DataTable';
import { ENTITY_TEAM } from 'constants/schemas';
import NewTeamDialog from './NewTeamDialog';
import { formatType } from 'utils/entity.utils';
import { useMyGlobalPermission } from 'hooks/store.hooks';

function ActionAddTeam() {
  const [newTeamDialogOpen, setNewTeamDialogOpen] = useState(false);
  const canCreateTeam = useMyGlobalPermission(ENTITY_TEAM, ACTION_CREATE);
  return !canCreateTeam ? null : (
    <DataTableAction
      label={`Add ${formatType(ENTITY_TEAM)}`}
      icon={<AddIcon />}
      onClick={() => setNewTeamDialogOpen(true)}
    >
      <NewTeamDialog
        open={newTeamDialogOpen}
        onClose={() => setNewTeamDialogOpen(false)}
      />
    </DataTableAction>
  );
}


export default ActionAddTeam;
