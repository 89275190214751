import React from 'react';
import Sop from './Sop';
import { useParams } from 'react-router-dom';

function SopPage() {
  const { id } = useParams();
  return (<Sop id={parseFloat(id)} />);
}

export default SopPage;
