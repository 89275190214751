import React, { useCallback, useMemo } from 'react';
import { Link as RouterLink, useHref } from 'react-router-dom';

import MuiLink from '@mui/material/Link';
import PropTypes from 'prop-types';
import { useElectronContextMenuHandler } from 'hooks/electron.hooks';

const Link = React.forwardRef((props, ref) => {
  const { to, onContextMenu, ...restProps } = props;
  const href = useHref(to);

  const contextMenuOptions = useMemo(() => ({ href }), [href]);
  const handleContextMenu = useElectronContextMenuHandler(contextMenuOptions);

  const _onContextMenu = useCallback((...args) => {
    onContextMenu?.(...args);
    handleContextMenu(...args);
  }, [onContextMenu, handleContextMenu]);

  return (
    <MuiLink
      ref={ref}
      underline='hover'
      component={RouterLink}
      {...restProps}
      to={to}
      onContextMenu={_onContextMenu}
    />
  );
});

Link.displayName = 'Link';
Link.propTypes = {
  to: PropTypes.any,
  onContextMenu: PropTypes.func,
};

export default Link;
