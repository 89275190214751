import client from 'services';

// #############################################################################
// ################################ Directories ################################
// #############################################################################
export function fetchDirectories(params, config) {
  return client({ method: 'get', url: '/api/directories/', params, ...config });
}

export function createDirectory(data, config) {
  return client({ method: 'post', url: '/api/directories/', data, ...config });
}

export function fetchDirectory({ id }, config) {
  return client({ method: 'get', url: `/api/directories/${id}/`, ...config });
}

export function patchDirectory({ id, data }, config) {
  return client({ method: 'patch', url: `/api/directories/${id}/`, data, ...config });
}

export function deleteDirectory({ id }, config) {
  return client({ method: 'delete', url: `/api/directories/${id}/`, ...config });
}

export function deleteDirectoryPermanently({ id }, config) {
  const data = { permanently: true };
  return client({ method: 'delete', url: `/api/directories/${id}/`, data, ...config });
}

export function checkinDirectory({ id, data }, config) {
  return client({ method: 'patch', url: `/api/directories/${id}/checkin/`, data, ...config });
}

export function checkoutDirectory({ id }, config) {
  return client({ method: 'get', url: `/api/directories/${id}/checkout/`, ...config });
}

export function cloneDirectory({ id }, config) {
  return client({ method: 'post', url: `/api/directories/${id}/clone/`, ...config });
}

export function exportDirectory({ id }, config) {
  return client({ method: 'get', url: `/api/directories/${id}/export/`, ...config });
}

export function generateDirectoryTemplate({ id }, config) {
  return client({ method: 'post', url: `/api/directories/${id}/generate-template/`, ...config });
}

export function instantiateDirectory({ id }, config) {
  return client({ method: 'post', url: `/api/directories/${id}/instantiate/`, ...config });
}

export function restoreDirectory({ id }, config) {
  return client({ method: 'patch', url: `/api/directories/${id}/restore/`, ...config });
}

// #############################################################################
// ############################## DirectoryItems ###############################
// #############################################################################
export function fetchDirectoryItems(params, config) {
  return client({ method: 'get', url: '/api/directory-items/', params, ...config });
}

// #############################################################################
// ################################ MasterNotes ################################
// #############################################################################
export function fetchMasterNote({ id }, config) {
  return client({ method: 'get', url: `/api/master-notes/${id}/`, ...config });
}

export function patchMasterNote({ id, data }, config) {
  return client({ method: 'patch', url: `/api/master-notes/${id}/`, data, ...config });
}

export function createMasterNoteItem({ id, data }, config) {
  return client({ method: 'patch', url: `/api/master-notes/${id}/create-item/`, data, ...config });
}

export function createMasterNoteSection({ id, data }, config) {
  return client({ method: 'patch', url: `/api/master-notes/${id}/create-section/`, data, ...config });
}

export function deleteMasterNoteItem({ id, data }, config) {
  return client({ method: 'patch', url: `/api/master-notes/${id}/delete-item/`, data, ...config });
}

export function deleteMasterNoteSection({ id, data }, config) {
  return client({ method: 'patch', url: `/api/master-notes/${id}/delete-section/`, data, ...config });
}
