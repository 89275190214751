import { Icon } from '@iconify/react';
import React from 'react';

export const INACTIVE = 'Inactive';
export const DRAFT = 'Draft';
export const SIGNING = 'Signing';
export const PUBLISHED = 'Published';

export const ICON_MATERIAL = 'material_icon';
export const ICON_PROCEDURE = 'procedure_overview';
export const ICON_DATA = 'data';
export const ICON_QC = 'quality_control';

export const VIEW_MATERIAL = 'MaterialNav';
export const VIEW_PROCEDURE = 'ProcedureOverviewNav';
export const VIEW_DATA = 'DataNav';
export const VIEW_QC = 'QCNav';
export const VIEW_NEW_SECTION = 'NewSectionNav';

export const SECTION_ICONS = {
  parameters: (<Icon icon='eva:options-2-outline' />),
  [ICON_MATERIAL]: (<Icon icon='entypo:lab-flask' />),
  [ICON_PROCEDURE]: (<Icon icon='clarity:blocks-group-solid' />),
  [ICON_DATA]: (<Icon icon='academicons:open-data' />),
  [ICON_QC]: (<Icon icon='wpf:inspection' />),
};
