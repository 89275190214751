import React, { useMemo } from 'react';
import { getComparator, getFilterValue } from '../DataTable.utils';

import Box from '@mui/material/Box';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { TextField } from '@acheloisbiosoftware/absui.core';
import Typography from '@mui/material/Typography';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useRows } from '../DataTableContext';

function FieldValueSelect(props) {
  const { filter, setValue } = props;
  const { column, value } = filter;
  const { filterOptionProps } = column;
  const rows = useRows();
  const options = useMemo(() => {
    const sortedRows = [...rows].sort(getComparator(column, 'asc'));
    const values = sortedRows.map((row) => getFilterValue(row, column));
    return values.filter((v, i, arr) => arr.indexOf(v) === i);
  }, [rows, column]);
  return (
    <TextField
      size='small'
      variant='standard'
      label='Value'
      select
      value={options.includes(value) ? value ?? '' : ''}
      SelectProps={{
        renderValue: (v) => {
          const menuItemProps = typeof(filterOptionProps) === 'function' ? filterOptionProps(v) : filterOptionProps;
          if (isDefined(menuItemProps?.children)) {
            return (
              <Box sx={{ height: 23, px: 1 }}>
                {menuItemProps.children}
              </Box>
            );
          }
          if (isDefined(menuItemProps?.icon) || isDefined(menuItemProps?.label)) {
            return (
              <Stack direction='row' alignItems='center' sx={{ height: 23, px: 1 }}>
                {menuItemProps.icon ?? null}
                <Typography sx={{ ml: 1 }}>
                  {menuItemProps.label ?? ''}
                </Typography>
              </Stack>
            );
          }
          return v ?? '';
        },
      }}
      onChange={(_, newValue) => setValue(newValue)}
      sx={{ minWidth: 150, flexGrow: 1 }}
    >
      { options.map((option) => {
        const menuItemProps = typeof(filterOptionProps) === 'function' ? filterOptionProps(option) : filterOptionProps;
        return (
          <MenuItem
            key={`option_${option}`}
            label={!isDefined(menuItemProps?.children) ? option : null}
            {...menuItemProps}
            value={option}
          />
        );
      })}
    </TextField>
  );
}

FieldValueSelect.propTypes = {
  filter: PropTypes.shape({
    column: PropTypes.shape({
      field: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      valueGetter: PropTypes.func,
      comparator: PropTypes.func,
      filterOptionProps: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
      ]),
    }),
    value: PropTypes.any,
  }),
  setValue: PropTypes.func.isRequired,
};

export default FieldValueSelect;
