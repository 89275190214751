import {
  ENTITY_ATTACHMENT,
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_SOP,
  ENTITY_TASK,
} from 'constants/schemas';
import {
  GenericProp,
  Prop,
  attachmentActions,
  directoryActions,
  entitySelectors,
  experimentActions,
  sopActions,
  taskActions,
} from 'store/entity';
import React, { useCallback, useMemo, useState } from 'react';
import { getGenericId, useGenericId } from 'utils/generic.utils';
import {
  useAttachmentList,
  useDirectoryItemList,
  useEntity,
  useEntityIcon,
  useFetchListing,
  useMyId,
} from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import { CheckinIcon } from 'constants/icon.constants';
import Collapse from '@mui/material/Collapse';
import ContentButton from 'components/ContentButton';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { getPath } from 'utils/entity.utils';
import { useDispatch } from 'react-redux';
import { useStageDelayedUpdates } from 'components/DelayedUpdateProvider';

const checkinActionMap = {
  [ENTITY_ATTACHMENT]: attachmentActions.checkinAttachment,
  [ENTITY_DIRECTORY]: directoryActions.checkinDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.checkinExperiment,
  [ENTITY_SOP]: sopActions.checkinSop,
  [ENTITY_TASK]: taskActions.checkinTask,
};

const titleProp = new GenericProp({
  [ENTITY_ATTACHMENT]: 'name',
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
});

function EntityButton(props) {
  const { id, type } = props;
  const genericId = useGenericId(id, type);
  const title = useEntity(genericId, titleProp);
  const isTemplate = useEntity(genericId, 'is_template');
  const path = getPath(genericId);
  const Icon = useEntityIcon(genericId);
  return (
    <ContentButton
      icon={Icon ? <Icon colored template={isTemplate} fontSize='small' /> : null}
      content={title}
      listItemButtonProps={{
        component: Link,
        to: path,
        sx: { py: 0, px: 1, minHeight: 0 },
      }}
      listItemTextProps={{ primaryTypographyProps: { variant: 'subtitle2' }}}
    />
  );
}

EntityButton.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.string,
};

function CheckinButton() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [entitiesFailedCheckin, setEntitiesFailedCheckin] = useState([]);
  const error = entitiesFailedCheckin.length > 0;

  const myId = useMyId();
  const directoryItemListParams = useMemo(() => ({
    filter: (directoryItem, state) => {
      const genericId = getGenericId(directoryItem.object_id, directoryItem.content_type);
      return (
        entitySelectors.selectEntity(state, genericId, 'is_checked_out') &&
        entitySelectors.selectEntity(state, genericId, 'checked_out_by') === myId
      );
    },
    prop: new Prop({ id: 'object_id', type: 'content_type' }),
  }), [myId]);
  const checkedOutGenericIds = useDirectoryItemList(directoryItemListParams);

  const attachmentListParams = useMemo(() => ({
    filter: (attachment) => attachment.is_checked_out && attachment.checked_out_by === myId,
    prop: 'uuid',
  }), [myId]);
  const checkedOutAttachmentIds = useAttachmentList(attachmentListParams);

  const checkedOutItems = useMemo(() => [
    ...checkedOutGenericIds,
    ...(checkedOutAttachmentIds.map((attachmentId) => getGenericId(attachmentId, ENTITY_ATTACHMENT))),
  ], [checkedOutGenericIds, checkedOutAttachmentIds]);

  const fetchOptions = useMemo(() => ({
    params: { filters: { is_checked_out: true, checked_out_by: myId }},
  }), [myId]);
  useFetchListing(directoryActions.fetchDirectoryItems, fetchOptions);
  useFetchListing(attachmentActions.fetchAttachments, fetchOptions);

  const stageDelayedUpdates = useStageDelayedUpdates();
  const onCheckin = useCallback(async () => {
    if (checkedOutItems.length === 0) return;
    stageDelayedUpdates();
    setLoading(true);
    setEntitiesFailedCheckin([]);
    const failedEntities = [];

    for (let i = 0; i < checkedOutItems.length; i++) {
      const genericId = checkedOutItems[i];
      const action = checkinActionMap[genericId.type];
      try {
        const data = genericId.type === ENTITY_ATTACHMENT ? { uuid: genericId.id } : { id: genericId.id };
        const result = await dispatch(action(data)).unwrap();
        if (result.error && result.error.name !== 'ConditionError') {
          failedEntities.push(genericId);
        }
      } catch (err) {
        if (err.name !== 'ConditionError') {
          failedEntities.push(genericId);
        }
      }
    }

    setEntitiesFailedCheckin(failedEntities);
    setLoading(false);
  }, [dispatch, checkedOutItems, stageDelayedUpdates]);

  return (
    <Box> {/** Needed for Collapse to be positioned correctly in parent Stack */}
      <Collapse in={checkedOutItems.length > 0} orientation='horizontal'>
        <Tooltip
          title={(
            <>
              <Typography variant='body2'>{!error ? 'Check in all documents:' : 'Failed to check in the following documents:'}</Typography>
              {(!error ? checkedOutItems : entitiesFailedCheckin).map(({ id, type }) => (
                <EntityButton
                  key={`checked-out-${type}-${id}`}
                  id={id}
                  type={type}
                />
              ))}
            </>
          )}
          arrow
          placement='bottom'
        >
          <Box sx={{ color: 'text.icon' }}>
            <Button
              icon
              size='small'
              color={error ? 'error' : 'inherit'}
              onClick={onCheckin}
              loading={loading}
            >
              <CheckinIcon fontSize='small' />
            </Button>
          </Box>
        </Tooltip>
      </Collapse>
    </Box>
  );
}

export default CheckinButton;
