import { AUTH, getLocalStoreSliceId } from 'store/store.constants';
import {
  useAuthAccessToken,
  useAuthToken,
  useAuthTokenExpiration,
  useIsAuthenticated,
  useMyId,
} from './store.hooks';
import { useCallback, useEffect, useState } from 'react';

import { ACTA_API_ERROR } from 'constants/event.constants';
import { authActions } from 'store/auth';
import { eventListenerEffect } from '@acheloisbiosoftware/absui.hooks';
import { isNil } from 'lodash';
import { localStore } from 'utils/localStorage.utils';
import { releaseQueue } from 'services';
import { useDispatch } from 'react-redux';

const REFRESH_LEEWAY_SECONDS = 30;
const getRefreshDelay = (exp) => Math.max(0, (exp * 1000) - Date.now() - (REFRESH_LEEWAY_SECONDS * 1000));

const useAuthVerify = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const myId = useMyId();
  const token = useAuthToken();
  const access = useAuthAccessToken();
  const exp = useAuthTokenExpiration();
  const [refreshLoading, setRefreshLoading] = useState(false);

  const refreshToken = useCallback(() => {
    if (!refreshLoading) {
      setRefreshLoading(true);
      dispatch(authActions.refreshToken()).finally(() => setRefreshLoading(false));
    }
  }, [dispatch, refreshLoading]);

  useEffect(() => {
    if (access) dispatch(authActions.verifyToken());
  }, [access, dispatch]);

  useEffect(() => {
    let timeout;
    if (exp) timeout = setTimeout(refreshToken, getRefreshDelay(exp));
    return () => clearTimeout(timeout);
  }, [exp, refreshToken]);

  useEffect(() => eventListenerEffect(document, ACTA_API_ERROR, (event) => {
    const { detail: error } = event;
    if (error.response?.status === 401) refreshToken();
  }), [refreshToken]);

  useEffect(() => {
    if (isAuthenticated && !isNil(token?.access)) {
      releaseQueue(token.access);
      const authLocalStorageId = getLocalStoreSliceId(AUTH);
      const localState = localStore.load(authLocalStorageId) ?? {};
      localState.isAuthenticated = true;
      localState.token = { ...localState.token, ...token };
      localState.myId = myId ?? localState.myId;
      localStore.save(authLocalStorageId, localState);
    }
  }, [isAuthenticated, token, myId]);
};

export default useAuthVerify;
