import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CollapseButton from 'components/CollapseButton';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import { useCollapseAll } from './CollapseAllContext';

function MasterNoteSectionBase(props) {
  const { children, containerProps, sectionHeaderProps } = props;
  const { isAllCollapsed, isAllExpanded, uncontrolCollapse } = useCollapseAll();
  const [open, setOpen] = useState(isAllExpanded && !isAllCollapsed);

  const _setOpen = (...args) => {
    uncontrolCollapse();
    setOpen(...args);
  };

  useEffect(() => {
    if (isAllExpanded) setOpen(true);
  }, [isAllExpanded]);

  useEffect(() => {
    if (isAllCollapsed) setOpen(false);
  }, [isAllCollapsed]);

  return (
    <Box {...containerProps}>
      <SectionHeader
        {...sectionHeaderProps}
        sticky
        variant='h5'
        extraActions={{
          ...sectionHeaderProps.extraActions,
          right: [
            ...(sectionHeaderProps.extraActions?.right ?? []),
            {
              id: 'collapse',
              component: Box,
              props: {
                sx: { display: 'flex', alignItems: 'center' },
                children: (<CollapseButton open={open} setOpen={_setOpen} />),
              },
            },
          ],
        }}
        sx={{ py: 1 }}
      />
      <Collapse in={open} unmountOnExit> {/* Unmounting to avoid DND bugs */}
        { children }
      </Collapse>
    </Box>
  );
}

MasterNoteSectionBase.propTypes = {
  containerProps: PropTypes.object,
  children: PropTypes.node,
  sectionHeaderProps: PropTypes.object,
};

export default MasterNoteSectionBase;
