import { BLOCK_SEARCH, BLOCK_VARIABLE } from './FormulaEditor.constants';
import { Editor, Range, Transforms } from 'slate';

export const getSearchText = (node) => node.children.map((child) => child.text).join('');

export const generateVariable = (variable) => ({
  type: BLOCK_VARIABLE,
  uuid: variable.uuid ?? '',
  label: variable.label,
  units: variable.units ?? '',
  children: [{ text: '' }],
});

export const insertVariable = (editor, variable) => {
  Transforms.insertNodes(editor, generateVariable(variable));
  Transforms.move(editor);
};

export function withFormulas(editor) {
  const {
    isInline,
    isVoid,
    normalizeNode,
    deleteBackward,
    deleteForward,
    insertText,
  } = editor;

  editor.isInline = (element) => element.type === BLOCK_VARIABLE || element.type === BLOCK_SEARCH || isInline(element);
  editor.isVoid = (element) => element.type === BLOCK_VARIABLE || isVoid(element);
  editor.insertBreak = () => null;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (node.type === BLOCK_SEARCH) {
      const text = getSearchText(node);
      if (!text.startsWith('@')) {
        Transforms.unwrapNodes(editor, { at: path, match: (n) => n.type === BLOCK_SEARCH });
        return;
      }
    }

    normalizeNode(entry);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [variableBefore] = Editor.nodes(editor, {
        at: Editor.before(editor, selection),
        match: (n) => n.type === BLOCK_VARIABLE,
      });
      if (variableBefore) {
        Transforms.select(editor, Editor.range(editor, selection, variableBefore[1]));
        return;
      }
    }
    deleteBackward(...args);
  };

  editor.deleteForward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [variableAfter] = Editor.nodes(editor, {
        at: Editor.after(editor, selection),
        match: (n) => n.type === BLOCK_VARIABLE,
      });
      if (variableAfter) {
        Transforms.select(editor, Editor.range(editor, selection, variableAfter[1]));
        return;
      }
    }
    deleteForward(...args);
  };

  editor.insertText = (text) => {
    if (text.startsWith('@')) {
      const [currSearch] = Editor.nodes(editor, { match: (n) => n.type === BLOCK_SEARCH });
      if (!currSearch) {
        Transforms.insertNodes(editor, { type: BLOCK_SEARCH, children: [{ text }]});
      }
      return;
    }
    insertText(text);
  };

  return editor;
}
