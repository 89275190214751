import { ENTITY_EXPERIMENT, ENTITY_EXPERIMENT_SUBSECTION } from 'constants/schemas';
import { VIEW_DATA, VIEW_MATERIAL, VIEW_NEW_SECTION, VIEW_PROCEDURE, VIEW_QC } from 'constants/sop.constants';
import { useEntityId, useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useExpSection, useExpSubsection, useExpSubsectionPermission, useExperiment, useUpdateEntity } from 'hooks/store.hooks';

import { ACTION_COMMENT_CREATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { CommentSection } from 'components/Comment';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from 'components/SectionHeader';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';
import { useGenericId } from 'utils/generic.utils';

function SubsectionDetails(props) {
  const { subsectionId } = props;
  const expId = useEntityId();
  const subsection = useExpSubsection(subsectionId);
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(expId, ENTITY_EXPERIMENT);

  const onChange = (value) => {
    update({ type: ENTITY_EXPERIMENT_SUBSECTION, id: subsectionId, key: 'description', value });
  };

  return (
    <>
      <Field label='Estimated Time (hours)'>{subsection.estimated_time}</Field>
      <Field
        label='Description'
        value={subsection.description}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly
        interactable={!isReadOnly}
        onChangeSerialized={onChange}
        onChange={editorChangeHandler}
        withVariables
      />
    </>
  );
}
SubsectionDetails.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};

function SubsectionStepDefinition(props) {
  const { subsectionId } = props;
  const expId = useEntityId();
  const subsectionDefinition = useExpSubsection(subsectionId, 'definition');
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(expId, ENTITY_EXPERIMENT);

  const onChange = (value) => {
    update({ type: ENTITY_EXPERIMENT_SUBSECTION, id: subsectionId, key: 'definition', value });
  };

  return (
    <Field
      label='Step Definition'
      value={subsectionDefinition}
      variant={Field.VARIANTS.TEXT_EDITOR}
      readOnly
      interactable={!isReadOnly}
      onChangeSerialized={onChange}
      onChange={editorChangeHandler}
      withVariables
    />
  );
}
SubsectionStepDefinition.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};

function MaterialsSubsection(props) {
  const { subsectionId } = props;
  const expId = useEntityId();
  const subsection = useExpSubsection(subsectionId);
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(expId, ENTITY_EXPERIMENT);

  const onChangeFactory = (key) => (value) => {
    update({ type: ENTITY_EXPERIMENT_SUBSECTION, id: subsectionId, key, value });
  };

  return (
    <>
      <Field
        value={subsection.description}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly
        interactable={!isReadOnly}
        onChangeSerialized={onChangeFactory('description')}
        onChange={editorChangeHandler}
        withVariables
      />
      <Field
        label='Additional Details'
        value={subsection.definition}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly
        interactable={!isReadOnly}
        onChangeSerialized={onChangeFactory('definition')}
        onChange={editorChangeHandler}
        withVariables
      />
    </>
  );
}
MaterialsSubsection.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};

function ExperimentSubsection(props) {
  const { subsectionId } = props;
  const expId = useEntityId();
  const subsection = useExpSubsection(subsectionId);
  const sectionSequence = useExpSection(subsection.section, 'sequence');
  const sectionViewId = useExpSection(subsection.section, 'view_id');
  const genericId = useGenericId(subsectionId, ENTITY_EXPERIMENT_SUBSECTION);
  const isTemplate = useExperiment(expId, 'is_template');
  const commentsAllowed = useExpSubsectionPermission(subsectionId, ACTION_COMMENT_CREATE);

  return (
    <Box sx={{ my: 1, px: 3 }}>
      <Box id={`subsection_${subsectionId}`} sx={{ position: 'relative', top: -56 }} /> {/** Accounts for sticky section header */}
      <SectionHeader
        sticky
        title={subsection.title}
        prefix={`${sectionSequence + 1}.${subsection.sequence}.`}
        variant='h5'
        stickyContainerProps={{ sx: {
          pt: 1,
          top: 56, /* Accounts for sticky section header */
          zIndex: 'subheader',
        }}}
      />
      { sectionViewId === VIEW_DATA ? (
        <SubsectionStepDefinition subsectionId={subsectionId} />
      ) : sectionViewId === VIEW_MATERIAL ? (
        <MaterialsSubsection subsectionId={subsectionId} />
      ) : [VIEW_NEW_SECTION, VIEW_PROCEDURE, VIEW_QC].includes(sectionViewId) ? (
        <>
          <SubsectionDetails subsectionId={subsectionId} />
          <SubsectionStepDefinition subsectionId={subsectionId} />
        </>
      ) : null }
      { !isTemplate ? (
        <CommentSection
          relatedObjectId={genericId}
          disableNew={!commentsAllowed}
          fieldProps={{ label: 'Documentation' }}
        />
      ) : null}
    </Box>
  );
}

ExperimentSubsection.propTypes = {
  subsectionId: PropTypes.number.isRequired,
};

export default ExperimentSubsection;

