import React, { useEffect } from 'react';
import { noteActions, noteSelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import DeletedAlert from 'components/DeletedAlert';
import { ENTITY_NOTE } from 'constants/schemas';
import NoteBody from './NoteBody';
import NoteHeader from './NoteHeader';
import PageContainer from 'components/PageContainer';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { getGenericId } from 'utils/generic.utils';
import { navigationActions } from 'store/navigation';
import { useDispatch } from 'react-redux';
import { withEntity } from 'hooks/Entity.hooks';

function Note(props) {
  const { id, pageContainerProps } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(navigationActions.addRecentlyViewed(getGenericId(id, ENTITY_NOTE)));
  }, [dispatch, id]);

  return (
    <PageContainer {...pageContainerProps}>
      <Stack
        sx={{
          height: 1,
          width: 1,
          minWidth: 640,
          position: 'relative',
        }}
      >
        <DeletedAlert id={id} type={ENTITY_NOTE} sx={{ px: 3 }} />
        <NoteHeader id={id} />
        <NoteBody id={id} />
      </Stack>
    </PageContainer>
  );

}

Note.propTypes = {
  id: PropTypes.number.isRequired,
  pageContainerProps: PropTypes.object,
};

export default withEntity(Note, {
  fetchAction: noteActions.fetchNote,
  hasLoadedSelector: noteSelectors.selectNoteHasLoaded,
  readOnlySelector: (state, id) => !noteSelectors.selectNotePermission(state, id, ACTION_UPDATE),
  isInSpaceSelector: noteSelectors.selectNoteInSpace,
});
