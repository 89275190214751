import { ACTIVE, INACTIVE } from 'constants/user.constants';
import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_SELECT, FILTER_TYPE_SELECT } from 'components/DataTable';
import React, { useCallback, useMemo } from 'react';

import { ACTION_VIEW } from 'constants/permission.constants';
import ActionUserAssign from './ActionUserAssign';
import { AdminIcon } from 'constants/icon.constants';
import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { ENTITY_USER } from 'constants/schemas';
import PropTypes from 'prop-types';
import Status from 'components/Status';
import Typography from '@mui/material/Typography';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { selectEntityActions } from 'components/EntityActions';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'hooks/store.hooks';

function UserTable(props) {
  const {
    userIds,
    columnProps,
    onAssign,
    initAssignment,
    ...restProps
  } = props;
  const users = useUser(userIds);
  const navigate = useNavigate();

  const onRowClick = useCallback((row) => {
    if (row?.permissions?.[ACTION_VIEW]) {
      navigate(getPath(getGenericId(row.id, ENTITY_USER)));
    }
  }, [navigate]);

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      hidden: true,
      ...columnProps?.checkbox,
    },
    {
      id: 'full_name',
      label: 'Name',
      field: 'full_name',
      renderValue: ({ row, value }) => (
        <Box sx={{ display: 'flex' }}>
          <Avatar id={row.id} type={ENTITY_USER} sx={{ my: 'auto' }} />
          <Typography
            variant='subtitle2'
            noWrap
            sx={{ my: 'auto', ml: 1 }}
          >
            {value}
          </Typography>
          { row.is_superuser || row.is_staff ? (
            <Chip
              icon={<AdminIcon />}
              label='Admin'
              size='small'
              sx={{ my: 'auto', ml: 1 }}
            />
          ) : null}
        </Box>
      ),
      ...columnProps?.full_name,
    },
    {
      id: 'email',
      label: 'Email',
      field: 'email',
      secondary: true,
      ...columnProps?.email,
    },
    {
      id: 'is_active',
      label: 'Status',
      field: 'is_active',
      valueGetter: ({ value }) => (value ? ACTIVE : INACTIVE),
      renderValue: ({ value }) => (<Status>{value}</Status>),
      filterType: FILTER_TYPE_SELECT,
      filterOptionProps: (value) => ({ children: (<Status>{value}</Status>) }),
      groupable: true,
      ...columnProps?.is_active,
    },
    {
      id: 'actions',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      inMenu: true,
      actions: ({ row, state }) => selectEntityActions(state, row.id, ENTITY_USER),
      ...columnProps?.actions,
    },
  ], [columnProps]);

  return (
    <DataTable
      rows={users}
      columns={columns}
      onRowClick={onRowClick}
      {...restProps}
      actions={onAssign ? (
        <>
          <ActionUserAssign initAssignment={initAssignment} onAssign={onAssign} />
          {restProps?.actions}
        </>
      ) : restProps?.actions}
      initState={{
        orderBy: 'full_name',
        ...restProps?.initState,
      }}
    />
  );
}

UserTable.propTypes = {
  userIds: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ])),
  columnProps: PropTypes.object,
  onAssign: PropTypes.func,
  initAssignment: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ])),
};

export default UserTable;
