import Link from 'components/Link';
import MenuItem from 'components/MenuItem';
import React from 'react';

function MenuItemAction(props) {
  return (<MenuItem {...props} />);
}

export function MenuItemLinkAction(props) {
  return (<MenuItemAction {...props} component={Link} />);
}

export default MenuItemAction;
