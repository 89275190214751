import {
  COL_TYPES,
  COL_TYPE_ACTIONS,
  COL_TYPE_BOOLEAN,
  COL_TYPE_DATE,
  COL_TYPE_SELECT,
  COL_TYPE_TEXT,
  COL_TYPE_USER,
  FILTER_TYPES,
  FILTER_TYPE_BOOLEAN,
  FILTER_TYPE_DATE,
  FILTER_TYPE_SELECT,
  FILTER_TYPE_TEXT,
} from './DataTable.constants';
import { ColumnsContext, ExpandedRowsContext, FilterStateContext, GroupByStateContext, RowsContext, SortStateContext } from './DataTableContext';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  GROUP_DATES_BY_OPTIONS,
  getComparator,
  getFilterObject,
  getGroupValue,
  getValue,
  isValidFilter,
  testFilter,
  testQuickFilter,
} from './DataTable.utils';
import React, { useMemo } from 'react';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { formatDate, momentComparator } from 'utils/date.utils';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import { useFilterable, useGroupable, useSortable } from './DataTable.hooks';

import Box from '@mui/material/Box';
import CellHeader from './CellHeader';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { ConditionalWrapper } from '@acheloisbiosoftware/absui.core';
import { DATE_FMT_SHORT } from 'constants/date.constants';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import Row from './Row';
import RowGrouped from './RowGrouped';
import RowSortable from './RowSortable';
import Table from '@mui/material/Table';
import TableActions from './TableActions';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';
import { useSelector } from 'react-redux';
import { useUserName } from 'hooks/store.hooks';
import { userSelectors } from 'store/entity';

const LOADING_SIZE = 40;

const UserName = (props) => useUserName(props.id); // Simple component to render a user's name
const getColumnUserNameField = (col) => `${col.id}__name`;
const selectUserInjectedRowsCurried = createSelector(
  (rows) => rows,
  (rows, userColumns) => userColumns,
  (rows, userColumns) => {
    if (userColumns.length === 0) return () => rows;
    return createSelector(
      ...rows.map((row) => createSelector(
        ...userColumns.map((col) => (state) => userSelectors.selectUserName(state, getValue(row, col))),
        (...userNames) => Object.fromEntries(userColumns.map((col, idx) => [
          getColumnUserNameField(col), userNames[idx],
        ])),
      )),
      (...rowValues) => rows.map((row, idx) => ({ ...row, ...rowValues[idx] })),
    );
  },
);

function DataTable(props) {
  const {
    rows,
    columns,
    actions,
    onRowClick,
    loading,
    initState = {},
    stateInSearchParams,
    expandedRows,
    enableDnd,
    dndContextProps,
    enableSortable,
    sortableContextProps,
    quickFilter,
    noHeader,
    disableFilter,
    disableGroupBy,
    tableProps,
    sx,
  } = props;

  const _columns = useMemo(() => columns.map((col) => {
    const { type } = col;
    if (type === COL_TYPE_ACTIONS) {
      return {
        ...col,
        sortable: false,
        filterable: false,
        groupable: false,
      };
    } else if (type === COL_TYPE_BOOLEAN) {
      return {
        renderValue: (({ value }) => (<Checkbox checked={value} disabled />)),
        filterType: FILTER_TYPE_BOOLEAN,
        sortable: true,
        filterable: true,
        groupable: false,
        ...col,
      };
    } else if (type === COL_TYPE_DATE) {
      return {
        valueGetter: (({ value }) => (isDefined(value) ? moment(value) : null)),
        quickFilterValueGetter: ({ value }) => formatDate(value, DATE_FMT_SHORT, ''),
        renderValue: ({ value }) => formatDate(value, DATE_FMT_SHORT, ''),
        renderGroupValue: ({ value }) => value,
        comparator: ({ value: m1 }, { value: m2 }) => momentComparator(m1, m2),
        filterType: FILTER_TYPE_DATE,
        sortable: true,
        filterable: true,
        groupable: false,
        ...col,
      };
    } else if (type === COL_TYPE_SELECT) {
      return {
        selection: [],
        disabled: [],
        isPrimarySelection: true,
        ...col,
        sortable: false,
        filterable: false,
        groupable: false,
      };
    } else if (type === COL_TYPE_USER) {
      const userNameField = getColumnUserNameField(col);
      return {
        sortValueGetter: ({ row }) => row[userNameField] ?? '',
        groupValueGetter: ({ value }) => value,
        quickFilterValueGetter: ({ row }) => row[userNameField] ?? '',
        filterValueGetter: ({ value }) => value,
        renderValue: ({ value }) => <UserName id={value} />,
        filterType: FILTER_TYPE_SELECT,
        filterOptionProps: (value) => ({
          label: <UserName id={value} />,
        }),
        sortable: true,
        filterable: true,
        groupable: true,
        noWrap: true,
        ...col,
      };
    }
    return {
      type: COL_TYPE_TEXT,
      filterType: FILTER_TYPE_TEXT,
      sortable: true,
      filterable: true,
      groupable: false,
      ...col,
    };

  }), [columns]);

  const userColumns = useMemo(
    () => _columns.filter((col) => col.type === COL_TYPE_USER),
    [_columns],
  );
  const _rows = useSelector((state) => selectUserInjectedRowsCurried(rows, userColumns)(state));

  const sortState = useSortable(initState, stateInSearchParams, _columns);
  const { order, orderBy } = sortState;

  const filterState = useFilterable(initState, stateInSearchParams);
  const { filters } = filterState;

  const groupByState = useGroupable(initState, stateInSearchParams, _columns);
  const { groupBy, groupDatesBy } = groupByState;

  const sortedFilteredRows = useMemo(() => {
    const filterObjs = filters.map((filter) => getFilterObject(filter, _columns));
    const activeFilters = filterObjs.filter(isValidFilter);
    let filteredRows = _rows.filter((row) => activeFilters.every((filter) => testFilter(filter, row)));
    if (isDefined(quickFilter)) {
      filteredRows = filteredRows.filter((row) => _columns.some((col) => testQuickFilter(quickFilter, row, col)));
    }
    const columnToSortOn = _columns.find((col) => col.id === orderBy);
    if (!isDefined(columnToSortOn)) return filteredRows;
    return filteredRows.sort(getComparator(columnToSortOn, order));
  }, [_rows, _columns, orderBy, order, filters, quickFilter]);
  const rowIds = useMemo(() => sortedFilteredRows.map((row) => row.id), [sortedFilteredRows]);

  const groupByColumn = useMemo(() => _columns.find((col) => col.id === groupBy), [groupBy, _columns]);
  const isGrouping = isDefined(groupByColumn);
  const groupedRows = useMemo(() => {
    const grouped = sortedFilteredRows.reduce((acc, row) => {
      if (!isGrouping) return acc;
      let value = getGroupValue(row, groupByColumn);
      if (groupByColumn.type === COL_TYPE_DATE) {
        value = groupDatesBy.getGroupValue(value);
      }
      if (!isDefined(value)) {
        value = 'null';
      }
      acc[value] = acc[value] ?? [];
      acc[value].push(row);
      return acc;
    }, {});

    return Object.entries(grouped).map(([groupValue, childrenRows]) => ({
      id: `group_${groupValue}`,
      isGrouping: true,
      groupValue: groupValue === 'null' ? null : groupValue,
      childrenRows,
    })).sort((g1, g2) => (
      sortedFilteredRows.indexOf(g1.childrenRows[0]) - sortedFilteredRows.indexOf(g2.childrenRows[0])
    ));
  }, [sortedFilteredRows, groupByColumn, groupDatesBy, isGrouping]);

  const orderedColumns = useMemo(
    () => (isGrouping ? [groupByColumn, ..._columns.filter((col) => col !== groupByColumn)] : _columns),
    [_columns, groupByColumn, isGrouping],
  );
  const lastVisibleColumnId = useMemo(() => [...orderedColumns].reverse().find((col) => !col.hidden)?.id, [orderedColumns]);
  const dndOn = !isGrouping && enableDnd;
  const dndSortableOn = !isGrouping && enableSortable;

  const activationConstraint = { distance: 10 };
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint }),
    useSensor(KeyboardSensor, { activationConstraint, coordinateGetter: sortableKeyboardCoordinates }),
  );
  const RowComponent = dndSortableOn ? RowSortable : Row;

  const _dndContextProps = useMemo(() => ({
    sensors,
    collisionDetection: closestCenter,
    modifiers: [restrictToVerticalAxis, restrictToFirstScrollableAncestor],
    ...dndContextProps,
  }), [sensors, dndContextProps]);

  const _sortableContextProps = useMemo(() => ({
    items: rowIds,
    strategy: verticalListSortingStrategy,
    ...sortableContextProps,
  }), [rowIds, sortableContextProps]);

  return (
    <ColumnsContext.Provider value={orderedColumns}>
      <RowsContext.Provider value={_rows}>
        <ExpandedRowsContext.Provider value={expandedRows}>
          <FilterStateContext.Provider value={filterState}>
            <SortStateContext.Provider value={sortState}>
              <GroupByStateContext.Provider value={groupByState}>
                <Box
                  sx={mergeSx({
                    display: 'flex',
                    position: 'relative',
                    height: 1,
                    width: 1,
                  }, sx)}
                >
                  <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                    <ConditionalWrapper
                      condition={dndOn}
                      wrapperComponent={DndContext}
                      wrapperProps={_dndContextProps}
                    >
                      <ConditionalWrapper
                        condition={dndSortableOn}
                        wrapperComponent={SortableContext}
                        wrapperProps={_sortableContextProps}
                      >
                        <Table stickyHeader {...tableProps}>
                          { noHeader ? null : (
                            <TableHead>
                              <TableRow>
                                { orderedColumns.map((col) => (
                                  col.hidden && col.id !== groupBy ? null : (
                                    <CellHeader
                                      key={`col-header_${col.id}`}
                                      columnId={col.id}
                                      rowIds={rowIds}
                                      isLastColumn={lastVisibleColumnId === col.id}
                                    />
                                  )
                                ))}
                              </TableRow>
                            </TableHead>
                          )}
                          <TableBody>
                            { isGrouping ? groupedRows.map((row, idx) => (
                              <RowGrouped
                                key={`row${row.id}`}
                                row={row}
                                onRowClick={onRowClick}
                                isLastRow={idx + 1 === groupedRows.length}
                              />
                            )) : sortedFilteredRows.map((row, idx) => (
                              <RowComponent
                                key={`row${row.id}`}
                                rowId={row.id}
                                onClick={onRowClick}
                                isLastRow={idx + 1 === sortedFilteredRows.length}
                                {...(dndSortableOn ? { tableProps } : {})}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </ConditionalWrapper>
                    </ConditionalWrapper>
                  </Box>

                  <TableActions
                    disableFilter={disableFilter}
                    disableGroupBy={disableGroupBy}
                    actions={actions}
                  />

                  { !loading ? null : (
                    (rows?.length ?? 0) === 0 ? (
                      <CircularProgress
                        size={LOADING_SIZE}
                        sx={{
                          position: 'absolute',
                          top: `calc(50% - ${LOADING_SIZE / 2}px)`,
                          left: `calc(50% - ${LOADING_SIZE / 2}px)`,
                          zIndex: 'containedModal',
                        }}
                      />
                    ) : (
                      <LinearProgress
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          zIndex: 'containedModal',
                        }}
                      />
                    )
                  )}
                </Box>
              </GroupByStateContext.Provider>
            </SortStateContext.Provider>
          </FilterStateContext.Provider>
        </ExpandedRowsContext.Provider>
      </RowsContext.Provider>
    </ColumnsContext.Provider>
  );
}

DataTable.propTypes = {
  /**
   * Rows to be displayed. Can be arbitrary objects, but the following
   * properties are used as metadata for the table:
   * - `id`:               Unique identifier for the row (required).
   * - `rowProps`:         Props to be passed to the MUI TableRow component.
   * - `draggableProps`:   Props to be passed to useDraggable, making the row
   *                       draggable.
   * - `droppableProps`:   Props to be passed to useDroppable, making the row
   *                       droppable.
   * - `expandedChildren`: Children to be rendered when the row is expanded.
   */
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    rowProps: PropTypes.object,
    draggableProps: PropTypes.object,
    droppableProps: PropTypes.object,
    expandedChildren: PropTypes.node,
  })),

  /**
   * Columns for the table. Each column can have the following properties:
   * - `id`:                     Unique identifier for the column (required).
   * - `type`:                   Type of the column, defaults to
   *                             `COL_TYPE_TEXT`. Can be one of
   *                             `COL_TYPE_ACTIONS` for an actions column,
   *                             `COL_TYPE_BOOLEAN` for a boolean column,
   *                             `COL_TYPE_DATE` for a date column, or
   *                             `COL_TYPE_SELECT` for a checkbox selection
   *                             column.
   * - `label`:                  Label to be rendered in the column header.
   * - `field`:                  Field of the row object to be used as the value
   *                             for the column.
   * - `valueGetter`:            Function that takes an object with `row` and
   *                             `value` as a parameter (`value` is the value of
   *                             the field specified by `field` or the `row` if
   *                             `field` is not specified) and returns the value
   *                             for the row. If not provided,
   *                             `row[field] ?? row` will be used instead.
   * - `sortValueGetter`:        Function that takes an object with `row` and
   *                             `value` as a parameter and returns the value
   *                             for the row to be used for sorting. If not
   *                             provided, the `valueGetter` will be used
   *                             instead.
   * - `groupValueGetter`:       Function that takes an object with `row` and
   *                             `value` as a parameter and returns the value
   *                             for the row to be used for grouping. If not
   *                             provided, the `valueGetter` will be used
   *                             instead.
   * - `quickFilterValueGetter`: Function that takes an object with `row` and
   *                             `value` as a parameter and returns the value
   *                             for the row to be used for quick filtering. If
   *                             not provided, the `filterValueGetter` will be
   *                             used instead.
   * - `filterValueGetter`:      Function that takes an object with `row` and
   *                             `value` as a parameter and returns the value
   *                             for the row to be used for filtering. If not
   *                             provided, the `valueGetter` will be used
   *                             instead.
   * - `renderGroupValue`:       Function that takes an object with `row`
   *                             (grouped) and `value` as a parameter and
   *                             returns a node to be rendered for the grouped
   *                             value. If not provided, the `renderValue` will
   *                             be used instead.
   * - `renderValue`:            Function that takes an object with `row` and
   *                             `value` as a parameter and returns what to be
   *                             rendered in the cell. If not provided, the
   *                             `valueGetter` will be used instead.
   * - `comparator`:             Function that takes two row objects as
   *                             parameters and returns a number indicating the
   *                             order of the rows.
   * - `hidden`:                 Whether the column is hidden. Defaults to
   *                             `false`.
   * - `sortable`:               Whether the column is can be sorted, defaults
   *                             to `true`.
   * - `filterable`:             Whether the column is can be filtered, defaults
   *                             to `true`.
   * - `groupable`:              Whether the rows can be grouped by this column,
   *                             defaults to `false`.
   * - `headerProps`:            Props to be passed to the MUI TableCell
   *                             component of the column header.
   * - `noHeader`:               If true, no content will be rendered in the
   *                             column header. This includes removing the sort
   *                             button, even if `sortable` is true.
   * - `cellProps`:              Props to be passed to the MUI TableCell
   *                             component. Can be an object or a function that
   *                             takes an object with `row`, `groupBy` (the
   *                             column that the table is being grouped by or
   *                             null if it is not being grouped), and
   *                             `isGrouping` (whether the current column is
   *                             being grouped on) as a parameter and returns an
   *                             object.
   * - `noWrap`:                 Whether the column should not wrap, defaults to
   *                             `false`. overflow will be hidden and text will
   *                             be ellipsized.
   * - `primary`:                Whether the column should be styled as the
   *                             primary column.
   * - `secondary`:              Whether the column should be styled as a
   *                             secondary column.
   * - `showOnHover`:            Whether the column cell contents should only be
   *                             shown on hover.
   * - `filterType`:             Type of filters to use for the column, defaults
   *                             to `FILTER_TYPE_TEXT`.
   * - `filterOptionProps`:      Only applicable when `filterType` is
   *                             `FILTER_TYPE_SELECT`. Props to be passed to the
   *                             MenuItem component of the filter option in the
   *                             selection dropdown. Can be an object or a
   *                             function that takes the option value as a
   *                             parameter and returns an object.
   * - `selection`:              Only applicable if `type` is `COL_TYPE_SELECT`.
   *                             An array of row IDs that are selected (will be
   *                             rendered as checked).
   * - `onSelect`:               Only applicable if `type` is `COL_TYPE_SELECT`.
   *                             A function that takes an array of row IDs that
   *                             are selected and an event as a parameter and
   *                             will be called when the selection changes.
   * - `disabled`:               Only applicable if `type` is `COL_TYPE_SELECT`.
   *                             An array of row IDs that are disabled.
   * - `disabledMessage`:        Only applicable if `type` is `COL_TYPE_SELECT`.
   *                             A message to be displayed when a disabled row
   *                             is disabled and hovered over. Can either be a
   *                             string or a function that takes the row as a
   *                             parameter and returns a string.
   * - `checkboxProps`:          Only applicable if `type` is `COL_TYPE_SELECT`.
   *                             An object to be passed to the MUI Checkbox
   *                             component.
   * - `isPrimarySelection`:     Only applicable if `type` is `COL_TYPE_SELECT`.
   *                             If true, rows that are selected will be
   *                             highlighted.
   * - `inMenu`:                 Only applicable if `type` is
   *                             `COL_TYPE_ACTIONS`. Determins whether the
   *                             actions should be in a collapsable menu.
   * - `actions`:                Only applicable if `type` is
   *                             `COL_TYPE_ACTIONS`. An array of objects or a
   *                             function that takes an object with `row` as a
   *                             parameter and returns a list of objects with
   *                             the following properties:
   *                             - `id`:        Unique identifier for the action
   *                                            (required).
   *                             - `component`: The action component to render.
   *                             - `props`:     The props to be passed to the
   *                                            action component.
   */
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    type: PropTypes.oneOf(COL_TYPES),
    label: PropTypes.node,
    field: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    valueGetter: PropTypes.func,
    sortValueGetter: PropTypes.func,
    groupValueGetter: PropTypes.func,
    quickFilterValueGetter: PropTypes.func,
    filterValueGetter: PropTypes.func,
    renderGroupValue: PropTypes.func,
    renderValue: PropTypes.func,
    comparator: PropTypes.func,
    hidden: PropTypes.bool,
    sortable: PropTypes.bool,
    filterable: PropTypes.bool,
    groupable: PropTypes.bool,
    headerProps: PropTypes.object,
    noHeader: PropTypes.bool,
    cellProps: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    noWrap: PropTypes.bool,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    filterType: PropTypes.oneOf(FILTER_TYPES),
    filterOptionProps: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func,
    ]),
    selection: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
    onSelect: PropTypes.func,
    disabled: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
    disabledMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]),
    checkboxProps: PropTypes.object,
    isPrimarySelection: PropTypes.bool,
    actions: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        label: PropTypes.node,
        icon: PropTypes.node,
        onClick: PropTypes.func,
        inMenu: PropTypes.bool,
        buttonProps: PropTypes.object,
      })),
    ]),
  })),

  /** Actions to be rendered in the right action sidebar. */
  actions: PropTypes.node,

  /**
   * Callback when a row is clicked. Passed the `row` object and `event` as
   *  parameters
   */
  onRowClick: PropTypes.func,

  /** Indicates that the data is loading and renders a loading indicator. */
  loading: PropTypes.bool,

  /**
   * Initial state of the table used to set the initial column to sort on (give
   * the column `id`), the initial sort order (`asc` or `desc`), the initial
   * column to group by (give the column `id`), the initial way to group
   * dates (give the option `id`, e.g. `day`, `week`, `month`, `quarter`, or
   * `year`), and the initial filters (an array of objects with `column` (the
   * column ID), `operator` (the operator ID), and `value`).
   */
  initState: PropTypes.shape({
    orderBy: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    order: PropTypes.oneOf(['asc', 'desc']),
    groupBy: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    groupDatesBy: PropTypes.oneOf(GROUP_DATES_BY_OPTIONS.map((option) => option.id)),
    filters: PropTypes.arrayOf(PropTypes.shape({
      column: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      operator: PropTypes.string,
      value: PropTypes.any,
    })),
  }),

  /**
   * If true, the table state will be stored in the URL search params. This
   * allows the table state to be persisted when navigating to a different page
   * and back.
   */
  stateInSearchParams: PropTypes.bool,

  /**
   * An array of row IDs that are expanded. This will render the row's
   * `expandedChildren` prop under the row if it is defined.
   */
  expandedRows: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),

  /**
   * If true, the table will be wrapped in a DndContext with some default
   * parameters set. If false, the table CAN still have drag and drop, but the
   * context must be provided by the parent.
   */
  enableDnd: PropTypes.bool,

  /** Props to be passed to DndContext (if `enableDnd` is set to true). */
  dndContextProps: PropTypes.object,

  /**
   * If true, the table will be wrapped in a SortableContext with some default
   * parameters set. If false, the table CAN still have sortable drag and drop,
   * but the context must be provided by the parent.
   */
  enableSortable: PropTypes.bool,

  /**
   * Props to be passed to SortableContext (if `enableSortable` is set to true).
   */
  sortableContextProps: PropTypes.object,

  /**
   * Search term that will filter rows to only those that have a value that
   * matches.
   */
  quickFilter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  /**
   * If true, the table will not have column headers. This will also mean that
   * the user will not be able to adjust the table sorting.
   */
  noHeader: PropTypes.bool,

  /** If true, the table will not have a filter action. */
  disableFilter: PropTypes.bool,

  /** If true, the table will not have a group by action. */
  disableGroupBy: PropTypes.bool,

  /** Props passed to the MUI Table component. */
  tableProps: PropTypes.object,

  /** sx passed to the container Box. */
  sx: sxPropType,
};

DataTable.defaultProps = {
  rows: [],
  columns: [],
};

export default DataTable;
