import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import { ProgramIcon, ProjectIcon, ScriptIcon, StudyIcon } from 'constants/icon.constants';
import React, { useMemo } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import { useDirectoryList, useRecentlyViewed } from 'hooks/store.hooks';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@acheloisbiosoftware/absui.core';
import Divider from '@mui/material/Divider';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import Link from 'components/Link';
import { NavButton } from 'views/AppSidebar';
import { Prop } from 'store/entity';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WidgetContainer from './WidgetContainer';
import WorkspaceTable from 'components/WorkspaceTable';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useNavigate } from 'react-router-dom';

const navButtonProps = {
  size: 36,
};

function WorkspacesWidget() {
  const navigate = useNavigate();
  const recentlyViewed = useRecentlyViewed();

  const listParams = useMemo(() => ({
    prop: new Prop('id'),
    filter: (directory) => isDefined(recentlyViewed.find((recent) => recent.id === directory.id && recent.type === ENTITY_DIRECTORY)),
    sort: (dir1, dir2) => {
      const idx1 = recentlyViewed.findIndex((recent) => recent.id === dir1.id && recent.type === ENTITY_DIRECTORY);
      const idx2 = recentlyViewed.findIndex((recent) => recent.id === dir2.id && recent.type === ENTITY_DIRECTORY);
      return idx1 - idx2;
    },
  }), [recentlyViewed]);
  const directoryIds = useDirectoryList(listParams);

  const columnProps = useMemo(() => ({
    owner: { hidden: true },
    created: { hidden: true },
  }), []);

  return (
    <WidgetContainer>
      <Typography variant='h4'>{formatType(ENTITY_DIRECTORY, { plural: true })}</Typography>
      <Stack direction='row' justifyContent='space-around' alignItems='center' sx={{ mb: 3 }}>
        <NavButton
          label={formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_STUDY })}
          to='/studies'
          Icon={StudyIcon}
          {...navButtonProps}
        />
        <NavButton
          label={formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_PROJECT })}
          to='/projects'
          Icon={ProjectIcon}
          {...navButtonProps}
        />
        <NavButton
          label={formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_PROGRAM })}
          to='/programs'
          Icon={ProgramIcon}
          {...navButtonProps}
        />
        <NavButton
          label={formatType(ENTITY_DIRECTORY, { plural: true, template: true })}
          to='/scripts'
          Icon={ScriptIcon}
          {...navButtonProps}
        />
      </Stack>
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Recently Viewed</Typography>
      <Divider />
      { directoryIds.length === 0 ? (
        <Typography sx={{ my: 2 }} variant='body2' color='text.disabled'>
          No recently viewed {formatType(ENTITY_DIRECTORY, { plural: true, lowercase: true })}...
        </Typography>
      ) : (
        <WorkspaceTable
          ids={directoryIds}
          withDnd
          columnProps={columnProps}
          onRowClick={(row) => navigate(getPath({ id: row.id, type: ENTITY_DIRECTORY }))}
          stateInSearchParams={false}
          disableFilter
          disableGroupBy
          noHeader
          initState={{ orderBy: null }}
          tableProps={{ size: 'small' }}
          sx={{ overflow: 'auto' }}
        />
      )}
      <Stack direction='row' justifyContent='flex-end' alignItems='flex-end' sx={{ flexGrow: 1, mt: 1 }}>
        <Button
          size='small'
          variant='text'
          endIcon={<ArrowForwardIcon />}
          component={Link}
          to='/workspaces'
        >
          View All
        </Button>
      </Stack>
    </WidgetContainer>
  );
}

export default WorkspacesWidget;
