import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

function SearchResultBase(props) {
  const { icon, primary, secondary, path, ...restProps } = props;
  const buttonProps = path ? { component: RouterLink, to: path } : {};
  return (
    <ListItemButton {...buttonProps} {...restProps}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItemButton>
  );
}

SearchResultBase.propTypes = {
  icon: PropTypes.node,
  primary: PropTypes.node,
  secondary: PropTypes.node,
  onClick: PropTypes.func,
  path: PropTypes.string,
};

export default SearchResultBase;
