export const ACTION_CREATE = 'create';
export const ACTION_VIEW = 'read';
export const ACTION_UPDATE = 'update';
export const ACTION_DELETE = 'destroy';
export const ACTION_ADMIN = 'admin';

export const ACTION_NEW_VERSION = 'new_version';
export const ACTION_CLONE = 'clone';
export const ACTION_INSTANTIATE = 'instantiate';
export const ACTION_GENERATE_TEMPLATE = 'generate_template';
export const ACTION_SIGN = 'sign';
export const ACTION_REJECT = 'reject';
export const ACTION_PUBLISH = 'publish';
export const ACTION_COMPLETE = 'complete';
export const ACTION_LAUNCH = 'launch';
export const ACTION_RESTORE = 'restore';
export const ACTION_CHECKOUT = 'checkout';
export const ACTION_CHECKIN = 'checkin';
export const ACTION_COMMENT_CREATE = 'comment_create';
export const ACTION_SIGNATURE_WORKFLOW_CREATE = 'signature_workflow_create';

export const RELATIONSHIP_PERMISSION_READ = 'read';
export const RELATIONSHIP_PERMISSION_WRITE = 'write';
export const RELATIONSHIP_PERMISSION_ADMIN = 'admin';
export const RELATIONSHIP_PERMISSION_OWNER = 'owner';
export const RELATIONSHIP_PERMISSION_OPTIONS = [
  RELATIONSHIP_PERMISSION_READ,
  RELATIONSHIP_PERMISSION_WRITE,
  RELATIONSHIP_PERMISSION_ADMIN,
];
export const RELATIONSHIP_PERMISSION_DISPLAY_MAP = {
  [RELATIONSHIP_PERMISSION_READ]: 'Viewer',
  [RELATIONSHIP_PERMISSION_WRITE]: 'Editor',
  [RELATIONSHIP_PERMISSION_ADMIN]: 'Admin',
  [RELATIONSHIP_PERMISSION_OWNER]: 'Owner',
};
