import { isEmpty, isEqual } from 'lodash';
import { reduceError, reduceLoading } from 'store/store.reducers.utils';

import { ENTITY_DEPENDENCY_MAP } from 'constants/schemas';
import { ENTITY_MERGE_OPTIONS } from './entity.mergeOptions';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { mergeUniqueArr } from 'utils/helpers';

const defaultValueIsEqual = (v1, v2) => isEqual(v1, v2);
const defaultMergeStrategy = (oldValue, newValue) => Object.assign(oldValue ?? {}, newValue);

export const clearUpdate = (slice, entityId, prop) => {
  const { updates } = slice;
  const entityUpdates = updates[entityId];
  delete entityUpdates?.[prop];
  if (isEmpty(entityUpdates)) {
    delete updates[entityId];
  }
};

const maybeTrimmed = (value) => (typeof(value) === 'string' ? value.trim() : value);
export const clearUpdates = (slice, entityId, {
  valueIsEqual = defaultValueIsEqual,
} = {}) => {
  const entity = slice.byId[entityId];
  const entityUpdates = slice.updates[entityId];

  /** Delete updates are the same as the saved entity */
  for (const prop of Object.keys(entityUpdates ?? {})) {
    const updateValue = maybeTrimmed(entityUpdates[prop]);
    const entityValue = maybeTrimmed(entity[prop]);
    if (valueIsEqual(updateValue, entityValue, prop)) {
      clearUpdate(slice, entityId, prop);
    }
  }
};

const mergeEntities = (state, action, type, {
  valueIsEqual = defaultValueIsEqual,
  mergeStrategy = defaultMergeStrategy,
} = {}) => {
  const slice = state[type];
  const newEntities = action.payload.entities[type];
  const newEntityIds = Object.keys(newEntities ?? {}).filter((id) => !['null', 'undefined'].includes(String(id)));

  for (const entityId of newEntityIds) {
    slice.byId[entityId] = mergeStrategy(slice.byId[entityId], newEntities[entityId], {
      id: entityId,
      slice,
      state,
      action,
    });
    clearUpdates(slice, entityId, { valueIsEqual });
  }

  slice.allIds = mergeUniqueArr(slice.allIds, newEntityIds);
};

export const removeEntity = (state, removedGenericId, visited = {}) => {
  const { id: removedId, type: removedType } = removedGenericId;
  if (visited[removedType]?.[removedId]) return;
  (visited[removedType] ??= {})[removedId] = true;

  (ENTITY_DEPENDENCY_MAP[removedType] ?? []).forEach((dependency) => {
    const {
      isRelatedField,
      field,
      cascade,
      type: relatedType,
    } = dependency;

    if (isRelatedField) {
      state[relatedType].allIds.forEach((id) => {
        const dependentObj = state[relatedType].byId[id];
        const dependentObjUpdates = state[relatedType].updates[id] ?? {};

        if (Array.isArray(dependentObjUpdates[field]) && dependentObjUpdates[field].some((childId) => String(childId) === String(removedId))) {
          dependentObjUpdates[field] = dependentObjUpdates[field].filter((childId) => String(childId) !== String(removedId));
          clearUpdates(state[relatedType], id, ENTITY_MERGE_OPTIONS[relatedType]);
        } else if (String(dependentObjUpdates[field]) === String(removedId)) {
          delete dependentObjUpdates[field];
          clearUpdates(state[relatedType], id, ENTITY_MERGE_OPTIONS[relatedType]);
        }

        if (Array.isArray(dependentObj[field]) && dependentObj[field].some((childId) => String(childId) === String(removedId))) {
          if (cascade) {
            removeEntity(state, getGenericId(id, relatedType), visited);
          } else {
            dependentObj[field] = dependentObj[field].filter((childId) => String(childId) !== String(removedId));
          }
        } else if (String(dependentObj[field]) === String(removedId)) {
          if (cascade) {
            removeEntity(state, getGenericId(id, relatedType), visited);
          } else {
            dependentObj[field] = null;
          }
        }
      });
    } else if (cascade) {
      const fieldValue = state[removedType].updates[removedId]?.[field] ?? state[removedType].byId[removedId]?.[field];
      const ids = Array.isArray(fieldValue) ? fieldValue : isDefined(fieldValue) ? [fieldValue] : [];
      ids.forEach((id) => removeEntity(state, getGenericId(id, relatedType), visited));
    }
  });

  state[removedType].allIds = state[removedType].allIds.filter((id) => String(id) !== String(removedId));
  delete state[removedType].updates[removedId];
  delete state[removedType].byId[removedId];
};

export const reduceEntitiesFulfilled = (state, action) => {
  const { entities = {}} = action.payload;
  const entityTypes = Object.keys(entities);
  for (const type of entityTypes) {
    mergeEntities(state, action, type, ENTITY_MERGE_OPTIONS[type]);
  }
};

export const reduce404 = (state, action, genericId) => {
  if (action.payload?.status === 404) removeEntity(state, genericId);
};

export const reduceEntities = (builder, { pending, fulfilled, rejected }, {
  onPending,
  onFulfilled,
  onRejected,
} = {}) => {
  builder
    .addCase(pending, (state, action) => {
      reduceLoading(state, action);
      onPending?.(state, action);
    })
    .addCase(fulfilled, (state, action) => {
      reduceEntitiesFulfilled(state, action);
      onFulfilled?.(state, action);
    })
    .addCase(rejected, (state, action) => {
      reduceError(state, action);
      onRejected?.(state, action);
    });
};
