import { ENTITY_DEPARTMENT, ENTITY_TEAM, ENTITY_USER } from 'constants/schemas';
import React, { useMemo } from 'react';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import { useEntity, useSpaceGenericId, useSpaceType } from 'hooks/store.hooks';

import AllDocumentsContent from './AllDocumentsContent';
import AllDocumentsTable from './AllDocumentsTable';
import { GenericProp } from 'store/entity';
import ListingPage from 'components/ListingPage';
import PropTypes from 'prop-types';
import { genericIdFromString } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';

const parseSelected = (selected) => selected;
const validateSelected = (selected) => {
  const genericId = genericIdFromString(selected);
  return isDefined(genericId?.id) && isDefined(genericId?.type);
};

const nameProp = new GenericProp({
  [ENTITY_USER]: 'full_name',
  [ENTITY_TEAM]: 'name',
  [ENTITY_DEPARTMENT]: 'name',
});

function AllDocuments(props) {
  const { templates = false } = props;
  const spaceType = useSpaceType();
  const spaceGenericId = useSpaceGenericId();
  const individualName = useEntity(spaceGenericId, nameProp);
  const documentType = templates ? 'Templates' : 'Documents';
  const title = spaceType === SPACE_USER ? (
    individualName ? `${individualName}'s ${documentType}` : null
  ) : [SPACE_TEAM, SPACE_DEPARTMENT].includes(spaceType) ? (
    individualName ? `${individualName} ${documentType}` : null
  ) : `All ${documentType}`;

  const sectionHeaderProps = useMemo(() => ({
    title,
    skeletonProps: { width: 300 },
  }), [title]);

  const tableProps = useMemo(() => ({ templates }), [templates]);

  return (
    <ListingPage
      parseSelected={parseSelected}
      validateSelected={validateSelected}
      sectionHeaderProps={sectionHeaderProps}
      tableComponent={AllDocumentsTable}
      tableComponentProps={tableProps}
      contentComponent={AllDocumentsContent}
    />
  );
}

AllDocuments.propTypes = {
  templates: PropTypes.bool,
};

export default AllDocuments;
