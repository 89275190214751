import React, { useEffect } from 'react';
import { taskActions, taskSelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { ENTITY_TASK } from 'constants/schemas';
import PageContainer from 'components/PageContainer';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TaskComments from './TaskComments';
import TaskDetails from './TaskDetails';
import TaskHeader from './TaskHeader';
import TaskWarnings from './TaskWarnings';
import { getGenericId } from 'utils/generic.utils';
import { navigationActions } from 'store/navigation';
import { useDispatch } from 'react-redux';
import { useTask } from 'hooks/store.hooks';
import { withEntity } from 'hooks/Entity.hooks';

function Task(props) {
  const { id, pageContainerProps } = props;
  const dispatch = useDispatch();
  const isTemplate = useTask(id, 'is_template');

  useEffect(() => {
    dispatch(navigationActions.addRecentlyViewed(getGenericId(id, ENTITY_TASK)));
  }, [dispatch, id]);

  return (
    <PageContainer {...pageContainerProps}>
      <Stack sx={{ width: 1, minWidth: 425, height: 1 }}>
        <TaskWarnings id={id} />
        <Box sx={{ overflow: 'auto', position: 'relative', px: 3, display: 'flex' }}>
          <Box sx={{ width: 1, maxWidth: 1000, mx: 'auto', mt: 'auto', mb: 2 }}>
            <TaskHeader id={id} />
            <TaskDetails id={id} />
            {!isTemplate ? (<TaskComments id={id} />) : null}
          </Box>
        </Box>
      </Stack>
    </PageContainer>
  );

}

Task.propTypes = {
  id: PropTypes.number.isRequired,
  pageContainerProps: PropTypes.object,
};

export default withEntity(Task, {
  fetchAction: taskActions.fetchTask,
  hasLoadedSelector: taskSelectors.selectTaskHasLoaded,
  readOnlySelector: (state, id) => (
    !taskSelectors.selectTaskPermission(state, id, ACTION_UPDATE) ||
    !taskSelectors.selectTask(state, id, 'is_checked_out')
  ),
  isInSpaceSelector: taskSelectors.selectTaskInSpace,
});
