import { useCallback, useMemo, useState } from 'react';

import useSpaceInitialPermissionRelationships from 'hooks/useSpaceInitialPermissionRelationships';

const DEFAULT_INIT_STATE = {
  title: '',
  assignees: [],
  due_date: null,
  description: null,
  related_obj_content_type: null,
  related_obj_id: null,
  related_obj_init_data: {},
  is_template: false,
  permission_relationships: [],
};

export const validateTaskFormData = (formData) => {
  const { title } = formData ?? {};
  return Boolean(title);
};

const useNewTaskFormData = (initState) => {
  const initPermissionRelationships = useSpaceInitialPermissionRelationships();
  const initFormData = useMemo(() => ({
    ...DEFAULT_INIT_STATE,
    permission_relationships: initPermissionRelationships,
    ...initState,
  }), [initState, initPermissionRelationships]);

  const [formData, setFormData] = useState(initFormData);
  const valid = validateTaskFormData(formData);

  const resetFormData = useCallback(() => setFormData(initFormData), [initFormData]);

  const updateFormData = useCallback((key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: typeof(value) === 'function' ? value(prev[key]) : value,
    }));
  }, []);

  return useMemo(() => ({
    formData,
    valid,
    resetFormData,
    updateFormData,
  }), [formData, valid, resetFormData, updateFormData]);
};

export default useNewTaskFormData;
