import React, { useEffect } from 'react';
import { experimentActions, experimentSelectors } from 'store/entity';
import { getGenericId, useGenericId } from 'utils/generic.utils';

import { ACTION_UPDATE } from 'constants/permission.constants';
import { ENTITY_EXPERIMENT } from 'constants/schemas';
import { EntityScopeContext } from 'hooks/scope.hooks';
import ExperimentBody from './ExperimentBody';
import ExperimentNav from './ExperimentNav';
import Loading from 'components/Loading';
import PageContainer from 'components/PageContainer';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { navigationActions } from 'store/navigation';
import { useDispatch } from 'react-redux';
import { withEntity } from 'hooks/Entity.hooks';

function Experiment(props) {
  const { id, loading, pageContainerProps } = props;
  const dispatch = useDispatch();
  const genericId = useGenericId(id, ENTITY_EXPERIMENT);

  useEffect(() => {
    dispatch(navigationActions.addRecentlyViewed(getGenericId(id, ENTITY_EXPERIMENT)));
  }, [dispatch, id]);

  return (
    <EntityScopeContext.Provider value={genericId}>
      <PageContainer
        key={`experimentBody${id}`} /* To force rerender of all children when experimentId changes */
        component={Stack}
        direction='row'
        {...pageContainerProps}
      >
        <ExperimentNav expId={id} loading={loading} />
        { loading ? <Loading containerProps={{ sx: { minWidth: 425 }}} /> : <ExperimentBody expId={id} /> }
      </PageContainer>
    </EntityScopeContext.Provider>
  );
}

Experiment.propTypes = {
  id: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  pageContainerProps: PropTypes.object,
};

export default withEntity(Experiment, {
  fetchAction: experimentActions.fetchExperiment,
  hasLoadedSelector: experimentSelectors.selectExperimentHasLoaded,
  readOnlySelector: (state, id) => (
    !experimentSelectors.selectExperimentPermission(state, id, ACTION_UPDATE) ||
    !experimentSelectors.selectExperiment(state, id, 'is_checked_out')
  ),
  isInSpaceSelector: experimentSelectors.selectExperimentInSpace,
});
