import { Button, EditorPlugins } from '@acheloisbiosoftware/absui.core';
import React, { useState } from 'react';
import { ReactEditor, useReadOnly, useSelected, useSlate } from 'slate-react';

import Chip from '@mui/material/Chip';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Day } from './Day';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import moment from 'moment';
import { usePromise } from '@acheloisbiosoftware/absui.hooks';

const { Transforms } = EditorPlugins;

function DayElement(props) {
  const { attributes, children, element, borrowFocus } = props;
  const { date } = element;
  const formattedDate = moment(date).format('dddd, MM/DD/YYYY');

  const editorStatic = useSlate();
  const path = ReactEditor.findPath(editorStatic, element);
  const [anchorEl, setAnchorEl] = useState(null);
  const selected = useSelected();
  const readOnly = useReadOnly();
  const { promiseRef, defer } = usePromise();
  const prevDay = Day.getPrevDay(editorStatic, path);
  const nextDay = Day.getNextDay(editorStatic, path);
  const minDate = prevDay ? moment(prevDay[0].date).add(1, 'day') : null;
  const maxDate = nextDay ? moment(nextDay[0].date).subtract(1, 'day') : null;

  const onOpen = (event) => {
    event.preventDefault();
    borrowFocus(() => defer());
    setAnchorEl(event.currentTarget);
  };

  const onClose = (newDate) => {
    setAnchorEl(null);
    promiseRef.current.resolve(() => {
      if (newDate) {
        editorStatic.Day.setDay(path, { date: newDate.toISOString() });
      }
    });
  };

  const onRemove = () => {
    onClose();
    Transforms.removeNodes(editorStatic, { at: path, match: (n) => Day.isDay(n) });
  };

  return (
    <Divider {...attributes} textAlign='left' sx={{ my: 1 }}>
      <Chip
        color={selected ? 'primary' : 'default'}
        contentEditable={false}
        label={formattedDate}
        sx={{ userSelect: 'none' }}
        onClick={!readOnly ? onOpen : null}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement='bottom-start'
        onClose={() => onClose()}
        sx={{ mt: 5 }} /* Chip height = 4 spacing, placement prop doesn't work correctly on vertical axis */
      >
        <DateCalendar
          value={moment(date)}
          onChange={(newDate) => onClose(newDate)}
          views={['day']}
          minDate={minDate}
          maxDate={maxDate}
        />
        <Button
          variant='outlined'
          sx={{ float: 'right', mb: 1, mr: 1 }}
          onClick={onRemove}
        >
          Remove
        </Button>
      </Popover>
      {children}
    </Divider>
  );
}

DayElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.shape({
    date: PropTypes.string.isRequired,
  }).isRequired,
  borrowFocus: PropTypes.func.isRequired,
};

export default DayElement;
