import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import {
  createEntitiesWithUpdatesSelector,
  createEntityHasLoadedSelector,
  createEntityListSelector,
  createEntityPermissionSelector,
  createEntitySelector,
  createEntityUpdatesSelector,
} from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { ENTITY_NOTE } from 'constants/schemas';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { isEmpty } from 'lodash';
import { userSelectors } from '../user';

export const selectNotes = (state) => state[ENTITY][ENTITY_NOTE];

export const selectNoteList = createEntityListSelector(selectNotes);
export const selectNote = createEntitySelector(selectNotes);
export const selectNoteHasLoaded = createEntityHasLoadedSelector(selectNotes);
export const selectNoteUpdates = createEntityUpdatesSelector(selectNotes);
export const selectNotesWithUpdates = createEntitiesWithUpdatesSelector(selectNoteUpdates);
export const selectNoteHasUpdates = (state, id) => !isEmpty(selectNoteUpdates(state, id));

export const selectNotePermission = createEntityPermissionSelector(selectNote);
export const selectNoteInSpace = (state, id, spaceType, spaceGenericId) => {
  const note = selectNote(state, id);
  if (!isDefined(note)) return true;
  if (spaceType === SPACE_USER) return note.created_by === spaceGenericId.id;
  if (spaceType === SPACE_TEAM) return userSelectors.selectTeamHasPermission(state, spaceGenericId.id, note);
  if (spaceType === SPACE_DEPARTMENT) return userSelectors.selectDepartmentHasPermission(state, spaceGenericId.id, note);
  return true;
};
