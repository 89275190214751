import * as noteActions from './note.actions';

import { reduce404, reduceEntities, removeEntity } from '../entity.reducers.utils';

import { ENTITY_NOTE } from 'constants/schemas';
import { getGenericId } from 'utils/generic.utils';

export default (builder) => {
  reduceEntities(builder, noteActions.fetchNotes);
  reduceEntities(builder, noteActions.createNote);
  reduceEntities(builder, noteActions.fetchNote, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_NOTE)),
  });
  reduceEntities(builder, noteActions.patchNote);
  reduceEntities(builder, noteActions.deleteNote, {
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_NOTE)),
  });
  reduceEntities(builder, noteActions.deleteNotePermanently, {
    onFulfilled: (state, action) => removeEntity(state, getGenericId(action.payload.result, ENTITY_NOTE)),
    onRejected: (state, action) => reduce404(state, action, getGenericId(action.meta.arg.id, ENTITY_NOTE)),
  });
  reduceEntities(builder, noteActions.cloneNote);
  reduceEntities(builder, noteActions.generateNoteTemplate);
  reduceEntities(builder, noteActions.instantiateNote);
  reduceEntities(builder, noteActions.restoreNote);
};
