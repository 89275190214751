import Note from './Note';
import React from 'react';
import { useParams } from 'react-router-dom';

function NotePage() {
  const { id } = useParams();
  return (<Note id={parseFloat(id)} />);
}

export default NotePage;
