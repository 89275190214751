import { Button, Dialog, TextField } from '@acheloisbiosoftware/absui.core';
import { ENTITY_EXPERIMENT, ENTITY_SOP } from 'constants/schemas';
import React, { useState } from 'react';
import { ShareIcon, SopIcon } from 'constants/icon.constants';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import SelectDialog from 'components/SelectDialog';
import { SelectSop } from 'components/SopTable';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useSop } from 'hooks/store.hooks';

function NewExperimentFormInline(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    containerProps,
  } = props;
  const { title, related_sop, is_template, permission_relationships } = formData;

  const numShares = (permission_relationships ?? []).length;
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const sopLongTitle = useSop(related_sop, 'long_title');
  const [stagedSop, setStagedSop] = useState(related_sop);

  return (
    <Stack direction='row' alignItems='center' {...containerProps}>
      { excludeFields.includes('title') ? null : (
        <TextField
          variant='standard'
          size='small'
          label='Title'
          {...fieldPropsMap?.title}
          onChange={(_, newTitle) => updateFormData('title', newTitle)}
          value={title}
        />
      )}
      { excludeFields.includes('related_sop') ? null : (
        <SelectDialog
          variant='standard'
          size='small'
          label={`Linked ${formatType(ENTITY_SOP)}`}
          value={isDefined(related_sop) ? (
            <Stack direction='row' alignItems='center'>
              <SopIcon colored sx={{ mx: 1 }} />
              <Typography noWrap>{sopLongTitle}</Typography>
            </Stack>
          ) : null}
          {...fieldPropsMap?.related_sop}
          dialogProps={{
            maxWidth: 'md',
            fullWidth: true,
            children: (
              <SelectSop
                value={stagedSop}
                onChange={(newSop) => setStagedSop(newSop)}
                sopTableContainerProps={{ sx: { maxHeight: 512 }}}
                sopTableProps={{ sx: { overflow: 'auto' }}}
              />
            ),
            onConfirm: () => updateFormData('related_sop', stagedSop),
            ...fieldPropsMap?.related_sop?.dialogProps,
          }}
        />
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <Box sx={{ color: 'text.icon' }}>
          <Button
            variant='text'
            color={numShares > 0 ? 'primary' : 'inherit'}
            onClick={() => setShareDialogOpen(true)}
            startIcon={<ShareIcon />}
          >
            {numShares}
          </Button>
          <Dialog
            title={`Share New ${formatType(ENTITY_EXPERIMENT, { template: is_template })}`}
            open={shareDialogOpen}
            onConfirm={() => setShareDialogOpen(false)}
            confirmOnly
            fullWidth
            maxWidth='sm'
          >
            <ShareNewDocumentField
              shares={permission_relationships}
              setShares={(newShares) => updateFormData('permission_relationships', newShares)}
              {...fieldPropsMap?.permission_relationships}
            />
          </Dialog>
        </Box>
      )}
    </Stack>
  );
}

NewExperimentFormInline.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewExperimentFormInline;
