import Box from '@mui/material/Box';
import ProfileDetails from './ProfileDetails';
import ProfileHeader from './ProfileHeader';
import ProfilePreferences from './ProfilePreferences';
import React from 'react';

function Profile() {
  return (
    <Box sx={{ px: 5, py: 4, flexGrow: 1, display: 'flex' }}>
      <Box sx={{ width: 400, mx: 'auto' }}>
        <ProfileHeader />
        <ProfileDetails />
        <ProfilePreferences />
      </Box>
    </Box>
  );
}

export default Profile;
