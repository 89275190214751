export const COL_TYPE_ACTIONS = 'actions';
export const COL_TYPE_BOOLEAN = 'boolean';
export const COL_TYPE_DATE = 'date';
export const COL_TYPE_SELECT = 'select';
export const COL_TYPE_TEXT = 'text';
export const COL_TYPE_USER = 'user';

export const COL_TYPES = [
  COL_TYPE_ACTIONS,
  COL_TYPE_BOOLEAN,
  COL_TYPE_DATE,
  COL_TYPE_SELECT,
  COL_TYPE_TEXT,
  COL_TYPE_USER,
];

export const FILTER_TYPE_BOOLEAN = 'filter-boolean';
export const FILTER_TYPE_DATE = 'filter-date';
export const FILTER_TYPE_SELECT = 'filter-select';
export const FILTER_TYPE_TEXT = 'filter-text';

export const FILTER_TYPES = [
  FILTER_TYPE_BOOLEAN,
  FILTER_TYPE_DATE,
  FILTER_TYPE_SELECT,
  FILTER_TYPE_TEXT,
];
