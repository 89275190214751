import {
  ENTITY_COMMENT,
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_NOTE,
  ENTITY_PERMISSION,
  ENTITY_SOP,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';
import React, { useMemo } from 'react';
import { useFetchListing, usePermissionList } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Field from 'components/Field';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { formatType } from 'utils/entity.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { permissionActions } from 'store/entity';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

const PERMISSIONS = [
  {
    key: 'user',
    section_label: `${formatType(ENTITY_USER)} Management`,
    permissions: [
      { codename: 'add_baseuser', label: `Create ${formatType(ENTITY_USER, { plural: true })}` },
      { codename: 'change_baseuser', label: `Edit ${formatType(ENTITY_USER, { plural: true })}` },
    ],
  },
  {
    key: 'user_role',
    section_label: `${formatType(ENTITY_USER_ROLE)} Management`,
    permissions: [
      { codename: 'add_userrole', label: `Create ${formatType(ENTITY_USER_ROLE, { plural: true })}` },
      { codename: 'change_userrole', label: `Edit ${formatType(ENTITY_USER_ROLE, { plural: true })}` },
      { codename: 'delete_userrole', label: `Delete ${formatType(ENTITY_USER_ROLE, { plural: true })}` },
    ],
  },
  {
    key: 'team',
    section_label: `${formatType(ENTITY_TEAM)} Management`,
    permissions: [
      { codename: 'add_team', label: `Create ${formatType(ENTITY_TEAM, { plural: true })}` },
      { codename: 'change_team', label: `Edit ${formatType(ENTITY_TEAM, { plural: true })}` },
      { codename: 'delete_team', label: `Delete ${formatType(ENTITY_TEAM, { plural: true })}` },
    ],
  },
  {
    key: 'department',
    section_label: `${formatType(ENTITY_DEPARTMENT)} Management`,
    permissions: [
      { codename: 'add_department', label: `Create ${formatType(ENTITY_DEPARTMENT, { plural: true })}` },
      { codename: 'change_department', label: `Edit ${formatType(ENTITY_DEPARTMENT, { plural: true })}` },
      { codename: 'delete_department', label: `Delete ${formatType(ENTITY_DEPARTMENT, { plural: true })}` },
    ],
  },
  {
    key: 'directory',
    section_label: formatType(ENTITY_DIRECTORY),
    permissions: [
      { codename: 'add_directory', label: `Create ${formatType(ENTITY_DIRECTORY, { plural: true })}` },
      { codename: 'change_directory', label: `Edit ${formatType(ENTITY_DIRECTORY, { plural: true })}` },
      { codename: 'delete_directory', label: `Delete ${formatType(ENTITY_DIRECTORY, { plural: true })}` },
    ],
  },
  {
    key: 'task',
    section_label: formatType(ENTITY_TASK),
    permissions: [
      { codename: 'view_task', label: `View ${formatType(ENTITY_TASK, { plural: true })}` },
      { codename: 'add_task', label: `Create ${formatType(ENTITY_TASK, { plural: true })}` },
      { codename: 'change_task', label: `Edit ${formatType(ENTITY_TASK, { plural: true })}` },
      { codename: 'delete_task', label: `Delete ${formatType(ENTITY_TASK, { plural: true })}` },
    ],
  },
  {
    key: 'sop',
    section_label: formatType(ENTITY_SOP),
    permissions: [
      { codename: 'view_sop', label: `View ${formatType(ENTITY_SOP, { plural: true })}` },
      { codename: 'add_sop', label: `Create ${formatType(ENTITY_SOP, { plural: true })}` },
      { codename: 'change_sop', label: `Edit ${formatType(ENTITY_SOP, { plural: true })}` },
      { codename: 'delete_sop', label: `Delete ${formatType(ENTITY_SOP, { plural: true })}` },
    ],
  },
  {
    key: 'experiment',
    section_label: formatType(ENTITY_EXPERIMENT),
    permissions: [
      { codename: 'view_experiment', label: `View ${formatType(ENTITY_EXPERIMENT, { plural: true })}` },
      { codename: 'add_experiment', label: `Create ${formatType(ENTITY_EXPERIMENT, { plural: true })}` },
      { codename: 'change_experiment', label: `Edit ${formatType(ENTITY_EXPERIMENT, { plural: true })}` },
      { codename: 'delete_experiment', label: `Delete ${formatType(ENTITY_EXPERIMENT, { plural: true })}` },
    ],
  },
  {
    key: 'comment',
    section_label: formatType(ENTITY_COMMENT),
    permissions: [
      { codename: 'add_comment', label: `Post ${formatType(ENTITY_COMMENT, { plural: true })}` },
      { codename: 'change_comment', label: `Edit ${formatType(ENTITY_COMMENT, { plural: true })}` },
      { codename: 'delete_comment', label: `Delete ${formatType(ENTITY_COMMENT, { plural: true })}` },
    ],
  },
  {
    key: 'note',
    section_label: formatType(ENTITY_NOTE),
    permissions: [
      { codename: 'view_note', label: `View ${formatType(ENTITY_NOTE, { plural: true })}` },
      { codename: 'add_note', label: `Create ${formatType(ENTITY_NOTE, { plural: true })}` },
      { codename: 'change_note', label: `Edit ${formatType(ENTITY_NOTE, { plural: true })}` },
      { codename: 'delete_note', label: `Delete ${formatType(ENTITY_NOTE, { plural: true })}` },
    ],
  },
];

function PermissionMatrix(props) {
  const {
    value = [],
    onChange,
    readOnly,
    loading,
    sx,
  } = props;
  const permissions = usePermissionList();
  const permissionsByCodename = useMemo(() => Object.fromEntries(
    permissions.map((permission) => [permission.codename, permission]),
  ), [permissions]);
  const valueCodenames = value.map((permission) => permission.codename);

  useFetchListing(permissionActions.fetchPermissions);

  const handlePermissionChange = (codename) => {
    const newValue = [...value];
    const matchIdx = newValue.findIndex((permission) => permission.codename === codename);
    if (matchIdx >= 0) {
      newValue.splice(matchIdx, 1);
    } else {
      newValue.push(permissionsByCodename[codename]);
    }
    onChange?.(newValue);
  };

  return (
    <Box
      sx={mergeSx({
        bgcolor: 'background.grey',
        px: 2,
        py: 1,
        borderRadius: 3,
      }, sx)}
    >
      <Typography variant='h6'>{formatType(ENTITY_PERMISSION, { plural: true })}</Typography>
      { PERMISSIONS.map((section) => (
        <Field key={section.key} label={section.section_label}>
          <Grid container spacing={1}>
            { section.permissions.map((permission) => (
              <Grid item xs={4} key={permission.codename}>
                <FormControlLabel
                  control={!loading ? (
                    <Checkbox
                      sx={[
                        { p: 0.5 },
                        { '&.Mui-disabled': { color: 'text.secondary' }},
                      ]}
                    />
                  ) : (
                    <Skeleton variant='rounded' width={24} height={24} sx={{ m: 0.5 }} />
                  )}
                  checked={valueCodenames.includes(permission.codename)}
                  onChange={() => handlePermissionChange(permission.codename)}
                  label={permission.label}
                  disabled={readOnly}
                  sx={{ '& .MuiFormControlLabel-label.Mui-disabled': { color: 'text.primary' }}}
                />
              </Grid>
            )) }
          </Grid>
        </Field>
      ))}
    </Box>
  );
}

PermissionMatrix.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    codename: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  sx: sxPropType,
};

export default PermissionMatrix;
