import ATTACHMENT_MERGE_OPTIONS from './attachment/attachment.mergeOptions';
import COMMENT_MERGE_OPTIONS from './comment/comment.mergeOptions';
import DIRECTORY_MERGE_OPTIONS from './directory/directory.mergeOptions';
import EXPERIMENT_MERGE_OPTIONS from './experiment/experiment.mergeOptions';
import NOTE_MERGE_OPTIONS from './note/note.mergeOptions';
import PERMISSION_MERGE_OPTIONS from './permission/permission.mergeOptions';
import PIN_MERGE_OPTIONS from './pin/pin.mergeOptions';
import SIGNATURE_MERGE_OPTIONS from './signature/signature.mergeOptions';
import SOP_MERGE_OPTIONS from './sop/sop.mergeOptions';
import TASK_MERGE_OPTIONS from './task/task.mergeOptions';
import USER_MERGE_OPTIONS from './user/user.mergeOptions';

export const ENTITY_MERGE_OPTIONS = {
  ...ATTACHMENT_MERGE_OPTIONS,
  ...COMMENT_MERGE_OPTIONS,
  ...DIRECTORY_MERGE_OPTIONS,
  ...EXPERIMENT_MERGE_OPTIONS,
  ...NOTE_MERGE_OPTIONS,
  ...PERMISSION_MERGE_OPTIONS,
  ...PIN_MERGE_OPTIONS,
  ...SIGNATURE_MERGE_OPTIONS,
  ...SOP_MERGE_OPTIONS,
  ...TASK_MERGE_OPTIONS,
  ...USER_MERGE_OPTIONS,
};
