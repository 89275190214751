import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpaceGenericId, useSpaceType } from './store.hooks';

import Error from 'components/Error';
import PropTypes from 'prop-types';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { notificationActions } from 'store/notification';
import useSpaceName from './useSpaceName';

export const EntityIdContext = createContext(null);
export const useEntityId = () => useContext(EntityIdContext);

export const EntityReadOnlyContext = createContext(true);
export const useEntityIsReadOnly = () => useContext(EntityReadOnlyContext);

export const withEntity = (Component, options = {}) => {
  const {
    fetchAction,
    idKey = 'id',
    hasLoadedSelector = () => false,
    readOnlySelector = () => true,
    isInSpaceSelector = () => true,
  } = options;
  const MemoizedComponent = React.memo(Component);

  const Entity = (props) => {
    const dispatch = useDispatch();
    const id = props[idKey];
    const { preloaded } = props;
    const [initLoading, setInitLoading] = useState(!preloaded);
    const [initLoadError, setInitLoadError] = useState(null);
    const hasLoaded = useSelector((state) => hasLoadedSelector(state, id));
    const spaceType = useSpaceType();
    const spaceGenericId = useSpaceGenericId();
    const spaceName = useSpaceName();
    const isReadOnly = useSelector((state) => readOnlySelector(state, id));
    const isInSpace = useSelector((state) => isInSpaceSelector(state, id, spaceType, spaceGenericId));
    const [outOfSpaceWarningKey, setOutOfSpaceWarningKey] = useState(null);

    useEffect(() => {
      const promise = { current: null };
      if (!preloaded) {
        setInitLoadError(null);
        setInitLoading(true);
        promise.current = dispatch(fetchAction({ [idKey]: id }));
        promise.current.then((res) => {
          if (!res.meta.aborted) {
            setInitLoading(false);
            if (res.meta.requestStatus === 'rejected') {
              const errors = Object.values(res.payload?.data ?? {});
              setInitLoadError(errors[0] ?? 'Unknown error...');
            }
          }
        });
      }
      return () => {
        promise.current?.abort?.();
      };
    }, [preloaded, id, dispatch]);

    useEffect(() => {
      if (initLoadError && hasLoaded) {
        dispatch(notificationActions.enqueueSnackbar({
          key: `entityLoadFailure${Date.now()}`,
          title: 'Failed to load page data',
          message: 'Try refreshing the page or logging out and back in again',
          variant: 'error',
        }));
      }
    }, [initLoadError, hasLoaded, dispatch]);

    useEffect(() => {
      const key = `entityNotInSpace${spaceType}_${spaceGenericId.id}_${spaceGenericId.type}`;
      if (!isInSpace && outOfSpaceWarningKey !== key) {
        if (isDefined(outOfSpaceWarningKey)) {
          dispatch(notificationActions.removeSnackbar(outOfSpaceWarningKey));
        }
        setOutOfSpaceWarningKey(key);
        dispatch(notificationActions.enqueueSnackbar({
          key,
          message: `Viewing content outside of the current space: ${spaceName}`,
          variant: 'warning',
          snackbarProps: { autoHideDuration: null },
        }));
      } else if (isInSpace && isDefined(outOfSpaceWarningKey)) {
        dispatch(notificationActions.removeSnackbar(outOfSpaceWarningKey));
        setOutOfSpaceWarningKey(null);
      }
      return () => {
        if (isDefined(outOfSpaceWarningKey)) {
          dispatch(notificationActions.removeSnackbar(outOfSpaceWarningKey));
        }
      };
    }, [isInSpace, outOfSpaceWarningKey, spaceType, spaceGenericId, spaceName, dispatch]);

    if (initLoadError && !hasLoaded) {
      return (<Error>{initLoadError}</Error>);
    }

    return (
      <EntityIdContext.Provider value={id}>
        <EntityReadOnlyContext.Provider value={isReadOnly}>
          <MemoizedComponent {...props} loading={Boolean(!hasLoaded && (initLoading || preloaded))} />
        </EntityReadOnlyContext.Provider>
      </EntityIdContext.Provider>
    );
  };

  Entity.propTypes = {
    [idKey]: PropTypes.any,
    preloaded: PropTypes.bool,
  };

  return Entity;
};
