import { ACTION_DELETE, ACTION_REJECT, ACTION_SIGN, ACTION_UPDATE } from 'constants/permission.constants';
import React, { useCallback, useState } from 'react';
import { useSignature, useSignaturePermission } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import SignatureAssignmentBase from './SignatureAssignmentBase';
import SignatureDialog from './SignatureDialog';
import { signatureActions } from 'store/entity';
import { useDispatch } from 'react-redux';

function SignatureAssignment(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const [signDialogState, setSignDialogState] = useState({ open: false, action: null });

  const signature = useSignature(id);
  const { is_signed, is_rejected, signee } = signature ?? {};
  const canUpdate = useSignaturePermission(id, ACTION_UPDATE);
  const canDelete = useSignaturePermission(id, ACTION_DELETE);
  const canSign = useSignaturePermission(id, ACTION_SIGN);
  const canReject = useSignaturePermission(id, ACTION_REJECT);

  const status = is_signed ? SignatureAssignmentBase.STATUS_SIGNED : (
    is_rejected ? SignatureAssignmentBase.STATUS_REJECTED : (
      SignatureAssignmentBase.STATUS_PENDING
    )
  );

  const updateAssignee = useCallback(async (newSignee) => {
    await dispatch(signatureActions.patchSignature({ id, data: { signee: newSignee }}));
  }, [dispatch, id]);

  const deleteSignature = useCallback(async () => {
    await dispatch(signatureActions.deleteSignature({ id }));
  }, [dispatch, id]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <SignatureAssignmentBase
        signee={signee}
        status={status}
        onConfirm={canUpdate ? updateAssignee : null}
        onDelete={canDelete ? deleteSignature : null}
      />
      { canSign ? (
        <Button
          variant='text'
          onClick={() => setSignDialogState({ open: true, action: ACTION_SIGN })}
        >
          Sign
        </Button>
      ) : null}
      { canReject ? (
        <Button
          variant='text'
          color='error'
          onClick={() => setSignDialogState({ open: true, action: ACTION_REJECT })}
        >
          Reject
        </Button>
      ) : null}
      <SignatureDialog
        open={signDialogState.open}
        onClose={() => setSignDialogState((oldState) => ({ ...oldState, open: false }))}
        action={signDialogState.action}
        signatureId={id}
      />
    </Box>
  );
}

SignatureAssignment.propTypes = {
  id: PropTypes.number.isRequired,
};

export default SignatureAssignment;
