import React, { useState } from 'react';
import { formatType, getIcon } from 'utils/entity.utils';

import { DIR_TYPES } from 'constants/directory.constants';
import { DataTableAction } from 'components/DataTable';
import { DirectoryIcon } from 'constants/icon.constants';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import NewDirectoryDialog from 'components/NewDirectoryDialog';
import PropTypes from 'prop-types';

function ActionAddDirectory(props) {
  const { directoryTypes = [], initState } = props;
  const [dialogState, setDialogState] = useState({ open: false, initState: {}});
  const isTemplate = initState?.is_template;

  return (
    <>
      { directoryTypes.length === 0 ? (
        <DataTableAction
          label={`New ${formatType(ENTITY_DIRECTORY, { template: isTemplate })}`}
          icon={<DirectoryIcon colored template={isTemplate} />}
          onClick={() => setDialogState((oldState) => ({ ...oldState, open: true }))}
        />
      ) : directoryTypes.map((type) => {
        const DirIcon = getIcon(ENTITY_DIRECTORY, { directoryType: type });
        return (
          <DataTableAction
            key={`new-${type}`}
            label={`New ${formatType(ENTITY_DIRECTORY, { directoryType: type, template: isTemplate })}`}
            icon={<DirIcon colored template={isTemplate} />}
            onClick={() => setDialogState({ open: true, initState: { type }})}
          />
        );
      })}
      <NewDirectoryDialog
        open={dialogState.open}
        onClose={() => setDialogState((oldState) => ({ ...oldState, open: false }))}
        initState={{ ...initState, ...dialogState.initState }}
      />
    </>
  );
}

ActionAddDirectory.propTypes = {
  directoryTypes: PropTypes.arrayOf(PropTypes.oneOf(DIR_TYPES)),
  initState: PropTypes.object,
};

export default ActionAddDirectory;
