import DocumentsWidget from './DocumentsWidget';
import Grid from '@mui/material/Grid';
import PageContainer from 'components/PageContainer';
import PinnedWidget from './PinnedWidget';
import React from 'react';
import WorkspacesWidget from './WorkspacesWidget';

function Recent() {
  return (
    <PageContainer sx={{ background: 'none' }}>
      <Grid container spacing={1}>
        <PinnedWidget />
        <WorkspacesWidget />
        <DocumentsWidget />
      </Grid>
    </PageContainer>
  );
}

export default Recent;
