import { ENTITY_COMMENT, ENTITY_EXPERIMENT, ENTITY_SOP } from 'constants/schemas';
import { formatType, getPath } from 'utils/entity.utils';
import { useSop, useSopPermission } from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Button } from '@acheloisbiosoftware/absui.core';
import CheckoutAlert from 'components/CheckoutAlert';
import DeletedAlert from 'components/DeletedAlert';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { getGenericId } from 'utils/generic.utils';
import { scopeActions } from 'store/scope';
import { useDispatch } from 'react-redux';
import { useEntityScope } from 'hooks/scope.hooks';
import { usePreviewMode } from './usePreview';

function SopWarnings(props) {
  const { sopId } = props;
  const { previewMode, setPreviewMode } = usePreviewMode();
  const dispatch = useDispatch();
  const isDeleted = useSop(sopId, 'is_deleted');
  const isTemplate = useSop(sopId, 'is_template');
  const isCurrent = useSop(sopId, 'is_current_version');
  const isEffective = useSop(sopId, 'is_effective_version');
  const versionIds = useSop(sopId, 'versions');
  const versions = useSop(versionIds);
  const currVersion = versions?.find((version) => version.is_current_version);
  const canViewCurrVersion = useSopPermission(currVersion?.id, ACTION_VIEW);
  const entityScope = useEntityScope();

  const deactivePreviewMode = () => {
    setPreviewMode(false);
    dispatch(scopeActions.resetScopeUpdates({ genericId: entityScope }));
  };

  return (
    <>
      <DeletedAlert id={sopId} type={ENTITY_SOP} />

      <CheckoutAlert id={sopId} type={ENTITY_SOP} />

      { !isCurrent && !isDeleted ? (
        <Alert
          severity={isEffective ? 'info' : 'warning'}
          sx={{ borderRadius: 0 }}
        >
          <AlertTitle>{isEffective ? 'New Draft in Progress' : 'Outdated'}</AlertTitle>
          { isEffective ? (
            `This version is the effective version (i.e. the latest published version), but there is a new version of this ${formatType(ENTITY_SOP, { lowercase: true, template: isTemplate })} with pending updates. See current draft version: `
          ) : (
            `This ${formatType(ENTITY_SOP, { lowercase: true, template: isTemplate })} is not the current version. See current version: `
          )}
          { canViewCurrVersion ? (
            <Link to={getPath(getGenericId(currVersion?.id, ENTITY_SOP))}>{currVersion?.long_title}</Link>
          ) : (
            <Tooltip title={`You do not have permission to view this ${formatType(ENTITY_SOP, { lowercase: true })}`} arrow>
              <Typography component='span' variant='body2' color='text.secondary'>[Content Hidden]</Typography>
            </Tooltip>
          )}
        </Alert>
      ) : null}

      { previewMode ? (
        <Alert
          severity='info'
          sx={{ borderRadius: 0 }}
          action={(
            <Button
              color='inherit'
              variant='text'
              onClick={deactivePreviewMode}
            >
              Exit
            </Button>
          )}
        >
          <AlertTitle>Preview Mode</AlertTitle>
          This is what your {formatType(ENTITY_SOP, { lowercase: true })} will roughly look like during {formatType(ENTITY_EXPERIMENT, { lowercase: true })} execution. Note: {formatType(ENTITY_COMMENT, { plural: true, lowercase: true })} are disabled in this mode.
        </Alert>
      ) : null}
    </>
  );
}

SopWarnings.propTypes = {
  sopId: PropTypes.number.isRequired,
};

export default SopWarnings;
