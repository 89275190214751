import client from 'services';

export function fetchExperiments(params, config) {
  return client({ method: 'get', url: `/api/experiments/`, params, ...config });
}

export function createExperiment(data, config) {
  return client({ method: 'post', url: '/api/experiments/', data, ...config });
}

export function fetchExperiment({ id }, config) {
  return client({ method: 'get', url: `/api/experiments/${id}/`, ...config });
}

export function patchExperiment({ id, data }, config) {
  return client({ method: 'patch', url: `/api/experiments/${id}/`, data, ...config });
}

export function deleteExperiment({ id }, config) {
  return client({ method: 'delete', url: `/api/experiments/${id}/`, ...config });
}

export function deleteExperimentPermanently({ id }, config) {
  const data = { permanently: true };
  return client({ method: 'delete', url: `/api/experiments/${id}/`, data, ...config });
}

export function checkinExperiment({ id, data }, config) {
  return client({ method: 'patch', url: `/api/experiments/${id}/checkin/`, data, ...config });
}

export function checkoutExperiment({ id }, config) {
  return client({ method: 'get', url: `/api/experiments/${id}/checkout/`, ...config });
}

export function cloneExperiment({ id }, config) {
  return client({ method: 'post', url: `/api/experiments/${id}/clone/`, ...config });
}

export function completeExperiment({ id, data }, config) {
  return client({ method: 'patch', url: `/api/experiments/${id}/complete/`, data, ...config });
}

export function exportExperiment({ id }, config) {
  return client({ method: 'get', url: `/api/experiments/${id}/export/`, ...config });
}

export function generateExperimentTemplate({ id }, config) {
  return client({ method: 'post', url: `/api/experiments/${id}/generate-template/`, ...config });
}

export function instantiateExperiment({ id }, config) {
  return client({ method: 'post', url: `/api/experiments/${id}/instantiate/`, ...config });
}

export function restoreExperiment({ id }, config) {
  return client({ method: 'patch', url: `/api/experiments/${id}/restore/`, ...config });
}
