import Logo from './Logo';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LogoIcon = React.forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    inheritViewBox
  >
    <Logo />
  </SvgIcon>
));

LogoIcon.displayName = 'LogoIcon';

export default LogoIcon;
