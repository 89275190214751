import {
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_MASTER_NOTE,
  ENTITY_NOTE,
  ENTITY_SOP,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';
import {
  GenericProp,
  SelectorProp,
  directoryActions,
  directorySelectors,
  experimentActions,
  experimentSelectors,
  noteActions,
  noteSelectors,
  sopActions,
  sopSelectors,
  taskActions,
  taskSelectors,
  userActions,
  userSelectors,
} from 'store/entity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getGenericId, uniqueGenericIds, useGenericId } from 'utils/generic.utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEntity, useEntityIcon, useEntityPermission } from 'hooks/store.hooks';
import { useGetEntitiesWithDelayedUpdates, useHasDelayedUpdates, useStageDelayedUpdates } from 'components/DelayedUpdateProvider';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ContentButton from 'components/ContentButton';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { createSelector } from '@reduxjs/toolkit';
import { getPath } from 'utils/entity.utils';
import { keyframes } from '@mui/system';
import { notificationActions } from 'store/notification';
import saveShortcutEffect from 'hooks/useSaveShortcut';
import { useDebouncedFn } from '@acheloisbiosoftware/absui.hooks';
import { useUnloadBlocker } from 'hooks/navigation.hooks';

const AUTOSYNC_INTERVAL = 10; // seconds

const SPIN_OFFSET = 45; // degrees
const NUM_SPINS = 2; // reps
const SPIN_DURATION = 2; // seconds
const SPIN = keyframes`
  from {
    transform: rotate(${SPIN_OFFSET}deg);
  }
  to {
    transform: rotate(${SPIN_OFFSET - (NUM_SPINS * 360)}deg);
  }
`;
const transitionProps = {
  unmountOnExit: true,
  timeout: 300,
};

const selectEntitiesWithUpdates = createSelector(
  userSelectors.selectDepartmentsWithUpdates,
  directorySelectors.selectDirectoriesWithUpdates,
  experimentSelectors.selectExperimentsWithUpdates,
  directorySelectors.selectMasterNotesWithUpdates,
  noteSelectors.selectNotesWithUpdates,
  sopSelectors.selectSopsWithUpdates,
  taskSelectors.selectTasksWithUpdates,
  userSelectors.selectTeamsWithUpdates,
  userSelectors.selectUsersWithUpdates,
  userSelectors.selectUserRolesWithUpdates,
  (
    departmentIds,
    directoryIds,
    experimentIds,
    masterNoteIds,
    noteIds,
    sopIds,
    taskIds,
    teamIds,
    userIds,
    userRoleIds,
  ) => [
    ...departmentIds.map((id) => getGenericId(id, ENTITY_DEPARTMENT)),
    ...directoryIds.map((id) => getGenericId(id, ENTITY_DIRECTORY)),
    ...experimentIds.map((id) => getGenericId(id, ENTITY_EXPERIMENT)),
    ...masterNoteIds.map((id) => getGenericId(id, ENTITY_MASTER_NOTE)),
    ...noteIds.map((id) => getGenericId(id, ENTITY_NOTE)),
    ...sopIds.map((id) => getGenericId(id, ENTITY_SOP)),
    ...taskIds.map((id) => getGenericId(id, ENTITY_TASK)),
    ...teamIds.map((id) => getGenericId(id, ENTITY_TEAM)),
    ...userIds.map((id) => getGenericId(id, ENTITY_USER)),
    ...userRoleIds.map((id) => getGenericId(id, ENTITY_USER_ROLE)),
  ],
);
const useEntitiesWithUpdates = () => {
  const entitiesWithUpdates = useSelector(selectEntitiesWithUpdates);
  const entityUpdatePermission = useEntityPermission(entitiesWithUpdates, ACTION_UPDATE);
  return useMemo(
    () => entitiesWithUpdates.filter((_, idx) => entityUpdatePermission[idx]),
    [entitiesWithUpdates, entityUpdatePermission],
  );
};

const ENTITY_PATCH_ACTION_MAP = {
  [ENTITY_DEPARTMENT]: userActions.patchDepartment,
  [ENTITY_DIRECTORY]: directoryActions.patchDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.patchExperiment,
  [ENTITY_MASTER_NOTE]: directoryActions.patchMasterNote,
  [ENTITY_NOTE]: noteActions.patchNote,
  [ENTITY_SOP]: sopActions.patchSop,
  [ENTITY_TASK]: taskActions.patchTask,
  [ENTITY_TEAM]: userActions.patchTeam,
  [ENTITY_USER]: userActions.patchUser,
  [ENTITY_USER_ROLE]: userActions.patchUserRole,
};

const titleProp = new GenericProp({
  [ENTITY_DEPARTMENT]: 'name',
  [ENTITY_DIRECTORY]: 'name',
  [ENTITY_EXPERIMENT]: 'title',
  [ENTITY_MASTER_NOTE]: new SelectorProp((state, masterNote) => directorySelectors.selectDirectory(state, masterNote?.parent, 'name')),
  [ENTITY_NOTE]: 'title',
  [ENTITY_SOP]: 'title',
  [ENTITY_TASK]: 'title',
  [ENTITY_TEAM]: 'name',
  [ENTITY_USER]: 'full_name',
  [ENTITY_USER_ROLE]: 'name',
});

function EntityButton(props) {
  const { id, type } = props;
  const genericId = useGenericId(id, type);
  const title = useEntity(genericId, titleProp);
  const isTemplate = useEntity(genericId, 'is_template');
  const path = useSelector((state) => (
    type === ENTITY_MASTER_NOTE ? getPath({ id: directorySelectors.selectMasterNote(state, id, 'parent'), type: ENTITY_DIRECTORY }) : getPath(genericId)
  ));
  const Icon = useEntityIcon(genericId);
  return (
    <ContentButton
      icon={Icon ? <Icon colored template={isTemplate} fontSize='small' /> : null}
      content={title}
      listItemButtonProps={{
        component: Link,
        to: path,
        sx: { py: 0, px: 1, minHeight: 0 },
      }}
      listItemTextProps={{ primaryTypographyProps: { variant: 'subtitle2' }}}
    />
  );
}

EntityButton.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
};

function SyncButton() {
  const dispatch = useDispatch();
  const entitiesWithUpdates = useEntitiesWithUpdates();
  const getEntitiesWithDelayedUpdates = useGetEntitiesWithDelayedUpdates();
  const stageDelayedUpdates = useStageDelayedUpdates();
  const hasDelayedUpdates = useHasDelayedUpdates();
  const [entitiesFailedSync, setEntitiesFailedSync] = useState([]);

  const isSynced = entitiesWithUpdates.length === 0 && !hasDelayedUpdates;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useUnloadBlocker(!isSynced);

  const onSync = useCallback(async () => {
    const entitiesWithDelayedUpdates = getEntitiesWithDelayedUpdates();
    stageDelayedUpdates();
    const allEntitiesWithUpdates = uniqueGenericIds([
      ...entitiesWithUpdates,
      ...entitiesWithDelayedUpdates,
    ]);
    if (allEntitiesWithUpdates.length === 0) return;
    setLoading(true);
    setError(false);
    setEntitiesFailedSync([]);
    const failedEntities = [];

    for (let i = 0; i < allEntitiesWithUpdates.length; i++) {
      const genericId = allEntitiesWithUpdates[i];
      const action = ENTITY_PATCH_ACTION_MAP[genericId.type];
      try {
        const result = await dispatch(action({ id: genericId.id })).unwrap();
        if (result.error && result.error.name !== 'ConditionError') {
          failedEntities.push(genericId);
        }
      } catch (err) {
        if (err.name !== 'ConditionError') {
          failedEntities.push(genericId);
        }
      }
    }

    const hasErrors = failedEntities.length > 0;
    if (hasErrors) {
      dispatch(notificationActions.enqueueSnackbar({
        key: `syncError${Date.now()}`,
        message: 'Failed to sync changes',
        variant: 'error',
      }));
    }

    setEntitiesFailedSync(failedEntities);
    setError(hasErrors);
    setLoading(false);
  }, [dispatch, entitiesWithUpdates, getEntitiesWithDelayedUpdates, stageDelayedUpdates]);

  const autoSyncTimeout = useDebouncedFn(onSync, AUTOSYNC_INTERVAL * 1000);

  useEffect(() => {
    if (!isSynced) {
      autoSyncTimeout();
    }
  }, [isSynced, autoSyncTimeout]);

  /* CMD + S handler */
  useEffect(() => saveShortcutEffect(() => {
    if (!isSynced) onSync();
  }), [isSynced, onSync]);

  return (
    <Box sx={{ width: 30, height: 30, overflow: 'hidden', ml: 1 }}>
      <Zoom in={isSynced} {...transitionProps}>
        <Tooltip title='All changes are synced' arrow placement='bottom'>
          <Box>
            <Button
              icon
              size='small'
              disabled
              sx={{ color: 'text.icon' }}
            >
              <CloudDoneIcon fontSize='small' />
            </Button>
          </Box>
        </Tooltip>
      </Zoom>
      <Zoom in={Boolean(!isSynced && !error)} {...transitionProps}>
        <Tooltip title='Sync' arrow placement='bottom'>
          <Box>
            <Button
              icon
              size='small'
              disabled={loading}
              onClick={onSync}
              sx={[
                { color: 'text.icon' },
                loading ? { animation: `${SPIN} ${SPIN_DURATION}s infinite` } : null,
              ]}
            >
              <SyncIcon fontSize='small' />
            </Button>
          </Box>
        </Tooltip>
      </Zoom>
      <Zoom in={Boolean(error && !isSynced)} {...transitionProps}>
        <Tooltip
          title={(
            <>
              <Typography variant='body2'>The following entities failed to sync:</Typography>
              {entitiesFailedSync.map(({ id, type }) => (
                <EntityButton
                  key={`failed_${type}_${id}`}
                  id={id}
                  type={type}
                />
              ))}
            </>
          )}
          arrow
          placement='bottom'
        >
          <Box>
            <Button
              icon
              size='small'
              color='error'
              onClick={onSync}
            >
              <SyncProblemIcon fontSize='small' />
            </Button>
          </Box>
        </Tooltip>
      </Zoom>
    </Box>
  );
}

export default SyncButton;
