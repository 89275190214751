import { downloadFile, stringToDownloadable } from '@acheloisbiosoftware/absui.utils';

import { ACTA_API_PAGINATION_COMPLETE } from 'constants/event.constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { pauseQueue } from 'services';
import { timeNow } from 'utils/date.utils';

export async function responseToPayload(response, thunkAPI, {
  successPayload = () => ({ ...response }),
  errorPayload = () => ({}),
} = {}) {
  const { rejectWithValue } = thunkAPI;
  if (response.error) {
    return rejectWithValue({
      status: response.error?.response?.status,
      data: response.error?.response?.data,
      ...(await errorPayload(response)),
    });
  }
  return await successPayload(response);
}

export const createServiceThunk = ({
  name,
  service,
  schema,
  preprocessingFn = (args) => args,
  postprocessingFn = (res) => res,
  isPaginated = false,
} = {}, options = {}) => {
  const action = createAsyncThunk(name, async (args, thunkAPI) => {
    const { paginationAPI, ...restArgs } = args ?? {};
    const { dispatch, signal } = thunkAPI;

    const childActionRef = { current: null };
    signal.addEventListener('abort', () => {
      childActionRef.current?.abort?.();
    });

    const data = await preprocessingFn(restArgs, thunkAPI);
    let res = await service(data, { signal });

    if (res.error && res.error.response?.status === 401) {
      pauseQueue();
      res = await service(data, { signal });
    }

    if (res.error) {
      // Store error for later troubleshooting download (see below)
      const { response: resError, code: errorCode } = res.error;
      let requestData, responseData;
      try {
        requestData = JSON.stringify(data);
      } catch {
        requestData = data;
      }
      try {
        responseData = JSON.stringify(resError.data);
      } catch {
        responseData = resError.data;
      }
      ((window.__acta__ ??= {}).errors ??= []).push({
        timestamp: timeNow(),
        action: name,
        status: resError.status,
        errorCode,
        requestDataString: requestData,
        responseDataString: responseData,
        data,
        error: res.error,
      });
    }

    if (isPaginated) { /* Deals with pagination */
      if (res.current_page < res.total_pages && !signal.aborted) {
        childActionRef.current = dispatch(action({
          ...args,
          page: res.current_page + 1,
        }));
      } else {
        document.dispatchEvent(new CustomEvent(ACTA_API_PAGINATION_COMPLETE, { detail: paginationAPI?.id }));
      }
    }
    const payload = await postprocessingFn(isPaginated ? res.results : res, thunkAPI);
    const _schema = typeof(schema) === 'function' ? schema(payload) : schema;
    return responseToPayload(
      res,
      thunkAPI,
      { successPayload: () => ({ ...normalize(payload, _schema) }) },
    );
  }, options);
  return action;
};

// Troubleshooting download function
(window.__acta__ ??= {}).downloadErrorLogs = () => {
  const { errors = []} = window.__acta__ ?? {};
  let errorString = '';
  errors.forEach((error) => {
    errorString += `Action: ${error.action} (${error.timestamp})\n`;
    errorString += `Status: ${error.status} (${error.errorCode})\n\n`;
    errorString += `Request Data: ${error.requestDataString}\n\n`;
    errorString += `Response Data: ${error.responseDataString}\n\n`;
    errorString += `${'-'.repeat(80)}\n\n`;
  });

  errorString += `Current Time: ${timeNow()}\n\n`;
  errorString += 'Current State:\n';
  errorString += JSON.stringify(window.__acta__?.store?.getState?.() ?? {});

  downloadFile({
    name: 'acta-error-logs.txt',
    file: stringToDownloadable(errorString),
  });
};
