import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import SectionHeader from 'components/SectionHeader';

function SidePreviewHeader(props) {
  const { icon, title, subtitle } = props;
  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        { icon }
        <SectionHeader title={title} variant='h4' />
      </Box>
      <Box sx={{ typography: 'caption', color: 'text.secondary' }}>
        { subtitle }
      </Box>
    </Box>
  );
}

SidePreviewHeader.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
};

export default SidePreviewHeader;
