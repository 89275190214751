import { Button } from '@acheloisbiosoftware/absui.core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

const CollapseButton = React.forwardRef((props, ref) => {
  const { open, setOpen, buttonProps, iconProps, ...restProps } = props;
  return (
    <Button
      ref={ref}
      size='small'
      onClick={() => setOpen?.(!open)}
      {...restProps}
      {...buttonProps}
      sx={mergeSx({ color: 'text.icon' }, restProps?.sx, buttonProps?.sx)}
      icon
    >
      <ExpandMoreIcon
        {...iconProps}
        sx={mergeSx({
          transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
          transition: (theme) => theme.transitions.create('transform'),
        }, iconProps?.sx)}
      />
    </Button>
  );
});

CollapseButton.displayName = 'CollapseButton';

CollapseButton.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  buttonProps: PropTypes.object,
  iconProps: PropTypes.object,
};

export default CollapseButton;
