import { ENTITY_DIRECTORY, ENTITY_DIRECTORY_ITEM, ENTITY_MASTER_NOTE, ENTITY_MASTER_NOTE_ITEM, ENTITY_MASTER_NOTE_SECTION } from 'constants/schemas';
import { SPACE_DEPARTMENT, SPACE_TEAM, SPACE_USER } from 'constants/space.constants';
import {
  createEntitiesWithUpdatesSelector,
  createEntityHasLoadedSelector,
  createEntityListSelector,
  createEntityPermissionSelector,
  createEntitySelector,
  createEntityUpdatesSelector,
} from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { createSelector } from '@reduxjs/toolkit';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { isEmpty } from 'lodash';
import { userSelectors } from '../user';

// #############################################################################
// ################################ Directories ################################
// #############################################################################
export const selectDirectories = (state) => state[ENTITY][ENTITY_DIRECTORY];
export const selectDirectoryList = createEntityListSelector(selectDirectories);
export const selectDirectory = createEntitySelector(selectDirectories);
export const selectDirectoryHasLoaded = createEntityHasLoadedSelector(selectDirectories);
export const selectDirectoryUpdates = createEntityUpdatesSelector(selectDirectories);
export const selectDirectoriesWithUpdates = createEntitiesWithUpdatesSelector(selectDirectoryUpdates);
export const selectDirectoryHasUpdates = (state, id) => !isEmpty(selectDirectoryUpdates(state, id));
export const selectDirectoryPermission = createEntityPermissionSelector(selectDirectory);
export const selectDirectoryInSpace = (state, id, spaceType, spaceGenericId) => {
  const dir = selectDirectory(state, id);
  if (!isDefined(dir)) return true;
  if (spaceType === SPACE_USER) return dir.created_by === spaceGenericId.id;
  if (spaceType === SPACE_TEAM) return userSelectors.selectTeamHasPermission(state, spaceGenericId.id, dir);
  if (spaceType === SPACE_DEPARTMENT) return userSelectors.selectDepartmentHasPermission(state, spaceGenericId.id, dir);
  return true;
};


// #############################################################################
// ############################## Directory Items ##############################
// #############################################################################
export const selectDirectoryItems = (state) => state[ENTITY][ENTITY_DIRECTORY_ITEM];
export const selectDirectoryItemList = createEntityListSelector(selectDirectoryItems);
export const selectDirectoryItem = createEntitySelector(selectDirectoryItems);
export const selectDirectoryItemPermission = createEntityPermissionSelector(selectDirectoryItem);


// #############################################################################
// ############################# Master Note Items #############################
// #############################################################################
export const selectMasterNoteItems = (state) => state[ENTITY][ENTITY_MASTER_NOTE_ITEM];
export const selectMasterNoteItem = createEntitySelector(selectMasterNoteItems);
export const selectMasterNoteItemUpdates = createEntityUpdatesSelector(selectMasterNoteItems);
const _selectMasterNoteItemsWithUpdates = createEntitiesWithUpdatesSelector(selectMasterNoteItemUpdates);


// #############################################################################
// ########################### Master Note Sections ############################
// #############################################################################
export const selectMasterNoteSections = (state) => state[ENTITY][ENTITY_MASTER_NOTE_SECTION];
export const selectMasterNoteSection = createEntitySelector(selectMasterNoteSections);
export const selectMasterNoteSectionUpdates = createEntityUpdatesSelector(selectMasterNoteSections);
const _selectMasterNoteSectionsWithUpdates = createEntitiesWithUpdatesSelector(selectMasterNoteSectionUpdates);


// #############################################################################
// ############################### Master Notes ################################
// #############################################################################
export const selectMasterNotes = (state) => state[ENTITY][ENTITY_MASTER_NOTE];
export const selectMasterNoteList = createEntityListSelector(selectMasterNotes);
export const selectMasterNote = createEntitySelector(selectMasterNotes);
export const selectMasterNoteHasLoaded = createEntityHasLoadedSelector(selectMasterNotes);
export const selectMasterNoteUpdates = createEntityUpdatesSelector(selectMasterNotes);
export const selectMasterNotePermission = createEntityPermissionSelector(selectMasterNote);

export const selectMasterNotePatchData = (state, id) => {
  const data = { ...(selectMasterNoteUpdates(state, id) ?? {}) };
  delete data.sections; // Handled in getMasterNoteSectionPatchData
  const sectionIds = selectMasterNote(state, id, 'sections') ?? [];

  const getMasterNoteItemPatchData = (masterNoteItemId, idx) => {
    const updates = { ...(selectMasterNoteItemUpdates(state, masterNoteItemId) ?? {}) };
    if (idx !== selectMasterNoteItem(state, masterNoteItemId, 'sequence')) {
      updates.sequence = idx;
    }
    return isEmpty(updates) ? null : { ...updates, id: masterNoteItemId };
  };

  const getMasterNoteSectionPatchData = (masterNoteSectionId, idx) => {
    const updates = { ...(selectMasterNoteSectionUpdates(state, masterNoteSectionId) ?? {}) };
    delete updates.items; // Handled in getMasterNoteItemPatchData
    if (idx !== selectMasterNoteSection(state, masterNoteSectionId, 'sequence')) {
      updates.sequence = idx;
    }
    const masterNoteItemIds = selectMasterNoteSection(state, masterNoteSectionId, 'items') ?? [];
    const masterNoteItemData = masterNoteItemIds.map(getMasterNoteItemPatchData).filter((update) => !isEmpty(update));
    if (masterNoteItemData.length > 0) updates.item_data = masterNoteItemData;
    return isEmpty(updates) ? null : { ...updates, id: masterNoteSectionId };
  };

  const sectionData = sectionIds.map(getMasterNoteSectionPatchData).filter((update) => !isEmpty(update));
  if (sectionData.length > 0) data.section_data = sectionData;
  return data;
};

const _selectMasterNotesWithUpdates = createEntitiesWithUpdatesSelector(selectMasterNoteUpdates);
export const selectMasterNotesWithUpdates = createSelector(
  _selectMasterNotesWithUpdates,
  _selectMasterNoteSectionsWithUpdates,
  _selectMasterNoteItemsWithUpdates,
  selectMasterNotes,
  selectMasterNoteSections,
  selectMasterNoteItems,
  (masterNoteIds, masterNoteSectionIds, masterNoteItemIds, masterNoteSlice, sectionSlice, itemSlice) => {
    const additionalSectionIds = masterNoteItemIds.reduce((result, itemId) => {
      const sectionId = itemSlice.byId[itemId]?.master_note_section;
      if ((sectionSlice.byId[sectionId]?.items ?? []).some((id) => String(id) === String(itemId))) result.push(sectionId); // In the case that the item has been deleted remotely
      return result;
    }, []);

    const additionalMasterNoteIds = [...masterNoteSectionIds, ...additionalSectionIds].reduce((result, sectionId) => {
      const masterNoteId = sectionSlice.byId[sectionId]?.master_note;
      if ((masterNoteSlice.byId[masterNoteId]?.sections ?? []).some((id) => String(id) === sectionId)) result.push(masterNoteId); // In the case that the section has been deleted remotely
      return result;
    }, []);

    return [...new Set([...masterNoteIds, ...additionalMasterNoteIds.filter((id) => isDefined(id))])];
  },
);

export const selectMasterNoteHasUpdates = (state, id) => {
  if (isDefined(id)) {
    return !isEmpty(selectMasterNotePatchData(state, id));
  }
  return (
    !isEmpty(selectMasterNoteUpdates(state)) ||
    !isEmpty(selectMasterNoteSectionUpdates(state)) ||
    !isEmpty(selectMasterNoteItemUpdates(state))
  );
};


// #############################################################################
// ################################### Other ###################################
// #############################################################################
export const selectDirectoryChildren = createSelector(
  (state, id) => {
    const masterNoteId = selectDirectory(state, id, 'master_note');
    const masterNoteSectionIds = selectMasterNote(state, masterNoteId, 'sections') ?? [];
    const masterNoteItemIds = (selectMasterNoteSection(state, masterNoteSectionIds, 'items') ?? []).flat();
    return selectMasterNoteItem(state, masterNoteItemIds, 'directory_item');
  },
  (directoryItemIds) => [...new Set(directoryItemIds ?? [])],
);
