import client from 'services';

// #############################################################################
// ################################ Categories #################################
// #############################################################################
export function fetchCategories(params, config) {
  return client({ method: 'get', url: `/api/categories/`, params, ...config });
}

export function createCategory(data, config) {
  return client({ method: 'post', url: `/api/categories/`, data, ...config });
}

// #############################################################################
// ################################### SOPs ####################################
// #############################################################################
export function fetchSops(params, config) {
  return client({ method: 'get', url: '/api/sops/', params, ...config });
}

export function createSop(data, config) {
  return client({ method: 'post', url: '/api/sops/', data, ...config });
}

export function fetchSop({ id }, config) {
  return client({ method: 'get', url: `/api/sops/${id}/`, ...config });
}

export function patchSop({ id, data }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/`, data, ...config });
}

export function deleteSop({ id }, config) {
  return client({ method: 'delete', url: `/api/sops/${id}/`, ...config });
}

export function checkinSop({ id, data }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/checkin/`, data, ...config });
}

export function checkoutSop({ id }, config) {
  return client({ method: 'get', url: `/api/sops/${id}/checkout/`, ...config });
}

export function deleteSopPermanently({ id }, config) {
  const data = { permanently: true };
  return client({ method: 'delete', url: `/api/sops/${id}/`, data, ...config });
}

export function cloneSop({ id }, config) {
  return client({ method: 'post', url: `/api/sops/${id}/clone/`, ...config });
}

export function createSection({ id, data }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/create-section/`, data, ...config });
}

export function createSubsection({ id, data }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/create-subsection/`, data, ...config });
}

export function deleteSection({ id, data }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/delete-section/`, data, ...config });
}

export function deleteSubsection({ id, data }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/delete-subsection/`, data, ...config });
}

export function exportSop({ id }, config) {
  return client({ method: 'get', url: `/api/sops/${id}/export/`, ...config });
}

export function generateSopTemplate({ id }, config) {
  return client({ method: 'post', url: `/api/sops/${id}/generate-template/`, ...config });
}

export function instantiateSop({ id }, config) {
  return client({ method: 'post', url: `/api/sops/${id}/instantiate/`, ...config });
}

export function newSopVersion({ id }, config) {
  return client({ method: 'post', url: `/api/sops/${id}/new-version/`, ...config });
}

export function publishSop({ id, data }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/publish/`, data, ...config });
}

export function restoreSop({ id }, config) {
  return client({ method: 'patch', url: `/api/sops/${id}/restore/`, ...config });
}
