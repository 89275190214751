import { ACTION_LAUNCH, ACTION_VIEW } from 'constants/permission.constants';
import { DONE, IN_PROGRESS, TO_DO } from 'constants/task.constants';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK, ENTITY_USER } from 'constants/schemas';
import React, { useCallback, useMemo } from 'react';
import { taskActions, userActions } from 'store/entity';
import {
  useFetchListing,
  useMyGlobalPermission,
  useTask,
  useTaskHasLoaded,
  useTaskPermission,
  useUpdateEntity,
  useUser,
  useUserList,
  useUserName,
} from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Chip from '@mui/material/Chip';
import { DATE_FMT_TIME } from 'constants/date.constants';
import Field from 'components/Field';
import ListItem from '@mui/material/ListItem';
import Locations from 'components/Locations';
import MenuItem from 'components/MenuItem';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Status from 'components/Status';
import TaskRelatedDocument from 'components/TaskRelatedDocument';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { getPath } from 'utils/entity.utils';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';
import { validateDirectoryFormData } from 'components/NewDirectoryForm';
import { validateExperimentFormData } from 'components/NewExperimentForm';
import { validateNoteFormData } from 'components/NewNoteForm';
import { validateSopFormData } from 'components/NewSopForm';
import { validateTaskFormData } from 'components/NewTaskForm';

const toolbarProps = { sx: { top: 51 }, elevation: 2 };
const formValidatorMap = {
  [ENTITY_DIRECTORY]: validateDirectoryFormData,
  [ENTITY_EXPERIMENT]: validateExperimentFormData,
  [ENTITY_NOTE]: validateNoteFormData,
  [ENTITY_SOP]: validateSopFormData,
  [ENTITY_TASK]: validateTaskFormData,
};

function TaskDetails(props) {
  const { id } = props;
  const task = useTask(id);
  const {
    is_template,
    status,
    created_by,
    created,
    assignees,
    due_date,
    description,
    related_obj_id,
    related_obj_content_type,
    related_obj_init_data,
    directory_item,
  } = task ?? {};
  const {
    statusHasLoaded,
    createdByHasLoaded,
    createdHasLoaded,
    assigneesHasLoaded,
    dueDateHasLoaded,
    descriptionHasLoaded,
    relatedObjIdHasLoaded,
    relatedObjContentTypeHasLoaded,
    relatedObjInitDataHasLoaded,
  } = useTaskHasLoaded(id, {
    statusHasLoaded: 'status',
    createdByHasLoaded: 'created_by',
    createdHasLoaded: 'created',
    assigneesHasLoaded: 'assignees',
    dueDateHasLoaded: 'due_date',
    descriptionHasLoaded: 'description',
    relatedObjIdHasLoaded: 'related_obj_id',
    relatedObjContentTypeHasLoaded: 'related_obj_content_type',
    relatedObjInitDataHasLoaded: 'related_obj_init_data',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isReadOnly = useEntityIsReadOnly();
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(id, ENTITY_TASK);

  const createdByName = useUserName(created_by);
  const canViewUsers = useMyGlobalPermission(ENTITY_USER, ACTION_VIEW);
  const userFetchOptions = useMemo(() => ({
    condition: canViewUsers,
    params: { filters: { is_active: true }},
  }), [canViewUsers]);
  useFetchListing(userActions.fetchUsers, userFetchOptions);
  const userListParams = useMemo(() => ({
    filter: (user) => user.is_active || assignees?.includes?.(user.id),
  }), [assignees]);
  const users = useUserList(userListParams);
  const assigneeObjs = useUser(assignees);

  const momentDueDate = useMemo(() => (due_date ? moment(due_date) : null), [due_date]);

  const formValidator = formValidatorMap[related_obj_content_type] ?? (() => false);
  const canLaunch = useTaskPermission(id, ACTION_LAUNCH);
  const launchDisabled = !formValidator(related_obj_init_data) || !canLaunch;

  const onLaunch = useCallback(async () => {
    try {
      const res = await dispatch(taskActions.launchTask({ id })).unwrap();
      const { result, entities } = res;
      const { related_obj_id: relatedId, related_obj_content_type: relatedType } = entities?.[ENTITY_TASK]?.[result] ?? {};
      navigate(getPath(getGenericId(relatedId, relatedType)));
    } catch {}
  }, [dispatch, id, navigate]);

  return (
    <>
      <Field
        label='Assignee(s)'
        variant={Field.VARIANTS.CHIPS}
        readOnly={isReadOnly}
        loading={!assigneesHasLoaded}
        skeletonProps={{ width: '100%' }}
        value={assigneeObjs ?? []}
        autocompleteProps={{
          options: users,
          onChange: (newAssignees) => update({ type: ENTITY_TASK, id, key: 'assignees', value: newAssignees.map((user) => user.id) }),
          disableCreateOption: true,
          isOptionEqualToValue: (option, value) => option.id === value.id,
          getOptionLabel: (option) => option.full_name,
          renderOption: (_props, option) => (
            <ListItem {..._props} key={`option_${option.id}`}>
              <Stack direction='row' alignItems='center'>
                <Avatar id={option.id} type={ENTITY_USER} sx={{ mr: 1 }} />
                {option.full_name}
              </Stack>
            </ListItem>
          ),
          renderTags: (value, getTagProps) => value.map((option, index) => {
            const tagProps = getTagProps({ index });
            return (
              <Chip
                {...tagProps}
                key={`tag_${option.id}`}
                label={option.full_name}
                avatar={<Avatar id={option.id} type={ENTITY_USER} size={24} />}
                sx={mergeSx(tagProps?.sx, { '& .MuiChip-avatar': { color: 'background.default' }})}
              />
            );
          }),
        }}
      />
      <Stack direction='row'>
        <Field
          label={is_template ? 'Author' : 'Reporter'}
          loading={!createdByHasLoaded}
          containerProps={{ width: 1, mr: 1 }}
        >
          {createdByName}
        </Field>
        <Field
          label='Date Created'
          loading={!createdHasLoaded}
          containerProps={{ width: 1, ml: 1 }}
        >
          {formatDate(created, DATE_FMT_TIME)}
        </Field>
      </Stack>
      <Stack direction='row'>
        <Field
          label='Status'
          readOnly={isReadOnly}
          loading={!statusHasLoaded}
          skeletonProps={{ width: '100%' }}
          variant={Field.VARIANTS.SELECT}
          value={status ?? ''}
          textFieldProps={{
            onChange: (_, newStatus) => update({ type: ENTITY_TASK, id, key: 'status', value: newStatus }),
            children: ([TO_DO, IN_PROGRESS, DONE].map((statusOption) => (
              <MenuItem
                key={`option_${statusOption}`}
                value={statusOption}
              >
                <Status>{statusOption}</Status>
              </MenuItem>
            ))),
            SelectProps: { renderValue: (value) => (<Status>{value}</Status>) },
          }}
          containerProps={{ width: 1, mr: 1 }}
        />
        <Field
          label='Due Date'
          variant={Field.VARIANTS.DATE}
          readOnly={isReadOnly}
          loading={!dueDateHasLoaded}
          skeletonProps={{ width: '100%' }}
          value={momentDueDate}
          datePickerProps={{
            onChange: (newDueDate) => update({ type: ENTITY_TASK, id, key: 'due_date', value: newDueDate?.toISOString() ?? null }),
            slotProps: { actionBar: { actions: ['today', 'clear']}},
          }}
          containerProps={{ width: 1, ml: 1 }}
        />
      </Stack>
      <Field
        label='Description'
        key={`description_${id}`}
        value={description}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly={isReadOnly}
        loading={!descriptionHasLoaded}
        interactable={!isReadOnly}
        onChangeSerialized={(newDescription) => update({ type: ENTITY_TASK, id, key: 'description', value: newDescription })}
        onChange={editorChangeHandler}
        serializeOnBlur={false}
        toolbarProps={toolbarProps}
      />
      <Field label='Location(s)'>
        <Locations directoryItem={directory_item} />
      </Field>
      <Field
        label='Related Document'
        readOnly={isReadOnly}
        loading={!relatedObjIdHasLoaded || !relatedObjContentTypeHasLoaded || !relatedObjInitDataHasLoaded}
        skeletonProps={{ variant: 'rounded', width: '100%', height: 40 }}
      >
        <TaskRelatedDocument
          related_obj_id={related_obj_id}
          related_obj_content_type={related_obj_content_type}
          related_obj_init_data={related_obj_init_data}
          onChange={(key, value) => update({ type: ENTITY_TASK, id, key, value })}
          onLaunch={onLaunch}
          launchDisabled={launchDisabled}
          readOnly={isReadOnly}
          hideLaunch={is_template}
        />
      </Field>
    </>
  );
}

TaskDetails.propTypes = {
  id: PropTypes.number,
};

export default TaskDetails;
