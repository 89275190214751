import { NAVIGATION, getLocalStoreSliceId } from 'store/store.constants';

import { SPACE_OPUS_U } from 'constants/space.constants';
import { localStore } from 'utils/localStorage.utils';

export const getInitialState = () => ({
  isBlocked: false,
  spaceType: SPACE_OPUS_U,
  spaceId: null,
  recentlyViewed: [],
  ...localStore.load(getLocalStoreSliceId(NAVIGATION)) ?? {},
  blockers: [],
});
