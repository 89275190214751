import { RELATIONSHIP_PERMISSION_OPTIONS, RELATIONSHIP_PERMISSION_OWNER } from 'constants/permission.constants';
import React, { useMemo } from 'react';
import { getGenericId, useGenericId } from 'utils/generic.utils';

import AccessRow from './AccessRow';
import { ENTITY_USER } from 'constants/schemas';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import ShareAccess from './ShareAccess';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { useMyId } from 'hooks/store.hooks';

function ShareNewDocumentField(props) {
  const { shares = [], setShares, ...restProps } = props;
  const myId = useMyId();
  const myGenericId = useGenericId(myId, ENTITY_USER);
  const shareGenericIds = useMemo(
    () => shares.map((share) => getGenericId(share.individual_id, share.individual_content_type)),
    [shares],
  );
  const exisitingIndividuals = useMemo(
    () => [myGenericId, ...shareGenericIds],
    [myGenericId, shareGenericIds],
  );

  const onChangeShare = (share, permission) => {
    setShares((prev) => {
      const { newShares } = prev.reduce((result, oldShare) => {
        if (
          oldShare.individual_id === share.individual_id &&
          oldShare.individual_content_type === share.individual_content_type
        ) {
          const newShare = { ...oldShare, permission };
          result.newShares.push(newShare);
        } else {
          result.newShares.push(oldShare);
        }
        return result;
      }, { newShares: []});
      return newShares;
    });
  };

  const onDeleteShare = (share) => {
    setShares((prev) => prev.filter((oldShare) => !(
      oldShare.individual_id === share.individual_id &&
      oldShare.individual_content_type === share.individual_content_type
    )));
  };

  const onShare = (individuals, permission) => {
    setShares((prev) => [
      ...prev,
      ...individuals.map((individual) => ({
        individual_id: individual.id,
        individual_content_type: individual.type,
        permission,
      })),
    ]);
  };

  return (
    <Field
      label='People With Access'
      {...restProps}
      valueProps={{
        ...restProps?.valueProps,
        sx: mergeSx({ ml: 0, width: 1 }, restProps?.valueProps?.sx),
      }}
    >
      <AccessRow
        individualId={myGenericId}
        permission={RELATIONSHIP_PERMISSION_OWNER}
      />
      { shares.map((share, idx) => (
        <AccessRow
          key={`AccessRow-${share.individual_content_type}${share.individual_id}`}
          individualId={shareGenericIds[idx]}
          permission={share.permission}
          onChange={(permission) => onChangeShare(share, permission)}
          onDelete={() => onDeleteShare(share)}
          canUpdate
          canDelete
        />
      ))}
      <ShareAccess
        existingIndividuals={exisitingIndividuals}
        onShare={onShare}
      />
    </Field>
  );
}

ShareNewDocumentField.propTypes = {
  shares: PropTypes.arrayOf(PropTypes.shape({
    individual_id: PropTypes.number,
    individual_content_type: PropTypes.string,
    permission: PropTypes.oneOf(RELATIONSHIP_PERMISSION_OPTIONS),
  })),
  setShares: PropTypes.func,
};

export default ShareNewDocumentField;
