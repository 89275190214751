import React, { useRef, useState } from 'react';

import CalculateIcon from '@mui/icons-material/Calculate';
import CalculationDefinition from './CalculationDefinition';
import { EditorComponents } from '@acheloisbiosoftware/absui.core';
import PropTypes from 'prop-types';
import { usePromise } from '@acheloisbiosoftware/absui.hooks';
import { useSlate } from 'slate-react';

const { ToolbarButton } = EditorComponents;

function CalculationButton(props) {
  const { borrowFocus, inMenu } = props;
  const editor = useSlate();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [units, setUnits] = useState('');
  const [formula, setFormula] = useState('');
  const { promiseRef, defer } = usePromise();

  const onClose = (exited) => {
    setOpen(false);
    promiseRef.current.resolve(() => {
      if (!exited) {
        if (formula) editor.Variable.insertCalculation({ label, units, formula });
      }
    });
    setLabel('');
    setUnits('');
    setFormula('');
  };

  const onOpen = () => {
    borrowFocus(() => defer());
    setOpen(true);
  };

  return (
    <>
      <ToolbarButton
        icon={CalculateIcon}
        label='Insert Calculation'
        onClick={onOpen}
        inMenu={inMenu}
        ref={buttonRef}
      />
      <CalculationDefinition
        anchorEl={buttonRef?.current}
        open={open}
        onClose={onClose}
        formState={{
          label,
          setLabel,
          units,
          setUnits,
          formula,
          setFormula,
        }}
      />
    </>
  );
}

CalculationButton.propTypes = {
  borrowFocus: PropTypes.func.isRequired,
  inMenu: PropTypes.bool,
};

export default CalculationButton;
