import { useColumn, useGroupByState, useGroupedRowOpenState } from './DataTableContext';

import Box from '@mui/material/Box';
import CollapseButton from 'components/CollapseButton';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { getRenderGroupValue } from './DataTable.utils';

function ContentGrouped(props) {
  const { row, columnId } = props;
  const column = useColumn(columnId);
  const { groupBy } = useGroupByState();
  const { childrenRows } = row;
  const { open, setOpen } = useGroupedRowOpenState();

  return groupBy !== columnId ? null : (
    <Box sx={{ display: 'flex', alignItems: 'center', overflowX: 'auto' }}>
      <CollapseButton
        open={open}
        setOpen={setOpen}
        buttonProps={{ sx: { mr: 1 }}}
      />
      {getRenderGroupValue(row, column)}
      <Typography variant='body2' color='text.secondary' sx={{ ml: 1 }}>
        ({childrenRows.length})
      </Typography>
    </Box>
  );
}

ContentGrouped.propTypes = {
  row: PropTypes.shape({
    isGrouping: PropTypes.bool,
    groupValue: PropTypes.any,
    childrenRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  columnId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ContentGrouped;
