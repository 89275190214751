import {
  FILTER_TYPE_BOOLEAN,
  FILTER_TYPE_DATE,
  FILTER_TYPE_SELECT,
  FILTER_TYPE_TEXT,
} from '../DataTable.constants';
import { generateFilter, getFilterObject, getFiltersForColumn } from '../DataTable.utils';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import { ClearIcon } from 'constants/icon.constants';
import FieldColumn from './FieldColumn';
import FieldOperator from './FieldOperator';
import FieldValueDate from './FieldValueDate';
import FieldValueDefault from './FieldValueDefault';
import FieldValueSelect from './FieldValueSelect';
import PropTypes from 'prop-types';
import React from 'react';
import { useColumns } from '../DataTableContext';

function FilterRow(props) {
  const { filter, setFilters } = props;
  const columns = useColumns();
  const filterObject = getFilterObject(filter, columns);
  const { column, operator, value } = filterObject;
  const { filterType = FILTER_TYPE_TEXT } = column ?? {};
  const operatorOptions = getFiltersForColumn(column);

  const changeHandler = (prop, newValue) => {
    setFilters((filters) => {
      const newFilters = [...filters];
      const idx = newFilters.findIndex((f) => f === filter);
      const newFilter = newFilters[idx];
      newFilter[prop] = newValue;
      if (prop === 'column') {
        newFilter.operator = null;
        newFilter.value = null;
      }
      return newFilters;
    });
  };

  const onDelete = () => {
    setFilters((filters) => {
      const newFilters = [...filters];
      const idx = newFilters.findIndex((f) => f === filter);
      newFilters.splice(idx, 1);
      if (newFilters.length === 0) newFilters.push(generateFilter());
      return newFilters;
    });
  };

  return (
    <Box sx={{ display: 'flex', mb: 1 }}>
      <FieldColumn
        column={column}
        setColumn={(newValue) => changeHandler('column', newValue)}
      />

      <FieldOperator
        operator={operator}
        setOperator={(newValue) => changeHandler('operator', newValue)}
        options={operatorOptions}
      />

      { filterType === FILTER_TYPE_BOOLEAN ? (
        null
      ) : filterType === FILTER_TYPE_DATE ? (
        <FieldValueDate
          value={value}
          setValue={(newValue) => changeHandler('value', newValue)}
        />
      ) : filterType === FILTER_TYPE_SELECT ? (
        <FieldValueSelect
          filter={filterObject}
          setValue={(newValue) => changeHandler('value', newValue)}
        />
      ) : (
        <FieldValueDefault
          value={value}
          setValue={(newValue) => changeHandler('value', newValue)}
        />
      )}

      <Box sx={{ my: 'auto' }}>
        <Button icon onClick={onDelete}>
          <ClearIcon />
        </Button>
      </Box>
    </Box>
  );
}

FilterRow.propTypes = {
  filter: PropTypes.shape({
    column: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    operator: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    value: PropTypes.any,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default FilterRow;
