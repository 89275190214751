import { ACTION_PUBLISH, ACTION_SIGNATURE_WORKFLOW_CREATE } from 'constants/permission.constants';
import { ENTITY_SIGNATURE, ENTITY_SOP } from 'constants/schemas';
import React, { useCallback, useState } from 'react';
import { signatureActions, sopActions } from 'store/entity';
import { useSop, useSopHasLoaded, useSopPermission } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import Loading from 'components/Loading';
import ParameterSection from './ParameterSection';
import PreviewIcon from '@mui/icons-material/Preview';
import PropTypes from 'prop-types';
import PublishIcon from '@mui/icons-material/Publish';
import { SignatureIcon } from 'constants/icon.constants';
import SignaturesSection from './SignaturesSection';
import SopSection from './SopSection';
import SopWarnings from './SopWarnings';
import Stack from '@mui/material/Stack';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { usePreviewMode } from './usePreview';
import { useStageDelayedUpdates } from 'components/DelayedUpdateProvider';

const BODY_ID = 'sop-body';

function SopActions(props) {
  const { sopId } = props;
  const canPublish = useSopPermission(sopId, ACTION_PUBLISH);
  const canCreateSignatureWorkflow = useSopPermission(sopId, ACTION_SIGNATURE_WORKFLOW_CREATE);
  const stageUpdates = useStageDelayedUpdates();
  const dispatch = useDispatch();
  const { previewMode, setPreviewMode } = usePreviewMode();

  const [signatureLoading, setSignatureLoading] = useState(false);
  const initSignatureWorkflow = useCallback(() => {
    setSignatureLoading(true);
    dispatch(signatureActions.createSignatureWorkflow({
      object_id: sopId,
      content_type: ENTITY_SOP,
    }))
      .unwrap()
      .catch(() => null)
      .finally(() => setSignatureLoading(false));
  }, [dispatch, sopId]);

  const [publishLoading, setPublishLoading] = useState(false);
  const onPublish = useCallback(() => {
    stageUpdates();
    setPublishLoading(true);
    dispatch(sopActions.publishSop({ id: sopId }))
      .unwrap()
      .catch(() => null)
      .finally(() => setPublishLoading(false));
  }, [dispatch, sopId, stageUpdates]);

  const activatePreviewMode = () => {
    setPreviewMode(true);
    document.getElementById(BODY_ID).scrollTo({ top: 0, behavior: 'smooth' });
  };

  return previewMode ? null : (
    <Box sx={{ flexGrow: 1, display: 'flex', my: 2 }}>
      <Button
        variant='outlined'
        startIcon={<PreviewIcon />}
        onClick={activatePreviewMode}
        sx={{ ml: 'auto' }}
        data-test='preview-sop'
      >
        Preview
      </Button>
      { canCreateSignatureWorkflow ? (
        <Button
          startIcon={<SignatureIcon />}
          onClick={initSignatureWorkflow}
          loading={signatureLoading}
          sx={{ ml: 2 }}
        >
          Send for {formatType(ENTITY_SIGNATURE, { plural: true })}
        </Button>
      ) : null}
      { canPublish ? (
        <Button
          startIcon={<PublishIcon />}
          onClick={onPublish}
          variant='outlined'
          requireConfirmation
          loading={publishLoading}
          dialogProps={{
            title: 'Publish without Review?',
            children: `Are you sure you would like to publish this ${formatType(ENTITY_SOP, { lowercase: true })} without review? Once published, no further changes can be made. To publish with review, send for ${formatType(ENTITY_SIGNATURE, { plural: true, lowercase: true })} instead, then once approved, the ${formatType(ENTITY_SOP, { lowercase: true })} will be published.`,
          }}
          sx={{ ml: 2 }}
          data-test='publish-sop'
        >
          Publish
        </Button>
      ) : null}
    </Box>
  );
}

SopActions.propTypes = {
  sopId: PropTypes.number.isRequired,
};

function SopBody(props) {
  const { sopId } = props;
  const sectionIds = useSop(sopId, 'sections');
  const signatureWorkflowId = useSop(sopId, 'signature_workflow');
  const sectionIdsHasLoaded = useSopHasLoaded(sopId, 'sections');
  const signatureWorkflowIdHasLoaded = useSopHasLoaded(sopId, 'signature_workflow');

  return (!sectionIdsHasLoaded || !signatureWorkflowIdHasLoaded) ? (<Loading containerProps={{ sx: { minWidth: 425 }}} />) : (
    <Stack
      sx={{
        width: 1,
        minWidth: 425,
      }}
    >
      <SopWarnings sopId={sopId} />
      <Box
        id={BODY_ID}
        sx={{
          overflow: 'auto',
          position: 'relative',
          px: 4,
          display: 'flex',
        }}
      >
        <Box
          sx={{
            maxWidth: 800,
            width: 1,
            mx: 'auto',
            mt: 'auto',
            mb: 2,
          }}
        >
          <ParameterSection sopId={sopId} />
          { sectionIds?.map((sectionId) => (
            <SopSection
              key={`section_${sectionId}`}
              id={sectionId}
            />
          ))}
          { isDefined(signatureWorkflowId) ? (
            <SignaturesSection signatureWorkflowId={signatureWorkflowId} />
          ) : null}
          <SopActions sopId={sopId} />
        </Box>
      </Box>
    </Stack>
  );
}

SopBody.propTypes = {
  sopId: PropTypes.number.isRequired,
};

export default SopBody;
