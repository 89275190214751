import React, { useState } from 'react';
import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import Link from 'components/Link';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const DEFAULT_ICON_SIZE = 24;
const DEFAULT_ICON_SIZE_GROW = 26;

function NavButton(props) {
  const {
    label,
    Icon,
    selected,
    size = DEFAULT_ICON_SIZE,
    to,
    ...restProps
  } = props;
  const sizeGrow = size * (DEFAULT_ICON_SIZE_GROW / DEFAULT_ICON_SIZE);
  const isLink = isDefined(to);
  const [hover, setHover] = useState(false);

  const onMouseEnter = (...args) => {
    setHover(true);
    restProps?.onMouseEnter?.(...args);
  };

  const onMouseLeave = (...args) => {
    setHover(false);
    restProps?.onMouseLeave?.(...args);
  };

  return (
    <Stack
      alignItems='center'
      {...restProps}
      component={isLink ? Link : null}
      {...(isLink ? { to } : {})}
      sx={mergeSx(
        {
          color: selected || hover ? 'text.primary' : 'text.icon',
          cursor: 'pointer',
        },
        isLink ? { '&:hover': { textDecoration: 'none' }} : {},
        restProps?.sx,
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Stack
        justifyContent='center'
        alignItems='center'
        sx={{
          height: sizeGrow,
          width: sizeGrow,
          p: 0.5,
          borderRadius: 2,
          bgcolor: selected ? 'action.selected' : (hover ? 'action.hover' : null),
          transition: (theme) => theme.transitions.create('background-color'),
        }}
      >
        { Icon ? (
          <Icon
            sx={{
              fontSize: selected || hover ? sizeGrow : size,
              transition: (theme) => theme.transitions.create('font-size'),
            }}
          />
        ) : null}
      </Stack>
      <Typography
        variant='caption'
        sx={{
          lineHeight: 1,
          mt: 0.5,
          fontWeight: selected || hover ? 'fontWeightBold' : 'fontWeightRegular',
          transition: (theme) => theme.transitions.create('font-weight'),
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
}

NavButton.propTypes = {
  label: PropTypes.node,
  Icon: PropTypes.elementType,
  selected: PropTypes.bool,
  size: PropTypes.number,
  to: PropTypes.string,
};

export default NavButton;
