import { formatType, getIcon } from 'utils/entity.utils';

import { DIR_TYPES } from 'constants/directory.constants';
import { ENTITY_DIRECTORY } from 'constants/schemas';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import React from 'react';
import { ShareNewDocumentField } from 'components/Shares';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function NewDirectoryFormBlock(props) {
  const {
    formData,
    updateFormData,
    excludeFields,
    fieldPropsMap,
    containerProps,
  } = props;
  const { name, type, permission_relationships } = formData ?? {};

  return (
    <Stack {...containerProps}>
      { excludeFields.includes('name') ? null : (
        <Field
          label='Name'
          value={name}
          variant={Field.VARIANTS.TEXT_FIELD}
          readOnly={false}
          {...fieldPropsMap?.name}
          labelProps={{ required: true, ...fieldPropsMap?.name?.labelProps }}
          textFieldProps={{
            onChange: (_, newValue) => updateFormData('name', newValue),
            ...fieldPropsMap?.name?.textFieldProps,
          }}
        />
      )}
      { excludeFields.includes('type') ? null : (
        <Field
          label='Type'
          {...fieldPropsMap?.type}
          labelProps={{ required: true, ...fieldPropsMap?.type?.labelProps }}
        >
          <ToggleButtonGroup
            color='primary'
            size='small'
            value={type}
            exclusive
            onChange={(_, newType) => (newType ? updateFormData('type', newType) : null)}
            sx={{ my: 0.5 }}
          >
            { DIR_TYPES.map((_type) => {
              const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: _type });
              return (
                <ToggleButton key={_type} value={_type}>
                  <Stack alignItems='center' sx={{ mx: 1 }}>
                    <Icon sx={{ fontSize: 20, mx: 1 }} />
                    {formatType(ENTITY_DIRECTORY, { directoryType: _type })}
                  </Stack>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </Field>
      )}
      { excludeFields.includes('permission_relationships') ? null : (
        <ShareNewDocumentField
          shares={permission_relationships}
          setShares={(newShares) => updateFormData('permission_relationships', newShares)}
          {...fieldPropsMap?.permission_relationships}
        />
      )}
    </Stack>
  );
}

NewDirectoryFormBlock.propTypes = {
  formData: PropTypes.object,
  updateFormData: PropTypes.func,
  excludeFields: PropTypes.arrayOf(PropTypes.string),
  fieldPropsMap: PropTypes.object,
  containerProps: PropTypes.object,
};

export default NewDirectoryFormBlock;
