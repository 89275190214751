import {
  ConstantProp,
  FunctionProp,
  GenericProp,
  Prop,
  commentActions,
  directoryActions,
  directorySelectors,
  entitySelectors,
  experimentActions,
  noteActions,
  signatureActions,
  sopActions,
  taskActions,
  userActions,
} from 'store/entity';
import { DELETE_PERMANENT, DELETE_SOFT, DELETE_STANDARD } from 'constants/deletion.constants';
import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import {
  ENTITY_COMMENT,
  ENTITY_DEPARTMENT,
  ENTITY_DIRECTORY,
  ENTITY_EXPERIMENT,
  ENTITY_MASTER_NOTE_SECTION,
  ENTITY_NOTE,
  ENTITY_SIGNATURE,
  ENTITY_SOP,
  ENTITY_SOP_SECTION,
  ENTITY_SOP_SUBSECTION,
  ENTITY_TASK,
  ENTITY_TEAM,
  ENTITY_USER,
  ENTITY_USER_ROLE,
} from 'constants/schemas';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatType, getIcon } from 'utils/entity.utils';
import { getGenericId, includesGenericId, uniqueGenericIds, useGenericId } from 'utils/generic.utils';
import { useDirectory, useDirectoryItem, useEntity, useFetchListing } from 'hooks/store.hooks';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_DELETE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import ChildrenDocumentTable from './ChildrenDocumentTable';
import Collapse from '@mui/material/Collapse';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

const STANDARD_DELETE_ACTION_MAP = {
  [ENTITY_COMMENT]: commentActions.deleteComment,
  [ENTITY_DEPARTMENT]: userActions.deleteDepartment,
  [ENTITY_MASTER_NOTE_SECTION]: directoryActions.deleteMasterNoteSection,
  [ENTITY_SIGNATURE]: signatureActions.deleteSignature,
  [ENTITY_SOP_SECTION]: sopActions.deleteSection,
  [ENTITY_SOP_SUBSECTION]: sopActions.deleteSubsection,
  [ENTITY_TEAM]: userActions.deleteTeam,
  [ENTITY_USER_ROLE]: userActions.deleteUserRole,
};

const SOFT_DELETE_ACTION_MAP = {
  [ENTITY_DIRECTORY]: directoryActions.deleteDirectory,
  [ENTITY_EXPERIMENT]: experimentActions.deleteExperiment,
  [ENTITY_NOTE]: noteActions.deleteNote,
  [ENTITY_SOP]: sopActions.deleteSop,
  [ENTITY_TASK]: taskActions.deleteTask,
};

const PERMANENT_DELETE_ACTION_MAP = {
  [ENTITY_DIRECTORY]: directoryActions.deleteDirectoryPermanently,
  [ENTITY_EXPERIMENT]: experimentActions.deleteExperimentPermanently,
  [ENTITY_NOTE]: noteActions.deleteNotePermanently,
  [ENTITY_SOP]: sopActions.deleteSopPermanently,
  [ENTITY_TASK]: taskActions.deleteTaskPermanently,
};

const getDeletionType = (type, isDeleted = false) => {
  if (isDeleted && Object.keys(PERMANENT_DELETE_ACTION_MAP).includes(type)) return DELETE_PERMANENT;
  if (!isDeleted && Object.keys(SOFT_DELETE_ACTION_MAP).includes(type)) return DELETE_SOFT;
  if (Object.keys(STANDARD_DELETE_ACTION_MAP).includes(type)) return DELETE_STANDARD;
  return null;
};

const getDeleteAction = (entityType, deletionType) => {
  if (deletionType === DELETE_SOFT) return SOFT_DELETE_ACTION_MAP[entityType];
  if (deletionType === DELETE_PERMANENT) return PERMANENT_DELETE_ACTION_MAP[entityType];
  return STANDARD_DELETE_ACTION_MAP[entityType];
};

const directoryTypeProp = new GenericProp({ [ENTITY_DIRECTORY]: 'type' }, { ignoreMissing: true });

const EMPTY_ARRAY = [];
const genericIdProp = new Prop({ id: 'object_id', type: 'content_type' });
const selectDirectChildren = (state, id, type) => {
  if (type === ENTITY_DIRECTORY) {
    const directoryItems = directorySelectors.selectDirectoryChildren(state, id) ?? EMPTY_ARRAY;
    return directorySelectors.selectDirectoryItem(state, directoryItems, genericIdProp);
  } else if (type === ENTITY_MASTER_NOTE_SECTION) {
    const masterNoteItems = directorySelectors.selectMasterNoteSection(state, id, 'items') ?? EMPTY_ARRAY;
    const directoryItems = directorySelectors.selectMasterNoteItem(state, masterNoteItems, 'directory_item');
    return directorySelectors.selectDirectoryItem(state, directoryItems, genericIdProp);
  }
  return EMPTY_ARRAY;
};

const selectChildrenTreeCurried = createSelector(
  (state, id, type) => selectDirectChildren(state, id, type),
  (state, id) => id,
  (state, id, type) => type,
  (directChildrenIds, id, type) => {
    const parentGenericId = getGenericId(id, type);
    return createSelector(
      [
        (state) => {
          const directoryItemId = entitySelectors.selectEntity(state, parentGenericId, 'directory_item');
          const locations = directorySelectors.selectDirectoryItem(state, directoryItemId, 'locations');
          return entitySelectors.selectEntityPermission(state, parentGenericId, ACTION_DELETE) && !isNil(locations) && locations.length <= 1;
        },
        ...directChildrenIds.map((genericId) => (state) => selectChildrenTreeCurried(state, genericId.id, genericId.type)(state)),
      ],
      (canDelete, ...childrenTrees) => ({ parent: parentGenericId, canDelete, children: childrenTrees }),
    );
  },
);

const getDeletableChildren = (rootNode) => {
  const _getDeletableChildren = (node) => {
    const { parent, canDelete, children } = node;
    return canDelete ? [parent, ...children.map(_getDeletableChildren).flat()] : [];
  };
  return uniqueGenericIds(rootNode.children.map(_getDeletableChildren).flat());
};

const useChildrenEntities = (id, type, open) => {
  const dispatch = useDispatch();
  const fetchedRef = useRef([]);

  const childrenTree = useSelector((state) => selectChildrenTreeCurried(state, id, type)(state));
  const directChildren = useMemo(
    () => childrenTree.children.map((node) => node.parent),
    [childrenTree],
  );
  const deletableChildren = useMemo(() => getDeletableChildren(childrenTree), [childrenTree]);

  useEffect(() => {
    if (!open) fetchedRef.current = [];
  }, [open]);

  // NOTE: no need to fetch or master note sections, since deletion of them
  // are only from pages which already fetch the children. Only need to fetch
  // directories.
  useEffect(() => {
    if (open) {
      deletableChildren.forEach((genericId) => {
        if (genericId.type === ENTITY_DIRECTORY && !fetchedRef.current.includes(genericId.id)) {
          fetchedRef.current = [...fetchedRef.current, genericId.id];
          dispatch(directoryActions.fetchDirectory({ id: genericId.id }));
        }
      });
    }
  }, [deletableChildren, dispatch, open]);

  return useMemo(() => ({
    directChildren,
    deletableChildren,
    childrenTree,
  }), [directChildren, deletableChildren, childrenTree]);
};

const useDirectoryLocations = (genericId, open) => {
  const directoryItem = useEntity(genericId, 'directory_item');
  const locations = useDirectoryItem(directoryItem, 'locations');
  const fetchParams = useMemo(() => ({
    condition: open,
    params: { filters: { ids: locations }},
  }), [open, locations]);
  useFetchListing(directoryActions.fetchDirectories, fetchParams);
  return useDirectory(locations);
};

const KEEP_CHILDREN = 'KEEP_ALL';
const DELETE_CHILDREN = 'DELETE_ALL';
const DELETE_SELECTED_CHILDREN = 'DELETE_SELECTION';

const levelProp = new GenericProp({
  [ENTITY_DIRECTORY]: new FunctionProp((directory) => [DIR_TYPE_STUDY, DIR_TYPE_PROJECT, DIR_TYPE_PROGRAM].indexOf(directory?.type)),
  [ENTITY_EXPERIMENT]: new ConstantProp(-1),
  [ENTITY_NOTE]: new ConstantProp(-1),
  [ENTITY_SOP]: new ConstantProp(-1),
  [ENTITY_TASK]: new ConstantProp(-1),
});

function DeleteDialog(props) {
  const {
    open,
    onClose,
    id,
    type,
    onBeforeDelete,
    onDeleteSuccess,
    onDeleteFailure,
    ...restProps
  } = props;
  const dispatch = useDispatch();
  const primaryGenericId = useGenericId(id, type);
  const template = useEntity(primaryGenericId, 'is_template');
  const isDeleted = useEntity(primaryGenericId, 'is_deleted');
  const deletionType = getDeletionType(type, isDeleted);
  const directoryType = useEntity(primaryGenericId, directoryTypeProp);
  const directoryLocations = useDirectoryLocations(primaryGenericId, open);

  const [childrenHandling, setChildrenHandling] = useState(KEEP_CHILDREN);

  const [selectedChildren, setSelectedChildren] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    directChildren,
    deletableChildren,
    childrenTree,
  } = useChildrenEntities(id, type, open);
  const childrenToDelete = childrenHandling === DELETE_SELECTED_CHILDREN ? (
    selectedChildren
  ) : childrenHandling === DELETE_CHILDREN ? (
    deletableChildren
  ) : EMPTY_ARRAY;

  const _onClose = useCallback(() => {
    setChildrenHandling(KEEP_CHILDREN);
    setSelectedChildren([]);
    onClose?.();
  }, [onClose]);

  const getSelectableChildren = useCallback((selection) => {
    // NOTE: this function only checks which children are selectable in the
    // sense that they are visible in the children tree. It does not check if
    // deletion for the document is permitted.
    const _getSelectableChildren = (node) => {
      const { parent, canDelete, children } = node;
      const parentSelected = includesGenericId(selection, parent);
      return canDelete ? [
        parent,
        ...(parentSelected ? children.flatMap(_getSelectableChildren) : []),
      ] : [];
    };
    return childrenTree.children.flatMap(_getSelectableChildren);
  }, [childrenTree]);

  const _setSelection = useCallback((newValue) => {
    if (childrenHandling !== DELETE_SELECTED_CHILDREN) setChildrenHandling(DELETE_SELECTED_CHILDREN);
    setSelectedChildren((oldSelection) => {
      const newSelection = typeof(newValue) === 'function' ? newValue(oldSelection) : newValue;
      const selectableChildren = getSelectableChildren(newSelection);
      return newSelection.filter((genericId) => includesGenericId(selectableChildren, genericId));
    });
  }, [childrenHandling, getSelectableChildren]);

  const childrenLevel = useEntity(childrenToDelete, levelProp);
  const orderedChildrenToDelete = useMemo(() => {
    // NOTE: order matters because parents must be guaranteed to be deleted
    // before children, mainly for the directory case so that the MasterNoteItem
    // objects to remain in the database.
    const zipped = childrenToDelete.map((genericId, i) => [genericId, childrenLevel[i]]);
    zipped.sort((a, b) => b[1] - a[1]);
    return zipped.map(([genericId]) => genericId);
  }, [childrenToDelete, childrenLevel]);

  const deleteEntities = useCallback(async () => {
    setLoading(true);
    const primaryDeletionId = { id, type };
    const entitiesToDelete = [
      primaryDeletionId,
      ...orderedChildrenToDelete,
    ];
    const primaryDeletionIdx = entitiesToDelete.indexOf(primaryDeletionId);
    await onBeforeDelete?.(primaryDeletionId, { entitiesToDelete, deletionType });

    const successes = [];
    for (let i = 0; i < entitiesToDelete.length; i++) {
      const genericId = entitiesToDelete[i];
      const _deletionType = i === primaryDeletionIdx ? deletionType : DELETE_SOFT;
      const action = getDeleteAction(genericId.type, _deletionType);
      try {
        const result = await dispatch(action({ id: genericId.id })).unwrap();
        if (!result.error) {
          successes.push(result);
        } else {
          await onDeleteFailure?.({
            error: result,
            successes,
            skipped: entitiesToDelete.slice(i + 1),
            deletionType,
          });
          setLoading(false);
          return;
        }
      } catch (error) {
        await onDeleteFailure?.({
          error,
          successes,
          skipped: entitiesToDelete.slice(i + 1),
          deletionType,
        });
        setLoading(false);
        return;
      }
    }
    const primaryDeletionResult = successes[primaryDeletionIdx];
    await onDeleteSuccess?.(primaryDeletionResult, { results: successes, deletionType });
    setLoading(false);
    _onClose();
  }, [
    id,
    type,
    orderedChildrenToDelete,
    onBeforeDelete,
    onDeleteSuccess,
    onDeleteFailure,
    deletionType,
    _onClose,
    dispatch,
  ]);

  const title = deletionType === DELETE_PERMANENT ? (
    `Permanently Delete ${formatType(type, { template, directoryType })}?`
  ) : deletionType === DELETE_SOFT ? (
    `Move ${formatType(type, { template, directoryType })} to Trash?`
  ) : (
    `Delete ${formatType(type, { template, directoryType })}?`
  );
  const confirmButtonText = deletionType === DELETE_PERMANENT ? (
    'Yes, Permanently Delete'
  ) : deletionType === DELETE_SOFT ? (
    'Yes, Move to Trash'
  ) : (
    'Yes, Delete'
  );
  const backButtonProps = useMemo(() => ({ color: 'error', ...restProps?.backButtonProps }), [restProps?.backButtonProps]);
  const confirmButtonProps = useMemo(() => ({ color: 'error', ...restProps?.confirmButtonProps }), [restProps?.confirmButtonProps]);

  return (
    <Dialog
      title={title}
      loading={loading}
      maxWidth='sm'
      fullWidth
      disableCloseOnConfirm
      onConfirm={deleteEntities}
      confirmButtonText={confirmButtonText}
      backButtonText='Cancel'
      {...restProps}
      backButtonProps={backButtonProps}
      confirmButtonProps={confirmButtonProps}
      open={open}
      onClose={_onClose}
    >
      <Typography>
        {deletionType === DELETE_PERMANENT ? (
          `Are you sure you want to permanently delete this ${formatType(type, { lowercase: true, template, directoryType })}?`
        ) : deletionType === DELETE_SOFT ? (
          `Are you sure you want to move this ${formatType(type, { lowercase: true, template, directoryType })} to trash?`
        ) : (
          `Are you sure you want to delete this ${formatType(type, { lowercase: true, template, directoryType })}?`
        )}
        {deletionType !== DELETE_SOFT ? ' This action cannot be undone.' : ''}
        {deletionType !== DELETE_SOFT && type === ENTITY_USER_ROLE ? ` All ${formatType(ENTITY_USER, { plural: true, lowercase: true })} assigned to this ${formatType(ENTITY_USER_ROLE, { lowercase: true })} will lose the permissions granted by this ${formatType(ENTITY_USER_ROLE, { lowercase: true })}.` : ''}
        {deletionType !== DELETE_SOFT && type === ENTITY_DIRECTORY ? ` Permissions inherited from this ${formatType(ENTITY_DIRECTORY, { lowercase: true, template, directoryType })} will be removed from children items.` : ''}
      </Typography>
      { (directoryLocations ?? []).length > 0 ? (
        <Paper elevation={2} sx={{ mt: 1, px: 2, py: 1 }}>
          <Typography variant='body2' color='text.secondary'>
            This {formatType(type, { lowercase: true, directoryType, template })} will be removed from the following locations:
          </Typography>
          {directoryLocations.map((directory, idx) => {
            const Icon = getIcon(ENTITY_DIRECTORY, { directoryType: directory?.type });
            return isNil(directory) ? (
              <Tooltip
                key={`location_hidden_${idx}`} // eslint-disable-line react/no-array-index-key
                title='You do not have permission to view this location'
                arrow
              >
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, my: 0.5, width: 'fit-content' }}>
                  { Icon ? <Icon fontSize='small' colored /> : null}
                  <Typography
                    variant='subtitle2'
                    color='text.secondary'
                    sx={{ ml: 1 }}
                  >
                    [Content Hidden]
                  </Typography>
                </Box>
              </Tooltip>
            ) : (
              <Box key={`location_${directory.id}`} sx={{ display: 'flex', alignItems: 'center', ml: 1, my: 0.5 }}>
                { Icon ? <Icon fontSize='small' colored template={directory.is_template} /> : null}
                <Typography
                  variant='subtitle2'
                  sx={[{ ml: 1 }, directory.is_deleted ? { textDecoration: 'line-through' } : {}]}
                >
                  {directory.name}
                </Typography>
              </Box>
            );
          })}
        </Paper>
      ) : null}
      { ((type === ENTITY_DIRECTORY && deletionType === DELETE_SOFT) || type === ENTITY_MASTER_NOTE_SECTION) && directChildren.length > 0 ? (
        <Paper elevation={2} sx={{ overflow: 'hidden', mt: 1 }}>
          <Box sx={{ px: 2, py: 1 }}>
            <Typography variant='body2' color='text.secondary'>What should happen to the children items?</Typography>
            <RadioGroup value={childrenHandling} onChange={(event) => setChildrenHandling(event.target.value)}>
              <FormControlLabel
                control={<Radio />}
                value={KEEP_CHILDREN}
                label='Keep children items.'
              />
              <FormControlLabel
                control={<Radio color='error' />}
                value={DELETE_SELECTED_CHILDREN}
                label={(
                  <Typography variant='inherit'>
                    Select children items to move to trash.{' '}
                    <Typography variant='body2' color='text.secondary' component='span'>
                      ({selectedChildren.length} item{selectedChildren.length !== 1 ? 's' : ''})
                    </Typography>
                  </Typography>
                )}
              />
              <FormControlLabel
                control={<Radio color='error' />}
                value={DELETE_CHILDREN}
                label={(
                  <Typography variant='inherit'>
                    Move all children items to trash.{' '}
                    <Typography variant='body2' color='text.secondary' component='span'>
                      ({deletableChildren.length} item{deletableChildren.length !== 1 ? 's' : ''})
                    </Typography>
                  </Typography>
                )}
              />
            </RadioGroup>
          </Box>
          <Collapse in={!loading && [DELETE_CHILDREN, DELETE_SELECTED_CHILDREN].includes(childrenHandling)}>
            <Box>
              <Divider />
              <Typography color='error.main' variant='subtitle2' sx={{ px: 2, py: 1 }}>
                Selected items below will be moved to trash:
              </Typography>
              <ChildrenDocumentTable
                ids={directChildren}
                selection={childrenToDelete}
                setSelection={_setSelection}
              />
              <Typography color='error.main' variant='overline' sx={{ px: 2, fontWeight: 'fontWeightBold' }}>
                Total number of children items moving to trash: {childrenToDelete.length}
              </Typography>
            </Box>
          </Collapse>
        </Paper>
      ) : null}
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onBeforeDelete: PropTypes.func,
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
};

export default DeleteDialog;
