import { useCallback, useMemo, useState } from 'react';

import { isDefined } from '@acheloisbiosoftware/absui.utils';
import useSpaceInitialPermissionRelationships from 'hooks/useSpaceInitialPermissionRelationships';

const DEFAULT_INIT_STATE = {
  title: '',
  category: null,
  is_template: false,
  permission_relationships: [],
};

export const validateSopFormData = (formData) => {
  const { title, category } = formData ?? {};
  return Boolean(title) && isDefined(category);
};

const useNewSopFormData = (initState) => {
  const initPermissionRelationships = useSpaceInitialPermissionRelationships();
  const initFormData = useMemo(() => ({
    ...DEFAULT_INIT_STATE,
    permission_relationships: initPermissionRelationships,
    ...initState,
  }), [initState, initPermissionRelationships]);

  const [formData, setFormData] = useState(initFormData);
  const valid = validateSopFormData(formData);

  const resetFormData = useCallback(() => setFormData(initFormData), [initFormData]);

  const updateFormData = useCallback((key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: typeof(value) === 'function' ? value(prev[key]) : value,
    }));
  }, []);

  return useMemo(() => ({
    formData,
    valid,
    resetFormData,
    updateFormData,
  }), [formData, valid, resetFormData, updateFormData]);
};

export default useNewSopFormData;
