import { ReactComponent as OpusU } from './OpusU.svg';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const OpusUIcon = React.forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    inheritViewBox
    component={OpusU}
  />
));

OpusUIcon.displayName = 'OpusUIcon';

export default OpusUIcon;
