import { ACTION_REJECT, ACTION_SIGN } from 'constants/permission.constants';
import React, { useState } from 'react';
import { formatDate, timeNow } from 'utils/date.utils';
import { useSignature, useUserName } from 'hooks/store.hooks';

import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import { ENTITY_USER } from 'constants/schemas';
import Field from 'components/Field';
import PropTypes from 'prop-types';
import { signatureActions } from 'store/entity';
import { useDispatch } from 'react-redux';

const toolbarProps = { elevation: 16 };

function SignatureDialog(props) {
  const { open, onClose, action, signatureId } = props;
  const dispatch = useDispatch();
  const isRejecting = action === ACTION_REJECT;
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const signee = useSignature(signatureId, 'signee');
  const signeeName = useUserName(signee);
  const date = formatDate(timeNow(), 'MM/DD/YYYY');

  const onConfirm = () => {
    if (action === ACTION_SIGN) {
      setLoading(true);
      dispatch(signatureActions.signSignature({ id: signatureId, data: { content }}))
        .unwrap()
        .then(() => onClose?.())
        .finally(() => setLoading(false));
    } else if (action === ACTION_REJECT) {
      setLoading(true);
      dispatch(signatureActions.rejectSignature({ id: signatureId, data: { content }}))
        .unwrap()
        .then(() => onClose?.())
        .finally(() => setLoading(false));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={isRejecting ? 'Reject Document' : 'Sign Document'}
      onConfirm={onConfirm}
      loading={loading}
      disableCloseOnConfirm
      confirmButtonText={isRejecting ? 'Reject' : 'Sign'}
      confirmButtonProps={isRejecting ? { color: 'error' } : {}}
      backButtonProps={isRejecting ? { color: 'error' } : {}}
      fullWidth
      maxWidth='sm'
    >
      <Field label='Signee' labelProps={{ sx: { mt: 0 }}}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar id={signee} type={ENTITY_USER} sx={{ mr: 1 }} />
          {signeeName}
        </Box>
      </Field>
      <Field label='Date' value={date} />
      <Field
        label='Comment'
        value={content}
        variant={Field.VARIANTS.TEXT_EDITOR}
        readOnly={false}
        onChangeSerialized={(newValue) => setContent(newValue)}
        debounceSerialization={false}
        toolbarProps={toolbarProps}
      />
    </Dialog>
  );
}

SignatureDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  action: PropTypes.oneOf([ACTION_REJECT, ACTION_SIGN]),
  signatureId: PropTypes.number,
};

export default SignatureDialog;
