import {
  createEntitiesWithUpdatesSelector,
  createEntityListSelector,
  createEntitySelector,
  createEntityUpdatesSelector,
} from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { ENTITY_PIN } from 'constants/schemas';
import { isEmpty } from 'lodash';

// #############################################################################
// ################################### Pins ####################################
// #############################################################################
export const selectPins = (state) => state[ENTITY][ENTITY_PIN];
export const selectPinList = createEntityListSelector(selectPins);
export const selectPin = createEntitySelector(selectPins);
export const selectPinUpdates = createEntityUpdatesSelector(selectPins);
export const selectPinsWithUpdates = createEntitiesWithUpdatesSelector(selectPinUpdates);
export const selectPinHasUpdates = (state, id) => !isEmpty(selectPinUpdates(state, id));
