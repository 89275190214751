import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback } from 'react';
import {
  directoryActions,
  experimentActions,
  noteActions,
  sopActions,
  taskActions,
} from 'store/entity';
import { formatType, getPath } from 'utils/entity.utils';

import BaseAction from './BaseAction';
import PropTypes from 'prop-types';
import { TemplateIcon } from 'constants/icon.constants';
import { getGenericId } from 'utils/generic.utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const generateTemplateActionMap = {
  [ENTITY_DIRECTORY]: directoryActions.generateDirectoryTemplate,
  [ENTITY_EXPERIMENT]: experimentActions.generateExperimentTemplate,
  [ENTITY_NOTE]: noteActions.generateNoteTemplate,
  [ENTITY_SOP]: sopActions.generateSopTemplate,
  [ENTITY_TASK]: taskActions.generateTaskTemplate,
};

function ActionGenerateTemplate(props) {
  const { id, type, ...restProps } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAction = useCallback(async () => {
    try {
      const res = await dispatch(generateTemplateActionMap[type]({ id })).unwrap();
      navigate(getPath(getGenericId(res.result, type)));
    } catch {}
  }, [id, type, dispatch, navigate]);

  return (
    <BaseAction
      icon={<TemplateIcon />}
      label={`Save as ${formatType(type, { template: true, includeTemplateType: false })}`}
      onAction={onAction}
      {...restProps}
    />
  );
}

ActionGenerateTemplate.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.oneOf(Object.keys(generateTemplateActionMap)),
};

export default ActionGenerateTemplate;
