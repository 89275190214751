const expKey = '_EXPIRES_AT_';

function saveToLocalStorage(key, state, expiration) {
  const toSave = expiration ? { ...state, [expKey]: expiration.getTime() } : state;
  try {
    const serialisedState = JSON.stringify(toSave);
    localStorage.setItem(key, serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

function clearFromLocalStorage(key) {
  localStorage.removeItem(key);
}

function loadFromLocalStorage(key, { withExpiration = false } = {}) {
  try {
    const serialisedState = localStorage.getItem(key);
    if (serialisedState === null) return withExpiration ? [null, null] : null;
    const { [expKey]: expiration, ...state } = JSON.parse(serialisedState);
    if (expiration && expiration < new Date().getTime()) {
      clearFromLocalStorage(key);
      return withExpiration ? [null, expiration] : null;
    }
    return withExpiration ? [state, expiration] : state;
  } catch (e) {
    console.warn(e);
    return null;
  }
}

export const localStore = {
  save: saveToLocalStorage,
  load: loadFromLocalStorage,
  clear: clearFromLocalStorage,
};
