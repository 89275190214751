import React, { useState } from 'react';
import { useColumn, useRow } from './DataTableContext';

import { Button } from '@acheloisbiosoftware/absui.core';
import Menu from '@mui/material/Menu';
import { MoreOptionsIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const EMPTY_ARRAY = [];
const selectActionsCurried = createSelector(
  (actions) => actions,
  (actions, row) => row,
  (actions, row) => {
    const getActions = typeof(actions) === 'function' ? actions : () => actions;
    return createSelector(
      (state) => getActions({ row, state }),
      (_actions) => _actions ?? EMPTY_ARRAY,
    );
  },
);

function ContentActions(props) {
  const { rowId, columnId } = props;
  const row = useRow(rowId);
  const column = useColumn(columnId);
  const { actions, inMenu } = column;

  const _actions = useSelector((state) => selectActionsCurried(actions, row)(state));
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const onClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const createMenuActionHandler = (action) => async (event) => {
    onClose(event);
    await action.props?.onClick?.(event);
  };

  const createButtonActionHandler = (action) => async (event) => {
    event.stopPropagation();
    await action.props?.onClick?.(event);
  };

  return _actions.length === 0 ? null : (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-evenly'
      spacing={1}
      onClick={(event) => event.stopPropagation()}
    >
      { inMenu ? (
        <>
          <Button
            icon
            onClick={onOpen}
            size='small'
            sx={{ color: 'text.icon' }}
          >
            <MoreOptionsIcon fontSize='small' />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            onClick={(event) => event.stopPropagation()}
            disableEnforceFocus
          >
            { _actions.map((action) => {
              const { id, component: Component, props: actionProps } = action;
              return (
                <Component
                  key={`actionMenu_${id}`}
                  {...actionProps}
                  onClick={createMenuActionHandler(action)}
                />
              );
            })}
          </Menu>
        </>
      ) : (
        _actions.map((action) => {
          const { id, component: Component, props: actionProps } = action;
          return (
            <Component
              key={`actionButton_${id}`}
              {...actionProps}
              onClick={createButtonActionHandler(action)}
            />
          );
        })
      )}
    </Stack>
  );
}

ContentActions.propTypes = {
  rowId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  columnId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ContentActions;
