import { Button } from '@acheloisbiosoftware/absui.core';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import React from 'react';

function ButtonAction(props) {
  const {
    label,
    icon,
    ...restProps
  } = props;
  return (
    <Button startIcon={icon} {...restProps}>{label}</Button>
  );
}

export function ButtonLinkAction(props) {
  return (<ButtonAction {...props} component={Link} />);
}

ButtonAction.propTypes = {
  label: PropTypes.node,
  icon: PropTypes.node,
};

export default ButtonAction;
