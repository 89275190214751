import { Button, DownloadButton } from '@acheloisbiosoftware/absui.core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TimeIcon, UserIcon } from 'constants/icon.constants';
import { electron, inBrowser } from 'utils/electron.utils';
import { formatDate, isTimeApproxEq } from 'utils/date.utils';
import { useAttachment, useAttachmentPermission, useMyId, useUserName } from 'hooks/store.hooks';

import { ACTION_UPDATE } from 'constants/permission.constants';
import { AWS_QUERYSTRING_EXPIRE } from 'constants/service.constants';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CheckoutAlert from 'components/CheckoutAlert';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { ENTITY_ATTACHMENT } from 'constants/schemas';
import Error from 'components/Error';
import Loading from 'components/Loading';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { attachmentActions } from 'store/entity';
import { formatType } from 'utils/entity.utils';
import { getFileSize } from 'utils/helpers';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function Attachment() {
  const { uuid } = useParams();
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const attachment = useAttachment(uuid);
  const authorName = useUserName(attachment?.created_by);
  const wasModified = !isTimeApproxEq(attachment?.modified, attachment?.created, 1, 'seconds');

  const myId = useMyId();
  const isCheckedOut = attachment?.is_checked_out;
  const isMyCheckout = isCheckedOut && attachment?.checked_out_by === myId;
  const canUpdate = useAttachmentPermission(uuid, ACTION_UPDATE);

  const openAttachment = useCallback(() => {
    if (inBrowser) return;
    electron.ipcRenderer.send('drive:startTracking', {
      id: uuid,
      filename: attachment?.name,
      url: attachment?.file,
    });
  }, [uuid, attachment]);

  const fetchAttachment = useCallback(async () => {
    try {
      await dispatch(attachmentActions.fetchAttachment({ uuid })).unwrap();
    } catch (err) {
      const errors = Object.values(err?.data ?? {});
      setError(errors[0] ?? `Error fetching ${formatType(ENTITY_ATTACHMENT, { lowercase: true })}...`);
    }
    setInitialLoad(false);
  }, [uuid, dispatch]);

  useEffect(() => {
    fetchAttachment();
    const interval = setInterval(() => {
      fetchAttachment();
    }, 1000 * AWS_QUERYSTRING_EXPIRE);
    return () => clearInterval(interval);
  }, [fetchAttachment]);

  const rows = useMemo(() => [
    { title: 'Uploaded By', value: authorName, icon: <UserIcon /> },
    { title: 'Uploaded', value: formatDate(attachment?.created), icon: <TimeIcon /> },
    ...(wasModified ? [{ title: 'Modified', value: formatDate(attachment?.modified), icon: <TimeIcon /> }] : []),
    { title: 'File Size', value: isDefined(attachment?.size) ? getFileSize(attachment.size) : 'Unknown', icon: <SdStorageIcon /> },
  ], [attachment, authorName, wasModified]);

  return error ? (<Error>{error}</Error>) : (
    <Box sx={{ display: 'flex', height: 1 }}>
      { initialLoad ? <Loading /> : (
        <Box sx={{ mt: 4, mx: 'auto' }}>
          <Card sx={{ mt: 4, mx: 'auto', minWidth: 400 }} elevation={24}>
            <CheckoutAlert id={uuid} type={ENTITY_ATTACHMENT} />
            <CardContent>
              <Typography variant='h5'>{attachment?.name}</Typography>
              <Table>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.title}>
                      <TableCell>
                        <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                          {row.icon}
                          <Typography variant='body2' sx={{ my: 'auto', ml: 1 }}>
                            {row.title}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
            <CardActions>
              { !inBrowser && isMyCheckout && canUpdate ? (
                <Tooltip title='Changes are synced to the cloud' arrow>
                  <Button
                    variant='text'
                    size='small'
                    startIcon={<CloudSyncIcon />}
                    onClick={openAttachment}
                    sx={{ ml: 'auto' }}
                  >
                    Open File
                  </Button>
                </Tooltip>
              ) : null}
              <DownloadButton
                size='small'
                download={{ name: attachment?.name, file: attachment?.file }}
                sx={{ ml: 'auto', mr: 0 }}
              >
                Download
              </DownloadButton>
            </CardActions>
          </Card>
        </Box>
      )}
    </Box>
  );

}

export default Attachment;
