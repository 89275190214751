import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { eventListenerEffect, useResizableWidth } from '@acheloisbiosoftware/absui.hooks';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';

const RESIZER_WIDTH = 8;

const OpenContext = createContext(null);
const OnCloseContext = createContext(null);
export const useOpen = () => useContext(OpenContext);
export const useOnClose = () => useContext(OnCloseContext);

function SidePreview(props) {
  const {
    open,
    onClose,
    width: initWidth,
    minWidth,
    maxWidth,
    children,
    paperProps,
  } = props;

  const resizableOptions = useMemo(() => ({ minWidth, maxWidth, reverse: true }), [minWidth, maxWidth]);
  const { width, Resizer, isResizing } = useResizableWidth(initWidth, resizableOptions);

  const _onClose = useCallback(() => {
    if (open) onClose();
  }, [open, onClose]);

  useEffect(() => eventListenerEffect(document, 'keydown', (event) => {
    if (open && event.key === 'Escape') {
      event.preventDefault();
      _onClose();
    }
  }), [_onClose, open]);

  return (
    <OpenContext.Provider value={open}>
      <OnCloseContext.Provider value={_onClose}>
        <ClickAwayListener onClickAway={_onClose}>
          <Paper
            elevation={24}
            square
            {...paperProps}
            sx={mergeSx(
              {
                height: 1,
                overflow: 'auto',
                zIndex: 'containedModal',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
              },
              paperProps?.sx,
              !isResizing ? { transition: (theme) => theme.transitions.create(['width', 'box-shadow']) } : {},
              open ? { width } : { width: 0, boxShadow: 0 },
            )}
          >
            { children }
            <Resizer
              sx={{
                width: RESIZER_WIDTH,
                left: 0,
                zIndex: 'header',
              }}
            />
          </Paper>
        </ClickAwayListener>
      </OnCloseContext.Provider>
    </OpenContext.Provider>
  );
}

SidePreview.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  width: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  children: PropTypes.node,
  paperProps: PropTypes.object,
};

SidePreview.defaultProps = {
  width: 500,
  minWidth: 400,
  maxWidth: 800,
};

export default SidePreview;
