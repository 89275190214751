import { DIR_TYPE_PROGRAM, DIR_TYPE_PROJECT, DIR_TYPE_STUDY } from 'constants/directory.constants';
import {
  DeleteIcon,
  LaterIcon,
  MoreOptionsIcon,
  ProgramIcon,
  ProjectIcon,
  ScriptIcon,
  StudyIcon,
  UnpinIcon,
} from 'constants/icon.constants';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useMemo, useRef, useState } from 'react';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from 'components/Link';
import Menu from '@mui/material/Menu';
import MenuItem from 'components/MenuItem';
import NavButton from './NavButton';
import NewButton from './NewButton';
import NotificationButton from './NotificationButton';
import SettingsButton from './SettingsButton';
import Stack from '@mui/material/Stack';
import { formatType } from 'utils/entity.utils';
import { useIntersectionObserver } from '@acheloisbiosoftware/absui.hooks';
import { useLocation } from 'react-router-dom';
import { useMyGlobalPermission } from 'hooks/store.hooks';

const NAV_OPTIONS = [
  {
    id: 'nav-recent',
    navButtonProps: {
      label: 'Recent',
      Icon: UnpinIcon,
    },
    path: '/recent',
    selected: (pathname) => pathname === '/recent',
    dividerBefore: false,
    entityTypes: [ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK],
  },
  {
    id: 'nav-studies',
    navButtonProps: {
      label: formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_STUDY }),
      Icon: StudyIcon,
    },
    path: '/studies',
    selected: (pathname) => pathname === '/studies',
    dividerBefore: false,
    entityTypes: [ENTITY_DIRECTORY],
  },
  {
    id: 'nav-projects',
    navButtonProps: {
      label: formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_PROJECT }),
      Icon: ProjectIcon,
    },
    path: '/projects',
    selected: (pathname) => pathname === '/projects',
    dividerBefore: false,
    entityTypes: [ENTITY_DIRECTORY],
  },
  {
    id: 'nav-programs',
    navButtonProps: {
      label: formatType(ENTITY_DIRECTORY, { plural: true, directoryType: DIR_TYPE_PROGRAM }),
      Icon: ProgramIcon,
    },
    path: '/programs',
    selected: (pathname) => pathname === '/programs',
    dividerBefore: false,
    entityTypes: [ENTITY_DIRECTORY],
  },
  {
    id: 'nav-scripts',
    navButtonProps: {
      label: formatType(ENTITY_DIRECTORY, { plural: true, template: true }),
      Icon: ScriptIcon,
    },
    path: '/scripts',
    selected: (pathname) => pathname === '/scripts',
    dividerBefore: false,
    entityTypes: [ENTITY_DIRECTORY],
  },
  {
    id: 'nav-later',
    navButtonProps: {
      label: 'Later',
      Icon: LaterIcon,
    },
    path: '/later',
    selected: (pathname) => pathname === '/later',
    dividerBefore: true,
    entityTypes: [ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK],
  },
  {
    id: 'nav-trash',
    navButtonProps: {
      label: 'Trash',
      Icon: DeleteIcon,
    },
    path: '/trash',
    selected: (pathname) => pathname === '/trash',
    dividerBefore: false,
    entityTypes: [ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK],
  },
];

function AppSidebar() {
  const { pathname } = useLocation();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);

  const canViewDirectories = useMyGlobalPermission(ENTITY_DIRECTORY, ACTION_VIEW);
  const canViewExperiments = useMyGlobalPermission(ENTITY_EXPERIMENT, ACTION_VIEW);
  const canViewNotes = useMyGlobalPermission(ENTITY_NOTE, ACTION_VIEW);
  const canViewSops = useMyGlobalPermission(ENTITY_SOP, ACTION_VIEW);
  const canViewTasks = useMyGlobalPermission(ENTITY_TASK, ACTION_VIEW);

  const viewPermissions = useMemo(() => ({
    [ENTITY_DIRECTORY]: canViewDirectories,
    [ENTITY_EXPERIMENT]: canViewExperiments,
    [ENTITY_NOTE]: canViewNotes,
    [ENTITY_SOP]: canViewSops,
    [ENTITY_TASK]: canViewTasks,
  }), [canViewDirectories, canViewExperiments, canViewNotes, canViewSops, canViewTasks]);

  const options = useMemo(() => NAV_OPTIONS.filter((option) => (
    option.entityTypes.some((type) => viewPermissions[type])
  )), [viewPermissions]);

  const actionContainer = useRef(null);
  const intersectionOptions = useMemo(() => ({ threshold: 1.0 }), []);
  const childrenIds = useMemo(() => options.map((action) => action.id), [options]);
  const visibilityMap = useIntersectionObserver(actionContainer.current, childrenIds, intersectionOptions);
  const allActionsVisible = Object.values(visibilityMap).every((v) => v);

  return (
    <Stack alignItems='center' justifyContent='space-between' sx={{ overflow: 'auto' }}>
      <Stack
        alignItems='center'
        sx={{ mt: 2, overflow: 'hidden' }}
      >
        <Stack
          ref={actionContainer}
          alignItems='center'
          spacing={3}
          sx={{ overflow: 'hidden' }}
        >
          { options.map((option) => (
            <React.Fragment key={option.id}>
              { option.dividerBefore ? (
                <Box sx={{ width: 36 }}><Divider /></Box>
              ) : null}
              <NavButton
                {...option.navButtonProps}
                id={option.id}
                to={option.path}
                selected={option.selected(pathname)}
                sx={!visibilityMap[option.id] ? { opacity: 0, pointerEvents: 'none' } : {}}
              />
            </React.Fragment>
          ))}
        </Stack>
        <NavButton
          Icon={MoreOptionsIcon}
          label='More'
          onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}
          selected={Boolean(moreMenuAnchorEl)}
          sx={allActionsVisible ? { opacity: 0, pointerEvents: 'none' } : {}}
        />
        <Menu
          anchorEl={moreMenuAnchorEl}
          open={Boolean(moreMenuAnchorEl && !allActionsVisible)}
          onClose={() => setMoreMenuAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          { options.flatMap((option) => {
            const { id: optionId, navButtonProps, path, dividerBefore } = option;
            const { label, Icon } = navButtonProps;
            return (
              !visibilityMap[optionId] ? [
                dividerBefore ? <Divider key={`${optionId}_divider`} /> : null,
                <MenuItem
                  key={optionId}
                  label={label}
                  icon={Icon ? <Icon /> : null}
                  component={Link}
                  to={path}
                  onClick={() => setMoreMenuAnchorEl(null)}
                />,
              ] : null
            );
          })}
        </Menu>
      </Stack>
      <Stack alignItems='center' sx={{ py: 1 }}>
        <NewButton />
        <NotificationButton />
        <SettingsButton />
      </Stack>
    </Stack>
  );
}

export default AppSidebar;
