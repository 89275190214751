import { MoreOptionsIcon, OpenIcon } from 'constants/icon.constants';
import React, { useEffect, useState } from 'react';
import { useOnClose, useOpen } from './SidePreview';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Link from 'components/Link';
import Menu from '@mui/material/Menu';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

function SidePreviewActions(props) {
  const { path, actions } = props;
  const [actionsAnchor, setActionsAnchor] = useState(null);
  const open = useOpen();
  const onClose = useOnClose();

  const closeMenu = () => {
    setActionsAnchor(null);
  };

  useEffect(() => {
    if (!open) closeMenu();
  }, [open]);

  const onActionsClick = (event) => {
    setActionsAnchor(event.currentTarget);
  };

  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        background: 'inherit',
        position: 'sticky',
        top: 0,
        zIndex: 'header',
      }}
    >
      <Tooltip title='Close' arrow>
        <Button
          onClick={onClose}
          size='small'
          icon
          sx={{ color: 'text.icon' }}
        >
          <KeyboardDoubleArrowRightIcon />
        </Button>
      </Tooltip>

      { path ? (
        <Tooltip title='Open' arrow>
          <Button
            component={Link}
            to={path}
            size='small'
            icon
            sx={{ color: 'text.icon' }}
          >
            <OpenIcon fontSize='small' />
          </Button>
        </Tooltip>
      ) : null }

      { actions.length > 0 ? (
        <>
          <Button
            onClick={onActionsClick}
            size='small'
            icon
            sx={{ mr: 0, ml: 'auto', color: 'text.icon' }}
          >
            <MoreOptionsIcon />
          </Button>
          <Menu
            anchorEl={open ? actionsAnchor : null}
            open={Boolean(open && actionsAnchor)}
            onClose={closeMenu}
            disableEnforceFocus
          >
            { actions.map((action) => {
              const { id, component: Component, props: actionProps } = action;
              return (
                <Component
                  key={`action_${id}`}
                  {...actionProps}
                  onClick={closeMenu}
                />
              );
            }) }
          </Menu>
        </>
      ) : null }
    </Box>
  );
}

SidePreviewActions.propTypes = {
  path: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    component: PropTypes.elementType.isRequired,
    props: PropTypes.object,
  })),
};

export default SidePreviewActions;
