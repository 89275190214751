import React, { useState } from 'react';
import { useSignatureWorkflow, useSignatureWorkflowStep } from 'hooks/store.hooks';

import { COMPLETED } from 'constants/signature.constants';
import PropTypes from 'prop-types';
import SignatureWorkflowStep from './SignatureWorkflowStep';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';

function SignatureWorkflow(props) {
  const { id, stepperProps } = props;
  const steps = useSignatureWorkflow(id, 'steps');
  const stepStatuses = useSignatureWorkflowStep(steps, 'status');
  const currentStepId = useSignatureWorkflow(id, 'current_step');
  const currentStepSequence = useSignatureWorkflowStep(currentStepId, 'sequence');
  const [activeStep, setActiveStep] = useState(currentStepSequence);
  return (
    <Stepper
      activeStep={activeStep}
      orientation='vertical'
      nonLinear
      {...stepperProps}
    >
      { (steps ?? []).map((stepId, idx) => (
        <Step key={`step_${stepId}`} completed={stepStatuses[idx] === COMPLETED}>
          <SignatureWorkflowStep
            id={stepId}
            onOpen={() => setActiveStep(idx)}
          />
        </Step>
      )) }
    </Stepper>
  );
}

SignatureWorkflow.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  stepperProps: PropTypes.object,
};

export default SignatureWorkflow;
