import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE } from 'components/DataTable';
import { EntityActionState, selectEntityActions } from 'components/EntityActions';
import { formatType, getPath } from 'utils/entity.utils';
import { useFetchListing, useTeamList } from 'hooks/store.hooks';

import ActionAddTeam from './ActionAddTeam';
import { ENTITY_TEAM } from 'constants/schemas';
import React from 'react';
import { getGenericId } from 'utils/generic.utils';
import { useNavigate } from 'react-router-dom';
import { userActions } from 'store/entity';

const columns = [
  {
    id: 'name',
    label: `${formatType(ENTITY_TEAM)} Name`,
    field: 'name',
    primary: true,
  },
  {
    id: 'created',
    label: 'Date Created',
    field: 'created',
    type: COL_TYPE_DATE,
    secondary: true,
  },
  {
    id: 'actions',
    type: COL_TYPE_ACTIONS,
    showOnHover: true,
    inMenu: true,
    actions: ({ row, state }) => selectEntityActions(state, row.id, ENTITY_TEAM),
  },
];

function Teams() {
  const allTeams = useTeamList();
  const navigate = useNavigate();
  const loading = useFetchListing(userActions.fetchTeams);

  return (
    <EntityActionState>
      <DataTable
        rows={allTeams}
        columns={columns}
        actions={(<ActionAddTeam />)}
        loading={loading}
        onRowClick={(row) => navigate(getPath(getGenericId(row.id, ENTITY_TEAM)))}
        initState={{
          orderBy: 'name',
        }}
      />
    </EntityActionState>
  );
}

export default Teams;
