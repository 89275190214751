import { useEffect, useState } from 'react';

const useSystemDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches ?? false);
  const mqListener = ((e) => setIsDarkMode(e?.matches ?? false));

  useEffect(() => {
    const darkThemeMq = window?.matchMedia?.('(prefers-color-scheme: dark)');
    darkThemeMq?.addEventListener?.('change', mqListener);
    return () => darkThemeMq?.removeEventListener?.('change', mqListener);
  }, []);

  return isDarkMode;
};

export default useSystemDarkMode;
