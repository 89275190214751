import { ENTITY_ACTION_DELETE, EntityActionState, useEntityActions } from 'components/EntityActions';
import { ENTITY_EXPERIMENT, ENTITY_SOP } from 'constants/schemas';
import {
  ExperimentIcon,
  LocationIcon,
  SopIcon,
  StatusIcon,
  TimeIcon,
  UserIcon,
} from 'constants/icon.constants';
import React, { useEffect } from 'react';
import { formatType, getPath } from 'utils/entity.utils';
import {
  useExperiment,
  useSop,
  useSopHasLoaded,
  useSopPermission,
  useUserName,
} from 'hooks/store.hooks';

import { ACTION_VIEW } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import { DATE_FMT_SHORT } from 'constants/date.constants';
import DeletedAlert from 'components/DeletedAlert';
import Field from 'components/Field';
import Link from 'components/Link';
import Locations from 'components/Locations';
import PropTypes from 'prop-types';
import SidePreviewActions from './SidePreviewActions';
import SidePreviewContent from './SidePreviewContent';
import SidePreviewHeader from './SidePreviewHeader';
import Skeleton from '@mui/material/Skeleton';
import Status from 'components/Status';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { experimentActions } from 'store/entity';
import { formatDate } from 'utils/date.utils';
import { getGenericId } from 'utils/generic.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { useOnClose } from './SidePreview';

function ExperimentPreviewContent(props) {
  const { id } = props;
  const onClose = useOnClose();
  const dispatch = useDispatch();
  const experiment = useExperiment(id);
  const owner = useUserName(experiment?.created_by);
  const relatedSop = useSop(experiment?.related_sop);
  const relatedSopHasLoaded = useSopHasLoaded(experiment?.related_sop);
  const canViewRelatedSop = useSopPermission(experiment?.related_sop, ACTION_VIEW);

  useEffect(() => {
    if (isDefined(id)) dispatch(experimentActions.fetchExperiment({ id }));
  }, [dispatch, id]);

  const createRow = (title, value, icon) => ({ title, value, icon });
  const iconSx = { fontSize: 20, my: 'auto' };
  const tableProperties = [
    createRow(formatType(ENTITY_SOP), !relatedSopHasLoaded ? (
      <Skeleton />
    ) : canViewRelatedSop ? (
      <Link to={getPath(getGenericId(relatedSop.id, ENTITY_SOP))}>
        {relatedSop.long_title}
      </Link>
    ) : (
      <Tooltip title={`You do not have permission to view this ${formatType(ENTITY_SOP, { lowercase: true })}`} arrow>
        <Typography color='text.disabled'>[Content Hidden]</Typography>
      </Tooltip>
    ), <SopIcon sx={iconSx} />),
    createRow(experiment?.is_template ? 'Author' : 'Responsible Person', owner, <UserIcon sx={iconSx} />),
    !experiment?.is_template ? createRow('Status', isDefined(experiment?.status) ? <Status>{experiment.status}</Status> : null, <StatusIcon sx={iconSx} />) : null,
    createRow('Date Created', isDefined(experiment?.created) ? formatDate(experiment.created, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    createRow('Last Modified', isDefined(experiment?.modified) ? formatDate(experiment.modified, DATE_FMT_SHORT) : null, <TimeIcon sx={iconSx} />),
    experiment?.is_deleted ? createRow('Date Deleted', formatDate(experiment.deleted, DATE_FMT_SHORT), <TimeIcon sx={iconSx} />) : null,
    createRow('Location(s)', (<Locations directoryItem={experiment?.directory_item} />), <LocationIcon sx={iconSx} />),
  ];

  const actions = useEntityActions(id, ENTITY_EXPERIMENT, {
    actionPropsMap: { [ENTITY_ACTION_DELETE]: { onDeleteSuccess: onClose }},
  });

  return (
    <>
      <EntityActionState>
        <SidePreviewActions path={getPath(getGenericId(id, ENTITY_EXPERIMENT))} actions={actions} />
      </EntityActionState>

      <SidePreviewHeader
        icon={<ExperimentIcon colored template={experiment?.is_template} />}
        title={experiment?.title}
        subtitle={experiment?.exp_code}
      />

      <SidePreviewContent>
        <DeletedAlert id={id} type={ENTITY_EXPERIMENT} sx={{ mb: 1 }} />

        <Table size='small'>
          <TableBody>
            {tableProperties.map((row) => (!isDefined(row) ? null : (
              <TableRow key={row.title}>
                <TableCell sx={{ minWidth: 155, width: 155 }}>
                  <Box sx={{ display: 'flex', color: 'text.secondary' }}>
                    {row.icon}
                    <Typography variant='body2' sx={{ my: 'auto', ml: 1 }}>
                      {row.title ?? <Skeleton />}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ typography: 'body1' }}>
                  {row.value ?? <Skeleton />}
                </TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>

        <Box sx={{ mt: 2, position: 'relative' }}>
          { experiment && 'description' in experiment ? (
            <Field
              label='Description'
              value={experiment.description}
              variant={Field.VARIANTS.TEXT_EDITOR}
              readOnly
              interactable={false}
              valueProps={{ key: `description${id}` }}
            />
          ) : null}
        </Box>
      </SidePreviewContent>
    </>
  );
}

ExperimentPreviewContent.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ExperimentPreviewContent;
