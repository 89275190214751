import { ENTITY_PERMISSION, ENTITY_PERMISSION_RELATIONSHIP } from 'constants/schemas';
import { createEntityListSelector, createEntityPermissionSelector, createEntitySelector } from '../entity.selectors.utils';

import { ENTITY } from 'store/store.constants';
import { authSelectors } from 'store/auth';
import { userSelectors } from '../user';

// #############################################################################
// ################################ Permissions ################################
// #############################################################################
export const selectPermissions = (state) => state[ENTITY][ENTITY_PERMISSION];
export const selectPermissionList = createEntityListSelector(selectPermissions);
export const selectPermission = createEntitySelector(selectPermissions);


// #############################################################################
// ######################### Permission Relationships ##########################
// #############################################################################
export const selectPermissionRelationships = (state) => state[ENTITY][ENTITY_PERMISSION_RELATIONSHIP];
export const selectPermissionRelationship = createEntitySelector(selectPermissionRelationships);
export const selectPermissionRelationshipPermission = createEntityPermissionSelector(selectPermissionRelationship);


// #############################################################################
// ################################### Other ###################################
// #############################################################################
export const selectMyGlobalPermission = (state, type, action) => (
  userSelectors.selectUser(state, authSelectors.selectMyId(state), 'global_permissions')?.[type]?.[action] ?? false
);
