import React, { useEffect } from 'react';
import { navigationActions, navigationSelectors } from 'store/navigation';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import Typography from '@mui/material/Typography';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useBlocker } from 'react-router-dom';
import { useUnloadBlocker } from 'hooks/navigation.hooks';

function NavBlocker() {
  const when = useSelector(navigationSelectors.selectWhen);
  const message = useSelector(navigationSelectors.selectMessage);
  const blocker = useBlocker(when);
  const dispatch = useDispatch();
  const dialogOpen = blocker.state === 'blocked';

  useEffect(() => {
    dispatch(navigationActions.setIsBlocked(dialogOpen));
  }, [dispatch, dialogOpen]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when]);

  useUnloadBlocker(when);

  const onClose = () => {
    blocker.reset?.();
  };

  const onConfirm = () => {
    blocker.proceed?.();
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      disableCloseOnConfirm
      title='Confirm Navigation'
    >
      <Box id='NavBlockerContent'> {/* ID to use as portal if custom message is required */}
        {
          isDefined(message) ? (
            message ? (<Typography>{message}</Typography>) : null
          ) : (
            <Typography>Are you sure you would like to navigate away? There are unsaved changes which will be lost.</Typography>
          )
        }
      </Box>
    </Dialog>
  );
}

export default NavBlocker;
