import React, { createContext, useContext, useMemo, useState } from 'react';

import DeleteDialog from 'components/DeleteDialog';
import { Dialog } from '@acheloisbiosoftware/absui.core';
import MoveDialog from 'components/MoveDialog';
import NewExperimentDialog from 'components/NewExperimentDialog';
import PropTypes from 'prop-types';
import { ShareDialog } from 'components/Shares';

const MoveDialogContext = createContext({});
export const useMoveDialogContext = () => useContext(MoveDialogContext);

const ShareDialogContext = createContext({});
export const useShareDialogContext = () => useContext(ShareDialogContext);

const NewExperimentDialogContext = createContext({});
export const useNewExperimentDialogContext = () => useContext(NewExperimentDialogContext);

const DeleteDialogContext = createContext({});
export const useDeleteDialogContext = () => useContext(DeleteDialogContext);

const DialogContext = createContext({});
export const useDialogContext = () => useContext(DialogContext);

function EntityActionContainer(props) {
  const { children } = props;

  const [moveDialogProps, setMoveDialogProps] = useState({
    open: false,
    onClose: () => setMoveDialogProps((oldProps) => ({ ...oldProps, open: false })),
  });
  const moveDialogContextValue = useMemo(() => ({ setMoveDialogProps }), []);

  const [shareDialogProps, setShareDialogProps] = useState({
    open: false,
    onClose: () => setShareDialogProps((oldProps) => ({ ...oldProps, open: false })),
  });
  const shareDialogContextValue = useMemo(() => ({ setShareDialogProps }), []);

  const [newExperimentDialogProps, setNewExperimentDialogProps] = useState({
    open: false,
    onClose: () => setNewExperimentDialogProps((oldProps) => ({ ...oldProps, open: false })),
  });
  const newExperimentDialogContextValue = useMemo(() => ({ setNewExperimentDialogProps }), []);

  const [deleteDialogProps, setDeleteDialogProps] = useState({
    open: false,
    onClose: () => setDeleteDialogProps((oldProps) => ({ ...oldProps, open: false })),
  });
  const deleteDialogContextValue = useMemo(() => ({ setDeleteDialogProps }), []);

  const [dialogProps, setDialogProps] = useState({
    open: false,
    onClose: () => setDialogProps((oldProps) => ({ ...oldProps, open: false })),
  });
  const dialogContextValue = useMemo(() => ({ setDialogProps }), []);

  return (
    <MoveDialogContext.Provider value={moveDialogContextValue}>
      <ShareDialogContext.Provider value={shareDialogContextValue}>
        <NewExperimentDialogContext.Provider value={newExperimentDialogContextValue}>
          <DeleteDialogContext.Provider value={deleteDialogContextValue}>
            <DialogContext.Provider value={dialogContextValue}>
              { children }
              <MoveDialog {...moveDialogProps} />
              <ShareDialog {...shareDialogProps} />
              <NewExperimentDialog {...newExperimentDialogProps} />
              <DeleteDialog {...deleteDialogProps} />
              <Dialog {...dialogProps} />
            </DialogContext.Provider>
          </DeleteDialogContext.Provider>
        </NewExperimentDialogContext.Provider>
      </ShareDialogContext.Provider>
    </MoveDialogContext.Provider>
  );
}

EntityActionContainer.propTypes = {
  children: PropTypes.node,
};

export default EntityActionContainer;
