import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import LandingDialog from 'components/LandingDialog';
import LogoIcon from 'assets/LogoIcon';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSubmitted from './ResetPasswordSubmitted';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';

const logoSx = { fontSize: 64 };

function ResetPassword() {
  const [submitted, setSubmitted] = useState(false);
  const containerRef = useRef(null);

  const theme = useTheme();

  return (
    <LandingDialog containerProps={{ ref: containerRef }}>
      <LogoIcon sx={logoSx} />
      <Slide
        direction='right'
        in={!submitted}
        unmountOnExit
        appear={false}
        container={containerRef.current}
      >
        <Box><ResetPasswordForm setSubmitted={setSubmitted} /></Box>
      </Slide>
      <Slide
        direction='left'
        in={submitted}
        style={{ transitionDelay: theme.transitions.duration.leavingScreen + 1 }}
        container={containerRef.current}
      >
        <Box><ResetPasswordSubmitted /></Box>
      </Slide>
    </LandingDialog>
  );
}

export default ResetPassword;
