import * as SopService from 'services/SopService';
import * as schemas from 'constants/schemas';
import * as sopSelectors from './sop.selectors';

import { createServiceThunk } from 'store/store.actions.utils';

// #############################################################################
// ################################ Categories #################################
// #############################################################################
export const fetchCategories = createServiceThunk({
  name: `${schemas.ENTITY_CATEGORY}/fetchCategories`,
  service: SopService.fetchCategories,
  schema: [schemas.category],
  isPaginated: true,
});

export const createCategory = createServiceThunk({
  name: `${schemas.ENTITY_CATEGORY}/createCategory`,
  service: SopService.createCategory,
  schema: schemas.category,
});


// #############################################################################
// ################################### SOPs ####################################
// #############################################################################
export const fetchSops = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/fetchSops`,
  service: SopService.fetchSops,
  schema: [schemas.sop],
  isPaginated: true,
});

export const createSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/createSop`,
  service: SopService.createSop,
  schema: schemas.sop,
});

export const fetchSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/fetchSop`,
  service: SopService.fetchSop,
  schema: schemas.sop,
});

export const patchSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/patchSop`,
  service: SopService.patchSop,
  schema: schemas.sop,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: sopSelectors.selectSopPatchData(getState(), id),
  }),
});

export const deleteSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/deleteSop`,
  service: SopService.deleteSop,
  schema: schemas.sop,
});

export const deleteSopPermanently = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/deleteSopPermanently`,
  service: SopService.deleteSopPermanently,
  schema: schemas.sop,
});

export const checkinSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/checkinSop`,
  service: SopService.checkinSop,
  schema: schemas.sop,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: sopSelectors.selectSopPatchData(getState(), id),
  }),
});

export const checkoutSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/checkoutSop`,
  service: SopService.checkoutSop,
  schema: schemas.sop,
});

export const cloneSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/cloneSop`,
  service: SopService.cloneSop,
  schema: schemas.sop,
});

export const createSection = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/createSection`,
  service: SopService.createSection,
  schema: schemas.sop,
  preprocessingFn: ({ id, data }, { getState }) => ({
    id,
    data: {
      ...sopSelectors.selectSopPatchData(getState(), id),
      _post_data: data,
    },
  }),
});

export const createSubsection = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/createSubsection`,
  service: SopService.createSubsection,
  schema: schemas.sop,
  preprocessingFn: ({ id, data }, { getState }) => ({
    id,
    data: {
      ...sopSelectors.selectSopPatchData(getState(), id),
      _post_data: data,
    },
  }),
});

export const deleteSection = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/deleteSection`,
  service: SopService.deleteSection,
  schema: schemas.sop,
  preprocessingFn: ({ id }, { getState }) => {
    const state = getState();
    const sopId = sopSelectors.selectSection(state, id, 'sop');
    return {
      id: sopId,
      data: {
        ...sopSelectors.selectSopPatchData(getState(), sopId),
        _delete_data: { id },
      },
    };
  },
});

export const deleteSubsection = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/deleteSubsection`,
  service: SopService.deleteSubsection,
  schema: schemas.sop,
  preprocessingFn: ({ id }, { getState }) => {
    const state = getState();
    const sectionId = sopSelectors.selectSubsection(state, id, 'section');
    const sopId = sopSelectors.selectSection(state, sectionId, 'sop');
    return {
      id: sopId,
      data: {
        ...sopSelectors.selectSopPatchData(getState(), sopId),
        _delete_data: { id },
      },
    };
  },
});

export const generateSopTemplate = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/generateSopTemplate`,
  service: SopService.generateSopTemplate,
  schema: schemas.sop,
});

export const instantiateSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/instantiateSop`,
  service: SopService.instantiateSop,
  schema: schemas.sop,
});

export const newSopVersion = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/newSopVersion`,
  service: SopService.newSopVersion,
  schema: schemas.sop,
});

export const publishSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/publishSop`,
  service: SopService.publishSop,
  schema: schemas.sop,
  preprocessingFn: ({ id }, { getState }) => ({
    id,
    data: sopSelectors.selectSopPatchData(getState(), id),
  }),
});

export const restoreSop = createServiceThunk({
  name: `${schemas.ENTITY_SOP}/restoreSop`,
  service: SopService.restoreSop,
  schema: schemas.sop,
});
