import { isDefined, mergeSx } from '@acheloisbiosoftware/absui.utils';

import { Button } from '@acheloisbiosoftware/absui.core';
import { ClearIcon } from 'constants/icon.constants';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '@mui/material/Stack';

function ContentButton(props) {
  const {
    listProps,
    listItemProps,
    listItemButtonProps,
    listItemIconProps,
    listItemTextProps,
    clearButtonProps,
    secondaryAction,
    onClear,
    disabled,
    onClick,
    icon,
    content,
    children,
  } = props;
  const hasSecondaryAction = (
    isDefined(onClear) ||
    isDefined(secondaryAction) ||
    isDefined(listItemProps?.secondaryAction)
  );

  return (
    <List disablePadding {...listProps}>
      <ListItem
        disablePadding
        {...listItemProps}
        secondaryAction={hasSecondaryAction ? (
          <Stack direction='row' alignItems='center' sx={{ color: 'text.icon' }}>
            { isDefined(onClear) ? (
              <Button
                icon
                size='small'
                color='inherit'
                onClick={onClear}
                {...clearButtonProps}
              >
                <ClearIcon fontSize='small' />
              </Button>
            ) : null}
            { secondaryAction }
            { listItemProps?.secondaryAction }
          </Stack>
        ) : null}
      >
        <ListItemButton
          onClick={onClick}
          disabled={disabled}
          {...listItemButtonProps}
          sx={mergeSx(
            {
              minHeight: 40,
              px: 1,
              py: 0.5,
              borderRadius: 2,
            },
            { '&.Mui-disabled': { opacity: 1 }},
            listItemButtonProps?.sx,
          )}
        >
          { isDefined(icon) ? (
            <ListItemIcon
              {...listItemIconProps}
              sx={mergeSx({ minWidth: 36, color: 'text.icon' }, listItemIconProps?.sx)}
            >
              { icon }
            </ListItemIcon>
          ) : null}
          { content ? (
            <ListItemText
              primary={content}
              {...listItemTextProps}
              primaryTypographyProps={{
                noWrap: true,
                ...listItemTextProps?.primaryTypographyProps,
              }}
            />
          ) : null}
          { children }
        </ListItemButton>
      </ListItem>
    </List>
  );
}

ContentButton.propTypes = {
  listProps: PropTypes.object,
  listItemProps: PropTypes.object,
  listItemButtonProps: PropTypes.object,
  listItemIconProps: PropTypes.object,
  listItemTextProps: PropTypes.object,
  clearButtonProps: PropTypes.object,
  secondaryAction: PropTypes.node,
  onClear: PropTypes.func,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  content: PropTypes.node,
  children: PropTypes.node,
};

export default ContentButton;
