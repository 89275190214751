import { BaseAction, EntityActionState, useEntityActions } from 'components/EntityActions';
import React, { useMemo } from 'react';
import { useDirectory, useMasterNote, useUpdateEntity, useUserName } from 'hooks/store.hooks';

import { DIR_TYPE_PROGRAM } from 'constants/directory.constants';
import { ENTITY_MASTER_NOTE } from 'constants/schemas';
import Field from 'components/Field';
import Locations from 'components/Locations';
import MasterNoteSectionBase from './MasterNoteSectionBase';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { formatDate } from 'utils/date.utils';
import { useCollapseAll } from './CollapseAllContext';
import { useEditorChangeHandler } from 'components/DelayedUpdateProvider';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';

const toolbarProps = { sx: { top: 58 }, elevation: 2 };

function MasterNoteOverview(props) {
  const { id } = props;
  const { collapseAll, expandAll } = useCollapseAll();
  const content = useMasterNote(id, 'content');
  const parentId = useMasterNote(id, 'parent');
  const parent = useDirectory(parentId);
  const { type, directory_item, created, modified, created_by } = parent ?? {};
  const owner = useUserName(created_by);
  const update = useUpdateEntity();
  const editorChangeHandler = useEditorChangeHandler(id, ENTITY_MASTER_NOTE);
  const isReadOnly = useEntityIsReadOnly();

  const onChange = (value) => {
    update({ type: ENTITY_MASTER_NOTE, id, key: 'content', value });
  };

  const extraActions = useEntityActions(id, ENTITY_MASTER_NOTE);
  const inMenu = useMemo(() => ([
    {
      id: 'expandAll',
      component: BaseAction,
      props: {
        label: 'Expand All',
        icon: <UnfoldMoreIcon />,
        onAction: () => expandAll(),
      },
    },
    {
      id: 'collapseAll',
      component: BaseAction,
      props: {
        label: 'Collapse All',
        icon: <UnfoldLessIcon />,
        onAction: () => collapseAll(),
      },
    },
    ...extraActions,
  ]), [extraActions, expandAll, collapseAll]);

  return (
    <EntityActionState>
      <MasterNoteSectionBase
        containerProps={{ id: 'section_overview' }}
        sectionHeaderProps={{
          title: 'Overview',
          extraActions: { inMenu },
        }}
      >
        <Stack direction='row'>
          <Stack sx={{ flexGrow: 1 }}>
            <Field label='Owner'>{owner}</Field>
            { type !== DIR_TYPE_PROGRAM ? (
              <Field label='Location(s)'>
                <Locations directoryItem={directory_item} />
              </Field>
            ) : null}
          </Stack>
          <Stack sx={{ flexGrow: 1 }}>
            <Field label='Date Created'>{formatDate(created)}</Field>
            <Field label='Last Modified'>{formatDate(modified)}</Field>
          </Stack>
        </Stack>
        <Field
          label='Overview'
          value={content}
          variant={Field.VARIANTS.TEXT_EDITOR}
          readOnly={isReadOnly}
          onChangeSerialized={onChange}
          onChange={editorChangeHandler}
          serializeOnBlur={false}
          sx={!isReadOnly ? { maxHeight: 360, minHeight: 80 } : {}}
          toolbarProps={toolbarProps}
          valueProps={{ key: `overview${id}` }}
        />
      </MasterNoteSectionBase>
    </EntityActionState>
  );
}

MasterNoteOverview.propTypes = {
  id: PropTypes.number.isRequired,
};

export default MasterNoteOverview;
