import {
  ENTITY_ACTION_DELETE,
  ENTITY_ACTION_OPEN,
  EntityActionState,
  IconButtonAction,
  useEntityActions,
} from 'components/EntityActions';
import { ENTITY_DIRECTORY, ENTITY_MASTER_NOTE } from 'constants/schemas';
import React, { useCallback, useMemo } from 'react';
import { useDirectory, useEntityIcon, useUpdateEntity } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { DELETE_SOFT } from 'constants/deletion.constants';
import { MasterNoteIcon } from 'constants/icon.constants';
import PropTypes from 'prop-types';
import SectionHeader from 'components/SectionHeader';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TocIcon from '@mui/icons-material/Toc';
import { formatType } from 'utils/entity.utils';
import { getGenericId } from 'utils/generic.utils';
import { useEntityIsReadOnly } from 'hooks/Entity.hooks';
import { useNavigate } from 'react-router-dom';

const TAB_PROPS = { iconPosition: 'start', sx: { minHeight: 'unset' }};

function WorkspaceHeader(props) {
  const { id, viewMasterNote, setViewMasterNote, collapsed, onExpand } = props;
  const navigate = useNavigate();
  const update = useUpdateEntity();
  const isReadOnly = useEntityIsReadOnly();
  const dirName = useDirectory(id, 'name');
  const dirType = useDirectory(id, 'type');
  const isTemplate = useDirectory(id, 'is_template');
  const Icon = useEntityIcon(getGenericId(id, ENTITY_DIRECTORY));

  const onEdit = useCallback((value) => {
    if ((value ?? '').length > 0 && value !== dirName) {
      update({ type: ENTITY_DIRECTORY, id, key: 'name', value });
    }
  }, [dirName, update, id]);

  const onDeleteSuccessDirectory = useCallback((res, { deletionType }) => {
    if (deletionType !== DELETE_SOFT) {
      navigate('/');
    }
  }, [navigate]);

  const entityActionsOptions = useMemo(() => ({
    exclude: [ENTITY_ACTION_OPEN],
    actionPropsMap: { [ENTITY_ACTION_DELETE]: {
      label: `Delete ${formatType(ENTITY_DIRECTORY, { directoryType: dirType, template: isTemplate })}`,
      onDeleteSuccess: onDeleteSuccessDirectory,
    }},
  }), [dirType, onDeleteSuccessDirectory, isTemplate]);

  const dirActions = useEntityActions(id, ENTITY_DIRECTORY, entityActionsOptions);

  const extraActions = useMemo(() => ({
    inMenu: collapsed ? null : dirActions,
    right: collapsed ? [{
      id: 'toggleViewDocument',
      component: IconButtonAction,
      props: {
        label: `View ${formatType(ENTITY_MASTER_NOTE)}`,
        icon: <MasterNoteIcon />,
        size: 'small',
        color: 'primary',
        onClick: onExpand,
      },
    }] : null,
  }), [collapsed, onExpand, dirActions]);

  return (
    <Box
      sx={{
        borderBottom: collapsed ? 2 : 1,
        borderColor: 'divider',
        px: collapsed ? 1 : 2,
        py: collapsed ? 0 : 1,
        transition: (theme) => theme.transitions.create(['padding', 'border']),
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <EntityActionState>
        <SectionHeader
          title={dirName}
          prefix={(
            <Icon
              colored
              template={isTemplate}
              templateBadgeSize={16}
              sx={{ fontSize: 32, verticalAlign: 'middle' }}
            />
          )}
          onEdit={isReadOnly || collapsed ? null : onEdit}
          extraActions={extraActions}
          sx={{ width: 'unset' }}
        />
      </EntityActionState>
      <Box sx={{ ml: 'auto', mr: 0 }}>
        <Collapse in={!collapsed} orientation='horizontal'>
          <Tabs
            value={viewMasterNote ? 0 : 1}
            onChange={(event, value) => setViewMasterNote(value === 0)}
          >
            <Tab
              value={0}
              icon={<MasterNoteIcon />}
              label={formatType(ENTITY_MASTER_NOTE)}
              {...TAB_PROPS}
            />
            <Tab
              value={1}
              icon={<TocIcon />}
              label='Documents'
              {...TAB_PROPS}
            />
          </Tabs>
        </Collapse>
      </Box>
    </Box>
  );
}

WorkspaceHeader.propTypes = {
  id: PropTypes.number.isRequired,
  viewMasterNote: PropTypes.bool,
  setViewMasterNote: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  onExpand: PropTypes.func,
};

export default WorkspaceHeader;
