import { userActions, userSelectors } from 'store/entity';

import { ACTION_UPDATE } from 'constants/permission.constants';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import UserRoleDetails from './UserRoleDetails';
import UserRolePermissions from './UserRolePermissions';
import UserRoleUsers from './UserRoleUsers';
import { withEntity } from 'hooks/Entity.hooks';

function UserRole(props) {
  const { id } = props;

  return (
    <Box sx={{ px: 5, py: 2, flexGrow: 1, display: 'flex' }}>
      <Box sx={{ minWidth: 640, maxWidth: 900, mx: 'auto' }}>
        <UserRoleDetails id={id} />
        <UserRolePermissions id={id} />
        <UserRoleUsers id={id} />
      </Box>
    </Box>
  );
}

UserRole.propTypes = {
  id: PropTypes.number.isRequired,
};

export default withEntity(UserRole, {
  fetchAction: userActions.fetchUserRole,
  hasLoadedSelector: userSelectors.selectUserRoleHasLoaded,
  readOnlySelector: (state, id) => !userSelectors.selectUserRolePermission(state, id, ACTION_UPDATE),
});
