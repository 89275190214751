import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@mui/material/Typography';
import { mergeSx } from '@acheloisbiosoftware/absui.utils';
import { sxPropType } from '@acheloisbiosoftware/absui.constants';

function EmptyField(props) {
  const { children, sx, ...restProps } = props;
  return (
    <Typography
      variant='body2'
      {...restProps}
      sx={mergeSx({
        ml: 2,
        fontStyle: 'italic',
        color: 'text.tertiary',
      }, sx)}
    >
      { children ?? 'None' }
    </Typography>
  );
}

EmptyField.propTypes = {
  children: PropTypes.node,
  sx: sxPropType,
};

export default EmptyField;
