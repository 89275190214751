import { ACTION_COMPLETE, ACTION_SIGNATURE_WORKFLOW_CREATE } from 'constants/permission.constants';
import { ENTITY_EXPERIMENT, ENTITY_SIGNATURE } from 'constants/schemas';
import React, { useCallback, useState } from 'react';
import { experimentActions, signatureActions } from 'store/entity';
import { useExperiment, useExperimentHasLoaded, useExperimentPermission } from 'hooks/store.hooks';

import Box from '@mui/material/Box';
import { Button } from '@acheloisbiosoftware/absui.core';
import DetailsSection from './DetailsSection';
import ExperimentSection from './ExperimentSection';
import ExperimentWarnings from './ExperimentWarnings';
import Loading from 'components/Loading';
import PropTypes from 'prop-types';
import { SignatureIcon } from 'constants/icon.constants';
import SignaturesSection from './SignaturesSection';
import Stack from '@mui/material/Stack';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { formatType } from 'utils/entity.utils';
import { isDefined } from '@acheloisbiosoftware/absui.utils';
import { useDispatch } from 'react-redux';
import { useStageDelayedUpdates } from 'components/DelayedUpdateProvider';

function ExperimentActions(props) {
  const { expId } = props;
  const canComplete = useExperimentPermission(expId, ACTION_COMPLETE);
  const canCreateSignatureWorkflow = useExperimentPermission(expId, ACTION_SIGNATURE_WORKFLOW_CREATE);
  const stageUpdates = useStageDelayedUpdates();
  const dispatch = useDispatch();

  const [completeLoading, setCompleteLoading] = useState(false);
  const onComplete = useCallback(() => {
    stageUpdates();
    setCompleteLoading(true);
    dispatch(experimentActions.completeExperiment({ id: expId }))
      .unwrap()
      .catch(() => null)
      .finally(() => setCompleteLoading(false));
  }, [dispatch, expId, stageUpdates]);

  const [signatureLoading, setSignatureLoading] = useState(false);
  const initSignatureWorkflow = useCallback(() => {
    setSignatureLoading(true);
    dispatch(signatureActions.createSignatureWorkflow({
      object_id: expId,
      content_type: ENTITY_EXPERIMENT,
    }))
      .unwrap()
      .catch(() => null)
      .finally(() => setSignatureLoading(false));
  }, [dispatch, expId]);

  return !(canComplete || canCreateSignatureWorkflow) ? null : (
    <Box sx={{ flexGrow: 1, display: 'flex', my: 2, justifyContent: 'flex-end' }}>
      { canComplete ? (
        <Button
          startIcon={<TaskAltIcon />}
          onClick={onComplete}
          loading={completeLoading}
        >
          Mark as Completed
        </Button>
      ) : null}
      { canCreateSignatureWorkflow ? (
        <Button
          startIcon={<SignatureIcon />}
          onClick={initSignatureWorkflow}
          loading={signatureLoading}
        >
          Send for {formatType(ENTITY_SIGNATURE, { plural: true })}
        </Button>
      ) : null}
    </Box>
  );
}

ExperimentActions.propTypes = {
  expId: PropTypes.number.isRequired,
};

function ExperimentBody(props) {
  const { expId } = props;
  const sectionIds = useExperiment(expId, 'sections');
  const signatureWorkflowId = useExperiment(expId, 'signature_workflow');
  const sectionIdsHasLoaded = useExperimentHasLoaded(expId, 'sections');
  const signatureWorkflowIdHasLoaded = useExperimentHasLoaded(expId, 'signature_workflow');

  return (!sectionIdsHasLoaded || !signatureWorkflowIdHasLoaded) ? (<Loading containerProps={{ sx: { minWidth: 425 }}} />) : (
    <Stack
      sx={{
        width: 1,
        minWidth: 425,
      }}
    >
      <ExperimentWarnings expId={expId} />

      <Box
        sx={{
          overflow: 'auto',
          px: 4,
          display: 'flex',
        }}
      >
        <Box
          sx={{
            maxWidth: 800,
            width: 1,
            mx: 'auto',
            mt: 'auto',
            mb: 2,
          }}
        >
          <DetailsSection expId={expId} />
          { sectionIds?.map((sectionId) => (
            <ExperimentSection
              key={`section_${sectionId}`}
              sectionId={sectionId}
            />
          ))}
          { isDefined(signatureWorkflowId) ? (
            <SignaturesSection signatureWorkflowId={signatureWorkflowId} />
          ) : null}
          <ExperimentActions expId={expId} />
        </Box>
      </Box>
    </Stack>
  );
}

ExperimentBody.propTypes = {
  expId: PropTypes.number.isRequired,
};

export default ExperimentBody;
