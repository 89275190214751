import { ButtonAction, EntityActionState, selectEntityActions } from 'components/EntityActions';
import { ConstantProp, GenericProp, SelectorProp, directoryActions, entitySelectors } from 'store/entity';
import DataTable, { COL_TYPE_ACTIONS, COL_TYPE_DATE, COL_TYPE_SELECT, COL_TYPE_USER, FILTER_TYPE_SELECT } from 'components/DataTable';
import { ENTITY_DIRECTORY, ENTITY_EXPERIMENT, ENTITY_NOTE, ENTITY_SOP, ENTITY_TASK } from 'constants/schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatType, getIcon, getPath } from 'utils/entity.utils';
import { genericIdFromString, genericIdToString, getGenericId } from 'utils/generic.utils';
import { isNil, maxBy } from 'lodash';
import { useDirectoryItemList, useEntity, useFetchListing } from 'hooks/store.hooks';

import ActionRestore from 'components/EntityActions/ActionRestore';
import Box from '@mui/material/Box';
import { EntityPreview } from 'components/SidePreview';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { momentValue } from 'utils/date.utils';
import { useNavigate } from 'react-router-dom';

const TEMPLATE_SUFFIX = '-template';
const fetchOptions = { params: { filters: { is_deleted: true }}};
const listParams = {
  prop: { id: 'object_id', type: 'content_type' },
  trash: true,
};

const createIconSelectorProp = (type) => new SelectorProp((state, entity) => entitySelectors.selectEntityIcon(state, getGenericId(entity?.id, type)));
const commonFragment = {
  objectId: 'id',
  isTemplate: 'is_template',
  owner: 'created_by',
  deleted: 'deleted',
};
const childrenItemProp = new GenericProp({
  [ENTITY_DIRECTORY]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_DIRECTORY),
    Icon: createIconSelectorProp(ENTITY_DIRECTORY),
    name: 'name',
  },
  [ENTITY_EXPERIMENT]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_EXPERIMENT),
    Icon: createIconSelectorProp(ENTITY_EXPERIMENT),
    name: 'long_title',
    status: 'status',
  },
  [ENTITY_NOTE]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_NOTE),
    Icon: createIconSelectorProp(ENTITY_NOTE),
    name: 'long_title',
  },
  [ENTITY_SOP]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_SOP),
    Icon: createIconSelectorProp(ENTITY_SOP),
    name: 'long_title',
    status: 'status',
  },
  [ENTITY_TASK]: {
    ...commonFragment,
    type: new ConstantProp(ENTITY_TASK),
    Icon: createIconSelectorProp(ENTITY_TASK),
    name: 'long_title',
    status: 'status',
  },
});

function TrashTable(props) {
  const { listView, selected, setSelected } = props;
  const [previewState, setPreviewState] = useState({ open: false, id: null, type: null });
  const navigate = useNavigate();
  const loading = useFetchListing(directoryActions.fetchDirectoryItems, fetchOptions);
  const genericIds = useDirectoryItemList(listParams);
  const childrenItemObjects = useEntity(genericIds, childrenItemProp);

  const rows = useMemo(() => (childrenItemObjects ?? []).map((row) => ({
    ...row,
    id: genericIdToString(getGenericId(row.objectId, row.type)),
  })), [childrenItemObjects]);

  const columns = useMemo(() => [
    {
      id: 'checkbox',
      type: COL_TYPE_SELECT,
      selection: !listView || isNil(selected) ? [] : [selected],
      hidden: true,
    },
    {
      id: 'type',
      label: 'Type',
      field: 'type',
      groupValueGetter: ({ row, value }) => `${value}${row.isTemplate ? TEMPLATE_SUFFIX : ''}`,
      filterValueGetter: ({ row, value }) => `${value}${row.isTemplate ? TEMPLATE_SUFFIX : ''}`,
      renderValue: ({ row }) => {
        const { Icon, isTemplate } = row;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            { Icon ? <Icon colored template={isTemplate} /> : null }
          </Box>
        );
      },
      renderGroupValue: ({ value }) => {
        const isTemplate = value.endsWith(TEMPLATE_SUFFIX);
        const type = isTemplate ? value.slice(0, -TEMPLATE_SUFFIX.length) : value;
        const Icon = getIcon(type);
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            { Icon ? <Icon colored template={isTemplate} /> : null }
            <Typography variant='subtitle2' sx={{ ml: 1 }} noWrap>
              {formatType(type, { template: isTemplate })}
            </Typography>
          </Box>
        );
      },
      cellProps: ({ isGrouping }) => (isGrouping ? {} : { sx: { width: 20, pr: 0 }}),
      filterType: FILTER_TYPE_SELECT,
      filterOptionProps: (value) => {
        const isTemplate = value.endsWith(TEMPLATE_SUFFIX);
        const type = isTemplate ? value.slice(0, -TEMPLATE_SUFFIX.length) : value;
        const Icon = getIcon(type);
        return {
          icon: Icon ? <Icon colored template={isTemplate} /> : null,
          label: formatType(type, { template: isTemplate }),
        };
      },
      groupable: true,
      noHeader: true,
    },
    {
      id: 'name',
      label: 'Name',
      field: 'name',
      renderValue: ({ value }) => value || 'Untitled',
      cellProps: { sx: {
        maxWidth: 360,
        ...(listView ? { whiteSpace: 'normal' } : {}),
      }},
      primary: true,
      noWrap: true,
    },
    {
      id: 'restore',
      type: COL_TYPE_ACTIONS,
      actions: ({ row }) => [{
        id: 'restore',
        component: ActionRestore,
        props: {
          id: row.objectId,
          type: row.type,
          component: ButtonAction,
          color: 'info',
          variant: 'outlined',
        },
      }],
      hidden: listView,
    },
    {
      id: 'owner',
      label: 'Owner',
      field: 'owner',
      type: COL_TYPE_USER,
      cellProps: { sx: { width: 150 }},
      secondary: true,
      noWrap: true,
      hidden: listView,
    },
    {
      id: 'deleted',
      label: 'Deleted',
      field: 'deleted',
      type: COL_TYPE_DATE,
      groupable: true,
      cellProps: { sx: { width: 200 }},
      secondary: true,
      hidden: listView,
    },
    {
      id: 'actions',
      type: COL_TYPE_ACTIONS,
      showOnHover: true,
      inMenu: true,
      actions: ({ row, state }) => {
        const genericId = genericIdFromString(row.id);
        return selectEntityActions(state, genericId.id, genericId.type);
      },
    },
  ], [listView, selected]);

  useEffect(() => {
    if (isNil(selected)) {
      const firstRow = maxBy(rows, (row) => momentValue(row.deleted));
      setSelected(firstRow?.id, { inParams: false });
    }
  }, [selected, setSelected, rows]);

  const onRowClick = useCallback((row, event) => {
    const genericId = genericIdFromString(row.id);
    if (event.detail > 1) {
      navigate(getPath(genericId));
      return;
    }
    if (listView) {
      setSelected(row.id, { inParams: true });
    } else {
      setPreviewState({ open: true, id: genericId.id, type: genericId.type });
      event.stopPropagation(); /* To prevent the preview from closing */
    }
  }, [navigate, listView, setSelected]);

  const onClose = useCallback(() => {
    setPreviewState((oldPreviewState) => ({ ...oldPreviewState, open: false }));
  }, []);

  return (
    <>
      <EntityActionState>
        <DataTable
          rows={rows}
          columns={columns}
          loading={loading}
          onRowClick={onRowClick}
          noHeader={listView}
          disableFilter={listView}
          disableGroupBy={listView}
          stateInSearchParams
          initState={{
            orderBy: 'deleted',
            order: 'desc',
          }}
          sx={{ overflowY: 'auto' }}
        />
      </EntityActionState>
      <EntityPreview
        id={previewState.id}
        type={previewState.type}
        open={previewState.open}
        onClose={onClose}
      />
    </>
  );
}

TrashTable.propTypes = {
  listView: PropTypes.bool,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};

export default TrashTable;
